<div *ngIf="isFullGrid && images.length > 0" class="full-grid-title">
  <span
    [innerText]="'search_more_banner.search_more_grid_1' | translate"
  ></span>
  "{{ searchQuery }}"
  <span
    [innerText]="'search_more_banner.search_more_grid_2' | translate"
  ></span>
  {{ nameOfSubportal }}
</div>

<a
  class="block"
  href="{{ link }}"
  *ngIf="!isLoading"
  (click)="$event.stopPropagation()"
>
  <div>
    <ng-container *ngTemplateOutlet="imgRow"></ng-container>
  </div>
  <div *ngIf="!isFullGrid" class="footer">
    <div class="text-left">
      {{ "search_more_banner.content" | translate }}
      <b>{{ nameOfSubportal }}</b>
    </div>
    <div class="text-right">
      {{ "search_more_banner.show_more" | translate
      }}<img src="/assets/more-arrow-black.svg" alt="" />
    </div>
  </div>
  <div class="click-prevention"></div>
</a>

<ng-template #imgRow>
  <app-fancy-grid
    [imageData]="images"
    [imagesPerRow]="imagesPerRow"
    [variant]="1"
    [isSearchMoreGrid]="isFullGrid ? false : true"
  ></app-fancy-grid>
</ng-template>
