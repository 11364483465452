import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CookiesService } from '../cookies/cookies.service';
import { version } from 'package.json';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public version: string = version;
  public isHidden: boolean = false;

  private urlSubscription: Subscription = new Subscription();

  constructor(private cookiesService: CookiesService, private router: Router) {}

  ngOnInit(): void {
    if (this.router.url.startsWith('/login')) {
      this.isHidden = true;
    }
    this.urlSubscription = this.router.events.subscribe((path: any) => {
      if (path.url) {
        const url = path.url;
        if (url.startsWith('/login')) {
          this.isHidden = true;
        } else {
          this.isHidden = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.urlSubscription.unsubscribe();
  }

  handleCookieSettingsClick(event: any) {
    event.preventDefault();
    this.cookiesService.openCookieSettings();
  }
}
