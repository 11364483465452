import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { GalleryImportItem } from '../../cms/content/galleries/galleries.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/shared/environment';
import { GalleryGroupImportItem } from '../../cms/content/gallery-group/gallery-group.component';

@Component({
  selector: 'app-main-preview-gallery-group-item',
  templateUrl: './main-preview-gallery-group-item.component.html',
  styleUrls: ['./main-preview-gallery-group-item.component.scss'],
})
export class MainPreviewGalleryGroupItemComponent implements OnInit, OnChanges {
  @Input() importItem?: GalleryGroupImportItem;
  @Input() isVideo: boolean = false;
  @Input() isLocked?: boolean;
  @Input() lockedBy?: string;
  @Input() isError?: boolean;

  videoPreviewUrl?: string;
  imagePreviewUrl?: string;
  imageLoadingPreviewUrl?: string;

  imageLoaded: boolean = false;
  isImageError: boolean = false;

  title?: string;
  @Input() publishDate?: string;
  @Input() numberOfProducts?: number;
  @Input() tags?: (string | undefined)[];
  previewUrl: string[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.title = this.importItem?.headline?.title;
    this.previewUrl = this.getPreviewUrl();
  }

  getPreviewUrl(): string[] {
    if (this.importItem?.previewUrl == undefined)
      return ['/assets/no-image.svg'];

    if (this.importItem?.previewUrl.length == 0)
      return ['/assets/no-image.svg'];

    return this.importItem?.previewUrl
      .slice(0, 4)
      .map((url) => {
        //console.log("IRENA", this.importItem, url);
        if (url == undefined || url.length == 0) return '/assets/no-image.svg';

        return environment.productServingUrl + url;
      })
      .map((pu) => pu!);
  }
}
