import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PreviewType } from '../../cms/import/main-preview/main-preview.component';
import { filter, map } from 'rxjs/operators';
import { AutocompleteOption } from '../field-price/field-price.component';
import { GalleryImportItem } from '../../cms/content/galleries/galleries.component';
import { SnackBarService } from '../../share/snack-bar.service';
import logging from '../../logging';

export interface FieldChangedEvent {
  field: string;
  value: any;
}

export interface IMultipleItemEditorService {
  selectedIdsData: Observable<number[]>;
  errorIdsData: Observable<number[]>;
  isUnsavedData: Observable<boolean>;

  afterSaveData: Observable<(shouldRefresh: boolean) => void>;

  fieldChangeData: Observable<FieldChangedEvent>;

  setField(field: string, value: any): void;

  selectAll(): void;

  deleteSelected(): void;

  cancel(): void;

  queueSaveSession(callback: (() => void) | undefined): void;

  loadSession(page: number, options: any): void;

  getIsLoaded(): Observable<boolean>;
}

@Component({ template: '' })
export class MultipleItemEditorComponent<T = any> implements OnInit, OnDestroy {
  public unsubscribe$ = new Subject<void>();
  public items: T[] = [];

  public selectedIds: number[] = [];
  public errorIds: number[] = [];

  public previewType: PreviewType = PreviewType.Grid;
  public isUnsavedData: boolean = false;

  public isLoaded: boolean = true;
  @Output() public isLoadedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /**
   * You can mark that there's unsaved data from external sources and it will show the save buttons.
   * Use this in combination with the beforeSaveCallback to move the saving logic outside of this component.
   */
  @Input() markAsUnsavedFromExternal: boolean = false;

  public currentPage: number = 1;

  public filters?: { [key: string]: any } = {};

  /**
   * If editing is disabled all of the fields have to be read-only and grayed
   * out.
   * @protected
   */
  public isEditingDisabled: boolean = false;

  protected fieldChangedSource = new Subject<FieldChangedEvent>();
  protected currentFields: { [key: string]: any } = {};

  protected ref?: ChangeDetectorRef;
  protected snackBarService?: SnackBarService;

  @Output() public beforeSaveCallback = new EventEmitter<Subject<boolean>>();

  @Input()
  isEmbedded: boolean = false;

  allIsSelected: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.getService()
      .getIsLoaded()
      .subscribe((isLoaded) => {
        this.isLoaded = isLoaded;
        this.isLoadedChange.emit(isLoaded);
      });

    this.debugTag = this.generateUuidv4();

    this.subscribeToService();
  }

  ngAfterViewInit() {
    this.loadSession();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //////////////////////////////////////////////////////////////////////////////
  // Services
  //////////////////////////////////////////////////////////////////////////////

  debugTag: string = '';

  generateUuidv4(): string {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      // tslint:disable-next-line:no-bitwise
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  _log(message: string) {
    //console.log(`${this.debugTag}: ${message}`);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Services
  //////////////////////////////////////////////////////////////////////////////

  getService(): IMultipleItemEditorService {
    throw new Error('not Implemented');
  }

  subscribeToService() {
    this.getService().selectedIdsData.subscribe((selectedIds) => {
      this.allIsSelected = selectedIds.length != 0;

      // Remove the current selection.
      // @ts-ignore
      document.activeElement.blur();

      this.selectedIds = selectedIds;
      this.isEditingDisabled = selectedIds.length == 0;
    });

    this.getService().errorIdsData.subscribe((errorIds) => {
      this.errorIds = errorIds;
    });

    this.getService().isUnsavedData.subscribe((isUnsavedData) => {
      this.isUnsavedData = isUnsavedData;
    });

    this.getService().fieldChangeData.subscribe((fieldChangeEvent) => {
      //console.log(`Field change data '${fieldChangeEvent.field}' to value '${fieldChangeEvent.value}'`, fieldChangeEvent.value)
      this.currentFields[fieldChangeEvent.field] = fieldChangeEvent.value;

      this.fieldChangedSource.next(fieldChangeEvent);
    });
  }

  onFilter() {
    if (this.promptUserToSaveBeforeContinuing()) return;

    this.currentPage = 1;
    this.loadSession();
  }

  onPageChange() {
    if (this.promptUserToSaveBeforeContinuing()) return;

    this.loadSession();
  }

  //////////////////////////////////////////////////////////////////////////////
  // Loading
  //////////////////////////////////////////////////////////////////////////////

  loadSession() {
    let options = { page: this.currentPage - 1, ...this.filters };
    //console.log("loadSession", options);
    this.getService().loadSession(this.currentPage - 1, options);
  }

  cancel() {
    this.getService().cancel();
  }

  save() {
    if (this.beforeSaveCallback.observers.length != 0) {
      let subject = new Subject<boolean>();
      subject.asObservable().subscribe((value) => {
        if (value) {
          this.getService().queueSaveSession(() => {
            this.currentValue = {};
            this.afterSaveCallback();
          });
        }
      });
      this.beforeSaveCallback.emit(subject);
    } else {
      this.getService().queueSaveSession(() => {
        this.currentValue = {};
        this.afterSaveCallback();
      });
    }
  }

  afterSaveCallback() {}

  promptUserToSaveBeforeContinuing() {
    if (this.isUnsavedData || this.markAsUnsavedFromExternal) {
      this.snackBarService!.showSnackBar(
        'Please save or cancel before continuing.',
        'ERROR'
      );
      return true;
    }

    return false;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Fields
  //////////////////////////////////////////////////////////////////////////////

  getFieldValue<T>(field: string): Observable<T> {
    return this.fieldChangedSource.pipe(
      filter((event) => {
        return event.field == field;
      }),

      map((event) => {
        return event.value as T;
      })
    );
  }

  currentValue: { [key: string]: any | undefined } = {};

  saveField(field: string, newValue?: any) {
    let oldValue: any | undefined = this.currentValue[field];

    if (oldValue == undefined && field == 'publishDate') {
      oldValue = this.getCurrentFieldValue(field);
    }

    if (oldValue === newValue && newValue != undefined) {
      logging.debug(
        'multiple-item-editor.component.ts',
        `Ignoring value change cause on field '${field}' it looks same '${oldValue}' == '${newValue}.'`,
        oldValue,
        newValue
      );
      return;
    }

    logging.debug(
      'multiple-item-editor.component.ts',
      `NOT ignoring value change cause on field '${field}' it looks different '${oldValue}' != '${newValue}.'`,
      oldValue,
      newValue
    );

    this.getService().setField(field, newValue);

    this.currentValue[field] = newValue;
  }

  getStringFieldValue(field: string): Observable<string> {
    return this.getFieldValue(field);
  }

  getBooleanFieldValue(field: string): Observable<boolean> {
    return this.getFieldValue(field);
  }

  getAutocompleteFieldValue(field: string): Observable<AutocompleteOption> {
    return this.getFieldValue(field);
  }

  getAutocompleteFieldTitleValue(field: string): Observable<string> {
    return this.getFieldValue<AutocompleteOption>(field).pipe(
      map((v) => v.title)
    );
  }

  getMultipleAutocompleteFieldValue(
    field: string
  ): Observable<AutocompleteOption[]> {
    return this.getFieldValue(field);
  }

  getNumberFieldValue(field: string): Observable<number> {
    return this.getFieldValue(field);
  }

  getCurrentFieldValue<T>(field: string): T | undefined {
    return this.currentFields[field] as T | undefined;
  }

  getCurrentStringFieldValue(field: string): string | undefined {
    return this.getCurrentFieldValue(field);
  }

  getCurrentBooleanFieldValue(field: string): boolean | undefined {
    return this.getCurrentFieldValue(field);
  }

  getCurrentAutocompleteFieldValue(
    field: string
  ): AutocompleteOption | undefined {
    return this.getCurrentFieldValue(field);
  }

  getCurrentMultipleAutocompleteFieldValue(
    field: string
  ): AutocompleteOption[] | undefined {
    return this.getCurrentFieldValue(field);
  }

  getCurrentNumberFieldValue(field: string): number | undefined {
    return this.getCurrentFieldValue(field);
  }

  selectAll() {
    this.getService().selectAll();
  }

  deleteAll() {
    this.deleteSelected();
  }

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    // Don't catch space bar when editing stuff.
    if (
      document.activeElement != null &&
      (document.activeElement.tagName == 'INPUT' ||
        document.activeElement.tagName == 'TEXTAREA')
    )
      return;

    switch (event.key) {
      case 'Delete':
        this.deleteSelected();

        break;
    }
  }

  deleteSelected() {
    this.getService().deleteSelected();
  }

  imageBoxContainerPaddingLeft: number = 0.0;
  imageBoxContainerPaddingTop: number = 0.0;
  imageBoxContainerWidth: string = 'auto';
  imageBoxContainerHeight: string = 'auto';

  onImageLoaded(event: { item: GalleryImportItem; aspectRatio: number }) {
    if (event.aspectRatio) {
      if (event.aspectRatio < 1.0) {
        this.imageBoxContainerPaddingLeft = (1.0 - event.aspectRatio) * 100.0;
        this.imageBoxContainerPaddingTop = 0.0;
        this.imageBoxContainerWidth = '100%';
        this.imageBoxContainerHeight = 'auto';
      } else {
        this.imageBoxContainerPaddingLeft = (1.0 / event.aspectRatio) * 100.0;
        this.imageBoxContainerPaddingTop = 0.0;
        this.imageBoxContainerWidth = '100%';
        this.imageBoxContainerHeight = 'auto';
      }
    }

    this.ref?.detectChanges();
  }
}
