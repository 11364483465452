/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AutocompleteItem } from './autocompleteItem';

export interface DynamicViewField {
  name?: string;
  title?: string;
  kind?: DynamicViewField.KindEnum;
  /**
   * Name of the autocomplete field that will be used to show suggestions.
   */
  autocomplete?: string;
  /**
   * Possible dropdown options.
   */
  dropdown_options?: Array<AutocompleteItem>;
  dynamic_kind_field?: string;
  dynamic_is_read_only_field?: string;
  dynamic_dropdown_field?: string;
  is_read_only?: boolean;
  is_hidden?: boolean;
}
export namespace DynamicViewField {
  export type KindEnum =
    | 'STRING'
    | 'INTEGER'
    | 'DECIMAL'
    | 'BOOLEAN'
    | 'TEXT'
    | 'DROPDOWN'
    | 'TAGS';
  export const KindEnum = {
    STRING: 'STRING' as KindEnum,
    INTEGER: 'INTEGER' as KindEnum,
    DECIMAL: 'DECIMAL' as KindEnum,
    BOOLEAN: 'BOOLEAN' as KindEnum,
    TEXT: 'TEXT' as KindEnum,
    DROPDOWN: 'DROPDOWN' as KindEnum,
    TAGS: 'TAGS' as KindEnum,
  };
}
