<div [ngClass]="'theme-' + theme">
  <div class="field-title">{{ label }}</div>
  <div class="field-group-container">
    <div class="field-container" [class.disabled]="disabled">
      <input
        type="text"
        [disabled]="disabled"
        #inputEl
        appDigitOnly
        [decimal]="true"
        [decimalSeparator]="'.'"
        [(ngModel)]="rawValue"
        (ngModelChange)="onRawValueChanged()"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
    </div>
  </div>

  <!-- Errors -->
  <div *ngIf="errors != undefined && errors.length > 0" class="field-errors">
    <div *ngFor="let error of errors" class="field-error">
      {{ error.error_description }}
    </div>
  </div>
</div>
