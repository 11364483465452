import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() isInplaceSearch = false;

  @Input() searchQuery: string = '';
  @Output() searchQueryChange = new EventEmitter<string>();

  @Input() isSearchActive: boolean = false;
  @Output() isSearchActiveChange = new EventEmitter<boolean>();
  @Input() isFilterSidebarOpen: boolean = true;
  @Input() bannerSrc: string = '';
  @Input() bannerTitle: string = '';
  @Input() bannerSearchPlaceholder: string = '';
  @Output() openFilterSidebarEvent: EventEmitter<void> = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  openFilterSidebar() {
    this.openFilterSidebarEvent.emit();
  }

  onEnter() {
    if (this.isInplaceSearch) return;

    if (this.searchQuery !== '') {
      this.router.navigate([], {
        queryParams: { q: this.searchQuery },
      });
      this.isSearchActiveChange.emit(true);
    }
  }
}
