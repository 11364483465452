<div
  class="main-preview-holder"
  [class.embedded]="isEmbedded"
  [class.not-embedded]="!isEmbedded"
>
  <div class="main-preview-header">
    <div
      class="main-preview-header-types"
      *ngIf="!isGalleryEdit && !isEmbedded"
    >
      <div
        class="main-preview-header-type grid"
        [class.active]="previewType == 1"
        (click)="changePreviewType('Grid')"
        *ngIf="false"
      ></div>

      <div
        class="main-preview-header-type list"
        *ngIf="false"
        [class.active]="previewType == 2"
        (click)="changePreviewType('Table')"
      ></div>

      <div
        class="main-preview-header-type details"
        [class.active]="previewType == 3"
        *ngIf="false"
        (click)="changePreviewType('InlineEdit')"
      ></div>
    </div>

    <div class="ml-auto d-flex align-items-center">
      <app-preview-slider
        [style.display]="previewType == 1 ? 'block' : 'none'"
        [maxValue]="7"
        [value]="imagesPerRow"
        (valueChange)="percentChangeHandler($event)"
      ></app-preview-slider>

      <div
        class="main-preview-pagination"
        *ngIf="isLoaded && numberOfItems === undefined"
      >
        <div class="current-page" *ngIf="!perPage">Page {{ page }}</div>
        <div class="current-page" *ngIf="perPage">
          {{ pageFromItem }} - {{ pageToItem }}
        </div>
        <div class="total-pages" *ngIf="totalResults">
          of {{ totalResults }}
        </div>

        <div class="previous-page" (click)="previousPage()"></div>
        <div class="next-page" (click)="nextPage()"></div>
      </div>

      <div
        class="main-preview-pagination"
        *ngIf="isLoaded && numberOfItems !== undefined"
      >
        <div class="current-page">
          {{ numberOfItems }}
        </div>
        <div class="total-pages">/ max. {{ maximumNumberOfItems }} item(s)</div>
      </div>
    </div>
  </div>

  <div class="main-preview-body" #container>
    <div *ngIf="previewType == 1">
      <div
        class="main-preview-row"
        *ngIf="isLoaded && reorderingEnabled"
        dragula="VAMPIRES"
      >
        <app-main-preview-item
          *ngFor="let item of items; index as i; trackBy: getItemId"
          [canSetAsGalleryHead]="canSetAsGalleryHead"
          [attr.iaspect]="item.aspectRatio"
          [attr.iwidth]="item.width"
          [attr.iheight]="item.height"
          [attr.iitemHeight]="itemHeight"
          (onImageLoaded)="onImageLoaded($event)"
          [importItem]="item"
          [isLocked]="item.isLocked"
          [attr.data-id]="item.id"
          [attr.index]="items.indexOf(item)"
          [imageSize]="imageSize"
          [isVideo]="item.isVideo"
          [isError]="hasError(item.id)"
          [previewUrl]="item.previewUrl"
          [loadingPreviewUrl]="item.blurPreviewUrl"
          [largePreviewUrl]="item.largePreviewUrl"
          [videoPreviewUrl]="item.previewVideoLoop"
          [canDownload]="canDownload"
          (click)="previewItemClick(item, $event)"
          (mouseover)="itemOver(item, $event)"
          (mouseleave)="itemOut(item, $event)"
          [ngClass]="{
            selected: selectedItemIds.includes(item.id),
            error: errorIds.includes(item.id)
          }"
          [ngStyle]="{
            height: item.height * itemHeight + 'px',
            flex: item.width | previewItemFlex
          }"
        ></app-main-preview-item>
      </div>
      <div class="main-preview-row" *ngIf="isLoaded && !reorderingEnabled">
        <app-main-preview-item
          *ngFor="let item of items; index as i; trackBy: getItemId"
          [canSetAsGalleryHead]="canSetAsGalleryHead"
          [attr.iaspect]="item.aspectRatio"
          [attr.iwidth]="item.width"
          [attr.iheight]="item.height"
          [attr.iitemHeight]="itemHeight"
          (onImageLoaded)="onImageLoaded($event)"
          [importItem]="item"
          [isLocked]="item.isLocked"
          [attr.data-id]="item.id"
          [attr.index]="items.indexOf(item)"
          [imageSize]="imageSize"
          [isVideo]="item.isVideo"
          [isError]="hasError(item.id)"
          [previewUrl]="item.previewUrl"
          [loadingPreviewUrl]="item.blurPreviewUrl"
          [largePreviewUrl]="item.largePreviewUrl"
          [videoPreviewUrl]="item.previewVideoLoop"
          [canDownload]="canDownload"
          (click)="previewItemClick(item, $event)"
          (mouseover)="itemOver(item, $event)"
          (mouseleave)="itemOut(item, $event)"
          [ngClass]="{
            selected: selectedItemIds.includes(item.id),
            error: errorIds.includes(item.id)
          }"
          [ngStyle]="{
            height: item.height * itemHeight + 'px',
            flex: item.width | previewItemFlex
          }"
        ></app-main-preview-item>
      </div>
      <div class="main-preview-row" *ngIf="!isLoaded">
        <div
          [ngStyle]="{
            height: 0.6871876876876877 * itemHeight + 'px',
            flex: 10 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>
        <div
          [ngStyle]="{
            height: 0.6871876876876877 * itemHeight + 'px',
            flex: 40 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>
        <div
          [ngStyle]="{
            height: 0.6871876876876877 * itemHeight + 'px',
            flex: 20 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>
        <div
          [ngStyle]="{
            height: 0.6871876876876877 * itemHeight + 'px',
            flex: 10 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>
        <div
          [ngStyle]="{
            height: 0.6871876876876877 * itemHeight + 'px',
            flex: 20 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>

        <div
          [ngStyle]="{
            height: 0.6871876876876877871876876876877 * itemHeight + 'px',
            flex: 20 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>
        <div
          [ngStyle]="{
            height: 0.6871876876876877871876876876877 * itemHeight + 'px',
            flex: 20 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>
        <div
          [ngStyle]="{
            height: 0.6871876876876877871876876876877 * itemHeight + 'px',
            flex: 50 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>
        <div
          [ngStyle]="{
            height: 0.6871876876876877871876876876877 * itemHeight + 'px',
            flex: 10 | previewItemFlex
          }"
          class="loading-skeleton-item-container"
        >
          <div class="loading-skeleton-item"></div>
        </div>
      </div>
    </div>

    <div *ngIf="previewType == 2">
      <app-table
        (tableRowSelected)="tableItemClick($event)"
        [columns]="columns"
        [columnTypes]="columnTypes"
        [columnTitles]="columnTitles"
        [items]="items"
      ></app-table>
    </div>

    <div *ngIf="previewType == 3">
      <cdk-virtual-scroll-viewport
        itemSize="2389"
        class="virtual-scroll-viewport"
        minBufferPx="2389"
        maxBufferPx="2389"
      >
        <div *cdkVirtualFor="let item of items" class="example-item">
          <app-import-inline-editing-item
            [selected]="selectedItemIds.includes(item.id)"
            (selectedChange)="previewItemClick(item, $event)"
            [item]="item"
          >
          </app-import-inline-editing-item>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
