/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SpecialInstructions } from './specialInstructions';
import { UserRights } from './userRights';

export interface ProductPriceCalculation {
  product_name?: string;
  file_name?: string;
  video_resolution?: string;
  web_price?: number;
  print_price?: number;
  video_price?: number;

  alternative_web_price?: number;
  alternative_print_price?: number;
  alternative_video_price?: number;
  use_tax?: boolean;

  currency?: string;
  /**
   * Hide price, show \"Available on demand\"
   */
  price_on_demand?: boolean;
  available_variations?: Array<ProductPriceCalculation.AvailableVariationsEnum>;
  download_options?: Array<ProductPriceCalculation.DownloadOptionsEnum>;
  special_instructions?: Array<SpecialInstructions>;
  usage_rights?: Array<UserRights>;
  agephoto_large_resolution?: string;
  agephoto_small_resolution?: string;
}
export namespace ProductPriceCalculation {
  export type AvailableVariationsEnum = 'PRINT' | 'WEB' | 'VIDEO';
  export const AvailableVariationsEnum = {
    PRINT: 'PRINT' as AvailableVariationsEnum,
    WEB: 'WEB' as AvailableVariationsEnum,
    VIDEO: 'VIDEO' as AvailableVariationsEnum,
  };
  export type DownloadOptionsEnum =
    | 'AUTHOR_SIGNATURE'
    | 'DOWNLOAD'
    | 'SEND_TO_SYSTEM'
    | 'SEND_TO_CMS'
    | 'ADD_TO_CART'
    | 'DOWNLOAD_EXAMPLE';
  export const DownloadOptionsEnum = {
    AUTHORSIGNATURE: 'AUTHOR_SIGNATURE' as DownloadOptionsEnum,
    DOWNLOAD: 'DOWNLOAD' as DownloadOptionsEnum,
    SENDTOSYSTEM: 'SEND_TO_SYSTEM' as DownloadOptionsEnum,
    SENDTOCMS: 'SEND_TO_CMS' as DownloadOptionsEnum,
    ADDTOCART: 'ADD_TO_CART' as DownloadOptionsEnum,
    DOWNLOADEXAMPLE: 'DOWNLOAD_EXAMPLE' as DownloadOptionsEnum,
  };
}
