import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ConfirmModal {
  title: string;
  text: string;
  onConfirm: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService {
  private confirmModalSource = new Subject<ConfirmModal>();
  public confirmModalData: Observable<ConfirmModal> =
    this.confirmModalSource.asObservable();

  constructor() {}

  showConfirmModal(title: string, text: string, onConfirm: () => void) {
    this.confirmModalSource.next({
      title,
      text,
      onConfirm,
    });
  }
}
