import { InjectionToken } from '@angular/core';
import { ApiHandler } from './configuration';

export const API_HANDLER = new InjectionToken<ApiHandler>('apiHandler');
export const BASE_PATH = new InjectionToken<string>('basePath');
export const VERSION = new InjectionToken<string>('version');
export const COLLECTION_FORMATS = {
  csv: ',',
  tsv: '   ',
  ssv: ' ',
  pipe: '|',
};
