<div>
  <div class="main-preview-header">
    <div class="main-preview-header-types">
      <div
        class="main-preview-header-type grid"
        [class.active]="previewType == 1"
        (click)="changePreviewType('Grid')"
      ></div>

      <div
        class="main-preview-header-type list"
        [class.active]="previewType == 2"
        (click)="changePreviewType('Table')"
      ></div>

      <div
        class="main-preview-header-type details"
        [class.active]="previewType == 3"
        (click)="changePreviewType('InlineEdit')"
      ></div>
    </div>

    <div class="ml-auto d-flex align-items-center">
      <div class="main-preview-pagination">
        <div class="current-page">Stranica {{ page }}</div>
        <div class="total-pages"></div>

        <div class="previous-page" (click)="previousPage()"></div>
        <div class="next-page" (click)="nextPage()"></div>
      </div>
    </div>
  </div>
  <div>
    <ng-container [ngSwitch]="contentType">
      <ng-container *ngSwitchCase="'gallery'">
        <ng-container *ngTemplateOutlet="gallery"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'galleryGroup'">
        <ng-container *ngTemplateOutlet="galleryGroup"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'galleryDetailed'">
        <ng-container *ngTemplateOutlet="galleryDetailed"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!--           -->
<!-- Templates -->
<ng-template #gallery>
  <div class="card-list__container">
    <div
      class="card-outer"
      *ngFor="let card of items; let i = index"
      (click)="handleCardClick(i, $event)"
    >
      <app-gallery-card
        [cardData]="card"
        [class.selected]="selectedCards.includes(i)"
      ></app-gallery-card>
    </div>
  </div>
</ng-template>

<ng-template #galleryGroup>
  <div class="card-list__container">
    <div
      class="card-outer"
      *ngFor="let card of items; let i = index"
      (click)="handleCardClick(i, $event)"
    >
      <app-gallery-group-card
        [cardData]="card"
        [class.selected]="selectedCards.includes(i)"
      ></app-gallery-group-card>
    </div>
  </div>
</ng-template>

<ng-template #galleryDetailed>
  <div class="card-list__container">
    <div
      class="card-outer"
      *ngFor="let card of items; let i = index"
      (click)="handleCardClick(i, $event)"
    >
      <app-gallery-detailed-card
        [cardData]="card"
        [class.selected]="selectedCards.includes(i)"
      ></app-gallery-detailed-card>
    </div>
  </div>
</ng-template>
