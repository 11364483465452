/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Agency } from './agency';
import { Gallery } from './gallery';
import { MediaPreivew } from './mediaPreivew';
import { ProductPreviewSchema } from './productPreviewSchema';
import { Translatable } from './translatable';

export interface ProductItem {
  agency?: Agency;
  author?: Agency;
  currency?: string;
  caption: Array<Translatable>;
  gallery?: Gallery;
  keywords_list?: Array<Agency>;
  kind: ProductItem.KindEnum;
  notes?: string;
  preview?: MediaPreivew;
  related_items?: Array<ProductPreviewSchema>;
  headline?: Array<Translatable>;
  var_large_description?: string;
  var_large_name?: string;
  var_large_price?: string;
  var_small_description?: string;
  var_small_name?: string;
  var_small_price?: string;
}
export namespace ProductItem {
  export type KindEnum = 'PHOTO' | 'VIDEO';
  export const KindEnum = {
    PHOTO: 'PHOTO' as KindEnum,
    VIDEO: 'VIDEO' as KindEnum,
  };
}
