import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateBlock } from 'piwe-front-swagger-client';
import { BlockData } from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-data.service';
import { DoubleClickSectionObject } from '../section/section.component';

@Component({
  selector: 'app-homepage-section',
  templateUrl: './homepage-section.component.html',
  styleUrls: ['./homepage-section.component.scss'],
})
export class HomepageSectionComponent implements OnInit {
  @Input() sectionVariant!: 1 | 2 | 3 | 4 | 5 | 6;
  @Input() isSelectedInsideThisBlock: boolean = false;
  @Output() sectionDoubleClickEvent: EventEmitter<DoubleClickSectionObject> =
    new EventEmitter();
  @Input() blockData!: TemplateBlock;
  @Input() pagebuilderMode: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  handleDoubleClick(obj: DoubleClickSectionObject) {
    obj.innerIndex = obj.innerIndex - 1;

    this.sectionDoubleClickEvent.emit(obj);
  }
}
