import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  NavigationStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { AutocompleteOption } from '../shared/field-text/field-text.component';
import logging from '../logging';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterRememberingService {
  private previousUrl?: string = undefined;
  private currentUrl?: string = undefined;
  private isGalleryUrl?: boolean = undefined;
  private isProductUrl?: boolean = undefined;

  private urlStack: string[] = [];
  private _isRemembering: boolean = false;
  private _searchSubPortal?: string;
  private _searchFilters?: any;
  private _searchFilterDisplayValues: { [key: string]: string } | undefined;
  private _searchFilterAutocompleteValue?: AutocompleteOption | null;
  private _searchResults?: any[] = undefined;
  private _searchNumberOfResults?: number;
  private _searchScrollPosition?: number;
  private _searchQuery?: string;
  private _searchCurrentIndex?: number;
  private _appComponentScrollPosition?: number;

  private _galleryScrollPosition?: number;
  private _galleryResult: any = undefined;
  private _galleryNumberOfResults?: number;
  private _galleryCurrentPage?: number;
  private _gallerySortDesc?: boolean;

  private setScrollPositionSource = new Subject<{
    position: number;
    isAppComponent: boolean;
  }>();
  public setScrollPositionData: Observable<{
    position: number;
    isAppComponent: boolean;
  }> = this.setScrollPositionSource.asObservable();

  constructor(private route: ActivatedRoute, private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        logging.debug(
          'filter-remembering.service',
          `Navigation from "${this.previousUrl}" => "${this.currentUrl}"`
        );

        this.previousUrl = this.currentUrl;

        if (this.previousUrl == undefined) this.previousUrl = this.router.url;

        this.currentUrl = event.url;

        if (this._isRemembering) {
          if (this.previousUrl != this.getLastUrlInStack()) {
            if (this.currentUrl == this.getLastUrlInStack()) {
              const cachedSearchCurrentIndex =
                this._searchCurrentIndexCache[this.currentUrl];
              logging.debug(
                'filter-remembering.service',
                `Popping stack`,
                this.urlStack,
                cachedSearchCurrentIndex,
                this.isGalleryUrl,
                this.isProductUrl
              );
              this._searchCurrentIndex = cachedSearchCurrentIndex;
              this.popStack();

              if (this.isGalleryUrl) {
                this._galleryScrollPosition = undefined;
                this._galleryResult = undefined;
                this._galleryNumberOfResults = undefined;
                this._galleryCurrentPage = undefined;
                this._gallerySortDesc = undefined;
              }
            } else if (this.urlStack.includes(this.currentUrl!)) {
              const cachedSearchCurrentIndex =
                this._searchCurrentIndexCache[this.currentUrl];
              logging.debug(
                'filter-remembering.service',
                `Rewinding stack`,
                this.urlStack,
                cachedSearchCurrentIndex,
                this.isGalleryUrl,
                this.isProductUrl
              );
              this._searchCurrentIndex = cachedSearchCurrentIndex;
              this.rewindStack(this.currentUrl!);

              if (this.urlStack.length === 0) {
                this._galleryScrollPosition = undefined;
                this._galleryResult = undefined;
                this._galleryNumberOfResults = undefined;
                this._galleryCurrentPage = undefined;
                this._galleryCurrentPage = undefined;
                this._gallerySortDesc = undefined;
              }

              if (this.isGalleryUrl) {
                this._galleryScrollPosition = undefined;
                this._galleryResult = undefined;
                this._galleryNumberOfResults = undefined;
                this._galleryCurrentPage = undefined;
                this._galleryCurrentPage = undefined;
                this._gallerySortDesc = undefined;
              }
            } else {
              const cachedSearchCurrentIndex =
                this._searchCurrentIndexCache[this.currentUrl];
              logging.debug(
                'filter-remembering.service',
                `Clearing stack`,
                this.urlStack,
                cachedSearchCurrentIndex,
                this.isGalleryUrl,
                this.isProductUrl
              );
              this._searchCurrentIndex = cachedSearchCurrentIndex;
              this._searchFilters = undefined;
              this.clearStack();
            }
          } else {
            logging.debug('filter-remembering.service', `Exit case 1`);
          }
        } else {
          logging.debug(
            'filter-remembering.service',
            `Clearing stack cause not remembering`,
            this.urlStack
          );
          this._searchFilters = undefined;
          this.clearStack();
        }

        this.isGalleryUrl = undefined;
      } else if (event instanceof ActivationEnd) {
        let mapped = event as ActivationEnd;
        logging.info('filter-remembering.service.ts', mapped.snapshot);
        if (this.isGalleryUrl === undefined) {
          if (mapped.snapshot.data.isGallery) {
            logging.info('filter-remembering.service.ts', 'Is a gallery');
            this.isGalleryUrl = true;
          } else {
            logging.info('filter-remembering.service.ts', 'Not a gallery');
            this.isGalleryUrl = false;
          }
        }

        if (this.isProductUrl === undefined) {
          if (mapped.snapshot.data.isProduct) {
            logging.info('filter-remembering.service.ts', 'Is a product');
            this.isProductUrl = true;
          } else {
            logging.info('filter-remembering.service.ts', 'Not a product');
            this.isProductUrl = false;
          }
        }
      }
    });
  }

  get searchSubPortal(): string | undefined {
    return this._searchSubPortal;
  }

  set searchSubPortal(value: string | undefined) {
    this._searchSubPortal = value;
  }

  get searchQuery(): string | undefined {
    return this._searchQuery;
  }

  get isRemembering(): boolean {
    return this._isRemembering;
  }

  get searchFilters(): any {
    return this._searchFilters;
  }

  get searchFilterDisplayValues(): { [p: string]: string } | undefined {
    return this._searchFilterDisplayValues;
  }

  get searchFilterAutocompleteValue(): AutocompleteOption | undefined | null {
    return this._searchFilterAutocompleteValue;
  }

  get searchResults(): any[] | undefined {
    return this._searchResults;
  }

  get searchNumberOfResults(): number | undefined {
    return this._searchNumberOfResults;
  }

  get searchScrollPosition(): number | undefined {
    return this._searchScrollPosition;
  }

  get searchCurrentIndex(): number | undefined {
    return this._searchCurrentIndex;
  }

  set searchCurrentIndex(value: number | undefined) {
    this._searchCurrentIndex = value;
    if (this.currentUrl && value)
      this._searchCurrentIndexCache[this.currentUrl!] = value!;
  }

  get galleryResult(): any | undefined {
    return this._galleryResult;
  }

  get galleryNumberOfResults(): number | undefined {
    return this._galleryNumberOfResults;
  }

  get galleryCurrentPage(): number | undefined {
    return this._galleryCurrentPage;
  }

  get gallerySortDesc(): boolean | undefined {
    return this._gallerySortDesc;
  }

  _searchCurrentIndexCache: { [key: string]: number } = {};

  public remember(
    searchQuery: string | undefined,
    searchSubPortals: string | undefined,
    searchFilters: any | undefined,
    searchFilterDisplayValues: { [key: string]: string } | undefined,
    searchFilterAutocompleteValue: AutocompleteOption | undefined | null,
    searchResults: any[] | undefined,
    searchNumberOfResults: number | undefined
  ) {
    this._searchQuery = searchQuery;
    this._searchSubPortal = searchSubPortals;
    this._searchFilters = searchFilters;
    this._searchFilterDisplayValues = searchFilterDisplayValues;
    this._searchFilterAutocompleteValue = searchFilterAutocompleteValue;
    this._searchResults = searchResults;
    this._searchNumberOfResults = searchNumberOfResults;

    this._isRemembering = true;

    logging.debug(
      'filter-remembering.service',
      'Now remembering',
      searchFilters,
      searchFilterDisplayValues,
      searchFilterAutocompleteValue,
      searchResults,
      searchNumberOfResults
    );
  }

  public rememberGallery(
    results: any,
    numberOfResults: number,
    currentPage: number,
    sortDesc: boolean
  ) {
    this._galleryResult = results;
    this._galleryNumberOfResults = numberOfResults;
    this._galleryCurrentPage = currentPage;
    this._gallerySortDesc = sortDesc;
  }

  public rememberScrollPosition(
    scrollPosition: number,
    isAppScrollComponent: boolean
  ) {
    if (scrollPosition == 0.0 && isAppScrollComponent) {
      return;
    }

    if (isAppScrollComponent) {
      if (this.isCurrentComponentGalleryComponent()) {
        this._galleryScrollPosition = scrollPosition;

        logging.debug(
          'filter-remembering.service',
          'Now remembering gallery scroll position',
          this._galleryScrollPosition
        );

        return;
      }

      if (!this.isCurrentComponentSearchComponent()) {
        return;
      }

      this._appComponentScrollPosition = scrollPosition;
    } else {
      this._searchScrollPosition = scrollPosition;
    }
    logging.debug(
      'filter-remembering.service',
      'Now remembering scroll position',
      this._appComponentScrollPosition,
      this._searchScrollPosition
    );
  }

  private isCurrentComponentSearchComponent(): boolean {
    // brainstorming solutions
    // - use a switch statement with enabled routes
    // - use a variable that enables it or disables it via a service on component init/destroy

    const currentRoute = this.router.url.split('?')[0];

    switch (currentRoute) {
      case '/production':
      case '/video':
      case '/agency':
      case '/reuters':
      case '/stock':
      case '/agephotostock':
        return true;
      default:
        return false;
    }
  }

  private isCurrentComponentGalleryComponent(): boolean {
    return this.isGalleryUrl ?? false;
  }

  public scrollToRememberedPosition(isAppScrollComponent: boolean) {
    let position: number | undefined;

    if (isAppScrollComponent) {
      position = this._appComponentScrollPosition;
    } else {
      position = this._searchScrollPosition;
    }

    logging.debug('filter-remembering.service', 'Scrolling to ', position);

    if (position == undefined) return;

    this.setScrollPositionSource.next({
      isAppComponent: isAppScrollComponent,
      position: position,
    });
  }

  public scrollGalleryToRememberedPosition(isAppScrollComponent: boolean) {
    let position: number | undefined;

    position = this._galleryScrollPosition;

    logging.debug(
      'filter-remembering.service',
      'Scrolling gallery to ',
      position
    );

    if (position == undefined) return;

    this.setScrollPositionSource.next({
      isAppComponent: isAppScrollComponent,
      position: position,
    });
  }

  private forget() {
    this._searchQuery = undefined;
    this._searchFilters = undefined;
    this._searchFilterDisplayValues = undefined;
    this._searchFilterAutocompleteValue = null;
    this._searchResults = undefined;
    this._searchNumberOfResults = undefined;
    this._searchScrollPosition = undefined;
    this._searchCurrentIndex = undefined;
    this._searchSubPortal = undefined;
    this._searchCurrentIndexCache = {};
    this._galleryScrollPosition = undefined;
    this._galleryResult = undefined;
    this._galleryCurrentPage = undefined;
    this._galleryNumberOfResults = undefined;
    this._gallerySortDesc = undefined;

    this._isRemembering = false;

    logging.debug('filter-remembering.service', 'Forgetting');
  }

  _nextUrl?: string;

  public ignoreNextUrl(nextUrl?: string) {
    this._nextUrl = nextUrl;
  }

  public pushCurrentUrlToStack(index: number) {
    if (this.currentUrl == undefined) this.currentUrl = this.router.url;

    this._searchCurrentIndex = index;

    this.urlStack.push(this.currentUrl!);
    logging.debug(
      'filter-remembering.service',
      `Pushing to stack`,
      this.urlStack
    );
  }

  public pushToStack(url: string) {
    if (this.currentUrl == undefined) this.currentUrl = url;

    this.urlStack.push(this.currentUrl!);

    logging.debug(
      'filter-remembering.service',
      `Pushing custom to stack`,
      this.urlStack
    );
  }

  public replaceUrlToStack(url: string) {
    this.currentUrl = url;
    this.urlStack[this.urlStack.length - 1] = this.currentUrl!;
    logging.debug('filter-remembering.service', `Replace stack`, url);
  }

  private getLastUrlInStack() {
    if (!this.urlStack.length) {
      return undefined;
    }

    return this.urlStack[this.urlStack.length - 1];
  }

  private popStack() {
    this.urlStack.pop();
  }

  private rewindStack(url: string) {
    const position = this.urlStack.indexOf(url);
    if (position != undefined) {
      this.urlStack = this.urlStack.slice(0, position);
    }
  }

  private clearStack() {
    this.urlStack = [];
    this.forget();
  }
}
