import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MetaDescriptionService {
  constructor(private meta: Meta, private router: Router, private translateService: TranslateService) {
    this.setMetaTags();
  }

  private setMetaTags(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if (event.url === '/' || event.urlAfterRedirects === '/') {
        this.updateMetaDescription(this.translateService.currentLang);
      } else {
        this.removeMetaDescription();
      }
    });

    this.translateService.onLangChange.subscribe(
      (params: LangChangeEvent) => {
        this.updateMetaDescription(params.lang as any);
      }
    );
  }

  private updateMetaDescription(lang: string): void {
    if (lang === 'hr') {
      this.meta.updateTag({ name: 'description', content: 'Pixsell je vodeća hrvatska foto i video agencija koja može zadovoljiti specifične i jedinstvene potrebe svakog klijenta za foto i video materijalom.' });
    } else if (lang === 'en') {
      this.meta.updateTag({ name: 'description', content: 'Pixsell is the leading Croatian photo and video agency with capacity for satisfying each client\'s specific needs for photo and video content.' });
    }
  }

  private removeMetaDescription(): void {
    this.meta.removeTag('name="description"');
  }
}
