import { Injectable } from '@angular/core';
import {
  AutocompleteService,
  DownloadList,
  ProfileService as ApiProfileService,
  User,
} from 'piwe-front-swagger-client';
import { ProfileService } from '../../profile.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, skip, take } from 'rxjs/operators';
import { Serialization } from '../models';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SrvService {
  private profile?: any;

  private currentUserSource = new BehaviorSubject<any | undefined>(undefined);
  public currentUserData: Observable<any | undefined> =
    this.currentUserSource.asObservable();

  private countries: { [id: number]: string } = {};

  private countriesSource = new BehaviorSubject<{ [id: number]: string }>({});
  public countriesData: Observable<{ [id: number]: string }> =
    this.countriesSource.asObservable();

  constructor(
    private profileService: ProfileService,
    private apiProfileService: ApiProfileService,
    private autocompleteService: AutocompleteService,
    private router: Router
  ) {
    this.autocompleteService
      .frontGetAutocompleteCountryByTitle('', 'HR')
      .subscribe((countries) => {
        countries.forEach((country) => {
          this.countries[country.id] = country.title;
        });

        this.countriesSource.next(this.countries);

        this.profileService.profileData.subscribe((profile) => {
          if (profile == undefined) return;
          this.profile = Serialization.serializeUser(this.countries, profile);
          this.currentUserSource.next(this.profile);
        });
      });
  }

  NEWSLETTER_List: any[] = NEWSLETTER;
  USERS_List: any[] = USERS;

  getDownloads(
    page: number,
    dateFrom: string | undefined,
    dateTo: string | undefined
  ): Observable<DownloadList> {
    return this.apiProfileService.frontGetProfileDownloads(
      page,
      50,
      dateFrom,
      dateTo
    );
  }

  getCurrentUser() {
    return this.currentUserData;
  }

  getCurrentUserId() {
    return this.currentUserData.pipe(
      map((u) => {
        if (u == undefined) return undefined;

        return u.id;
      })
    );
  }

  getCountries() {
    return this.countries;
  }

  getFactures() {
    return this.apiProfileService.frontGetProfileInvoices().pipe(
      map((r) => {
        return r.invoices?.map((d) => Serialization.serializeInvoice(d)) ?? [];
      })
    );
  }

  getNewsletterForUser(userId: number) {
    return this.NEWSLETTER_List.filter((item) => {
      if (userId === item.userId) {
        return item;
      }
      return;
    });
  }

  getUsers() {
    return this.apiProfileService.frontGetProfileSubusers().pipe(
      map((r) => {
        return (
          r.users?.map((d) => Serialization.serializeUser(this.countries, d)) ??
          []
        );
      })
    );
  }

  getSingleUser(id: number) {
    return this.apiProfileService.frontGetProfileSubusers().pipe(
      map((r) => {
        return r.users
          ?.map((d) => Serialization.serializeUser(this.countries, d))
          .find((a) => a.id == id);
      })
    );
  }

  getSingleContract(id: number) {
    return this.getContractsForAccount().pipe(
      map((c) => {
        return c.find((n) => n.id == id);
      })
    );
  }

  editSingleUser(results: Map<string, string>) {
    let userId = results.get('userId');
    let name = results.get('name');
    let surname = results.get('surname');
    let rights = results.get('rights');
    let email = results.get('email');

    for (var val of this.USERS_List) {
      if (val.id == userId) {
        val.name = name;
        val.surname = surname;
        val.rights = rights;
        val.email = email;
      }
    }
  }

  editAccount(results: Map<string, string | undefined>) {
    let serverUserModel: User = Serialization.deserializeUser(results);
    this.apiProfileService
      .frontUpdateCurrentUser(serverUserModel)
      .subscribe((_) => {
        alert('Updated');
        location.reload();
      });
  }

  removeSignleUser(id: number) {
    var index = this.USERS_List.map((x) => {
      return x.id;
    }).indexOf(id);

    this.USERS_List.splice(index, 1);
  }

  updateNewsLetter(results: Map<string, boolean>) {
    let userId = this.getCurrentUserId();
    let AgencijeNews = results.get('AgencijeNews');
    let PixsellNews = results.get('PixsellNews');
    let AgencijeShow = results.get('AgencijeShow');
    let PixsellShow = results.get('PixsellShow');
    let AgencijeSport = results.get('AgencijeSport');
    let PixsellSport = results.get('PixsellSport');
    let Specijali = results.get('Specijali');
    //update newsletter list
    for (var val of this.NEWSLETTER_List) {
      if (val.userId == userId) {
        val.AgencijeNews = AgencijeNews!;
        val.PixsellNews = PixsellNews!;
        val.AgencijeShow = AgencijeShow!;
        val.PixsellShow = PixsellShow!;
        val.AgencijeSport = AgencijeSport!;
        val.PixsellSport = PixsellSport!;
        val.Specijali = Specijali!;
      }
    }
  }

  getContractsForAccount() {
    return this.apiProfileService.frontGetProfileContracts().pipe(
      map((r) => {
        return (
          r.contracts?.map((d) => Serialization.serializeContract(d)) ?? []
        );
      })
    );
  }
}

const NEWSLETTER = [
  {
    userId: 1,
    AgencijeNews: true,
    PixsellNews: false,
    AgencijeShow: false,
    PixsellShow: true,
    AgencijeSport: true,
    PixsellSport: false,
    Specijali: false,
  },
];

const USERS = [
  {
    id: 1,
    userId: 1,
    name: 'Ivan',
    surname: 'Horvat',
    email: 'ivan.horvat@gmail.com',
    rights: 'Administrator',
    profileUrl: 'assets/img/1.png',
  },
  {
    id: 2,
    userId: 1,
    name: 'Petar',
    surname: 'Perić',
    email: 'patar.peric@gmail.com',
    rights: 'Korisnik',
    profileUrl: 'assets/img/2.png',
  },
  {
    id: 3,
    userId: 1,
    name: 'Kruno',
    surname: 'Jerbić',
    email: 'kruno.jerbic@gmail.com',
    rights: 'Korisnik',
    profileUrl: 'assets/img/3.png',
  },
  {
    id: 4,
    userId: 1,
    name: 'Martina',
    surname: 'Krulić',
    email: 'martina.krulic@gmail.com',
    rights: 'Administrator',
    profileUrl: 'assets/img/4.png',
  },
  {
    id: 5,
    userId: 1,
    name: 'Marko',
    surname: 'Sabo',
    email: 'marko.sabo@gmail.com',
    rights: 'Korisnik',
    profileUrl: 'assets/img/5.png',
  },
  {
    id: 6,
    userId: 1,
    name: 'Karla',
    surname: 'Lovričević',
    email: 'karla.lovricevic@gmail.com',
    rights: 'Administrator',
    profileUrl: 'assets/img/6.png',
  },
  {
    id: 7,
    userId: 1,
    name: 'Monika',
    surname: 'Pejak',
    email: 'monika.pejak@gmail.com',
    rights: 'Korisnik',
    profileUrl: 'assets/img/7.png',
  },
];

const COUNTRIES = [
  'Hrvatska',
  'USA',
  'UK',
  'Kina',
  'Rusija',
  'Australija',
  'Njemačka',
  'Belgija',
  'Japan',
];
