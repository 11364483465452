<div class="card">
  <!-- <img src="{{ data.preview_url }}" alt="" class="card__img" /> -->
  <img src="/assets/no-image.svg" alt="" class="card__img" />
  <div class="card__text">
    <div *ngIf="!isBeingRenamed" class="card__title">{{ data.title }}</div>
    <label
      [class.none]="!isBeingRenamed"
      for="templateName"
      class="card__name-input-container"
    >
      <input
        #templateNameInput
        class="card__name-input"
        type="text"
        name="templateName"
        id="templateName"
        (blur)="disableRename()"
        (keydown.enter)="renameTemplate()"
        [(ngModel)]="templateName"
      />
    </label>
    <div class="card__timestamp">
      Last edited: {{ data.last_edit_date | date : "dd.MM.yyyy. HH:mm" }}
    </div>
    <div class="card__timestamp" *ngIf="data.is_published">
      Date published: {{ data.publish_date | date : "dd.MM.yyyy. HH:mm" }}
    </div>
  </div>
  <button class="card__button" (click)="openContextMenu()"></button>
  <div *ngIf="isContextMenuOpen">
    <div class="context-menu__wrapper" (click)="closeContextMenu()"></div>

    <ng-container [ngSwitch]="contextMenuType">
      <ng-container *ngSwitchCase="'published'">
        <ng-container *ngTemplateOutlet="contextMenuPublished"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'draft'">
        <ng-container *ngTemplateOutlet="contextMenuDraft"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'aboutUs'">
        <ng-container *ngTemplateOutlet="contextMenuAboutUs"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="card__darken"></div>
</div>

<ng-template #contextMenuPublished>
  <ul class="context-menu">
    <li>
      <button (click)="editTemplate()">Edit</button>
    </li>
    <li>
      <button (click)="duplicateTemplate()">Duplicate</button>
    </li>
  </ul>
</ng-template>

<ng-template #contextMenuDraft>
  <ul class="context-menu">
    <li>
      <button (click)="editTemplate()">Edit</button>
    </li>
    <li>
      <button (click)="publishTemplate()">Publish</button>
    </li>
    <li>
      <button (click)="enableRename()">Rename</button>
    </li>
    <li>
      <button (click)="duplicateTemplate()">Duplicate</button>
    </li>
    <li>
      <button (click)="openActivityLog()">Activity log</button>
    </li>
    <li>
      <button (click)="deleteTemplate()">Delete</button>
    </li>
  </ul>
</ng-template>

<ng-template #contextMenuAboutUs>
  <ul class="context-menu">
    <li>
      <button (click)="editTemplate()">Edit</button>
    </li>
    <li>
      <button (click)="openActivityLog()">Activity log</button>
    </li>
  </ul>
</ng-template>
