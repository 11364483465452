<div class="card-custom">
  <div [ngSwitch]="cardData.thumbnails.length">
    <div *ngSwitchCase="1" class="card-custom__image--1">
      <img src="{{ cardData.thumbnails[0] }}" alt="" />
    </div>
    <div *ngSwitchCase="2" class="card-custom__image--2">
      <img src="{{ cardData.thumbnails[0] }}" alt="" />
      <img src="{{ cardData.thumbnails[1] }}" alt="" />
    </div>
    <div *ngSwitchDefault class="card-custom__image--3">
      <img src="{{ cardData.thumbnails[0] }}" alt="" />
      <img src="{{ cardData.thumbnails[1] }}" alt="" />
      <img src="{{ cardData.thumbnails[2] }}" alt="" />
    </div>
  </div>
  <div class="card-custom__title">
    {{ cardData.title || cardData.headline }}
  </div>
</div>
