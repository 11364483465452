/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Company } from './company';
import { ContractLine } from './contractLine';
import { User } from './user';

export interface Contract {
  id?: number;
  title?: string;
  valid_from?: string;
  valid_to?: string;
  contract_lines?: Array<ContractLine>;
  status?: Contract.StatusEnum;
  company?: Company;
  user?: User;
  currency?: string;
  currency_id?: number;
}
export namespace Contract {
  export type StatusEnum = 'ACTIVE' | 'INACTIVE';
  export const StatusEnum = {
    ACTIVE: 'ACTIVE' as StatusEnum,
    INACTIVE: 'INACTIVE' as StatusEnum,
  };
}
