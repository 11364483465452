/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AddToCart {
  product_id: string;
  variation: AddToCart.VariationEnum;
  download_with_author_signature?: boolean;
  source?: AddToCart.SourceEnum;
}
export namespace AddToCart {
  export type VariationEnum = 'PRINT' | 'WEB';
  export const VariationEnum = {
    PRINT: 'PRINT' as VariationEnum,
    WEB: 'WEB' as VariationEnum,
  };
  export type SourceEnum = 'PIXSELL' | 'THP_PHOTO_SERVICE';
  export const SourceEnum = {
    PIXSELL: 'PIXSELL' as SourceEnum,
    THPPHOTOSERVICE: 'THP_PHOTO_SERVICE' as SourceEnum,
  };
}
