/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CartPaymentGatewayResponse {
  payment_method?: CartPaymentGatewayResponse.PaymentMethodEnum;
  status?: CartPaymentGatewayResponse.StatusEnum;
  wspays_shopping_cart_id?: string;
  wspays_eci?: string;
  /**
   * alphanumeric field that indicates the sequence of transaction
   */
  wspay_stan?: string;
  wspay_pay_order_id?: string;
  paypal_order_id?: string;
  error_message?: string;
  partner?: CartPaymentGatewayResponse.PartnerEnum;
}
export namespace CartPaymentGatewayResponse {
  export type PaymentMethodEnum = 'CARD' | 'KEKS_PAY' | 'PAYPAL' | 'DOWNLOAD';
  export const PaymentMethodEnum = {
    CARD: 'CARD' as PaymentMethodEnum,
    KEKSPAY: 'KEKS_PAY' as PaymentMethodEnum,
    PAYPAL: 'PAYPAL' as PaymentMethodEnum,
    DOWNLOAD: 'DOWNLOAD' as PaymentMethodEnum,
  };
  export type StatusEnum = 'SUCCESS' | 'ERROR' | 'CANCEL' | 'FAILURE';
  export const StatusEnum = {
    SUCCESS: 'SUCCESS' as StatusEnum,
    ERROR: 'ERROR' as StatusEnum,
    CANCEL: 'CANCEL' as StatusEnum,
    FAILURE: 'FAILURE' as StatusEnum,
  };
  export type PartnerEnum = 'CARD' | 'PAYPAL' | 'KEKS_PAY' | 'AIRCASH';
  export const PartnerEnum = {
    CARD: 'CARD' as PartnerEnum,
    PAYPAL: 'PAYPAL' as PartnerEnum,
    KEKSPAY: 'KEKS_PAY' as PartnerEnum,
    AIRCASH: 'AIRCASH' as PartnerEnum,
  };
}
