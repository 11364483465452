/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CartPaymentGatewayRequest {
  wspay_shop_id?: string;
  wspay_shopping_cart_id?: string;
  wspay_signature?: string;
  pp_payment_id?: string;
  pp_redirect_url?: string;
  total_amount?: number;
  currency?: CartPaymentGatewayRequest.CurrencyEnum;
  int_amount?: number;
  int_currency?: string;
  lang?: CartPaymentGatewayRequest.LangEnum;
}
export namespace CartPaymentGatewayRequest {
  export type CurrencyEnum = 'HRK' | 'EUR';
  export const CurrencyEnum = {
    HRK: 'HRK' as CurrencyEnum,
    EUR: 'EUR' as CurrencyEnum,
  };
  export type LangEnum = 'EN' | 'HR';
  export const LangEnum = {
    EN: 'EN' as LangEnum,
    HR: 'HR' as LangEnum,
  };
}
