import { AgencyTagInputComponent } from '../cms/desk-administration/agencies/agency-tag-input/agency-tag-input.component';
import { AutocompletePipe } from './autocomplete.pipe';
import { AutocompleteTagsPipe } from '../autocomplete-tags.pipe';
import { ButtonNewComponent } from './button-new/button-new.component';
import { CmsSearchComponent } from './cms/cms-search/cms-search.component';
import { CommonModule } from '@angular/common';
import { ContextMenuOverlayComponent } from './context-menu-overlay/context-menu-overlay.component';
import { ContractSingleComponent } from './cms/contract-single/contract-single.component';
import { CreateGalleryEmptyComponent } from './cms/create-gallery-empty/create-gallery-empty.component';
import { DigitOnlyDirective } from './digit-only.directive';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { DropzoneDirective } from './dropzone.directive';
import { DropzoneProgressComponent } from './dropzone-progress/dropzone-progress.component';
import { ExpansionPanelComponent } from './search/expansion-panel/expansion-panel.component';
import { ExpansionPanelHeaderComponent } from './search/expansion-panel/expansion-panel-header/expansion-panel-header.component';
import { FancyGridComponent } from './fancy-grid/fancy-grid.component';
import { FieldCheckboxComponent } from './field-checkbox/field-checkbox.component';
import { FieldPriceComponent } from './field-price/field-price.component';
import { FieldRadioComponent } from './field-radio/field-radio.component';
import { FieldTagsComponent } from './field-tags/field-tags.component';
import { FieldTextareaComponent } from './field-textarea/field-textarea.component';
import { FieldTextComponent } from './field-text/field-text.component';
import { FilterSidebarComponent } from './search/filter-sidebar/filter-sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryCardComponent } from './cms/gallery-card/gallery-card.component';
import { GalleryDetailedCardComponent } from './cms/gallery-detailed-card/gallery-detailed-card.component';
import { GalleryGroupCardComponent } from './cms/gallery-group-card/gallery-group-card.component';
import { GalleryPreviewComponent } from './cms/gallery-preview/gallery-preview.component';
import { ImportItemHeadlinePipe } from './import-item-headline.pipe';
import { LocationHeaderComponent } from './cms/location-header/location-header.component';
import { MainPreviewGalleryItemComponent } from './main-preview-gallery-item/main-preview-gallery-item.component';
import { MainPreviewItemComponent } from './main-preview-item/main-preview-item.component';
import { NgModule } from '@angular/core';
import { PaginationComponent } from './cms/pagination/pagination.component';
import { PreviewItemFlexPipe } from './preview-item-flex.pipe';
import { RadioInputComponent } from './radio-input/radio-input.component';
import { SearchBarComponent } from './search/search-bar/search-bar.component';
import { SearchBarSoloComponent } from './search-bar-solo/search-bar-solo.component';
import { SearchComponent } from './search/search.component';
import { SidebarAddComponent } from './cms/sidebar-add/sidebar-add.component';
import { SidebarAutocompleteCheckboxComponent } from './cms/sidebar/inputs/sidebar-autocomplete-checkbox/sidebar-autocomplete-checkbox.component';
import { SidebarAutocompleteComponent } from './cms/sidebar/inputs/sidebar-autocomplete/sidebar-autocomplete.component';
import { SidebarCaptionComponent } from './cms/sidebar/inputs/sidebar-caption/sidebar-caption.component';
import { SidebarCheckboxListComponent } from './cms/sidebar/inputs/sidebar-checkbox-list/sidebar-checkbox-list.component';
import { SidebarComponent } from './cms/sidebar/sidebar.component';
import { SidebarTagsComponent } from './cms/sidebar/inputs/sidebar-tags/sidebar-tags.component';
import { SidebarTextComponent } from './cms/sidebar/inputs/sidebar-text/sidebar-text.component';
import { SingleAgencySearchComponent } from './single-agency-search/single-agency-search.component';
import { SingleCmsInputComponent } from './cms/single-cms-input/single-cms-input.component';
import { TableComponent } from './table/table.component';
import { TransformingInputComponent } from './cms/transforming-input/transforming-input.component';
import { YesNoPipe } from './yes-no.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FieldWysiwygComponent } from './field-wysiwyg/field-wysiwyg.component';
import { MainPreviewGalleryGroupItemComponent } from './main-preview-gallery-group-item/main-preview-gallery-group-item.component';
import { MultipleItemEditorComponent } from './multiple-item-editor/multiple-item-editor.component';
import { PixsellPreviewUrlPipe } from './pixsell-preview-url.pipe';
import { RemoteApiAssetPipe } from './remote-api-asset.pipe';
import { TextareaAutoresizeDirective } from '../textarea-autoresize.directive';
import { TranslatablePipe } from './translatable.pipe';
import { ImageSideRightComponent } from './image-side-right/image-side-right.component';
import { GalleryCartComponent } from './gallery-cart/gallery-cart.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ScheduledPublishDialogComponent } from './scheduled-publish-dialog/scheduled-publish-dialog.component';
import { ImportButtonComponent } from './import-button/import-button.component';
import { SliderBigComponent } from './slider-switch/slider-big.component';
import { GalleryCartDownloadsComponent } from './gallery-cart/gallery-cart-downloads/gallery-cart-downloads.component';
import { PageCardComponent } from './cms/web-administration/page-card/page-card.component';
import { SliderSwitchComponent } from './slider-switch/slider-switch.component';
import { BlockComponent } from './homepage-template-parts/block/block.component';
import { SingleImageComponent } from './single-image/single-image.component';
import { SearchBarHomeComponent } from './homepage-template-parts/search-bar-home/search-bar-home.component';
import { HomepageSectionTitleComponent } from './homepage-template-parts/sections/section-components/homepage-section-title/homepage-section-title.component';
import { HomepageSectionBigComponent } from './homepage-template-parts/sections/section-components/homepage-section-big/homepage-section-big.component';
import { HomepageSectionContactComponent } from './homepage-template-parts/sections/section-components/homepage-section-contact/homepage-section-contact.component';
import { HomepageSectionGalleryComponent } from './homepage-template-parts/sections/section-components/homepage-section-gallery/homepage-section-gallery.component';
import { HomepageSectionDetailedComponent } from './homepage-template-parts/sections/section-components/homepage-section-detailed/homepage-section-detailed.component';
import { HomepageSectionDetailedSmallComponent } from './homepage-template-parts/sections/section-components/homepage-section-detailed-small/homepage-section-detailed-small.component';
import { HomepageSectionComponent } from './homepage-template-parts/sections/homepage-section/homepage-section.component';
import { SectionComponent } from './homepage-template-parts/sections/section/section.component';
import { BlockContainerComponent } from './homepage-template-parts/block-container/block-container.component';
import { SectionButtonComponent } from './homepage-template-parts/sections/section-button/section-button.component';
import { HomepageSectionGallerySimpleComponent } from './homepage-template-parts/sections/section-components/homepage-section-gallery-simple/homepage-section-gallery-simple.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { DeskAdministrationTransformingFormsComponent } from './cms/desk-administration-transforming-forms/desk-administration-transforming-forms.component';
import { TableSelectableComponent } from './cms/table-selectable/table-selectable.component';
import { RouterModule } from '@angular/router';
import { MultitabHeaderComponent } from './cms/multitab-header/multitab-header.component';
import { PhotojournalistRowComponent } from './photojournalist-row/photojournalist-row.component';
import { HorizontalScrollComponent } from './horizontal-scroll/horizontal-scroll.component';
import { HorizontalScrollSlideComponent } from './horizontal-scroll/horizontal-scroll-slide/horizontal-scroll-slide.component';
import { FluidGalleryMComponent } from './fluid-gallery-m/fluid-gallery-m.component';
import { UslugeComponent } from './usluge/usluge.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CurrencyContainerComponent } from './currency-container/currency-container.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ModalComponent } from './modal/modal.component';
import { PaginationDetailedComponent } from './pagination-detailed/pagination-detailed.component';
import { DigitalExhibitionComponent } from './digital-exhibition/digital-exhibition.component';
import { ToNumberPipe } from './to-number.pipe';
import { AsAutocompleteOptionPipe } from './as-autocomplete-option.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateImageTagsPipe } from '../translate-image-tags.pipe';
import { SearchMoreBlockComponent } from './search-more-block/search-more-block.component';
import { DynamicPriceComponent } from './dynamic-price/dynamic-price.component';
import { DynamicPricePipe } from './dynamic-price.pipe';
import { BooleanPipe } from './boolean.pipe';

@NgModule({
  declarations: [
    AgencyTagInputComponent,
    AutocompletePipe,
    AutocompleteTagsPipe,
    BlockComponent,
    BlockContainerComponent,
    ButtonNewComponent,
    CmsSearchComponent,
    ContextMenuOverlayComponent,
    ContractSingleComponent,
    CreateGalleryEmptyComponent,
    DeskAdministrationTransformingFormsComponent,
    DigitOnlyDirective,
    DropzoneComponent,
    DropzoneDirective,
    DropzoneProgressComponent,
    ExpansionPanelComponent,
    ExpansionPanelHeaderComponent,
    FancyGridComponent,
    FieldCheckboxComponent,
    FieldPriceComponent,
    FieldRadioComponent,
    FieldTagsComponent,
    FieldTextComponent,
    FieldTextareaComponent,
    FieldWysiwygComponent,
    FilterSidebarComponent,
    GalleryCardComponent,
    GalleryCartComponent,
    GalleryCartDownloadsComponent,
    GalleryDetailedCardComponent,
    GalleryGroupCardComponent,
    GalleryPreviewComponent,
    HomepageSectionBigComponent,
    HomepageSectionComponent,
    HomepageSectionContactComponent,
    HomepageSectionDetailedComponent,
    HomepageSectionDetailedSmallComponent,
    HomepageSectionGalleryComponent,
    HomepageSectionGallerySimpleComponent,
    HomepageSectionTitleComponent,
    ImageSideRightComponent,
    ImportButtonComponent,
    ImportItemHeadlinePipe,
    LocationHeaderComponent,
    MainPreviewGalleryGroupItemComponent,
    MainPreviewGalleryItemComponent,
    MainPreviewItemComponent,
    MultipleItemEditorComponent,
    PageCardComponent,
    PaginationComponent,
    PixsellPreviewUrlPipe,
    PreviewItemFlexPipe,
    RadioInputComponent,
    RemoteApiAssetPipe,
    ScheduledPublishDialogComponent,
    SearchBarComponent,
    SearchBarHomeComponent,
    SearchBarSoloComponent,
    SearchComponent,
    SectionButtonComponent,
    SectionComponent,
    SidebarAddComponent,
    SidebarAutocompleteCheckboxComponent,
    SidebarAutocompleteComponent,
    SidebarCaptionComponent,
    SidebarCheckboxListComponent,
    SidebarComponent,
    SidebarTagsComponent,
    SidebarTextComponent,
    SingleAgencySearchComponent,
    SingleCmsInputComponent,
    SingleImageComponent,
    SliderBigComponent,
    SliderSwitchComponent,
    SnackBarComponent,
    TableComponent,
    TextareaAutoresizeDirective,
    TransformingInputComponent,
    TranslatablePipe,
    YesNoPipe,
    ImageSideRightComponent,
    GalleryCartComponent,
    ScheduledPublishDialogComponent,
    ImportButtonComponent,
    SliderBigComponent,
    GalleryCartDownloadsComponent,
    PageCardComponent,
    SliderSwitchComponent,
    BlockComponent,
    SingleImageComponent,
    SearchBarHomeComponent,
    HomepageSectionTitleComponent,
    HomepageSectionBigComponent,
    HomepageSectionContactComponent,
    HomepageSectionGalleryComponent,
    HomepageSectionDetailedComponent,
    HomepageSectionDetailedSmallComponent,
    HomepageSectionComponent,
    SectionComponent,
    BlockContainerComponent,
    SectionButtonComponent,
    HomepageSectionGallerySimpleComponent,
    SnackBarComponent,
    DeskAdministrationTransformingFormsComponent,
    TableSelectableComponent,
    MultitabHeaderComponent,
    HorizontalScrollComponent,
    PhotojournalistRowComponent,
    HorizontalScrollSlideComponent,
    FluidGalleryMComponent,
    UslugeComponent,
    CurrencyContainerComponent,
    SpinnerComponent,
    ModalComponent,
    PaginationDetailedComponent,
    DigitalExhibitionComponent,
    ToNumberPipe,
    AsAutocompleteOptionPipe,
    TranslateImageTagsPipe,
    SearchMoreBlockComponent,
    DynamicPriceComponent,
    DynamicPricePipe,
    BooleanPipe,
  ],
  imports: [
    CKEditorModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    RouterModule,
    InfiniteScrollModule,
    TranslateModule,
  ],
  exports: [
    AgencyTagInputComponent,
    AutocompletePipe,
    AutocompleteTagsPipe,
    BlockComponent,
    BlockContainerComponent,
    ButtonNewComponent,
    CmsSearchComponent,
    ContextMenuOverlayComponent,
    ContractSingleComponent,
    CreateGalleryEmptyComponent,
    DeskAdministrationTransformingFormsComponent,
    DigitOnlyDirective,
    DropzoneComponent,
    DropzoneDirective,
    DropzoneProgressComponent,
    ExpansionPanelComponent,
    ExpansionPanelHeaderComponent,
    FancyGridComponent,
    FieldCheckboxComponent,
    FieldPriceComponent,
    FieldRadioComponent,
    FieldTagsComponent,
    FieldTextComponent,
    FieldTextareaComponent,
    FieldWysiwygComponent,
    FilterSidebarComponent,
    GalleryCardComponent,
    GalleryCartComponent,
    GalleryDetailedCardComponent,
    GalleryGroupCardComponent,
    GalleryPreviewComponent,
    HomepageSectionComponent,
    ImageSideRightComponent,
    ImportButtonComponent,
    ImportItemHeadlinePipe,
    LocationHeaderComponent,
    MainPreviewGalleryGroupItemComponent,
    MainPreviewGalleryItemComponent,
    MainPreviewItemComponent,
    MultipleItemEditorComponent,
    PageCardComponent,
    PaginationComponent,
    PixsellPreviewUrlPipe,
    PreviewItemFlexPipe,
    RadioInputComponent,
    RemoteApiAssetPipe,
    ScheduledPublishDialogComponent,
    SearchBarComponent,
    SearchBarHomeComponent,
    SearchBarSoloComponent,
    SearchComponent,
    SidebarAddComponent,
    SidebarAutocompleteCheckboxComponent,
    SidebarAutocompleteComponent,
    SidebarCaptionComponent,
    SidebarCheckboxListComponent,
    SidebarComponent,
    SidebarTagsComponent,
    SidebarTextComponent,
    SingleAgencySearchComponent,
    SingleCmsInputComponent,
    SingleImageComponent,
    SliderBigComponent,
    SliderSwitchComponent,
    SnackBarComponent,
    TableComponent,
    TextareaAutoresizeDirective,
    TransformingInputComponent,
    TranslatablePipe,
    YesNoPipe,
    ImageSideRightComponent,
    GalleryCartComponent,
    ScheduledPublishDialogComponent,
    ImportButtonComponent,
    SliderBigComponent,
    PageCardComponent,
    SliderSwitchComponent,
    SingleImageComponent,
    BlockComponent,
    SearchBarHomeComponent,
    HomepageSectionComponent,
    BlockContainerComponent,
    SnackBarComponent,
    DeskAdministrationTransformingFormsComponent,
    TableSelectableComponent,
    MultitabHeaderComponent,
    HorizontalScrollComponent,
    PhotojournalistRowComponent,
    FluidGalleryMComponent,
    UslugeComponent,
    CurrencyContainerComponent,
    SpinnerComponent,
    ModalComponent,
    PaginationDetailedComponent,
    DigitalExhibitionComponent,
    TranslateModule,
    TranslateImageTagsPipe,
    SearchMoreBlockComponent,
    DynamicPriceComponent,
    BooleanPipe,
  ],
})
export class SharedModule {}
