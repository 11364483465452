import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HomepageTemplateItem,
  HomepageTemplateItemResponse,
  HomepageTemplateListResponse,
  WebAdministrationService,
} from 'piwe-front-swagger-client';
import { TemplateBlock } from 'projects/piwe-front-swagger-client/src';
import { Observable } from 'rxjs';
import { BlockData } from './homepage-creator/homepage-creator-data.service';

export interface IHomepageTemplate {
  id?: number;
  is_published: boolean;
  page_content: BlockData[];
  title?: string;
  preview_url?: string;
  last_edit_date?: string;
  publish_date?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HomepageAdministrationService {
  private homepageAdministrationTemplatesUrl =
    'http://localhost:3000/homepage_administration_templates';

  constructor(
    private http: HttpClient,
    private apiWebAdministrationService: WebAdministrationService,
    private router: Router
  ) {}

  getTemplateList(): Observable<HomepageTemplateListResponse> {
    return this.apiWebAdministrationService.frontGetHomepageTemplateList();
  }

  cmsGetTemplateList(): Observable<HomepageTemplateListResponse> {
    return this.apiWebAdministrationService.cmsGetHomepageTemplateList();
  }

  getTemplate(id: number): Observable<HomepageTemplateItemResponse> {
    return this.apiWebAdministrationService.cmsGetHomepageTemplate(id);
  }
  postTemplate(
    pageContent: TemplateBlock[],
    isPublished: boolean,
    isPublishable: boolean
  ) {
    //quick and dirty
    const routeArr = this.router.url.split('/');
    const id = routeArr[routeArr.length - 1];

    const body: HomepageTemplateItem = {
      blocks: pageContent,
      is_published: isPublished,
      is_publishable: isPublishable,
    };
    //if there is no id, it means its a new template, if an id exists it edits an existing one
    if (id === 'new') {
      return this.apiWebAdministrationService.cmsPostHomepageTemplate(body);
    } else {
      return this.apiWebAdministrationService.cmsPutHomepageTemplate(
        parseInt(id),
        body
      );
    }
  }
  deleteTemplate(id: number) {
    return this.apiWebAdministrationService.cmsDeleteTemplate(id);
  }
  patchTemplate() {
    return this.apiWebAdministrationService.cmsPostHomepageTemplate();
  }
  duplicateTemplate(id: number) {
    return this.apiWebAdministrationService.cmsDuplicateTemplate(id);
  }
  getActivityLog() {}

  publishTemplate(id: number) {
    return this.apiWebAdministrationService.cmsPublishTemplate(id);
  }
  renameTemplate(id: number, name: string) {
    const body = {
      name: name,
    };
    return this.apiWebAdministrationService.cmsRenameTemplate(id, body);
  }
}
