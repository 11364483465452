import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { selectDeselectAnimation } from '../../block-container/block-container.component';

@Component({
  selector: 'app-section-button',
  templateUrl: './section-button.component.html',
  styleUrls: ['./section-button.component.scss'],
  animations: [selectDeselectAnimation],
})
export class SectionButtonComponent implements OnInit {
  @Input() isSelected: boolean = false;
  @Output() doubleClickEvent: EventEmitter<void> = new EventEmitter();

  @Input() pagebuilderMode: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  buttonDoubleClicked() {
    this.doubleClickEvent.emit();
  }
}
