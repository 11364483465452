import { HttpRequest } from '@angular/common/http';

function escapeStringWindows(str: string) {
  return (
    '"' +
    str
      .replace(/"/g, '""')
      .replace(/%/g, '"%"')
      .replace(/\\/g, '\\\\')
      .replace(/[\r\n]+/g, '"^$&"') +
    '"'
  );
}

function escapeStringPosix(str: string) {
  function escapeCharacter(x: string) {
    let code: number = x.charCodeAt(0);
    if (code < 256) {
      // Add leading zero when needed to not care about the next character.
      return code < 16 ? '\\x0' + code.toString(16) : '\\x' + code.toString(16);
    }
    let codeStr = code.toString(16);
    return '\\u' + ('0000' + codeStr).substr(codeStr.length, 4);
  }

  if (/[^\x20-\x7E]|\'/.test(str)) {
    // Use ANSI-C quoting syntax.
    return (
      "$'" +
      str
        .replace(/\\/g, '\\\\')
        .replace(/\'/g, "\\'")
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/[^\x20-\x7E]/g, escapeCharacter) +
      "'"
    );
  } else {
    // Use single quote syntax.
    return "'" + str + "'";
  }
}

export function toCurl(request: HttpRequest<any>) {
  let platform: string = 'posix';
  let url = new URL(request.url);
  let method = request.method;
  let path = url.pathname;

  let command = ['curl'],
    ignoredHeaders = ['host', 'method', 'path', 'scheme', 'version'],
    escapeString = platform === 'win' ? escapeStringWindows : escapeStringPosix,
    requestMethod = 'GET',
    data = [],
    requestHeaders = request.headers,
    requestBody = request.serializeBody() as string,
    contentType = requestHeaders.get('content-type');
  console.debug('Request headers', requestHeaders);

  command.push(escapeString(request.url).replace(/[[{}\]]/g, '\\$&'));

  if (requestBody !== null && requestBody !== '') {
    ignoredHeaders.push('content-length');
    requestMethod = 'POST';

    if (
      contentType &&
      contentType.startsWith('application/x-www-form-urlencoded')
    ) {
      data.push('--data');
    } else {
      data.push('--data-binary');
    }

    data.push(escapeString(requestBody));
  }

  if (method !== requestMethod) {
    command.push('-X');
    command.push(method);
  }

  requestHeaders
    .keys()
    .filter((name) => ignoredHeaders.indexOf(name) === -1)
    .forEach(function (name) {
      command.push('-H');
      command.push(
        escapeString(name.replace(/^:/, '') + ': ' + requestHeaders.get(name))
      );
    });

  command = command.concat(data);
  command.push('--compressed');

  return command.join(' ');
}
