import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDatePickerConfig } from 'ng2-date-picker';
import { Subscription } from 'rxjs';

export enum FieldType {
  Text = 1,
  Select,
  Date,
  Search,
  DateNoX,
  Autocomplete,
  DateSingle,
}

export interface IFieldOption {
  labelName: string;
  devName: string;
  type: FieldType;
  selectOptions?: { [key: string]: string };
  //search for getAutocompleteQuery to find all of the autocomplete types
  autocompleteType?: string | undefined;
}

@Component({
  selector: 'app-cms-search',
  templateUrl: './cms-search.component.html',
  styleUrls: ['./cms-search.component.scss'],
})
export class CmsSearchComponent implements OnInit {
  @Input() hasSearch: boolean = false;
  @Input() fieldOptions: IFieldOption[] = [];

  public formData: any = {};
  private formSubscription: Subscription = new Subscription();

  public params: any = {};
  private paramsSubscription: Subscription = new Subscription();

  dtConfig: IDatePickerConfig = {
    format: 'YYYY-MM-DD',
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParams.subscribe((params) => {
      this.params = params;
    });

    this.populateForm(this.fieldOptions);
    this.formSubscription = this.searchForm.valueChanges.subscribe(
      (data: any) => {
        this.formData = data;
      }
    );
    for (const property in this.params) {
      this.searchForm.controls[property].setValue(this.params[property]);
    }
  }
  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
  }

  searchForm = this.formBuilder.group({});

  populateForm(array: IFieldOption[]) {
    array.forEach((element: IFieldOption) => {
      if (element.devName === 'date') {
        this.searchForm.addControl('fromDate', this.formBuilder.control(''));
        this.searchForm.addControl('toDate', this.formBuilder.control(''));
      }
      this.searchForm.addControl(element.devName, this.formBuilder.control(''));
    });
    if (this.hasSearch)
      this.searchForm.addControl('q', this.formBuilder.control(''));
  }

  resetFromDateInput() {
    this.searchForm.get('fromDate')?.setValue('');
  }
  resetToDateInput() {
    this.searchForm.get('toDate')?.setValue('');
  }

  resetDateSingleInput(controlName: string) {
    this.searchForm.get(controlName)?.setValue('');
  }

  onSubmit() {
    const queryData = getChangedFormData(this.formData);

    this.router.navigate([], {
      queryParams: queryData,
      relativeTo: this.route,
    });

    function getChangedFormData(obj: any) {
      const resultObj: any = {};
      for (const property in obj) {
        if (obj[property] !== '') resultObj[property] = obj[property];
      }
      return resultObj;
    }
  }

  autocompleteInputValueChange(autocompleteObj: any, devName: string) {
    this.searchForm.get(devName)?.setValue(autocompleteObj.title);
  }
}
