<label for="{{ controlName }}">{{ (isRequired ? "* " : "") + label }}</label>
<div [formGroup]="form">
  <input
    #tagInput
    type="text"
    name="{{ controlName }}"
    id="{{ controlName }}"
    formControlName="{{ controlName }}"
    minlength="3"
    (keydown.enter)="handleEnter()"
    [class.input--expanded]="tags.size"
  />

  <div class="tag-container" *ngIf="tags.size">
    <div *ngFor="let tag of tags">
      <div class="tag">
        {{ tag }} <button class="tag-btn" (click)="removeTag(tag)"></button>
      </div>
    </div>
  </div>
</div>
