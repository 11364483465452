import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-button-new',
  templateUrl: './button-new.component.html',
  styleUrls: ['./button-new.component.scss'],
})
export class ButtonNewComponent implements OnInit {
  @Input() navigateTo: string = 'new';
  @Input() state: Object = {};
  @Input() action: 'navigate' | 'emit' = 'navigate';
  @Output() buttonPressed: EventEmitter<void> = new EventEmitter();

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}
  addNew() {
    if (this.action === 'navigate')
      [
        this.router.navigate([this.navigateTo], {
          relativeTo: this.route,
          state: { items: this.state },
        }),
      ];
    else if (this.action === 'emit') {
      this.buttonPressed.emit();
    }
  }
}
