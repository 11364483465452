import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ProductPreview, Translatable } from 'piwe-front-swagger-client';
import { Subscription } from 'rxjs';
import { HomepageCreatorDataService } from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-data.service';

@Component({
  selector: 'app-homepage-section-detailed-small',
  templateUrl: './homepage-section-detailed-small.component.html',
  styleUrls: ['./homepage-section-detailed-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageSectionDetailedSmallComponent implements OnInit {
  @Input() mediaData!: ProductPreview | null;

  public productTitle!: string;
  public productTitleEn!: string;

  public noImgSectionUrl: string = '';

  @Input() pagebuilderMode: boolean = true;

  public currentLanguage = 'hr';
  private currentLanguageSubscription = new Subscription();

  constructor(
    private homepageCreatorDataService: HomepageCreatorDataService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.noImgSectionUrl = this.homepageCreatorDataService.noImgSectionUrl;

    this.productTitle =
      this.mediaData?.title?.find((t: Translatable) => t.language == 'HR')
        ?.content ?? '';

    this.productTitleEn =
      this.mediaData?.title?.find((t: Translatable) => t.language == 'EN')
        ?.content ?? '';

    //translation
    this.currentLanguage = this.translateService.currentLang;
    this.currentLanguageSubscription =
      this.translateService.onLangChange.subscribe(
        (params: LangChangeEvent) => {
          this.currentLanguage = params.lang;
        }
      );
  }

  ngOnDestroy() {
    this.currentLanguageSubscription.unsubscribe();
  }
}
