import { Pipe, PipeTransform } from '@angular/core';
import { AutocompleteOption } from './field-text/field-text.component';

@Pipe({
  name: 'asAutocompleteOption',
})
export class AsAutocompleteOptionPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): AutocompleteOption {
    return {
      title: value as string,
      value: -1,
      isSelectable: false,
      displayTitle: value as string,
    };
  }
}
