/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CmsEditingUser {
  id?: number;
  username?: string;
  first_name?: string;
  last_name?: string;
  name?: string;
  email?: string;
  password?: string;
  state?: CmsEditingUser.StateEnum;
  kind?: CmsEditingUser.KindEnum;
  company_id?: number;
  company_name?: string;
  company_role?: CmsEditingUser.CompanyRoleEnum;
  old_flag_can_see_image_only_agencies?: number;
  /**
   * What kind of stuff the can download
   */
  download_type_permissions?: Array<CmsEditingUser.DownloadTypePermissionsEnum>;
  send_to_system?: boolean;
  send_to_system_ftp_path?: string;
  send_to_cms?: boolean;
  send_to_cms_api_key?: string;
  send_to_cms_url?: string;
  city?: string;
  country_id?: number;
  currency_id?: number;
  phone_number?: string;
  mobile_number?: string;
  press_magazine_name?: string;
  agreement_date?: Date;
  gdpr_confirmed_date?: Date;
  metrics_confirmed_date?: Date;
  created_at?: Date;
  user_permissions?: Array<CmsEditingUser.UserPermissionsEnum>;
  user_group_id?: number;
  note?: string;
}
export namespace CmsEditingUser {
  export type StateEnum =
    | 'UNCONFIRMED_IDENTITY'
    | 'PENDING_APPROVAL'
    | 'APPROVED'
    | 'DELETED';
  export const StateEnum = {
    UNCONFIRMEDIDENTITY: 'UNCONFIRMED_IDENTITY' as StateEnum,
    PENDINGAPPROVAL: 'PENDING_APPROVAL' as StateEnum,
    APPROVED: 'APPROVED' as StateEnum,
    DELETED: 'DELETED' as StateEnum,
  };
  export type KindEnum = 'SMALL' | 'CORPORATE';
  export const KindEnum = {
    SMALL: 'SMALL' as KindEnum,
    CORPORATE: 'CORPORATE' as KindEnum,
  };
  export type CompanyRoleEnum = 'OWNER' | 'ADMIN' | 'USER';
  export const CompanyRoleEnum = {
    OWNER: 'OWNER' as CompanyRoleEnum,
    ADMIN: 'ADMIN' as CompanyRoleEnum,
    USER: 'USER' as CompanyRoleEnum,
  };
  export type DownloadTypePermissionsEnum =
    | 'PHOTO'
    | 'PHOTO_LARGE'
    | 'PHOTO_LARGE_SIGNATURE'
    | 'PHOTO_SMALL'
    | 'PHOTO_SMALL_SIGNATURE'
    | 'VIDEO';
  export const DownloadTypePermissionsEnum = {
    PHOTO: 'PHOTO' as DownloadTypePermissionsEnum,
    PHOTOLARGE: 'PHOTO_LARGE' as DownloadTypePermissionsEnum,
    PHOTOLARGESIGNATURE: 'PHOTO_LARGE_SIGNATURE' as DownloadTypePermissionsEnum,
    PHOTOSMALL: 'PHOTO_SMALL' as DownloadTypePermissionsEnum,
    PHOTOSMALLSIGNATURE: 'PHOTO_SMALL_SIGNATURE' as DownloadTypePermissionsEnum,
    VIDEO: 'VIDEO' as DownloadTypePermissionsEnum,
  };
  export type UserPermissionsEnum =
    | 'F_AFP_READ'
    | 'F_AGENCIES_READ'
    | 'F_REUTERS_READ'
    | 'F_STOCK_READ'
    | 'F_GIRL_OF_DAY_READ'
    | 'SUPERADMIN'
    | 'DEVELOPER'
    | 'IMPORT_READ'
    | 'IMPORT_EDIT'
    | 'IMPORT_CREATE'
    | 'IMPORT_DELETE'
    | 'C_PRODUCTS_READ'
    | 'C_PRODUCTS_EDIT'
    | 'C_PRODUCTS_CREATE'
    | 'C_PRODUCTS_DELETE'
    | 'C_GALLERIES_READ'
    | 'C_GALLERIES_EDIT'
    | 'C_GALLERIES_CREATE'
    | 'C_GALLERIES_DELETE'
    | 'C_GALLERIES_PUBLISH'
    | 'C_GALLERY_GROUPS_READ'
    | 'C_GALLERY_GROUPS_EDIT'
    | 'C_GALLERY_GROUPS_CREATE'
    | 'C_GALLERY_GROUPS_DELETE'
    | 'C_GALLERY_GROUPS_PUBLISH'
    | 'C_ARTICLES_READ'
    | 'C_ARTICLES_EDIT'
    | 'C_ARTICLES_CREATE'
    | 'C_ARTICLES_DELETE'
    | 'C_ARTICLES_PUBLISH'
    | 'C_CATEGORIES_READ'
    | 'C_CATEGORIES_EDIT'
    | 'C_CATEGORIES_CREATE'
    | 'C_CATEGORIES_DELETE'
    | 'C_CATEGORIES_PUBLISH'
    | 'NEWSLETTER_READ'
    | 'NEWSLETTER_EDIT'
    | 'NEWSLETTER_CREATE'
    | 'NEWSLETTER_DELETE'
    | 'UC_COMPANIES_READ'
    | 'UC_COMPANIES_EDIT'
    | 'UC_COMPANIES_CREATE'
    | 'UC_COMPANIES_DELETE'
    | 'UC_USERS_READ'
    | 'UC_USERS_EDIT'
    | 'UC_USERS_CREATE'
    | 'UC_USERS_DELETE'
    | 'UC_DOWNLOADS_READ'
    | 'UC_DOWNLOADS_EDIT'
    | 'UC_DOWNLOADS_CREATE'
    | 'UC_DOWNLOADS_DELETE'
    | 'UC_CONTRACTS_READ'
    | 'UC_CONTRACTS_EDIT'
    | 'UC_CONTRACTS_CREATE'
    | 'UC_CONTRACTS_DELETE'
    | 'UC_INVOICES_READ'
    | 'UC_INVOICES_EDIT'
    | 'UC_INVOICES_CREATE'
    | 'UC_INVOICES_DELETE'
    | 'DA_AGENCIES_READ'
    | 'DA_AGENCIES_EDIT'
    | 'DA_AGENCIES_CREATE'
    | 'DA_AGENCIES_DELETE'
    | 'DA_IPTC_READ'
    | 'DA_IPTC_EDIT'
    | 'DA_IPTC_CREATE'
    | 'DA_IPTC_DELETE'
    | 'DA_KEYWORDS_READ'
    | 'DA_KEYWORDS_EDIT'
    | 'DA_KEYWORDS_CREATE'
    | 'DA_KEYWORDS_DELETE'
    | 'DA_PROPER_NAMES_READ'
    | 'DA_PROPER_NAMES_EDIT'
    | 'DA_PROPER_NAMES_CREATE'
    | 'DA_PROPER_NAMES_DELETE'
    | 'DA_TAGS_READ'
    | 'DA_TAGS_EDIT'
    | 'DA_TAGS_CREATE'
    | 'DA_TAGS_DELETE'
    | 'DA_PHOTOREPORTERS_READ'
    | 'DA_PHOTOREPORTERS_EDIT'
    | 'DA_PHOTOREPORTERS_CREATE'
    | 'DA_PHOTOREPORTERS_DELETE'
    | 'WA_HOME_READ'
    | 'WA_HOME_EDIT'
    | 'WA_HOME_CREATE'
    | 'WA_HOME_DELETE'
    | 'WA_HOME_PUBLISH'
    | 'WA_ABOUT_US_READ'
    | 'WA_ABOUT_US_EDIT'
    | 'WA_ABOUT_US_CREATE'
    | 'WA_ABOUT_US_DELETE'
    | 'WA_ABOUT_US_PUBLISH'
    | 'WA_CONTENT_PAGES_READ'
    | 'WA_CONTENT_PAGES_EDIT'
    | 'WA_CONTENT_PAGES_CREATE'
    | 'WA_CONTENT_PAGES_DELETE'
    | 'WA_CONTENT_PAGES_PUBLISH'
    | 'WA_STATIC_PAGES_READ'
    | 'WA_STATIC_PAGES_EDIT'
    | 'WA_STATIC_PAGES_CREATE'
    | 'WA_STATIC_PAGES_DELETE'
    | 'WA_STATIC_PAGES_PUBLISH'
    | 'FIN_PRODUCT_DOWNLOADS_READ'
    | 'FIN_PRODUCT_DOWNLOADS_EDIT'
    | 'FIN_PRODUCT_DOWNLOADS_CREATE'
    | 'FIN_PRODUCT_DOWNLOADS_DELETE'
    | 'FIN_ADMINISTRATION_READ'
    | 'FIN_ADMINISTRATION_EDIT'
    | 'FIN_ADMINISTRATION_CREATE'
    | 'FIN_ADMINISTRATION_DELETE'
    | 'FIN_DISCOUNT_READ'
    | 'FIN_DISCOUNT_EDIT'
    | 'FIN_DISCOUNT_CREATE'
    | 'FIN_DISCOUNT_DELETE'
    | 'FIN_INVOICES_READ'
    | 'FIN_INVOICES_EDIT'
    | 'FIN_INVOICES_CREATE'
    | 'FIN_INVOICES_DELETE'
    | 'STATISTICS_READ'
    | 'STATISTICS_EDIT'
    | 'STATISTICS_CREATE'
    | 'STATISTICS_DELETE'
    | 'IU_INTERNAL_USERS_READ'
    | 'IU_INTERNAL_USERS_EDIT'
    | 'IU_INTERNAL_USERS_CREATE'
    | 'IU_INTERNAL_USERS_DELETE'
    | 'IU_USER_GROUPS_READ'
    | 'IU_USER_GROUPS_EDIT'
    | 'IU_USER_GROUPS_CREATE'
    | 'IU_USER_GROUPS_DELETE'
    | 'IU_USER_LOGIN_LOG_READ'
    | 'IU_USER_LOGIN_LOG_EDIT'
    | 'IU_USER_LOGIN_LOG_CREATE'
    | 'IU_USER_LOGIN_LOG_DELETE'
    | 'ACTIVITY_LOG_READ'
    | 'ACTIVITY_LOG_EDIT'
    | 'ACTIVITY_LOG_CREATE'
    | 'ACTIVITY_LOG_DELETE'
    | 'CONTACT_FORM_READ'
    | 'CONTACT_FORM_EDIT'
    | 'CONTACT_FORM_CREATE'
    | 'CONTACT_FORM_DELETE';
  export const UserPermissionsEnum = {
    FAFPREAD: 'F_AFP_READ' as UserPermissionsEnum,
    FAGENCIESREAD: 'F_AGENCIES_READ' as UserPermissionsEnum,
    FREUTERSREAD: 'F_REUTERS_READ' as UserPermissionsEnum,
    FSTOCKREAD: 'F_STOCK_READ' as UserPermissionsEnum,
    FGIRLOFDAYREAD: 'F_GIRL_OF_DAY_READ' as UserPermissionsEnum,
    SUPERADMIN: 'SUPERADMIN' as UserPermissionsEnum,
    DEVELOPER: 'DEVELOPER' as UserPermissionsEnum,
    IMPORTREAD: 'IMPORT_READ' as UserPermissionsEnum,
    IMPORTEDIT: 'IMPORT_EDIT' as UserPermissionsEnum,
    IMPORTCREATE: 'IMPORT_CREATE' as UserPermissionsEnum,
    IMPORTDELETE: 'IMPORT_DELETE' as UserPermissionsEnum,
    CPRODUCTSREAD: 'C_PRODUCTS_READ' as UserPermissionsEnum,
    CPRODUCTSEDIT: 'C_PRODUCTS_EDIT' as UserPermissionsEnum,
    CPRODUCTSCREATE: 'C_PRODUCTS_CREATE' as UserPermissionsEnum,
    CPRODUCTSDELETE: 'C_PRODUCTS_DELETE' as UserPermissionsEnum,
    CGALLERIESREAD: 'C_GALLERIES_READ' as UserPermissionsEnum,
    CGALLERIESEDIT: 'C_GALLERIES_EDIT' as UserPermissionsEnum,
    CGALLERIESCREATE: 'C_GALLERIES_CREATE' as UserPermissionsEnum,
    CGALLERIESDELETE: 'C_GALLERIES_DELETE' as UserPermissionsEnum,
    CGALLERIESPUBLISH: 'C_GALLERIES_PUBLISH' as UserPermissionsEnum,
    CGALLERYGROUPSREAD: 'C_GALLERY_GROUPS_READ' as UserPermissionsEnum,
    CGALLERYGROUPSEDIT: 'C_GALLERY_GROUPS_EDIT' as UserPermissionsEnum,
    CGALLERYGROUPSCREATE: 'C_GALLERY_GROUPS_CREATE' as UserPermissionsEnum,
    CGALLERYGROUPSDELETE: 'C_GALLERY_GROUPS_DELETE' as UserPermissionsEnum,
    CGALLERYGROUPSPUBLISH: 'C_GALLERY_GROUPS_PUBLISH' as UserPermissionsEnum,
    CARTICLESREAD: 'C_ARTICLES_READ' as UserPermissionsEnum,
    CARTICLESEDIT: 'C_ARTICLES_EDIT' as UserPermissionsEnum,
    CARTICLESCREATE: 'C_ARTICLES_CREATE' as UserPermissionsEnum,
    CARTICLESDELETE: 'C_ARTICLES_DELETE' as UserPermissionsEnum,
    CARTICLESPUBLISH: 'C_ARTICLES_PUBLISH' as UserPermissionsEnum,
    CCATEGORIESREAD: 'C_CATEGORIES_READ' as UserPermissionsEnum,
    CCATEGORIESEDIT: 'C_CATEGORIES_EDIT' as UserPermissionsEnum,
    CCATEGORIESCREATE: 'C_CATEGORIES_CREATE' as UserPermissionsEnum,
    CCATEGORIESDELETE: 'C_CATEGORIES_DELETE' as UserPermissionsEnum,
    CCATEGORIESPUBLISH: 'C_CATEGORIES_PUBLISH' as UserPermissionsEnum,
    NEWSLETTERREAD: 'NEWSLETTER_READ' as UserPermissionsEnum,
    NEWSLETTEREDIT: 'NEWSLETTER_EDIT' as UserPermissionsEnum,
    NEWSLETTERCREATE: 'NEWSLETTER_CREATE' as UserPermissionsEnum,
    NEWSLETTERDELETE: 'NEWSLETTER_DELETE' as UserPermissionsEnum,
    UCCOMPANIESREAD: 'UC_COMPANIES_READ' as UserPermissionsEnum,
    UCCOMPANIESEDIT: 'UC_COMPANIES_EDIT' as UserPermissionsEnum,
    UCCOMPANIESCREATE: 'UC_COMPANIES_CREATE' as UserPermissionsEnum,
    UCCOMPANIESDELETE: 'UC_COMPANIES_DELETE' as UserPermissionsEnum,
    UCUSERSREAD: 'UC_USERS_READ' as UserPermissionsEnum,
    UCUSERSEDIT: 'UC_USERS_EDIT' as UserPermissionsEnum,
    UCUSERSCREATE: 'UC_USERS_CREATE' as UserPermissionsEnum,
    UCUSERSDELETE: 'UC_USERS_DELETE' as UserPermissionsEnum,
    UCDOWNLOADSREAD: 'UC_DOWNLOADS_READ' as UserPermissionsEnum,
    UCDOWNLOADSEDIT: 'UC_DOWNLOADS_EDIT' as UserPermissionsEnum,
    UCDOWNLOADSCREATE: 'UC_DOWNLOADS_CREATE' as UserPermissionsEnum,
    UCDOWNLOADSDELETE: 'UC_DOWNLOADS_DELETE' as UserPermissionsEnum,
    UCCONTRACTSREAD: 'UC_CONTRACTS_READ' as UserPermissionsEnum,
    UCCONTRACTSEDIT: 'UC_CONTRACTS_EDIT' as UserPermissionsEnum,
    UCCONTRACTSCREATE: 'UC_CONTRACTS_CREATE' as UserPermissionsEnum,
    UCCONTRACTSDELETE: 'UC_CONTRACTS_DELETE' as UserPermissionsEnum,
    UCINVOICESREAD: 'UC_INVOICES_READ' as UserPermissionsEnum,
    UCINVOICESEDIT: 'UC_INVOICES_EDIT' as UserPermissionsEnum,
    UCINVOICESCREATE: 'UC_INVOICES_CREATE' as UserPermissionsEnum,
    UCINVOICESDELETE: 'UC_INVOICES_DELETE' as UserPermissionsEnum,
    DAAGENCIESREAD: 'DA_AGENCIES_READ' as UserPermissionsEnum,
    DAAGENCIESEDIT: 'DA_AGENCIES_EDIT' as UserPermissionsEnum,
    DAAGENCIESCREATE: 'DA_AGENCIES_CREATE' as UserPermissionsEnum,
    DAAGENCIESDELETE: 'DA_AGENCIES_DELETE' as UserPermissionsEnum,
    DAIPTCREAD: 'DA_IPTC_READ' as UserPermissionsEnum,
    DAIPTCEDIT: 'DA_IPTC_EDIT' as UserPermissionsEnum,
    DAIPTCCREATE: 'DA_IPTC_CREATE' as UserPermissionsEnum,
    DAIPTCDELETE: 'DA_IPTC_DELETE' as UserPermissionsEnum,
    DAKEYWORDSREAD: 'DA_KEYWORDS_READ' as UserPermissionsEnum,
    DAKEYWORDSEDIT: 'DA_KEYWORDS_EDIT' as UserPermissionsEnum,
    DAKEYWORDSCREATE: 'DA_KEYWORDS_CREATE' as UserPermissionsEnum,
    DAKEYWORDSDELETE: 'DA_KEYWORDS_DELETE' as UserPermissionsEnum,
    DAPROPERNAMESREAD: 'DA_PROPER_NAMES_READ' as UserPermissionsEnum,
    DAPROPERNAMESEDIT: 'DA_PROPER_NAMES_EDIT' as UserPermissionsEnum,
    DAPROPERNAMESCREATE: 'DA_PROPER_NAMES_CREATE' as UserPermissionsEnum,
    DAPROPERNAMESDELETE: 'DA_PROPER_NAMES_DELETE' as UserPermissionsEnum,
    DATAGSREAD: 'DA_TAGS_READ' as UserPermissionsEnum,
    DATAGSEDIT: 'DA_TAGS_EDIT' as UserPermissionsEnum,
    DATAGSCREATE: 'DA_TAGS_CREATE' as UserPermissionsEnum,
    DATAGSDELETE: 'DA_TAGS_DELETE' as UserPermissionsEnum,
    DAPHOTOREPORTERSREAD: 'DA_PHOTOREPORTERS_READ' as UserPermissionsEnum,
    DAPHOTOREPORTERSEDIT: 'DA_PHOTOREPORTERS_EDIT' as UserPermissionsEnum,
    DAPHOTOREPORTERSCREATE: 'DA_PHOTOREPORTERS_CREATE' as UserPermissionsEnum,
    DAPHOTOREPORTERSDELETE: 'DA_PHOTOREPORTERS_DELETE' as UserPermissionsEnum,
    WAHOMEREAD: 'WA_HOME_READ' as UserPermissionsEnum,
    WAHOMEEDIT: 'WA_HOME_EDIT' as UserPermissionsEnum,
    WAHOMECREATE: 'WA_HOME_CREATE' as UserPermissionsEnum,
    WAHOMEDELETE: 'WA_HOME_DELETE' as UserPermissionsEnum,
    WAHOMEPUBLISH: 'WA_HOME_PUBLISH' as UserPermissionsEnum,
    WAABOUTUSREAD: 'WA_ABOUT_US_READ' as UserPermissionsEnum,
    WAABOUTUSEDIT: 'WA_ABOUT_US_EDIT' as UserPermissionsEnum,
    WAABOUTUSCREATE: 'WA_ABOUT_US_CREATE' as UserPermissionsEnum,
    WAABOUTUSDELETE: 'WA_ABOUT_US_DELETE' as UserPermissionsEnum,
    WAABOUTUSPUBLISH: 'WA_ABOUT_US_PUBLISH' as UserPermissionsEnum,
    WACONTENTPAGESREAD: 'WA_CONTENT_PAGES_READ' as UserPermissionsEnum,
    WACONTENTPAGESEDIT: 'WA_CONTENT_PAGES_EDIT' as UserPermissionsEnum,
    WACONTENTPAGESCREATE: 'WA_CONTENT_PAGES_CREATE' as UserPermissionsEnum,
    WACONTENTPAGESDELETE: 'WA_CONTENT_PAGES_DELETE' as UserPermissionsEnum,
    WACONTENTPAGESPUBLISH: 'WA_CONTENT_PAGES_PUBLISH' as UserPermissionsEnum,
    WASTATICPAGESREAD: 'WA_STATIC_PAGES_READ' as UserPermissionsEnum,
    WASTATICPAGESEDIT: 'WA_STATIC_PAGES_EDIT' as UserPermissionsEnum,
    WASTATICPAGESCREATE: 'WA_STATIC_PAGES_CREATE' as UserPermissionsEnum,
    WASTATICPAGESDELETE: 'WA_STATIC_PAGES_DELETE' as UserPermissionsEnum,
    WASTATICPAGESPUBLISH: 'WA_STATIC_PAGES_PUBLISH' as UserPermissionsEnum,
    FINPRODUCTDOWNLOADSREAD:
      'FIN_PRODUCT_DOWNLOADS_READ' as UserPermissionsEnum,
    FINPRODUCTDOWNLOADSEDIT:
      'FIN_PRODUCT_DOWNLOADS_EDIT' as UserPermissionsEnum,
    FINPRODUCTDOWNLOADSCREATE:
      'FIN_PRODUCT_DOWNLOADS_CREATE' as UserPermissionsEnum,
    FINPRODUCTDOWNLOADSDELETE:
      'FIN_PRODUCT_DOWNLOADS_DELETE' as UserPermissionsEnum,
    FINADMINISTRATIONREAD: 'FIN_ADMINISTRATION_READ' as UserPermissionsEnum,
    FINADMINISTRATIONEDIT: 'FIN_ADMINISTRATION_EDIT' as UserPermissionsEnum,
    FINADMINISTRATIONCREATE: 'FIN_ADMINISTRATION_CREATE' as UserPermissionsEnum,
    FINADMINISTRATIONDELETE: 'FIN_ADMINISTRATION_DELETE' as UserPermissionsEnum,
    FINDISCOUNTREAD: 'FIN_DISCOUNT_READ' as UserPermissionsEnum,
    FINDISCOUNTEDIT: 'FIN_DISCOUNT_EDIT' as UserPermissionsEnum,
    FINDISCOUNTCREATE: 'FIN_DISCOUNT_CREATE' as UserPermissionsEnum,
    FINDISCOUNTDELETE: 'FIN_DISCOUNT_DELETE' as UserPermissionsEnum,
    FININVOICESREAD: 'FIN_INVOICES_READ' as UserPermissionsEnum,
    FININVOICESEDIT: 'FIN_INVOICES_EDIT' as UserPermissionsEnum,
    FININVOICESCREATE: 'FIN_INVOICES_CREATE' as UserPermissionsEnum,
    FININVOICESDELETE: 'FIN_INVOICES_DELETE' as UserPermissionsEnum,
    STATISTICSREAD: 'STATISTICS_READ' as UserPermissionsEnum,
    STATISTICSEDIT: 'STATISTICS_EDIT' as UserPermissionsEnum,
    STATISTICSCREATE: 'STATISTICS_CREATE' as UserPermissionsEnum,
    STATISTICSDELETE: 'STATISTICS_DELETE' as UserPermissionsEnum,
    IUINTERNALUSERSREAD: 'IU_INTERNAL_USERS_READ' as UserPermissionsEnum,
    IUINTERNALUSERSEDIT: 'IU_INTERNAL_USERS_EDIT' as UserPermissionsEnum,
    IUINTERNALUSERSCREATE: 'IU_INTERNAL_USERS_CREATE' as UserPermissionsEnum,
    IUINTERNALUSERSDELETE: 'IU_INTERNAL_USERS_DELETE' as UserPermissionsEnum,
    IUUSERGROUPSREAD: 'IU_USER_GROUPS_READ' as UserPermissionsEnum,
    IUUSERGROUPSEDIT: 'IU_USER_GROUPS_EDIT' as UserPermissionsEnum,
    IUUSERGROUPSCREATE: 'IU_USER_GROUPS_CREATE' as UserPermissionsEnum,
    IUUSERGROUPSDELETE: 'IU_USER_GROUPS_DELETE' as UserPermissionsEnum,
    IUUSERLOGINLOGREAD: 'IU_USER_LOGIN_LOG_READ' as UserPermissionsEnum,
    IUUSERLOGINLOGEDIT: 'IU_USER_LOGIN_LOG_EDIT' as UserPermissionsEnum,
    IUUSERLOGINLOGCREATE: 'IU_USER_LOGIN_LOG_CREATE' as UserPermissionsEnum,
    IUUSERLOGINLOGDELETE: 'IU_USER_LOGIN_LOG_DELETE' as UserPermissionsEnum,
    ACTIVITYLOGREAD: 'ACTIVITY_LOG_READ' as UserPermissionsEnum,
    ACTIVITYLOGEDIT: 'ACTIVITY_LOG_EDIT' as UserPermissionsEnum,
    ACTIVITYLOGCREATE: 'ACTIVITY_LOG_CREATE' as UserPermissionsEnum,
    ACTIVITYLOGDELETE: 'ACTIVITY_LOG_DELETE' as UserPermissionsEnum,
    CONTACTFORMREAD: 'CONTACT_FORM_READ' as UserPermissionsEnum,
    CONTACTFORMEDIT: 'CONTACT_FORM_EDIT' as UserPermissionsEnum,
    CONTACTFORMCREATE: 'CONTACT_FORM_CREATE' as UserPermissionsEnum,
    CONTACTFORMDELETE: 'CONTACT_FORM_DELETE' as UserPermissionsEnum,
  };
}
