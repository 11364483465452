import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'previewItemFlex',
})
export class PreviewItemFlexPipe implements PipeTransform {
  transform(value: number | undefined): string {
    return '0 0 ' + value + '%';
  }
}
