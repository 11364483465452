<div class="contract">
  <button (click)="toggleIsHidden()" class="header-btn">
    <div class="contract__header">
      <div class="contract__header-title">
        <img
          *ngIf="contractType === 'active'"
          src="/assets/contract.svg"
          alt=""
        />
        <img
          *ngIf="contractType === 'inactive'"
          src="/assets/contracts-grey.svg"
          alt=""
        />
        <span
          >{{ contractData.id }} /
          {{ contractData.company!.company_name }}</span
        >
      </div>

      <img
        src="/assets/expanded.svg"
        alt=""
        [@openCloseIcon]="isHidden ? 'closedIcon' : 'openIcon'"
      />
    </div>
  </button>

  <div>
    <div
      class="contract__form-buttons"
      [@openClose]="isHidden ? 'closed' : 'open'"
    >
      <div class="company-data__edit-container" *ngIf="!isEditModeOn">
        <button class="company-data__edit" (click)="openEditMode()">
          <img src="/assets/pencil.svg" alt="" />
          <div>Edit contract</div>
        </button>
      </div>

      <div class="company-data__edit-container" *ngIf="isEditModeOn">
        <button class="btn-save" (click)="saveChanges(contractForm.value)">
          Save
        </button>
        <button class="btn-cancel" (click)="closeEditMode()">Cancel</button>
      </div>
    </div>

    <app-transforming-input
      [label]="'TITLE'"
      [controlName]="'title'"
      [form]="contractForm"
      [type]="InputType.Text"
      [isEditModeActive]="isEditModeOn"
    ></app-transforming-input>

    <div
      class="contract__form contract__form-top"
      [class.contract__form-top2]="isEditModeOn"
      [@transformFormMargins]="isHidden ? 'formClosedMargin' : 'formOpenMargin'"
    >
      <app-transforming-input
        [label]="'CONTRACT START DATE'"
        [controlName]="'valid_from'"
        [form]="contractForm"
        [type]="InputType.Date2"
        [isEditModeActive]="isEditModeOn"
        [isDateFormated]="false"
      ></app-transforming-input>
      <app-transforming-input
        [label]="'CONTRACT END DATE'"
        [controlName]="'valid_to'"
        [form]="contractForm"
        [type]="InputType.Date2"
        [dateTimeFormat]="undefined"
        [isEditModeActive]="isEditModeOn"
        [isDateFormated]="false"
      ></app-transforming-input>

      <app-transforming-input
        [label]="'CURRENCY'"
        [controlName]="'currency'"
        [form]="contractForm"
        [type]="InputType.Select"
        [selectOptions]="{ EUR: 'EUR', HRK: 'HRK' }"
        [isEditModeActive]="isEditModeOn"
      ></app-transforming-input>
    </div>
  </div>
  <div [@openClose]="isHidden ? 'closed' : 'open'">
    <div
      class="contract__form contract__form-bottom"
      [@transformFormMargins]="isHidden ? 'formClosedMargin' : 'formOpenMargin'"
    >
      <app-transforming-input
        [label]="'ID NUMBER'"
        [controlName]="'id'"
        [form]="contractForm"
        [isEditModeActive]="isEditModeOn"
        [type]="InputType.Disabled"
        [@openClose]="isHidden ? 'closed' : 'open'"
      ></app-transforming-input>
      <app-transforming-input
        [label]="'STATUS'"
        [controlName]="'status'"
        [form]="contractForm"
        [isEditModeActive]="isEditModeOn"
        [type]="InputType.Select"
        [selectOptions]="{ ACTIVE: 'Active', INACTIVE: 'Inactive' }"
        [@openClose]="isHidden ? 'closed' : 'open'"
      ></app-transforming-input>
    </div>
    <div class="contract__fotocopy" style="display: none">
      <div class="contract__fotocopy-title">Potpisana fotokopija ugovora</div>

      <div class="contract-uploaded">
        <div>
          <img src="/assets/file-small.svg" alt="" />
          Filename
        </div>
        <button *ngIf="isEditModeOn" (click)="removeContractCopy()"></button>
      </div>

      <label class="contract__btn label-btn" for="file"
        >Dohvati ugovor
        <input
          class="file-input"
          type="file"
          id="file"
          name="contract-file"
          accept=".pdf"
          [(ngModel)]="file"
          (change)="saveFile($event)"
        />
      </label>
    </div>

    <hr class="contract__hr" />

    <div class="contract__item-header">
      <div>contract items</div>
      <button (click)="openAddItemModal()" class="contract__btn">
        Add item
      </button>
    </div>

    <div class="contract__items">
      <ng-container *ngIf="contractItems">
        <div class="contract__no-items" *ngIf="contractItems.length === 0">
          <img
            class="contract__no-items-img"
            src="/assets/files-grey.svg"
            alt=""
          />
          <div class="contract__no-items-title">
            There are currently no items.
          </div>
          <div class="contract__no-items-body"></div>
        </div>

        <div class="contract__table" *ngIf="contractItems.length > 0">
          <div
            class="table-container"
            [class.hide-overflow]="isAddItemModalOpen || isAddContractModalOpen"
          >
            <table>
              <tr>
                <th>Item name</th>
                <th>Agencies</th>
                <th>Agency Groups</th>
                <th>Type</th>
                <th>Content Type</th>
                <th>Product</th>
                <th>From Downloads</th>
                <th>To Downloads</th>
                <th>Fixed Price / Percentage</th>
                <th>Item Group</th>
                <th>Item Price (w/o VAT)</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
              <tr *ngFor="let item of contractItems; let i = index">
                <td>{{ item.line_name }}</td>

                <td>
                  <div *ngFor="let agency of item.agencies">
                    {{ agency.title }}
                  </div>
                </td>
                <td>
                  <div *ngFor="let agencyGroup of item.agency_groups">
                    {{ agencyGroup.title }}
                  </div>
                </td>
                <td>
                  <span *ngIf="item.line_type == 'FLAT_RATE'">Fixed</span>
                  <span *ngIf="item.line_type == 'PERCENTAGE'">Discount</span>
                </td>
                <td>
                  <span *ngIf="item.content_type == 'PHOTO'">Photo</span>
                  <span *ngIf="item.content_type == 'VIDEO'">Video</span>
                  <span *ngIf="item.content_type == undefined"
                    >Photo/Video</span
                  >
                </td>
                <td>{{ item.invoice_product }}</td>
                <td>{{ item.from }}</td>
                <td>{{ item.to }}</td>
                <td>
                  <span *ngIf="item.line_type == 'FLAT_RATE'">{{
                    item.flat_rate | number : "1.2-2"
                  }}</span>
                  <span *ngIf="item.line_type == 'PERCENTAGE'"
                    >{{ item.percentage | number : "1.2-2" }}%</span
                  >
                </td>
                <td>{{ item.group_name }}</td>
                <td>{{ item.line_price | number : "1.2-2" }}</td>
                <td>
                  <button class="table-button" (click)="editItem(i)">
                    Edit
                  </button>
                </td>
                <td>
                  <button class="table-button" (click)="deleteItem(i)">
                    Delete
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="isAddItemModalOpen">
  <div class="special-modal">
    <div
      class="special-modal__body special-modal__body--2"
      (click)="stopEventPropagation($event)"
    >
      <h1 class="special-modal__header">Contract Item</h1>

      <div class="modal-contentt">
        <div class="modal-template" style="display: none">
          <div class="modal-group">
            <label for="template">Template</label>
            <input
              type="text"
              name="template"
              id="template"
              [(ngModel)]="template"
              #templateTag="ngModel"
            />
          </div>
        </div>
        <div class="modal-grid">
          <div class="modal-group">
            <label for="itemName">ITEM NAME</label>
            <input
              type="text"
              name="itemName"
              id="itemName"
              [(ngModel)]="itemName"
              #itemNameTag="ngModel"
              required
              [class.invalid]="
                itemNameTag.invalid &&
                (itemNameTag.dirty || itemNameTag.touched)
              "
            />
            <div
              *ngIf="
                itemNameTag.invalid &&
                (itemNameTag.dirty || itemNameTag.touched)
              "
              class="error-msg"
            >
              * Field is required!
            </div>
          </div>
          <div class="modal-group">
            <label for="lineType">TYPE</label>

            <select
              [(ngModel)]="type"
              name="lineType"
              id="lineType"
              #typeTag="ngModel"
              required
              [class.invalid]="
                typeTag.invalid && (typeTag.dirty || typeTag.touched)
              "
            >
              <option *ngFor="let c of lineTypes" [ngValue]="c.id">
                {{ c.title }}
              </option>
            </select>

            <div
              *ngIf="typeTag.invalid && (typeTag.dirty || typeTag.touched)"
              class="error-msg"
            >
              * Field is required!
            </div>
          </div>
          <div class="modal-group">
            <label for="productInvoice">PRODUCT</label>
            <select
              [(ngModel)]="productInvoice"
              name="productInvoice"
              id="productInvoice"
              #productInvoiceTag="ngModel"
              required
              [class.invalid]="
                productInvoiceTag.invalid &&
                (productInvoiceTag.dirty || productInvoiceTag.touched)
              "
            >
              <option *ngFor="let c of product" [ngValue]="c.id">
                {{ c.title }}
              </option>
            </select>

            <div
              *ngIf="
                productInvoiceTag.invalid &&
                (productInvoiceTag.dirty || productInvoiceTag.touched)
              "
              class="error-msg"
            >
              * Field is required!
            </div>
          </div>
          <div class="modal-group">
            <label for="startUnlimitedFrom">FROM DOWNLOADS</label>
            <input
              type="number"
              name="startUnlimitedFrom"
              id="startUnlimitedFrom"
              [(ngModel)]="startUnlimitedFrom"
              #startUnlimitedFromTag="ngModel"
              required
              [class.invalid]="
                startUnlimitedFromTag.invalid &&
                (startUnlimitedFromTag.dirty || startUnlimitedFromTag.touched)
              "
            />
            <div
              *ngIf="
                startUnlimitedFromTag.invalid &&
                (startUnlimitedFromTag.dirty || startUnlimitedFromTag.touched)
              "
              class="error-msg"
            >
              * Field is required!
            </div>
          </div>
          <div class="modal-group">
            <label for="endUnlimitedAt">TO DOWNLOADS</label>
            <input
              type="number"
              name="endUnlimitedAt"
              id="endUnlimitedAt"
              [(ngModel)]="endUnlimitedAt"
              #endUnlimitedAtTag="ngModel"
              required
              [class.invalid]="
                endUnlimitedAtTag.invalid &&
                (endUnlimitedAtTag.dirty || endUnlimitedAtTag.touched)
              "
            />
            <div
              *ngIf="
                endUnlimitedAtTag.invalid &&
                (endUnlimitedAtTag.dirty || endUnlimitedAtTag.touched)
              "
              class="error-msg"
            >
              * Field is required!
            </div>
          </div>
          <div
            class="modal-group"
            [style.display]="type == 'FLAT_RATE' ? 'block' : 'none'"
          >
            <label for="downloadPrice">FIXED PRICE</label>
            <input
              type="number"
              name="downloadPrice"
              id="downloadPrice"
              [(ngModel)]="downloadPrice"
              #downloadPriceTag="ngModel"
            />
          </div>

          <div
            class="modal-group"
            [style.display]="type == 'PERCENTAGE' ? 'block' : 'none'"
          >
            <label for="downloadPrice">PERCENTAGE (%)</label>
            <input
              type="number"
              name="discount"
              id="discount"
              [(ngModel)]="discount"
              #discountTag="ngModel"
            />
          </div>

          <div class="modal-group">
            <label for="itemGroup">ITEM GROUP</label>
            <input
              type="text"
              name="itemGroup"
              id="itemGroup"
              [(ngModel)]="itemGroup"
              #itemGroupTag="ngModel"
              required
              [class.invalid]="
                itemGroupTag.invalid &&
                (itemGroupTag.dirty || itemGroupTag.touched)
              "
            />
            <div
              *ngIf="
                itemGroupTag.invalid &&
                (itemGroupTag.dirty || itemGroupTag.touched)
              "
              class="error-msg"
            >
              * Field is required!
            </div>
          </div>
          <div class="modal-group">
            <label for="lumpSumPrice">ITEM PRICE (W/O VAT)</label>
            <input
              type="number"
              name="lumpSumPrice"
              id="lumpSumPrice"
              [(ngModel)]="lumpSumPrice"
              #lumpSumPriceTag="ngModel"
              required
              [class.invalid]="
                lumpSumPriceTag.invalid &&
                (lumpSumPriceTag.dirty || lumpSumPriceTag.touched)
              "
            />
            <div
              *ngIf="
                lumpSumPriceTag.invalid &&
                (lumpSumPriceTag.dirty || lumpSumPriceTag.touched)
              "
              class="error-msg"
            >
              * Field is required!
            </div>
          </div>

          <app-field-tags
            class="filter"
            title="AGENCIES"
            placeholder="Agencies"
            theme="filter"
            autocompleteType="agency"
            [(tags)]="agencies"
          ></app-field-tags>

          <app-field-tags
            class="filter"
            title="AGENCY GROUPS"
            placeholder="Agency Groups"
            theme="filter"
            autocompleteType="agencyGroups"
            [(tags)]="agencyGroups"
          ></app-field-tags>
        </div>

        <div class="help-text">
          The list of agencies for this contract line is calculated as follows:

          <ul>
            <li>
              If the product is one of the:
              <i
                >UgMN_PxF_D, UgRa_PxF_D, RP_PxF_D, PD_PxF_D, UgMN_PxV_D,
                UgRa_PxV_D, RP_PxV_D, PD_PxV_D</i
              >
              all agencies marked as Pixsell will be a match.
            </li>
            <li>If the agency is in the agency field it will be a match.</li>
            <li>
              If the agency is in the agency group in the agency group field it
              will be a match.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="special-modal__footer">
      <button class="special-modal__cancel-btn" (click)="closeAddItemModal()">
        Cancel
      </button>
      <button
        class="special-modal__add-btn"
        (click)="
          createNewItem(
            templateTag,
            itemNameTag,
            typeTag,
            productInvoiceTag,
            startUnlimitedFromTag,
            endUnlimitedAtTag,
            downloadPriceTag,
            discountTag,
            itemGroupTag,
            lumpSumPriceTag
          )
        "
      >
        Save
      </button>
    </div>
  </div>
</ng-container>
