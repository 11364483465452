<div class="app-navbar">
  <div class="app-navbar-logo">
    <img src="assets/pixsell.svg" />
  </div>

  <div class="app-navbar-avatar">
    <img
      src="assets/default-avatar.svg"
      style="width: 31px; height: 31px"
      (click)="openModal()"
    />
  </div>
</div>

<!-- The Modal -->
<div
  id="myModal"
  class="modal"
  @fadeModal
  *ngIf="!chooseAction"
  (click)="closeModalWindow()"
>
  <!-- Modal content -->
  <div class="modal-content" (click)="stopModalPropagation($event)">
    <div class="modal-body">
      <div class="modal-headerr">
        <div class="modal-headerr__main" *ngIf="user">
          <img
            src="{{ user.profilePic }}"
            class=""
            style="width: 31px; height: 31px"
          />
          <div class="name-container">{{ user.name }} {{ user.surname }}</div>
        </div>
        <button class="close-btn" (click)="closeModalWindow()">
          <img src="/assets/close-black-small.svg" alt="" />
        </button>
      </div>
      <div class="modal-rest">
        <div class="btn-container">
          <button
            class="btn-setup txt"
            routerLink="/profile"
            (click)="closeModalWindow()"
          >
            Postavke profila
          </button>
        </div>
        <div class="btn-container">
          <button class="btn-logout txt" (click)="logOut()">
            <span id="test">Odjava</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
