/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AgencyIpctTag } from './agencyIpctTag';
import { SpecialInstructions } from './specialInstructions';
import { UserRights } from './userRights';

export interface Agency {
  id?: number;
  name?: string;
  currency?: string;
  slug?: string;
  email?: string;
  web?: string;
  street_and_number?: string;
  zip?: string;
  city?: string;
  country?: string;
  contact_person?: string;
  contact_number?: string;
  contact_email?: string;
  note?: string;
  var_large_price?: number;
  var_small_price?: number;
  contracts_bellow_limit?: number;
  contracts_above_limit?: number;
  contract_limit?: number;
  is_active?: boolean;
  is_public?: boolean;
  is_visible_in_rss?: boolean;
  positon_in_rss?: number;
  content_type?: Agency.ContentTypeEnum;
  import_type?: string;
  agency_folder_name?: string;
  /**
   * Slike se nalaze unutar subfoldera
   */
  contains_subfolder?: boolean;
  agency_folder_name2?: string;
  iptc_identificators?: Array<string>;
  iptc_tags?: Array<string>;
  description?: string;
  sufix?: string;
  prefix?: string;
  uses_iptc_fields?: boolean;
  special_instructions?: Array<SpecialInstructions>;
  blocked_countries?: Array<string>;
  allowed_countries?: Array<string>;
  user_rights?: Array<UserRights>;
  iptc_after_download?: Array<AgencyIpctTag>;
  signature?: string;
}
export namespace Agency {
  export type ContentTypeEnum =
    | 'PICTURE,'
    | 'VIDEO,'
    | 'PICTURE_VIDEO,'
    | 'ILLUSTRATION,'
    | 'PICTURE_VIDEO,'
    | 'PICTURE,'
    | 'VIDEO,'
    | 'ALL';
  export const ContentTypeEnum = {
    PICTURE: 'PICTURE,' as ContentTypeEnum,
    VIDEO: 'VIDEO,' as ContentTypeEnum,
    PICTUREVIDEO: 'PICTURE_VIDEO,' as ContentTypeEnum,
    ILLUSTRATION: 'ILLUSTRATION,' as ContentTypeEnum,
    PICTUREVIDEO_4: 'PICTURE_VIDEO,' as ContentTypeEnum,
    PICTURE_5: 'PICTURE,' as ContentTypeEnum,
    VIDEO_6: 'VIDEO,' as ContentTypeEnum,
    ALL: 'ALL' as ContentTypeEnum,
  };
}
