import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface Tab {
  routerLink: string;
  tabText: string;
}

@Component({
  selector: 'app-multitab-header',
  templateUrl: './multitab-header.component.html',
  styleUrls: ['./multitab-header.component.scss'],
})
export class MultitabHeaderComponent implements OnInit {
  @Input() tabs: Tab[] = [];
  @Input() headerText: string = '';
  @Input() isNavigateBackEnabled: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  navigateBack() {
    this.router.navigate(['../'], {
      relativeTo: this.route,
    });
  }
}
