import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/app/shared/environment';

@Pipe({
  name: 'remoteApiAsset',
})
export class RemoteApiAssetPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return environment.apiUrl + value;
  }
}
