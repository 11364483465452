import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ImportItem } from './import.component';
import { bufferTime, last, skipUntil, skipWhile } from 'rxjs/operators';
import { AutocompleteOption } from '../../shared/field-text/field-text.component';
import {
  ErrorResponse,
  IdsList,
  MediaItem,
  ModelError,
  Translatable,
  UploadSession,
  UploadSessionPublishRequest,
  UploadSessionsService,
} from 'piwe-front-swagger-client';
import {
  Agency,
  Author,
  Category,
  Keyword,
  LicenceType,
  Person,
  SpecialOfferType,
  Tag,
} from '../../../../projects/piwe-front-swagger-client/src';
import { LoadingService } from '../../shared/loading.service';
import { SnackBarService } from '../../share/snack-bar.service';
import { ComputeFields } from '../../shared/compute-fields';
import logging from '../../logging';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  private files: any[] = [];

  private selectItemsSource = new Subject<number[]>();
  public selectItemsData: Observable<number[]> =
    this.selectItemsSource.asObservable();

  private refreshEditingErrorsSource = new Subject<void>();
  public refreshEditingErrorsData: Observable<void> =
    this.refreshEditingErrorsSource.asObservable();

  private selectAllSource = new Subject<boolean>();
  public selectAllData: Observable<boolean> =
    this.selectAllSource.asObservable();

  private uploadingFilesSource = new Subject<any[]>();
  public uploadingFilesData: Observable<any[]> =
    this.uploadingFilesSource.asObservable();

  private itemsSource = new Subject<ImportItem[]>();
  public itemsData: Observable<ImportItem[]> = this.itemsSource.asObservable();

  private selectedIdsSource = new Subject<number[]>();
  public selectedIdsData: Observable<number[]> =
    this.selectedIdsSource.asObservable();

  public items: ImportItem[] = [];
  private itemsById: { [key: number]: ImportItem } = {};

  private selectedIds: number[] = [];

  private editingHeadlineSource = new Subject<AutocompleteOption | undefined>();
  public editingHeadlineData: Observable<AutocompleteOption | undefined> =
    this.editingHeadlineSource.asObservable();

  private editingCaptionSource = new Subject<string | undefined>();
  public editingCaptionData: Observable<string | undefined> =
    this.editingCaptionSource.asObservable();

  private editingPeopleSource = new Subject<AutocompleteOption[] | undefined>();
  public editingPeopleData: Observable<AutocompleteOption[] | undefined> =
    this.editingPeopleSource.asObservable();

  private editingKeywordsSource = new Subject<
    AutocompleteOption[] | undefined
  >();
  public editingKeywordsData: Observable<AutocompleteOption[] | undefined> =
    this.editingKeywordsSource.asObservable();

  private videoTitleSource = new Subject<string | undefined>();
  public videoTitleData: Observable<string | undefined> =
    this.videoTitleSource.asObservable();

  private editingCategoriesSource = new Subject<
    AutocompleteOption | undefined
  >();
  public editingCategoriesData: Observable<AutocompleteOption | undefined> =
    this.editingCategoriesSource.asObservable();

  private editingAuthorSource = new Subject<AutocompleteOption | undefined>();
  public editingAuthorData: Observable<AutocompleteOption | undefined> =
    this.editingAuthorSource.asObservable();

  private editingAgencySource = new Subject<AutocompleteOption | undefined>();
  public editingAgencyData: Observable<AutocompleteOption | undefined> =
    this.editingAgencySource.asObservable();

  private editingTagSource = new Subject<AutocompleteOption | undefined>();
  public editingTagData: Observable<AutocompleteOption | undefined> =
    this.editingTagSource.asObservable();

  private editingSpecialOfferSource = new Subject<boolean>();
  public editingSpecialOfferData: Observable<boolean> =
    this.editingSpecialOfferSource.asObservable();

  private editingSpecialOfferTypesSource = new Subject<
    AutocompleteOption[] | undefined
  >();
  public editingSpecialOfferTypesData: Observable<
    AutocompleteOption[] | undefined
  > = this.editingSpecialOfferTypesSource.asObservable();

  private editingApiFtpSource = new Subject<boolean>();
  public editingApiFtpData: Observable<boolean> =
    this.editingApiFtpSource.asObservable();

  private editingLicenceSource = new Subject<string | undefined>();
  public editingLicenceData: Observable<string | undefined> =
    this.editingLicenceSource.asObservable();

  private editingDateCreatedSource = new Subject<string | undefined>();
  public editingDateCreatedData: Observable<string | undefined> =
    this.editingDateCreatedSource.asObservable();

  private editingNeedsTranslationEnSource = new Subject<boolean>();
  public editingNeedsTranslationEnData: Observable<boolean> =
    this.editingNeedsTranslationEnSource.asObservable();

  private editingCaptionEnSource = new Subject<string | undefined>();
  public editingCaptionEnData: Observable<string | undefined> =
    this.editingCaptionEnSource.asObservable();

  private editingKeywordsEnSource = new Subject<
    AutocompleteOption[] | undefined
  >();
  public editingKeywordsEnData: Observable<AutocompleteOption[] | undefined> =
    this.editingKeywordsEnSource.asObservable();

  private editingWebPriceSource = new Subject<number | undefined>();
  public editingWebPriceData: Observable<number | undefined> =
    this.editingWebPriceSource.asObservable();

  private editingPrintPriceSource = new Subject<number | undefined>();
  public editingPrintPriceData: Observable<number | undefined> =
    this.editingPrintPriceSource.asObservable();

  private editingWebPriceEurSource = new Subject<number | undefined>();
  public editingWebPriceEurData: Observable<number | undefined> =
    this.editingWebPriceEurSource.asObservable();

  private editingPrintPriceEurSource = new Subject<number | undefined>();
  public editingPrintPriceEurData: Observable<number | undefined> =
    this.editingPrintPriceEurSource.asObservable();

  private editingSuffixSource = new Subject<string | undefined>();
  public editingSuffixData: Observable<string | undefined> =
    this.editingSuffixSource.asObservable();

  private currentErrorResponse?: ErrorResponse;
  private errorResponseSource = new Subject<ErrorResponse | undefined>();
  public errorResponseData: Observable<ErrorResponse | undefined> =
    this.errorResponseSource.asObservable();

  private errorIdsSource = new Subject<number[]>();
  public errorIdsData: Observable<number[]> =
    this.errorIdsSource.asObservable();

  private saveQueueSource = new Subject<void>();
  public saveQueueData: Observable<void> = this.saveQueueSource.asObservable();

  private savingStatusSource = new BehaviorSubject<boolean>(false);
  public saveStatusData: Observable<boolean> =
    this.savingStatusSource.asObservable();

  public lastModifiedAt?: Date = undefined;
  public blockingOperations: {
    subscription: Subscription;
    tag: string;
    type: string;
  }[] = [];

  public isSaving: boolean = false;
  public lastLoadedTime?: Date = undefined;

  constructor(
    public uploadService: UploadSessionsService,
    public loadingService: LoadingService,
    public snackBarService: SnackBarService
  ) {
    this.items = [];
    this.itemsById = {};

    this.setUpSaveSession();
  }

  uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  loadSession() {
    if (this.blockingOperations.length) {
      logging.info(
        'import.service.ts',
        `Already loading, won't start again.`,
        this.blockingOperations
      );
      return;
    }

    let tag: string = this.uuidv4();

    let subscription: Subscription = this.uploadService
      .cmsGetUploadSession(this.lastModifiedAt)
      .pipe(skipWhile((x) => this.isSaving))
      .subscribe(
        (uploadSession) => {
          if (uploadSession.last_modified_at)
            this.lastModifiedAt = new Date(
              uploadSession.last_modified_at + 'Z'
            );

          let newItems = this.deserializeUploadSession(uploadSession);
          let difference = newItems.filter(
            (x) => !this.items.map((i) => i.id).includes(x.id)
          );
          this.items = this.items.concat(difference);

          difference.forEach((newItem) => {
            this.itemsById[newItem.id] = newItem;
          });

          this.itemsSource.next(this.items);

          if (difference.length) {
            this.appendIdsToSelection(difference.map((i) => i.id));
          }

          logging.info(
            'import.service.ts',
            `Loading finished for tag ${tag}`,
            this.blockingOperations
          );
          this.lastLoadedTime = new Date();
          this.blockingOperations = this.blockingOperations.filter(
            (s) => s.tag != tag
          );
        },
        (error) => {
          logging.info(
            'import.service.ts',
            `Loading failed for tag ${tag}`,
            this.blockingOperations
          );
          this.blockingOperations = this.blockingOperations.filter(
            (s) => s.tag != tag
          );
        }
      );

    this.blockingOperations.push({
      tag,
      subscription,
      type: 'refresh',
    });

    logging.info(
      'import.service.ts',
      `Starting loading with tag ${tag}`,
      this.lastModifiedAt
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // DELETING
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public deleteSelected() {
    let request: IdsList = {
      ids: this.selectedIds,
    };

    let toDeleteIds = this.selectedIds;

    this.loadingService.setLoading(true);

    let tag: string = this.uuidv4();

    let subscription: Subscription = this.uploadService
      .cmsDeleteMediaItems(request)
      .subscribe(
        (result: any) => {
          this.items = this.items.filter((i) => !toDeleteIds.includes(i.id));
          toDeleteIds.forEach((id: number) => {
            delete this.itemsById[id];
          });

          this.selectedIds = this.selectedIds.filter(
            (id) => !toDeleteIds.includes(id)
          );
          this.selectedIdsSource.next(this.selectedIds);

          this.itemsSource.next(this.items);
          this.snackBarService.showSnackBar(
            'Successfully deleted item(s).',
            'INFO'
          );
          this.loadingService.setLoading(false);
          this.deselectIds(this.selectedIds);

          logging.info('import.service.ts', `Success deleting with tag ${tag}`);
          this.blockingOperations = this.blockingOperations.filter(
            (s) => s.tag != tag
          );
        },
        (error) => {
          this.snackBarService.showSnackBar(
            'Failed deleting item(s).',
            'ERROR'
          );
          this.loadingService.setLoading(false);

          logging.info('import.service.ts', `Failed deleting with tag ${tag}`);
          this.blockingOperations = this.blockingOperations.filter(
            (s) => s.tag != tag
          );
        }
      );

    this.blockingOperations.push({
      tag,
      subscription,
      type: 'delete',
    });

    logging.info('import.service.ts', `Starting deleting with tag ${tag}`);
  }

  private saveSession() {
    let tag: string = this.uuidv4();
    let uploadSession: UploadSession = this.serializeUploadSession();
    let subscription: Subscription = this.uploadService
      .cmsPutUploadSession(uploadSession)
      .subscribe(
        (result: UploadSession) => {
          logging.info('import.service.ts', `Success saving with tag ${tag}`);
          this.blockingOperations = this.blockingOperations.filter(
            (s) => s.tag != tag
          );
        },
        (error) => {
          logging.info('import.service.ts', `Failed saving with tag ${tag}`);
          this.blockingOperations = this.blockingOperations.filter(
            (s) => s.tag != tag
          );
        }
      );

    this.blockingOperations.push({
      tag,
      subscription,
      type: 'saving',
    });
    logging.info('import.service.ts', `Starting saving with tag ${tag}`);
  }

  public publishSession(
    kind: UploadSessionPublishRequest.KindEnum,
    isNewsletterPush: boolean,
    isNotificationPush: boolean
  ) {
    let tag: string = this.uuidv4();
    this.loadingService.setLoading(true);

    let uploadSession: UploadSession = this.serializeUploadSession();
    let uploadSessionPublishRequest: UploadSessionPublishRequest = {
      kind: kind,
      is_newsletter_push: isNewsletterPush,
      is_notification_push: isNotificationPush,
    };

    this.selectItems([]);
    this.isSaving = true;
    let subscription: Subscription = this.uploadService
      .cmsPutUploadSession(uploadSession)
      .subscribe(
        (result: UploadSession) => {
          this.uploadService
            .cmsPublishUploadSession(uploadSessionPublishRequest, 'response')
            .subscribe(
              (result) => {
                this.loadingService.setLoading(false);
                this.snackBarService.showSnackBar('Saved.', 'INFO');
                this.items = [];
                this.itemsById = {};
                this.selectItems([]);
                this.itemsSource.next(this.items);
                this.selectedIdsSource.next([]);
                this.loadDataForSelectedItem();
                this.refreshErrorData();
                this.unselectAll();
                this.isSaving = false;

                logging.info(
                  'import.service.ts',
                  `Success publishing with tag ${tag}`
                );
                this.lastLoadedTime = new Date();
                this.blockingOperations = this.blockingOperations.filter(
                  (s) => s.tag != tag
                );
              },
              (error) => {
                let errorResponse: ErrorResponse = error.error;

                this.currentErrorResponse = errorResponse;
                this.errorResponseSource.next(errorResponse);
                this.errorIdsSource.next(
                  errorResponse.errors.map((e) => parseInt(e.item_id ?? '-1'))
                );
                this.refreshErrorData();
                this.loadingService.setLoading(false);
                this.snackBarService.showSnackBar('Error saving.', 'ERROR');
                this.isSaving = false;

                logging.info(
                  'import.service.ts',
                  `Failed publishing with tag ${tag}`
                );
                this.blockingOperations = this.blockingOperations.filter(
                  (s) => s.tag != tag
                );
              }
            );
        },
        (error) => {
          logging.info(
            'import.service.ts',
            `Failed publishing with tag ${tag}`
          );
          this.blockingOperations = this.blockingOperations.filter(
            (s) => s.tag != tag
          );
        }
      );

    this.blockingOperations.push({
      tag,
      subscription,
      type: 'publish',
    });
    logging.info('import.service.ts', `Starting publishing with tag ${tag}`);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // SELECTION
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  appendIdsToSelection(ids: number[]) {
    this.selectItems(this.selectedIds.concat(ids));
  }

  selectItems(itemIds: number[]) {
    this.selectedIds = itemIds;
    this.selectedIdsSource.next(itemIds);
    this.selectItemsSource.next(itemIds);
    this.loadDataForSelectedItem();
    this.refreshErrorData();
  }

  deselectIds(ids: number[]) {
    this.selectItems(this.selectedIds.filter((i) => !ids.includes(i)));
  }

  selectAll() {
    this.selectAllSource.next(true);
  }

  unselectAll() {
    this.selectAllSource.next(false);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  refreshErrorData() {
    this.refreshEditingErrorsSource.next();
  }

  loadDataForSelectedItem() {
    this.editingHeadlineSource.next(
      ComputeFields.computeAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.headline
      )
    );
    this.editingCaptionSource.next(
      ComputeFields.computeFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.caption
      )
    );
    this.editingPeopleSource.next(
      ComputeFields.computeMultipleAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.people
      )
    );
    this.editingKeywordsSource.next(
      ComputeFields.computeMultipleAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.keywords
      )
    );
    this.editingCategoriesSource.next(
      ComputeFields.computeAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.categories
      )
    );
    this.videoTitleSource.next(
      ComputeFields.computeFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.videoTitle
      )
    );
    this.editingAuthorSource.next(
      ComputeFields.computeAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.author
      )
    );
    this.editingAgencySource.next(
      ComputeFields.computeAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.agency
      )
    );
    this.editingTagSource.next(
      ComputeFields.computeAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.tag
      )
    );
    this.editingSpecialOfferSource.next(
      ComputeFields.computeBooleanFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.specialOffer
      )
    );
    this.editingSpecialOfferTypesSource.next(
      ComputeFields.computeMultipleAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.specialOfferTypes
      )
    );
    this.editingApiFtpSource.next(
      ComputeFields.computeBooleanFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.apiFtp
      )
    );
    this.editingLicenceSource.next(
      ComputeFields.computeFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.licence
      )
    );
    this.editingDateCreatedSource.next(
      ComputeFields.computeFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.dateCreated
      )
    );
    this.editingNeedsTranslationEnSource.next(
      ComputeFields.computeBooleanFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.needsTranslationEn
      )
    );
    this.editingCaptionEnSource.next(
      ComputeFields.computeFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.captionEn
      )
    );
    this.editingKeywordsEnSource.next(
      ComputeFields.computeMultipleAutocompleteFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.keywordsEn
      )
    );

    this.editingPrintPriceSource.next(
      ComputeFields.computeNumberFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.printPrice
      )
    );
    this.editingWebPriceSource.next(
      ComputeFields.computeNumberFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.webPrice
      )
    );

    this.editingPrintPriceEurSource.next(
      ComputeFields.computeNumberFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.printPriceEur
      )
    );
    this.editingWebPriceEurSource.next(
      ComputeFields.computeNumberFieldValue(
        this.selectedIds,
        this.itemsById,
        (i: ImportItem) => i.webPriceEur
      )
    );

    this.calculateSuffix();

    logging.info('import.service.ts', 'loadDataForSelectedItem');
  }

  /////////////////////////////
  // FIELDS
  /////////////////////////////

  getSuffixForCaption() {
    const author = ComputeFields.computeAutocompleteFieldValue(
      this.selectedIds,
      this.itemsById,
      (i: ImportItem) => i.author
    );
    const agency = ComputeFields.computeAutocompleteFieldValue(
      this.selectedIds,
      this.itemsById,
      (i: ImportItem) => i.agency
    );
    const people = ComputeFields.computeMultipleAutocompleteFieldValue(
      this.selectedIds,
      this.itemsById,
      (i: ImportItem) => i.people
    );

    if (
      author.value == -3 ||
      agency.value == -3 ||
      people.find((e) => e.value == -3) != undefined
    ) {
      return '<multiple values>';
    }

    if (
      author != undefined &&
      agency != undefined &&
      author?.value != -1 &&
      agency?.value != -1
    ) {
      let _author = author!.title;
      let _agency = agency!.title;
      let _people = people?.map((p) => p.title).join(', ');
      if (_people != undefined) {
        _people = _people + '\n';
      } else {
        _people = '';
      }
      return `${_people}Photo: ${_author}/${_agency}`;
    }

    return 'This will be calculated automatically.';
  }

  calculateSuffix() {
    const suffix = this.getSuffixForCaption();
    this.editingSuffixSource.next(suffix);
  }

  dismissErrorsAfterUserInput(field: string, value: string) {
    // TODO: API returns wrong field name.
    if (field === 'categories') {
      field = 'category';
    }

    if (this.currentErrorResponse === undefined) {
      return;
    }

    const errorToBeRemoved: ModelError[] = [];

    for (const error of this.currentErrorResponse!.errors) {
      // Clear error on editing.
      if (
        this.selectedIds.includes(parseInt(error.item_id!)) &&
        error.field_name === field
      ) {
        errorToBeRemoved.push(error);
      }
    }

    this.currentErrorResponse!.errors =
      this.currentErrorResponse!.errors.filter(
        (e) => !errorToBeRemoved.includes(e)
      );
    this.errorResponseSource.next(this.currentErrorResponse!);
    this.errorIdsSource.next(
      this.currentErrorResponse!.errors.map((e) => parseInt(e.item_id ?? '-1'))
    );
    this.refreshErrorData();
  }

  setField(field: string, value: any) {
    this.dismissErrorsAfterUserInput(field, value);

    let setter: (item: ImportItem) => void;

    if (this.selectedIds.length) {
      switch (field) {
        case 'videoTitle':
          setter = (item: ImportItem) => (item.videoTitle = value);
          break;

        case 'headline':
          setter = (item: ImportItem) => (item.headline = value);
          break;

        case 'caption':
          setter = (item: ImportItem) => (item.caption = value);
          break;

        case 'people':
          setter = (item: ImportItem) => (item.people = value);
          break;

        case 'keywords':
          setter = (item: ImportItem) => (item.keywords = value);
          break;

        case 'categories':
          setter = (item: ImportItem) => (item.categories = value);
          break;

        case 'author':
          setter = (item: ImportItem) => (item.author = value);
          break;

        case 'agency':
          setter = (item: ImportItem) => (item.agency = value);
          break;

        case 'tag':
          setter = (item: ImportItem) => (item.tag = value);
          break;

        case 'specialOffer':
          setter = (item: ImportItem) => (item.specialOffer = value);
          break;

        case 'specialOfferTypes':
          setter = (item: ImportItem) => (item.specialOfferTypes = value);
          break;

        case 'apiFtp':
          setter = (item: ImportItem) => (item.apiFtp = value);
          break;

        case 'licence':
          setter = (item: ImportItem) => (item.licence = value);
          break;

        case 'dateCreated':
          setter = (item: ImportItem) => (item.dateCreated = value);
          break;

        case 'needsTranslationEn':
          setter = (item: ImportItem) => (item.needsTranslationEn = value);
          break;

        case 'captionEn':
          setter = (item: ImportItem) => (item.captionEn = value);
          break;

        case 'keywordsEn':
          setter = (item: ImportItem) => (item.keywordsEn = value);
          break;

        case 'webPrice':
          setter = (item: ImportItem) => (item.webPrice = value);
          break;

        case 'printPrice':
          setter = (item: ImportItem) => (item.printPrice = value);
          break;

        case 'webPriceEur':
          setter = (item: ImportItem) => (item.webPriceEur = value);
          break;

        case 'printPriceEur':
          setter = (item: ImportItem) => (item.printPriceEur = value);
          break;
      }
    }

    let selectedItems = this.selectedIds
      .map((id) => this.itemsById[id])
      .filter((i) => i != undefined);
    selectedItems.forEach((item) => setter(item));

    this.calculateSuffix();

    this.queueSaveSession();
  }

  /////////////////////////////
  // Saving
  /////////////////////////////

  getHeadline(mediaItem: MediaItem): string {
    if (mediaItem.headline == undefined) return '';

    if (mediaItem.headline!.length == 0) return '';

    return mediaItem.headline![0].content ?? '';
  }

  checkedIsLocked(mediaItem: MediaItem) {
    if (mediaItem.editing_lock != undefined) {
      let raw: string =
        <string>(<unknown>mediaItem.editing_lock.locked_at!) + 'Z';
      let lockedAt = new Date(raw);
      let newDate = new Date();

      if (lockedAt != undefined) {
        let timeDiff = newDate.getTime() - lockedAt!.getTime();

        // More than 5 minutes.
        return timeDiff < 15 * 1000;
      }
      return;
    }

    return false;
  }

  lockedByName(mediaItem: MediaItem): string | undefined {
    if (this.checkedIsLocked(mediaItem)) {
      return (
        mediaItem.editing_lock!.locked_by!.first_name +
        ' ' +
        mediaItem.editing_lock!.locked_by!.last_name
      );
    }

    return undefined;
  }

  deserializeUploadSession(uploadSession: UploadSession): ImportItem[] {
    return uploadSession.media_list.map((uploadSessionItem) => {
      let width = 1;
      let height = 1;

      let agency: AutocompleteOption | undefined = undefined;
      if (uploadSessionItem.agency != undefined)
        agency = {
          title: uploadSessionItem.agency!.name!,
          value: uploadSessionItem.agency!.id!,
          isSelectable: true,
        };

      let author: AutocompleteOption | undefined = undefined;
      if (uploadSessionItem.author != undefined)
        author = {
          title: uploadSessionItem.author!.name,
          value: uploadSessionItem.author!.id,
          isSelectable: true,
        };

      let categories: AutocompleteOption | undefined = undefined;

      if (uploadSessionItem.category != undefined)
        categories = {
          title:
            uploadSessionItem.category!.name.find(
              (t: Translatable) => t.language == 'HR'
            )?.content ?? '',
          value: uploadSessionItem.category!.id!,
          isSelectable: true,
        };

      if (uploadSessionItem.agency != undefined)
        agency = {
          title: uploadSessionItem.agency!.name!,
          value: uploadSessionItem.agency!.id!,
          isSelectable: true,
        };

      let specialOfferTypes: AutocompleteOption[] | undefined = undefined;
      if (uploadSessionItem.special_offer_types != undefined)
        specialOfferTypes = uploadSessionItem.special_offer_types!.map((k) => {
          return {
            title:
              k.name?.find((t: Translatable) => t.language == 'HR')?.content ??
              '',
            value: k.id,
            isSelectable: true,
          };
        });

      let tag: AutocompleteOption | undefined = undefined;

      if (
        uploadSessionItem.tag_list != undefined &&
        uploadSessionItem.tag_list.length != 0
      )
        tag = {
          title:
            uploadSessionItem.tag_list[0].name.find(
              (t: Translatable) => t.language == 'HR'
            )?.content ?? '',
          value: uploadSessionItem.tag_list[0].id,
          isSelectable: true,
        };

      let object: ImportItem = {
        id: parseInt(uploadSessionItem.id),
        width: width,
        height: height,
        aspectRatio: uploadSessionItem.aspect_ratio,
        previewUrl: uploadSessionItem.preview?.preview_url,
        blurPreviewUrl: uploadSessionItem.preview?.preview_url_blur,
        largePreviewUrl: uploadSessionItem.preview?.preview_url_large,
        previewVideoLoop: uploadSessionItem.preview?.preview_video_loop,
        previewVideo720d: uploadSessionItem.preview?.preview_video_720p,

        isVideo: uploadSessionItem.preview?.preview_video_720p != null,
        isLocked: this.checkedIsLocked(uploadSessionItem),
        lockedBy: this.lockedByName(uploadSessionItem),

        title:
          uploadSessionItem.headline?.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',
        headline: {
          title: this.getHeadline(uploadSessionItem),
          value: -1,
          isSelectable: true,
        },

        caption:
          uploadSessionItem.caption?.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',
        people: uploadSessionItem.people_list!.map((p) => {
          return {
            title: p.name,
            value: p.id,
            isSelectable: true,
          };
        }),
        keywords: uploadSessionItem.keywords_list!.map((k) => {
          return {
            title:
              k.name?.find((t: Translatable) => t.language == 'HR')?.content ??
              '',
            value: k.id,
            isSelectable: true,
          };
        }),

        categories: categories,
        author: author,
        agency: agency,
        tag: tag,
        specialOffer: uploadSessionItem.is_special_offer,
        specialOfferTypes: specialOfferTypes,
        apiFtp: uploadSessionItem.api_ftp,

        printPrice: uploadSessionItem.print_price,
        webPrice: uploadSessionItem.web_price,

        videoTitle:
          uploadSessionItem.video_title?.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',

        dateCreated: uploadSessionItem.date_created,
        needsTranslationEn: uploadSessionItem.needs_translation,
        captionEn:
          uploadSessionItem.caption?.find(
            (t: Translatable) => t.language == 'EN'
          )?.content ?? '',
        keywordsEn: uploadSessionItem.keywords_list!.map((k) => {
          return {
            title:
              k.name?.find((t: Translatable) => t.language == 'EN')?.content ??
              '',
            value: k.id,
            isSelectable: true,
          };
        }),

        printPriceEur: uploadSessionItem.print_price_eur,
        webPriceEur: uploadSessionItem.web_price_eur,
      };

      return object;
    });
  }

  serializeUploadSession(): UploadSession {
    let mediaList: MediaItem[] = this.items.map((item) => {
      // Here we have to fill out such information and take in account
      // where we have a new upload item or if the upload item is something
      // from an old session.

      let agency: Agency | undefined = undefined;
      if (item.agency != undefined)
        agency = {
          id: item.agency.value,
          name: item.agency.title,
        };

      let author: Author | undefined = undefined;
      if (item.author != undefined)
        author = {
          id: item.author.value,
          name: item.author.title,
        };

      let category: Category | undefined = undefined;
      if (item.categories != undefined)
        category = {
          id: item.categories.value,
          name: [],
        };

      let keywordsList: Keyword[] = [];
      if (item.keywords != undefined)
        item.keywords.forEach((k) => {
          keywordsList.push({
            id: k.value,
            name: [{ language: 'HR', content: k.title }],
          });
        });

      if (item.keywordsEn != undefined)
        item.keywordsEn.forEach((k) => {
          keywordsList.push({
            id: k.value,
            name: [{ language: 'EN', content: k.title }],
          });
        });

      let licenceType: LicenceType | undefined = undefined;
      if (item.author != undefined)
        author = {
          id: item.author.value,
          name: item.author.title,
        };

      let peopleList: Array<Person> = [];
      if (item.people != undefined)
        item.people.forEach((k) => {
          peopleList.push({
            id: k.value,
            name: k.title,
          });
        });

      let specialOfferTypes: SpecialOfferType[] | undefined = undefined;
      if (item.specialOfferTypes != undefined)
        specialOfferTypes = item.specialOfferTypes.map((so) => {
          return {
            id: so.value,
            name: [],
          };
        });

      let tagList: Array<Tag> = [];
      if (item.tag != undefined)
        tagList = [
          {
            id: item.tag!.value,
            name: [],
          },
        ];

      let uploadItem: MediaItem = {
        // This is required, but not for sending to the server.
        editing_lock: undefined,
        has_preview: false,
        kind: 'PHOTO',

        id: item.id.toString(),
        agency: agency,
        api_ftp: item.apiFtp,
        aspect_ratio: undefined,
        author: author,

        caption: [
          { language: 'HR', content: item.caption },
          { language: 'EN', content: item.captionEn },
        ],
        category: category,
        date_created: undefined, // Backend team needs to calc this
        gallery_id: item.headline?.value,
        headline: [{ language: 'HR', content: item.headline?.title }],
        video_title: [{ language: 'HR', content: item.videoTitle }],
        is_special_offer: item.specialOffer ?? false,
        keywords_list: keywordsList,
        needs_translation: item.needsTranslationEn,
        people_list: peopleList,
        preview: {
          preview_url: item.previewUrl,
          preview_url_blur: item.blurPreviewUrl,
          preview_url_large: item.largePreviewUrl,
          preview_video_720p: item.previewVideo720d,
          preview_video_loop: item.previewVideoLoop,
        },
        special_offer_types: specialOfferTypes,
        tag_list: tagList,
        print_price: item.printPrice,
        web_price: item.webPrice,
        web_price_eur: item.webPriceEur,
        print_price_eur: item.printPriceEur,
      };

      return uploadItem;
    });

    return {
      media_list: mediaList,
    };
  }

  public queueSaveSession() {
    this.saveQueueSource.next(void 0);
  }

  private setUpSaveSession() {
    this.saveQueueData
      .pipe(
        skipWhile((x) => this.isSaving),
        bufferTime(1000)
      )
      .subscribe((arr) => {
        if (arr.length == 0) return;

        this.saveSession();
      });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // UPLOADING FILES
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public uploadFile(files: any) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);

      this.uploadService
        .cmsUploadUploadSessionFile(item, 'events', true)
        .subscribe((result: any) => {
          if (result.type == 1) {
            item.progress = (result.loaded / result.total) * 100.0;

            // It's finished.
            if (item.progress >= 100.0) {
              this.files = this.files.filter((file) => file !== item);
              this.uploadingFilesSource.next(this.files);

              this.loadSession();
            }
          }
        });
    }

    this.uploadingFilesSource.next(this.files);
  }

  public replaceFile(files: any, itemId: number) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      // TODO: Implement
      this.uploadService
        .cmsReplaceMediaMedia(itemId, item, 'events', true)
        .subscribe((result: any) => {
          if (result.type == 1) {
            item.progress = (result.loaded / result.total) * 100.0;

            // It's finished.
            if (item.progress >= 100.0) {
              this.files = this.files.filter((file) => file !== item);
              this.uploadingFilesSource.next(this.files);

              window.location.reload();
            }
          }
        });
    }

    this.uploadingFilesSource.next(this.files);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // REORDERING
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private move(array: any[], oldIndex: number, newIndex: number) {
    let temp = array[newIndex];
    array[newIndex] = array[oldIndex];
    array[oldIndex] = temp;
  }

  public moveItemFromIndexToIndex(oldIndex: number, newIndex: number) {
    this.move(this.items, oldIndex, newIndex);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ACTIONS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceInString(string: string, needle: string, withValue: string): string {
    const escapedRegexNeedle = this.escapeRegExp(needle);
    return string.replace(new RegExp(escapedRegexNeedle, 'gi'), withValue);
  }

  replace(
    field: string,
    what: AutocompleteOption,
    withWhat: AutocompleteOption
  ) {
    this.items.forEach((item) => {
      if (!this.selectedIds.includes(item.id)) return;

      var dirtyItem = false;

      switch (field) {
        case 'people':
          if (item.people?.find((p) => p.value == what.value)) {
            item.people = item.people.map((ao) => {
              if (ao.value == what.value) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          break;

        case 'keywords':
          if (item.keywords?.find((p) => p.title == what.title)) {
            item.keywords = item.keywords.map((ao) => {
              if (ao.title == what.title) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          if (item.keywordsEn?.find((p) => p.value == what.value)) {
            item.keywordsEn = item.keywordsEn.map((ao) => {
              if (ao.value == what.value) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          break;

        case 'headline':
          if (item?.headline?.title.includes(what.title)) {
            dirtyItem = true;
            if (item.headline != undefined) {
              item.headline = {
                title: this.replaceInString(
                  item.headline!.title,
                  what.title,
                  withWhat.title
                ),
                isSelectable: item.headline!.isSelectable,
                value: item.headline!.value,
              };
              item.title = item.headline!.title;
            }
          }

          break;

        case 'categories':
          if (item.categories?.value == what.value) {
            dirtyItem = true;
            item.categories = withWhat;
          }

          break;

        case 'author':
          if (item.author?.value == what.value) {
            dirtyItem = true;
            item.author = withWhat;
          }

          break;

        case 'agency':
          if (item.agency?.value == what.value) {
            dirtyItem = true;
            item.agency = withWhat;
          }

          break;

        case 'tag':
          if (item.tag?.value == what.value) {
            dirtyItem = true;
            item.tag = withWhat;
          }

          break;

        case 'caption':
          if (item.caption?.includes(what.title)) {
            dirtyItem = true;
            item.caption = this.replaceInString(
              item.caption!,
              what.title,
              withWhat.title
            );
          }

          break;
      }
    });

    this.loadDataForSelectedItem();
    this.queueSaveSession();
  }

  append(field: string, what: AutocompleteOption) {
    this.items.forEach((item) => {
      if (!this.selectedIds.includes(item.id)) return;

      switch (field) {
        case 'people':
          this.itemsById[item.id].people = this.itemsById[
            item.id
          ].people?.concat([what]);
          break;

        case 'keywords':
          this.itemsById[item.id].keywords = this.itemsById[
            item.id
          ].keywords?.concat([what]);
          break;

        case 'keywordsEn':
          this.itemsById[item.id].keywordsEn = this.itemsById[
            item.id
          ].keywordsEn?.concat([what]);
          break;

        case 'caption':
          this.itemsById[item.id].caption += what.title;
          break;
      }
    });

    this.loadDataForSelectedItem();
    this.queueSaveSession();
  }
}
