/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FinanceCalculation {
  id?: number;
  date_created?: string;
  created_by?: string;
  currency?: string;
  user?: string;
  date_from?: string;
  date_to?: string;
  comment?: string;
}
