<div [formGroup]="form" class="single-cms-input-form">
  <label
    for="{{ controlName }}"
    [class.disabled]="type === InputType.Disabled"
    >{{ label }}</label
  >
  <label class="select-input">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="InputType.Date">
        <input
          type="date"
          id="{{ controlName }}"
          name="{{ controlName }}"
          formControlName="{{ controlName }}"
          [valueAsDate]
          [class.invalid]="
            this.form.get(controlName)?.invalid &&
            (this.form.get(controlName)?.dirty ||
              this.form.get(controlName)?.touched)
          "
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Date2">
        <dp-date-picker
          [config]="dtConfig"
          theme="dp-material"
          class="date-picker"
          [mode]="'daytime'"
          id="{{ controlName }}"
          formControlName="{{ controlName }}"
          [class.invalid-dp]="
            this.form.get(controlName)?.invalid &&
            (this.form.get(controlName)?.dirty ||
              this.form.get(controlName)?.touched)
          "
        ></dp-date-picker>
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Text">
        <input
          type="text"
          id="{{ controlName }}"
          name="{{ controlName }}"
          formControlName="{{ controlName }}"
          [class.invalid]="
            this.form.get(controlName)?.invalid &&
            (this.form.get(controlName)?.dirty ||
              this.form.get(controlName)?.touched)
          "
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Disabled">
        <input
          class="disabled"
          type="text"
          id="{{ controlName }}"
          name="{{ controlName }}"
          formControlName="{{ controlName }}"
          [attr.disabled]="'disabled'"
          [class.invalid]="
            this.form.get(controlName)?.invalid &&
            (this.form.get(controlName)?.dirty ||
              this.form.get(controlName)?.touched)
          "
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Password">
        <input
          #password
          formControlName="{{ controlName }}"
          type="password"
          (focus)="passwordFocus(true)"
          (blur)="passwordFocus(false)"
          (input)="touchPassword()"
          [class.invalid]="
            this.form.get(controlName)?.invalid &&
            (this.form.get(controlName)?.dirty ||
              this.form.get(controlName)?.touched)
          "
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Number">
        <input
          type="number"
          id="{{ controlName }}"
          name="{{ controlName }}"
          formControlName="{{ controlName }}"
          [class.invalid]="
            this.form.get(controlName)?.invalid &&
            (this.form.get(controlName)?.dirty ||
              this.form.get(controlName)?.touched)
          "
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Select">
        <select
          name="{{ controlName }}"
          id="{{ controlName }}"
          formControlName="{{ controlName }}"
          [attr.required]="isRequiredField()"
          [class.invalid-input]="
            form.get(controlName)?.dirty && form.get(controlName)?.invalid
          "
          [class.invalid-input2]="wasSubmited && form.get(controlName)?.invalid"
          [class.invalid]="
            this.form.get(controlName)?.invalid &&
            (this.form.get(controlName)?.dirty ||
              this.form.get(controlName)?.touched)
          "
          [class.placeholder]="!selectWasFocused"
          (focus)="onSelectFocus()"
        >
          <option disabled selected value=""></option>
          <option
            *ngFor="let option of selectOptions | keyvalue"
            [value]="option.key"
          >
            {{ option.value }}
          </option>
        </select>
        <img class="select-icon" src="/assets/select-icon.svg" alt="" />
      </ng-container>
      <ng-container *ngSwitchCase="InputType.SelectNumeric">
        <select
          name="{{ controlName }}"
          id="{{ controlName }}"
          formControlName="{{ controlName }}"
          [attr.required]="isRequiredField()"
          [class.invalid-input]="
            form.get(controlName)?.dirty && form.get(controlName)?.invalid
          "
          [class.invalid-input2]="wasSubmited && form.get(controlName)?.invalid"
          [class.invalid]="
            this.form.get(controlName)?.invalid &&
            (this.form.get(controlName)?.dirty ||
              this.form.get(controlName)?.touched)
          "
          [class.placeholder]="!selectWasFocused"
          (focus)="onSelectFocus()"
        >
          <option disabled selected value=""></option>
          <option
            *ngFor="let option of selectOptions | keyvalue"
            [ngValue]="option.key | toNumber"
          >
            {{ option.value }}
          </option>
        </select>
        <img class="select-icon" src="/assets/select-icon.svg" alt="" />
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Autocomplete">
        <app-field-text
          class="filter"
          label=""
          theme="filter"
          type="search"
          autocomplete="off"
          [autocompleteType]="autocompleteType"
          [minimalAutocompleteLength]="0"
          [autocompleteValue]="placeholder | asAutocompleteOption"
          (autocompleteValueChange)="changeAutocomplete($event)"
        ></app-field-text>
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Boolean">
        <select
          name="{{ controlName }}"
          id="{{ controlName }}"
          formControlName="{{ controlName }}"
          [attr.required]="isRequiredField()"
          [class.invalid-input]="
            form.get(controlName)?.dirty && form.get(controlName)?.invalid
          "
          [class.invalid-input2]="wasSubmited && form.get(controlName)?.invalid"
          [class.placeholder]="!selectWasFocused"
          (focus)="onSelectFocus()"
        >
          <option disabled selected value=""></option>
          <option [value]="true">Yes</option>
          <option [value]="false">No</option>
        </select>
        <img class="select-icon" src="/assets/select-icon.svg" alt="" />
      </ng-container>
      <ng-container *ngSwitchCase="InputType.Tags">
        <app-field-tags
          class="filter"
          placeholder="Categories"
          theme="filter"
          [autocompleteType]="autocompleteType"
          [tags]="getTags()"
          (tagsChange)="this.form.get(controlName)!.setValue($event!)"
        ></app-field-tags>
      </ng-container>
      <ng-container *ngSwitchCase="InputType.ImageUpload">
        <img
          class="avatar"
          src="{{
            form.get(controlName)!.value || '/assets/default-avatar.svg'
          }}"
          alt=""
        />
        <label class="avatar-btn" for="{{ controlName }}">
          <input
            type="file"
            name="{{ controlName }}"
            id="{{ controlName }}"
            (change)="handleFileInput($event)"
            accept="image/png, image/jpeg"
          />
          Change photo
        </label>
      </ng-container>
    </ng-container>
  </label>
  <div
    *ngIf="
      this.form.get(controlName)?.invalid &&
      (this.form.get(controlName)?.dirty || this.form.get(controlName)?.touched)
    "
    class="error-msg"
  >
    * Field is required!
  </div>
</div>
