/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DownloadLinkResponse } from '../model/downloadLinkResponse';
import { ErrorResponse } from '../model/errorResponse';
import { ProductPriceCalculation } from '../model/productPriceCalculation';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ProductsFrontService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Download product from the CMS, only possible for CMS users
   * @param id ID
   * @param kind
   * @param has_author_signature
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDownloadCMSProduct(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public cmsDownloadCMSProduct(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public cmsDownloadCMSProduct(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public cmsDownloadCMSProduct(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsDownloadCMSProduct.'
      );
    }

    if (kind === null || kind === undefined) {
      throw new Error(
        'Required parameter kind was null or undefined when calling cmsDownloadCMSProduct.'
      );
    }

    if (has_author_signature === null || has_author_signature === undefined) {
      throw new Error(
        'Required parameter has_author_signature was null or undefined when calling cmsDownloadCMSProduct.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }
    if (has_author_signature !== undefined && has_author_signature !== null) {
      queryParameters = queryParameters.set(
        'has_author_signature',
        <any>has_author_signature
      );
    }
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'post',
      `${this.basePath}/cms/products/${encodeURIComponent(
        String(id)
      )}/download/cms`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Calculate product prices and options
   * @param id ID
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontCalculateProductPrice(
    id: string,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductPriceCalculation>;
  public frontCalculateProductPrice(
    id: string,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductPriceCalculation>>;
  public frontCalculateProductPrice(
    id: string,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductPriceCalculation>>;
  public frontCalculateProductPrice(
    id: string,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling frontCalculateProductPrice.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProductPriceCalculation>(
      'get',
      `${this.basePath}/products/${encodeURIComponent(String(id))}/calculate`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Create a download link
   * @param id ID
   * @param kind
   * @param has_author_signature
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontDownloadFullCreateLink(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DownloadLinkResponse>;
  public frontDownloadFullCreateLink(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DownloadLinkResponse>>;
  public frontDownloadFullCreateLink(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DownloadLinkResponse>>;
  public frontDownloadFullCreateLink(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling frontDownloadFullCreateLink.'
      );
    }

    if (kind === null || kind === undefined) {
      throw new Error(
        'Required parameter kind was null or undefined when calling frontDownloadFullCreateLink.'
      );
    }

    if (has_author_signature === null || has_author_signature === undefined) {
      throw new Error(
        'Required parameter has_author_signature was null or undefined when calling frontDownloadFullCreateLink.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }
    if (has_author_signature !== undefined && has_author_signature !== null) {
      queryParameters = queryParameters.set(
        'has_author_signature',
        <any>has_author_signature
      );
    }
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DownloadLinkResponse>(
      'post',
      `${this.basePath}/products/${encodeURIComponent(
        String(id)
      )}/download/full/link`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * [DEPRICATED] Download full product and charge the user
   * @param id ID
   * @param kind
   * @param has_author_signature
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontDownloadFullProduct(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public frontDownloadFullProduct(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public frontDownloadFullProduct(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public frontDownloadFullProduct(
    id: number,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling frontDownloadFullProduct.'
      );
    }

    if (kind === null || kind === undefined) {
      throw new Error(
        'Required parameter kind was null or undefined when calling frontDownloadFullProduct.'
      );
    }

    if (has_author_signature === null || has_author_signature === undefined) {
      throw new Error(
        'Required parameter has_author_signature was null or undefined when calling frontDownloadFullProduct.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }
    if (has_author_signature !== undefined && has_author_signature !== null) {
      queryParameters = queryParameters.set(
        'has_author_signature',
        <any>has_author_signature
      );
    }
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'post',
      `${this.basePath}/products/${encodeURIComponent(
        String(id)
      )}/download/full`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Download product preview
   * @param id ID
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontDownloadProductPreview(
    id: string,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public frontDownloadProductPreview(
    id: string,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public frontDownloadProductPreview(
    id: string,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public frontDownloadProductPreview(
    id: string,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling frontDownloadProductPreview.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'post',
      `${this.basePath}/products/${encodeURIComponent(
        String(id)
      )}/download/preview`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json', // manual changes
      }
    );
  }

  /**
   *
   * Download full product and charge the user
   * @param tokenId Token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontDownloadWithToken(
    tokenId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public frontDownloadWithToken(
    tokenId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public frontDownloadWithToken(
    tokenId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public frontDownloadWithToken(
    tokenId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (tokenId === null || tokenId === undefined) {
      throw new Error(
        'Required parameter tokenId was null or undefined when calling frontDownloadWithToken.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'get',
      `${this.basePath}/products/download/token/${encodeURIComponent(
        String(tokenId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Run a product action available in the front
   * @param id ID
   * @param action
   * @param kind
   * @param has_author_signature
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontRunProductAction(
    id: number,
    action: string,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public frontRunProductAction(
    id: number,
    action: string,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public frontRunProductAction(
    id: number,
    action: string,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public frontRunProductAction(
    id: number,
    action: string,
    kind: string,
    has_author_signature: boolean,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling frontRunProductAction.'
      );
    }

    if (action === null || action === undefined) {
      throw new Error(
        'Required parameter action was null or undefined when calling frontRunProductAction.'
      );
    }

    if (kind === null || kind === undefined) {
      throw new Error(
        'Required parameter kind was null or undefined when calling frontRunProductAction.'
      );
    }

    if (has_author_signature === null || has_author_signature === undefined) {
      throw new Error(
        'Required parameter has_author_signature was null or undefined when calling frontRunProductAction.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }
    if (has_author_signature !== undefined && has_author_signature !== null) {
      queryParameters = queryParameters.set(
        'has_author_signature',
        <any>has_author_signature
      );
    }
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/products/${encodeURIComponent(
        String(id)
      )}/actions/${encodeURIComponent(String(action))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
