<ng-container *ngFor="let row of galleryMatrix">
  <div class="image-row">
    <ng-container *ngFor="let element of row">
      <div class="image-container">
        <app-single-image
          class="image"
          [imageLoadingPreviewUrl]="element.blurPreviewUrl"
          [imagePreviewUrl]="element.previewUrl"
          (onImageLoaded)="onImageLoad($event)"
        >
        </app-single-image>
        <!-- <div class="overlay">
          <div class="overlay-text">
            <div class="overlay-text-top">{{ element.timestamp }}</div>
            <div class="overlay-text-bottom">{{ element.title }}</div>
          </div>
          <button></button>
        </div> -->
      </div>
    </ng-container>
  </div>
</ng-container>
