<div class="search-container">
  <div class="search" style="padding-left: 30px">
    <input
      type="text"
      [placeholder]="getPlaceholder()"
      class="search-input"
      (keydown.enter)="onEnter()"
      [(ngModel)]="searchQuery"
    />
    <button class="search-btn" (click)="onEnter()"></button>
  </div>
</div>

<!-- Pretraži video ponudu Pretraži ponudu agencija Pretraži ponudu produkcije -->
