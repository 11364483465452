import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sidebar-checkbox-list',
  templateUrl: './sidebar-checkbox-list.component.html',
  styleUrls: ['./sidebar-checkbox-list.component.scss'],
})
export class SidebarCheckboxListComponent implements OnInit {
  @Input() title: string = '';
  @Input() controlNameArray: string[] = [];
  @Input() labelArray: string[] = [];
  @Input() form!: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    for (let i = 0; i < this.controlNameArray.length; i++) {
      this.form.addControl(
        this.controlNameArray[i],
        this.formBuilder.control(false)
      );
    }
  }
}
