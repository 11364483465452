/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CmsEditingCompany {
  id?: number;
  created_at?: Date;
  additional_invoice_text?: string;
  address_line_1?: string;
  city?: string;
  contact_user_id?: number;
  contact_user_name?: string;
  country_id?: number;
  currency_id?: number;
  email?: string;
  hide_discounts?: boolean;
  i_c?: string;
  invoice_n_r?: boolean;
  invoice_sent_at_email?: string;
  invoice_text?: string;
  mbs?: string;
  name?: string;
  note?: string;
  oib_company?: string;
  payment_due_in_days?: number;
  payment_method?: string;
  payment_model?: string;
  phone?: string;
  postal_number?: string;
  press_magazine_name?: string;
  pxl_user_id?: number;
  pxl_user_name?: number;
  sap_indicator?: string;
  sap_number?: string;
  state?: string;
  status?: string;
  tax_number?: string;
  vat_number?: string;
  contact_email?: string;
  contact_person?: string;
  contact_phone?: string;
  dont_create_invoice?: boolean;
  fax?: string;
  send_to_system?: boolean;
  send_to_system_ftp_path?: string;
  send_to_cms?: boolean;
  send_to_cms_api_key?: string;
  send_to_cms_url?: string;
  telephone?: string;
  /**
   * What kind of stuff the can download
   */
  download_type_permissions?: Array<CmsEditingCompany.DownloadTypePermissionsEnum>;
  lang?: CmsEditingCompany.LangEnum;
  override_name?: string;
  override_contact_person?: string;
  are_downloads_blocked?: boolean;
  is_second_step_auth_enabled?: boolean;
}
export namespace CmsEditingCompany {
  export type DownloadTypePermissionsEnum =
    | 'PHOTO'
    | 'PHOTO_LARGE'
    | 'PHOTO_LARGE_SIGNATURE'
    | 'PHOTO_SMALL'
    | 'PHOTO_SMALL_SIGNATURE'
    | 'VIDEO';
  export const DownloadTypePermissionsEnum = {
    PHOTO: 'PHOTO' as DownloadTypePermissionsEnum,
    PHOTOLARGE: 'PHOTO_LARGE' as DownloadTypePermissionsEnum,
    PHOTOLARGESIGNATURE: 'PHOTO_LARGE_SIGNATURE' as DownloadTypePermissionsEnum,
    PHOTOSMALL: 'PHOTO_SMALL' as DownloadTypePermissionsEnum,
    PHOTOSMALLSIGNATURE: 'PHOTO_SMALL_SIGNATURE' as DownloadTypePermissionsEnum,
    VIDEO: 'VIDEO' as DownloadTypePermissionsEnum,
  };
  export type LangEnum = 'EN' | 'HR';
  export const LangEnum = {
    EN: 'EN' as LangEnum,
    HR: 'HR' as LangEnum,
  };
}
