import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ScrollService } from '../scroll.service';

@Component({
  selector: 'app-horizontal-scroll',
  templateUrl: './horizontal-scroll.component.html',
  styleUrls: ['./horizontal-scroll.component.scss'],
})
export class HorizontalScrollComponent implements OnInit {
  @ViewChild('innerContainer') innerContainer!: ElementRef;
  @ViewChild('content') content!: ElementRef;

  images: Array<string> = [];

  constructor(private scrollService: ScrollService) {}

  ngOnInit(): void {
    this.scrollService.scrollSignalData.subscribe((response) => {
      const offsetTop = this.innerContainer.nativeElement.offsetTop;
      this.content.nativeElement.style.transform = `translateX(${-offsetTop}px)`;
    });

    this.setImages();
  }

  @HostListener('window:resize', []) resizeImages() {
    this.setImages();
  }

  setImages() {
    if (window.innerWidth > 800) {
      this.images = [
        '/assets/about-us/jpegs/usluge01.jpg',
        '/assets/about-us/jpegs/usluge02.jpg',
        '/assets/about-us/jpegs/usluge03.jpg',
        '/assets/about-us/jpegs/usluge04.jpg',
        '/assets/about-us/jpegs/usluge05.jpg',
        '/assets/about-us/jpegs/usluge06.jpg',
      ];
    } else {
      this.images = [
        '/assets/about-us/about1_mobile.png',
        '/assets/about-us/about2_mobile.png',
        '/assets/about-us/about3_mobile.png',
        '/assets/about-us/about4_mobile.png',
        '/assets/about-us/about5_mobile.png',
        '/assets/about-us/about6_mobile.png',
      ];
    }
  }
}
