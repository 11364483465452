import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicPrice',
})
export class DynamicPricePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    const numberValue = value as number;

    let digits = 2;
    if (args.length !== 0 && args[0] !== undefined) {
      digits = args[0] as number;
    }

    const roundedNumberValue =
      Math.round((numberValue + Number.EPSILON) * Math.pow(10, digits)) /
      Math.pow(10, digits);

    return roundedNumberValue?.toLocaleString('de-DE', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  }
}
