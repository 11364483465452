import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/app/shared/environment';

@Pipe({
  name: 'pixsellPreviewUrl',
})
export class PixsellPreviewUrlPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == undefined) return '';

    return environment.productServingUrl + value ?? '';
  }
}
