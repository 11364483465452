import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-gallery-detailed-card',
  templateUrl: './gallery-detailed-card.component.html',
  styleUrls: ['./gallery-detailed-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryDetailedCardComponent implements OnInit {
  @Input() cardData: any = {};

  constructor() {}

  ngOnInit(): void {}
}
