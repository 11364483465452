/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DynamicViewDisplayTab } from './dynamicViewDisplayTab';
import { DynamicViewField } from './dynamicViewField';
import { DynamicViewValidation } from './dynamicViewValidation';

export interface DynamicView {
  path?: string;
  id?: string;
  title?: string;
  kind?: DynamicView.KindEnum;
  fields?: Array<DynamicViewField>;
  allowed_actions?: Array<DynamicView.AllowedActionsEnum>;
  filters?: Array<DynamicViewField>;
  required_fields?: Array<string>;
  field_validations?: Array<DynamicViewValidation>;
  record_show_path?: string;
  record_edit_path?: string;
  record_create_path?: string;
  display_tabs?: Array<DynamicViewDisplayTab>;
}
export namespace DynamicView {
  export type KindEnum = 'INDEX' | 'VIEW_EDIT' | 'CREATE';
  export const KindEnum = {
    INDEX: 'INDEX' as KindEnum,
    VIEWEDIT: 'VIEW_EDIT' as KindEnum,
    CREATE: 'CREATE' as KindEnum,
  };
  export type AllowedActionsEnum =
    | 'CREATE'
    | 'READ'
    | 'UPDATE'
    | 'DELETE'
    | 'INDEX';
  export const AllowedActionsEnum = {
    CREATE: 'CREATE' as AllowedActionsEnum,
    READ: 'READ' as AllowedActionsEnum,
    UPDATE: 'UPDATE' as AllowedActionsEnum,
    DELETE: 'DELETE' as AllowedActionsEnum,
    INDEX: 'INDEX' as AllowedActionsEnum,
  };
}
