import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-bar-solo',
  templateUrl: './search-bar-solo.component.html',
  styleUrls: ['./search-bar-solo.component.scss'],
})
export class SearchBarSoloComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() searchRoute: string = '';
  public searchQuery: string = '';
  @Output() searchEvent: EventEmitter<void> = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onEnter() {
    if (this.searchQuery !== '') {
      this.router.navigate([this.searchRoute], {
        queryParams: { q: this.searchQuery },
      });
      this.searchEvent.emit();
    }
  }
}
