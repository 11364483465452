<div [ngClass]="'theme-' + theme">
  <div class="field-title">{{ title }}</div>

  <div class="field-group-container">
    <div class="field-container" [class.disabled]="disabled">
      <textarea
        type="text"
        #textarea
        [rows]="5"
        [(ngModel)]="value"
        [disabled]="disabled"
        (ngModelChange)="onChange(value)"
      >
      </textarea>
      <div class="field-suffix-container" *ngIf="suffix">
        <textarea
          class="field-suffix"
          type="text"
          [rows]="2"
          [(ngModel)]="suffix"
          [disabled]="true"
        >
        </textarea>
      </div>
    </div>
    <div
      #actionsToggleEl
      class="field-action-toggle"
      (click)="openActions()"
      *ngIf="actions.length > 0"
    ></div>
  </div>

  <!-- Errors -->
  <div *ngIf="errors != undefined && errors.length > 0" class="field-errors">
    <div *ngFor="let error of errors" class="field-error">
      {{ error.error_description }}
    </div>
  </div>
</div>

<ul
  #actionsEl
  class="field-autocomplete"
  style="position: absolute; top: 0px; left: 0px"
  [style.visibility]="shouldShowActionDropdown ? 'visible' : 'hidden'"
  [style.width.px]="actionsDropdownWidth"
>
  <li *ngFor="let action of actions" (mousedown)="onActionClick(action)">
    {{ action.title }}
  </li>
</ul>
