import { Subject } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Action } from './field-text/field-text.component';
import { ModelError } from '../../../projects/piwe-front-swagger-client/src';

@Component({ template: '' })
export class CmsComponent implements OnInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  @Input() title: string = '';

  @Input() disabled: boolean = false;
  @Input() errors: ModelError[] = [];
  @Input() theme: string = 'default';

  @Output() actionSelected: EventEmitter<{
    field: string;
    action: Action;
  }> = new EventEmitter<{
    field: string;
    action: Action;
  }>();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
