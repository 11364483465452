import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { SrvService } from '../profile/srv.service';
import { AuthenticationService } from '../../authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from '../../permissions.service';
import { User } from '../../../../projects/piwe-front-swagger-client/src';
import UserPermissionsEnum = User.UserPermissionsEnum;
import { FilterRememberingService } from '../filter-remembering.service';
import {
  LangChangeEvent,
  TranslateService,
  TranslationChangeEvent,
} from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('fadeModal', [
      state(
        'fadeIn',
        style({
          opacity: 1,
        })
      ),
      transition(':enter', [
        style({
          display: 'block',
          opacity: 0,
        }),
        animate('0.2s ease-in'),
      ]),
      transition(':leave', [
        animate('0.2s ease-in'),
        style({
          display: 'none',
          opacity: 0,
        }),
      ]),
    ]),
    trigger('openCloseIcon', [
      state(
        'openIcon',
        style({
          transform: 'rotate(180deg)',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'ease-in',
          filter:
            'invert(67%) sepia(44%) saturate(3754%) hue-rotate(348deg) brightness(100%) contrast(94%)',
        })
      ),
      state(
        'closedIcon',
        style({
          transform: 'rotate(0deg)',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'ease-in',
        })
      ),
      transition('open <=> closed', [animate('0.2s ease-in')]),
    ]),
    trigger('colorOrange', [
      state(
        'openIcon',
        style({
          color: '#F68B1E',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'ease-in',
        })
      ),
      state(
        'closedIcon',
        style({
          transitionDuration: '0.2s',
          transitionTimingFunction: 'ease-in',
        })
      ),
      transition('open <=> closed', [animate('0.2s ease-in')]),
    ]),
  ],
})
export class NavbarComponent implements OnInit {
  user: any;
  chooseAction: boolean = true;
  @Input() isLoggedIn: boolean = true;
  public isAboutUsMenuOpen: boolean = false;
  isReutersVisible: boolean = false;

  isLoaded: boolean = false;

  public isHamburgerMenuOpen: boolean = false;

  public currentSearchSubPortal: string | undefined;
  public currentLang: string = '';
  public isLanguageModalOpen: boolean = false;
  private languageSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private srv: SrvService,
    private authenticationService: AuthenticationService,
    private permissionsService: PermissionsService,
    private router: Router,
    private filterRememberingService: FilterRememberingService,
    private translationService: TranslateService
  ) {
    this.permissionsService.permissionsData.subscribe((data) => {
      if (data != undefined) {
        this.isLoaded = true;
        if (data!.includes(UserPermissionsEnum.FREUTERSREAD))
          this.isReutersVisible = false; // https://smartcodeeu.atlassian.net/browse/PSD-424, removing Reuters cause client asked.
      }
    });
  }

  ngOnInit(): void {
    this.srv.getCurrentUser().subscribe((user) => {
      this.user = user;
    });

    this.router.events.subscribe((_) => {
      this.currentSearchSubPortal =
        this.filterRememberingService.searchSubPortal;
      this.closeHamburgerMenu();
    });

    this.currentLang = this.translationService.currentLang;

    this.languageSubscription = this.translationService.onLangChange.subscribe(
      (params: LangChangeEvent) => {
        this.currentLang = params.lang;
      }
    );
  }

  ngOnDestroy() {
    document
      .querySelector('.main-app-container')!
      .classList.remove('disable-overflow');
    this.languageSubscription.unsubscribe();
  }

  openModal() {
    this.chooseAction = false;
  }

  closeModalWindow() {
    this.chooseAction = true;
  }

  logOut() {
    this.authenticationService.logOut();
    this.closeModalWindow();
  }

  stopModalPropagation(el: Event) {
    el.stopPropagation();
  }

  openAboutUsMenu() {
    this.isAboutUsMenuOpen = true;
  }
  closeAboutUsMenu(event: MouseEvent) {
    event.stopPropagation();
    this.isAboutUsMenuOpen = false;
  }

  openHamburgerMenu() {
    this.isHamburgerMenuOpen = true;

    document
      .querySelector('.main-app-container')!
      .classList.add('disable-overflow');
  }
  closeHamburgerMenu() {
    this.isHamburgerMenuOpen = false;

    document
      .querySelector('.main-app-container')!
      .classList.remove('disable-overflow');
  }
  changeLanguage(lang: 'en' | 'hr', event: MouseEvent) {
    event.stopPropagation();
    //change language here
    this.translationService.use(lang);

    this.isLanguageModalOpen = false;
    this.closeHamburgerMenu();
  }

  openLanguageModal() {
    this.isLanguageModalOpen = true;
  }
  closeLanguageModal() {
    this.isLanguageModalOpen = false;
  }
  closeDropdownMenus(event: MouseEvent) {
    event.stopPropagation();

    this.isAboutUsMenuOpen = false;
    this.isLanguageModalOpen = false;
  }
}
