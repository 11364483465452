import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ProfileService } from '../../profile.service';
import {
  EuroPhase,
  RemoteSettingsService,
  Settings,
} from '../../remote-settings.service';
import { User } from 'piwe-front-swagger-client';

@Component({
  selector: 'app-d-price',
  templateUrl: './dynamic-price.component.html',
  styleUrls: ['./dynamic-price.component.scss'],
})
export class DynamicPriceComponent implements OnInit {
  public readonly euroPhaseEnum: typeof EuroPhase = EuroPhase;

  @Input() theme = 'default';
  @Input() value?: number;
  @Input() alternativeValue?: number;
  @Input() currency?: string;

  @Input() currencyLabel?: string;

  @Input() euroPhase?: any;
  @Input() euroRate?: any;

  @Input() useCurrentEuroPhase = false;

  public usedExchangeRate: number | undefined;
  public usedEuroPhase: number | undefined;

  public settings?: Settings;

  constructor(
    private profileService: ProfileService,
    private remoteSettingsService: RemoteSettingsService
  ) {}

  ngOnInit(): void {
    this.remoteSettingsService.settingsObservable.subscribe((settings) => {
      this.settings = settings;

      this.usedExchangeRate = settings!.euroRate!;

      if (this.euroRate !== undefined && this.euroRate !== null)
        this.usedExchangeRate = this.euroRate;

      this.profileService.getProfile();
      this.profileService.profileData.subscribe((profile) => {
        if (this.currency !== undefined) {
          this.currencyLabel = this.currencyLabelForCurrency(this.currency);
        } else {
          this.currencyLabel = '';
        }

        if (this.euroPhase !== undefined) {
          switch (this.euroPhase) {
            case 'HRK_ONLY':
              this.usedEuroPhase = 0;
              break;
            case 'BIG_HRK_SMALL_EUR':
              this.usedEuroPhase = 1;
              break;
            case 'BIG_EUR_SMALL_HRK':
              this.usedEuroPhase = 2;
              break;
            case 'EUR_ONLY':
              this.usedEuroPhase = 3;
              break;
          }
        } else if (
          this.useCurrentEuroPhase &&
          settings &&
          profile &&
          profile.currency_name === 'HRK'
        ) {
          // Only show the current used Euro phase if the user has their currency set as HRK.
          this.usedEuroPhase = settings.euroPhase.valueOf();
        }
      });
    });
  }

  currencyLabelForCurrency(currency: any | undefined): string {
    switch (currency) {
      case 'HRK':
        return 'kn';
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 1:
        return 'kn';
      case '2':
        return '€';
      default:
        return '';
    }
  }

  computeCurrencyLabel(
    settings: Settings | undefined,
    response: User | undefined
  ): string {
    if (response?.currency_name !== undefined) {
      /*if (settings?.euroPhase.valueOf() === EuroPhase.EUR_ONLY.valueOf() && response?.currency_name === 'HRK') {
        return '€';
      }*/

      return this.currencyLabelForCurrency(response?.currency_name);
    }

    return 'kn';
  }
}
