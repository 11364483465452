import { environment as ngEnvironment } from '../../environments/environment';

class EnvironmentProxy {
  get production(): boolean {
    return ngEnvironment.production;
  }

  get apiUrl(): string {
    if (ngEnvironment.useWindowEnv) {
      // @ts-ignore
      return window.env.apiUrl;
    }

    return ngEnvironment.apiUrl;
  }

  get siteUrl(): string {
    if (ngEnvironment.useWindowEnv) {
      // @ts-ignore
      return window.env.siteUrl;
    }

    return ngEnvironment.siteUrl;
  }

  get productServingUrl(): string {
    if (ngEnvironment.useWindowEnv) {
      // @ts-ignore
      return window.env.productServingUrl;
    }

    return ngEnvironment.productServingUrl;
  }

  get wsPayForm(): string {
    if (ngEnvironment.useWindowEnv) {
      // @ts-ignore
      return window.env.wsPayForm;
    }

    return ngEnvironment.wsPayForm;
  }

  get staticServingUrl(): string {
    if (ngEnvironment.useWindowEnv) {
      // @ts-ignore
      return window.env.staticServingUrl;
    }

    return ngEnvironment.staticServingUrl;
  }
}

export const environment = new EnvironmentProxy();
