/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Translatable {
  content?: string;
  language?: Translatable.LanguageEnum;
}
export namespace Translatable {
  export type LanguageEnum = 'HR' | 'EN' | 'SI' | 'DE' | 'IT';
  export const LanguageEnum = {
    HR: 'HR' as LanguageEnum,
    EN: 'EN' as LanguageEnum,
    SI: 'SI' as LanguageEnum,
    DE: 'DE' as LanguageEnum,
    IT: 'IT' as LanguageEnum,
  };
}
