import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type Action = {
  id: string;
  title: string;
};

type ActionSelection = {
  action: Action;
  itemId: number;
};

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  private actionSelectedSource = new Subject<ActionSelection>();
  public actionSelectedData: Observable<ActionSelection> =
    this.actionSelectedSource.asObservable();

  constructor() {}

  selectedAction(action: Action, itemId: number) {
    this.actionSelectedSource.next({
      action: action,
      itemId: itemId,
    });
  }
}
