import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-slider-big',
  templateUrl: './slider-big.component.html',
  styleUrls: ['./slider-big.component.scss'],
})
export class SliderBigComponent implements OnInit {
  public checkbox: boolean = false;
  @Output() switchToggledEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() isDisabled: boolean = false;

  @Input() model: boolean = false;
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleModelChange(event: any) {
    this.modelChange.emit(event);
  }

  click() {
    const newValue = !this.model;
    this.model = newValue;

    this.modelChange.emit(newValue);
  }
}
