<div
  class="preview-gallery-item-container"
  [class.unpublished]="publishDate == undefined"
  style="
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    position: relative;
  "
>
  <div
    class="gallery-set-head-icon"
    (click)="setAsGalleryHead($event)"
    *ngIf="canSetAsGalleryHead"
  >
    <img src="/assets/gallery-set-head.svg" alt="" />
  </div>

  <div class="gallery-item-container">
    <div class="gallery-item-image-container">
      <img
        [src]="previewUrl"
        class="gallery-item-image"
        *ngIf="previewUrl != undefined"
      />
      <img
        *ngIf="bigTagElement"
        class="special-tag"
        src="{{
          '/assets/' + bigTagElement | translateImageTags : currentLanguage
        }}"
      />
    </div>
    <div class="gallery-item-details-container">
      <div class="gallery-item-details-container--title">{{ title }}</div>
      <div class="gallery-item-details-container--tags">
        <ng-container *ngFor="let tag of tags">
          <div class="gallery-item-details-container--tag" *ngIf="tag">
            {{ tag }}
          </div>
        </ng-container>
      </div>

      <div class="gallery-item-details-container--detail">
        <div class="gallery-item-details-container--detail--key">
          Publish date:
        </div>
        <div
          class="gallery-item-details-container--detail--value"
          *ngIf="publishDate"
        >
          {{ publishDate | date : "dd.MM.yyyy." }}
        </div>
        <div
          class="gallery-item-details-container--detail--value"
          *ngIf="publishDate == undefined"
        >
          Unpublished
        </div>
      </div>
      <div class="gallery-item-details-container--detail">
        <div class="gallery-item-details-container--detail--key">
          Number of products:
        </div>
        <div class="gallery-item-details-container--detail--value">
          {{ numberOfProducts }}
        </div>
      </div>
    </div>
  </div>

  <div class="preview-item-container-selection">
    <div></div>
  </div>
</div>
