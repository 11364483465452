<div
  class="context-menu-overlay"
  [class.active]="active"
  [style.left.px]="posX"
  [style.top.px]="posY"
>
  <ul class="context-menu">
    <li (click)="actionSelected(action)" *ngFor="let action of actions">
      {{ action.title }}
    </li>
  </ul>
</div>
