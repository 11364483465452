/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Settings extends Map<string, any> {
  [key: string]: any;
  AUTOPUBLISH: string;
  SUSPEND_B2C: string;
  EURO_PHASE: string;
  EURO_RATE: string;
}
