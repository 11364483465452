import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, NgModel, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ContractsService } from 'src/app/cms/contracts-and-users/contracts/contracts.service';
import { IContract } from '../../../cms/models';
import { InputType } from '../transforming-input/transforming-input.component';
import { Contract, ContractLine } from 'piwe-front-swagger-client';
import { SnackBarService } from '../../../share/snack-bar.service';
import logging from '../../../logging';
import { AutocompleteOption } from '../../field-text/field-text.component';
import * as moment from 'moment';
import { Moment } from 'moment';
import { LoadingService } from '../../loading.service';

@Component({
  selector: 'app-contract-single',
  templateUrl: './contract-single.component.html',
  styleUrls: ['./contract-single.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transformOrigin: 'top',
          overflow: 'hidden',
          opacity: '1',
        })
      ),
      state(
        'closed',
        style({
          transformOrigin: 'top',
          overflow: 'hidden',
          height: '0',
          paddingTop: '0',
          paddingBottom: '0',
          opacity: '0',
        })
      ),
      transition('open => closed', [animate('0.2s ease-in')]),
      transition('closed => open', [animate('0.2s ease-in')]),
    ]),
    trigger('openCloseIcon', [
      state(
        'openIcon',
        style({
          transform: 'rotate(0deg)',
          opacity: '1',
        })
      ),
      state(
        'closedIcon',
        style({
          transform: 'rotate(-180deg)',
          opacity: '0.57',
        })
      ),
      transition('openIcon => closedIcon', [animate('0.2s ease-in')]),
      transition('closedIcon => openIcon', [animate('0.2s ease-in')]),
    ]),
    trigger('transformFormMargins', [
      state(
        'formOpenMargin',
        style({
          margin: '*',
        })
      ),
      state(
        'formClosedMargin',
        style({
          margin: '23px 0 0',
        })
      ),
      transition('formOpenMargin => formClosedMargin', [
        animate('0.2s ease-in'),
      ]),
      transition('formClosedMargin => formOpenMargin', [
        animate('0.2s ease-in'),
      ]),
    ]),
    trigger('transformFormColor', [
      state(
        'formOpenColor',
        style({
          color: '*',
        })
      ),
      state(
        'formClosedColor',
        style({
          color: '#9B9B9B',
        })
      ),
      transition('formOpenColor => formClosedColor', [animate('0.2s ease-in')]),
      transition('formClosedColor => formOpenColor', [animate('0.2s ease-in')]),
    ]),
  ],
})
export class ContractSingleComponent implements OnInit {
  public InputType = InputType;

  public template: string = '';
  public itemId: number | undefined;
  public itemName: string = '';
  public type: string = '';
  public contentType: string = '';
  public productInvoice: string = '';
  public startUnlimitedFrom: string = '';
  public endUnlimitedAt: string = '';
  public downloadPrice: string = '';
  public discount: string = '';
  public itemGroup: string = '';
  public lumpSumPrice: string = '';
  public agencies: AutocompleteOption[] | undefined = [];
  public agencyGroups: AutocompleteOption[] | undefined = [];
  public isAddItemModalOpen: boolean = false;

  @Input() isAddContractModalOpen: boolean = false;

  public file: any;
  @Input() isHidden: boolean = false;
  public isEditModeOn: boolean = false;

  @Input() contractData!: Contract;
  @Input() contractType: 'active' | 'inactive' = 'active';
  private previousFormData: any;
  public contractItems: ContractLine[] = [];

  private isItemBeingEdited: boolean = false;
  private editedItemIndex: number = 0;

  @Output() contractWasSaved: EventEmitter<Contract> =
    new EventEmitter<Contract>();

  public contentTypes = [
    { id: ContractLine.ContentTypeEnum.PHOTO, title: 'Foto' },
    { id: ContractLine.ContentTypeEnum.VIDEO, title: 'Video' },
  ];

  public lineTypes = [
    { id: ContractLine.LineTypeEnum.FLATRATE, title: 'Fixed amount' },
    { id: ContractLine.LineTypeEnum.PERCENTAGE, title: 'Discount' },
  ];

  public product = [
    { id: ContractLine.InvoiceProductEnum.PDAgFD, title: 'PD_AgF_D' },
    { id: ContractLine.InvoiceProductEnum.PDAgVD, title: 'PD_AgV_D' },
    { id: ContractLine.InvoiceProductEnum.PDPxFD, title: 'PD_PxF_D' },
    { id: ContractLine.InvoiceProductEnum.PDPxFK, title: 'PD_PxF_K' },
    { id: ContractLine.InvoiceProductEnum.PDPxFSA, title: 'PD_PxF_S_A' },
    { id: ContractLine.InvoiceProductEnum.PDPxFSD, title: 'PD_PxF_S_D' },
    { id: ContractLine.InvoiceProductEnum.PDPxVD, title: 'PD_PxV_D' },
    { id: ContractLine.InvoiceProductEnum.RPAgFD, title: 'RP_AgF_D' },
    { id: ContractLine.InvoiceProductEnum.RPAgVD, title: 'RP_AgV_D' },
    { id: ContractLine.InvoiceProductEnum.RPPxFD, title: 'RP_PxF_D' },
    { id: ContractLine.InvoiceProductEnum.RPPxVD, title: 'RP_PxV_D' },
    { id: ContractLine.InvoiceProductEnum.UgMNAgFD, title: 'UgMN_AgF_D' },
    { id: ContractLine.InvoiceProductEnum.UgMNAgVD, title: 'UgMN_AgV_D' },
    { id: ContractLine.InvoiceProductEnum.UgMNPxFD, title: 'UgMN_PxF_D' },
    { id: ContractLine.InvoiceProductEnum.UgMNPxVD, title: 'UgMN_PxV_D' },
    { id: ContractLine.InvoiceProductEnum.UgRaAgFD, title: 'UgRa_AgF_D' },
    { id: ContractLine.InvoiceProductEnum.UgRaAgVD, title: 'UgRa_AgV_D' },
    { id: ContractLine.InvoiceProductEnum.UgRaPxFD, title: 'UgRa_PxF_D' },
    { id: ContractLine.InvoiceProductEnum.UgRaPxVD, title: 'UgRa_PxV_D' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private contractsService: ContractsService,
    private snackBarService: SnackBarService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.initForm(this.contractData);
    this.contractItems = this.contractData.contract_lines ?? [];
  }

  toggleIsHidden() {
    this.isEditModeOn = false;
    this.isHidden = !this.isHidden;
  }

  openEditMode() {
    this.previousFormData = this.contractForm.value;
    this.isEditModeOn = true;
  }

  closeEditMode() {
    this.rollBackForm(this.previousFormData);
    this.isEditModeOn = false;
  }

  initForm(data: Contract) {
    this.contractForm.get('valid_from')?.setValue(formatDate(data.valid_from!));
    this.contractForm.get('valid_to')?.setValue(formatDate(data.valid_to!));
    this.contractForm.get('id')?.setValue(data.id);
    this.contractForm.get('status')?.setValue(data.status);
    this.contractForm.get('title')?.setValue(data.title);
    this.contractForm.get('currency')?.setValue(data.currency);

    function formatDate(date: string | moment.Moment) {
      // formats input date, example: "2021-04-30 22:01:00" into "30.04.2021. 22:01"
      if (!date) {
        return date;
      }

      if (!(date instanceof String) && moment.isMoment(date)) {
        const castedMomentJsDate: moment.Moment = date as moment.Moment;

        return castedMomentJsDate.format('DD.MM.YYYY. HH:mm');
      }

      const splitDateArray = date.split(' ')[0].split('-');
      const year = splitDateArray[0];
      const month = splitDateArray[1];
      const day = splitDateArray[2];
      const time = date.split(' ')[1].split(':');

      return `${day}.${month}.${year}. ${time[0]}:${time[1]}`;
    }
  }

  rollBackForm(data: Contract) {
    this.contractForm.get('valid_from')?.setValue(data.valid_from);
    this.contractForm.get('valid_to')?.setValue(data.valid_to);
    this.contractForm.get('id')?.setValue(data.id);
    this.contractForm.get('status')?.setValue(data.status);
    this.contractForm.get('title')?.setValue(data.title);
    this.contractForm.get('currency')?.setValue(data.currency);
  }

  contractForm = this.formBuilder.group({
    valid_from: ['', Validators.required],
    valid_to: ['', Validators.required],
    id: ['', Validators.required],
    status: ['', Validators.required],
    title: ['', Validators.required],
    currency: ['', Validators.required],
  });

  saveChanges(data: Contract) {
    const body = {
      ...data,
      valid_from: formatDate(data.valid_from!),
      valid_to: formatDate(data.valid_to!),
      currency: data.currency,
    };

    body.company = undefined;

    this.loadingService.setLoading(true);

    this.contractsService
      .patchContracts(data.id!, body)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.isEditModeOn = false;
          this.loadingService.setLoading(false);
          this.contractWasSaved?.emit(response);
        },
        (err) => {
          this.snackBarService.showSnackBar(
            'Something went wrong, please try again',
            'ERROR'
          );
          this.loadingService.setLoading(false);
        }
      );

    function formatDate(date: string | moment.Moment) {
      // formats date from the inputs, example: "30.04.2021. 22:01" into "2021-04-30 22:01:00"
      if (!date) {
        return date;
      }

      if (!(date instanceof String) && moment.isMoment(date)) {
        const castedMomentJsDate: moment.Moment = date as moment.Moment;

        return castedMomentJsDate.format('YYYY-MM-DD HH:mm');
      }

      let dateFormatted: string = date as string;

      const splitDateArray = dateFormatted.split('.');
      const year = splitDateArray[2];
      const month = splitDateArray[1];
      const day = splitDateArray[0];
      const time = dateFormatted.split(' ')[1];

      return `${year}-${month}-${day} ${time}:00`;
    }
  }

  saveFile(event: any) {
    const file: File = event.target.files[0];
    const formData = new FormData();

    if (file) {
      formData.append('pdf', file);
      const body = { contractCopy: file } as IContract;
      this.contractsService
        .patchContracts(this.contractData.id!, {
          ...this.contractData,
          ...body,
        })
        .pipe(take(1))
        .subscribe(
          (response: any) => {},
          (err) => {
            this.snackBarService.showSnackBar('Something went wrong.', 'ERROR');
          }
        );
    }
  }

  deleteItem(index: number) {
    if (this.contractData.contract_lines!.length == 1) {
      this.contractData.contract_lines = [];
      this.contractItems = this.contractData.contract_lines;
    } else {
      this.contractData.contract_lines!.splice(index, 1);
      this.contractItems = this.contractData.contract_lines!;
    }
    const copyOfBody = { ...this.contractData };
    copyOfBody.company = undefined;

    this.contractsService
      .patchContracts(this.contractData.id!, copyOfBody)
      .pipe(take(1))
      .subscribe(
        (response) => {},
        (err) => {
          this.snackBarService.showSnackBar('Something went wrong.', 'ERROR');
        }
      );
  }

  stopEventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  contractItemFactory() {
    const body: ContractLine = {
      id: this.itemId,
      line_type: this.type as ContractLine.LineTypeEnum,
      line_name: this.itemName,
      from: parseInt(this.startUnlimitedFrom),
      to: parseInt(this.endUnlimitedAt),
      flat_rate: parseFloat(this.downloadPrice),
      percentage: parseFloat(this.discount),
      agencies: this.agencies?.map((a) => {
        return {
          id: a.value,
          title: a.title,
        };
      }),
      group_name: this.itemGroup,
      line_price: parseFloat(this.lumpSumPrice),
      invoice_product: this.productInvoice as ContractLine.InvoiceProductEnum,
      agency_groups: this.agencyGroups?.map((a) => {
        return {
          id: a.value,
          title: a.title,
        };
      }),
    };
    return body;
  }

  createNewItem(...args: NgModel[]) {
    /// number inputs need to be changed from text to number
    let isAllValid = true;
    for (let element of args) {
      if (element == undefined) continue;

      element.control.markAsTouched();
      element.control.markAsDirty();
      if (element.invalid) {
        isAllValid = false;
      }
    }

    if (isAllValid) {
      if (this.isItemBeingEdited) {
        // patch
        const contractItemBody = this.contractItemFactory();
        const contractItemCopy = [...this.contractItems];
        contractItemCopy[this.editedItemIndex] = contractItemBody;
        this.contractData.contract_lines = contractItemCopy;

        const copyOfBody = { ...this.contractData };
        copyOfBody.company = undefined;

        //needs a method for patching contract items instead of the whole contract
        this.contractsService
          .patchContracts(this.contractData.id!, copyOfBody)
          .pipe(take(1))
          .subscribe(
            (response) => {
              this.isItemBeingEdited = false;
              this.contractItems[this.editedItemIndex] = contractItemBody;
              this.closeAddItemModal();
            },
            (err) => {
              this.snackBarService.showSnackBar(
                'Something went wrong.',
                'ERROR'
              );
            }
          );
      } else {
        //post
        const contractItemBody = this.contractItemFactory();
        this.contractData.contract_lines!.push(contractItemBody);

        const copyOfBody = { ...this.contractData };
        copyOfBody.company = undefined;
        this.contractsService
          .patchContracts(this.contractData.id!, copyOfBody)
          .pipe(take(1))
          .subscribe(
            (response) => {
              this.closeAddItemModal();
            },
            (err) => {
              this.snackBarService.showSnackBar(
                'Something went wrong.',
                'ERROR'
              );
            }
          );
      }
    } else {
      logging.error('contract-single.component.ts', 'Failed');
    }
  }

  editItem(index: number) {
    this.isItemBeingEdited = true;
    this.editedItemIndex = index;

    const item: ContractLine = this.contractItems[index];

    this.itemId = item.id;
    this.itemName = item.line_name!;
    this.type = item.line_type!;
    this.contentType = item.content_type!;
    this.productInvoice = item.invoice_product!;
    this.startUnlimitedFrom = `${item.from}`;
    this.endUnlimitedAt = `${item.to}`;
    this.downloadPrice = `${item.flat_rate}`;
    this.discount = `${item.percentage}`;
    this.itemGroup = item.group_name!;
    this.lumpSumPrice = `${item.line_price}`;
    this.agencies = item!.agencies!.map((a) => {
      return {
        value: a.id!,
        title: a.title!,
        isSelectable: true,
      };
    });
    this.agencyGroups = item!.agency_groups!.map((a) => {
      return {
        value: a.id!,
        title: a.title!,
        isSelectable: true,
      };
    });

    this.openAddItemModal();
  }

  closeAddItemModal() {
    this.isAddItemModalOpen = false;
    this.template = '';
    this.itemName = '';
    this.type = '';
    this.contentType = '';
    this.productInvoice = '';
    this.startUnlimitedFrom = '';
    this.endUnlimitedAt = '';
    this.downloadPrice = '';
    this.discount = '';
    this.itemGroup = '';
    this.lumpSumPrice = '';
    this.agencies = [];
    this.agencyGroups = [];
  }

  openAddItemModal() {
    this.isAddItemModalOpen = true;
  }

  agenciesForm = this.formBuilder.group({
    agencies: [''],
    agencyGroups: [''],
  });

  removeContractCopy() {
    //delete
  }
}
