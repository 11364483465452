import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { FrontPushNotificationsService } from 'piwe-front-swagger-client';
import logging from './logging';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private frontPushNotificationService: FrontPushNotificationsService
  ) {
    this.angularFireMessaging.messages.subscribe((message: any) => {
      navigator.serviceWorker
        .getRegistration('/firebase-cloud-messaging-push-scope')
        .then((registration) => {
          let data = message.data;
          let title = data.title;

          // https://smartcodeeu.atlassian.net/browse/PSD-728
          let isFirefox = /Firefox/.test(navigator.userAgent);
          let isSafari =
            /Safari/.test(navigator.userAgent) &&
            !/Chrome/.test(navigator.userAgent);

          if (!isFirefox && !isSafari) {
            const notificationOptions = {
              icon: '/android-chrome-256x256.png',
              image: data.icon,
              data: data,
            };

            return registration!.showNotification(title, notificationOptions);
          } else {
            const notificationOptions = {
              icon: data.icon,
              data: data,
            };

            return registration!.showNotification(title, notificationOptions);
          }
        });
    });
  }

  requestPermission() {
    logging.info('notifications', 'Requesting token...');
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        if (token != null) {
          logging.info('notifications', 'Sending token...');
          this.frontPushNotificationService
            .frontPostPushNotification({
              platform: 'WEB',
              token,
            })
            .subscribe(
              (value) => {
                logging.info('notifications', 'did register with a token.');
              },
              (error) => {
                logging.info(
                  'notifications',
                  'did error for token registration.'
                );
              }
            );
        } else {
          logging.info('notifications', 'Token is null');
        }
      },
      (err) => {
        console.debug('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.debug('new message received. ', payload);
      // this.currentMessage.next(payload);
    });
  }
}
