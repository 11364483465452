import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() currentPage: number = 1;
  @Input() itemsPerPage: number = 10;
  @Input() pageCount: number = 50;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  lastPage() {
    if (this.currentPage == 1) return;

    this.pageChanged.emit(this.currentPage - 1);
    this.currentPage -= 1;
  }
  nextPage() {
    this.pageChanged.emit(this.currentPage + 1);
    this.currentPage += 1;
  }
}
