import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { IDatePickerConfig } from 'ng2-date-picker';
import { InputType } from '../transforming-input/transforming-input.component';
import { AutocompleteOption } from '../../field-text/field-text.component';
import { SnackBarService } from 'src/app/share/snack-bar.service';
import { ITagsArray } from '../../search/search';

@Component({
  selector: 'app-single-cms-input',
  templateUrl: './single-cms-input.component.html',
  styleUrls: ['./single-cms-input.component.scss'],
})
export class SingleCmsInputComponent implements OnInit {
  public InputType = InputType;
  public wasSubmited: boolean = false;

  @Input() autocompleteType: string = '';
  @Input() controlName: string = '';
  @Input() form!: FormGroup;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() type: InputType = InputType.Text;
  @Input() selectOptions: { [key: string]: any } = {};

  autocompleteOption?: AutocompleteOption;
  public selectWasFocused: boolean = false;

  dtConfig: IDatePickerConfig = {
    format: 'DD.MM.YYYY. HH:mm',
    firstDayOfWeek: 'mo',
    showTwentyFourHours: true,
  };

  constructor(
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.form.addControl(this.controlName, this.formBuilder.control(''));
  }

  isRequiredField() {
    const form_field = this.form!.get(this.controlName);

    if (form_field == null) return false;

    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  changeAutocomplete(autocompleteOption?: AutocompleteOption) {
    this.autocompleteOption = autocompleteOption;
    this.form.get(this.controlName)?.setValue(this.autocompleteOption?.value);
  }

  onSelectFocus() {
    this.selectWasFocused = true;
  }

  passwordTouched: boolean = false;

  passwordFocus(focus: boolean) {
    if (focus) {
      if (!this.passwordTouched) {
        //this.form.get(this.controlName)?.setValue('');
      }
    } else {
      if (!this.passwordTouched) {
        //this.form.get(this.controlName)?.setValue('');
      }
    }
  }

  touchPassword() {
    this.passwordTouched = true;
  }

  handleFileInput(event: any): void {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      if (file.size > 2048000) {
        //maybe different file size?? 1 or 3mb
        //1024000
        //2048000
        //3072000
        this.snackBarService.showSnackBar(
          'File too large. Maximum file size is 2048KB.',
          'ERROR'
        );
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.patchValue({
          [this.controlName]: reader.result,
        });

        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };
    } else {
      this.snackBarService.showSnackBar('No file attached.', 'ERROR');
    }
  }

  getTags(): AutocompleteOption[] {
    return this.form.get(this.controlName)!.value ?? [];
  }
}
