import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fluid-gallery-m',
  templateUrl: './fluid-gallery-m.component.html',
  styleUrls: ['./fluid-gallery-m.component.scss'],
})
export class FluidGalleryMComponent implements OnInit {
  @Input() galleryMatrix: any[] = [];

  constructor() {}

  ngOnInit(): void {}
  onImageLoad($event: any) {
    const target = $event.target;
    let aspectRatio: number = target.naturalWidth / target.naturalHeight;
    target.parentElement.parentElement.style.flex = aspectRatio;
  }
}
