<section class="background-1">
  <div class="section-container">
    <div class="section-container-inner">
      <div class="section-detailed">
        <div>
          <h2 [innerHTML]="'about_us.service_title' | translate">Usluge</h2>
          <p
            class="body--small"
            [innerHTML]="'about_us.service_text' | translate"
          >
            Iza svakog proizvoda, portreta i editorijala krije se priča koju
            Pixsell prepričava savršenim kadrovima iz svoje produkcije. Možemo
            snimiti svaki događaj ili temu u Hrvatskoj i regiji prema uputama i
            zahtjevima klijenta, uz naše stručne sugestije i kreativne
            prijedloge.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="banner">
    <app-horizontal-scroll></app-horizontal-scroll>
  </div>
</section>
