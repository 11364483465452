<button (click)="toggleIsHidden()" class="header-btn">
  <ng-content select="app-expansion-panel-header"></ng-content>
  <img
    src="/assets/dropdown.svg"
    alt=""
    [@openCloseIcon]="isHidden ? 'closedIcon' : 'openIcon'"
  />
</button>

<div class="content" [@openClose]="isHidden ? 'closed' : 'open'">
  <ng-content></ng-content>
</div>
