/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MediaPreivew } from './mediaPreivew';

/**
 * Check out Figma file for more information on properties: https://www.figma.com/file/bxdGwhSTzlh51nAWKilDLN/Untitled?node-id=1%3A10110
 */
export interface CartLine {
  id?: number;
  agency?: string;
  title?: string;
  title_en?: string;
  author?: string;
  /**
   * In pixsells
   */
  resolution_width?: number;
  /**
   * In pixsells
   */
  resolution_height?: number;
  published_at?: string;
  line_subtotal_vat?: number;
  line_total_no_vat?: number;
  line_total_with_vat?: number;

  use_tax?: boolean;

  alternative_line_subtotal_vat?: number;
  alternative_line_total_no_vat?: number;
  alternative_line_total_with_vat?: number;

  currency?: CartLine.CurrencyEnum;
  variation?: CartLine.VariationEnum;
  previews?: Array<MediaPreivew>;
  download_with_author_signature?: boolean;
  kind?: CartLine.KindEnum;
  product_id?: number;
  is_video?: boolean;
  user_displayed_source?: string;
  source?: CartLine.SourceEnum;
  seo_url?: string;
}
export namespace CartLine {
  export type CurrencyEnum = 'HRK' | 'EUR';
  export const CurrencyEnum = {
    HRK: 'HRK' as CurrencyEnum,
    EUR: 'EUR' as CurrencyEnum,
  };
  export type VariationEnum = 'PRINT' | 'WEB';
  export const VariationEnum = {
    PRINT: 'PRINT' as VariationEnum,
    WEB: 'WEB' as VariationEnum,
  };
  export type KindEnum = 'SINGLE_PRODUCT' | 'GALLERY' | 'GALLERY_GROUP';
  export const KindEnum = {
    SINGLEPRODUCT: 'SINGLE_PRODUCT' as KindEnum,
    GALLERY: 'GALLERY' as KindEnum,
    GALLERYGROUP: 'GALLERY_GROUP' as KindEnum,
  };
  export type SourceEnum = 'PIXSELL' | 'THP_PHOTO_SERVICE';
  export const SourceEnum = {
    PIXSELL: 'PIXSELL' as SourceEnum,
    THPPHOTOSERVICE: 'THP_PHOTO_SERVICE' as SourceEnum,
  };
}
