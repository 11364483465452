import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

export enum GalleryCartVariant {
  Cart = 1,
  Download,
}

@Component({
  selector: 'app-gallery-cart',
  templateUrl: './gallery-cart.component.html',
  styleUrls: ['./gallery-cart.component.scss'],
  animations: [
    trigger('backgroundAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        group([
          animate('300ms', style({ opacity: 1 })),
          query('@sidebarAnimation', animateChild()),
        ]),
      ]),
      transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
    ]),
    trigger('sidebarAnimation', [
      transition(':enter', [
        style({
          transform: 'translateX(100%)',
          opacity: 0,
        }),
        animate('300ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0, transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class GalleryCartComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() productId!: number;
  @Input() fileName!: string;
  @Output() addToBasketClosedEvent: EventEmitter<void> = new EventEmitter();
  @Output() sidebarClosedButModalStaysEvent: EventEmitter<void> =
    new EventEmitter();
  @Output() galleryAddedToCartEvent: EventEmitter<void> = new EventEmitter();
  @Input() variant: GalleryCartVariant = GalleryCartVariant.Cart;
  @Input() productCount: number = 1;
  @Input() isGallery: boolean = false;


  public isDownloadDoneModalOpen: boolean = false;
  public isCartVisible: boolean = false;
  public source?: string;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data
      .pipe(takeUntil(this.unsubscribe$), combineLatest(this.route.params))
      .subscribe(([data, params]) => {
        const isAgephotostock = data.isAgephotostock ?? false;
        this.source = isAgephotostock ? 'THP_PHOTO_SERVICE' : 'PIXSELL';
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleClick() {
    this.addToBasketClosedEvent.emit();
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  downloadCompleted() {
    this.sidebarClosedButModalStaysEvent.emit();
    this.isDownloadDoneModalOpen = true;
  }
  closeDownloadModal() {
    this.isDownloadDoneModalOpen = false;
    this.addToBasketClosedEvent.emit();
  }

  onAddedToCart() {
    this.addToBasketClosedEvent.emit();
    if (this.isGallery) {
      this.galleryAddedToCartEvent.emit();
    }
  }
}
