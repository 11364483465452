import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sidebar-autocomplete-checkbox',
  templateUrl: './sidebar-autocomplete-checkbox.component.html',
  styleUrls: ['./sidebar-autocomplete-checkbox.component.scss'],
})
export class SidebarAutocompleteCheckboxComponent implements OnInit {
  @Input() controlName!: string;
  @Input() form!: FormGroup;
  @Input() isRequired: boolean = false;
  @Input() label: string = '';

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.isRequired)
      this.form.addControl(
        this.controlName,
        this.formBuilder.control('', Validators.required)
      );
    else this.form.addControl(this.controlName, this.formBuilder.control(''));
    this.form.addControl(
      'specialOfferCheckbox',
      this.formBuilder.control(false)
    );
  }
}
