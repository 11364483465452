import { Injectable } from '@angular/core';
import { ApiHandler } from '../../projects/piwe-front-swagger-client/src';
import { Router } from '@angular/router';
import { AuthenticationStorageService } from './authentication-storage.service';
import { AuthenticationService, clearSource } from './authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from './share/snack-bar.service';

@Injectable({
  providedIn: 'any',
})
export class ApiHandlerService implements ApiHandler {
  constructor(
    private router: Router,
    private authenticationStorageService: AuthenticationStorageService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService
  ) {}

  handleUnauthenticated(message: string | undefined): void {
    clearSource.next();

    let shouldRedirect = false;
    if (!this.router.url.startsWith('/login')) {
      shouldRedirect = true;
    }

    let queryParams: any = {};

    if (shouldRedirect) {
      queryParams.redirect = this.router.url;
    }

    if (!this.router.url.startsWith('/login')) {
      this.router.navigate(['/login'], {
        queryParams: queryParams,
      });
    }

    if (message !== undefined) {
      this.snackBarService.showSnackBar(message, 'ERROR');
    }
  }

  getAccessToken(): string | undefined {
    return this.authenticationStorageService.getToken();
  }

  getLanguage() {
    if (this.translateService.currentLang) {
      //if it gets called before the current language is set, it doesn't load the language anymore
      return this.translateService.currentLang;
    } else {
      return '';
    }
  }
}
