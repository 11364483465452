/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CmsEditingCompanySummary {
  id?: number;
  status?: string;
  company_name?: string;
  oib?: string;
  address_line_1?: string;
  country_name?: string;
  created_at?: Date;
  contact_person_name?: string;
}
