import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new Subject<boolean>();
  public loadingData: Observable<boolean> = this.loadingSubject.asObservable();

  constructor() {}

  setLoading(isLoading: boolean) {
    this.loadingSubject.next(isLoading);
  }
}
