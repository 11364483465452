/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Agency } from './agency';
import { Author } from './author';
import { Category } from './category';
import { GalleryProductItem } from './galleryProductItem';
import { Keyword } from './keyword';
import { Person } from './person';
import { SpecialOfferType } from './specialOfferType';
import { Tag } from './tag';
import { Translatable } from './translatable';

export interface Gallery {
  id?: number;
  publish_date?: string;
  publish_state?: Gallery.PublishStateEnum;
  gal_product_id?: number;
  caption?: Array<Translatable>;
  headline: Array<Translatable>;
  keywords_list?: Array<Keyword>;
  people_list?: Array<Person>;
  category?: Category;
  categories?: Array<Category>;
  author?: Author;
  agency?: Agency;
  tag_list?: Array<Tag>;
  is_special_offer?: boolean;
  special_offer_types?: Array<SpecialOfferType>;
  web_price?: number;
  print_price?: number;
  web_price_eur?: number;
  print_price_eur?: number;
  created_date?: string;
  product_list?: Array<GalleryProductItem>;
  product_name?: string;
  video_count?: number;
  photo_count?: number;
  total_count?: number;
  article_id?: number;
  article_title?: string;
  article_content?: string;
  is_featured?: boolean;
  is_digital_exhibition?: boolean;
  is_free?: boolean;
  article_author?: Author;
  /**
   * URL to view more information. Will not contain the base URL of the website.
   */
  seo_url?: string;
}
export namespace Gallery {
  export type PublishStateEnum =
    | 'NOT_PUBLISHED'
    | 'PUBLISHED'
    | 'AUTO_PUBLISH_AFTER_DATE';
  export const PublishStateEnum = {
    NOTPUBLISHED: 'NOT_PUBLISHED' as PublishStateEnum,
    PUBLISHED: 'PUBLISHED' as PublishStateEnum,
    AUTOPUBLISHAFTERDATE: 'AUTO_PUBLISH_AFTER_DATE' as PublishStateEnum,
  };
}
