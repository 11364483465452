import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableService } from '../table.service';
import { $ } from 'protractor';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() tableId?: string;
  @Output() tableRowSelected: EventEmitter<{ event: MouseEvent; item: any }> =
    new EventEmitter<{ event: MouseEvent; item: any }>();

  @ViewChild('tableWrapper') tableWrapper: ElementRef | null = null;

  @Input() columns?: string[];
  @Input() columnTypes?: string[];
  @Input() columnTitles?: string[];

  @Input() items?: any[];

  selectedItemIds: number[] = [];

  constructor(private tableService: TableService) {}

  ngOnInit(): void {
    this.tableService.tableColumnsData.subscribe((columns) => {
      this.columns = columns;
    });

    this.tableService.tableColumnTitlesData.subscribe((columnTitles) => {
      this.columnTitles = columnTitles;
    });

    this.tableService.tableColumnTypesData.subscribe((columnTypes) => {
      this.columnTypes = columnTypes;
    });

    this.tableService.selectedIdsData.subscribe((selectedIds) => {
      this.selectedItemIds = selectedIds;
    });

    this.tableService.itemsData.subscribe((items) => {
      //this.items = items;
    });
  }

  parseTags(items: any, column: any) {
    return items[column]?.map((t: any) => t.title).join(', ');
  }

  columnSize(columnType: string) {
    switch (columnType) {
      case 'import-item-preview':
        return 100.0 + 'px';
      default:
        return 117.0 + 'px';
    }
  }

  didSelectTableRow(item: any, $event: MouseEvent) {
    this.tableRowSelected.emit({ event: $event, item: item });
  }

  getItemId(index: number, item: any) {
    return item.id;
  }

  isPreviousSelected(item: any) {
    if (!this.items) return false;

    let index = this.items.indexOf(item);
    if (index == undefined) return false;

    if (index == 0) return false;

    let nextItem = this.items[index - 1];

    return this.selectedItemIds.includes(nextItem.id);
  }
}
