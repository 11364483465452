import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { Configuration } from './configuration';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { AgenciesService } from './api/agencies.service';
import { AuthorsService } from './api/authors.service';
import { AutocompleteService } from './api/autocomplete.service';
import { CMSService } from './api/cMS.service';
import { CMSUsersService } from './api/cMSUsers.service';
import { CartService } from './api/cart.service';
import { CategoriesService } from './api/categories.service';
import { GalleryGroupsService } from './api/galleryGroups.service';
import { HomepageService } from './api/homepage.service';
import { IPTCService } from './api/iPTC.service';
import { KeywordsService } from './api/keywords.service';
import { LicenceTypesService } from './api/licenceTypes.service';
import { PeopleService } from './api/people.service';
import { ProductsService } from './api/products.service';
import { ProductsFrontService } from './api/productsFront.service';
import { ProfileService } from './api/profile.service';
import { ProperNameService } from './api/properName.service';
import { RegistrationLoginService } from './api/registrationLogin.service';
import { SpecialInstructionsService } from './api/specialInstructions.service';
import { SpecialOfferTypesService } from './api/specialOfferTypes.service';
import { TagsService } from './api/tags.service';
import { UploadSessionsService } from './api/uploadSessions.service';
import { UserRightsService } from './api/userRights.service';
import { UsersService } from './api/users.service';
import { Interceptor } from './interceptor';
import { WebAdministrationService } from './api/webAdministration.service';
import { CMSGalleriesService } from './api/cMSGalleries.service';
import { FrontGalleriesService } from './api/frontGalleries.service';
import { CMSCompaniesService } from './api/cMSCompanies.service';
import { CMSContractsService } from './api/cMSContracts.service';
import { FrontPushNotificationsService } from './api/frontPushNotifications.service';
import { InternalUsersService } from './api/internalUsers.service';
import { SettingsService } from './api/settings.service';
import { DynamicViewsService } from './api/dynamicViews.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    DynamicViewsService,
    AgenciesService,
    AuthorsService,
    AutocompleteService,
    CMSService,
    CMSGalleriesService,
    CMSUsersService,
    CMSCompaniesService,
    CMSContractsService,
    CartService,
    CategoriesService,
    FrontGalleriesService,
    GalleryGroupsService,
    HomepageService,
    IPTCService,
    KeywordsService,
    LicenceTypesService,
    PeopleService,
    ProductsService,
    ProductsFrontService,
    ProfileService,
    ProperNameService,
    RegistrationLoginService,
    SpecialInstructionsService,
    SpecialOfferTypesService,
    TagsService,
    InternalUsersService,
    FrontPushNotificationsService,
    UploadSessionsService,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    UserRightsService,
    UsersService,
    WebAdministrationService,
    SettingsService,
  ],
})
export class ApiModule {
  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }

  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }
}
