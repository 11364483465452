import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-photojournalist-row',
  templateUrl: './photojournalist-row.component.html',
  styleUrls: ['./photojournalist-row.component.scss'],
})
export class PhotojournalistRowComponent implements OnInit {
  @Input() imgUrl: string = '';
  @Input() name: string = '';

  @Input() sampleUrl1: string = '';
  @Input() sampleUrl2: string = '';
  @Input() sampleUrl3: string = '';
  @Input() sampleUrl4: string = '';

  @ViewChild('row') row?: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    const width: number =
      document.querySelector('.main-app-container')?.clientWidth ?? 0;
    if (width <= 1024) {
      const height = ((width - 30 - 32 - 30) / 3) * 0.66;

      //@ts-ignore
      this.row?.nativeElement.style.height = `${height}px`;
    }
  }
}
