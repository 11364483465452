<div class="field-radio-title">{{ title }}</div>

<ul class="field-radio" [class.disabled]="disabled">
  <li
    *ngFor="let option of options"
    (click)="onOptionClick(option)"
    [class.selected]="option.value == value"
  >
    <div class="field-radio-radio"></div>
    <span>{{ option.title }}</span>
  </li>
</ul>

<!-- Errors -->
<div *ngIf="errors != undefined && errors.length > 0" class="field-errors">
  <div *ngFor="let error of errors" class="field-error">
    {{ error.error_description }}
  </div>
</div>
