import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { ApiHandler, Configuration } from './configuration';
import { tap } from 'rxjs/operators';
import { API_HANDLER, BASE_PATH, VERSION } from './variables';
import { toCurl } from './to-curl';
import { ErrorResponse } from './model/errorResponse';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    @Optional() private configuration: Configuration,
    @Optional() @Inject(API_HANDLER) private apiHandler: ApiHandler,
    @Optional() @Inject(BASE_PATH) private basePath: string,
    @Optional() @Inject(VERSION) private version: string
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let accessTokenValue: string | undefined;

    if (this.apiHandler) {
      accessTokenValue = this.apiHandler!.getAccessToken();
    }

    let headers = new HttpHeaders();
    headers = headers.set('Accept-Language', this.apiHandler.getLanguage());
    headers = headers.set('X-Pixsell-Language', this.apiHandler.getLanguage());
    headers = headers.set('X-Pixsell-Version', this.version);

    if (accessTokenValue != undefined) {
      headers = headers.set('Authorization', 'Bearer ' + accessTokenValue);

      const modifiedRequest = req.clone({ headers });
      return next.handle(modifiedRequest).pipe(
        tap(
          (_) => {},
          (error) => {
            let errorOutput: string =
              '\n=============================================\n' +
              'REQUEST FAILED\n' +
              '=============================================\n\n\n';
            const curlRequest = toCurl(modifiedRequest);
            errorOutput += 'Request:\n' + curlRequest + '\n\n';

            console.debug('Request failed', error);

            if (error.error instanceof ErrorEvent) {
              // A client-side or network error occurred. Handle it accordingly.
              errorOutput += 'An error occurred:' + error.error.message + '\n';
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              errorOutput +=
                `Backend returned code ${error.status}, ` +
                `body was:\n\n${error.error}` +
                '\n';

              if (error.status === 401) {
                if (error.error != undefined) {
                  let errorResponse: { message: string } = error.error;

                  if (errorResponse.message != undefined) {
                    this.apiHandler?.handleUnauthenticated(
                      errorResponse.message
                    );
                  } else {
                    this.apiHandler?.handleUnauthenticated(undefined);
                  }
                } else {
                  this.apiHandler?.handleUnauthenticated(undefined);
                }

                return;
              }
            }

            errorOutput += '\n\n';
            console.debug(errorOutput);
          }
        )
      );
    }

    const modifiedRequest = req.clone({ headers });
    return next.handle(modifiedRequest).pipe(
      tap(
        (_) => {},
        (error) => {
          let errorOutput: string =
            '\n=============================================\n' +
            'REQUEST FAILED\n' +
            '=============================================\n\n\n';
          const curlRequest = toCurl(req);
          errorOutput += 'Request:\n' + curlRequest + '\n\n';

          console.debug('Request failed', error);

          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorOutput += 'An error occurred:' + error.error.message + '\n';
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorOutput +=
              `Backend returned code ${error.status}, ` +
              `body was:\n\n${error.error}` +
              '\n';

            if (error.status === 401) {
              if (error.error != undefined) {
                let errorResponse: { message: string } = error.error;

                if (errorResponse.message != undefined) {
                  this.apiHandler?.handleUnauthenticated(errorResponse.message);
                } else {
                  this.apiHandler?.handleUnauthenticated(undefined);
                }
              } else {
                this.apiHandler?.handleUnauthenticated(undefined);
              }

              return;
            }
          }

          errorOutput += '\n\n';
          console.debug(errorOutput);
        }
      )
    );
  }
}
