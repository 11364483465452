<div class="main-preview-body" #container>
  <div class="main-preview-row">
    <ng-container
      *ngFor="
        let item of isSearchMoreGrid ? searchMoreItems : items;
        index as i;
        trackBy: getItemId
      "
    >
      <ng-container
        *ngIf="
          i == searchMoreBlockInsertLocation &&
          hasSearchMoreBlock &&
          searchMoreBlockInsertLocation > 0 &&
          !searchMoreBlockInsertAtTheEnd
        "
      >
        <app-search-more-block
          [style]="{ width: '100%' }"
        ></app-search-more-block>
      </ng-container>

      <div
        class="main-preview-container"
        [attr.item-id]="item.id"
        [attr.kind]="item.kind"
        [ngStyle]="{
          height: item.height * itemHeight + 'px',
          flex: item.width | previewItemFlex
        }"
        (mouseover)="itemOver(item, $event)"
        (mouseout)="itemOut(item, $event)"
        (click)="itemClick(item, $event)"
      >
        <ng-container [ngSwitch]="item.kind">
          <ng-container *ngSwitchCase="'SINGLE_PRODUCT'">
            <app-main-preview-item
              *ngFor="let preview of item.preview"
              [attr.iaspect]="item.aspectRatio"
              [attr.iwidth]="item.width"
              [attr.iheight]="item.height"
              [attr.itemHeight]="itemHeight"
              (onImageLoaded)="onImageLoaded($event)"
              (onPreviewImageLoaded)="onPreviewImageLoaded($event)"
              [importItem]="item"
              [isLocked]="item.isLocked"
              [lockedBy]="item.lockedBy"
              [attr.data-id]="item.id"
              [attr.index]="items.indexOf(item)"
              [imageSize]="imageSize"
              [isVideo]="preview.preview_video_loop != null"
              [previewUrl]="preview.preview_url"
              [videoPreviewUrl]="preview.preview_video_loop"
              [loadingPreviewUrl]="preview.preview_url_blur"
              [largePreviewUrl]="preview.preview_url_large"
              [class.special-offer]="item.isSpecialOffer"
              (click)="itemClick(item, $event)"
            >
            </app-main-preview-item>
          </ng-container>
          <ng-container *ngSwitchCase="'GALLERY'">
            <app-main-preview-item
              *ngFor="let preview of item.preview"
              [attr.iaspect]="item.aspectRatio"
              [attr.iwidth]="item.width"
              [attr.iheight]="item.height"
              [attr.itemHeight]="itemHeight"
              [attr.item-kind]="'gallery'"
              (onImageLoaded)="onImageLoaded($event)"
              (onPreviewImageLoaded)="onPreviewImageLoaded($event)"
              [importItem]="item"
              [isLocked]="item.isLocked"
              [lockedBy]="item.lockedBy"
              [attr.data-id]="item.id"
              [attr.index]="items.indexOf(item)"
              [imageSize]="imageSize"
              [isVideo]="false"
              [previewUrl]="preview.preview_url"
              [loadingPreviewUrl]="preview.preview_url_blur"
              [largePreviewUrl]="preview.preview_url_large"
              [class.special-offer]="item.isSpecialOffer"
              (click)="itemClick(item, $event)"
            >
            </app-main-preview-item>
          </ng-container>
          <ng-container *ngSwitchCase="'GALLERY_GROUP'">
            <div
              class="gallery-item-image-container gallery-item-image-container-images-{{
                item.preview.length
              }}"
            >
              <img
                src="{{ preview.preview_url | remoteApiAsset }}"
                class="gallery-item-image gallery-item-image-{{ i }}"
                *ngFor="let preview of item.preview; let i = index"
              />
            </div>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="
            atLeastOnePreviewLoaded && (item.isLoaded || item.isPreviewLoaded)
          "
          [ngSwitch]="variant"
        >
          <ng-container *ngSwitchCase="1"></ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="main-preview-overlay">
              <div class="main-preview-count">
                <img src="/assets/image-count-white.svg" alt="" />
                <div>{{ item.imageCount }}</div>
              </div>
              <div class="main-preview-title">
                {{ currentLanguage == "hr" ? item.title : item.titleEn }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <!-- ProductPreview -->
            <ng-container [ngSwitch]="item.kind">
              <ng-container
                *ngSwitchCase="'SINGLE_PRODUCT'"
                [class.only-hover]="showDataOnlyOnHover"
              >
                <img
                  *ngIf="item.tag"
                  class="special-tag"
                  src="{{
                    '/assets/' + item.tag | translateImageTags : currentLanguage
                  }}"
                />
                <div
                  class="main-preview-overlay-product"
                  [class.video]="item.preview[0].preview_video_loop != null"
                  (mouseout)="resetAlreadyInCartTimeout()"
                >
                  <a
                    [attr.href]="generateLink(item)"
                    (click)="itemClick(item, $event)"
                    style="
                      position: absolute;
                      top: 0;
                      right: 0;
                      left: 0;
                      bottom: 0;
                    "
                  ></a>
                  <ng-container
                    *ngIf="item.preview[0].preview_video_loop == null"
                  >
                    <div class="main-preview-overlay-product__text">
                      <div class="main-preview-overlay-product__timestamp">
                        {{ item.timestamp | date : "dd.MM.yyyy. HH:mm" }}
                      </div>
                      <div class="main-preview-overlay-product__title">
                        {{
                          currentLanguage == "hr" ? item.title : item.titleEn
                        }}
                      </div>
                    </div>
                    <button
                      class="main-preview-overlay-product__button"
                      (click)="addProductToCart($event, item.id, item.isInCart)"
                      [class.is-in-cart]="item.isInCart"
                    >
                      <div
                        class="already-in-cart-popup"
                        *ngIf="isAlreadyInCartPopupVisible"
                      >
                        Proizvod već dodan u košaricu
                      </div>
                    </button>
                  </ng-container>
                </div>
                <div
                  class="video-overlay main-preview-overlay-product"
                  *ngIf="item.preview[0].preview_video_loop != null"
                >
                  <div class="main-preview-overlay-product__text">
                    <div class="main-preview-overlay-product__video-duration">
                      {{ item.video_duration * 1000 | date : "mm:ss" : "UTC" }}
                    </div>
                    <div class="main-preview-overlay-product__title">
                      {{ currentLanguage == "hr" ? item.title : item.titleEn }}
                    </div>
                  </div>
                  <button
                    class="main-preview-overlay-product__button"
                    (click)="addProductToCart($event, item.id, item.isInCart)"
                    [class.is-in-cart]="item.isInCart"
                  >
                    <div
                      class="already-in-cart-popup"
                      *ngIf="isAlreadyInCartPopupVisible"
                    >
                      Proizvod već dodan u košaricu
                    </div>
                  </button>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'GALLERY'">
                <img
                  *ngIf="item.tag"
                  class="special-tag"
                  src="{{
                    '/assets/' + item.tag | translateImageTags : currentLanguage
                  }}"
                />
                <img
                  *ngIf="item.isNewContent"
                  class="new-tag"
                  src="/assets/big-tag-new-in-gallery.svg"
                />
                <div
                  class="main-preview-gallery-overlay"
                  [class.only-hover]="showDataOnlyOnHover"
                >
                  <a
                    [attr.href]="generateLink(item)"
                    (click)="itemClick(item, $event)"
                    style="
                      position: absolute;
                      top: 0;
                      right: 0;
                      left: 0;
                      bottom: 0;
                      z-index: 2;
                    "
                  ></a>
                  <div
                    class="main-preview-overlay main-preview-gallery-overlay__inner"
                  >
                    <div class="gallery-details-row">
                      <div class="main-preview-count">
                        <img src="/assets/image-count-white.svg" alt="" />
                        <div *ngIf="item.imageCount > 0">
                          {{ item.imageCount }}
                        </div>
                        <div
                          *ngIf="item.imageCount > 0 && item.videoCount > 0"
                          class="gallery-vr rm-mobile"
                        ></div>
                        <img
                          src="/assets/video-count-white.svg"
                          *ngIf="item.videoCount > 0"
                          alt=""
                        />
                        <div *ngIf="item.videoCount > 0">
                          {{ item.videoCount }}
                        </div>
                        <div class="gallery-vr gallery-overlay-hover"></div>
                        <div class="gallery-overlay-hover">
                          {{
                            item.timestamp.replace(" ", "T")
                              | date : "dd.MM.yyyy. HH:mm"
                          }}
                        </div>
                      </div>
                      <!-- article icon is broken, so I just disabled it -->
                      <img
                        *ngIf="item.has_article"
                        style="display: none"
                        src="/assets/article-icon-white.svg"
                        alt=""
                      />
                    </div>
                    <div class="main-preview-title">
                      <!-- {{ item | importItemHeadline }} -->
                      {{ currentLanguage == "hr" ? item.title : item.titleEn }}
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'GALLERY_GROUP'">
                <div
                  class="main-preview-overlay main-preview-overlay--gallery-group"
                  [class.only-hover]="showDataOnlyOnHover"
                >
                  <div class="main-preview-subtitle">Groupa galerija</div>
                  <div class="main-preview-title">
                    <!-- {{ item | importItemHeadline }} -->
                    {{ currentLanguage == "hr" ? item.title : item.titleEn }}
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <!-- selectable variant -->
            <div
              class="main-preview-overlay selectable-overlay"
              [class.selected]="i === indexOfCurrentlySelected"
              (click)="selectionChangedEvent.emit(i)"
            ></div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        hasSearchMoreBlock &&
        searchMoreBlockInsertLocation == -1 &&
        searchMoreBlockInsertAtTheEnd
      "
    >
      <app-search-more-block
        [style]="{ width: '100%' }"
      ></app-search-more-block>
    </ng-container>

    <div
      *ngFor="let item of skeletonItems; index as i; trackBy: getItemId"
      [ngStyle]="{
        height: item.height * itemHeight + 'px',
        flex: item.width | previewItemFlex
      }"
      class="loading-skeleton-item-container"
    >
      <div class="loading-skeleton-item"></div>
    </div>
  </div>
</div>
