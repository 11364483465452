/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { CompanyList } from '../model/companyList';
import { Download } from '../model/download';
import { DownloadList } from '../model/downloadList';
import { FinanceAdministartionItemItem } from '../model/financeAdministartionItemItem';
import { FinanceAdministartionItemList } from '../model/financeAdministartionItemList';
import { FinanceCalculationItem } from '../model/financeCalculationItem';
import { FinanceCalculationsList } from '../model/financeCalculationsList';
import { FinanceInvoice } from '../model/financeInvoice';
import { FinanceInvoiceDeleteRequest } from '../model/financeInvoiceDeleteRequest';
import { FinanceInvoiceList } from '../model/financeInvoiceList';
import { FinanceInvoicePdfRequest } from '../model/financeInvoicePdfRequest';
import { FinanceInvoiceProductItemEditRequest } from '../model/financeInvoiceProductItemEditRequest';
import { FinanceInvoiceSapJournalEntryRequest } from '../model/financeInvoiceSapJournalEntryRequest';
import { FinanceInvoiceStatusRequest } from '../model/financeInvoiceStatusRequest';
import { FinanceInvoiceStatusRequestMass } from '../model/financeInvoiceStatusRequestMass';
import { FinanceSapTaxIndicatorItem } from '../model/financeSapTaxIndicatorItem';
import { FinanceSapTaxIndicatorList } from '../model/financeSapTaxIndicatorList';
import { InvoiceList } from '../model/invoiceList';
import { InvoiceStaticContentDeleteRequest } from '../model/invoiceStaticContentDeleteRequest';
import { InvoiceStaticContentItem } from '../model/invoiceStaticContentItem';
import { InvoiceStaticContentsList } from '../model/invoiceStaticContentsList';
import { IptcHtmlResponse } from '../model/iptcHtmlResponse';
import { SapJournalEntryCount } from '../model/sapJournalEntryCount';
import { UpdateDownload } from '../model/updateDownload';
import { UserList } from '../model/userList';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class CMSService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Cancel a invoice
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsCancelFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsCancelFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsCancelFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsCancelFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/finance-invoices/invoices/cancel`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Change invoice status
   * @param invoiceId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsChangeFinanceInvoiceStatus(
    invoiceId: number,
    body?: FinanceInvoiceStatusRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceInvoiceList>;
  public cmsChangeFinanceInvoiceStatus(
    invoiceId: number,
    body?: FinanceInvoiceStatusRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceInvoiceList>>;
  public cmsChangeFinanceInvoiceStatus(
    invoiceId: number,
    body?: FinanceInvoiceStatusRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceInvoiceList>>;
  public cmsChangeFinanceInvoiceStatus(
    invoiceId: number,
    body?: FinanceInvoiceStatusRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (invoiceId === null || invoiceId === undefined) {
      throw new Error(
        'Required parameter invoiceId was null or undefined when calling cmsChangeFinanceInvoiceStatus.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FinanceInvoiceList>(
      'post',
      `${this.basePath}/cms/finance-invoices/invoices/${encodeURIComponent(
        String(invoiceId)
      )}/status`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Change invoice status
   * @param invoiceId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsChangeFinanceInvoiceStatusMass(
    invoiceId: number,
    body?: FinanceInvoiceStatusRequestMass,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceInvoiceList>;
  public cmsChangeFinanceInvoiceStatusMass(
    invoiceId: number,
    body?: FinanceInvoiceStatusRequestMass,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceInvoiceList>>;
  public cmsChangeFinanceInvoiceStatusMass(
    invoiceId: number,
    body?: FinanceInvoiceStatusRequestMass,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceInvoiceList>>;
  public cmsChangeFinanceInvoiceStatusMass(
    invoiceId: number,
    body?: FinanceInvoiceStatusRequestMass,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (invoiceId === null || invoiceId === undefined) {
      throw new Error(
        'Required parameter invoiceId was null or undefined when calling cmsChangeFinanceInvoiceStatusMass.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FinanceInvoiceList>(
      'post',
      `${this.basePath}/cms/finance-invoices/invoices/${encodeURIComponent(
        String(invoiceId)
      )}/status_mass`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Delete download
   * @param download_id ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteDownload(
    download_id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DownloadList>;
  public cmsDeleteDownload(
    download_id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DownloadList>>;
  public cmsDeleteDownload(
    download_id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DownloadList>>;
  public cmsDeleteDownload(
    download_id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (download_id === null || download_id === undefined) {
      throw new Error(
        'Required parameter download_id was null or undefined when calling cmsDeleteDownload.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DownloadList>(
      'post',
      `${this.basePath}/cms/downloads/${encodeURIComponent(
        String(download_id)
      )}/delete`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json', // manual changes
      }
    );
  }

  /**
   *
   * Delete a invoice
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsDeleteFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsDeleteFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsDeleteFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/finance-invoices/invoices/delete`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Delete an invoice line
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteFinanceInvoiceLine(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceInvoice>;
  public cmsDeleteFinanceInvoiceLine(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceInvoice>>;
  public cmsDeleteFinanceInvoiceLine(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceInvoice>>;
  public cmsDeleteFinanceInvoiceLine(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsDeleteFinanceInvoiceLine.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FinanceInvoice>(
      'delete',
      `${this.basePath}/cms/finance-invoices/invoice-line/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Delete invoice static content - request body is an array of ids that will get deleted
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteInvoiceStaticContent(
    body?: InvoiceStaticContentDeleteRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceStaticContentsList>;
  public cmsDeleteInvoiceStaticContent(
    body?: InvoiceStaticContentDeleteRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceStaticContentsList>>;
  public cmsDeleteInvoiceStaticContent(
    body?: InvoiceStaticContentDeleteRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceStaticContentsList>>;
  public cmsDeleteInvoiceStaticContent(
    body?: InvoiceStaticContentDeleteRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InvoiceStaticContentsList>(
      'post',
      `${this.basePath}/cms/finance-administration/invoice-static-contents/delete`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Edit an invoice line
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsEditFinanceInvoiceLine(
    id: number,
    body?: FinanceInvoiceProductItemEditRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceInvoice>;
  public cmsEditFinanceInvoiceLine(
    id: number,
    body?: FinanceInvoiceProductItemEditRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceInvoice>>;
  public cmsEditFinanceInvoiceLine(
    id: number,
    body?: FinanceInvoiceProductItemEditRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceInvoice>>;
  public cmsEditFinanceInvoiceLine(
    id: number,
    body?: FinanceInvoiceProductItemEditRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsEditFinanceInvoiceLine.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FinanceInvoice>(
      'put',
      `${this.basePath}/cms/finance-invoices/invoice-line/${encodeURIComponent(
        String(id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json', // manual changes
      }
    );
  }

  /**
   * Exports the file containing all of the downloads
   * Export downloads
   * @param page Pagination page
   * @param page_size Items per page
   * @param date_from
   * @param date_to
   * @param company
   * @param product_name
   * @param content_type
   * @param user_type
   * @param user_id
   * @param user_name
   * @param author_id
   * @param author_name
   * @param agency_id
   * @param agency_name
   * @param variation
   * @param above_price
   * @param below_price
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsExportDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    company?: string,
    product_name?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    user_name?: string,
    author_id?: number,
    author_name?: string,
    agency_id?: number,
    agency_name?: string,
    variation?: string,
    above_price?: number,
    below_price?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public cmsExportDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    company?: string,
    product_name?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    user_name?: string,
    author_id?: number,
    author_name?: string,
    agency_id?: number,
    agency_name?: string,
    variation?: string,
    above_price?: number,
    below_price?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public cmsExportDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    company?: string,
    product_name?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    user_name?: string,
    author_id?: number,
    author_name?: string,
    agency_id?: number,
    agency_name?: string,
    variation?: string,
    above_price?: number,
    below_price?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public cmsExportDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    company?: string,
    product_name?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    user_name?: string,
    author_id?: number,
    author_name?: string,
    agency_id?: number,
    agency_name?: string,
    variation?: string,
    above_price?: number,
    below_price?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (date_from !== undefined && date_from !== null) {
      queryParameters = queryParameters.set('date_from', <any>date_from);
    }
    if (date_to !== undefined && date_to !== null) {
      queryParameters = queryParameters.set('date_to', <any>date_to);
    }
    if (company !== undefined && company !== null) {
      queryParameters = queryParameters.set('company', <any>company);
    }
    if (product_name !== undefined && product_name !== null) {
      queryParameters = queryParameters.set('product_name', <any>product_name);
    }
    if (content_type !== undefined && content_type !== null) {
      queryParameters = queryParameters.set('content_type', <any>content_type);
    }
    if (user_type !== undefined && user_type !== null) {
      queryParameters = queryParameters.set('user_type', <any>user_type);
    }
    if (user_id !== undefined && user_id !== null) {
      queryParameters = queryParameters.set('user_id', <any>user_id);
    }
    if (user_name !== undefined && user_name !== null) {
      queryParameters = queryParameters.set('user_name', <any>user_name);
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (author_name !== undefined && author_name !== null) {
      queryParameters = queryParameters.set('author_name', <any>author_name);
    }
    if (agency_id !== undefined && agency_id !== null) {
      queryParameters = queryParameters.set('agency_id', <any>agency_id);
    }
    if (agency_name !== undefined && agency_name !== null) {
      queryParameters = queryParameters.set('agency_name', <any>agency_name);
    }
    if (variation !== undefined && variation !== null) {
      queryParameters = queryParameters.set('variation', <any>variation);
    }
    if (above_price !== undefined && above_price !== null) {
      queryParameters = queryParameters.set('above_price', <any>above_price);
    }
    if (below_price !== undefined && below_price !== null) {
      queryParameters = queryParameters.set('below_price', <any>below_price);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/octet-stream'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'get',
      `${this.basePath}/cms/downloads/export`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json', // manual changes
      }
    );
  }

  /**
   *
   * Get single invoice for finances
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsFinanceInvoice(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceInvoice>;
  public cmsFinanceInvoice(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceInvoice>>;
  public cmsFinanceInvoice(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceInvoice>>;
  public cmsFinanceInvoice(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsFinanceInvoice.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FinanceInvoice>(
      'get',
      `${this.basePath}/cms/finance-invoices/invoice/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fiscalize a invoice
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsFiscalizeFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsFiscalizeFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsFiscalizeFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsFiscalizeFinanceInvoice(
    body?: FinanceInvoiceDeleteRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/finance-invoices/invoices/fiscalize`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Disable galleries auto-publish
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGalleriesDisablePixsellAutoPublish(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsGalleriesDisablePixsellAutoPublish(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsGalleriesDisablePixsellAutoPublish(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsGalleriesDisablePixsellAutoPublish(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/cms/galleries/pixsell-auto-publish`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Enable galleries auto-publish
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGalleriesEnablePixsellAutoPublish(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsGalleriesEnablePixsellAutoPublish(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsGalleriesEnablePixsellAutoPublish(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsGalleriesEnablePixsellAutoPublish(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/galleries/pixsell-auto-publish`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get Pixsell Auto Publish Mode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGalleriesGetPixsellAutoPublish(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsGalleriesGetPixsellAutoPublish(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsGalleriesGetPixsellAutoPublish(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsGalleriesGetPixsellAutoPublish(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/cms/galleries/pixsell-auto-publish`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get companies
   * @param page Pagination page
   * @param page_size Items per page
   * @param from_date
   * @param to_date
   * @param company_name
   * @param oib
   * @param company_id
   * @param address
   * @param contact_person
   * @param status
   * @param country
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetCompanies(
    page?: number,
    page_size?: number,
    from_date?: string,
    to_date?: string,
    company_name?: string,
    oib?: string,
    company_id?: string,
    address?: string,
    contact_person?: string,
    status?: string,
    country?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CompanyList>;
  public cmsGetCompanies(
    page?: number,
    page_size?: number,
    from_date?: string,
    to_date?: string,
    company_name?: string,
    oib?: string,
    company_id?: string,
    address?: string,
    contact_person?: string,
    status?: string,
    country?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CompanyList>>;
  public cmsGetCompanies(
    page?: number,
    page_size?: number,
    from_date?: string,
    to_date?: string,
    company_name?: string,
    oib?: string,
    company_id?: string,
    address?: string,
    contact_person?: string,
    status?: string,
    country?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CompanyList>>;
  public cmsGetCompanies(
    page?: number,
    page_size?: number,
    from_date?: string,
    to_date?: string,
    company_name?: string,
    oib?: string,
    company_id?: string,
    address?: string,
    contact_person?: string,
    status?: string,
    country?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (from_date !== undefined && from_date !== null) {
      queryParameters = queryParameters.set('from_date', <any>from_date);
    }
    if (to_date !== undefined && to_date !== null) {
      queryParameters = queryParameters.set('to_date', <any>to_date);
    }
    if (company_name !== undefined && company_name !== null) {
      queryParameters = queryParameters.set('company_name', <any>company_name);
    }
    if (oib !== undefined && oib !== null) {
      queryParameters = queryParameters.set('oib', <any>oib);
    }
    if (company_id !== undefined && company_id !== null) {
      queryParameters = queryParameters.set('company_id', <any>company_id);
    }
    if (address !== undefined && address !== null) {
      queryParameters = queryParameters.set('address', <any>address);
    }
    if (contact_person !== undefined && contact_person !== null) {
      queryParameters = queryParameters.set(
        'contact_person',
        <any>contact_person
      );
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }
    if (country !== undefined && country !== null) {
      queryParameters = queryParameters.set('country', <any>country);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<CompanyList>(
      'get',
      `${this.basePath}/cms/companies`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get company downloads
   * @param company_id
   * @param page Pagination page
   * @param page_size Items per page
   * @param date_from Date from
   * @param date_to Date to
   * @param user User
   * @param variation variation
   * @param content_type content_type
   * @param name Name
   * @param price Price
   * @param notice Notice
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetCompanyDownloads(
    company_id: number,
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    user?: string,
    variation?: string,
    content_type?: string,
    name?: string,
    price?: string,
    notice?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DownloadList>;
  public cmsGetCompanyDownloads(
    company_id: number,
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    user?: string,
    variation?: string,
    content_type?: string,
    name?: string,
    price?: string,
    notice?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DownloadList>>;
  public cmsGetCompanyDownloads(
    company_id: number,
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    user?: string,
    variation?: string,
    content_type?: string,
    name?: string,
    price?: string,
    notice?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DownloadList>>;
  public cmsGetCompanyDownloads(
    company_id: number,
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    user?: string,
    variation?: string,
    content_type?: string,
    name?: string,
    price?: string,
    notice?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (company_id === null || company_id === undefined) {
      throw new Error(
        'Required parameter company_id was null or undefined when calling cmsGetCompanyDownloads.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (date_from !== undefined && date_from !== null) {
      queryParameters = queryParameters.set('date_from', <any>date_from);
    }
    if (date_to !== undefined && date_to !== null) {
      queryParameters = queryParameters.set('date_to', <any>date_to);
    }
    if (user !== undefined && user !== null) {
      queryParameters = queryParameters.set('user', <any>user);
    }
    if (variation !== undefined && variation !== null) {
      queryParameters = queryParameters.set('variation', <any>variation);
    }
    if (content_type !== undefined && content_type !== null) {
      queryParameters = queryParameters.set('content_type', <any>content_type);
    }
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set('name', <any>name);
    }
    if (price !== undefined && price !== null) {
      queryParameters = queryParameters.set('price', <any>price);
    }
    if (notice !== undefined && notice !== null) {
      queryParameters = queryParameters.set('notice', <any>notice);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DownloadList>(
      'get',
      `${this.basePath}/cms/companies/${encodeURIComponent(
        String(company_id)
      )}/downloads`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get company invoices
   * @param company_id
   * @param page Pagination page
   * @param page_size Items per page
   * @param date_from Date from
   * @param date_to Date to
   * @param invoice_number Invoice number
   * @param status Status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetCompanyInvoices(
    company_id: number,
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: string,
    status?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceList>;
  public cmsGetCompanyInvoices(
    company_id: number,
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: string,
    status?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceList>>;
  public cmsGetCompanyInvoices(
    company_id: number,
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: string,
    status?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceList>>;
  public cmsGetCompanyInvoices(
    company_id: number,
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: string,
    status?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (company_id === null || company_id === undefined) {
      throw new Error(
        'Required parameter company_id was null or undefined when calling cmsGetCompanyInvoices.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (date_from !== undefined && date_from !== null) {
      queryParameters = queryParameters.set('date_from', <any>date_from);
    }
    if (date_to !== undefined && date_to !== null) {
      queryParameters = queryParameters.set('date_to', <any>date_to);
    }
    if (invoice_number !== undefined && invoice_number !== null) {
      queryParameters = queryParameters.set(
        'invoice_number',
        <any>invoice_number
      );
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InvoiceList>(
      'get',
      `${this.basePath}/cms/companies/${encodeURIComponent(
        String(company_id)
      )}/invoices`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get company users
   * @param company_id
   * @param page Pagination page
   * @param page_size Items per page
   * @param first_name First name
   * @param last_name Last name
   * @param email
   * @param user_id
   * @param user_rights
   * @param account_status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetCompanyUsers(
    company_id: number,
    page?: number,
    page_size?: number,
    first_name?: string,
    last_name?: string,
    email?: string,
    user_id?: string,
    user_rights?: string,
    account_status?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserList>;
  public cmsGetCompanyUsers(
    company_id: number,
    page?: number,
    page_size?: number,
    first_name?: string,
    last_name?: string,
    email?: string,
    user_id?: string,
    user_rights?: string,
    account_status?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserList>>;
  public cmsGetCompanyUsers(
    company_id: number,
    page?: number,
    page_size?: number,
    first_name?: string,
    last_name?: string,
    email?: string,
    user_id?: string,
    user_rights?: string,
    account_status?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserList>>;
  public cmsGetCompanyUsers(
    company_id: number,
    page?: number,
    page_size?: number,
    first_name?: string,
    last_name?: string,
    email?: string,
    user_id?: string,
    user_rights?: string,
    account_status?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (company_id === null || company_id === undefined) {
      throw new Error(
        'Required parameter company_id was null or undefined when calling cmsGetCompanyUsers.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (first_name !== undefined && first_name !== null) {
      queryParameters = queryParameters.set('first_name', <any>first_name);
    }
    if (last_name !== undefined && last_name !== null) {
      queryParameters = queryParameters.set('last_name', <any>last_name);
    }
    if (email !== undefined && email !== null) {
      queryParameters = queryParameters.set('email', <any>email);
    }
    if (user_id !== undefined && user_id !== null) {
      queryParameters = queryParameters.set('user_id', <any>user_id);
    }
    if (user_rights !== undefined && user_rights !== null) {
      queryParameters = queryParameters.set('user_rights', <any>user_rights);
    }
    if (account_status !== undefined && account_status !== null) {
      queryParameters = queryParameters.set(
        'account_status',
        <any>account_status
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<UserList>(
      'get',
      `${this.basePath}/cms/companies/${encodeURIComponent(
        String(company_id)
      )}/users`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get download
   * @param download_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetDownload(
    download_id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Download>;
  public cmsGetDownload(
    download_id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Download>>;
  public cmsGetDownload(
    download_id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Download>>;
  public cmsGetDownload(
    download_id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (download_id === null || download_id === undefined) {
      throw new Error(
        'Required parameter download_id was null or undefined when calling cmsGetDownload.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Download>(
      'get',
      `${this.basePath}/cms/downloads/${encodeURIComponent(
        String(download_id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the product IPTC
   * @param downloadId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetDownloadIptc(
    downloadId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<IptcHtmlResponse>;
  public cmsGetDownloadIptc(
    downloadId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<IptcHtmlResponse>>;
  public cmsGetDownloadIptc(
    downloadId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<IptcHtmlResponse>>;
  public cmsGetDownloadIptc(
    downloadId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (downloadId === null || downloadId === undefined) {
      throw new Error(
        'Required parameter downloadId was null or undefined when calling cmsGetDownloadIptc.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<IptcHtmlResponse>(
      'get',
      `${this.basePath}/cms/downloads/${encodeURIComponent(
        String(downloadId)
      )}/iptc`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get downloads
   * @param page Pagination page
   * @param page_size Items per page
   * @param date_from
   * @param date_to
   * @param company
   * @param product_name
   * @param content_type
   * @param user_type
   * @param user_id
   * @param user_name
   * @param author_id
   * @param author_name
   * @param agency_id
   * @param agency_name
   * @param variation
   * @param above_price
   * @param below_price
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    company?: string,
    product_name?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    user_name?: string,
    author_id?: number,
    author_name?: string,
    agency_id?: number,
    agency_name?: string,
    variation?: string,
    above_price?: number,
    below_price?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DownloadList>;
  public cmsGetDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    company?: string,
    product_name?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    user_name?: string,
    author_id?: number,
    author_name?: string,
    agency_id?: number,
    agency_name?: string,
    variation?: string,
    above_price?: number,
    below_price?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DownloadList>>;
  public cmsGetDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    company?: string,
    product_name?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    user_name?: string,
    author_id?: number,
    author_name?: string,
    agency_id?: number,
    agency_name?: string,
    variation?: string,
    above_price?: number,
    below_price?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DownloadList>>;
  public cmsGetDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    company?: string,
    product_name?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    user_name?: string,
    author_id?: number,
    author_name?: string,
    agency_id?: number,
    agency_name?: string,
    variation?: string,
    above_price?: number,
    below_price?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (date_from !== undefined && date_from !== null) {
      queryParameters = queryParameters.set('date_from', <any>date_from);
    }
    if (date_to !== undefined && date_to !== null) {
      queryParameters = queryParameters.set('date_to', <any>date_to);
    }
    if (company !== undefined && company !== null) {
      queryParameters = queryParameters.set('company', <any>company);
    }
    if (product_name !== undefined && product_name !== null) {
      queryParameters = queryParameters.set('product_name', <any>product_name);
    }
    if (content_type !== undefined && content_type !== null) {
      queryParameters = queryParameters.set('content_type', <any>content_type);
    }
    if (user_type !== undefined && user_type !== null) {
      queryParameters = queryParameters.set('user_type', <any>user_type);
    }
    if (user_id !== undefined && user_id !== null) {
      queryParameters = queryParameters.set('user_id', <any>user_id);
    }
    if (user_name !== undefined && user_name !== null) {
      queryParameters = queryParameters.set('user_name', <any>user_name);
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (author_name !== undefined && author_name !== null) {
      queryParameters = queryParameters.set('author_name', <any>author_name);
    }
    if (agency_id !== undefined && agency_id !== null) {
      queryParameters = queryParameters.set('agency_id', <any>agency_id);
    }
    if (agency_name !== undefined && agency_name !== null) {
      queryParameters = queryParameters.set('agency_name', <any>agency_name);
    }
    if (variation !== undefined && variation !== null) {
      queryParameters = queryParameters.set('variation', <any>variation);
    }
    if (above_price !== undefined && above_price !== null) {
      queryParameters = queryParameters.set('above_price', <any>above_price);
    }
    if (below_price !== undefined && below_price !== null) {
      queryParameters = queryParameters.set('below_price', <any>below_price);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DownloadList>(
      'get',
      `${this.basePath}/cms/downloads`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get invoice static content item item
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetFinanceAdministartionItemItem(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceAdministartionItemItem>;
  public cmsGetFinanceAdministartionItemItem(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceAdministartionItemItem>>;
  public cmsGetFinanceAdministartionItemItem(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceAdministartionItemItem>>;
  public cmsGetFinanceAdministartionItemItem(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsGetFinanceAdministartionItemItem.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FinanceAdministartionItemItem>(
      'get',
      `${this.basePath}/cms/finance-administration/items/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get finance administration items list
   * @param page Pagination page
   * @param page_size Items per page
   * @param sort Sort - whatever this is
   * @param contract_type Contract type
   * @param content_type Content type
   * @param agency_name Agency name
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetFinanceAdministrationItems(
    page?: number,
    page_size?: number,
    sort?: string,
    contract_type?: string,
    content_type?: string,
    agency_name?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceAdministartionItemList>;
  public cmsGetFinanceAdministrationItems(
    page?: number,
    page_size?: number,
    sort?: string,
    contract_type?: string,
    content_type?: string,
    agency_name?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceAdministartionItemList>>;
  public cmsGetFinanceAdministrationItems(
    page?: number,
    page_size?: number,
    sort?: string,
    contract_type?: string,
    content_type?: string,
    agency_name?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceAdministartionItemList>>;
  public cmsGetFinanceAdministrationItems(
    page?: number,
    page_size?: number,
    sort?: string,
    contract_type?: string,
    content_type?: string,
    agency_name?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = queryParameters.set('sort', <any>sort);
    }
    if (contract_type !== undefined && contract_type !== null) {
      queryParameters = queryParameters.set(
        'contract_type',
        <any>contract_type
      );
    }
    if (content_type !== undefined && content_type !== null) {
      queryParameters = queryParameters.set('content_type', <any>content_type);
    }
    if (agency_name !== undefined && agency_name !== null) {
      queryParameters = queryParameters.set('agency_name', <any>agency_name);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FinanceAdministartionItemList>(
      'get',
      `${this.basePath}/cms/finance-administration/items`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get finance calculations
   * @param page Pagination page
   * @param page_size Items per page
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetFinanceCalculations(
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceCalculationsList>;
  public cmsGetFinanceCalculations(
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceCalculationsList>>;
  public cmsGetFinanceCalculations(
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceCalculationsList>>;
  public cmsGetFinanceCalculations(
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FinanceCalculationsList>(
      'get',
      `${this.basePath}/cms/finance-administration/calculations`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get list of invoices inside of fiances
   * @param page Pagination page
   * @param page_size Items per page
   * @param date_from
   * @param date_to
   * @param invoice_number
   * @param company
   * @param invoice_type
   * @param payment_method
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetFinanceInvoiceList(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: number,
    company?: string,
    invoice_type?: string,
    payment_method?: string,
    status?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceInvoiceList>;
  public cmsGetFinanceInvoiceList(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: number,
    company?: string,
    invoice_type?: string,
    payment_method?: string,
    status?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceInvoiceList>>;
  public cmsGetFinanceInvoiceList(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: number,
    company?: string,
    invoice_type?: string,
    payment_method?: string,
    status?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceInvoiceList>>;
  public cmsGetFinanceInvoiceList(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: number,
    company?: string,
    invoice_type?: string,
    payment_method?: string,
    status?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (date_from !== undefined && date_from !== null) {
      queryParameters = queryParameters.set('date_from', <any>date_from);
    }
    if (date_to !== undefined && date_to !== null) {
      queryParameters = queryParameters.set('date_to', <any>date_to);
    }
    if (invoice_number !== undefined && invoice_number !== null) {
      queryParameters = queryParameters.set(
        'invoice_number',
        <any>invoice_number
      );
    }
    if (company !== undefined && company !== null) {
      queryParameters = queryParameters.set('company', <any>company);
    }
    if (invoice_type !== undefined && invoice_type !== null) {
      queryParameters = queryParameters.set('invoice_type', <any>invoice_type);
    }
    if (payment_method !== undefined && payment_method !== null) {
      queryParameters = queryParameters.set(
        'payment_method',
        <any>payment_method
      );
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FinanceInvoiceList>(
      'get',
      `${this.basePath}/cms/finance-invoices/invoices`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get invoice PDF
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetFinanceInvoicePDF(
    body?: FinanceInvoicePdfRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public cmsGetFinanceInvoicePDF(
    body?: FinanceInvoicePdfRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public cmsGetFinanceInvoicePDF(
    body?: FinanceInvoicePdfRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public cmsGetFinanceInvoicePDF(
    body?: FinanceInvoicePdfRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/pdf'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/pdf'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Blob>(
      'post',
      `${this.basePath}/cms/finance-invoices/invoices/pdf`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json', // manual changes
      }
    );
  }

  /**
   *
   * Get sap journal entry
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetFinanceInvoiceSapJournalEntry(
    body?: FinanceInvoiceSapJournalEntryRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public cmsGetFinanceInvoiceSapJournalEntry(
    body?: FinanceInvoiceSapJournalEntryRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public cmsGetFinanceInvoiceSapJournalEntry(
    body?: FinanceInvoiceSapJournalEntryRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public cmsGetFinanceInvoiceSapJournalEntry(
    body?: FinanceInvoiceSapJournalEntryRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Blob>(
      'post',
      `${this.basePath}/cms/finance-invoices/invoices/sap-journal-entry`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json', // manual changes
      }
    );
  }

  /**
   *
   * Get sap journal entry
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetFinanceInvoiceSapJournalEntryCount(
    body?: FinanceInvoiceSapJournalEntryRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SapJournalEntryCount>;
  public cmsGetFinanceInvoiceSapJournalEntryCount(
    body?: FinanceInvoiceSapJournalEntryRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SapJournalEntryCount>>;
  public cmsGetFinanceInvoiceSapJournalEntryCount(
    body?: FinanceInvoiceSapJournalEntryRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SapJournalEntryCount>>;
  public cmsGetFinanceInvoiceSapJournalEntryCount(
    body?: FinanceInvoiceSapJournalEntryRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<SapJournalEntryCount>(
      'post',
      `${this.basePath}/cms/finance-invoices/invoices/sap-journal-entry/count`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get invoice static content item
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetInvoiceStaticContentItem(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceStaticContentItem>;
  public cmsGetInvoiceStaticContentItem(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceStaticContentItem>>;
  public cmsGetInvoiceStaticContentItem(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceStaticContentItem>>;
  public cmsGetInvoiceStaticContentItem(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsGetInvoiceStaticContentItem.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InvoiceStaticContentItem>(
      'get',
      `${
        this.basePath
      }/cms/finance-administration/invoice-static-contents/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get invoices
   * @param page Pagination page
   * @param page_size Items per page
   * @param date_from Date from
   * @param date_to Date to
   * @param invoice_number Invoice number
   * @param status Status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetInvoices(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: string,
    status?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceList>;
  public cmsGetInvoices(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: string,
    status?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceList>>;
  public cmsGetInvoices(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: string,
    status?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceList>>;
  public cmsGetInvoices(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    invoice_number?: string,
    status?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (date_from !== undefined && date_from !== null) {
      queryParameters = queryParameters.set('date_from', <any>date_from);
    }
    if (date_to !== undefined && date_to !== null) {
      queryParameters = queryParameters.set('date_to', <any>date_to);
    }
    if (invoice_number !== undefined && invoice_number !== null) {
      queryParameters = queryParameters.set(
        'invoice_number',
        <any>invoice_number
      );
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InvoiceList>(
      'get',
      `${this.basePath}/cms/invoices`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get invocie static contents
   * @param page Pagination page
   * @param page_size Items per page
   * @param name
   * @param code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetInvoieStaticContents(
    page?: number,
    page_size?: number,
    name?: string,
    code?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceStaticContentsList>;
  public cmsGetInvoieStaticContents(
    page?: number,
    page_size?: number,
    name?: string,
    code?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceStaticContentsList>>;
  public cmsGetInvoieStaticContents(
    page?: number,
    page_size?: number,
    name?: string,
    code?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceStaticContentsList>>;
  public cmsGetInvoieStaticContents(
    page?: number,
    page_size?: number,
    name?: string,
    code?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set('name', <any>name);
    }
    if (code !== undefined && code !== null) {
      queryParameters = queryParameters.set('code', <any>code);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InvoiceStaticContentsList>(
      'get',
      `${this.basePath}/cms/finance-administration/invoice-static-contents`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get sap tax indicator
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetSapTaxIndicatorItem(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceSapTaxIndicatorItem>;
  public cmsGetSapTaxIndicatorItem(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceSapTaxIndicatorItem>>;
  public cmsGetSapTaxIndicatorItem(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceSapTaxIndicatorItem>>;
  public cmsGetSapTaxIndicatorItem(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsGetSapTaxIndicatorItem.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FinanceSapTaxIndicatorItem>(
      'get',
      `${
        this.basePath
      }/cms/finance-administration/sap_tax_indicators/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get SAP tax indicators list
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetSapTaxIndicatorsList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceSapTaxIndicatorList>;
  public cmsGetSapTaxIndicatorsList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceSapTaxIndicatorList>>;
  public cmsGetSapTaxIndicatorsList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceSapTaxIndicatorList>>;
  public cmsGetSapTaxIndicatorsList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FinanceSapTaxIndicatorList>(
      'get',
      `${this.basePath}/cms/finance-administration/sap_tax_indicators`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get users
   * @param page Pagination page
   * @param page_size Items per page
   * @param from_date
   * @param to_date
   * @param first_name Name
   * @param last_name last_name
   * @param company company
   * @param username username
   * @param email
   * @param user_type
   * @param status
   * @param download_rights
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetUsers(
    page?: number,
    page_size?: number,
    from_date?: string,
    to_date?: string,
    first_name?: string,
    last_name?: string,
    company?: string,
    username?: string,
    email?: string,
    user_type?: string,
    status?: string,
    download_rights?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserList>;
  public cmsGetUsers(
    page?: number,
    page_size?: number,
    from_date?: string,
    to_date?: string,
    first_name?: string,
    last_name?: string,
    company?: string,
    username?: string,
    email?: string,
    user_type?: string,
    status?: string,
    download_rights?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserList>>;
  public cmsGetUsers(
    page?: number,
    page_size?: number,
    from_date?: string,
    to_date?: string,
    first_name?: string,
    last_name?: string,
    company?: string,
    username?: string,
    email?: string,
    user_type?: string,
    status?: string,
    download_rights?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserList>>;
  public cmsGetUsers(
    page?: number,
    page_size?: number,
    from_date?: string,
    to_date?: string,
    first_name?: string,
    last_name?: string,
    company?: string,
    username?: string,
    email?: string,
    user_type?: string,
    status?: string,
    download_rights?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (from_date !== undefined && from_date !== null) {
      queryParameters = queryParameters.set('from_date', <any>from_date);
    }
    if (to_date !== undefined && to_date !== null) {
      queryParameters = queryParameters.set('to_date', <any>to_date);
    }
    if (first_name !== undefined && first_name !== null) {
      queryParameters = queryParameters.set('first_name', <any>first_name);
    }
    if (last_name !== undefined && last_name !== null) {
      queryParameters = queryParameters.set('last_name', <any>last_name);
    }
    if (company !== undefined && company !== null) {
      queryParameters = queryParameters.set('company', <any>company);
    }
    if (username !== undefined && username !== null) {
      queryParameters = queryParameters.set('username', <any>username);
    }
    if (email !== undefined && email !== null) {
      queryParameters = queryParameters.set('email', <any>email);
    }
    if (user_type !== undefined && user_type !== null) {
      queryParameters = queryParameters.set('user_type', <any>user_type);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }
    if (download_rights !== undefined && download_rights !== null) {
      queryParameters = queryParameters.set(
        'download_rights',
        <any>download_rights
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<UserList>(
      'get',
      `${this.basePath}/cms/users`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Post finance administration item
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPostFinanceAdministrationItem(
    body?: FinanceAdministartionItemItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceAdministartionItemItem>;
  public cmsPostFinanceAdministrationItem(
    body?: FinanceAdministartionItemItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceAdministartionItemItem>>;
  public cmsPostFinanceAdministrationItem(
    body?: FinanceAdministartionItemItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceAdministartionItemItem>>;
  public cmsPostFinanceAdministrationItem(
    body?: FinanceAdministartionItemItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FinanceAdministartionItemItem>(
      'post',
      `${this.basePath}/cms/finance-administration/items`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Post new finance calculation
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPostFinanceCalculation(
    body?: FinanceCalculationItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceCalculationItem>;
  public cmsPostFinanceCalculation(
    body?: FinanceCalculationItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceCalculationItem>>;
  public cmsPostFinanceCalculation(
    body?: FinanceCalculationItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceCalculationItem>>;
  public cmsPostFinanceCalculation(
    body?: FinanceCalculationItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FinanceCalculationItem>(
      'post',
      `${this.basePath}/cms/finance-administration/calculations`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Post new invoice static content
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPostInvoiceStaticContent(
    body?: InvoiceStaticContentItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceStaticContentItem>;
  public cmsPostInvoiceStaticContent(
    body?: InvoiceStaticContentItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceStaticContentItem>>;
  public cmsPostInvoiceStaticContent(
    body?: InvoiceStaticContentItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceStaticContentItem>>;
  public cmsPostInvoiceStaticContent(
    body?: InvoiceStaticContentItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InvoiceStaticContentItem>(
      'post',
      `${this.basePath}/cms/finance-administration/invoice-static-contents`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Post SAP tax indicator
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPostSapTaxIndicator(
    body?: FinanceSapTaxIndicatorItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceSapTaxIndicatorList>;
  public cmsPostSapTaxIndicator(
    body?: FinanceSapTaxIndicatorItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceSapTaxIndicatorList>>;
  public cmsPostSapTaxIndicator(
    body?: FinanceSapTaxIndicatorItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceSapTaxIndicatorList>>;
  public cmsPostSapTaxIndicator(
    body?: FinanceSapTaxIndicatorItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FinanceSapTaxIndicatorList>(
      'post',
      `${this.basePath}/cms/finance-administration/sap_tax_indicators`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Post new finance calculation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsResumeB2C(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsResumeB2C(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsResumeB2C(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsResumeB2C(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/cms/finance-administration/suspend-b2c`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Save download
   * @param download_id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsSaveDownload(
    download_id: number,
    body?: UpdateDownload,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Download>;
  public cmsSaveDownload(
    download_id: number,
    body?: UpdateDownload,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Download>>;
  public cmsSaveDownload(
    download_id: number,
    body?: UpdateDownload,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Download>>;
  public cmsSaveDownload(
    download_id: number,
    body?: UpdateDownload,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (download_id === null || download_id === undefined) {
      throw new Error(
        'Required parameter download_id was null or undefined when calling cmsSaveDownload.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Download>(
      'put',
      `${this.basePath}/cms/downloads/${encodeURIComponent(
        String(download_id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Save invoice static content item item
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsSaveFinanceAdministartionItemItem(
    id: number,
    body?: FinanceAdministartionItemItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceAdministartionItemItem>;
  public cmsSaveFinanceAdministartionItemItem(
    id: number,
    body?: FinanceAdministartionItemItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceAdministartionItemItem>>;
  public cmsSaveFinanceAdministartionItemItem(
    id: number,
    body?: FinanceAdministartionItemItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceAdministartionItemItem>>;
  public cmsSaveFinanceAdministartionItemItem(
    id: number,
    body?: FinanceAdministartionItemItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsSaveFinanceAdministartionItemItem.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FinanceAdministartionItemItem>(
      'put',
      `${this.basePath}/cms/finance-administration/items/${encodeURIComponent(
        String(id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Save invoice static content item
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsSaveInvoiceStaticContentItem(
    id: number,
    body?: InvoiceStaticContentItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceStaticContentItem>;
  public cmsSaveInvoiceStaticContentItem(
    id: number,
    body?: InvoiceStaticContentItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceStaticContentItem>>;
  public cmsSaveInvoiceStaticContentItem(
    id: number,
    body?: InvoiceStaticContentItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceStaticContentItem>>;
  public cmsSaveInvoiceStaticContentItem(
    id: number,
    body?: InvoiceStaticContentItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsSaveInvoiceStaticContentItem.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InvoiceStaticContentItem>(
      'put',
      `${
        this.basePath
      }/cms/finance-administration/invoice-static-contents/${encodeURIComponent(
        String(id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Save sap tax indicator
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsSaveSapTaxIndicatorItem(
    id: number,
    body?: FinanceSapTaxIndicatorItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FinanceSapTaxIndicatorItem>;
  public cmsSaveSapTaxIndicatorItem(
    id: number,
    body?: FinanceSapTaxIndicatorItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FinanceSapTaxIndicatorItem>>;
  public cmsSaveSapTaxIndicatorItem(
    id: number,
    body?: FinanceSapTaxIndicatorItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FinanceSapTaxIndicatorItem>>;
  public cmsSaveSapTaxIndicatorItem(
    id: number,
    body?: FinanceSapTaxIndicatorItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsSaveSapTaxIndicatorItem.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FinanceSapTaxIndicatorItem>(
      'put',
      `${
        this.basePath
      }/cms/finance-administration/sap_tax_indicators/${encodeURIComponent(
        String(id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json', // manual changes
      }
    );
  }

  /**
   *
   * Get finance calculations
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsSuspendB2C(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsSuspendB2C(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsSuspendB2C(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsSuspendB2C(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/finance-administration/suspend-b2c`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get B2C suspension status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsSuspendB2CStatus(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsSuspendB2CStatus(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsSuspendB2CStatus(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsSuspendB2CStatus(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/cms/finance-administration/suspend-b2c`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
