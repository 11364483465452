import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TemplateBlock, Translatable } from 'piwe-front-swagger-client';
import {
  BlockData,
  HomepageCreatorDataService,
} from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-data.service';
import { EditModeTypes } from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-sidebar/sidebar-page-builder-edit/sidebar-page-builder-edit.component';
import { selectDeselectAnimation } from '../block-container/block-container.component';
import { DoubleClickSectionObject } from '../sections/section/section.component';
import { HomepageBlockProductPreview } from '../../../../../projects/piwe-front-swagger-client/src';
import { Router } from '@angular/router';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
  animations: [selectDeselectAnimation],
})
export class BlockComponent implements OnInit {
  @Input() blockVariant!: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  private aspectRatios = [0.45, 0.45, 0.33, 0.44, 0.22, 0.56, 0.56];
  @Input() data: any[] = [];
  public images: any[] = [];
  @Input() pagebuilderMode: boolean = true;
  @Input() isBeingHovered: boolean = false;

  @Output() blockDoubleClicked: EventEmitter<DoubleClickSectionObject> =
    new EventEmitter();

  public numberArray: number[] = [];
  @Input() isSelectedInsideThisBlock: boolean = false;

  @ViewChild('grid') gridReference!: ElementRef;
  public isDoubleClicked: boolean = false;
  public doubleClickedIndex!: number;

  @Input() isThisBlockSelected: boolean = false;

  @Input() blockData!: TemplateBlock;

  public noImgUrl: string = '';

  public headlineArray: string[] = [];

  constructor(
    private homepageCreatorDataService: HomepageCreatorDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const numberOfPictures =
      this.homepageCreatorDataService.getNumberOfPictures({
        id: this.blockVariant,
        variation: 'block',
      });

    for (let i = 1; i <= numberOfPictures; i++) {
      this.numberArray.push(i);
    }

    this.noImgUrl = this.homepageCreatorDataService.noImgUrl;

    if (!this.pagebuilderMode) this.initHeadlineArray();
  }

  initHeadlineArray() {
    this.headlineArray = this.blockData.products!.map((element) => {
      return (
        element?.title?.find((t: Translatable) => t.language == 'HR')
          ?.content ?? ''
      );
    });
  }

  ngAfterViewInit() {
    this.calculateGridRows();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calculateGridRows();
  }

  calculateUrl(url: string): string {
    return url.replace('photo/preview/', 'photo/pnw/');
  }

  calculateGridRows() {
    //block height changes depending on the width of the grid and the aspect ratio of the blockVariant
    const width = this.gridReference.nativeElement.offsetWidth;
    const height = width * this.aspectRatios[this.blockVariant - 1];

    let gridTemplateRowsValue: string = '';
    switch (this.blockVariant) {
      case 1:
      case 2:
      case 4:
        gridTemplateRowsValue = `${height / 2}px ${height / 2}px`;
        break;
      case 3:
      case 5:
        gridTemplateRowsValue = `${height}px`;
        break;
      case 6:
        gridTemplateRowsValue = `${height * 0.4}px ${height * 0.6}px`;
        break;
      case 7:
        gridTemplateRowsValue = `${height * 0.6}px ${height * 0.4}px`;
        break;
    }
    this.gridReference.nativeElement.style.gridTemplateRows =
      gridTemplateRowsValue;
  }

  onImageLoad($event: any) {
    const target = $event.target;
    let aspectRatio: number = target.naturalWidth / target.naturalHeight;
    target.parentElement.parentElement.style.flex = aspectRatio;
  }

  handleDoubleClick(index: number) {
    const obj: DoubleClickSectionObject = {
      innerIndex: index,
      editModeType: EditModeTypes.ImageEditor,
    };
    this.blockDoubleClicked.emit(obj);

    this.isDoubleClicked = true;
    this.doubleClickedIndex = index;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSelectedInsideThisBlock) {
      if (changes.isSelectedInsideThisBlock.previousValue) {
        this.isDoubleClicked = false;
        this.doubleClickedIndex = -1;
      }
    }
  }

  hasCustomUrl(block: HomepageBlockProductPreview | null) {
    if (block?.link == undefined || block?.link.length == 0) {
      if (block?.id === -999) return undefined;

      if (block?.kind === 'GALLERY') {
        return this.router.createUrlTree(['/ga/', block?.id]);
      } else {
        return this.router.createUrlTree(['/g/', block?.id]);
      }
    }

    return block?.link;
  }
}
