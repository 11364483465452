/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PushNotificationTokenRegistration {
  platform?: PushNotificationTokenRegistration.PlatformEnum;
  token?: string;
}
export namespace PushNotificationTokenRegistration {
  export type PlatformEnum = 'WEB';
  export const PlatformEnum = {
    WEB: 'WEB' as PlatformEnum,
  };
}
