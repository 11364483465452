/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UploadSessionPublishRequest {
  is_newsletter_push: boolean;
  is_notification_push: boolean;
  kind: UploadSessionPublishRequest.KindEnum;
}
export namespace UploadSessionPublishRequest {
  export type KindEnum = 'SAVE' | 'PUBLISH';
  export const KindEnum = {
    SAVE: 'SAVE' as KindEnum,
    PUBLISH: 'PUBLISH' as KindEnum,
  };
}
