<div [ngClass]="'theme-' + theme + ' type-' + type">
  <div class="field-title">{{ label }}</div>
  <div class="field-group-container">
    <div class="field-container" [class.disabled]="disabled">
      <ng-container *ngIf="type === 'view-date-time'">
        <input
          type="text"
          [placeholder]="placeholder"
          [disabled]="disabled"
          [valueAsDate]
          [type]="type"
          #inputEl
          [ngModel]="formattedValue"
          (blur)="onBlur()"
          (focus)="onFocus()"
        />
      </ng-container>

      <ng-container
        *ngIf="
          type !== 'date' && type !== 'view-date-time' && type !== 'single-date'
        "
      >
        <input
          type="text"
          [placeholder]="placeholder"
          [disabled]="disabled"
          [valueAsDate]
          [type]="type"
          #inputEl
          [(ngModel)]="value"
          (ngModelChange)="onValueChanged()"
          (blur)="onBlur()"
          (focus)="onFocus()"
        />
        <input
          type="text"
          *ngIf="isDoubleInput"
          [placeholder]="placeholder"
          [disabled]="disabled"
          [type]="type"
          #inputEl
          [(ngModel)]="secondValue"
          (ngModelChange)="onSecondValueChanged()"
          (blur)="onBlur()"
          (focus)="onFocus()"
        />
      </ng-container>

      <ng-container *ngIf="type === 'date'">
        <dp-date-picker
          [config]="config2"
          theme="dp-material"
          class="date-picker"
          placeholder="From date"
          [(ngModel)]="value"
          (ngModelChange)="onValueChanged()"
        ></dp-date-picker>
        <div class="date-picker-vertical-divider"></div>
        <dp-date-picker
          [config]="config2"
          theme="dp-material"
          class="date-picker"
          placeholder="To date"
          [(ngModel)]="secondValue"
          (ngModelChange)="onSecondValueChanged()"
        ></dp-date-picker>
        <div
          class="reset-date"
          *ngIf="value !== '' && value !== undefined"
          (click)="resetDateInput(1)"
        ></div>
        <div
          class="reset-date reset-date2"
          *ngIf="secondValue !== '' && secondValue !== undefined"
          (click)="resetDateInput(2)"
        ></div>
      </ng-container>

      <ng-container *ngIf="type === 'single-date'">
        <dp-date-picker
          #date
          [config]="config"
          mode="daytime"
          (click)="!disabled && !isCalendarOpen && date.showCalendars()"
          (open)="onCalendarOpen()"
          (close)="onCalendarClose()"
          theme="dp-material"
          class="single-date-picker"
          [disabled]="true"
          [placeholder]="placeholder!"
          [ngModel]="formattedValue"
          (ngModelChange)="updateSingleDate($event)"
        ></dp-date-picker>
        <div
          class="reset-date"
          *ngIf="formattedValue !== '' && formattedValue !== undefined"
          (click)="resetDateInput(3)"
        ></div>
      </ng-container>

      <div
        class="expand"
        *ngIf="autocompleteType != undefined"
        [class.active]="focus && !hideAutocomplete"
        (mousedown)="onAutocompleteToggleMouseDown()"
        (click)="onAutocompleteToggleClick()"
      ></div>

      <div
        class="field-autocomplete-toggle"
        [style.display]="autocompleteType != undefined ? 'flex' : 'none'"
        [class.active]="focus && !hideAutocomplete"
        (mousedown)="onAutocompleteToggleMouseDown()"
        (click)="onAutocompleteToggleClick()"
      >
        <img
          src="assets/import-autocomplete-toggle.svg"
          class="field-autocomplete-toggle-icon"
        />
      </div>

      <ul
        #autocomplete
        class="field-autocomplete"
        [style.width.px]="autocompleteWidth"
        [style.display]="focus && !hideAutocomplete ? 'block' : 'none'"
      >
        <li
          *ngFor="let autocompleteOption of autocompleteOptions"
          (mousedown)="onAutocompleteClick(autocompleteOption)"
          [innerHTML]="autocompleteOption.displayTitle"
        ></li>
      </ul>
    </div>

    <div
      #actionsToggleEl
      class="field-action-toggle"
      (click)="openActions()"
      *ngIf="actions.length > 0"
    ></div>
  </div>

  <!-- Errors -->
  <div *ngIf="errors != undefined && errors.length > 0" class="field-errors">
    <div *ngFor="let error of errors" class="field-error">
      {{ error.error_description }}
    </div>
  </div>
</div>

<ul
  #actionsEl
  class="field-autocomplete"
  style="position: absolute; top: 0px; left: 0px"
  [style.visibility]="shouldShowActionDropdown ? 'visible' : 'hidden'"
  [style.width.px]="actionsDropdownWidth"
>
  <li *ngFor="let action of actions" (mousedown)="onActionClick(action)">
    {{ action.title }}
  </li>
</ul>
