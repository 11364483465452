/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DynamicView } from '../model/dynamicView';
import { DynamicViewCreateRequest } from '../model/dynamicViewCreateRequest';
import { DynamicViewEditRequest } from '../model/dynamicViewEditRequest';
import { DynamicViewIndexRequest } from '../model/dynamicViewIndexRequest';
import { DynamicViewMultipleResults } from '../model/dynamicViewMultipleResults';
import { ErrorResponse } from '../model/errorResponse';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { DynamicViewSingleResult } from '../model/dynamicViewSingleResult';

@Injectable()
export class DynamicViewsService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * List all dynamic views
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetDynamicViews(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DynamicView>>;
  public cmsGetDynamicViews(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DynamicView>>>;
  public cmsGetDynamicViews(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DynamicView>>>;
  public cmsGetDynamicViews(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<DynamicView>>(
      'get',
      `${this.basePath}/cms/dynamic_views`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Create view
   * @param viewId View ID
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetViewCreate(
    viewId: string,
    body?: DynamicViewCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DynamicViewSingleResult>;
  public cmsGetViewCreate(
    viewId: string,
    body?: DynamicViewCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<DynamicViewSingleResult>;
  public cmsGetViewCreate(
    viewId: string,
    body?: DynamicViewCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<DynamicViewSingleResult>;
  public cmsGetViewCreate(
    viewId: string,
    body?: DynamicViewCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (viewId === null || viewId === undefined) {
      throw new Error(
        'Required parameter viewId was null or undefined when calling cmsGetViewCreate.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (viewId !== undefined && viewId !== null) {
      queryParameters = queryParameters.set('viewId', <any>viewId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DynamicViewSingleResult>(
      'post',
      `${this.basePath}/cms/dynamic_views/${viewId}/show/`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Delete view
   * @param viewId View ID
   * @param id ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetViewDelete(
    viewId: string,
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsGetViewDelete(
    viewId: string,
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsGetViewDelete(
    viewId: string,
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsGetViewDelete(
    viewId: string,
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (viewId === null || viewId === undefined) {
      throw new Error(
        'Required parameter viewId was null or undefined when calling cmsGetViewDelete.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsGetViewDelete.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (viewId !== undefined && viewId !== null) {
      queryParameters = queryParameters.set('viewId', <any>viewId);
    }
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/cms/dynamic_views/${viewId}/show/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Index view
   * @param viewId View ID
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetViewIndex(
    viewId: string,
    body?: DynamicViewIndexRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DynamicViewMultipleResults>;
  public cmsGetViewIndex(
    viewId: string,
    body?: DynamicViewIndexRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DynamicViewMultipleResults>>;
  public cmsGetViewIndex(
    viewId: string,
    body?: DynamicViewIndexRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DynamicViewMultipleResults>>;
  public cmsGetViewIndex(
    viewId: string,
    body?: DynamicViewIndexRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (viewId === null || viewId === undefined) {
      throw new Error(
        'Required parameter viewId was null or undefined when calling cmsGetViewIndex.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (viewId !== undefined && viewId !== null) {
      //queryParameters = queryParameters.set('viewId', <any>viewId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DynamicViewMultipleResults>(
      'post',
      `${this.basePath}/cms/dynamic_views/${viewId}/index`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Show view
   * @param viewId View ID
   * @param id ID
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetViewShow(
    viewId: string,
    id: number,
    body?: DynamicViewIndexRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DynamicViewSingleResult>;
  public cmsGetViewShow(
    viewId: string,
    id: number,
    body?: DynamicViewIndexRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DynamicViewSingleResult>>;
  public cmsGetViewShow(
    viewId: string,
    id: number,
    body?: DynamicViewIndexRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DynamicViewSingleResult>>;
  public cmsGetViewShow(
    viewId: string,
    id: number,
    body?: DynamicViewIndexRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (viewId === null || viewId === undefined) {
      throw new Error(
        'Required parameter viewId was null or undefined when calling cmsGetViewShow.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsGetViewShow.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (viewId !== undefined && viewId !== null) {
      //queryParameters = queryParameters.set('viewId', <any>viewId);
    }
    if (id !== undefined && id !== null) {
      //queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DynamicViewSingleResult>(
      'get',
      `${this.basePath}/cms/dynamic_views/${viewId}/show/${id}`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update view
   * @param viewId View ID
   * @param id ID
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetViewUpdate(
    viewId: string,
    id: number,
    body?: DynamicViewEditRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsGetViewUpdate(
    viewId: string,
    id: number,
    body?: DynamicViewEditRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsGetViewUpdate(
    viewId: string,
    id: number,
    body?: DynamicViewEditRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsGetViewUpdate(
    viewId: string,
    id: number,
    body?: DynamicViewEditRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (viewId === null || viewId === undefined) {
      throw new Error(
        'Required parameter viewId was null or undefined when calling cmsGetViewUpdate.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsGetViewUpdate.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (viewId !== undefined && viewId !== null) {
      //queryParameters = queryParameters.set('viewId', <any>viewId);
    }
    if (id !== undefined && id !== null) {
      //queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/cms/dynamic_views/${viewId}/show/${id}`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
