<div class="filter-container">
  <div class="button-container">
    <div class="button-container-left">
      <img src="/assets/filters.svg" class="tn" />
      <div
        id="text-holder"
        class="button-container-text"
        [innerHTML]="'filter_sidebar.title' | translate"
      >
        Filter
      </div>
    </div>
    <button id="image-button" (click)="closeFilterSidebar()"></button>
  </div>

  <div class="content" [formGroup]="filterForm" *ngIf="filterForm">
    <app-expansion-panel class="collapsible" *ngIf="activeFilters.length">
      <app-expansion-panel-header>
        <b [innerHTML]="'filter_sidebar.active_filters' | translate"
          >AKTIVNI FILTERI</b
        >
      </app-expansion-panel-header>
      <div class="active-filters-container">
        <div *ngFor="let item of activeFilters">
          <button class="categoryItemRemove" (click)="removeActiveFilter(item)">
            {{
              item.translationString
                ? ("filter_sidebar." + item.translationString | translate)
                : (item.title | titlecase)
            }}
            <img
              src="/assets/remove-filter.svg"
              alt=""
              (click)="removeActiveFilter(item)"
            />
          </button>
        </div>
      </div>
    </app-expansion-panel>

    <hr class="filter-hr" *ngIf="activeFilters.length" />
    <app-expansion-panel class="collapsible" *ngIf="showCategories">
      <app-expansion-panel-header>
        <b [innerHTML]="'filter_sidebar.category' | translate">KATEGORIJA</b>
      </app-expansion-panel-header>
      <div formGroupName="category" class="input-category-container">
        <div class="input-group--category" *ngFor="let category of categories">
          <label>
            <input
              type="checkbox"
              name="category-{{ category.id! }}"
              id="category-{{ category.id! }}"
              formControlName="category-{{ category.id! }}"
            />
            <span>{{ category.name | translatable }}</span>
          </label>
        </div>
      </div>
    </app-expansion-panel>

    <hr class="filter-hr" *ngIf="showCategories" />
    <app-expansion-panel class="collapsible" *ngIf="showTimeInterval">
      <app-expansion-panel-header>
        <b [innerHTML]="'filter_sidebar.time_interval' | translate"
          >VREMENSKI INTERVAL</b
        >
      </app-expansion-panel-header>

      <div formGroupName="timeInterval">
        <div>
          <div class="input-group--radio">
            <label for="24h">
              <input
                type="radio"
                name="timeIntervalRadio"
                formControlName="timeIntervalRadio"
                value="24h"
                id="24h"
              />
              <div class="custom-radio"></div>
              <div
                style="display: 'inline'"
                [innerHTML]="'filter_sidebar.last_24h' | translate"
              >
                Zadnja 24 sata
              </div>
            </label>
          </div>

          <div class="input-group--radio">
            <label for="48h">
              <input
                type="radio"
                name="timeIntervalRadio"
                formControlName="timeIntervalRadio"
                value="48h"
                id="48h"
              />
              <div class="custom-radio"></div>
              <div
                style="display: 'inline'"
                [innerHTML]="'filter_sidebar.last_48h' | translate"
              >
                Zadnjih 48 sati
              </div>
            </label>
          </div>
          <div class="input-group--radio">
            <label for="3M">
              <input
                type="radio"
                name="timeIntervalRadio"
                formControlName="timeIntervalRadio"
                value="3M"
                id="3M"
              />
              <div class="custom-radio"></div>
              <div
                style="display: 'inline'"
                [innerHTML]="'filter_sidebar.last_3m' | translate"
              >
                Zadnja 3 mjeseca
              </div>
            </label>
          </div>

          <div class="input-group--radio">
            <label for="all">
              <input
                type="radio"
                name="timeIntervalRadio"
                formControlName="timeIntervalRadio"
                value="all"
                id="all"
              />
              <div class="custom-radio"></div>
              <div
                style="display: 'inline'"
                [innerHTML]="'filter_sidebar.no_interval' | translate"
              >
                Bez intervala
              </div>
            </label>
          </div>

          <div class="input-group--radio">
            <label for="customDate">
              <input
                type="radio"
                name="timeIntervalRadio"
                formControlName="timeIntervalRadio"
                value="customDate"
                id="customDate"
              />
              <div class="custom-radio"></div>
              <div
                style="display: 'inline'"
                [innerHTML]="'filter_sidebar.custom_date' | translate"
              >
                Prilagođeni datum
              </div>
            </label>
          </div>
        </div>
        <div>
          <div class="date-picker-c">
            <label
              for="startDate"
              [innerHTML]="'filter_sidebar.from' | translate"
              >Od:</label
            >
            <!-- <input
              type="date"
              id="startDate"
              name="startDate"
              formControlName="startDate"
              [valueAsNumber]
            /> -->
            <dp-date-picker
              #startDateElem
              [config]="config"
              theme="dp-material"
              class="date-picker"
              [ngModel]="startDate"
              (ngModelChange)="changeStartDate($event)"
              [ngModelOptions]="{ standalone: true }"
            ></dp-date-picker>
            <!-- <label for="startDate" class="date-picker-button"></label> -->
            <button
              class="reset-date"
              (click)="resetStartDate()"
              *ngIf="startDate !== '' && startDate !== undefined"
            ></button>
          </div>
          <div class="date-picker-c">
            <label for="endDate" [innerHTML]="'filter_sidebar.to' | translate"
              >Do:</label
            >
            <!-- <input
              type="date"
              id="endDate"
              name="endDate"
              formControlName="endDate"
              [valueAsDate]
            /> -->
            <dp-date-picker
              #endDateElem
              [config]="config"
              theme="dp-material"
              class="date-picker"
              [ngModel]="endDate"
              (ngModelChange)="changeEndDate($event)"
              [ngModelOptions]="{ standalone: true }"
            ></dp-date-picker>
            <!-- resetDateButton -->
            <button
              class="reset-date"
              (click)="resetEndDate()"
              *ngIf="endDate !== '' && endDate !== undefined"
            ></button>
          </div>
        </div>
      </div>
    </app-expansion-panel>
    <hr class="filter-hr" *ngIf="showTimeInterval" />

    <app-expansion-panel *ngIf="showContentType" class="collapsible">
      <app-expansion-panel-header>
        <b [innerHTML]="'filter_sidebar.content_type' | translate"
          >VRSTA SADRŽAJA</b
        >
      </app-expansion-panel-header>
      <div formGroupName="contentType">
        <div class="input-group--radio">
          <label for="foto">
            <input
              type="radio"
              formControlName="content"
              name="content"
              value="foto"
              id="foto"
            />
            <div class="custom-radio"></div>
            {{ "filter_sidebar.photo" | translate }}
          </label>
        </div>

        <div class="input-group--radio">
          <label for="video">
            <input
              type="radio"
              formControlName="content"
              name="content"
              value="video"
              id="video"
            />
            <div class="custom-radio"></div>
            {{ "filter_sidebar.video" | translate }}
          </label>
        </div>
      </div>
    </app-expansion-panel>
    <hr class="filter-hr" *ngIf="showContentType" />

    <div *ngIf="showRefinementFilters">
      <div
        *ngFor="let refinementFilterKey of refinementFiltersKeys; let n = index"
      >
        <app-expansion-panel
          class="collapsible"
          [defaultIsHidden]="
            !ALWAYS_OPEN_REFINEMENT_FILTERS.includes(
              refinementFilterKey.toLowerCase()
            )
          "
        >
          <app-expansion-panel-header>
            <b style="display: block">
              {{
                "filter_sidebar." +
                  refinementFiltersCategoryTranslationKeys[refinementFilterKey]
                  | translate
              }}
            </b>
          </app-expansion-panel-header>
          <div formGroupName="refinementFilters" class="tags">
            <ng-container
              *ngFor="
                let refinementFilterValue of refinementFilters[
                  refinementFilterKey
                ] | slice : 0 : (refinementFilterMask[n] ? undefined : 5);
                let i = index
              "
            >
              <div class="input-group--checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="refinementFilter-{{ refinementFilterValue.id }}"
                    id="refinementFilter{{ refinementFilterValue.id }}"
                    formControlName="refinementFilter-{{
                      refinementFilterValue.id
                    }}"
                  />
                  <div class="custom-checkbox"></div>
                  {{
                    "filter_sidebar." + refinementFilterValue.translationString!
                      | translate
                  }}
                </label>
              </div>
            </ng-container>
            <button
              *ngIf="refinementFilters[refinementFilterKey].length > 5"
              class="agencies-link"
              (click)="toggleRefinementFilterMaskElement(n)"
              [innerHTML]="
                refinementFilterMask[n]
                  ? ('filter_sidebar.show_less' | translate)
                  : ('filter_sidebar.show_all' | translate)
              "
            >
              Prikaži sve
            </button>
          </div>
        </app-expansion-panel>
        <hr class="filter-hr" />
      </div>
    </div>

    <app-expansion-panel class="collapsible" *ngIf="showTags">
      <app-expansion-panel-header>
        <b [innerHTML]="'filter_sidebar.tag' | translate">OZNAKA</b>
      </app-expansion-panel-header>
      <div formGroupName="tags" class="tags">
        <ng-container *ngFor="let tag of tagsArray; let i = index">
          <div class="input-group--checkbox">
            <label>
              <input
                type="checkbox"
                name="tag-{{ tag.name }}"
                id="tag-{{ tag.name }}"
                formControlName="tag-{{ tag.name }}"
              />
              <div class="custom-checkbox"></div>
              {{ currentLanguage == "hr" ? tag.text : tag.textEn }}
              <img *ngIf="tag.icon" src="/assets/tags/{{ tag.icon }}" alt="" />
            </label>
          </div>
        </ng-container>
      </div>
    </app-expansion-panel>

    <div *ngFor="let filter of extraFilters">
      <ng-container [ngSwitch]="filter">
        <ng-container *ngSwitchCase="'agencies'">
          <hr class="filter-hr" />
          <app-expansion-panel class="collapsible">
            <app-expansion-panel-header>
              <b [innerHTML]="'filter_sidebar.agencies' | translate"
                >AGENCIJE</b
              >
            </app-expansion-panel-header>
            <div class="tags" *ngIf="!areAllAgenciesVisible">
              <ng-container
                formGroupName="agencies"
                *ngFor="let ag of agenciesArr | slice : 0 : 6; let i = index"
              >
                <!-- condition for reuters -->
                <ng-container *ngIf="ag.name == 'REUTERS' || i < 5">
                  <div class="input-group--checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="agency-{{ ag.id }}"
                        id="agency-{{ ag.id }}"
                        formControlName="agency-{{ ag.id }}"
                        value="agency-{{ ag.id }}"
                      />
                      <div class="custom-checkbox"></div>
                      {{ agenciesArr[i].name }}
                    </label>
                  </div>
                </ng-container>
              </ng-container>
              <button
                class="agencies-link"
                (click)="showAllAgencies()"
                [innerHTML]="'filter_sidebar.show_all' | translate"
              >
                Prikaži sve
              </button>
            </div>
            <!-- expanded -->
            <div class="tags" *ngIf="areAllAgenciesVisible">
              <ng-container
                formGroupName="agencies"
                *ngFor="let ag of agenciesArr; let i = index"
              >
                <div class="input-group--checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="agency-{{ ag.id }}"
                      id="agency-{{ ag.id }}"
                      formControlName="agency-{{ ag.id }}"
                      value="agency-{{ ag.id }}"
                    />
                    <div class="custom-checkbox"></div>
                    {{ agenciesArr[i].name }}
                  </label>
                </div>
              </ng-container>
              <button
                class="agencies-link"
                (click)="showLessAgencies()"
                [innerHTML]="'filter_sidebar.show_less' | translate"
              >
                Prikaži manje
              </button>
            </div>
          </app-expansion-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'photoJournalist'">
          <hr class="filter-hr" />
          <app-expansion-panel class="collapsible">
            <app-expansion-panel-header>
              <b [innerHTML]="'filter_sidebar.photojournalist' | translate"
                >FOTOREPORTER</b
              >
            </app-expansion-panel-header>
            <div>
              <label for="photoJournalist" class="journalist-input">
                <img src="/assets/search-icon.svg" alt="" />

                <div formGroupName="photoJournalist">
                  <input
                    type="text"
                    id="photoJournalist"
                    formControlName="photoJournalist"
                    name="photoJournalist"
                    placeholder="{{
                      'filter_sidebar.photojournalist_search_placeholder'
                        | translate
                    }}"
                    style="display: none"
                  />
                </div>

                <input
                  #inputEl
                  type="text"
                  id="photoJournalistAuto"
                  placeholder="{{
                    'filter_sidebar.photojournalist_search_placeholder'
                      | translate
                  }}"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="value"
                  (ngModelChange)="onValueChanged()"
                  (blur)="onBlur()"
                  (focus)="onFocus()"
                />

                <div
                  #autocomplete
                  class="field-autocomplete"
                  [style.display]="focus ? 'block' : 'none'"
                  [style.width.px]="autocompleteWidth"
                >
                  <ul *ngIf="value != undefined && value.length != 0">
                    <li
                      *ngFor="let autocompleteOption of autocompleteOptions"
                      (mousedown)="onAutocompleteClick(autocompleteOption)"
                    >
                      {{ autocompleteOption.title }}
                    </li>
                  </ul>
                </div>
              </label>
            </div>
          </app-expansion-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'format'">
          <hr class="filter-hr" />
          <app-expansion-panel class="collapsible">
            <app-expansion-panel-header>
              <b [innerHTML]="'filter_sidebar.format' | translate">FORMAT</b>
            </app-expansion-panel-header>
            <div formGroupName="formats">
              <div class="input-group--radio">
                <label for="vertical">
                  <input
                    type="radio"
                    name="format"
                    formControlName="format"
                    name="format"
                    id="vertical"
                    value="vertical"
                  />
                  <div class="custom-radio"></div>
                  {{ "filter_sidebar.vertical" | translate }}
                </label>
              </div>

              <div class="input-group--radio">
                <label for="horizontal">
                  <input
                    type="radio"
                    name="format"
                    formControlName="format"
                    name="format"
                    id="horizontal"
                    value="horizontal"
                  />
                  <div class="custom-radio"></div>
                  {{ "filter_sidebar.horizontal" | translate }}
                </label>
              </div>

              <div class="input-group--radio">
                <label for="square">
                  <input
                    type="radio"
                    name="format"
                    formControlName="format"
                    name="format"
                    id="square"
                    value="square"
                  />
                  <div class="custom-radio"></div>
                  {{ "filter_sidebar.square" | translate }}
                </label>
              </div>
            </div>
          </app-expansion-panel>
        </ng-container>
        <ng-container *ngSwitchCase="'color'">
          <hr class="filter-hr" />
          <app-expansion-panel class="collapsible">
            <app-expansion-panel-header>
              <b [innerHTML]="'filter_sidebar.color' | translate">BOJA</b>
            </app-expansion-panel-header>
            <div formGroupName="colors" class="colors">
              <label for="color">
                <input
                  type="radio"
                  name="color"
                  formControlName="color"
                  name="color"
                  id="color"
                  value="color"
                />
                <div class="color-all"></div>
              </label>
              <label for="blackAndWhite">
                <input
                  type="radio"
                  name="color"
                  formControlName="color"
                  name="color"
                  id="blackAndWhite"
                  value="blackAndWhite"
                />
                <div class="color-black"></div>
              </label>
            </div>
          </app-expansion-panel>
        </ng-container>
        <ng-container *ngSwitchDefault>
          Wrong extra filter value!!
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
