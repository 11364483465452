import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'autocompleteTags',
})
export class AutocompleteTagsPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (value == undefined || value.length == 0) return '<empty>';

    return value.map((t: any) => t.title).join(', ');
  }
}
