<a
  class="image-container"
  [routerLink]="
    pagebuilderMode
      ? []
      : mediaData?.kind === 'GALLERY'
      ? ['/ga/', mediaData?.id]
      : ['/g/', mediaData?.id]
  "
>
  <app-single-image
    *ngIf="mediaData !== null"
    class="image"
    [imageLoadingPreviewUrl]="mediaData!.preview![0].preview_url_blur!"
    [imagePreviewUrl]="mediaData!.preview![0].preview_url!"
  >
  </app-single-image>
  <app-single-image
    *ngIf="mediaData === null"
    class="image"
    [imageLoadingPreviewUrl]="noImgSectionUrl"
    [imagePreviewUrl]="noImgSectionUrl"
  >
  </app-single-image>
  <div class="overlay">
    <div class="overlay-text">
      <div class="overlay-text-top" *ngIf="mediaData?.timestamp !== null">
        {{ mediaData?.timestamp | date : "dd.MM.yyyy. HH:mm" }}
      </div>
      <div class="overlay-text-top" *ngIf="mediaData?.timestamp === null">
        {{ "01.01.1900. 00:00" }}
      </div>
      <div class="overlay-text-bottom">
        {{
          (currentLanguage ? productTitle : productTitleEn) ||
            "Placeholder headline"
        }}
      </div>
    </div>
    <!-- <button></button> -->
  </div>
</a>
