import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TemplateBlock } from 'piwe-front-swagger-client';
import {
  BlockData,
  HomepageCreatorDataService,
} from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-data.service';
import { EditModeTypes } from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-sidebar/sidebar-page-builder-edit/sidebar-page-builder-edit.component';
import { DoubleClickSectionObject } from '../../section/section.component';

@Component({
  selector: 'app-homepage-section-contact',
  templateUrl: './homepage-section-contact.component.html',
  styleUrls: ['./homepage-section-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageSectionContactComponent implements OnInit {
  @Input() isSelectedInsideThisBlock: boolean = false;
  @Output() blockDoubleClicked: EventEmitter<DoubleClickSectionObject> =
    new EventEmitter();
  @Input() doubleClickIndex: number | null = null;

  @Input() blockData!: TemplateBlock;

  @Input() pagebuilderMode: boolean = true;

  public noImgUrl: string = '';
  constructor(private homepageCreatorDataService: HomepageCreatorDataService) {}

  ngOnInit(): void {
    this.noImgUrl = this.homepageCreatorDataService.noImgUrl;
  }
  handleImageDoubleClick(i: number) {
    const emitObj: DoubleClickSectionObject = {
      innerIndex: i,
      editModeType: EditModeTypes.ImageEditor,
    };
    this.blockDoubleClicked.emit(emitObj);
    this.doubleClickIndex = i;
  }
  handleTextDoubleClick(i: number) {
    const emitObj: DoubleClickSectionObject = {
      innerIndex: i,
      editModeType: EditModeTypes.TextEditor,
    };
    this.blockDoubleClicked.emit(emitObj);
    this.doubleClickIndex = i;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSelectedInsideThisBlock) {
      if (changes.isSelectedInsideThisBlock.previousValue) {
        this.doubleClickIndex = null;
      }
    }
  }
}
