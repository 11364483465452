import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from './permissions.service';
import { map, skip, skipWhile } from 'rxjs/operators';
import { User } from '../../projects/piwe-front-swagger-client/src';
import UserPermissionsEnum = User.UserPermissionsEnum;

@Injectable({
  providedIn: 'root',
})
export class CmsGuard implements CanActivate {
  constructor(
    private permissionsService: PermissionsService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.permissionsService.permissionsData.pipe(
      skipWhile((x) => x == undefined),
      map((data) => {
        if (!data!.includes(UserPermissionsEnum.CPRODUCTSREAD)) {
          this.router.navigateByUrl('/404');
          return false;
        }

        return true;
      })
    );
  }
}
