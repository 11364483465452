<div [ngSwitch]="editModeType">
  <ng-container *ngSwitchCase="1">
    <ng-container *ngTemplateOutlet="imageEditor"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <ng-container *ngTemplateOutlet="textEditor"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="3">
    <ng-container *ngTemplateOutlet="blockEditor"></ng-container>
  </ng-container>
</div>

<ng-template #imageEditor>
  <div class="image-editor" *ngIf="!hasImage">
    <button (click)="handleGallerySelection()">
      <img src="/assets/edit-gallery.svg" alt="" />
      Select gallery
    </button>
    <button (click)="handleGalleryGroupSelection()">
      <img src="/assets/edit-gallery-group.svg" alt="" />
      Select gallery group
    </button>

    <div class="image-editor__text">Or simply upload your image</div>
    <button class="normal-btn" (click)="fileUpload.click()">
      Add an image
    </button>
    <input
      style="visibility: hidden"
      type="file"
      id="fileDialogId"
      requiredFileType="image"
      (change)="handleFileInput($event)"
      #fileUpload
    />
  </div>

  <ng-container *ngIf="hasImage">
    <ng-container *ngTemplateOutlet="imageExisting"> </ng-container>
  </ng-container>
</ng-template>

<ng-template #textEditor>
  <div class="text-editor">
    <label for="headline" class="text-editor__label">Headline</label>
    <input
      type="text"
      name="headline"
      id="headline"
      [(ngModel)]="headline"
      class="text-editor__input"
      (ngModelChange)="headlineChanged()"
    />
    <label for="description" class="text-editor__label">Description</label>
    <textarea
      class="text-editor__textarea"
      name="description"
      id="description"
      [(ngModel)]="description"
      (ngModelChange)="descriptionChanged()"
    ></textarea>
    <div class="en-textfields">
      <label for="headline-en" class="text-editor__label">Headline (EN)</label>
      <input
        type="text"
        name="headline-en"
        id="headline-en"
        [(ngModel)]="headlineEn"
        class="text-editor__input"
        (ngModelChange)="headlineChangedEn()"
      />
      <label for="description-en" class="text-editor__label"
        >Description (EN)</label
      >
      <textarea
        class="text-editor__textarea"
        name="description-en"
        id="description-en"
        [(ngModel)]="descriptionEn"
        (ngModelChange)="descriptionChangedEn()"
      ></textarea>
    </div>
    <label for="titleLink" class="text-editor__label"
      >Custom Headline Link (URL)</label
    >
    <input
      class="text-editor__input"
      name="titleLink"
      id="titleLink"
      [(ngModel)]="titleLink"
      (ngModelChange)="titleLinkChanged()"
      (blur)="titleLinkBlur()"
    />
  </div>
</ng-template>

<ng-template #imageExisting>
  <div class="existing-img__container">
    <div class="existing-img" style="margin-bottom: 20px">
      <div class="existing-img__data-row">
        <img
          class="existing-img__img"
          src="{{ apiUrl + (existingImgData?.preview)![0].preview_url }}"
          alt=""
        />
        <div class="existing-img__text">
          <div class="existing-img__title">{{ productTitle }}</div>
          <div class="existing-img__type">
            {{ existingImgData?.kind?.split("_")?.join(" ") | lowercase }}
          </div>
        </div>
      </div>
      <div class="existing-img__buttons">
        <button (click)="handleImageReplace()">Replace</button>
        <button (click)="handleImageRemove()">Remove</button>
      </div>
    </div>
    <label for="link" class="text-editor__label"
      >Custom Redirect Link (URL)</label
    >
    <input
      class="text-editor__input"
      name="link"
      id="link"
      [(ngModel)]="link"
      (ngModelChange)="linkChanged()"
      (blur)="linkBlur()"
    />
  </div>
</ng-template>

<ng-template #blockEditor>
  <div class="text-editor">
    <label class="text-editor__label">Section colors</label>
    <div class="text-editor__buttons">
      <button
        [style.background]="'#FFFFFF'"
        (click)="handleColorChange('#FFFFFF', '#000000')"
        [class.selected-color]="bgColor === '#FFFFFF'"
      ></button>
      <button
        [style.background]="'#717171'"
        (click)="handleColorChange('#505050')"
        [class.selected-color]="bgColor === '#505050'"
      ></button>
      <button
        [style.background]="'#3E3E3E'"
        (click)="handleColorChange('#1F1F1F')"
        [class.selected-color]="bgColor === '#1F1F1F'"
      ></button>
      <button
        [style.background]="'#0A0A0A'"
        (click)="handleColorChange('#0A0A0A')"
        [class.selected-color]="bgColor === '#0A0A0A'"
      ></button>
    </div>
    <div *ngIf="!showLessColors" class="text-editor__buttons">
      <button
        [style.background]="'#CD2929'"
        (click)="handleColorChange('#B94949')"
        [class.selected-color]="bgColor === '#B94949'"
      ></button>
      <button
        [style.background]="'#DC772E'"
        (click)="handleColorChange('#C1673E')"
        [class.selected-color]="bgColor === '#C1673E'"
      ></button>
      <button
        [style.background]="'#DDD973'"
        (click)="handleColorChange('#DDD973', '#000000')"
        [class.selected-color]="bgColor === '#DDD973'"
      ></button>
      <button
        [style.background]="'#67C048'"
        (click)="handleColorChange('#6D984B')"
        [class.selected-color]="bgColor === '#6D984B'"
      ></button>
      <button
        [style.background]="'#33D7D7'"
        (click)="handleColorChange('#47ACAC')"
        [class.selected-color]="bgColor === '#47ACAC'"
      ></button>
      <button
        [style.background]="'#317FDB'"
        (click)="handleColorChange('#4997B9')"
        [class.selected-color]="bgColor === '#4997B9'"
      ></button
      ><button
        [style.background]="'#3E43C5'"
        (click)="handleColorChange('#4968B9')"
        [class.selected-color]="bgColor === '#4968B9'"
      ></button
      ><button
        [style.background]="'#8533D8'"
        (click)="handleColorChange('#5F49B9')"
        [class.selected-color]="bgColor === '#5F49B9'"
      ></button
      ><button
        [style.background]="'#DB2EA0'"
        (click)="handleColorChange('#B94990')"
        [class.selected-color]="bgColor === '#B94990'"
      ></button>
    </div>
    <div class="text-editor__buttons">
      <button
        [style.background]="'#950808'"
        (click)="handleColorChange('#950808')"
        [class.selected-color]="bgColor === '#950808'"
      ></button>
      <button
        [style.background]="'#AD562E'"
        (click)="handleColorChange('#AD562E')"
        [class.selected-color]="bgColor === '#AD562E'"
      ></button>
      <button
        [style.background]="'#B9B549'"
        (click)="handleColorChange('#B9B549', '#000000')"
        [class.selected-color]="bgColor === '#B9B549'"
      ></button>
      <button
        [style.background]="'#4B5C3E'"
        (click)="handleColorChange('#4B5C3E')"
        [class.selected-color]="bgColor === '#4B5C3E'"
      ></button
      ><button
        [style.background]="'#226161'"
        (click)="handleColorChange('#226161')"
        [class.selected-color]="bgColor === '#226161'"
      ></button>
      <button
        [style.background]="'#316C85'"
        (click)="handleColorChange('#316C85')"
        [class.selected-color]="bgColor === '#316C85'"
      ></button
      ><button
        [style.background]="'#3C5697'"
        (click)="handleColorChange('#3C5697')"
        [class.selected-color]="bgColor === '#3C5697'"
      ></button
      ><button
        [style.background]="'#4C3C9C'"
        (click)="handleColorChange('#4C3C9C')"
        [class.selected-color]="bgColor === '#4C3C9C'"
      ></button
      ><button
        [style.background]="'#8C4372'"
        (click)="handleColorChange('#8C4372')"
        [class.selected-color]="bgColor === '#8C4372'"
      ></button>
    </div>
    <!-- <label for="bgColor" class="text-editor__label">Background color</label>
    <input
      class="text-editor__color"
      type="color"
      name="bgColor"
      id="bgColor"
      [(ngModel)]="bgColor"
      (ngModelChange)="bgColorChanged()"
    />
    <label for="textColor" class="text-editor__label">Text color</label>
    <input
      class="text-editor__color"
      type="color"
      name="textColor"
      id="textColor"
      [(ngModel)]="textColor"
      (ngModelChange)="textColorChanged()"
    />
  </div> -->
  </div>
</ng-template>
