import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as Tether from 'tether';
import {
  AutocompleteItem,
  AutocompleteService,
} from 'piwe-front-swagger-client';
import Popper from 'popper.js';
import {
  debounce,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { interval, merge, Observable, of, Subject, zip } from 'rxjs';
import * as levenshtein from 'fast-levenshtein';
import { ModelError } from '../../../../projects/piwe-front-swagger-client/src';
import { Router } from '@angular/router';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import * as moment from 'moment';
import PopperOptions = Popper.PopperOptions;

export interface AutocompleteOption {
  title: string;
  value: number;
  helperText?: string;
  isSelectable: boolean;
  displayTitle?: string;
}

export interface Action {
  id: string;
  title: string;
}

@Component({
  selector: 'app-field-text',
  templateUrl: './field-text.component.html',
  styleUrls: ['./field-text.component.scss'],
})
export class FieldTextComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() shouldEmptyInputOnAutocompleteSelected: boolean = false;

  /**
   * Name of the field.
   */
  @Input() name?: string;

  @Input() type: string = 'text';

  @Input() isDoubleInput: boolean = false;

  /**
   * Label shown above the input.
   */
  @Input() label: string = '';

  /**
   * Disables input and sets input opacity to 50%.
   */
  @Input() disabled: boolean = false;

  /**
   * Placeholder value.
   */
  @Input() placeholder?: string = '';
  @Input() secondPlaceholder?: string = '';

  /**
   * Input value.
   */
  @Input() value?: string;

  @Input() secondValue?: string;

  formattedValue?: string;

  dateValue?: string;

  /**
   * Event triggered every time the value changes.
   */
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() secondValueChange: EventEmitter<string> =
    new EventEmitter<string>();

  /**
   * Focus on input.
   */
  focus: boolean = false;

  @Input() errors: ModelError[] = [];

  /**
   * Theming.
   */
  @Input() theme: string = '';

  // View children.
  @ViewChild('inputEl') protected inputEl: ElementRef | null = null;

  config: IDatePickerConfig = {
    format: 'DD.MM.YYYY. HH:mm:ss',
    returnedValueType: ECalendarValue.Moment,
    showTwentyFourHours: true,
  };

  config2: IDatePickerConfig = {
    format: 'YYYY-MM-DD',
  };

  ///////////////////////////////
  // Actions
  ///////////////////////////////

  @ViewChild('actionsEl') actionsEl: ElementRef | null = null;
  @ViewChild('actionsToggleEl') actionsToggleEl: ElementRef | null = null;

  @Input() actions: Action[] = [];
  @Output() actionSelected: EventEmitter<{
    field: string;
    action: Action;
  }> = new EventEmitter<{
    field: string;
    action: Action;
  }>();

  public shouldShowActionDropdown: boolean = false;

  private actionsDropdownPopper?: Popper;
  public actionsDropdownWidth: Number = 300.0;

  private readonly actionsDropdownPopperOptions: PopperOptions = {
    placement: 'bottom-end',
    modifiers: {
      flip: {
        behavior: ['left'],
      },

      offset: {
        offset: '-8',
      },
    },
  };

  ///////////////////////////////
  // Autocomplete
  ///////////////////////////////

  tether: Tether | null = null;
  focusLostBecauseButton: boolean = false;

  autocompleteOptions: AutocompleteOption[] = [];
  autocompleteWidth: Number = 0.0;

  @ViewChild('autocomplete') autocomplete: ElementRef | null = null;

  @Input() autocompleteValue?: AutocompleteOption | null;
  @Output() autocompleteValueChange: EventEmitter<AutocompleteOption> =
    new EventEmitter<AutocompleteOption>();

  @Input() acceptUserInput: boolean = false;
  @Input() autocompleteType: string | undefined = undefined;

  @Input() minimalAutocompleteLength = 3;

  hideAutocomplete: boolean = false;

  timeValue?: any;
  isCalendarOpen: boolean = false;

  @Input() hasCreateNewAutocomplete: boolean = true;

  ///////////////////////////////
  // Lifecycles
  ///////////////////////////////

  constructor(
    private elementRef: ElementRef,
    private autocompleteService: AutocompleteService,
    private router: Router
  ) {}

  ngOnInit(): void {
    //
    // if inside products, default date is today
    //
    // might cause issues if dp-date-picker changes formats based on region
    if (
      this.type === 'date' &&
      (this.router.url === '/cms/content/products' ||
        this.router.url === '/cms/content/galleries')
    ) {
      const d = new Date();
      let day = d.getDate().toString() as unknown as string;
      let month = (d.getMonth() + 1).toString() as unknown as string;
      let year = d.getFullYear().toString() as unknown as string;
      if (day.length === 1) {
        day = `0${day}`;
      }
      if (month.length === 1) {
        month = `0${month}`;
      }
      const today = `${year}-${month}-${day}`;
      this.value = today;
      this.secondValue = today;
      this.valueChange.emit(this.value);
      this.secondValueChange.emit(this.secondValue);
    }
  }

  ngAfterViewInit() {
    this.setUpAutocomplete();
  }

  ngOnDestroy() {
    this.tether?.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If the autocomplete value changes, change the value.
    if (this.autocompleteType && changes.autocompleteValue != undefined) {
      if (changes.autocompleteValue.currentValue != undefined) {
        this.value = changes.autocompleteValue.currentValue.title;
      } else {
        this.value = '';
      }
    }

    if (changes.value != undefined) {
      this.oldValue = this.value;

      if (this.type == 'view-date-time') {
        if (
          this.value !== undefined &&
          this.value !== null &&
          this.value.length != 0 &&
          this.value != '<multiple values>'
        ) {
          this.formattedValue = moment(this.value).format(
            'DD.MM.YYYY. HH:mm:ss'
          );
        } else {
          this.formattedValue = this.value;
        }
      }

      if (this.type == 'single-date') {
        if (
          this.value !== undefined &&
          this.value !== null &&
          this.value.length != 0 &&
          this.value != '<multiple values>'
        ) {
          this.formattedValue = moment(this.value).format(
            'DD.MM.YYYY. HH:mm:ss'
          );
        } else {
          this.formattedValue = '';
        }
      }
    }
  }

  updateSingleDate(value: any) {
    if (value != undefined) {
      this.valueChange.emit(moment(value).format('YYYY-MM-DDTHH:mm:ss'));
    }
  }

  ///////////////////////////////
  // Listeners
  ///////////////////////////////

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (
      !this.actionsEl!.nativeElement.contains(event.target) &&
      this.shouldShowActionDropdown
    ) {
      this.hideActions();
    }

    if (
      this.autocompleteType &&
      this.focus &&
      event.target.className == 'tag-clear'
    ) {
      this.isTagClear = true;
    }
  }

  isTagClear: boolean = false;
  oldValue?: string;

  onValueChanged() {
    if (this.oldValue != this.value) {
      this.valueChange.emit(this.value);
      this.oldValue = this.value;
    }
  }

  onSecondValueChanged() {
    this.secondValueChange.emit(this.secondValue);
  }

  ///////////////////////////////
  // Event handling
  ///////////////////////////////

  onFocus() {
    if (!this.autocompleteType) return;

    this.tether = new Tether({
      target: this.inputEl!.nativeElement,
      element: this.autocomplete!.nativeElement,
      attachment: 'top left',
      targetAttachment: 'bottom left',
    });
    this.autocompleteWidth = this.inputEl!.nativeElement.offsetWidth;

    this.focus = true;
  }

  onBlur() {
    if (this.isTagClear) {
      this.isTagClear = false;
      this.inputEl?.nativeElement.focus();
      return;
    }

    if (!this.autocompleteType) return;

    if (this.focusLostBecauseButton) {
      this.focusLostBecauseButton = false;
      this.inputEl?.nativeElement.focus();
      return;
    }

    this.focus = false;
    this.hideAutocomplete = false;

    this.tether?.destroy();
    this.tether = null;

    if (this.acceptUserInput && this.value != this.autocompleteValue?.title) {
      this.autocompleteValue = {
        title: this.value!,
        displayTitle: `New value '${this.value!}'`,
        value: -1,
        isSelectable: false,
      };
      this.autocompleteValueChange.emit(this.autocompleteValue);
    } else {
      if (!this.autocompleteValue) {
        this.value = '';
      } else if (this.value?.length == 0) {
        // If the user clears all content.
        this.value = '';
        this.autocompleteValue = undefined;
        this.autocompleteValueChange.emit(this.autocompleteValue);
      } else {
        this.value = this.autocompleteValue!.title;
      }
    }

    this.autocompleteOptions = [];
  }

  ///////////////////////////////
  // Autocomplete
  ///////////////////////////////

  a?: Observable<string>;
  clearSource = new Subject<string>();

  setUpAutocomplete() {
    if (!this.autocompleteType) return;

    this.a = this.valueChange.pipe(
      // Ignore any value change that is the same.
      distinctUntilChanged(),
      map((value) => {
        // Clear all autocomplete options on each character change.
        this.autocompleteOptions = [];
        return value;
      }),
      // API has no autocomplete if the query has less than 3 characters.
      filter((value) => {
        return value.length >= this.minimalAutocompleteLength;
      })
    );

    // Set the autocomplete option to loading...
    merge(this.a!, this.clearSource.asObservable())
      .pipe(
        map((value) => {
          this.autocompleteOptions = [];

          if (this.acceptUserInput && this.hasCreateNewAutocomplete) {
            this.autocompleteOptions.push({
              value: -1,
              title: this.value!,
              displayTitle: `New value '${this.value!}'`,
              isSelectable: true,
            });
          }

          this.autocompleteOptions.push({
            value: -1,
            title: 'Loading...',
            displayTitle: 'Loading...',
            isSelectable: false,
          });

          return value;
        }),
        // Rate limit everything and ignore fast writers.
        debounce(() => interval(150)),
        // Merge the query value and the query result in one and use switch map.
        // Switch map is awesome because it will cancel the old query when
        // you start a new one.
        switchMap((value) => {
          return zip(of(value), this.getAutocompleteQuery(value)!);
        })
      )
      .subscribe((result) => {
        const [value, autocompleteOptions] = result;

        // We're doing out internal levenshtein sorting here.
        let sortedAutocompleteOption = autocompleteOptions
          .map((a) => {
            return { ...a, score: levenshtein.get(value, a.title) };
          })
          .sort((a, b) => a.score - b.score);

        // Remove more than 5 queries.
        if (sortedAutocompleteOption.length > 5) {
          sortedAutocompleteOption = sortedAutocompleteOption.slice(0, 5);
        }

        // Convert the results into out AutocompleteObject.
        let endResult: Array<AutocompleteOption> = [];
        let apiResult = sortedAutocompleteOption.map((option) => {
          let helperText: string;

          if (option.helper_text !== undefined) {
            helperText = option.helper_text;
          } else {
            helperText = `ID: ${option.id}`;
          }

          let returnOption: AutocompleteOption = {
            title: option.title,
            displayTitle: `${option.title} <span class="autocomplete-id">(${helperText})</span>`,
            value: option.id,
            isSelectable: true,
          };

          return returnOption;
        });

        if (
          this.acceptUserInput &&
          this.hasCreateNewAutocomplete &&
          apiResult.find((a) => a.title == this.value) == undefined
        ) {
          endResult.push({
            value: -1,
            displayTitle: `New value '${this.value!}'`,
            title: this.value!,
            isSelectable: true,
          });
        }

        endResult = endResult.concat(apiResult);

        this.autocompleteOptions = endResult;
      });
  }

  onAutocompleteClick(autocompleteOption: AutocompleteOption) {
    if (!autocompleteOption.isSelectable) {
      this.inputEl?.nativeElement.blur();
      return;
    }

    this.value = autocompleteOption.title;
    this.autocompleteValue = autocompleteOption;
    this.autocompleteValueChange.emit(this.autocompleteValue);
    this.inputEl?.nativeElement.blur();
    this.autocompleteOptions = [];

    if (this.shouldEmptyInputOnAutocompleteSelected) {
      this.value = '';
    }
  }

  onAutocompleteToggleMouseDown() {
    if (this.disabled) return;

    this.focusLostBecauseButton = true;
    this.hideAutocomplete = !this.hideAutocomplete;
  }

  onAutocompleteToggleClick() {
    if (this.disabled) return;

    if (this.focus) return;

    this.inputEl?.nativeElement.focus();
    this.hideAutocomplete = false;
    this.onFocus();

    if (this.minimalAutocompleteLength == 0) {
      this.clearSource.next('');
    }
  }

  getAutocompleteQuery(
    value: string
  ): Observable<AutocompleteItem[]> | undefined {
    switch (this.autocompleteType) {
      case 'user':
        return this.autocompleteService.cmsGetAutocompleteUsersByName(value);
      case 'company':
        return this.autocompleteService.cmsGetAutocompleteCompaniesByTitle(
          value
        );
      case 'gallery':
        return this.autocompleteService.cmsGetAutocompleteGalleriesByTitle(
          value
        );
      case 'currency':
        return this.autocompleteService.cmsGetAutocompleteCurrencyByTitle(
          value,
          'HR'
        );
      case 'country':
        return this.autocompleteService.frontGetAutocompleteCountryByTitle(
          value,
          'HR'
        );
      case 'category':
        return this.autocompleteService.cmsGetAutocompleteCategoriesByTitle(
          value
        );
      case 'contract':
        return this.autocompleteService.cmsGetAutocompleteContractsByName(
          value
        );
      case 'author':
        return this.autocompleteService.cmsGetAutocompleteAuthorsByName(value);
      case 'agency':
        return this.autocompleteService.cmsGetAutocompleteAgenciesByName(value);
      case 'agencyGroups':
        return this.autocompleteService.cmsGetAutocompleteAgencyGroupsByName(
          value
        );
      case 'tag':
        return this.autocompleteService.cmsGetAutocompleteTagsByTitle(value);
      case 'specialOfferType':
        return this.autocompleteService.cmsGetAutocompleteSpecialOfferTypesByName(
          value
        );
      case 'people':
        return this.autocompleteService.cmsGetAutocompletePeopleByName(value);
      case 'keywords':
        return this.autocompleteService.cmsGetAutocompleteKeywordsByTitle(
          value,
          'HR'
        );
      case 'downloaded':
        var autocompleteItems: AutocompleteItem[] = [
          { id: 1, title: 'Downloaded' },
          { id: 2, title: 'Not downloaded' },
        ];

        return of(autocompleteItems);
      case 'productStatus':
        var autocompleteItems: AutocompleteItem[] = [
          { id: 1, title: 'Published' },
          { id: 2, title: 'Not published' },
          { id: 3, title: 'Deleted' },
        ];

        return of(autocompleteItems);
      case 'contentType':
        var autocompleteItems: AutocompleteItem[] = [
          { id: 1, title: 'Photo' },
          { id: 2, title: 'Video' },
        ];

        return of(autocompleteItems);
      case 'euroPhase':
        var autocompleteItems: AutocompleteItem[] = [
          { id: 1, title: 'HRK Only' },
          { id: 2, title: 'Big HRK, Small EUR' },
          { id: 3, title: 'Big EUR, Small HRK' },
          { id: 4, title: 'EUR Only' },
        ];

        return of(autocompleteItems);
    }

    return undefined;
  }

  ///////////////////////////////
  // Actions
  ///////////////////////////////

  openActions() {
    if (this.disabled) return;

    const reference = this.actionsDropdownPopper
      ? this.actionsDropdownPopper
      : this.actionsToggleEl!.nativeElement;
    this.actionsDropdownPopper = new Popper(
      reference,
      this.actionsEl!.nativeElement,
      this.actionsDropdownPopperOptions
    );

    this.autocompleteWidth = this.inputEl!.nativeElement.offsetWidth;

    this.shouldShowActionDropdown = true;
  }

  hideActions() {
    this.tether?.destroy();
    this.tether = null;
    this.shouldShowActionDropdown = false;
  }

  onActionClick(action: Action) {
    this.actionSelected.emit({
      field: this.name!,
      action: action,
    });
    this.hideActions();
  }

  resetDateInput(number: number) {
    switch (number) {
      case 1:
        this.value = '';
        break;

      case 2:
        this.secondValue = '';
        break;

      case 3:
        this.valueChange.emit(undefined);
        this.formattedValue = undefined;
    }
  }
  onCalendarOpen() {
    this.isCalendarOpen = true;
  }
  onCalendarClose() {
    this.isCalendarOpen = false;
  }
}
