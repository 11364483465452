<label for="switch">
  <input
    type="checkbox"
    name="switch"
    id="switch"
    [(ngModel)]="checkbox"
    (ngModelChange)="handleModelChange()"
  />
  <div class="background">
    <div class="circle"></div>
  </div>
</label>
