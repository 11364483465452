import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (value == undefined) return 'No';

    return value ? 'Yes' : 'No';
  }
}
