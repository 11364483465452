import {
  ProfileService as ApiProfileService,
  User,
} from 'piwe-front-swagger-client';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public profileSource = new BehaviorSubject<User | undefined>(undefined);
  public profileData: Observable<User | undefined> =
    this.profileSource.asObservable();

  constructor(private apiProfileService: ApiProfileService) {}

  clearProfile() {
    this.profileSource.next(undefined);
  }

  getProfile() {
    if (this.profileSource.getValue() !== undefined) return;

    this.apiProfileService.frontGetCurrentUser().subscribe((user) => {
      this.profileSource.next(user);
    });
  }
}
