import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { formatNumber } from '@angular/common';
import { ModelError } from '../../../../projects/piwe-front-swagger-client/src';

export interface AutocompleteOption {
  title: string;
  value: number;
  isSelectable: boolean;
}

export interface Action {
  id: string;
  title: string;
}

@Component({
  selector: 'app-field-price',
  templateUrl: './field-price.component.html',
  styleUrls: ['./field-price.component.scss'],
})
export class FieldPriceComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() label?: string;
  @Input() title?: string;

  @Input() disabled: boolean = false;

  @Input() value?: number;
  @Output() valueChange: EventEmitter<number | undefined> = new EventEmitter<
    number | undefined
  >();

  @Input() rawValue?: string;
  @Output() rawValueChange: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >();

  @Input() errors: ModelError[] = [];

  /**
   * Theming.
   */
  @Input() theme: string = '';

  @Input() valueChanged?: number;

  @Output() actionSelected: EventEmitter<{
    field: string;
    action: Action;
  }> = new EventEmitter<{
    field: string;
    action: Action;
  }>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.focused) {
      this.rawValue = formatNumber(this.value ?? 0, 'en-US', '1.2-2');
    }
  }

  ngOnDestroy(): void {}

  formatValue() {
    this.rawValue = formatNumber(this.value ?? 0, 'en-US', '1.2-2');
  }

  focused: boolean = false;

  onBlur() {
    this.focused = false;
    this.rawValue = formatNumber(
      parseFloat(this.rawValue ?? ''),
      'en-US',
      '1.2-2'
    );
  }

  onFocus() {
    this.focused = true;
  }

  onRawValueChanged() {
    this.value = parseFloat(this.rawValue ?? '0');
    this.valueChange.emit(this.value);
  }
}
