import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DraggingService {
  private draggingSubject = new Subject<boolean>();
  public draggingData: Observable<boolean> =
    this.draggingSubject.asObservable();

  constructor() {}

  setDragging(isDragging: boolean) {
    this.draggingSubject.next(isDragging);
  }
}
