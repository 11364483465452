/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MediaPreivew } from './mediaPreivew';
import { Tag } from './tag';
import { Translatable } from './translatable';

/**
 * This object is used on the \"FRONT\" part of the website
 */
export interface HomepageBlockProductPreview {
  id?: number;
  preview?: Array<MediaPreivew>;
  title?: Array<Translatable>;
  tags?: Array<Tag>;
  timestamp?: Date;
  count?: number;
  video_count?: number;
  video_duration?: number;
  has_article?: boolean;
  product_name?: string;
  agency_name?: string;
  author_name?: string;
  kind?: HomepageBlockProductPreview.KindEnum;
  link?: string;
  /**
   * URL to view more information. Will not contain the base URL of the website.
   */
  seo_url?: string;
}
export namespace HomepageBlockProductPreview {
  export type KindEnum = 'SINGLE_PRODUCT' | 'GALLERY' | 'GALLERY_GROUP';
  export const KindEnum = {
    SINGLEPRODUCT: 'SINGLE_PRODUCT' as KindEnum,
    GALLERY: 'GALLERY' as KindEnum,
    GALLERYGROUP: 'GALLERY_GROUP' as KindEnum,
  };
}
