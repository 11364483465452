import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Action } from '../field-text/field-text.component';
import { ModelError } from '../../../../projects/piwe-front-swagger-client/src';
import Popper from 'popper.js';
import PopperOptions = Popper.PopperOptions;
import * as Tether from 'tether';
import logging from '../../logging';

@Component({
  selector: 'app-field-textarea',
  templateUrl: './field-textarea.component.html',
  styleUrls: ['./field-textarea.component.scss'],
})
export class FieldTextareaComponent implements OnInit, OnChanges {
  /**
   * Name of the field.
   */
  @Input() name?: string;

  @Input() title: string = '';
  autocompleteWidth: Number = 0.0;
  tether: Tether | null = null;

  @Input() autocompleteType: string | undefined = undefined;

  @Input() value?: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() suffix?: string;

  @Input() disabled: boolean = false;

  @Input() rows: number = 5;

  @Input() errors: ModelError[] = [];

  @ViewChild('textarea') textAreaElementRef?: ElementRef;

  /**
   * Focus on input.
   */
  focus: boolean = false;

  ///////////////////////////////
  // Actions
  ///////////////////////////////

  @ViewChild('actionsEl') actionsEl: ElementRef | null = null;
  @ViewChild('actionsToggleEl') actionsToggleEl: ElementRef | null = null;

  @Input() actions: Action[] = [];
  @Output() actionSelected: EventEmitter<{
    field: string;
    action: Action;
  }> = new EventEmitter<{
    field: string;
    action: Action;
  }>();

  public shouldShowActionDropdown: boolean = false;

  private actionsDropdownPopper?: Popper;
  public actionsDropdownWidth: Number = 300.0;

  private readonly actionsDropdownPopperOptions: PopperOptions = {
    placement: 'bottom-end',
    modifiers: {
      flip: {
        behavior: ['left'],
      },

      offset: {
        offset: '-8',
      },
    },
  };

  /**
   * Theming.
   */
  @Input() theme: string = '';

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.textAreaElementRef?.nativeElement.scrollHeight) {
      setTimeout(() => this.resize());
    }
  }

  onChange(value: any) {
    logging.debug('field-textarea.component.ts', 'onChange');
    this.valueChange.emit(value);
  }

  ///////////////////////////////
  // Listeners
  ///////////////////////////////

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (
      !this.actionsEl!.nativeElement.contains(event.target) &&
      this.shouldShowActionDropdown
    ) {
      this.hideActions();
    }
  }

  resize() {
    if (this.textAreaElementRef) {
      this.textAreaElementRef!.nativeElement.style.height = '0';
      this.textAreaElementRef!.nativeElement.style.height =
        this.textAreaElementRef?.nativeElement.scrollHeight + 'px';
    }
  }

  ///////////////////////////////
  // Actions
  ///////////////////////////////

  openActions() {
    if (this.disabled) return;

    const reference = this.actionsDropdownPopper
      ? this.actionsDropdownPopper
      : this.actionsToggleEl!.nativeElement;
    this.actionsDropdownPopper = new Popper(
      reference,
      this.actionsEl!.nativeElement,
      this.actionsDropdownPopperOptions
    );

    this.autocompleteWidth = this.textAreaElementRef!.nativeElement.offsetWidth;

    this.shouldShowActionDropdown = true;
  }

  hideActions() {
    this.tether?.destroy();
    this.tether = null;
    this.shouldShowActionDropdown = false;
  }

  onActionClick(action: Action) {
    this.actionSelected.emit({
      field: this.name!,
      action: action,
    });
    this.hideActions();
  }
}
