<div
  class="containerr"
  [style.background]="
    blockData.background_color ? blockData.background_color : '#0A0A0A'
  "
  [style.--text-color]="blockData.text_color ? blockData.text_color : '#ffffff'"
>
  <div class="containerr-inner">
    <app-section-button
      class="title-button"
      (doubleClickEvent)="handleTitleDoubleClick()"
      [isSelected]="doubleClickIndex === -1"
      [pagebuilderMode]="pagebuilderMode"
    >
      <app-homepage-section-title
        [headline]="$any(blockData.headline)"
        [description]="$any(blockData.description)"
        [headlineEn]="$any(blockData.headlineEn)"
        [descriptionEn]="$any(blockData.descriptionEn)"
        [link]="$any(blockData.link)"
        [pagebuilderMode]="pagebuilderMode"
      ></app-homepage-section-title>
    </app-section-button>

    <div class="grid-container">
      <ng-container *ngIf="!isMobile">
        <div [ngSwitch]="sectionVariant" class="grid{{ sectionVariant }}" #grid>
          <ng-container *ngSwitchCase="1">
            <div class="grid-item1">
              <div class="grid1-left-col">
                <app-section-button
                  *ngFor="let i of [1, 2, 3, 4, 5]"
                  [pagebuilderMode]="pagebuilderMode"
                  (doubleClickEvent)="handleImageDoubleClick(i)"
                  [isSelected]="doubleClickIndex === i"
                >
                  <app-homepage-section-detailed-small
                    [mediaData]="blockData.products![i - 1]"
                    [pagebuilderMode]="pagebuilderMode"
                  ></app-homepage-section-detailed-small>
                </app-section-button>
              </div>
            </div>
            <div class="grid-item2">
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(6)"
                [isSelected]="doubleClickIndex === 6"
              >
                <app-homepage-section-big
                  [mediaData]="blockData.products![5]"
                  [pagebuilderMode]="pagebuilderMode"
                ></app-homepage-section-big>
              </app-section-button>
            </div>
            <div *ngFor="let i of [7, 8]" class="grid-item{{ i }}">
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(i)"
                [isSelected]="doubleClickIndex === i"
              >
                <ng-container [ngSwitch]="blockData.background_color">
                  <ng-container *ngSwitchCase="'#FFFFFF'">
                    <app-homepage-section-big
                      [mediaData]="blockData.products![i - 1]"
                      [pagebuilderMode]="pagebuilderMode"
                    ></app-homepage-section-big>
                  </ng-container>
                  <ng-container *ngSwitchCase="'#F4F5F7'">
                    <app-homepage-section-big
                      [mediaData]="blockData.products![i - 1]"
                      [pagebuilderMode]="pagebuilderMode"
                    ></app-homepage-section-big>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <app-homepage-section-detailed
                      [mediaData]="blockData.products![i - 1]"
                      [imgSize]="'large'"
                      [pagebuilderMode]="pagebuilderMode"
                    ></app-homepage-section-detailed>
                  </ng-container>
                </ng-container>
              </app-section-button>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="2">
            <div class="grid-item1">
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(1)"
                [isSelected]="doubleClickIndex === 1"
              >
                <app-homepage-section-big
                  [mediaData]="blockData.products![0]"
                  [pagebuilderMode]="pagebuilderMode"
                ></app-homepage-section-big>
              </app-section-button>
            </div>
            <div *ngFor="let i of [2, 3]" class="grid-item{{ i }}">
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(i)"
                [isSelected]="doubleClickIndex === i"
              >
                <app-homepage-section-big
                  [mediaData]="blockData.products![i - 1]"
                  [pagebuilderMode]="pagebuilderMode"
                ></app-homepage-section-big>
              </app-section-button>
            </div>
            <div class="grid-item4">
              <div class="grid4-right-col">
                <app-section-button
                  *ngFor="let i of [4, 5, 6, 7, 8, 9]"
                  [pagebuilderMode]="pagebuilderMode"
                  (doubleClickEvent)="handleImageDoubleClick(i)"
                  [isSelected]="doubleClickIndex === i"
                >
                  <app-homepage-section-detailed-small
                    [mediaData]="blockData.products![i - 1]"
                    [pagebuilderMode]="pagebuilderMode"
                  ></app-homepage-section-detailed-small>
                </app-section-button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="3">
            <div *ngFor="let i of [1]" class="grid-item{{ i }}">
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(i)"
                [isSelected]="doubleClickIndex === i"
              >
                <app-homepage-section-big
                  [mediaData]="blockData.products![i - 1]"
                  [pagebuilderMode]="pagebuilderMode"
                ></app-homepage-section-big>
              </app-section-button>
            </div>
            <div *ngFor="let i of [2, 3, 4, 5]" class="grid-item{{ i }}">
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(i)"
                [isSelected]="doubleClickIndex === i"
              >
                <app-homepage-section-detailed
                  [mediaData]="blockData.products![i - 1]"
                  [imgSize]="'small'"
                  [pagebuilderMode]="pagebuilderMode"
                ></app-homepage-section-detailed>
              </app-section-button>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="4">
            <div *ngFor="let i of [1, 2]" class="grid-item{{ i }}">
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(i)"
                [isSelected]="doubleClickIndex === i"
              >
                <app-homepage-section-big
                  [mediaData]="blockData.products![i - 1]"
                  [pagebuilderMode]="pagebuilderMode"
                ></app-homepage-section-big>
              </app-section-button>
            </div>
            <div *ngFor="let i of [3, 4, 5, 6]" class="grid-item{{ i }}">
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(i)"
                [isSelected]="doubleClickIndex === i"
              >
                <app-homepage-section-detailed
                  [mediaData]="blockData.products![i - 1]"
                  [imgSize]="'small'"
                  [pagebuilderMode]="pagebuilderMode"
                ></app-homepage-section-detailed>
              </app-section-button>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="5">
            <div
              *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              class="grid-item{{ i }}"
            >
              <app-section-button
                [pagebuilderMode]="pagebuilderMode"
                (doubleClickEvent)="handleImageDoubleClick(i)"
                [isSelected]="doubleClickIndex === i"
              >
                <app-homepage-section-gallery-simple
                  [mediaData]="blockData.products![i - 1]"
                  [pagebuilderMode]="pagebuilderMode"
                ></app-homepage-section-gallery-simple>
              </app-section-button>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="isMobile">
        <div>
          <app-fancy-grid
            [class.reorder-mobile-s1]="sectionVariant == 1"
            [class.reorder-mobile-s4]="sectionVariant == 4"
            [imageData]="blockData.products"
            [imagesPerRow]="1"
            [variant]="3"
            [showDataOnlyOnHover]="true"
          ></app-fancy-grid>
        </div>
      </ng-container>
    </div>
  </div>
</div>
