import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IFilterData } from './search';
import {
  CategoriesService as ApiCategoriesService,
  HomepageService as ApiHomepageService,
  TagsService as ApiTagsService,
} from 'piwe-front-swagger-client';
import {
  Category,
  SubportalFilterResponse,
} from '../../../../projects/piwe-front-swagger-client/src';
import { SubPortal } from './search.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public activeFilters$ = new Subject<{ field: string; property: any }[]>();
  public timeFilters$ = new Subject();
  public subPortalFilterResponse$: Subject<SubportalFilterResponse> =
    new Subject();

  constructor(
    private http: HttpClient,
    private homepageService: ApiHomepageService,
    private categoriesService: ApiCategoriesService,
    private apiTagsService: ApiTagsService
  ) {}

  setFilters(formData: IFilterData) {
    const activeFilters: Array<{ field: string; property: any }> = [];
    const timeFilters: Array<any> = [];

    activeFilters.push(...formDataToActiveFilters(formData));
    timeFilters.push(...formDataToTimeFilter(formData));

    this.activeFilters$.next(activeFilters);
    this.timeFilters$.next(timeFilters);

    function formDataToActiveFilters(
      formData: IFilterData
    ): Array<{ field: string; property: any }> {
      const arr: { field: string; property: any }[] = [];
      arr.push(...reduceToTags('tags', formData.tags));
      arr.push(...reduceToTags('category', formData.category));
      arr.push(...reduceToTags('agencies', formData.agencies));
      arr.push(...reduceToTagsRadio('colors', formData.colors));
      arr.push(...reduceToTagsRadio('formats', formData.formats));
      arr.push(...reduceToTagsRadio('contentType', formData.contentType));
      arr.push(
        ...reduceToTagsRadio('photoJournalist', formData.photoJournalist)
      );
      arr.push(
        ...reduceToTags('refinementFilters', formData.refinementFilters)
      );
      return arr;
    }

    function formDataToTimeFilter(formData: IFilterData) {
      const arr: { field: string; property: any }[] = [];
      arr.push(...reduceToTagsRadio('timeInterval', formData.timeInterval));
      return arr;
    }

    function reduceToTags(
      field: string,
      data: any
    ): Array<{ field: string; property: any }> {
      const reduced: any = [];
      for (const property in data) {
        if (data[property] !== false && data[property] !== '')
          reduced.push({ field, property: `${field}.${property}` });
      }
      return reduced;
    }

    //same as the function above, but returns the value instead of the property name, used for the data from radio inputs
    //also handles start and end date
    function reduceToTagsRadio(
      field: string,
      data: any
    ): Array<{ field: string; property: any }> {
      const reduced: Array<{ field: string; property: any }> = [];
      for (const property in data) {
        if (property === 'startDate' && data[property] !== '') {
          reduced.push({ field, property: `${field}.${data[property]}` });
        } else if (property === 'endDate' && data[property] !== '') {
          reduced.push({ field, property: `${field}.${data[property]}` });
        } else if (data[property] !== false && data[property] !== '')
          reduced.push({ field, property: `${field}.${data[property]}` });
      }
      return reduced;
    }
  }

  getAgencies() {
    const angenciesUrl = 'https://api.piwe.dev.smartcode.eu/mock/agencies';
    return this.http.get(angenciesUrl);
  }

  private categoriesSource = new Subject<Array<Category>>();
  public categoriesData: Observable<Array<Category>> =
    this.categoriesSource.asObservable();

  loadSubPortal(subportal: SubPortal) {
    switch (subportal) {
      case SubPortal.Agephotostock:
        this.categoriesService.frontGetCategory().subscribe((c) => {
          this.categoriesSource.next(c.category_list);
        });

        this.homepageService
          .frontGetHomepageAgefotostockFilter()
          .subscribe((f) => {
            this.subPortalFilterResponse$.next(f);
          });
        break;

      default:
        this.categoriesService.frontGetCategory().subscribe((c) => {
          this.categoriesSource.next(c.category_list);
        });

        switch (subportal) {
          case SubPortal.Production:
            this.homepageService
              .frontGetHomepageProductionFilter()
              .subscribe((f) => {
                this.subPortalFilterResponse$.next(f);
              });
            break;
          case SubPortal.Agencies:
            this.homepageService
              .frontGetHomepageAgenciesFilter()
              .subscribe((f) => {
                this.subPortalFilterResponse$.next(f);
              });
            break;
          case SubPortal.Stock:
            this.homepageService
              .frontGetHomepageStockFilter()
              .subscribe((f) => {
                this.subPortalFilterResponse$.next(f);
              });
            break;
          case SubPortal.Video:
            this.homepageService
              .frontGetHomepageVideoFilter()
              .subscribe((f) => {
                this.subPortalFilterResponse$.next(f);
              });
            break;
          case SubPortal.All:
            this.homepageService.frontGetHomepageAllFilter().subscribe((f) => {
              this.subPortalFilterResponse$.next(f);
            });
            break;
        }
    }
  }

  loadCategory(subportal: SubPortal) {
    switch (subportal) {
      case SubPortal.Agephotostock:
        return this.categoriesService.frontGetCategory().pipe(
          map((c) => {
            return c.category_list;
          })
        );

      default:
        return this.categoriesService.frontGetCategory().pipe(
          map((c) => {
            return c.category_list;
          })
        );
    }
  }

  loadFilter(subportal: SubPortal) {
    switch (subportal) {
      case SubPortal.Agephotostock:
        return this.homepageService.frontGetHomepageAgefotostockFilter().pipe(
          map((c) => {
            return c;
          })
        );

      default:
        switch (subportal) {
          case SubPortal.Agencies:
            return this.homepageService.frontGetHomepageAgenciesFilter().pipe(
              map((c) => {
                return c;
              })
            );
            break;
          case SubPortal.Stock:
            return this.homepageService.frontGetHomepageStockFilter().pipe(
              map((c) => {
                return c;
              })
            );
            break;
          case SubPortal.Video:
            return this.homepageService.frontGetHomepageVideoFilter().pipe(
              map((c) => {
                return c;
              })
            );
            break;
          case SubPortal.All:
            return this.homepageService.frontGetHomepageAllFilter().pipe(
              map((c) => {
                return c;
              })
            );
            break;
          default:
            return this.homepageService.frontGetHomepageProductionFilter().pipe(
              map((c) => {
                return c;
              })
            );
            break;
        }
    }
  }
}
