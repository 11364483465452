import { Component, OnInit } from '@angular/core';
import { IptcServiceService } from './iptc-service.service';
import { DynamicViewService } from './dynamic-view.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
})
export class CmsComponent implements OnInit {
  iptcValue?: string;

  constructor(
    private iptcServiceService: IptcServiceService,
    private dynamicViews: DynamicViewService
  ) {
    this.iptcServiceService.htmlResultsData.subscribe((value) => {
      this.iptcValue = value;
    });

    this.dynamicViews.loadDynamicViews();
  }

  ngOnInit(): void {}

  closeIptc() {
    this.iptcValue = undefined;
  }
}
