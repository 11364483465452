<div class="sidebar">
  <div class="header-buttons">
    <button
      class="btn btn--secondary"
      [class.disabled]="!selectedItems.length"
      [disabled]="!selectedItems.length"
      (click)="addItems()"
    >
      Add
    </button>

    <button
      class="btn btn--primary"
      [class.disabled]="!selectedItems.length"
      [disabled]="!selectedItems.length"
      (click)="addAndFinish()"
      *ngIf="!itemList.size"
    >
      Add and finish
    </button>

    <button
      class="btn btn--primary"
      [class.disabled]="!itemList.size"
      [disabled]="!itemList.size"
      (click)="finish()"
      *ngIf="itemList.size"
    >
      Finish
    </button>
  </div>
  <div class="sidebar__content">
    <ng-container *ngIf="!itemList.size">
      <ng-container *ngTemplateOutlet="empty"></ng-container>
    </ng-container>

    <ng-container *ngIf="itemList.size">
      <ng-container *ngTemplateOutlet="itemListTemplate"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #empty>
  <div class="empty">
    <img src="/assets/folder-icon.svg" alt="" />
    <div class="empty-title">No galleries added yet</div>
    <div class="empty-text">
      A list of galleries you have added to the gallery group will be displayed
      here
    </div>
  </div>
</ng-template>

<ng-template #itemListTemplate>
  <div class="list-header">Added galleries</div>
  <ul class="list">
    <li *ngFor="let item of itemList">
      <img src="{{ item.thumbnail_url }}" alt="" />
      <div>
        {{ item.title }}
      </div>
      <button class="remove-btn" (click)="removeItem(item)"></button>
    </li>
  </ul>
</ng-template>
