import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  CMSGalleriesService as ApiGalleriesService,
  GalleryGroupsService as ApiGalleryGroupsService,
  IdsList,
  MediaItem,
  ProductsService as ApiProductsService,
  Translatable,
  UploadSessionPublishRequest,
  UploadSessionsService,
} from 'piwe-front-swagger-client';
import { AutocompleteOption } from '../../../shared/field-text/field-text.component';
import {
  Agency,
  Author,
  Category,
  ErrorResponse,
  Gallery,
  GalleryList,
  Keyword,
  LicenceType,
  Person,
  SpecialOfferType,
  Tag,
} from '../../../../../projects/piwe-front-swagger-client/src';
import { GalleryImportItem } from './galleries.component';
import {
  Field,
  FIELD_AGENCY,
  FIELD_API_FTP,
  FIELD_ARTICLE_AUTHOR,
  FIELD_ARTICLE_CONTENT,
  FIELD_ARTICLE_TITLE,
  FIELD_AUTHOR,
  FIELD_CAPTION,
  FIELD_CAPTION_EN,
  FIELD_CATEGORIES,
  FIELD_DATE_CREATED,
  FIELD_DATE_PUBLISHED_V2,
  FIELD_FEATURED,
  FIELD_DIGITAL_EXHIBITION,
  FIELD_HEADLINE,
  FIELD_HEADLINE_EN,
  FIELD_ID,
  FIELD_IS_FREE,
  FIELD_KEYWORDS,
  FIELD_KEYWORDS_EN,
  FIELD_LICENCE,
  FIELD_NEEDS_TRANSLATION_EN,
  FIELD_PEOPLE,
  FIELD_PRINT_PRICE,
  FIELD_SPECIAL_OFFER,
  FIELD_SPECIAL_OFFER_TYPE,
  FIELD_TAG,
  FIELD_WEB_PRICE,
  FieldType,
  MultipleItemEditorService,
  FIELD_PRINT_PRICE_EUR,
  FIELD_WEB_PRICE_EUR,
} from '../../../shared/multiple-item-editor/multiple-item-editor.service';
import {
  FieldChangedEvent,
  IMultipleItemEditorService,
} from '../../../shared/multiple-item-editor/multiple-item-editor.component';
import { ProductsService } from '../products/products.service';
import { GalleryGroupImportItem } from '../gallery-group/gallery-group.component';
import { ImportItem } from '../../import/import.component';
import { formatDate } from '@angular/common';
import { GalleryGroupService } from '../gallery-group/gallery-group.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SnackBarService } from '../../../share/snack-bar.service';
import { LoadingService } from '../../../shared/loading.service';
import logging from '../../../logging';

export const GALLERIES_FIELDS: Field[] = [
  FIELD_ID,
  FIELD_HEADLINE,
  FIELD_HEADLINE_EN,
  FIELD_CAPTION,
  FIELD_PEOPLE,
  FIELD_KEYWORDS,
  FIELD_CATEGORIES,
  FIELD_AUTHOR,
  FIELD_AGENCY,
  FIELD_TAG,
  FIELD_SPECIAL_OFFER,
  FIELD_SPECIAL_OFFER_TYPE,
  FIELD_API_FTP,
  FIELD_LICENCE,
  FIELD_DATE_CREATED,
  FIELD_NEEDS_TRANSLATION_EN,
  FIELD_CAPTION_EN,
  FIELD_KEYWORDS_EN,
  FIELD_PRINT_PRICE,
  FIELD_WEB_PRICE,
  FIELD_PRINT_PRICE_EUR,
  FIELD_WEB_PRICE_EUR,
  FIELD_ARTICLE_TITLE,
  FIELD_ARTICLE_AUTHOR,
  FIELD_ARTICLE_CONTENT,
  FIELD_DATE_PUBLISHED_V2,
  FIELD_FEATURED,
  FIELD_DIGITAL_EXHIBITION,
  FIELD_IS_FREE,
];

type SessionAttributes = {
  page?: number;
  fromDate?: string;
  toDate?: string;
  originalName?: string;
  authors?: AutocompleteOption[];
  importDate?: string;
  downloaded?: AutocompleteOption;
  agencies?: AutocompleteOption[];
  galleryId?: string;
  contentType?: AutocompleteOption;
  productStatus?: AutocompleteOption;
  productId?: string;
  operators?: AutocompleteOption[];
  tags?: AutocompleteOption[];
  categories?: AutocompleteOption[];
  bigSearchPerson?: boolean;
  bigSearchDescription?: boolean;
  bigSearchKeywords?: boolean;
  bigSearch?: string;
};

@Injectable({
  providedIn: 'root',
})
export class GalleriesService
  extends MultipleItemEditorService<GalleryImportItem>
  implements IMultipleItemEditorService
{
  private files: any[] = [];

  public unsavedItemsIds: number[] = [];

  private numberOfResultsSource = new Subject<number>();
  public numberOfResultsData: Observable<number> =
    this.numberOfResultsSource.asObservable();

  public isUnsavedSource = new Subject<boolean>();
  public isUnsavedData: Observable<boolean> =
    this.isUnsavedSource.asObservable();

  private isEditingDisabledSource = new Subject<boolean>();
  public isEditingDisabledData: Observable<boolean> =
    this.isEditingDisabledSource.asObservable();

  private requestFileReplaceSource = new Subject<number>();
  public requestFileReplaceData: Observable<number> =
    this.requestFileReplaceSource.asObservable();

  private uploadingFilesSource = new Subject<any[]>();
  public uploadingFilesData: Observable<any[]> =
    this.uploadingFilesSource.asObservable();

  private itemsSource = new Subject<GalleryImportItem[]>();
  public itemsData: Observable<GalleryImportItem[]> =
    this.itemsSource.asObservable();

  private selectedIdsSource = new Subject<number[]>();
  public selectedIdsData: Observable<number[]> =
    this.selectedIdsSource.asObservable();

  private loadSessionSignalSource = new Subject<SessionAttributes>();
  public loadSessionSignalData: Observable<SessionAttributes> =
    this.loadSessionSignalSource.asObservable();

  private afterSaveSource = new Subject<(shouldRefresh: boolean) => void>();
  public afterSaveData: Observable<(shouldRefresh: boolean) => void> =
    this.afterSaveSource.asObservable();

  public editingGalleryGroup?: GalleryGroupImportItem;

  private editingGalleryGroupSource = new Subject<GalleryGroupImportItem>();
  public editingGalleryGroupData: Observable<GalleryGroupImportItem> =
    this.editingGalleryGroupSource.asObservable();

  private setAsGalleryHeadEventSource = new Subject<ImportItem>();
  public setAsGalleryHeadEventData: Observable<ImportItem> =
    this.setAsGalleryHeadEventSource.asObservable();

  private schedulePublishDialogSource = new Subject<void>();
  public schedulePublishDialogData: Observable<void> =
    this.schedulePublishDialogSource.asObservable();

  private schedulePublishDialogDoneSource = new Subject<string>();
  public schedulePublishDialogDoneData: Observable<string> =
    this.schedulePublishDialogDoneSource.asObservable();

  public unserializedGalleryList!: Gallery[];

  loadedGalleryProducts: boolean = false;

  constructor(
    private apiGalleriesService: ApiGalleriesService,
    private apiProductsService: ApiProductsService,
    private apiGalleryGroupService: ApiGalleryGroupsService,
    private uploadService: UploadSessionsService,
    private productService: ProductsService,
    private router: Router,
    private snackBarService: SnackBarService,
    private loadingService: LoadingService
  ) {
    super();

    this.items = [];
    this.itemsById = {};
    this.unsavedItemsIds = [];

    this.setUpSaveSession();

    this.loadSessionSignalData
      .pipe(
        switchMap((options: any) => {
          this.isLoadedSource.next(false);

          return this.apiGalleriesService.cmsGetGalleries(
            options['page'],
            48,
            options['largeSearchValue'],
            true,
            options['bigSearchPerson'] ?? true,
            options['bigSearchDescription'] ?? true,
            options['bigSearchKeywords'] ?? true,
            this.formatOptionalDate(options['fromDateValue']),
            this.formatOptionalDate(options['toDateValue']),
            this.resolveAutocompleteValue(
              'productStatus',
              options['productStatusValue']
            ),
            options['has_articles'],
            this.getFirstFromArray(
              options['agenciesValue']?.map((a: AutocompleteOption) => a.value)
            )
          );
        })
      )
      .subscribe((result) => {
        this.isLoadedSource.next(true);

        if (result.total_results) {
          this.numberOfResultsSource.next(result.total_results);
        }

        this.unserializedGalleryList = result.gallery_list;
        let newItems = this.serverListSchemaToClientListSchema(result);

        this.itemsById = [];
        newItems.forEach((item) => {
          this.itemsById[item.id] = item;
        });

        this.items = newItems;

        this.itemsSource.next(this.items);
      });
  }

  public getIsLoaded(): Observable<boolean> {
    return super.getIsLoaded();
  }

  finishSchedulePublishDialog(value: string) {
    this.schedulePublishDialogDoneSource.next(value);
  }

  openSchedulePublishDialog() {
    this.schedulePublishDialogSource.next();
  }

  setAsGalleryHead(importItem: ImportItem) {
    this.setAsGalleryHeadEventSource.next(importItem);
  }

  setEditingGalleryGroup(
    gallery: GalleryGroupImportItem | undefined,
    shouldLoadFromRemote: boolean = false
  ) {
    this.editingGalleryGroup = gallery;

    if (this.editingGalleryGroup != undefined) {
      if (shouldLoadFromRemote) {
        this.apiGalleryGroupService
          .cmsGetGalleryGroup(gallery!.id!.toString())
          .subscribe((full) => {
            const deserialized = GalleryGroupService.deserializeProduct(full);

            gallery!.items = deserialized.items;
            gallery!.previewUrl =
              GalleryGroupService.computePreviewGalleryImportItem(
                deserialized!
              );
            this.editingGalleryGroupSource.next(deserialized);
          });
      } else {
        this.editingGalleryGroupSource.next(gallery);
      }
    } else {
      this.editingGalleryGroupSource.next(undefined);
    }
  }

  requestFileReplace(id: number) {
    this.requestFileReplaceSource.next(id);
  }

  private resolveAutocompleteValue(
    type: string,
    value: AutocompleteOption | undefined
  ): any | undefined {
    if (value == undefined) {
      return undefined;
    }

    switch (type) {
      case 'downloaded':
        switch (value.value) {
          case 1:
            return true;
          default:
            return false;
        }

      case 'productStatus':
        switch (value.value) {
          case 1:
            return 'PUBLISHED';
          case 2:
            return 'NOT_PUBLISHED';
          case 3:
            return 'DELETED';
          default:
            return '';
        }

      case 'contentType':
        switch (value.value) {
          case 1:
            return 'PHOTO';
          default:
            return 'VIDEO';
        }
    }
  }

  fieldSources: { [key: string]: Subject<any> } = {};

  getEditingGalleryGroup(): GalleryGroupImportItem | undefined {
    return this.editingGalleryGroup;
  }

  formatOptionalDate(value: any | undefined) {
    if (value == undefined) return undefined;

    return formatDate(value, 'yyyy-MM-dd', 'en-US');
  }

  loadSession(page: number, options: any) {
    logging.debug('galleries', 'loadSession', page, options);

    this.items = [];
    this.itemsById = {};
    this.unsavedItemsIds = [];

    this.isUnsavedSource.next(false);

    this.itemsSource.next(this.items);

    this.selectedIds = [];
    this.selectedIdsSource.next([]);
    this.loadDataForSelectedItem();

    if (!(options.bigSearch?.length ?? 0)) {
      options.bigSearch = undefined;
    }

    this.loadSessionSignalSource.next({
      ...options,
      page,
    });
  }

  selectedOrdering: number[] = [];

  getFirstSelectedItem(): number | undefined {
    if (this.selectedOrdering.length != 0) return this.selectedOrdering[0];

    return undefined;
  }

  selectItems(itemIds: number[]) {
    this.selectedOrdering = this.selectedOrdering.filter((id) => {
      return itemIds.includes(id);
    });

    for (const itemId of itemIds) {
      if (this.selectedOrdering.includes(itemId)) {
        // Selected once again and move it to front.
        this.selectedOrdering = this.selectedOrdering.filter((id) => {
          return id != itemId;
        });
        this.selectedOrdering.push(itemId);
      } else {
        this.selectedOrdering.push(itemId);
      }
    }

    this.selectedIds = itemIds;
    this.selectedIdsSource.next(itemIds);
    this.loadDataForSelectedItem();
  }

  loadDataForSelectedItem() {
    let isAnySelectedLocked = this.selectedIds
      .map((id) => this.getItemById(id).isLocked)
      .reduce((sum, next) => sum || next, false);
    this.isEditingDisabledSource.next(isAnySelectedLocked);

    for (const field of GALLERIES_FIELDS) {
      var value: any;

      switch (field.fieldType) {
        case FieldType.String:
          value = this.computeFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            let key = field.name;
            // @ts-ignore
            let value = i[key] as any;

            if (key == 'datePublished') {
              key = 'publishDate';

              // @ts-ignore
              value = i[key] as any;

              if (value !== null && value !== undefined) {
                value = moment(value).format('YYYY-MM-DD');
              } else {
                value = '';
              }
            }

            return value;
          });
          break;
        case FieldType.Number:
          value = this.computeNumberFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as number;
          });
          break;
        case FieldType.Boolean:
          value = this.computeBooleanFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as boolean;
          });
          break;
        case FieldType.Autocomplete:
          value = this.computeAutocompleteFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as AutocompleteOption;
          });
          break;
        case FieldType.MultipleAutocomplete:
          value = this.computeMultipleAutocompleteFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as AutocompleteOption[];
          });
          break;
        case FieldType.Date:
          value = this.computeDateFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as string;
          });
          break;
      }

      this.fieldChangeSource.next({
        field: field.name,
        value: value,
      });
    }

    this.calculateSuffix();
  }

  /////////////////////////////
  // Fields
  /////////////////////////////

  setField(field: string, value: any) {
    let setter: (item: GalleryImportItem) => void;

    if (this.selectedIds.length) {
      switch (field) {
        case 'headline':
          setter = (item: GalleryImportItem) => (item.headline = value);

          break;

        case 'caption':
          setter = (item: GalleryImportItem) => (item.caption = value);
          break;

        case 'people':
          setter = (item: GalleryImportItem) => (item.people = value);
          break;

        case 'keywords':
          setter = (item: GalleryImportItem) => (item.keywords = value);
          break;

        case 'categories':
          setter = (item: GalleryImportItem) => (item.categories = value);
          break;

        case 'author':
          setter = (item: GalleryImportItem) => (item.author = value);
          break;

        case 'agency':
          setter = (item: GalleryImportItem) => (item.agency = value);
          break;

        case 'tag':
          setter = (item: GalleryImportItem) => (item.tag = value);
          break;

        case 'specialOffer':
          setter = (item: GalleryImportItem) => (item.specialOffer = value);
          break;

        case 'specialOfferTypes':
          setter = (item: GalleryImportItem) =>
            (item.specialOfferTypes = value);
          break;

        case 'apiFtp':
          setter = (item: GalleryImportItem) => (item.apiFtp = value);
          break;

        case 'licence':
          setter = (item: GalleryImportItem) => (item.licence = value);
          break;

        case 'dateCreated':
          setter = (item: GalleryImportItem) => (item.dateCreated = value);
          break;

        case 'needsTranslationEn':
          setter = (item: GalleryImportItem) =>
            (item.needsTranslationEn = value);
          break;

        case 'captionEn':
          setter = (item: GalleryImportItem) => (item.captionEn = value);
          break;

        case 'keywordsEn':
          setter = (item: GalleryImportItem) => (item.keywordsEn = value);
          break;

        case 'webPrice':
          setter = (item: GalleryImportItem) => (item.webPrice = value);
          break;

        case 'printPrice':
          setter = (item: GalleryImportItem) => (item.printPrice = value);
          break;

        case 'webPriceEur':
          setter = (item: GalleryImportItem) => (item.webPriceEur = value);
          break;

        case 'printPriceEur':
          setter = (item: GalleryImportItem) => (item.printPriceEur = value);
          break;

        case 'articleTitle':
          setter = (item: GalleryImportItem) => (item.articleTitle = value);
          break;

        case 'articleAuthor':
          setter = (item: GalleryImportItem) => (item.articleAuthor = value);
          break;

        case 'articleContent':
          setter = (item: GalleryImportItem) => (item.articleContent = value);
          break;

        case 'publishDate':
          setter = (item: GalleryImportItem) => (item.publishDate = value);
          break;

        case 'featured':
          setter = (item: GalleryImportItem) => (item.featured = value);
          break;

        case 'digitalExhibition':
          setter = (item: GalleryImportItem) =>
            (item.digitalExhibition = value);
          break;

        case 'isFree':
          setter = (item: GalleryImportItem) => (item.isFree = value);
          break;

        default:
        //console.error(`No setter for '${field}'`);
      }
    }

    let selectedItems = this.selectedIds.map((id) => this.itemsById[id]);
    selectedItems.forEach((item) => setter(item));
    selectedItems.forEach((item) => {
      if (!this.unsavedItemsIds.includes(item.id)) {
        this.lockGalleryImportItems([item]);
        this.unsavedItemsIds.push(item.id);
      }
    });

    this.isUnsavedSource.next(true);

    logging.debug(
      'galleries.service.ts',
      `Marking as unsaved, changed field '${field}' to value '${value}'`,
      field,
      value
    );
    logging.debug(
      'galleries.service.ts',
      `Dirty items are: '${this.unsavedItemsIds.join(', ')}'`,
      this.unsavedItemsIds,
      this.items
    );

    this.calculateSuffix();
  }

  markAsDirty(galleries: GalleryImportItem[]) {
    for (const product of galleries) {
      if (!this.unsavedItemsIds.includes(product.id)) {
        this.unsavedItemsIds.push(product.id);
      }
    }
    this.isUnsavedSource.next(true);
  }

  markAllNotDirty() {
    this.unsavedItemsIds = [];
    this.isUnsavedSource.next(false);
  }

  /////////////////////////////
  // Saving
  /////////////////////////////

  checkedIsLocked(mediaItem: MediaItem) {
    if (mediaItem.editing_lock != undefined) {
      let raw: string =
        <string>(<unknown>mediaItem.editing_lock.locked_at!) + 'Z';
      let lockedAt = new Date(raw);
      let newDate = new Date();

      if (lockedAt != undefined) {
        let timeDiff = newDate.getTime() - lockedAt!.getTime();

        // More than 5 minutes.
        return timeDiff < 15 * 1000;
      }

      return;
    }

    return false;
  }

  lockedByName(mediaItem: MediaItem): string | undefined {
    if (this.checkedIsLocked(mediaItem)) {
      return (
        mediaItem.editing_lock!.locked_by!.first_name +
        ' ' +
        mediaItem.editing_lock!.locked_by!.last_name
      );
    }

    return undefined;
  }

  static computePreviewGalleryImportItem(
    gallery: GalleryImportItem
  ): string | undefined {
    if (gallery.items?.length ?? 0 > 1) {
      return gallery.items![0].previewUrl;
    }

    return undefined;
  }

  addItemToGallery(galleryId: number, item: ImportItem) {
    let galleryImportItem: GalleryImportItem = this.getItemById(galleryId);

    // Change headline to new gallery when adding it to the gallery.
    item.headline = galleryImportItem.headline;
    this.productService.getItemById(item.id).headline =
      galleryImportItem.headline;
    this.getItemById(galleryId).items?.push(item);

    logging.debug(
      'galleries.service.ts',
      'Adding item to gallery',
      galleryId,
      item,
      this.getItemById(galleryId).items
    );

    this.markAsDirty([this.getItemById(galleryId)]);
  }

  createEmptyGallery() {
    const now = new Date();
    const utcMilllisecondsSinceEpoch =
      now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    let id = -utcMilllisecondsSinceEpoch;

    let gallery: GalleryImportItem = {
      agency: undefined,
      apiFtp: false,
      author: undefined,
      caption: '',
      captionEn: '',
      categories: undefined,
      dateCreated: '',
      dateImported: '',
      headline: undefined,
      height: 0,
      id: id,
      imageCount: 0,
      videoDuration: '',
      isLoaded: false,
      items: [],
      keywords: [],
      keywordsEn: [],
      licence: '',
      needsTranslationEn: false,
      people: [],
      previewUrl: '',
      printPrice: 0,
      featured: false,
      digitalExhibition: false,
      isFree: false,
      specialOffer: false,
      specialOfferTypes: undefined,
      tag: undefined,
      title: '',
      webPrice: 0,
      width: 0,
      publishState: 'AUTO_PUBLISH_AFTER_DATE',
      publishDate: undefined,
    };

    this.unsavedItemsIds.push(id);
    this.itemsById[id] = gallery;

    return gallery;
  }

  public static parseDate(date: string | undefined): string | undefined {
    if (date == undefined || date == null) {
      return undefined;
    }

    return date.split('T')[0];
  }

  static getHeadline(uploadSessionItem: Gallery): string {
    return (
      uploadSessionItem.headline?.find((t: Translatable) => t.language == 'HR')
        ?.content ?? ''
    );
  }

  static computePreview(uploadSessionItem: Gallery): string | undefined {
    if (uploadSessionItem.product_list?.length ?? 0 > 1) {
      return uploadSessionItem.product_list![0].preview?.preview_url;
    }

    return undefined;
  }

  private lockGalleryImportItems(importItems: GalleryImportItem[]) {
    let ids = importItems.map((item) => item.id);
    this.apiProductsService.cmsLockProducts({ ids: ids }).subscribe((_) => {
      //alert("Done");
    });
  }

  private errorResponseSource = new Subject<ErrorResponse | undefined>();
  public errorResponseData: Observable<ErrorResponse | undefined> =
    this.errorResponseSource.asObservable();

  private errorIdsSource = new Subject<number[]>();
  public errorIdsData: Observable<number[]> =
    this.errorIdsSource.asObservable();

  private saveQueueSource = new Subject<() => void | undefined>();
  public saveQueueData: Observable<() => void | undefined> =
    this.saveQueueSource.asObservable();

  private savingStatusSource = new BehaviorSubject<boolean>(false);
  public saveStatusData: Observable<boolean> =
    this.savingStatusSource.asObservable();

  public publishSession(
    kind: UploadSessionPublishRequest.KindEnum,
    isNewsletterPush: boolean,
    isNotificationPush: boolean
  ) {}

  public queueSaveSession(callback: (() => void) | undefined) {
    this.saveQueueSource.next(callback);
  }

  private setUpSaveSession() {
    this.saveQueueData.subscribe((callback) => {
      this.saveSession(callback);
    });
  }

  private saveSession(callback: (() => void) | undefined) {
    const isEditingGallery = this.productService.editingGallery != undefined;

    this.savingStatusSource.next(false);
    let productList = this.clientListSchemaToServerListSchema(isEditingGallery);
    this.apiGalleriesService.cmsPostGallery(productList).subscribe(
      (result) => {
        var completeFunction = (shouldRunDefaultAfterSave: boolean) => {
          if (shouldRunDefaultAfterSave && callback != undefined) {
            callback();
          }
        };

        if (this.afterSaveSource.observers.length != 0) {
          this.afterSaveSource.next(completeFunction);
        } else {
          completeFunction(true);
        }
      },
      (error) => {
        let errorResponse: ErrorResponse = error.error;
        this.errorResponseSource.next(errorResponse);
        this.errorIdsSource.next(
          errorResponse.errors.map((e) => parseInt(e.item_id ?? '-1'))
        );
        alert('Error saving. Please check all the marked items.');
      }
    );
  }

  /////////////////////////////
  // Uploading files
  /////////////////////////////

  public uploadFile(files: any, itemId: number) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      // TODO: Implement
      this.apiProductsService
        .cmsReplaceProductMedia(itemId, item, 'events', true)
        .subscribe((result: any) => {
          if (result.type == 1) {
            item.progress = (result.loaded / result.total) * 100.0;

            // It's finished.
            if (item.progress >= 100.0) {
              this.files = this.files.filter((file) => file !== item);
              this.uploadingFilesSource.next(this.files);

              window.location.reload();
            }
          }
        });
    }

    this.uploadingFilesSource.next(this.files);
  }

  /////////////////////////////
  // Reordering
  /////////////////////////////

  private move(array: any[], oldIndex: number, newIndex: number) {
    let temp = array[newIndex];
    array[newIndex] = array[oldIndex];
    array[oldIndex] = temp;
  }

  public moveItemFromIndexToIndex(oldIndex: number, newIndex: number) {
    this.move(this.items, oldIndex, newIndex);
    this.itemsSource.next(this.items);
  }

  /////////////////////////////
  // Deleting
  /////////////////////////////

  computePreviewGalleryGroupImportItem(
    gallery: GalleryGroupImportItem
  ): string[] | undefined {
    return gallery.items?.map((i) => i.previewUrl ?? '');
  }

  public deleteSelected() {
    if (this.editingGalleryGroup != undefined) {
      this.editingGalleryGroup!.items = this.editingGalleryGroup!.items?.filter(
        (i) => !this.selectedIds.includes(i.id)
      );
      this.editingGalleryGroup!.previewUrl =
        this.computePreviewGalleryGroupImportItem(this.editingGalleryGroup!);

      // https://app.clickup.com/t/24447792/SD-1534?comment=704332061
      GalleryGroupService.markAsDirtySource.next([this.editingGalleryGroup!]);

      this.editingGalleryGroupSource.next(this.editingGalleryGroup);

      return;
    }

    if (
      prompt(
        `Type 'CONFIRM' to confirm you want to delete gallery/ies: ${this.selectedIds.join(
          ', '
        )}`
      ) != 'CONFIRM'
    ) {
      return;
    }

    let request: IdsList = {
      ids: this.selectedIds,
    };

    let toDeleteIds = this.selectedIds;

    this.loadingService.setLoading(true);
    this.apiGalleriesService.cmsDeleteGalleries(request).subscribe(
      (result: any) => {
        this.items = this.items.filter((i) => !toDeleteIds.includes(i.id));
        toDeleteIds.forEach((id: number) => {
          delete this.itemsById[id];
        });

        this.selectedIds = this.selectedIds.filter(
          (id) => !toDeleteIds.includes(id)
        );
        this.selectedIdsSource.next(this.selectedIds);

        this.itemsSource.next(this.items);
        this.snackBarService.showSnackBar(
          'Successfully deleted gallery/ies.',
          'INFO'
        );
        this.loadingService.setLoading(false);

        this.unselectAll();
      },
      (error) => {
        this.snackBarService.showSnackBar(
          'Failed deleting gallery/ies.',
          'ERROR'
        );
        this.loadingService.setLoading(false);

        this.unselectAll();
      }
    );
  }

  /////////////////////////////
  // Actions
  /////////////////////////////
  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceInString(string: string, needle: string, withValue: string): string {
    const escapedRegexNeedle = this.escapeRegExp(needle);
    return string.replace(new RegExp(escapedRegexNeedle, 'gi'), withValue);
  }

  replace(
    field: string,
    what: AutocompleteOption,
    withWhat: AutocompleteOption
  ) {
    this.items.forEach((item) => {
      if (!this.selectedIds.includes(item.id)) return;

      var dirtyItem = false;

      switch (field) {
        case 'headline':
          if (item?.headline?.title.includes(what.title)) {
            dirtyItem = true;
            if (item.headline != undefined) {
              item.headline = {
                title: this.replaceInString(
                  item.headline!.title,
                  what.title,
                  withWhat.title
                ),
                isSelectable: item.headline!.isSelectable,
                value: item.headline!.value,
              };
              item.title = item.headline!.title;
            }
          }

          break;
      }

      if (dirtyItem) {
        this.markAsDirty([item]);
      }
    });
    this.loadDataForSelectedItem();
  }

  public mergeSelected() {
    let firstSelectedItem = this.getFirstSelectedItem();
    if (!firstSelectedItem) return;

    const title = this.getItemById(firstSelectedItem).title;

    if (
      prompt(
        `Type 'CONFIRM' to confirm you want to merge gallery/ies: ${this.selectedIds.join(
          ', '
        )} into gallery: '${title}'`
      ) != 'CONFIRM'
    ) {
      return;
    }

    let request: IdsList = {
      ids: this.selectedIds,
    };

    let toMergeIds = this.selectedIds;
    let noFirstids = toMergeIds.filter((id) => id != firstSelectedItem);
    let totalList = [firstSelectedItem, ...noFirstids];

    this.loadingService.setLoading(true);
    this.apiGalleriesService.cmsCombineGalleries(request).subscribe(
      (result: any) => {
        this.items = this.items.filter((i) => !noFirstids.includes(i.id));
        noFirstids.forEach((id: number) => {
          delete this.itemsById[id];
        });
        this.selectedIds = [];
        this.selectedOrdering = [];
        this.selectedIdsSource.next(this.selectedIds);
        this.unselectAll();

        this.itemsSource.next(this.items);
        this.snackBarService.showSnackBar(
          'Successfully merged gallery/ies.',
          'INFO'
        );
        this.loadingService.setLoading(false);
      },
      (error) => {
        this.snackBarService.showSnackBar(
          'Failed merged gallery/ies.',
          'ERROR'
        );
        this.loadingService.setLoading(false);
      }
    );
  }

  append(field: string, what: AutocompleteOption) {
    this.items.forEach((item) => {
      if (!this.selectedIds.includes(item.id)) return;

      switch (field) {
        case 'people':
          item.people?.push(what);

          break;

        case 'keywords':
          item.keywords?.push(what);

          break;

        case 'keywordsEn':
          item.keywordsEn?.push(what);

          break;

        case 'caption':
          this.itemsById[item.id].caption += what.title;
          break;
      }
    });
    this.markAsDirty(this.items);
    this.loadDataForSelectedItem();
  }

  cancel() {
    this.unsavedItemsIds = [];
    this.isUnsavedSource.next(false);
  }

  private selectAllSource = new Subject<boolean>();
  public selectAllData: Observable<boolean> =
    this.selectAllSource.asObservable();

  selectAll() {
    this.selectAllSource.next(true);
  }

  unselectAll() {
    this.selectAllSource.next(false);
  }

  private fieldChangeSource = new Subject<FieldChangedEvent>();
  public fieldChangeData: Observable<FieldChangedEvent> =
    this.fieldChangeSource.asObservable();

  //////////////////////////////////////////////////////////////////////////////
  // SCHEMA
  //////////////////////////////////////////////////////////////////////////////

  public serverListSchemaToClientListSchema(
    products: GalleryList
  ): GalleryImportItem[] {
    return products.gallery_list!.map((uploadSessionItem) => {
      return GalleriesService.serverSchemaToClientSchema(uploadSessionItem);
    });
  }

  clientListSchemaToServerListSchema(
    sendProducts: boolean = true
  ): GalleryList {
    let changedItems = this.unsavedItemsIds.map((id) => this.itemsById[id]);
    let galleries: Gallery[] = changedItems.map((item) => {
      // Here we have to fill out such information and take in account
      // where we have a new upload item or if the upload item is something
      // from an old session.

      let agency: Agency | undefined = undefined;
      if (item.agency != undefined)
        agency = {
          id: item.agency.value,
          name: item.agency.title,
        };

      let author: Author | undefined = undefined;
      if (item.author != undefined)
        author = {
          id: item.author.value,
          name: item.author.title,
        };

      let category: Category | undefined = undefined;
      if (item.categories != undefined)
        category = {
          id: item.categories.value,
          name: [],
        };

      let keywordsList: Keyword[] = [];
      if (item.keywords != undefined)
        item.keywords.forEach((k) => {
          keywordsList.push({
            id: k.value,
            name: [{ language: 'HR', content: k.title }],
          });
        });

      if (item.keywordsEn != undefined)
        item.keywordsEn.forEach((k) => {
          keywordsList.push({
            id: k.value,
            name: [{ language: 'EN', content: k.title }],
          });
        });

      let licenceType: LicenceType | undefined = undefined;
      if (item.author != undefined)
        author = {
          id: item.author.value,
          name: item.author.title,
        };

      let peopleList: Array<Person> = [];
      if (item.people != undefined)
        item.people.forEach((k) => {
          peopleList.push({
            id: k.value,
            name: k.title,
          });
        });

      let specialOfferTypes: SpecialOfferType[] | undefined = undefined;
      if (item.specialOfferTypes != undefined)
        specialOfferTypes = item.specialOfferTypes.map((so) => {
          return {
            id: so.value,
            name: [],
          };
        });

      let tagList: Array<Tag> = [];
      if (item.tag != undefined)
        tagList = [
          {
            id: item.tag!.value,
            name: [],
          },
        ];

      var id: number | undefined = item.id;
      if (id < 0) {
        id = undefined;
      }

      let articleContent: string | undefined = undefined;
      if (item.articleContent?.length != 0) {
        articleContent = item.articleContent;
      }

      let articleAuthor: Author | undefined = undefined;
      if (item.articleAuthor != undefined)
        articleAuthor = {
          id: item.articleAuthor.value,
          name: item.articleAuthor.title,
        };

      let uploadItem: Gallery = {
        // This is required, but not for sending to the server.

        id: id,
        agency: agency,
        author: author,
        caption: [
          { language: 'HR', content: item.caption },
          { language: 'EN', content: item.captionEn },
        ],
        category: category,
        headline: [{ language: 'HR', content: item.headline?.title }],
        is_free: item.isFree ?? false,
        is_featured: item.featured ?? false,
        is_digital_exhibition: item.digitalExhibition ?? false,
        is_special_offer: item.specialOffer ?? false,
        keywords_list: keywordsList,
        people_list: peopleList,
        special_offer_types: specialOfferTypes,
        tag_list: tagList,
        print_price: item.printPrice,
        web_price: item.webPrice,
        article_content: articleContent,
        article_title: item.articleTitle,
        article_author: articleAuthor,
        publish_date: item.publishDate,
        publish_state: item.publishState ?? 'AUTO_PUBLISH_AFTER_DATE',
        print_price_eur: item.printPriceEur,
        web_price_eur: item.webPriceEur,
      };

      if (sendProducts) {
        uploadItem.product_list = item.items?.map((item) => {
          let product: any = {
            id: item.id,
          };

          return product;
        });
      }

      return uploadItem;
    });

    return {
      gallery_list: galleries,
    };
  }

  public static serverSchemaToClientSchema(
    uploadSessionItem: Gallery
  ): GalleryImportItem {
    let width = 1;
    let height = 1;

    let agency: AutocompleteOption | undefined = undefined;
    if (uploadSessionItem.agency != undefined)
      agency = {
        title: uploadSessionItem.agency!.name!,
        value: uploadSessionItem.agency!.id!,
        isSelectable: true,
      };

    let author: AutocompleteOption | undefined = undefined;
    if (uploadSessionItem.author != undefined)
      author = {
        title: uploadSessionItem.author.name,
        value: uploadSessionItem.author.id,
        isSelectable: true,
      };

    let categories: AutocompleteOption | undefined = undefined;

    if (uploadSessionItem.category != undefined)
      categories = {
        title:
          uploadSessionItem.category.name.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',
        value: uploadSessionItem.category.id!,
        isSelectable: true,
      };

    if (uploadSessionItem.agency != undefined)
      agency = {
        title: uploadSessionItem.agency!.name!,
        value: uploadSessionItem.agency!.id!,
        isSelectable: true,
      };

    let specialOfferTypes: AutocompleteOption[] | undefined = undefined;
    if (uploadSessionItem.special_offer_types != undefined)
      specialOfferTypes = uploadSessionItem.special_offer_types.map((so) => {
        return {
          title:
            so.name.find((t: Translatable) => t.language == 'HR')?.content ??
            '',
          value: so.id,
          isSelectable: true,
        };
      });

    let tag: AutocompleteOption | undefined = undefined;

    if (
      uploadSessionItem.tag_list != undefined &&
      uploadSessionItem.tag_list.length != 0
    )
      tag = {
        title:
          uploadSessionItem.tag_list[0].name.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',
        value: uploadSessionItem.tag_list[0].id,
        isSelectable: true,
      };

    let articleAuthor: AutocompleteOption | undefined = undefined;
    if (uploadSessionItem.article_author != undefined)
      articleAuthor = {
        title: uploadSessionItem.article_author.name,
        value: uploadSessionItem.article_author.id,
        isSelectable: true,
      };

    let object: GalleryImportItem = {
      id: uploadSessionItem.id!,
      width: width,
      height: height,
      previewUrl: this.computePreview(uploadSessionItem),

      title:
        uploadSessionItem.headline?.find(
          (t: Translatable) => t.language == 'HR'
        )?.content ?? '',
      headline: {
        title: this.getHeadline(uploadSessionItem),
        value: -1,
        isSelectable: true,
      },

      caption:
        uploadSessionItem.caption?.find((t: Translatable) => t.language == 'HR')
          ?.content ?? '',
      people: uploadSessionItem.people_list?.map((p) => {
        return {
          title: p.name,
          value: p.id,
          isSelectable: true,
        };
      }),
      keywords: uploadSessionItem.keywords_list?.map((k) => {
        return {
          title:
            k.name?.find((t: Translatable) => t.language == 'HR')?.content ??
            '',
          value: k.id,
          isSelectable: true,
        };
      }),

      categories: categories,
      author: author,
      agency: agency,
      tag: tag,
      featured: uploadSessionItem.is_featured,
      digitalExhibition: uploadSessionItem.is_digital_exhibition,
      specialOffer: uploadSessionItem.is_special_offer,
      specialOfferTypes: specialOfferTypes,
      articleTitle: uploadSessionItem.article_title,
      articleContent: uploadSessionItem.article_content,
      articleAuthor: articleAuthor,

      printPrice: uploadSessionItem.print_price,
      webPrice: uploadSessionItem.web_price,

      printPriceEur: uploadSessionItem.print_price_eur,
      webPriceEur: uploadSessionItem.web_price_eur,

      items: uploadSessionItem.product_list?.map((p) =>
        ProductsService.deserializeProduct(p, uploadSessionItem.id ?? -1)
      ),

      captionEn:
        uploadSessionItem.caption?.find((t: Translatable) => t.language == 'EN')
          ?.content ?? '',
      keywordsEn: uploadSessionItem.keywords_list?.map((k) => {
        return {
          title:
            k.name?.find((t: Translatable) => t.language == 'EN')?.content ??
            '',
          value: k.id,
          isSelectable: true,
        };
      }),
      publishDate: uploadSessionItem.publish_date,
      publishState: uploadSessionItem.publish_state,
      dateCreated: (uploadSessionItem as any).create_date,
      dateImported: (uploadSessionItem as any).create_date,

      isFree: uploadSessionItem.is_free,

      totalCount: uploadSessionItem.total_count,
    };

    return object;
  }
}
