<div class="right-side-container" *ngIf="isLoaded">
  <div class="content-top" *ngIf="!isGallery">
    <p class="content-title" [innerHTML]="'purchase_sidebar.usage' | translate">
      Kako mogu koristiti ovaj sadržaj?
    </p>
    <button class="info-btn" (click)="toggleHowToUse()"></button>
  </div>

  <ng-container [ngSwitch]="isHowToUseActive">
    <ng-container *ngSwitchCase="true">
      <div class="how-to-use">
        <ng-container *ngIf="currentLanguage == 'hr'">
          <p *ngFor="let right of usageRightsName; let i = index">
            <span>{{ right.content }}: </span
            >{{ usageRightsContent[i].content }}
          </p>
        </ng-container>

        <ng-container *ngIf="currentLanguage == 'en'">
          <p *ngFor="let right of usageRightsNameEn; let i = index">
            <span>{{ right.content }}: </span
            >{{ usageRightsContentEn[i].content }}
          </p>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="image-price-wrapper">
        <div class="name-wrapper">
          <span id="name" *ngIf="productCount === 1">
            {{ calculateProductPriceObject.product_name }}
          </span>
          <span id="name" class="name-multiple" *ngIf="productCount > 1">
            BROJ PROIZVODA: {{ productCount }}
          </span>
        </div>
        <div class="emptyLine"></div>
        <div class="price-holder">
          <ul class="ul-keyword">
            <li class="li-keyword" *ngIf="isPrintAvailible && !isVideo">
              <label class="size-label" for="print">
                <div class="size-left">
                  <div class="other-Wrapper">
                    <input
                      type="radio"
                      id="print"
                      name="size"
                      [attr.checked]="true"
                      [(ngModel)]="variation"
                      value="PRINT"
                    />
                    <div class="custom-radio"></div>
                    LARGE
                  </div>
                  <div
                    *ngIf="
                      calculateProductPriceObject.agephoto_large_resolution ==
                        null ||
                      calculateProductPriceObject.agephoto_large_resolution ==
                        undefined
                    "
                    class="otherInformations"
                  >
                    Print (4000 x 2667)
                  </div>
                  <div
                    *ngIf="
                      calculateProductPriceObject.agephoto_large_resolution !=
                        null &&
                      calculateProductPriceObject.agephoto_large_resolution !=
                        undefined
                    "
                    class="otherInformations"
                  >
                    Print ({{
                      calculateProductPriceObject.agephoto_large_resolution
                    }})
                  </div>
                </div>
                <div class="size-right">
                  <div class="price" *ngIf="!isGallery">
                    <app-d-price
                      theme="1"
                      [value]="calculateProductPriceObject.print_price!"
                      [alternativeValue]="
                        calculateProductPriceObject.alternative_print_price!
                      "
                      [currency]="calculateProductPriceObject.currency!"
                      [useCurrentEuroPhase]="true"
                    ></app-d-price>
                  </div>
                  <div
                    *ngIf="calculateProductPriceObject.use_tax!"
                    class="has-pdv"
                    [innerHTML]="
                      'cart_payment_methods.pdv_included' | translate
                    "
                  >
                    (PDV uključen u cijenu)
                  </div>
                </div>
              </label>
            </li>

            <li class="li-keyword" *ngIf="isWebAvailible && !isVideo">
              <label class="size-label" for="web">
                <div class="size-left">
                  <div class="other-Wrapper">
                    <input
                      type="radio"
                      id="web"
                      name="size"
                      [(ngModel)]="variation"
                      value="WEB"
                    />
                    <div class="custom-radio"></div>
                    SMALL
                  </div>
                  <div
                    *ngIf="
                      calculateProductPriceObject.agephoto_small_resolution ==
                        null ||
                      calculateProductPriceObject.agephoto_small_resolution ==
                        undefined
                    "
                    class="otherInformations"
                  >
                    Web (1600 x 1072)
                  </div>
                  <div
                    *ngIf="
                      calculateProductPriceObject.agephoto_small_resolution !=
                        null &&
                      calculateProductPriceObject.agephoto_small_resolution !=
                        undefined
                    "
                    class="otherInformations"
                  >
                    Web ({{
                      calculateProductPriceObject.agephoto_small_resolution
                    }})
                  </div>
                </div>
                <div class="size-right">
                  <div
                    class="price"
                    *ngIf="
                      !calculateProductPriceObject.price_on_demand && !isGallery
                    "
                  >
                    <app-d-price
                      theme="1"
                      [value]="calculateProductPriceObject.web_price!"
                      [alternativeValue]="
                        calculateProductPriceObject.alternative_web_price!
                      "
                      [currency]="calculateProductPriceObject.currency!"
                      [useCurrentEuroPhase]="true"
                    ></app-d-price>
                    <div
                      *ngIf="calculateProductPriceObject.use_tax!"
                      class="has-pdv"
                      [innerHTML]="
                        'cart_payment_methods.pdv_included' | translate
                      "
                    >
                      (PDV uključen u cijenu)
                    </div>
                  </div>
                  <div
                    class="price on-demand"
                    *ngIf="calculateProductPriceObject.price_on_demand"
                  >
                    <span>Available on</span>
                    demand
                  </div>
                </div>
              </label>
            </li>

            <li class="li-keyword" *ngIf="isVideo">
              <div class="video-list-item">
                <div class="other-Wrapper">
                  {{ videoResolution }}
                </div>
                <div class="size-right">
                  <div
                    class="price"
                    *ngIf="
                      !calculateProductPriceObject.price_on_demand && !isGallery
                    "
                  >
                    <app-d-price
                      theme="1"
                      [value]="calculateProductPriceObject.video_price!"
                      [alternativeValue]="
                        calculateProductPriceObject.alternative_video_price!
                      "
                      [currency]="calculateProductPriceObject.currency!"
                      [useCurrentEuroPhase]="true"
                    ></app-d-price>
                  </div>
                  <div
                    class="price on-demand"
                    *ngIf="
                      calculateProductPriceObject.price_on_demand && !isGallery
                    "
                  >
                    <span>Available on</span>
                    demand
                  </div>
                </div>
              </div>
            </li>

            <li class="li-keyword dl-total" *ngIf="isDownload">
              <div class="dl-total__left">
                <div class="dl-total__title">Ukupna za platiti</div>
              </div>
              <div class="dl-total__price">
                <!-- num.toFixed(2).split(".").join(",") -->
                1360,00 HRK fixed value rn
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="author-signature" *ngIf="!isVideo">
        <label for="signature" id="checkboxtext">
          <input
            type="checkbox"
            id="signature"
            name="signature"
            [(ngModel)]="signature"
          />
          <div class="custom-input"></div>
          {{ "purchase_sidebar.signature" | translate }}
        </label>
      </div>
      <div class="content-wrapper">
        <a
          href="#"
          class="download text-wr"
          (click)="downloadFull($event)"
          *ngIf="isDownloadAvailable"
          [class.disabled]="isDownloading"
        >
          <img class="dl-btn--normal" src="/assets/download-arrow.svg" alt="" />
          <img
            class="dl-btn--hover"
            src="/assets/download-arrow-white.svg"
            alt=""
          />
          <ng-container *ngIf="isDownloading">
            {{ "purchase_sidebar.download_in_progress" | translate }}
          </ng-container>
          <ng-container *ngIf="!isDownloading">
            {{ "purchase_sidebar.download" | translate }}
          </ng-container>
        </a>
        <div class="send">
          <button
            class="send-to text-wr2"
            *ngIf="isSendToSystemAvailable && !isVideo && !isGallery"
            (click)="sendToSystem()"
            [innerHTML]="'purchase_sidebar.send_to_system' | translate"
          >
            Pošalji u sustav
          </button>
          <button
            class="send-to text-wr2"
            *ngIf="isSendToCMSAvailable && !isVideo && !isGallery"
            (click)="sendToCms()"
            [innerHTML]="'purchase_sidebar.send_to_cms' | translate"
          >
            Pošalji u CMS
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <button
    class="add-to-basket text-wr3"
    (click)="addToCart()"
    *ngIf="isAddToCartAvailable"
    [class.already-in-cart]="
      (isCurrentProductInCartObject.print === true && variation === 'PRINT') ||
      (isCurrentProductInCartObject.web === true && variation === 'WEB')
    "
    [disabled]="
      (isCurrentProductInCartObject.print === true && variation === 'PRINT') ||
      (isCurrentProductInCartObject.web === true && variation === 'WEB')
        ? 'disabled'
        : null
    "
  >
    <img src="/assets/shopping-cart-white.svg" alt="" />
    <span [innerHTML]="'purchase_sidebar.add_to_cart' | translate"
      >DODAJ U KOŠARICU</span
    >
  </button>
  <a
    href=""
    class="dl-example text-wr4"
    download
    [class.disabled]="isDownloadingSample"
    *ngIf="isDownloadSampleAvailable && !isVideo && !isGallery"
    (click)="downloadSample($event)"
  >
    <img class="dl-btn--normal" src="/assets/download-arrow.svg" alt="" />
    <img class="dl-btn--hover" src="/assets/download-arrow-white.svg" alt="" />
    <ng-container *ngIf="isDownloadingSample">
      {{ "purchase_sidebar.download_in_progress" | translate }}
    </ng-container>
    <ng-container *ngIf="!isDownloadingSample">
      {{ "purchase_sidebar.download_preview" | translate }}
    </ng-container>
  </a>
  <div class="emptyLine set-margin"></div>
  <div class="special-notes" *ngIf="!isGallery">
    <ul class="ul-notes" *ngIf="specialInstructionsName.length">
      <ng-container *ngIf="currentLanguage == 'hr'">
        <li
          class="li-notes"
          *ngFor="let instruction of specialInstructionsName; let i = index"
        >
          <h1 class="h1-wrapper">{{ instruction.content }}</h1>
          <p
            class="p1-wrapper"
            *ngIf="specialInstructionsContent[i]"
            [innerHTML]="specialInstructionsContent[i].content"
          ></p>
        </li>
      </ng-container>

      <ng-container *ngIf="currentLanguage == 'en'">
        <li
          class="li-notes"
          *ngFor="let instruction of specialInstructionsNameEn; let i = index"
        >
          <h1 class="h1-wrapper">{{ instruction.content }}</h1>
          <p
            class="p1-wrapper"
            *ngIf="specialInstructionsNameEn[i]"
            [innerHTML]="specialInstructionsContentEn[i].content"
          ></p>
        </li>
      </ng-container>
    </ul>
  </div>

  <app-gallery-cart-downloads
    *ngIf="isDownload"
    [downloadList]="calculateProductPriceObject"
    (downloadCompletedEvent)="downloadCompleted()"
  ></app-gallery-cart-downloads>
</div>

<div class="right-side-container" *ngIf="!isLoaded">
  <div class="loading-skeleton-item"></div>
</div>
