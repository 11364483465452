/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AutocompleteItem {
  /**
   * ID of the result item, for example if we're searching for     cats, this is the ID of the cat
   */
  id: number;
  /**
   * What title is displayed in the autocomplete box
   */
  title: string;
  helper_text?: string;
}
