import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Action } from '../field-text/field-text.component';
import {
  ErrorResponse,
  ModelError,
} from '../../../../dist/piwe-front-swagger-client';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CmsComponent } from '../cms-component';

@Component({
  selector: 'app-field-checkbox',
  templateUrl: './field-checkbox.component.html',
  styleUrls: ['./field-checkbox.component.scss'],
})
export class FieldCheckboxComponent
  extends CmsComponent
  implements OnInit, OnDestroy
{
  @Input('checkedAsync') public checked$?: Observable<boolean | undefined>;
  @Input() public checked?: boolean | undefined = false;
  @Output() checkedChange: EventEmitter<boolean | undefined> =
    new EventEmitter();

  //////////////////////////////////////////////////////////////////////////////
  // Life Cycle
  //////////////////////////////////////////////////////////////////////////////

  ngOnInit(): void {
    super.ngOnInit();

    this.checked$?.pipe(takeUntil(this.unsubscribe$)).subscribe((checked) => {
      this.checked = checked;
    });
  }

  //////////////////////////////////////////////////////////////////////////////
  // Actions
  //////////////////////////////////////////////////////////////////////////////

  toggle() {
    if (this.disabled) return;

    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
