<!--<div *ngIf="settings" style="width: 100vw; height: 30px; background-color: rgba(0,81,255,0.3); color: white; font-weight: bold; z-index: 99999; text-align: center; position: fixed; top: 0; left: 0; right: 0;">
  <div *ngIf="settings!.euroPhase.valueOf() == euroPhase.HRK_ONLY.valueOf()">
    HRK only
  </div>
  <div *ngIf="settings!.euroPhase.valueOf() == euroPhase.BIG_HRK_SMALL_EUR.valueOf()">
    Big HRK, small EUR
    (1 EUR = {{settings!.euroRate! * 1.00}} HRK)
  </div>
  <div *ngIf="settings!.euroPhase.valueOf() == euroPhase.BIG_EUR_SMALL_HRK.valueOf()">
    Big EUR, small HRK
    (1 EUR = {{settings!.euroRate! * 1.00}} HRK)
  </div>
  <div *ngIf="settings!.euroPhase.valueOf() == euroPhase.EUR_ONLY.valueOf()">
    EUR only
  </div>
</div>-->

<div
  #mainAppContainer
  class="main-app-container"
  (scroll)="onNormalScroll($event)"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
  *ngIf="isAppLoaded"
>
  <app-context-menu-overlay></app-context-menu-overlay>

  <router-outlet></router-outlet>
</div>

<div class="snack-bar-container" *ngIf="snackBar" @enterLeaveSnackBar>
  <div [ngSwitch]="snackBar">
    <app-snack-bar
      [text]="snackBar.title"
      [state]="snackBar.state"
      (snackBarClicked)="closeSnackBar()"
    ></app-snack-bar>
  </div>
</div>

<app-newsletter-popup></app-newsletter-popup>

<app-modal
  *ngFor="let confirmModal of confirmModals"
  [title]="confirmModal.title"
  (modalClosed)="modalClose(confirmModal)"
  (saveModalData)="modalConfirm(confirmModal)"
  primaryButtonText="Confirm"
  secondaryButtonText="Cancel"
  @quickOpenClose
>
  {{ confirmModal.text }}
  <div style="height: 30px; width: 200px"></div>
</app-modal>

<div *ngIf="isLoading" @openClose class="loading-overlay-bg"></div>

<div
  *ngIf="isLoading"
  class="loading-overlay"
  [class.active]="isLoading"
  @openClose
>
  <div class="loading-container">
    <div class="cs-loader">
      <div class="cs-loader-inner">
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
      </div>
    </div>

    <h3>Saving</h3>
  </div>
</div>
