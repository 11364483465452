<div
  class="dynamic-price-holder"
  [ngClass]="'theme-' + theme"
  *ngIf="value !== undefined && settings !== undefined"
>
  <span class="main-price"
    >{{ value | dynamicPrice : 2 }} {{ currencyLabel }}</span
  >

  <div *ngIf="usedEuroPhase == euroPhaseEnum.BIG_HRK_SMALL_EUR.valueOf()">
    <span class="alt-price">{{ alternativeValue | dynamicPrice : 2 }} €</span>
    <br />
    <span class="exchange"
      >(1 EUR = {{ usedExchangeRate! | dynamicPrice : 6 }} HRK)</span
    >
  </div>
  <div *ngIf="usedEuroPhase == euroPhaseEnum.BIG_EUR_SMALL_HRK.valueOf()">
    <span class="alt-price">{{ alternativeValue | dynamicPrice : 2 }} kn</span>
    <br />
    <span class="exchange"
      >(1 EUR = {{ usedExchangeRate! | dynamicPrice : 6 }} HRK)</span
    >
  </div>
</div>
