import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-homepage-section-gallery',
  templateUrl: './homepage-section-gallery.component.html',
  styleUrls: ['./homepage-section-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageSectionGalleryComponent implements OnInit {
  @Input() galleryMatrix: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  onImageLoad($event: any) {
    const target = $event.target;
    let aspectRatio: number = target.naturalWidth / target.naturalHeight;
    target.parentElement.parentElement.style.flex = aspectRatio;
  }
}
