import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { environment } from 'src/app/shared/environment';

@Component({
  selector: 'app-single-image',
  templateUrl: './single-image.component.html',
  styleUrls: ['./single-image.component.scss'],
})
export class SingleImageComponent implements OnInit {
  @Input() imageLoadingPreviewUrl: string = '';
  @Input() imagePreviewUrl: string = '';
  @Output() onImageLoaded: EventEmitter<any> = new EventEmitter<any>();

  imageLoaded: boolean = false;
  isImageError: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  loadedImage($event: any) {
    this.imageLoaded = true;
  }

  erroredImage($event: any) {
    this.isImageError = true;
  }

  loadedPreviewImage($event: any) {
    this.onImageLoaded.emit($event);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.imagePreviewUrl = this.getPreviewUrl();
    this.imageLoadingPreviewUrl = this.getLoadingUrl();
  }

  buildUrl(url: string | undefined): string {
    if (url == undefined) return '';

    //if the url starts with assets then it is a local img and it should just return the url
    if (this.imagePreviewUrl?.split('/')[1] === 'assets')
      return this.imagePreviewUrl;

    if (url.includes('https://') || url.includes('http://')) {
      return url;
    }

    return environment.productServingUrl + url;
  }

  getPreviewUrl(): string {
    return this.buildUrl(this.imagePreviewUrl);
  }

  getLoadingUrl(): string {
    return this.buildUrl(this.imageLoadingPreviewUrl);
  }
}
