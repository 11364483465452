import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CartService, ICartUpdateSubject } from 'src/app/cart.service';
import { environment } from 'src/app/shared/environment';

@Component({
  selector: 'app-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss'],
})
export class CartIconComponent implements OnInit {
  private cartUpdateSubscription: Subscription = Subscription.EMPTY;
  public cartItemCount: number = 0;

  public cartNewItemObject: Omit<ICartUpdateSubject, 'cartItemCount'> = {
    itemName: '',
    itemImgUrl: '',
    cartNewItemCount: 1,
  };
  //{itemName: string, itemImgUrl: string}
  public isCartPopupOpen: boolean = false;
  public isNewItemAddedPopupActive: boolean = false;

  public apiUrl = environment.productServingUrl;

  private toggleCartPopupTimeout!: NodeJS.Timeout;
  private toggleNewItemPopupTimeout!: NodeJS.Timeout;

  constructor(private router: Router, private cartService: CartService) {}

  ngOnInit(): void {
    this.cartService
      .getCart()
      .pipe(take(1))
      .subscribe((response) => console.debug('cart', response));
    this.cartUpdateSubscription = this.cartService.cartUpdateSubject.subscribe(
      (response) => {
        this.cartItemCount = response.cartItemCount;

        if (response.itemName && response.itemImgUrl) {
          this.cartNewItemObject = {
            itemName: response.itemName,
            itemImgUrl: response.itemImgUrl,
            cartNewItemCount: response.cartNewItemCount,
          };
          this.openNewItemPopup();
        }
      }
    );
    //check the cart
    //subscribe for updates
  }
  ngOnDestroy() {
    this.cartUpdateSubscription.unsubscribe();
  }

  handleCartClick() {
    if (this.cartItemCount) {
      this.router.navigate(['/cart']);
      this.closeNewItemPopup();
      this.closeToggleCartPopup();
    } else {
      this.openToggleCartPopup();
    }
  }

  closeToggleCartPopup() {
    this.isCartPopupOpen = false;
  }
  openToggleCartPopup() {
    clearTimeout(this.toggleCartPopupTimeout);
    this.isCartPopupOpen = true;

    this.toggleCartPopupTimeout = setTimeout(() => {
      this.isCartPopupOpen = false;
    }, 5000);
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  closeNewItemPopup() {
    this.isNewItemAddedPopupActive = false;
  }
  openNewItemPopup() {
    clearTimeout(this.toggleNewItemPopupTimeout);
    this.isNewItemAddedPopupActive = true;

    this.toggleNewItemPopupTimeout = setTimeout(() => {
      this.isNewItemAddedPopupActive = false;
    }, 5000);
  }
}
