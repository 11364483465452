import { Pipe, PipeTransform } from '@angular/core';
import { ImportItem } from '../cms/import/import.component';

@Pipe({
  name: 'importItemHeadline',
})
export class ImportItemHeadlinePipe implements PipeTransform {
  transform(value: ImportItem, ...args: unknown[]): unknown {
    return value.title;
  }
}
