<form class="search" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
  <div class="search__bar" *ngIf="hasSearch">
    <input
      type="text"
      name="q"
      id="q"
      formControlName="q"
      placeholder="Type a search keyword"
    />
  </div>
  <div class="search__fields">
    <ng-template ngFor let-item [ngForOf]="fieldOptions">
      <ng-container [ngSwitch]="item.type">
        <ng-container *ngSwitchCase="1">
          <div>
            <label for="{{ item.devName }}">{{ item.labelName }}</label>
            <input
              type="text"
              name="{{ item.devName }}"
              id="{{ item.devName }}"
              formControlName="{{ item.devName }}"
            />
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <div>
            <label for="{{ item.devName }}">{{ item.labelName }}</label>
            <select
              formControlName="{{ item.devName }}"
              id="{{ item.devName }}"
              class="select-custom"
            >
              <option
                *ngFor="let option of item.selectOptions | keyvalue"
                [value]="option.key"
              >
                {{ option.value | titlecase }}
              </option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <div>
            <label for="fromDate">{{ item.labelName }}</label>
            <div class="search__date">
              <!-- <input
                type="date"
                name="fromDate"
                id="fromDate"
                formControlName="fromDate"
              />
              <div class="search__vr"></div>
              <input
                type="date"
                name="toDate"
                id="toDate"
                formControlName="toDate"
              /> -->

              <dp-date-picker
                [config]="dtConfig"
                theme="dp-material"
                class="date-picker"
                placeholder="From date"
                formControlName="fromDate"
              ></dp-date-picker>
              <div class="date-picker-vertical-divider"></div>
              <dp-date-picker
                [config]="dtConfig"
                theme="dp-material"
                class="date-picker"
                placeholder="To date"
                formControlName="toDate"
              ></dp-date-picker>
              <div
                class="reset-date"
                *ngIf="
                  searchForm.get('fromDate')?.value !== '' &&
                  searchForm.get('fromDate')?.value !== undefined
                "
                (click)="resetFromDateInput()"
              ></div>
              <div
                class="reset-date reset-date2"
                *ngIf="
                  searchForm.get('toDate')?.value !== '' &&
                  searchForm.get('toDate')?.value !== undefined
                "
                (click)="resetToDateInput()"
              ></div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="5">
          <div>
            <label for="fromDate">{{ item.labelName }}</label>
            <div class="search__date">
              <dp-date-picker
                [config]="dtConfig"
                theme="dp-material"
                class="date-picker"
                placeholder="From date"
                formControlName="fromDate"
              ></dp-date-picker>
              <div class="date-picker-vertical-divider"></div>
              <dp-date-picker
                [config]="dtConfig"
                theme="dp-material"
                class="date-picker"
                placeholder="To date"
                formControlName="toDate"
              ></dp-date-picker>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="6">
          <div>
            <app-field-text
              class="import-sidebar-input"
              [label]="item.labelName"
              (autocompleteValueChange)="
                autocompleteInputValueChange($event, item.devName)
              "
              [acceptUserInput]="true"
              [hasCreateNewAutocomplete]="false"
              theme="cms-filter"
              [autocompleteType]="item.autocompleteType"
            ></app-field-text>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="7">
          <div>
            <label for="{{ item.devName }}">{{ item.labelName }}</label>
            <div class="search__date">
              <dp-date-picker
                [config]="dtConfig"
                theme="dp-material"
                class="date-picker"
                formControlName="{{ item.devName }}"
              ></dp-date-picker>
              <div
                class="reset-date reset-date-single"
                *ngIf="
                  searchForm.get(item.devName)?.value !== '' &&
                  searchForm.get(item.devName)?.value !== undefined
                "
                (click)="resetDateSingleInput(item.devName)"
              ></div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>

  <hr />
  <div class="search__footer">
    <button class="search__btn-edit"></button>
    <button
      class="search__btn-search"
      type="submit"
      [disabled]="!searchForm.valid"
    >
      Search
    </button>
  </div>
</form>
