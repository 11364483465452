<div class="special-modal" (click)="handleCancelClick()">
  <div class="special-modal__body" (click)="stopPropagation($event)">
    <h1 class="special-modal__header">{{ title }}</h1>

    <div class="special-modal__content">
      <ng-content></ng-content>
    </div>
    <div class="special-modal__footer" *ngIf="!isFooterHidden">
      <button
        *ngIf="!isSecondaryButtonHidden"
        class="special-modal__cancel-btn"
        (click)="handleCancelClick()"
      >
        {{ secondaryButtonText }}
      </button>
      <button
        *ngIf="!isPrimaryButtonHidden"
        class="special-modal__add-btn"
        (click)="handleSaveButtonClick()"
        [class.disable-save-btn]="isSaveButtonDisabled"
        [disabled]="isSaveButtonDisabled ? 'disabled' : null"
      >
        {{ primaryButtonText }}
      </button>
    </div>
  </div>
</div>
