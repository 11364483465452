import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollSignalSource = new Subject<number>();
  public scrollSignalData: Observable<number> =
    this.scrollSignalSource.asObservable();

  constructor() {}

  signalScroll(offset: number) {
    this.scrollSignalSource.next(offset);
  }
}
