import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          transformOrigin: 'top',
          overflow: 'hidden',
        })
      ),
      state(
        'closed',
        style({
          transformOrigin: 'top',
          overflow: 'hidden',
          height: '0',
          paddingTop: '0',
          paddingBottom: '0',
        })
      ),
      transition('open => closed', [animate('0.2s ease-in')]),
      transition('closed => open', [animate('0.2s ease-in')]),
    ]),
    trigger('openCloseIcon', [
      // ...
      state(
        'openIcon',
        style({
          transform: 'rotate(180deg)',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'ease-in',
        })
      ),
      state(
        'closedIcon',
        style({
          transform: 'rotate(0deg)',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'ease-in',
        })
      ),
      transition('open => closed', [animate('0.2s ease-in')]),
      transition('closed => open', [animate('0.2s ease-in')]),
    ]),
  ],
})
export class ExpansionPanelComponent implements OnInit {
  public isHidden: boolean = false;
  @Input() public defaultIsHidden: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.defaultIsHidden) this.isHidden = true;
  }
  toggleIsHidden() {
    this.isHidden = !this.isHidden;
  }
}
