import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SearchService, SubPortal } from '../search/search.service';

@Component({
  selector: 'app-search-more-block',
  templateUrl: './search-more-block.component.html',
  styleUrls: ['./search-more-block.component.scss'],
})
export class SearchMoreBlockComponent implements OnInit {
  @Input() nameOfSubportal: string = '';

  //this, instead of a banner returns a full grid with the same purpuse of cross subportal search
  @Input() isFullGrid: boolean = false;

  public images: any[] = [];
  public imagesPerRow: number = 7;
  private paramsSubscription: Subscription = new Subscription();
  public searchQuery: any;
  private currentRoute: string = '';
  public isLoading: boolean = true;
  public link: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParams.subscribe(
      (params: any) => {
        this.searchQuery = params.q ?? '';
        this.currentRoute = this.router.url.split('?')[0];

        this.queryOposite(this.currentRoute, this.searchQuery);
      }
    );
    this.getNumberOfImagesPerRow();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.getNumberOfImagesPerRow();
  }

  getNumberOfImagesPerRow() {
    const width: number =
      document.querySelector('.main-app-container')?.clientWidth ?? 0;

    if (width <= 1024) {
      this.images.length > 3
        ? (this.imagesPerRow = 3)
        : (this.imagesPerRow = this.images.length);
    } else if (width > 1024 && width <= 1200) {
      this.images.length > 4
        ? (this.imagesPerRow = 4)
        : (this.imagesPerRow = this.images.length);
    } else if (width > 1200 && width <= 1300) {
      this.images.length > 5
        ? (this.imagesPerRow = 5)
        : (this.imagesPerRow = this.images.length);
    } else if (width > 1200 && width <= 1500) {
      this.images.length > 6
        ? (this.imagesPerRow = 6)
        : (this.imagesPerRow = this.images.length);
    } else {
      this.images.length > 7
        ? (this.imagesPerRow = 7)
        : (this.imagesPerRow = this.images.length);
    }
  }

  queryOposite(route: string, query: string) {
    this.isLoading = true;
    //console.log('eawe', route, query);

    let subportal: SubPortal = SubPortal.Production;
    switch (route) {
      case '/production':
        this.nameOfSubportal = this.translate.instant(
          'search_more_banner.agencies'
        );
        //query agency
        subportal = SubPortal.Agencies;
        this.getSearchResults(subportal, query, '/agency');
        break;
      case '/video':
        //disable
        break;
      case '/agency':
        this.nameOfSubportal = this.translate.instant(
          'search_more_banner.production'
        );
        //query production
        subportal = SubPortal.Production;
        this.getSearchResults(subportal, query, '/production');
        break;
      case '/reuters':
        //none
        break;
      //TODO - enable AGEPHOTOSTOCK
      case '/agefotostock':
        /*this.nameOfSubportal = this.translate.instant(
          'search_more_banner.stock'
        );
        //query agephotostock
        subportal = SubPortal.Stock;
        this.getSearchResults(subportal, query, '/stock');*/
        break;
      case '/stock':
        this.nameOfSubportal = this.translate.instant(
          'search_more_banner.agephotostock'
        );
        //query stock
        subportal = SubPortal.Agephotostock;
        this.getSearchResults(subportal, query, '/agephotostock');
        break;
      default:
        break;
    }
  }

  private getSearchResults(subportal: SubPortal, query: string, route: string) {
    this.searchService
      .getSearchResults(subportal, query, [], 1, false)
      .subscribe((response) => {
        if (response.content != null && response.content.length > 0) {
          this.images = response.content;
          this.isLoading = false;
          this.link = query != '' ? `${route}?q=${query}` : `${route}`;

          this.getNumberOfImagesPerRow();
        }
      });
  }

  stopEventPropagation(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }
}
