<div
  class="search-container"
  *ngIf="!isSearchActive"
  [ngStyle]="{
    'background-image': 'url(' + bannerSrc + ')',
    'background-size': 'cover',
    'background-position': 'center'
  }"
>
  <div class="imgdiv">
    <div class="search-holder">
      <!-- <div class="search-text" [innerHTML]="bannerTitle"></div> -->
      <div class="search-wrapper">
        <ng-container *ngIf="!isFilterSidebarOpen">
          <div class="filter-group">
            <button class="image-button" (click)="openFilterSidebar()">
              <img src="/assets/filters.svg" alt="" />
              <span class="filt">Filter</span>
            </button>
            <div class="vr"></div>
          </div>
        </ng-container>

        <input
          [(ngModel)]="searchQuery"
          (ngModelChange)="searchQueryChange.emit(searchQuery)"
          type="text"
          placeholder="{{ bannerSearchPlaceholder }}"
          class="subdomain"
          [class.subdomain--smaller]="!isFilterSidebarOpen"
          (keydown.enter)="onEnter()"
        />
        <input
          type="submit"
          id="submitForm"
          value="&nbsp;"
          (click)="onEnter()"
        />
      </div>
    </div>
  </div>
</div>

<div class="search-alternative" *ngIf="isSearchActive">
  <div class="search-search">
    <ng-container *ngIf="!isFilterSidebarOpen">
      <div class="filter-group">
        <button
          class="image-button"
          id="image-button"
          (click)="openFilterSidebar()"
        >
          <img src="/assets/filters.svg" alt="" />
          <span class="filt">Filter</span>
        </button>
        <div class="vr"></div>
      </div>
    </ng-container>
    <input
      [(ngModel)]="searchQuery"
      type="text"
      placeholder="{{ 'all.generic_search_placeholder' | translate }}"
      class="subdomain subdomain--alt"
      [class.subdomain--smaller]="!isFilterSidebarOpen"
      (keydown.enter)="onEnter()"
    />
    <input
      type="submit"
      class="search-btn-alt"
      value="&nbsp;"
      (click)="onEnter()"
    />
  </div>
</div>
