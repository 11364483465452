import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ImportService } from '../../cms/import/import.service';
import { ProductsService } from '../../cms/content/products/products.service';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent implements OnInit {
  @Input() isImport: boolean = true;
  @Input() isImportReplace: boolean = true;

  itemReplaceId?: number;

  constructor(
    public importService: ImportService,
    public productService: ProductsService
  ) {}

  ngOnInit(): void {
    if (this.isImport) {
      this.importService.uploadingFilesData.subscribe((uploadingFiles) => {
        this.files = uploadingFiles;
      });
    } else {
      this.productService.uploadingFilesData.subscribe((uploadingFiles) => {
        this.files = uploadingFiles;
      });

      this.productService.requestFileReplaceData.subscribe((id) => {
        this.fileDropEl?.nativeElement.click();
        this.itemReplaceId = id;
      });
    }
  }

  @ViewChild('fileDropRef', { static: false })
  fileDropEl?: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event: any) {
    const files: any = $event.target.files;
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    if (this.isImport) {
      this.importService.uploadFile(files);
    } else {
      if (this.isImportReplace) {
        this.importService.replaceFile(files, this.itemReplaceId!);
      } else {
        this.productService.uploadFile(files, this.itemReplaceId!);
      }
    }

    if (this.fileDropEl) {
      this.fileDropEl.nativeElement.value = '';
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
