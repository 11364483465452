import { Download, User } from 'piwe-front-swagger-client';
import { environment } from 'src/app/shared/environment';
import {
  Contract,
  ContractLine,
  Invoice,
} from '../../../projects/piwe-front-swagger-client/src';
import { formatDate } from '@angular/common';

export class Serialization {
  static serializeUser(countries: { [id: number]: string }, user: User): any {
    let country = '';
    if (user.country_id) {
      country = countries[user.country_id];
    }

    return {
      id: user.id,
      name: user.first_name,
      surname: user.last_name,
      profilePic: 'assets/default-avatar.svg',
      profileUrl: 'assets/default-avatar.svg',
      companyName: user.company?.company_name,
      address: user.address_line_1,
      city: user.city,
      postalCode: user.postal_code,
      country: country,
      OIB: user.company?.oib_company,
      MBS: user.company?.mbs,
      phone: user.phone_number,
      email: user.email,
      password: 'SUPERSECRET',
      contactPerson: user.company?.contact_person,
      contactPhone: user.company?.contact_phone,
      contactEmail: user.company?.contact_email,
    };
  }

  static optionalParseNumber(
    stringValue: string | undefined
  ): number | undefined {
    if (stringValue == undefined) return undefined;

    return parseInt(stringValue);
  }

  static deserializeUser(userData: Map<string, string | undefined>): User {
    let password = undefined;

    if (userData.get('password') != 'SUPERSECRET') {
      password = userData.get('password');
    }

    return {
      first_name: userData.get('name'),
      last_name: userData.get('surname'),
      email: userData.get('email'),
      phone_number: userData.get('phone'),
      address_line_1: userData.get('address'),
      city: userData.get('city'),
      country_id: this.optionalParseNumber(userData.get('country')),
      password: password,
    };
  }

  static serializeDownload(download: Download): any {
    let previewUrl: string | undefined = undefined;

    if (
      download?.product_preview?.preview &&
      download?.product_preview?.preview.length != 0
    ) {
      previewUrl = download?.product_preview?.preview[0].preview_url;
    }

    let user: string | undefined = undefined;
    if (download?.user) {
      user =
        (download?.user?.first_name ?? '') + ' ' + download?.user?.last_name ??
        '';
    }

    let tariff = download?.variation == 'PRINT' ? 'Print' : 'Web';
    if (download?.content_type == 'VIDEO') {
      tariff = '';
    }

    return {
      previewUrl: environment.productServingUrl + previewUrl,
      user: user,
      date: Serialization.formatDate(download.downloaded_at),
      info: {
        name: download?.product_preview?.product_name,
        author: download?.product_preview?.author_name,
        agency: download?.product_preview?.agency_name,
      },
      currency: download?.currency,
      price: download?.download_price,
      tariff: tariff,
      type: download?.content_type == 'PHOTO' ? 'Foto' : 'Video',
    };
  }

  static formatDate(value: string | undefined): string {
    if (value == undefined) return '';

    return formatDate(value!, 'd.M.y. H:mm', 'en-uS');
  }

  static formatOnlyDate(value: string | undefined): string {
    if (value == undefined) return '';

    return formatDate(value!, 'd.M.y.', 'en-uS');
  }

  static serializeContractFromTo(contractLine: ContractLine): string {
    if (contractLine.from == undefined || contractLine.to == undefined)
      return '';

    return `${contractLine.from}-${contractLine.to}`;
  }

  static serializeContractUsage(contractLine: ContractLine): string {
    if (
      contractLine.state_used == undefined ||
      contractLine.state_total == undefined
    )
      return '';

    return `${Math.min(contractLine.state_total, contractLine.state_used)}/${
      contractLine.state_total
    }`;
  }

  static serializeMoney(money: number | undefined): string {
    if (money == undefined) return '';

    return `${money.toFixed(2)}`;
  }

  static serializePercentage(percentage: number | undefined): string {
    if (percentage == undefined) return '';

    return `${percentage.toFixed(2)}`;
  }

  static serializeContractLine(contractLine: ContractLine): any {
    return {
      id: 1,
      contractType: contractLine.line_type == 'FLAT_RATE' ? 'Paušal' : 'Popust',
      dataType: contractLine.content_type == 'VIDEO' ? 'Video' : 'Foto', // TODO: Fix Serialization
      FromTo: Serialization.serializeContractFromTo(contractLine),
      price: Serialization.serializeMoney(contractLine.line_price),
      percentage: Serialization.serializePercentage(contractLine.percentage),
      forAgency: contractLine.agencies?.map((a) => a.title).join(', ') ?? '',
      state: Serialization.serializeContractUsage(contractLine),
    };
  }

  static serializeContract(contract: Contract): any {
    return {
      id: contract.id,
      userId: 1,
      name: contract.title,
      startDate: Serialization.formatDate(contract.valid_from),
      endDate: Serialization.formatDate(contract.valid_to),
      pdfUrl: 'assets/pdf/testPdf.pdf',
      currency: contract.currency,
      currency_id: contract.currency_id,
      data:
        contract.contract_lines?.map((cl) =>
          Serialization.serializeContractLine(cl)
        ) ?? [],
    };
  }

  static serializeInvoice(invoice: Invoice): any {
    return {
      id: invoice.id,
      currency: invoice.currency,
      userId: 1,
      date: Serialization.formatOnlyDate(invoice.date),
      accountNumber: invoice.id,
      withoutTax: Serialization.serializeMoney(invoice.total_no_tax),
      withTax: Serialization.serializeMoney(invoice.total_with_tax),
      alternativeWithoutTax: Serialization.serializeMoney(
        invoice.alternative_total_no_tax
      ),
      alternativeWithTax: Serialization.serializeMoney(
        invoice.alternative_total_with_tax
      ),
      paid: invoice.status == 'PAID' ? 'true' : 'false',
      invoiceNumber: invoice.invoice_number,
      euroMode: invoice.euro_mode,
      exchangeRate: invoice.exchange_rate,
    };
  }
}
