import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-bar-home',
  templateUrl: './search-bar-home.component.html',
  styleUrls: ['./search-bar-home.component.scss'],
})
export class SearchBarHomeComponent implements OnInit {
  public searchQuery: string = '';
  public typeSelect: string = '/all';

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  onEnter() {
    if (this.searchQuery !== '') {
      this.router.navigate([this.typeSelect], {
        queryParams: { q: this.searchQuery },
      });
    }
  }

  getPlaceholder() {
    // switch (this.typeSelect) {
    //   case '/production':
    //     return 'Pretraži ponudu produkcije';
    //   case '/video':
    //     return 'Pretraži video ponudu';
    //   case '/agency':
    //     return 'Pretraži ponudu agencija';
    //   case '/stock':
    //     return 'Pretraži stock ponudu';
    //   default:
    //     return 'Pretraži ponudu Pixsella';
    // }
    switch (this.typeSelect) {
      case '/production':
        return this.translateService.instant('production.search_placeholder');
      case '/video':
        return this.translateService.instant('video.search_placeholder');
      case '/agency':
        return this.translateService.instant('agencies.search_placeholder');
      case '/stock':
        return this.translateService.instant('stock.search_placeholder');
      case '/agefotostock':
        return this.translateService.instant(
          'agephotostock.search_placeholder'
        );
      default:
        return this.translateService.instant('all.search_placeholder');
    }
  }
}
