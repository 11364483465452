<div class="sidebar-container">
  <ul class="sidebar-nav" *ngIf="userPermissions">
    <li
      class="sidebar-nav-item"
      routerLink="/cms/import"
      routerLinkActive="active"
      *ngIf="userPermissions.includes(UserPermissionsEnum.IMPORTREAD)"
    >
      <a>Import</a>
    </li>

    <li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isContentExpanded"
      style="position: relative"
    >
      <div
        style="left: 0; right: 0; height: 30px; position: absolute"
        (click)="toggle('content')"
      ></div>
      <img src="assets/sidebar-nav-expand.svg" class="expand" />
      <a>Content</a>

      <ul class="sidebar-nav-nested">
        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/content/products"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.CPRODUCTSREAD)"
        >
          <img src="assets/sidebar-shopping.svg" />
          <a>Products</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/content/galleries"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.CGALLERIESREAD)"
        >
          <img src="assets/sidebar-galleries.svg" />
          <a>Galleries</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/content/gallery-group"
          routerLinkActive="active"
          *ngIf="
            userPermissions.includes(UserPermissionsEnum.CGALLERYGROUPSREAD)
          "
        >
          <img src="assets/sidebar-gallery-group.svg" />
          <a>Gallery groups</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/content/articles"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.CARTICLESREAD)"
        >
          <img src="assets/sidebar-articles.svg" />
          <a>Articles</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/content/categories"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.CCATEGORIESREAD)"
        >
          <img src="assets/sidebar-categories.svg" />
          <a>Categories</a>
        </li>
      </ul>
    </li>

    <!--<li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isNewsletterExpanded"
    >
      <img
        src="assets/sidebar-nav-expand.svg"
        class="expand"
        style="filter: opacity(60%); touch-action: none; cursor: not-allowed"
      />
      <a style="filter: opacity(60%); touch-action: none; cursor: not-allowed"
        >Newsletter</a
      >
    </li>-->

    <li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isContractsAndUsersExpanded"
      style="position: relative"
    >
      <div
        style="left: 0; right: 0; height: 30px; position: absolute"
        (click)="toggle('contractsAndUsers')"
      ></div>
      <img src="assets/sidebar-nav-expand.svg" class="expand" /><a
        >Contracts and users</a
      >

      <ul class="sidebar-nav-nested">
        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/contracts-and-users/companies"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.UCCOMPANIESREAD)"
        >
          <img src="assets/sidebar-companies.svg" />
          <a>Companies</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/contracts-and-users/users"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.UCUSERSREAD)"
        >
          <img src="assets/sidebar-users.svg" />
          <a>Users</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/contracts-and-users/contracts"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.UCCONTRACTSREAD)"
        >
          <img src="assets/sidebar-contracts.svg" />
          <a>Contracts</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/finances/contract_agency_groups"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.UCCONTRACTSREAD)"
        >
          <img src="assets/sidebar-agencies.svg" />
          <a>Agency Groups</a>
        </li>

        <!--<li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/contracts-and-users/newsletter"
          routerLinkActive="active"
          style="position: relative"
          *ngIf="userPermissions.includes(UserPermissionsEnum.NEWSLETTERREAD)"
        >
          <img src="assets/sidebar-newsletter.svg" />
          <a>Newsletter</a>
        </li>-->
      </ul>
    </li>

    <li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isDeskAdministrationExpanded"
      style="position: relative"
    >
      <div
        style="left: 0; right: 0; height: 30px; position: absolute"
        (click)="toggle('deskAdministration')"
      ></div>
      <img src="assets/sidebar-nav-expand.svg" class="expand" />
      <a>Desk administration</a>

      <ul class="sidebar-nav-nested">
        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/desk-administration/agencies"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.DAAGENCIESREAD)"
        >
          <img src="assets/sidebar-agencies.svg" />
          <a>Agencies</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/desk-administration/iptc"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.DAIPTCREAD)"
        >
          <img src="assets/sidebar-iptc.svg" />
          <a>IPTC</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/desk-administration/keywords"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.DAKEYWORDSREAD)"
        >
          <img src="assets/sidebar-keywords.svg" />
          <a>Keywords</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/desk-administration/proper-names"
          routerLinkActive="active"
          *ngIf="
            userPermissions.includes(UserPermissionsEnum.DAPROPERNAMESREAD)
          "
        >
          <img src="assets/sidebar-proper-names.svg" />
          <a>People</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/desk-administration/tags"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.DATAGSREAD)"
        >
          <img src="assets/sidebar-tags.svg" />
          <a>Tags</a>
        </li>
      </ul>
    </li>

    <li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isInternalUsersAdministrationExpanded"
      style="position: relative"
    >
      <div
        style="left: 0; right: 0; height: 30px; position: absolute"
        (click)="toggle('internalUsersAdministration')"
      ></div>
      <img src="assets/sidebar-nav-expand.svg" class="expand" />
      <a>Admini. Internal Users</a>

      <ul class="sidebar-nav-nested">
        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/internal-users-administration/internal-users"
          routerLinkActive="active"
          *ngIf="
            userPermissions.includes(UserPermissionsEnum.IUINTERNALUSERSREAD)
          "
        >
          <img src="assets/sidebar-agencies.svg" />
          <a>Internal Users</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/internal-users-administration/user-groups"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.IUUSERGROUPSREAD)"
        >
          <img src="assets/sidebar-iptc.svg" />
          <a>User Groups</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/internal-users-administration/user-loging-log"
          routerLinkActive="active"
          *ngIf="
            userPermissions.includes(UserPermissionsEnum.IUUSERLOGINLOGREAD)
          "
        >
          <img src="assets/sidebar-keywords.svg" />
          <a>User loging log</a>
        </li>
      </ul>
    </li>

    <li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isWebAdministrationExpanded"
      style="position: relative"
    >
      <div
        style="left: 0; right: 0; height: 30px; position: absolute"
        (click)="toggle('webAdministration')"
      ></div>
      <img src="assets/sidebar-nav-expand.svg" class="expand" />
      <a>Web administration</a>

      <ul class="sidebar-nav-nested">
        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/web-administration/homepage-administration"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.WAHOMEREAD)"
        >
          <img src="assets/sidebar-agencies.svg" />
          <a>Front Page</a>
        </li>

        <!-- <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/web-administration/about-us-administration"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.WAABOUTUSREAD)"
        >
          <img src="assets/sidebar-iptc.svg" />
          <a>About Us</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/web-administration/static-pages-administration"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.WASTATICPAGESREAD)"
        >
          <img src="assets/sidebar-keywords.svg" />
          <a>Static Pages</a>
        </li> -->
      </ul>
    </li>

    <li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isFinancesExpanded"
      style="position: relative"
    >
      <div
        style="left: 0; right: 0; height: 30px; position: absolute"
        (click)="toggle('finances')"
      ></div>
      <img src="assets/sidebar-nav-expand.svg" class="expand" />
      <a>Finances / Sales</a>

      <ul class="sidebar-nav-nested">
        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/finances/downloads"
          routerLinkActive="active"
          *ngIf="
            userPermissions.includes(
              UserPermissionsEnum.FINPRODUCTDOWNLOADSREAD
            )
          "
          style="margin-right: -5px"
        >
          <img src="assets/sidebar-downloads.svg" />
          <a>Product downloads</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/finances/lump-sum"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.FINDISCOUNTREAD)"
        >
          <img src="assets/sidebar-invoices.svg" />
          <a>Pregled paušala</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/finances/administration/calculations"
          routerLinkActive="active"
          *ngIf="
            userPermissions.includes(UserPermissionsEnum.FINADMINISTRATIONREAD)
          "
        >
          <img src="assets/sidebar-iptc.svg" />
          <a>Administration</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/finances/invoices"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.FININVOICESREAD)"
        >
          <img src="assets/sidebar-invoices.svg" />
          <a>Invoices</a>
        </li>

        <li
          class="sidebar-nav-item sidebar-nav-item-icon"
          routerLink="/cms/finances/currencies"
          routerLinkActive="active"
          *ngIf="userPermissions.includes(UserPermissionsEnum.FININVOICESREAD)"
        >
          <a>Currencies</a>
        </li>
      </ul>
    </li>

    <!--<li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isReportsExpanded"
      routerLinkActive="active"
    >
      <img
        src="assets/sidebar-nav-expand.svg"
        class="expand"
        style="filter: opacity(60%); touch-action: none; cursor: not-allowed"
      />
      <a style="filter: opacity(60%); touch-action: none; cursor: not-allowed"
        >Reports</a
      >
    </li>

    <li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isSaleExpanded"
      routerLinkActive="active"
    >
      <img
        src="assets/sidebar-nav-expand.svg"
        class="expand"
        style="filter: opacity(60%); touch-action: none; cursor: not-allowed"
      />
      <a style="filter: opacity(60%); touch-action: none; cursor: not-allowed"
        >Sale</a
      >
    </li>

    <li
      class="sidebar-nav-item sidebar-nav-item-nested"
      [class.open]="isCalculationExpanded"
      routerLinkActive="active"
    >
      <img
        src="assets/sidebar-nav-expand.svg"
        class="expand"
        style="filter: opacity(60%); touch-action: none; cursor: not-allowed"
      />
      <a style="filter: opacity(60%); touch-action: none; cursor: not-allowed"
        >Calculation</a
      >
    </li>-->

    <li
      class="sidebar-nav-item"
      routerLink="/cms/app_settings"
      routerLinkActive="active"
      *ngIf="userPermissions.includes(UserPermissionsEnum.SUPERADMIN)"
    >
      <a>App Settings</a>
    </li>
  </ul>
</div>
