/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AgencyIpctTag {
  id?: number;
  iptc_tag?: string;
  text?: string;
  value?: AgencyIpctTag.ValueEnum;
}
export namespace AgencyIpctTag {
  export type ValueEnum = 'REPLACE' | 'APPEND' | 'PREPEND';
  export const ValueEnum = {
    REPLACE: 'REPLACE' as ValueEnum,
    APPEND: 'APPEND' as ValueEnum,
    PREPEND: 'PREPEND' as ValueEnum,
  };
}
