<div class="title">{{ title }}</div>

<div
  class="checkbox"
  *ngFor="let control of controlNameArray; index as i"
  [formGroup]="form"
>
  <label for="{{ control }}">
    <input
      type="checkbox"
      name="{{ control }}"
      id="{{ control }}"
      formControlName="{{ control }}"
    />
    <div class="checkbox-custom"></div>
    {{ labelArray[i] }}
  </label>
</div>
