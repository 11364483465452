<div class="company__header">
  <a *ngIf="isNavigateBackEnabled" (click)="navigateBack()">
    <img src="/assets/back-arrow-big.svg" alt="" />
  </a>
  <h1 class="company__name" [class.no-navigation]="!isNavigateBackEnabled">
    {{ headerText }}
  </h1>
  <ul class="company__nav">
    <li *ngFor="let tab of tabs">
      <a routerLink="{{ tab.routerLink }}" routerLinkActive="active">
        <span>{{ tab.tabText }}</span>
      </a>
    </li>
  </ul>
</div>
