import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ImageSize } from '../../cms/import/main-preview/main-preview.component';
import { ImportItem } from '../../cms/import/import.component';
import { environment } from 'src/app/shared/environment';
import { HttpClient } from '@angular/common/http';
import { GalleriesService } from '../../cms/content/galleries/galleries.service';
import { ProductsService } from '../../cms/content/products/products.service';
import { LoadingService } from '../loading.service';
import { SnackBarService } from '../../share/snack-bar.service';

@Component({
  selector: 'app-main-preview-item',
  templateUrl: './main-preview-item.component.html',
  styleUrls: ['./main-preview-item.component.scss'],
})
export class MainPreviewItemComponent implements OnInit, OnChanges {
  @Input() importItem?: ImportItem;
  @Input() isVideo: boolean = false;
  @Input() loadingPreviewUrl?: string;
  @Input() previewUrl?: string;
  @Input() largePreviewUrl?: string;
  @Input() isLocked?: boolean;
  @Input() lockedBy?: string;
  @Input() isError?: boolean;
  @Input() videoPreviewUrl?: string;

  isPreviewImageLoaded: boolean = false;

  @ViewChild('videoPlayer') videoPlayer?: ElementRef<HTMLVideoElement>;

  @Input() canDownload: boolean = false;
  @Input() canSetAsGalleryHead: boolean = false;

  @Input() imageSize: ImageSize = ImageSize.Normal;

  @Output() onImageLoaded: EventEmitter<{
    item: ImportItem;
    aspectRatio: number;
  }> = new EventEmitter<{ item: ImportItem; aspectRatio: number }>();
  @Output() onPreviewImageLoaded: EventEmitter<{
    item: ImportItem;
    aspectRatio: number;
  }> = new EventEmitter<{ item: ImportItem; aspectRatio: number }>();

  imagePreviewUrl?: string;
  imageLoadingPreviewUrl?: string;

  imageLoaded: boolean = false;
  isImageError: boolean = false;

  shouldLoadBlur: boolean = false;

  constructor(
    private http: HttpClient,
    private productsService: ProductsService,
    private galleriesService: GalleriesService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.videoPreviewUrl = this.getVideoPreviewUrl();
    this.imagePreviewUrl = this.getPreviewUrl();
    this.imageLoadingPreviewUrl = this.getLoadingUrl();

    if (this.imageLoadingPreviewUrl.includes('https://')) {
      this.shouldLoadBlur = false;
    }
  }

  buildUrl(url: string | undefined): string {
    if (url == undefined) return '';

    //if the url starts with assets then it is a local img and it should just return the url
    if (this.previewUrl?.split('/')[1] === 'assets') return this.previewUrl;

    if (url.includes('https://') || url.includes('http://')) {
      return url;
    }

    return environment.productServingUrl + url;
  }

  getPreviewUrl(): string {
    switch (this.imageSize) {
      case ImageSize.Normal:
        return this.buildUrl(this.previewUrl);
      case ImageSize.Large:
        return this.buildUrl(this.largePreviewUrl);
    }
  }

  getDownloadUrl(): string {
    return this.buildUrl(this.largePreviewUrl);
  }

  getLoadingUrl(): string {
    return this.buildUrl(this.loadingPreviewUrl);
  }

  loadedPreviewImage($event: any) {
    this.isPreviewImageLoaded = true;
    let target: any = $event.target;
    let aspectRatio: number = target.naturalWidth / target.naturalHeight;

    this.onPreviewImageLoaded.emit({
      item: this.importItem!,
      aspectRatio: aspectRatio,
    });
  }

  loadedVideoPreviewImage($event: any) {
    let target: any = $event.target;
    let aspectRatio: number = target.naturalWidth / target.naturalHeight;

    this.onPreviewImageLoaded.emit({
      item: this.importItem!,
      aspectRatio: aspectRatio,
    });
  }

  loadedImage($event: any) {
    this.imageLoaded = true;

    let target: any = $event.target;
    let aspectRatio: number = target.naturalWidth / target.naturalHeight;

    this.onImageLoaded.emit({
      item: this.importItem!,
      aspectRatio: aspectRatio,
    });
  }

  loadedVideo($event: any) {
    this.videoPlayer?.nativeElement.pause();
  }

  erroredImage($event: any) {
    this.isImageError = true;
  }

  getVideoPreviewUrl(): string {
    if (!this.videoPreviewUrl) return '';

    return environment.productServingUrl + `${this.videoPreviewUrl}`;
  }

  setAsGalleryHead($event: MouseEvent) {
    $event.preventDefault();

    this.galleriesService.setAsGalleryHead(this.importItem!);
  }

  videoOver() {
    this.videoPlayer?.nativeElement.play();
  }

  videoOut() {
    this.videoPlayer?.nativeElement.pause();
  }

  /*
   * DOWNLOADING
   */

  download($event: MouseEvent) {
    $event.preventDefault();
    this.productsService.downloadFileForCms(
      'PRODUCT.jpeg',
      this.importItem!.id
    );
  }
}
