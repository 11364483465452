export class KeyboardShortcutsUtils {
  private static isMacintosh() {
    return navigator.platform.indexOf('Mac') > -1;
  }

  public static isCtrlOrCmdPressed($event: MouseEvent) {
    if (this.isMacintosh()) {
      return $event.metaKey;
    }

    return $event.ctrlKey;
  }
}
