import { AutocompleteOption } from '../field-text/field-text.component';
import { Observable, Subject } from 'rxjs';

export enum FieldType {
  String,
  Boolean,
  Number,
  Autocomplete,
  MultipleAutocomplete,
  Date,
}

export type Field = {
  name: string;
  fieldType: FieldType;
};
export const FIELD_ID: Field = {
  name: 'id',
  fieldType: FieldType.String,
};
export const FIELD_HEADLINE: Field = {
  name: 'headline',
  fieldType: FieldType.Autocomplete,
};
export const FIELD_HEADLINE_EN: Field = {
  name: 'headlineEn',
  fieldType: FieldType.Autocomplete,
};
export const FIELD_CAPTION: Field = {
  name: 'caption',
  fieldType: FieldType.String,
};
export const FIELD_VIDEO_TITLE: Field = {
  name: 'videoTitle',
  fieldType: FieldType.String,
};
export const FIELD_PEOPLE: Field = {
  name: 'people',
  fieldType: FieldType.MultipleAutocomplete,
};
export const FIELD_KEYWORDS: Field = {
  name: 'keywords',
  fieldType: FieldType.MultipleAutocomplete,
};
export const FIELD_CATEGORIES: Field = {
  name: 'categories',
  fieldType: FieldType.Autocomplete,
};
export const FIELD_AUTHOR: Field = {
  name: 'author',
  fieldType: FieldType.Autocomplete,
};
export const FIELD_AGENCY: Field = {
  name: 'agency',
  fieldType: FieldType.Autocomplete,
};
export const FIELD_TAG: Field = {
  name: 'tag',
  fieldType: FieldType.Autocomplete,
};
export const FIELD_FEATURED: Field = {
  name: 'featured',
  fieldType: FieldType.Boolean,
};
export const FIELD_DIGITAL_EXHIBITION: Field = {
  name: 'digitalExhibition',
  fieldType: FieldType.Boolean,
};
export const FIELD_IS_FREE: Field = {
  name: 'isFree',
  fieldType: FieldType.Boolean,
};
export const FIELD_SPECIAL_OFFER: Field = {
  name: 'specialOffer',
  fieldType: FieldType.Boolean,
};
export const FIELD_SPECIAL_OFFER_TYPE: Field = {
  name: 'specialOfferTypes',
  fieldType: FieldType.MultipleAutocomplete,
};
export const FIELD_API_FTP: Field = {
  name: 'apiFtp',
  fieldType: FieldType.Boolean,
};
export const FIELD_LICENCE: Field = {
  name: 'licence',
  fieldType: FieldType.Autocomplete,
};
export const FIELD_DATE_CREATED: Field = {
  name: 'dateCreated',
  fieldType: FieldType.String,
};
export const FIELD_DATE_PUBLISHED: Field = {
  name: 'publishDate',
  fieldType: FieldType.Date,
};
export const FIELD_DATE_PUBLISHED_V2: Field = {
  name: 'publishDate',
  fieldType: FieldType.Date,
};
export const FIELD_DATE_IMPORTED: Field = {
  name: 'dateImported',
  fieldType: FieldType.String,
};
export const FIELD_NEEDS_TRANSLATION_EN: Field = {
  name: 'needsTranslationEn',
  fieldType: FieldType.Boolean,
};
export const FIELD_CAPTION_EN: Field = {
  name: 'captionEn',
  fieldType: FieldType.String,
};
export const FIELD_KEYWORDS_EN: Field = {
  name: 'keywordsEn',
  fieldType: FieldType.MultipleAutocomplete,
};
export const FIELD_PRINT_PRICE: Field = {
  name: 'printPrice',
  fieldType: FieldType.Number,
};
export const FIELD_WEB_PRICE: Field = {
  name: 'webPrice',
  fieldType: FieldType.Number,
};
export const FIELD_PRINT_PRICE_EUR: Field = {
  name: 'printPriceEur',
  fieldType: FieldType.Number,
};
export const FIELD_WEB_PRICE_EUR: Field = {
  name: 'webPriceEur',
  fieldType: FieldType.Number,
};

export const FIELD_ARTICLE_TITLE: Field = {
  name: 'articleTitle',
  fieldType: FieldType.String,
};
export const FIELD_ARTICLE_AUTHOR: Field = {
  name: 'articleAuthor',
  fieldType: FieldType.Autocomplete,
};
export const FIELD_ARTICLE_CONTENT: Field = {
  name: 'articleContent',
  fieldType: FieldType.String,
};

export const FIELDS: Field[] = [
  FIELD_ID,
  FIELD_HEADLINE,
  FIELD_HEADLINE_EN,
  FIELD_CAPTION,
  FIELD_PEOPLE,
  FIELD_KEYWORDS,
  FIELD_CATEGORIES,
  FIELD_AUTHOR,
  FIELD_AGENCY,
  FIELD_TAG,
  FIELD_FEATURED,
  FIELD_DIGITAL_EXHIBITION,
  FIELD_SPECIAL_OFFER,
  FIELD_SPECIAL_OFFER_TYPE,
  FIELD_API_FTP,
  FIELD_LICENCE,
  FIELD_DATE_CREATED,
  FIELD_NEEDS_TRANSLATION_EN,
  FIELD_CAPTION_EN,
  FIELD_KEYWORDS_EN,
  FIELD_PRINT_PRICE,
  FIELD_WEB_PRICE,
  FIELD_ARTICLE_TITLE,
  FIELD_ARTICLE_AUTHOR,
  FIELD_ARTICLE_CONTENT,
  FIELD_DATE_PUBLISHED,
  FIELD_DATE_IMPORTED,
  FIELD_VIDEO_TITLE,
  FIELD_PRINT_PRICE_EUR,
  FIELD_WEB_PRICE_EUR,
];

interface Idable {
  id: number;
}

export class MultipleItemEditorService<T extends Idable> {
  protected selectedIds: number[] = [];
  protected items: T[] = [];
  protected itemsById: { [key: number]: T } = {};
  private editingSuffixSource = new Subject<string | undefined>();
  public editingSuffixData: Observable<string | undefined> =
    this.editingSuffixSource.asObservable();

  protected isLoadedSource = new Subject<boolean>();
  protected isLoadedData: Observable<boolean> =
    this.isLoadedSource.asObservable();

  protected getIsLoaded(): Observable<boolean> {
    return this.isLoadedData;
  }

  getSuffixForCaption() {
    const author = this.computeAutocompleteFieldValue((i: any) => i.author);
    const agency = this.computeAutocompleteFieldValue((i: any) => i.agency);
    const people = this.computeMultipleAutocompleteFieldValue(
      (i: any) => i.people
    );

    //console.log("getSuffixForCaption", author, agency, people);

    if (
      author.value == -3 ||
      agency.value == -3 ||
      people.find((e) => e.value == -3) != undefined
    ) {
      return '<multiple values>';
    }

    if (
      author?.title?.length != 0 &&
      agency?.title?.length != 0 &&
      author?.value != -1 &&
      agency?.value != -1
    ) {
      let _author = author!.title;
      let _agency = agency!.title;
      let _people = people?.map((p) => p.title).join(', ');
      if (_people?.length != 0) {
        _people = _people + '\n';
      } else {
        _people = '';
      }
      return `${_people}Photo: ${_author}/${_agency}`;
    }

    return 'This will be calculated automatically.';
  }

  calculateSuffix() {
    const suffix = this.getSuffixForCaption();
    this.editingSuffixSource.next(suffix);
  }

  getFirstFromArray<T>(arr: T[] | undefined): T | undefined {
    if (arr == undefined || arr.length == 0) return undefined;

    return arr[0];
  }

  parseOptionalInteger(str: string | undefined): number | undefined {
    if (str == undefined) return undefined;

    return parseInt(str);
  }

  computeMultipleAutocompleteFieldValue(
    selector: (arg0: T) => AutocompleteOption[] | undefined
  ): AutocompleteOption[] {
    // No selection case.
    if (this.selectedIds.length == 0) return []; // No value

    if (this.selectedIds.length == 1) {
      let item = this.getItemById(this.selectedIds[0]);
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return []; // No value
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.getItemById(id));
    let values: string[] = selectedItems.map(
      (item) =>
        selector(item)
          ?.map((t) => t.title ?? '')
          .join(', ') ?? ''
    );

    let allEqualFunc = (arr: string[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let item = this.getItemById(this.selectedIds[0]);
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return [];
    } else {
      return [{ value: -3, title: '<multiple values>', isSelectable: false }]; // Multiple values.
    }
  }

  computeAutocompleteFieldValue(
    selector: (arg0: T) => AutocompleteOption | undefined
  ): AutocompleteOption {
    // No selection case.
    if (this.selectedIds.length == 0)
      return { title: '', value: -1, isSelectable: false };

    if (this.selectedIds.length == 1) {
      let item = this.itemsById[this.selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return { title: '', value: -1, isSelectable: false };
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.itemsById[id]);
    let values: (string | undefined)[] = selectedItems.map(
      (item) => selector(item)?.title
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return selector(selectedItems[0])!;

      return { title: '', value: -1, isSelectable: false };
    } else {
      return { title: '<multiple values>', value: -3, isSelectable: false };
    }
  }

  computeFieldValue(selector: (arg0: T) => string | undefined): string {
    // No selection case.
    if (this.selectedIds.length == 0) return '';

    if (this.selectedIds.length == 1) {
      let item = this.itemsById[this.selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return '';
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.itemsById[id]);
    let values: (string | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return '';
    } else {
      return '<multiple values>';
    }
  }

  computeDateFieldValue(selector: (arg0: T) => string | undefined): string {
    // No selection case.
    if (this.selectedIds.length == 0) return '';

    if (this.selectedIds.length == 1) {
      let item = this.itemsById[this.selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return '';
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.itemsById[id]);
    let values: (string | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return '';
    } else {
      return '';
    }
  }

  computeBooleanFieldValue(
    selector: (arg0: T) => boolean | undefined
  ): boolean {
    // No selection case.
    if (this.selectedIds.length == 0) return false;

    if (this.selectedIds.length == 1) {
      let item = this.itemsById[this.selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return false;
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.itemsById[id]);
    let values: (boolean | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return false;
    } else {
      // TODO: Not true or false!
      return true;
    }
  }

  computeNumberFieldValue(selector: (arg0: T) => number | undefined): number {
    // No selection case.
    if (this.selectedIds.length == 0) return 0.0;

    if (this.selectedIds.length == 1) {
      let item = this.itemsById[this.selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return 0.0;
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.itemsById[id]);
    let values: (number | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return 0.0;
    } else {
      // TODO: Not true or false!
      return 0.0;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Added items
  //////////////////////////////////////////////////////////////////////////////

  public addedItems: T[] = [];
  private addedItemsSource = new Subject<T[]>();
  public addedItemsData: Observable<T[]> = this.addedItemsSource.asObservable();

  public addItem(item: T) {
    if (!this.addedItems.includes(item)) {
      this.addedItems.push(item);
      this.addedItemsSource.next(this.addedItems);
    }
  }

  public clearAddedItems() {
    this.addedItems = [];
    this.addedItemsSource.next(this.addedItems);
  }

  public removeAddedItem(item: T) {
    this.addedItems = this.addedItems.filter(function (i) {
      return item !== i;
    });
    this.addedItemsSource.next(this.addedItems);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Items
  //////////////////////////////////////////////////////////////////////////////

  getItems(): T[] {
    return this.items;
  }

  setItems(items: T[]) {
    this.items = items;

    this.setItemsById({});
    this.items.forEach((item) => {
      this.setItemById(item.id, item);
    });
  }

  // --------------------------------

  getItemsById(): { [key: number]: T } {
    return this.itemsById;
  }

  setItemsById(items: { [key: number]: T }) {
    this.itemsById = items;
  }

  // --------------------------------

  clearItems(): void {
    this.setItems([]);
    this.setItemsById({});
  }

  // --------------------------------

  getItemById(id: number): T {
    if (this.getItemsById()[id] == undefined) {
    }

    return this.getItemsById()[id];
  }

  setItemById(id: number, item: T) {
    this.getItemsById()[item.id] = item;
  }

  deleteItem(ids: number[]) {
    this.setItems(this.getItems().filter((i) => !ids.includes(i.id)));
    ids.forEach((id: number) => {
      delete this.getItemsById()[id];
    });
  }
}
