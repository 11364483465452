import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor(private http: HttpClient) {}

  patchData(endpoint: string, id: number, body: any): Observable<any> {
    const url = `https://api.piwe.dev.smartcode.eu/mock/${endpoint}/${id}`;
    return this.http.patch(url, body);
  }
  postData(endpoint: string, body: any): Observable<any> {
    const url = `https://api.piwe.dev.smartcode.eu/mock/${endpoint}`;
    return this.http.post(url, body);
  }
}
