<div class="grid" #grid>
  <div
    *ngFor="let i of numberArray"
    class="grid-element{{ blockVariant }}-{{ i }}"
  >
    <button
      (dblclick)="handleDoubleClick(i - 1)"
      *ngIf="pagebuilderMode"
      class="grid-btn"
      [class.clicked]="
        isDoubleClicked &&
        doubleClickedIndex === i - 1 &&
        isSelectedInsideThisBlock
      "
      [class.empty]="
        !blockData.products![i - 1] && doubleClickedIndex !== i - 1
      "
      [class.empty-selected-outside]="
        !blockData.products![i - 1] &&
        doubleClickedIndex !== i - 1 &&
        !isThisBlockSelected &&
        !isBeingHovered
      "
      [@selectDeselect]="
        isDoubleClicked && doubleClickedIndex === i - 1
          ? 'selected'
          : 'deselected'
      "
    >
      <app-single-image
        *ngIf="blockData.products![i - 1] !== null"
        class="image"
        [imageLoadingPreviewUrl]="
          (blockData.products![i - 1]?.preview)![0].preview_url_blur!
        "
        [imagePreviewUrl]="
          (blockData.products![i - 1]?.preview)![0].preview_url_large!
        "
        (onImageLoaded)="onImageLoad($event)"
      >
      </app-single-image>
      <app-single-image
        *ngIf="blockData.products![i - 1] === null"
        class="image"
        [imageLoadingPreviewUrl]="noImgUrl"
        [imagePreviewUrl]="noImgUrl"
        (onImageLoaded)="onImageLoad($event)"
      >
      </app-single-image>
    </button>

    <a
      class="link-block"
      [href]="
        pagebuilderMode ? undefined : hasCustomUrl(blockData.products![i - 1])
      "
      *ngIf="!pagebuilderMode"
    >
      <app-single-image
        *ngIf="blockData.products![i - 1] !== null"
        class="image"
        [imageLoadingPreviewUrl]="
          (blockData.products![i - 1]?.preview)![0].preview_url_blur!
        "
        [imagePreviewUrl]="
          calculateUrl(
            (blockData.products![i - 1]?.preview)![0].preview_url_large!
          )
        "
        (onImageLoaded)="onImageLoad($event)"
      >
      </app-single-image>
      <app-single-image
        *ngIf="blockData.products![i - 1] === null"
        class="image"
        [imageLoadingPreviewUrl]="noImgUrl"
        [imagePreviewUrl]="noImgUrl"
        (onImageLoaded)="onImageLoad($event)"
      >
      </app-single-image>

      <div class="overlay">
        <div class="overlay-text">
          <div class="overlay-text-top">
            {{
              blockData.products![i - 1]?.timestamp | date : "dd.MM.yyyy. HH:mm"
            }}
          </div>
          <div class="overlay-text-bottom">
            {{ headlineArray[i - 1] }}
          </div>
        </div>
        <!-- <button></button> -->
      </div>
    </a>
  </div>
</div>
