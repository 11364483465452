<div class="call">
  <div class="call__container">
    <div class="call__left">
      <app-section-button
        [pagebuilderMode]="pagebuilderMode"
        (doubleClickEvent)="handleImageDoubleClick(0)"
        [isSelected]="doubleClickIndex === 0"
      >
        <app-single-image
          *ngIf="blockData.products![0] !== null"
          class="image"
          [imageLoadingPreviewUrl]="
            blockData.products![0].preview![0].preview_url_blur!
          "
          [imagePreviewUrl]="blockData.products![0].preview![0].preview_url!"
        >
        </app-single-image>
        <app-single-image
          *ngIf="blockData.products![0] === null"
          class="image"
          [imageLoadingPreviewUrl]="noImgUrl"
          [imagePreviewUrl]="noImgUrl"
        >
        </app-single-image>
      </app-section-button>
    </div>
    <app-section-button
      [pagebuilderMode]="pagebuilderMode"
      (doubleClickEvent)="handleTextDoubleClick(1)"
      [isSelected]="doubleClickIndex === 1"
    >
      <div class="call__right">
        <h1>Photo & Video usluge snimanja po narudžbi</h1>

        <p>
          Iza svakog proizvoda, portreta i editorijala krije se priča koju
          Pixsell prepričava savršenim kadrovima.
          <br />
          <br />
          Snimali smo brojne događaje koji su obilježili Hrvatsku: Weekend Media
          Festival, Dane komunikacija, ATP UMAG, Balkan Photo Festival, Svečano
          obilježavanje pristupanja Hrvatske u EU, Svečani mimohod oružanih
          snaga RH, Ponos Hrvatske, Večernjakovu ružu... ali bili smo i partneri
          mnogim korporativnim klijentima čije priče i proizvode prenosimo na
          najbolji mogući način – fotografijom i videom.
          <br />
          <br />
          Možemo snimiti svaki događaj ili temu u Hrvatskoj i regiji prema
          uputama i zahtjevima klijenta. Snimljene fotografije isporučujemo u
          najkraćem roku, a dio sadržaja potreban za brzu objavu isporučujemo za
          vrijeme događaja. Snimanje portreta, lokacija, poslovnih prostora,
          raznih konferencija i seminara, natjecanja ili ilustrativnih
          fotografija samo su dio onoga što nudimo korisnicima.
          <br />
          <br />
          Dovoljno je da nam javite gdje, kada i što želite snimiti i naš tim će
          pripremiti ponudu koju ne možete odbiti.
        </p>

        <a href="" class="call__link">
          Kontaktirajte nas
          <img src="/assets/front-home/more.svg" alt="" />
        </a>
      </div>
    </app-section-button>
  </div>
</div>
