/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BlockTypeSection {
  id?: BlockTypeSection.IdEnum;
  variation?: BlockTypeSection.VariationEnum;
}
export namespace BlockTypeSection {
  export type IdEnum = 1 | 2 | 3 | 4 | 5 | 6;
  export const IdEnum = {
    NUMBER_1: 1 as IdEnum,
    NUMBER_2: 2 as IdEnum,
    NUMBER_3: 3 as IdEnum,
    NUMBER_4: 4 as IdEnum,
    NUMBER_5: 5 as IdEnum,
    NUMBER_6: 6 as IdEnum,
  };
  export type VariationEnum = 'section';
  export const VariationEnum = {
    Section: 'section' as VariationEnum,
  };
}
