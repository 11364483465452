/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AutocompleteItem } from '../model/autocompleteItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class AutocompleteService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Fetch autocomplete suggestions for agency by their names
   * @param title Title we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteAgenciesByName(
    title: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteAgenciesByName(
    title: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteAgenciesByName(
    title: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteAgenciesByName(
    title: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteAgenciesByName.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/agencies_by_name`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for agency groups by their names
   * @param title Title we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteAgencyGroupsByName(
    title: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteAgencyGroupsByName(
    title: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteAgencyGroupsByName(
    title: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteAgencyGroupsByName(
    title: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteAgencyGroupsByName.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/agency_groups_by_name`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for authors by their names
   * @param title Title we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteAuthorsByName(
    title: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteAuthorsByName(
    title: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteAuthorsByName(
    title: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteAuthorsByName(
    title: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteAuthorsByName.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/authors_by_name`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for categories by their titles
   * @param title Title we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteCategoriesByTitle(
    title: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteCategoriesByTitle(
    title: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteCategoriesByTitle(
    title: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteCategoriesByTitle(
    title: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteCategoriesByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/categories_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for companies by their name
   * @param query Query we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteCompaniesByTitle(
    query: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteCompaniesByTitle(
    query: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteCompaniesByTitle(
    query: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteCompaniesByTitle(
    query: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (query === null || query === undefined) {
      throw new Error(
        'Required parameter query was null or undefined when calling cmsGetAutocompleteCompaniesByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/companies_by_name`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for contracts by their names
   * @param name Name we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteContractsByName(
    name: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteContractsByName(
    name: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteContractsByName(
    name: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteContractsByName(
    name: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling cmsGetAutocompleteContractsByName.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set('name', <any>name);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/contracts_by_name`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for countries by their name
   * @param title Title we&#x27;re filtering autocomplete by
   * @param language Language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteCountryByTitle(
    title: string,
    language: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteCountryByTitle(
    title: string,
    language: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteCountryByTitle(
    title: string,
    language: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteCountryByTitle(
    title: string,
    language: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteCountryByTitle.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling cmsGetAutocompleteCountryByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/country_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for currencies by their name
   * @param title Title we&#x27;re filtering autocomplete by
   * @param language Language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteCurrencyByTitle(
    title: string,
    language: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteCurrencyByTitle(
    title: string,
    language: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteCurrencyByTitle(
    title: string,
    language: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteCurrencyByTitle(
    title: string,
    language: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteCurrencyByTitle.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling cmsGetAutocompleteCurrencyByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/currency_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for galleries by their titles
   * @param query Query we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteGalleriesByTitle(
    query: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteGalleriesByTitle(
    query: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteGalleriesByTitle(
    query: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteGalleriesByTitle(
    query: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (query === null || query === undefined) {
      throw new Error(
        'Required parameter query was null or undefined when calling cmsGetAutocompleteGalleriesByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/galleries_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for keywords by their title
   * @param title Title we&#x27;re filtering autocomplete by
   * @param language Language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteKeywordsByTitle(
    title: string,
    language: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteKeywordsByTitle(
    title: string,
    language: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteKeywordsByTitle(
    title: string,
    language: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteKeywordsByTitle(
    title: string,
    language: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteKeywordsByTitle.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling cmsGetAutocompleteKeywordsByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/keywords_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for people by their names
   * @param query Query we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompletePeopleByName(
    query: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompletePeopleByName(
    query: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompletePeopleByName(
    query: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompletePeopleByName(
    query: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (query === null || query === undefined) {
      throw new Error(
        'Required parameter query was null or undefined when calling cmsGetAutocompletePeopleByName.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/people_by_names`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for special offer type by their names
   * @param title Title we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteSpecialOfferTypesByName(
    title: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteSpecialOfferTypesByName(
    title: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteSpecialOfferTypesByName(
    title: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteSpecialOfferTypesByName(
    title: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteSpecialOfferTypesByName.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/special_offer_types_by_name`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for tag by their title
   * @param title Title we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteTagsByTitle(
    title: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteTagsByTitle(
    title: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteTagsByTitle(
    title: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteTagsByTitle(
    title: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteTagsByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/tags_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for user groups by their name
   * @param title Title we&#x27;re filtering autocomplete by
   * @param language Language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteUserGroupByTitle(
    title: string,
    language: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteUserGroupByTitle(
    title: string,
    language: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteUserGroupByTitle(
    title: string,
    language: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteUserGroupByTitle(
    title: string,
    language: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling cmsGetAutocompleteUserGroupByTitle.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling cmsGetAutocompleteUserGroupByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/user_group_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for users by their name
   * @param query Query we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetAutocompleteUsersByName(
    query: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public cmsGetAutocompleteUsersByName(
    query: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteUsersByName(
    query: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public cmsGetAutocompleteUsersByName(
    query: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (query === null || query === undefined) {
      throw new Error(
        'Required parameter query was null or undefined when calling cmsGetAutocompleteUsersByName.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/cms/autocomplete/users_by_name`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for authors by their names
   * @param title Title we&#x27;re filtering autocomplete by
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetAutocompleteAuthorsByName(
    title: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public frontGetAutocompleteAuthorsByName(
    title: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public frontGetAutocompleteAuthorsByName(
    title: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public frontGetAutocompleteAuthorsByName(
    title: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling frontGetAutocompleteAuthorsByName.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/autocomplete/authors_by_name`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for countries by their name
   * @param title Title we&#x27;re filtering autocomplete by
   * @param language Language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetAutocompleteCountryByTitle(
    title: string,
    language: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public frontGetAutocompleteCountryByTitle(
    title: string,
    language: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public frontGetAutocompleteCountryByTitle(
    title: string,
    language: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public frontGetAutocompleteCountryByTitle(
    title: string,
    language: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling frontGetAutocompleteCountryByTitle.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling frontGetAutocompleteCountryByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/autocomplete/country_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Fetch autocomplete suggestions for currencies by their name
   * @param title Title we&#x27;re filtering autocomplete by
   * @param language Language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetAutocompleteCurrencyByTitle(
    title: string,
    language: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<AutocompleteItem>>;
  public frontGetAutocompleteCurrencyByTitle(
    title: string,
    language: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<AutocompleteItem>>>;
  public frontGetAutocompleteCurrencyByTitle(
    title: string,
    language: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<AutocompleteItem>>>;
  public frontGetAutocompleteCurrencyByTitle(
    title: string,
    language: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (title === null || title === undefined) {
      throw new Error(
        'Required parameter title was null or undefined when calling frontGetAutocompleteCurrencyByTitle.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling frontGetAutocompleteCurrencyByTitle.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<AutocompleteItem>>(
      'get',
      `${this.basePath}/autocomplete/currency_by_title`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
