<div class="slide">
  <img src="{{ previewUrl }}" alt="" />
  <div class="overlay">
    <a class="text" routerLink="{{ link }}">
      <div class="title"><ng-content></ng-content></div>
      <div class="link">
        <span [innerHTML]="'about_us.service_scroll_show_more' | translate"
          >Prikaži primjere</span
        >
        <img src="/assets/about-us/right-arrow.svg" alt="" />
      </div>
    </a>
  </div>
</div>
