<div [ngSwitch]="sectionVariant">
  <div *ngSwitchCase="1">
    <app-section
      [sectionVariant]="1"
      (blockDoubleClicked)="handleDoubleClick($event)"
      [isSelectedInsideThisBlock]="isSelectedInsideThisBlock"
      [blockData]="blockData"
      [pagebuilderMode]="pagebuilderMode"
    ></app-section>
  </div>
  <div *ngSwitchCase="2">
    <app-section
      [sectionVariant]="2"
      (blockDoubleClicked)="handleDoubleClick($event)"
      [isSelectedInsideThisBlock]="isSelectedInsideThisBlock"
      [blockData]="blockData"
      [pagebuilderMode]="pagebuilderMode"
    ></app-section>
  </div>
  <div *ngSwitchCase="3">
    <app-section
      [sectionVariant]="3"
      (blockDoubleClicked)="handleDoubleClick($event)"
      [isSelectedInsideThisBlock]="isSelectedInsideThisBlock"
      [blockData]="blockData"
      [pagebuilderMode]="pagebuilderMode"
    ></app-section>
  </div>
  <div *ngSwitchCase="4">
    <app-section
      [sectionVariant]="4"
      (blockDoubleClicked)="handleDoubleClick($event)"
      [isSelectedInsideThisBlock]="isSelectedInsideThisBlock"
      [blockData]="blockData"
      [pagebuilderMode]="pagebuilderMode"
    ></app-section>
  </div>
  <div *ngSwitchCase="5">
    <app-section
      [sectionVariant]="5"
      (blockDoubleClicked)="handleDoubleClick($event)"
      [isSelectedInsideThisBlock]="isSelectedInsideThisBlock"
      [blockData]="blockData"
      [pagebuilderMode]="pagebuilderMode"
    ></app-section>
  </div>
  <div *ngSwitchCase="6">
    <app-homepage-section-contact
      (blockDoubleClicked)="handleDoubleClick($event)"
      [isSelectedInsideThisBlock]="isSelectedInsideThisBlock"
      [blockData]="blockData"
      [pagebuilderMode]="pagebuilderMode"
    ></app-homepage-section-contact>
  </div>
</div>
