<ng-container>
  <img
    *ngIf="!imageLoaded && !isImageError"
    [src]="imageLoadingPreviewUrl"
    (error)="erroredImage($event)"
    (load)="loadedPreviewImage($event)"
  />
  <img
    class="main-image"
    [class.loaded]="imageLoaded"
    *ngIf="!isImageError"
    [src]="imagePreviewUrl"
    (error)="erroredImage($event)"
    (load)="loadedImage($event)"
  />
</ng-container>
