<div class="main">
  <div class="main-div">
    <div class="content">
      <h1 [innerHTML]="'500.title' | translate">Stranica nije pronađena</h1>

      <div class="body" [innerHTML]="'500.body' | translate">
        Došlo je do greške na poslužitelju. Molimo pokušajte ponovo.
      </div>

      <button class="btn" routerLink="/">
        <img class="icon" src="/assets/back-white-error.svg" alt="" />
        {{ "500.button" | translate }}
      </button>
    </div>
  </div>

  <div class="login-img"></div>
</div>
