/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CartPaymentRequest {
  payment_method?: CartPaymentRequest.PaymentMethodEnum;
}
export namespace CartPaymentRequest {
  export type PaymentMethodEnum = 'CARD' | 'KEKS_PAY' | 'PAYPAL' | 'DOWNLOAD';
  export const PaymentMethodEnum = {
    CARD: 'CARD' as PaymentMethodEnum,
    KEKSPAY: 'KEKS_PAY' as PaymentMethodEnum,
    PAYPAL: 'PAYPAL' as PaymentMethodEnum,
    DOWNLOAD: 'DOWNLOAD' as PaymentMethodEnum,
  };
}
