<app-app-navbar></app-app-navbar>

<div class="app-container">
  <app-app-sidebar></app-app-sidebar>
  <div class="app-router-outlet-container">
    <router-outlet></router-outlet>
  </div>
</div>

<app-modal
  *ngIf="iptcValue"
  title="IPTC"
  primaryButtonText="Cancel"
  [isSecondaryButtonHidden]="true"
  (saveModalData)="closeIptc()"
  @quickOpenClose
>
  <div style="height: 300px; overflow-y: scroll" [innerHTML]="iptcValue"></div>
  <div style="height: 30px; width: 500px"></div>
</app-modal>
