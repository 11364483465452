import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductPreview } from 'piwe-front-swagger-client';
import {
  HomepageBlockProductPreview,
  Translatable,
} from 'projects/piwe-front-swagger-client/src';
import { environment } from 'src/app/shared/environment';
import { HomepageCreatorDataService } from '../../homepage-creator-data.service';
import { SnackBarService } from '../../../../../../share/snack-bar.service';
import { LoadingService } from '../../../../../../shared/loading.service';

export enum EditModeTypes {
  Null = 0,
  ImageEditor = 1,
  TextEditor,
  BlockEditor,
}

@Component({
  selector: 'app-sidebar-page-builder-edit',
  templateUrl: './sidebar-page-builder-edit.component.html',
  styleUrls: ['./sidebar-page-builder-edit.component.scss'],
})
export class SidebarPageBuilderEditComponent implements OnInit {
  public headline: string = '';
  public description: string = '';
  public headlineEn: string = '';
  public descriptionEn: string = '';
  public bgColor: string = '#0A0A0A';
  public textColor: string = '#FFFFFF';
  public link: string = '';
  public titleLink: string = '';

  public showLessColors: boolean = false;

  @Input() editModeType: EditModeTypes = EditModeTypes.Null;
  @Input() indexOfEditedBlock: number | null = null;

  @Output() imageRemovedEvent: EventEmitter<void> = new EventEmitter();

  public hasImage: boolean = false;
  public existingImgData!: HomepageBlockProductPreview | null;
  public productTitle: string = '';

  apiUrl = environment.productServingUrl;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private homepageCreatorDataService: HomepageCreatorDataService,
    private snackBarService: SnackBarService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.hasImage = this.homepageCreatorDataService.hasImageAtCoordinates();
    if (this.hasImage) {
      this.existingImgData = this.homepageCreatorDataService.getSelectedData();

      const headline = this.existingImgData?.title;
      this.productTitle =
        headline?.find((t: Translatable) => t.language == 'HR')?.content ?? '';

      this.link = this.existingImgData?.link ?? '';
    }

    const outerIndex = this.homepageCreatorDataService.coordinates.outerIndex;
    this.headline = this.homepageCreatorDataService.dataMatrix[outerIndex]
      .headline as string;
    this.headlineEn = this.homepageCreatorDataService.dataMatrix[outerIndex]
      .headlineEn as string;
    this.titleLink = this.homepageCreatorDataService.dataMatrix[outerIndex]
      .link as string;
    this.description = this.homepageCreatorDataService.dataMatrix[outerIndex]
      .description as string;
    this.descriptionEn = this.homepageCreatorDataService.dataMatrix[outerIndex]
      .descriptionEn as string;
    this.bgColor =
      this.homepageCreatorDataService.dataMatrix[outerIndex].background_color ??
      '#0A0A0A';
    this.textColor =
      this.homepageCreatorDataService.dataMatrix[outerIndex].text_color ??
      '#FFFFFF';

    if (
      this.homepageCreatorDataService.dataMatrix[outerIndex].block_type
        ?.variation === 'section'
    ) {
      switch (
        this.homepageCreatorDataService.dataMatrix[outerIndex].block_type?.id
      ) {
        case 1:
        case 2:
        case 6:
          this.showLessColors = true;
          break;
        default:
          this.showLessColors = false;
          break;
      }
    }
  }

  headlineChanged() {
    if (typeof this.indexOfEditedBlock === 'number') {
      this.homepageCreatorDataService.editText(
        this.indexOfEditedBlock,
        'headline',
        this.headline
      );
    }
  }
  descriptionChanged() {
    if (typeof this.indexOfEditedBlock === 'number') {
      this.homepageCreatorDataService.editText(
        this.indexOfEditedBlock,
        'description',
        this.description
      );
    }
  }

  headlineChangedEn() {
    if (typeof this.indexOfEditedBlock === 'number') {
      this.homepageCreatorDataService.editText(
        this.indexOfEditedBlock,
        'headlineEn',
        this.headlineEn
      );
    }
  }
  descriptionChangedEn() {
    if (typeof this.indexOfEditedBlock === 'number') {
      this.homepageCreatorDataService.editText(
        this.indexOfEditedBlock,
        'descriptionEn',
        this.descriptionEn
      );
    }
  }

  linkChanged() {
    let expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);

    if (this.link.match(regex) || this.link.length == 0) {
      if (
        this.homepageCreatorDataService.hasImageAtCoordinates() &&
        typeof this.indexOfEditedBlock === 'number'
      ) {
        this.homepageCreatorDataService.editProduct('link', this.link);
      }
    }
  }

  linkBlur() {
    let expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);

    if (this.link.match(regex) || this.link.length == 0) {
      if (
        this.homepageCreatorDataService.hasImageAtCoordinates() &&
        typeof this.indexOfEditedBlock === 'number'
      ) {
        this.homepageCreatorDataService.editProduct('link', this.link);
      }
    } else {
      this.link = '';
      this.snackBarService.showSnackBar('Invalid URL.', 'ERROR');
    }
  }

  //////////////////////////////////////////////////////////////////////////////

  titleLinkChanged() {
    let expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);

    if (this.titleLink.match(regex) || this.titleLink.length == 0) {
      if (typeof this.indexOfEditedBlock === 'number') {
        this.homepageCreatorDataService.editText(
          this.indexOfEditedBlock,
          'link',
          this.titleLink
        );
      }
    }
  }

  titleLinkBlur() {
    let expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);

    if (this.titleLink.match(regex) || this.titleLink.length == 0) {
      if (typeof this.indexOfEditedBlock === 'number') {
        this.homepageCreatorDataService.editText(
          this.indexOfEditedBlock,
          'link',
          this.titleLink
        );
      }
    } else {
      this.titleLink = '';
      this.snackBarService.showSnackBar('Invalid URL.', 'ERROR');
    }
  }

  //////////////////////////////////////////////////////////////////////////////

  bgColorChanged() {
    this.homepageCreatorDataService.editText(
      this.homepageCreatorDataService.coordinates.outerIndex,
      'background_color',
      this.bgColor
    );
  }

  textColorChanged() {
    this.homepageCreatorDataService.editText(
      this.homepageCreatorDataService.coordinates.outerIndex,
      'text_color',
      this.textColor
    );
  }

  handleGallerySelection() {
    this.homepageCreatorDataService.memoScrollPositionSource.next();

    this.router.navigate(['add-gallery'], {
      relativeTo: this.route,
    });
  }

  handleGalleryGroupSelection() {
    this.homepageCreatorDataService.memoScrollPositionSource.next();

    this.router.navigate(['add-gallery-group'], {
      relativeTo: this.route,
    });
  }

  handleImageReplace() {
    this.hasImage = false;
  }

  handleImageRemove() {
    this.homepageCreatorDataService.swapMedia(null);
    this.imageRemovedEvent.emit();
  }

  handleColorChange(color: string, textColor: string = '#ffffff') {
    this.homepageCreatorDataService.editText(
      this.homepageCreatorDataService.coordinates.outerIndex,
      'background_color',
      color
    );
    this.bgColor = color;
    this.homepageCreatorDataService.editText(
      this.homepageCreatorDataService.coordinates.outerIndex,
      'text_color',
      textColor
    );
  }

  handleFileInput(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;

    if (fileList == undefined) {
      this.snackBarService.showSnackBar('No file attached.', 'ERROR');
      return;
    }

    let file: File | null = fileList!.item(0);

    if (file == null) {
      this.snackBarService.showSnackBar('No file attached.', 'ERROR');
      return;
    }

    this.loadingService.setLoading(true);
    this.homepageCreatorDataService.upload(file!).subscribe(
      (homepageBlockProductPreview) => {
        //let resultBlob: Blob = result as Blob;
        //resultBlob.text().then(t  => {
        //  const homepageBlockProductPreview: HomepageBlockProductPreview = JSON.parse(t);

        const obj: ProductPreview = {
          id: -999,
          preview: homepageBlockProductPreview.preview!,
          title: homepageBlockProductPreview.title,
          tags: homepageBlockProductPreview.tags,
          //@ts-ignore
          timestamp: undefined,
          //count: gallery.photo_count,
          count: 0, // :)
          video_count: 0,
          //video_duration: gallery.
          has_article: false,
          kind: ProductPreview.KindEnum.SINGLEPRODUCT,
        };
        //console.log('Preview', obj, homepageBlockProductPreview);
        this.homepageCreatorDataService.swapMedia(obj);

        this.loadingService.setLoading(false);
        this.snackBarService.showSnackBar('File uploaded.', 'INFO');
        //});
      },
      (error) => {
        this.loadingService.setLoading(false);
        this.snackBarService.showSnackBar('File upload failed.', 'ERROR');
      }
    );
  }
}
