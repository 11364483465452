import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { SidebarService } from './sidebar.service';
import { Action } from '../../field-text/field-text.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() buttonVariant: 'default' | 'publish' = 'default';
  @Input() selectedItem: any = {};
  @Output() removeSelection: EventEmitter<void> = new EventEmitter();

  @Output() dataSaved: EventEmitter<void> = new EventEmitter();

  @Input() addedItems: any[] = [];

  public keywords: Set<string> = new Set();
  public people: Set<string> = new Set();

  constructor(
    private formBuilder: FormBuilder,
    private sidebarService: SidebarService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit() {}
  ngOnChanges(changes: SimpleChange) {
    if (this.selectedItem) {
      if (changes.hasOwnProperty('selectedItem')) {
        this.fillForm();
        //console.log(this.selectedItem);
      }
    } else {
      // reset form when nothing is selected
      this.sidebarForm.reset();
      this.keywords.clear();
      this.people.clear();
    }
  }

  sidebarForm: FormGroup = this.formBuilder.group({});

  cancel() {
    this.removeSelection.emit();
  }
  save() {
    const body = {
      ...this.sidebarForm.value,
      keywords: [...this.keywords],
      people: [...this.people],
    };
    this.sidebarService
      .patchData('gallery_groups', this.selectedItem.id, body)
      .subscribe((response) => {
        //console.log(response);
        this.dataSaved.emit();
      });
  }

  savePublishVariant() {
    const body = {
      ...this.sidebarForm.value,
      keywords: [...this.keywords],
      people: [...this.people],
      isPublished: false,
      thumbnails: ['/assets/img/image4.png'],
      items: this.addedItems,
    };
    this.sidebarService
      .postData('gallery_groups', body)
      .subscribe((response: any) => {
        //console.log(response);
        this.dataSaved.emit();
        this.router.navigate(['../'], { relativeTo: this.route });
      });
  }
  publish() {
    const body = {
      ...this.sidebarForm.value,
      keywords: [...this.keywords],
      people: [...this.people],
      isPublished: true,
      thumbnails: ['/assets/img/image4.png'],
      items: this.addedItems,
    };
    this.sidebarService
      .postData('gallery_groups', body)
      .subscribe((response: any) => {
        //console.log(response);
        this.dataSaved.emit();
        this.router.navigate(['../'], { relativeTo: this.route });
      });
  }

  // prettier-ignore
  fillForm() {
    const keywords = this.selectedItem.keywords_list.map((element:any)=>element.name[0].content);
    const people = this.selectedItem.people_list.map((element:any)=>element.name);

    this.sidebarForm.get('headline'    )?.setValue(this.selectedItem.headline[0].content                ?? "");
    this.sidebarForm.get('caption'     )?.setValue(this.selectedItem.caption[0].content                 ?? "");
    this.keywords = new Set(keywords);
    this.people   = new Set(people);
    this.sidebarForm.get('category'    )?.setValue(this.selectedItem.category.name[0].content           ?? "");
    this.sidebarForm.get('author'      )?.setValue(this.selectedItem.author.name                        ?? "");
    this.sidebarForm.get('agency'      )?.setValue(this.selectedItem.agency.name                        ?? "");
    this.sidebarForm.get('tag'         )?.setValue(this.selectedItem.tag_list[0]?.name[0].content       ?? "");
    this.sidebarForm.get('specialOffer')?.setValue(this.selectedItem.special_offer_type.name[0].content ?? "");
    this.sidebarForm.get('webPrice'    )?.setValue(this.selectedItem.webPrice                           ?? "");
    this.sidebarForm.get('printPrice'  )?.setValue(this.selectedItem.printPrice                         ?? "");

    // checkboxes
    this.sidebarForm.get('noLicence'  )?.setValue(this.selectedItem.specialInstruction?.noLicence   ?? false);
    this.sidebarForm.get('standard'   )?.setValue(this.selectedItem.specialInstruction?.standard    ?? false);
    this.sidebarForm.get('childRights')?.setValue(this.selectedItem.specialInstruction?.childRights ?? false);
    this.sidebarForm.get('copyright2' )?.setValue(this.selectedItem.specialInstruction?.copyright2  ?? false);
    this.sidebarForm.get('copyright3' )?.setValue(this.selectedItem.specialInstruction?.copyright3  ?? false);
    this.sidebarForm.get('copyright4' )?.setValue(this.selectedItem.specialInstruction?.copyright4  ?? false);
  }
}
