<div class="outer-container">
  <div class="inner-container" #innerContainer>
    <!-- change .outer-container width based on slide number -->
    <div class="content" #content>
      <app-horizontal-scroll-slide
        [previewUrl]="images[0]"
        [link]="'/services/location'"
      >
        <div [innerHTML]="'about_us.service_scroll_01' | translate"></div>
        <!-- Fotografiranje portreta<br />u studiju ili na terenu -->
      </app-horizontal-scroll-slide>
      <app-horizontal-scroll-slide
        [previewUrl]="images[1]"
        [link]="'/services/product'"
      >
        <div [innerHTML]="'about_us.service_scroll_02' | translate"></div>
        <!-- Fotografiranje<br />proizvoda -->
      </app-horizontal-scroll-slide>
      <app-horizontal-scroll-slide
        [previewUrl]="images[2]"
        [link]="'/services/food'"
      >
        <div [innerHTML]="'about_us.service_scroll_03' | translate"></div>
        <!-- Fotografiranje<br />hrane -->
      </app-horizontal-scroll-slide>
      <app-horizontal-scroll-slide
        [previewUrl]="images[3]"
        [link]="'/services/native'"
      >
        <div [innerHTML]="'about_us.service_scroll_04' | translate"></div>
        <!-- Fotografiranje i video<br />snimanje za native<br />oglašavanje -->
      </app-horizontal-scroll-slide>
      <app-horizontal-scroll-slide
        [previewUrl]="images[4]"
        [link]="'/services/event'"
      >
        <div [innerHTML]="'about_us.service_scroll_05' | translate"></div>
        <!-- Fotografiranje i video<br />snimanje evenata -->
      </app-horizontal-scroll-slide>
      <app-horizontal-scroll-slide
        [previewUrl]="images[5]"
        [link]="'/services/drone'"
      >
        <div [innerHTML]="'about_us.service_scroll_06' | translate"></div>
        <!-- Fotografiranje i video<br />snimanje iz zraka<br />bespilotnom letjelicom -->
      </app-horizontal-scroll-slide>
    </div>
  </div>
</div>
