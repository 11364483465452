/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FinanceInvoiceProductItem {
  id?: number;
  product_item?: string;
  description?: string;
  quantity?: number;
  unit?: string;
  unit_price?: number;
  tax?: number;
  net?: number;
  discount?: number;
  discount_amount?: number;
  net_total?: number;
  tax_total?: number;
  gross?: number;
}
