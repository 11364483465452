<script src="../shared.module.ts"></script>
<div [class.cart-wrapper]="isAddToBasketOpen">
  <div
    class="production"
    [class.production--sidebar-active]="isFilterSidebarOpen"
  >
    <div class="production__sidebar" *ngIf="isFilterSidebarOpen">
      <app-filter-sidebar
        [initialFilterValue]="initialFilterValue"
        [(filterValue)]="filterValue"
        (filterValueChange)="filterValueChange()"
        [extraFilters]="extraFilters"
        (closeFilterSidebarEvent)="closeFilterSidebar()"
        [showContentType]="showContentType"
        [showCategories]="showCategories"
        [showTags]="showTags"
        [showTimeInterval]="showTimeInterval"
        [showMainFilters]="showMainFilters"
        [showRefinementFilters]="showRefinementFilters"
        [subPortal]="subPortal"
        [(displayValues)]="displayValues"
        (displayValuesChange)="filterDisplayValueChanged($event)"
        [(autocompleteValue)]="autocompleteValue"
        (autocompleteValueChange)="filterAutocompleteValueChanged($event)"
      ></app-filter-sidebar>
    </div>
    <div
      class="production__main"
      #productionMain
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      [style.visibility]="hideMosaic ? 'hidden' : 'visible'"
      (scrolled)="onScroll()"
      (scroll)="onNormalScroll($event)"
    >
      <div
        #searchBar
        class="search-bar-fixed"
        [class.full-width-search-bar]="!isFilterSidebarOpen"
      >
        <app-search-bar
          (openFilterSidebarEvent)="openFilterSidebar()"
          [isFilterSidebarOpen]="isFilterSidebarOpen"
          [(isSearchActive)]="isSearchActive"
          [bannerSrc]="bannerSrc"
          [searchQuery]="searchQuery"
          [bannerTitle]="bannerTitle"
          [bannerSearchPlaceholder]="bannerSearchPlaceholder"
        >
        </app-search-bar>
      </div>
      <div
        class="gallery__wrapper"
        [class.gallery-wrapper-smaller]="isSearchActive"
      >
        <div class="gallery-container">
          <div
            class="search-results"
            *ngIf="
              isSearchDone &&
              (searchQuery !== '' || isFilteringGallery(filterValue))
            "
          >
            <p
              class="search-results-number"
              *ngIf="searchResultNumber !== undefined"
            >
              <span
                >{{ searchResultNumber | number }}
                {{ "search_results.results" | translate }}
              </span>
              {{ "search_results.for_search" | translate }}
              <span *ngIf="searchQuery !== ''">“{{ searchQuery }}”</span>
            </p>

            <p
              class="search-results-help"
              *ngIf="searchResults.length === 0"
              [innerHTML]="'search_results.search_help' | translate"
            >
              <span> Za bolje rezultate pretraživanja pokušajte:</span>
              upotrijebiti manje ključnih riječi, pretraživati na drugom jeziku,
              provjeriti pravopisne greške, očistiti opcije filtra za
              pretraživanje ili pokušajte upisati jednostavniji pojam.
            </p>
            <p class="search-results-sugestion" *ngIf="false">
              <span> Jeste li mislili: </span><a class="sugestion">Nogomet</a>
            </p>
            <!-- REEEEMOVE THIS margin WHEN ADDING SUGESTIONS -->
            <div
              *ngIf="searchResults.length === 0"
              [style]="{ marginTop: '-30px' }"
            ></div>

            <app-search-more-block
              *ngIf="searchResults.length === 0"
              [isFullGrid]="true"
            ></app-search-more-block>
          </div>
          <div
            class="search-results"
            *ngIf="!isSearchDone && searchQuery !== ''"
          >
            <p class="search-results-number">
              <span
                [innerHTML]="'search_results.search_in_progress' | translate"
                >Pretraživanje u tijeku...</span
              >
            </p>
          </div>

          <div
            class="main-preview-row"
            *ngIf="!isSearchDone"
            [class.main-preview-row-1]="imagesPerRow === 1"
            [class.main-preview-row-2]="imagesPerRow === 2"
            [class.main-preview-row-3]="imagesPerRow === 3"
            [class.main-preview-row-4]="imagesPerRow === 4"
          >
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div class="loading-skeleton-item"></div>
            <div
              *ngIf="imagesPerRow % 2 === 0"
              class="loading-skeleton-item"
            ></div>
          </div>
        </div>

        <app-fancy-grid
          *ngIf="searchResults.length !== 0"
          [inInSearch]="true"
          [imageData]="searchResults"
          [imagesPerRow]="4"
          [allowImagePerRowRecalculation]="true"
          (imagePerRowEmitter)="handleImagePerRowChange($event)"
          [variant]="3"
          (cartButtonPressedEmitter)="addProductToBasket($event)"
          (atLeastOneLoaded)="atLeastOneLoaded($event)"
          [showDataOnlyOnHover]="showDataOnlyOnHover"
          [hasSearchMoreBlock]="true"
        ></app-fancy-grid>

        <div
          *ngIf="searchResults.length !== 0"
          class="load-more-btn__container"
        >
          <button
            class="load-more-btn"
            *ngIf="showLoadNextPageButton"
            (click)="loadNextPage()"
            [innerHTML]="'all.load_more' | translate"
          >
            Učitaj više
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAddToBasketOpen">
  <app-gallery-cart
    [productId]="addToBasketId"
    (addToBasketClosedEvent)="closeAddToBasket()"
  ></app-gallery-cart>
</div>
