<a
  class="left-item can-change-color"
  [routerLink]="
    pagebuilderMode
      ? []
      : mediaData?.kind === 'GALLERY'
      ? ['/ga/', mediaData?.id]
      : ['/g/', mediaData?.id]
  "
>
  <app-single-image
    *ngIf="mediaData !== null"
    class="image homepage-section-detailed-small-image"
    [imageLoadingPreviewUrl]="mediaData!.preview![0].preview_url_blur!"
    [imagePreviewUrl]="mediaData!.preview![0].preview_url!"
  >
  </app-single-image>
  <app-single-image
    *ngIf="mediaData === null"
    class="image homepage-section-detailed-small-image"
    [imageLoadingPreviewUrl]="noImgSectionUrl"
    [imagePreviewUrl]="noImgSectionUrl"
  >
  </app-single-image>
  <div class="small-group">
    <div class="left-title">
      {{
        (currentLanguage ? productTitle : productTitleEn) ||
          "Placeholder headline"
      }}
    </div>
    <div class="left-timestamp" *ngIf="mediaData?.timestamp !== null">
      {{ mediaData?.timestamp | date : "dd.MM.yyyy. HH:mm" }}
    </div>
    <div class="left-timestamp" *ngIf="mediaData?.timestamp === null">
      {{ "01.01.1900. 00:00" }}
    </div>
  </div>
</a>
