export * from './agencies.service';
import { AgenciesService } from './agencies.service';
export * from './authors.service';
import { AuthorsService } from './authors.service';
export * from './autocomplete.service';
import { AutocompleteService } from './autocomplete.service';
export * from './cMS.service';
import { CMSService } from './cMS.service';
export * from './cMSCompanies.service';
import { CMSCompaniesService } from './cMSCompanies.service';
export * from './cMSContracts.service';
import { CMSContractsService } from './cMSContracts.service';
export * from './cMSGalleries.service';
import { CMSGalleriesService } from './cMSGalleries.service';
export * from './cMSUsers.service';
import { CMSUsersService } from './cMSUsers.service';
export * from './cart.service';
import { CartService } from './cart.service';
export * from './categories.service';
import { CategoriesService } from './categories.service';
export * from './dynamicViews.service';
import { DynamicViewsService } from './dynamicViews.service';
export * from './frontGalleries.service';
import { FrontGalleriesService } from './frontGalleries.service';
export * from './frontPushNotifications.service';
import { FrontPushNotificationsService } from './frontPushNotifications.service';
export * from './galleryGroups.service';
import { GalleryGroupsService } from './galleryGroups.service';
export * from './homepage.service';
import { HomepageService } from './homepage.service';
export * from './iPTC.service';
import { IPTCService } from './iPTC.service';
export * from './internalUsers.service';
import { InternalUsersService } from './internalUsers.service';
export * from './keywords.service';
import { KeywordsService } from './keywords.service';
export * from './licenceTypes.service';
import { LicenceTypesService } from './licenceTypes.service';
export * from './people.service';
import { PeopleService } from './people.service';
export * from './products.service';
import { ProductsService } from './products.service';
export * from './productsFront.service';
import { ProductsFrontService } from './productsFront.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './properName.service';
import { ProperNameService } from './properName.service';
export * from './registrationLogin.service';
import { RegistrationLoginService } from './registrationLogin.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './specialInstructions.service';
import { SpecialInstructionsService } from './specialInstructions.service';
export * from './specialOfferTypes.service';
import { SpecialOfferTypesService } from './specialOfferTypes.service';
export * from './tags.service';
import { TagsService } from './tags.service';
export * from './uploadSessions.service';
import { UploadSessionsService } from './uploadSessions.service';
export * from './userRights.service';
import { UserRightsService } from './userRights.service';
export * from './webAdministration.service';
import { WebAdministrationService } from './webAdministration.service';
export const APIS = [
  AgenciesService,
  AuthorsService,
  AutocompleteService,
  CMSService,
  CMSCompaniesService,
  CMSContractsService,
  CMSGalleriesService,
  CMSUsersService,
  CartService,
  CategoriesService,
  DynamicViewsService,
  FrontGalleriesService,
  FrontPushNotificationsService,
  GalleryGroupsService,
  HomepageService,
  IPTCService,
  InternalUsersService,
  KeywordsService,
  LicenceTypesService,
  PeopleService,
  ProductsService,
  ProductsFrontService,
  ProfileService,
  ProperNameService,
  RegistrationLoginService,
  SettingsService,
  SpecialInstructionsService,
  SpecialOfferTypesService,
  TagsService,
  UploadSessionsService,
  UserRightsService,
  WebAdministrationService,
];
