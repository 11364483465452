import { Component, Input, OnInit } from '@angular/core';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { GalleriesService } from '../../cms/content/galleries/galleries.service';
import { formatDate } from '@angular/common';
import { GalleryGroupService } from '../../cms/content/gallery-group/gallery-group.service';

@Component({
  selector: 'app-scheduled-publish-dialog',
  templateUrl: './scheduled-publish-dialog.component.html',
  styleUrls: ['./scheduled-publish-dialog.component.scss'],
})
export class ScheduledPublishDialogComponent implements OnInit {
  @Input() kind?: 'GALLERY' | 'GALLERY_GROUP' = undefined;

  active: boolean = false;
  galleryGroup: boolean = false;
  doneEnabled: boolean = false;
  selectedDate: any;
  config: IDatePickerConfig = {
    format: 'DD.MM.YYYY. HH:mm:ss',
    returnedValueType: ECalendarValue.Moment,
    showTwentyFourHours: true,
  };

  constructor(
    private galleriesService: GalleriesService,
    private galleryGroupService: GalleryGroupService
  ) {}

  ngOnInit(): void {
    if (this.kind == 'GALLERY') {
      this.galleriesService.schedulePublishDialogData.subscribe((_) => {
        this.active = true;
        this.galleryGroup = false;
      });
    } else {
      this.galleryGroupService.schedulePublishDialogData.subscribe((_) => {
        this.active = true;
        this.galleryGroup = true;
      });
    }
  }

  publish() {
    const date = this.selectedDate.format('YYYY-MM-DDTHH:mm:ss');
    if (this.galleryGroup) {
      this.galleryGroupService.finishSchedulePublishDialog(date);
    } else {
      this.galleriesService.finishSchedulePublishDialog(date);
    }
    this.active = false;
  }

  cancel() {
    this.active = false;
  }
}
