<div class="app-wrapper">
  <app-navbar [isLoggedIn]="isLoggedIn"></app-navbar>

  <div class="app-container">
    <div class="app-router-outlet-container">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<!-- cookies -->
<!-- <app-cookies
  (cookiesClosedEvent)="closeCookies()"
  [(isCookieSettingsOpen)]="isCookieSettingsOpen"
></app-cookies> -->
