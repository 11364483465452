import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ImportItem } from '../import.component';
import { TableService } from '../../../shared/table.service';
import { DragulaService } from 'ng2-dragula';
import { ImportService } from '../import.service';
import { ProductsService } from '../../content/products/products.service';
import { GalleriesService } from '../../content/galleries/galleries.service';
import { KeyboardShortcutsUtils } from '../../../keyboard-shortcuts-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum ImageSize {
  Normal = 1,
  Large = 2,
}

export enum PreviewType {
  Grid = 1,
  Table,
  InlineEdit,
}

@Component({
  selector: 'app-main-preview',
  templateUrl: './main-preview.component.html',
  styleUrls: ['./main-preview.component.scss'],
})
/**
 * Mosaic component used for import and products in the CMS.
 */
export class MainPreviewComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy, OnChanges
{
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private tableService: TableService,
    private dragulaService: DragulaService,
    private importService: ImportService,
    private productService: ProductsService,
    private galleryService: GalleriesService,
    private ref: ChangeDetectorRef
  ) {}

  static DEFAULT_ASPECT_RATIO = 1.5015015015015014;

  @Input() items: ImportItem[] = [];
  @Input() selectedItemIds: number[] = [];
  @Input() isEmbedded = false;
  @Input() isGalleryEdit = false;
  @Input() canDownload = false;
  @Input() canSetAsGalleryHead = false;
  @Input() isLoaded = false;
  @Input() page = 1;
  @Input() numberOfItems?: number;
  @Input() maximumNumberOfItems?: number;
  @Input() perPage?: number;
  @Input() totalResults?: number;
  @Input() pageChangeDisabled = false;
  @Input() reorderingEnabled = false;
  @Input() errorIds: number[] = [];
  @Input() totalPages = 1;
  @Input() previewType: PreviewType = PreviewType.Grid;

  @Output() selectedItemIdsChange: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() previewTypeChange: EventEmitter<PreviewType> =
    new EventEmitter<PreviewType>();

  @ViewChild('container') container?: ElementRef;

  tableItems: any[] = [];
  lastSelectedItemId: number | undefined;
  rows: any = [];
  itemHeight = 100;
  imagesPerRow = 4;
  imageSize: ImageSize = ImageSize.Normal;
  pageFromItem?: number;
  pageToItem?: number;

  columns = [
    'preview',
    'headline',
    'caption',
    'people',
    'keywords',
    'categories',
    'author',
    'agency',
    'tag',
    'specialOffer',
    'specialOfferType',
    'dateCreated',
  ];

  columnTypes = [
    'import-item-preview',
    'autocomplete',
    'string',
    'tags',
    'tags',
    'autocomplete',
    'autocomplete',
    'autocomplete',
    'autocomplete',
    'boolean',
    'autocomplete',
    'autocomplete',
  ];

  columnTitles = [
    '',
    'Headline',
    'Caption',
    'People',
    'Keywords',
    'Category',
    'Author',
    'Agency',
    'Tag',
    'Special Offer',
    'Special Offer Type',
    'Date Created',
  ];

  ngOnInit(): void {
    if (!this.isEmbedded) {
      this.importService.selectItemsData
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((ids) => {
          this.selectedItemIds = ids;
        });
    }

    this.galleryService.setAsGalleryHeadEventData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((item) => {
        const editingGallery = this.productService.getEditingGallery();

        if (editingGallery != undefined) {
          const oldIndex: number = editingGallery!.items!.indexOf(item);
          this.move(this.items, oldIndex, 0);
          this.recalcLayout();

          editingGallery.previewUrl =
            this.productService.computePreviewGalleryImportItem(editingGallery);
          this.galleryService.markAsDirty([editingGallery]);
        }
      });

    if (this.reorderingEnabled) {
      this.dragulaService.createGroup('VAMPIRES', {});

      this.dragulaService
        .drop('VAMPIRES')
        .subscribe(({ name, el, target, source, sibling }) => {
          const oldIndex: number = parseInt(el.getAttribute('index')!);

          const children = Array.prototype.slice.call(
            el.parentElement!.children
          );
          const newIndex = children
            .map((child) => child.getAttribute('data-id'))
            .indexOf(el.getAttribute('data-id'));

          this.move(this.items, oldIndex, newIndex);
          this.recalcLayout();

          const editingGallery = this.productService.getEditingGallery();
          if (editingGallery) {
            editingGallery.previewUrl =
              this.productService.computePreviewGalleryImportItem(
                editingGallery
              );
            this.galleryService.markAsDirty([editingGallery]);
          }
        });
    }

    this.importService.selectAllData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((shouldSelect) => {
        if (shouldSelect) {
          this.selectAll();
        } else {
          this.unselectAll();
        }
      });

    this.productService.selectAllData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((shouldSelect) => {
        if (shouldSelect) {
          this.selectAll();
        } else {
          this.unselectAll();
        }
      });
  }

  ngOnChanges(model: SimpleChanges) {
    // Detect only changes that modify the ids of items.
    if (
      model.items == undefined ||
      !model.items.previousValue ||
      !model.items.currentValue
    ) {
      return;
    }

    const oldIds: number[] = model.items.previousValue.map(
      (i: { id: any }) => i.id
    );
    const newIds: number[] = model.items.currentValue.map(
      (i: { id: any }) => i.id
    );

    if (!this.arrayEquals(oldIds, newIds)) {
      this.recalcLayout();
      this.calculatePageCount();
    }
  }

  ngAfterViewInit() {
    this.recalcLayout();
  }

  ngOnDestroy() {
    if (this.reorderingEnabled) {
      this.dragulaService.destroy('VAMPIRES');
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.recalcLayout();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  hasError(item: any) {
    return this.errorIds.includes(item);
  }

  private move(array: any[], oldIndex: number, newIndex: number) {
    const temp = array[newIndex];
    array[newIndex] = array[oldIndex];
    array[oldIndex] = temp;
  }

  changePreviewType(newPreviewType: string) {
    this.previewType = PreviewType[newPreviewType as keyof typeof PreviewType];
    this.previewTypeChange.emit(this.previewType);
  }

  calculatePageCount() {
    if (!this.perPage || !this.totalResults) {
      return;
    }

    this.pageFromItem = Math.max(
      Math.min((this.page - 1) * this.perPage!, this.totalResults!),
      1
    );
    this.pageToItem = Math.max(
      Math.min(this.page * this.perPage!, this.totalResults!),
      1
    );
  }

  previousPage() {
    if (this.pageChangeDisabled) {
      return;
    }

    if (this.page == 1) {
      return;
    }

    this.page -= 1;
    this.calculatePageCount();

    this.pageChange.emit(this.page);
  }

  nextPage() {
    if (this.pageChangeDisabled) {
      return;
    }

    this.page += 1;
    this.calculatePageCount();

    this.pageChange.emit(this.page);
  }

  arrayEquals(a: any[], b: any[]) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  onImageLoaded(event: { item: ImportItem; aspectRatio: number }) {
    const index = this.items.map((i) => i.id).indexOf(event.item.id);
    this.items[index].aspectRatio = event.aspectRatio;

    this.ref.detectChanges();
    this.recalcLayout();
  }

  tableItemClick($event: { item: any; event: MouseEvent }) {
    this.previewItemClick($event.item, $event.event);
  }

  previewItemClick(item: any, $event: MouseEvent) {
    if (this.isEmbedded) {
      this.productService.addItem(item);
      return;
    }

    if (
      !KeyboardShortcutsUtils.isCtrlOrCmdPressed($event) &&
      !$event.shiftKey
    ) {
      // Single select
      // Clear selection if we had only one item and it was this item.
      if (
        this.selectedItemIds.length == 1 &&
        this.selectedItemIds.includes(item.id)
      ) {
        this.selectedItemIds = [];
        this.lastSelectedItemId = undefined;
      } else {
        this.selectedItemIds = [item.id];
        this.lastSelectedItemId = item.id;
      }
    } else if (
      !KeyboardShortcutsUtils.isCtrlOrCmdPressed($event) &&
      $event.shiftKey
    ) {
      // Find the index of the last selected item.
      if (
        !this.selectedItemIds.includes(item.id) &&
        this.lastSelectedItemId != undefined
      ) {
        const lastIndexOfSelected = this.items.findIndex(
          (i) => i.id == this.lastSelectedItemId
        );
        const currentIndex = this.items.findIndex((i) => i.id == item.id);

        let toBeSelectedItems: number[];
        if (lastIndexOfSelected < currentIndex) {
          toBeSelectedItems = this.items
            .slice(lastIndexOfSelected, currentIndex + 1)
            .map((i) => i.id);
        } else {
          toBeSelectedItems = this.items
            .slice(currentIndex, lastIndexOfSelected)
            .map((i) => i.id);
        }

        toBeSelectedItems.forEach((toBeSelectedItem) => {
          if (!this.selectedItemIds.includes(toBeSelectedItem)) {
            this.selectedItemIds.push(toBeSelectedItem);
          }
        });
      }
    } else {
      if (this.selectedItemIds.includes(item.id)) {
        this.selectedItemIds = this.selectedItemIds.filter(
          (id) => id !== item.id
        );
        this.lastSelectedItemId = undefined;
      } else {
        this.selectedItemIds.push(item.id);
        this.lastSelectedItemId = item.id;
      }
    }

    this.selectedItemIdsChange.emit(this.selectedItemIds);
    this.tableService.setSelected(this.selectedItemIds);

    /* Clear selection */
    if (window.getSelection) {
      // @ts-ignore
      if (window.getSelection().empty) {
        // Chrome
        // @ts-ignore
        window.getSelection().empty();
      } else {
        // @ts-ignore
        if (window.getSelection().removeAllRanges) {
          // Firefox
          // @ts-ignore
          window.getSelection().removeAllRanges();
        }
      }
    } else {
      // @ts-ignore
      if (document.selection) {
        // IE?
        // @ts-ignore
        document.selection.empty();
      }
    }
  }

  selectAll() {
    if (this.selectedItemIds.length != 0) {
      this.selectedItemIds = [];
    } else {
      this.selectedItemIds = this.items.map((i) => i.id);
    }

    this.lastSelectedItemId = undefined;
    this.selectedItemIdsChange.emit(this.selectedItemIds);
    this.tableService.setSelected(this.selectedItemIds);
  }

  unselectAll() {
    this.selectedItemIds = [];

    this.lastSelectedItemId = undefined;
    this.selectedItemIdsChange.emit(this.selectedItemIds);
    this.tableService.setSelected(this.selectedItemIds);
  }

  calculateRowAspectRatio(row: ImportItem[]): number {
    if (row.length == 0) {
      return MainPreviewComponent.DEFAULT_ASPECT_RATIO;
    }

    if (row.length == 1) {
      return (
        1.0 / row[0].aspectRatio! ?? MainPreviewComponent.DEFAULT_ASPECT_RATIO
      );
    }

    return (
      row
        .map((rowItem) => {
          const isRowItemVertical =
            rowItem.aspectRatio != undefined && rowItem.aspectRatio < 1.0;

          let width: number;
          if (isRowItemVertical && this.imagesPerRow != 1) {
            width = 0.5;
          } else {
            width = 1.0;
          }

          if (rowItem.aspectRatio) {
            return (1.0 / rowItem.aspectRatio) * width;
          } else {
            return MainPreviewComponent.DEFAULT_ASPECT_RATIO;
          }
        })
        .reduce((a, b) => a + b) / row.length
    );
  }

  recalcLayout() {
    this.itemHeight =
      (this.container!.nativeElement.offsetWidth - 10.0) / this.imagesPerRow;
    this.ref.detectChanges();

    let rowI = 0;
    let columnI = 0;
    let row: ImportItem[] = [];

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      let nextItem: ImportItem | undefined;

      if (i < this.items.length - 1) {
        nextItem = this.items[i + 1];
      }

      const isVertical =
        item.aspectRatio != undefined && item.aspectRatio < 1.0;

      let width: number;
      if (isVertical && this.imagesPerRow != 1) {
        width = 0.5;
      } else {
        width = 1.0;
      }

      columnI += width;

      item.width = width * (100.0 / this.imagesPerRow);

      row.push(item);

      // Start a new row
      if (columnI >= this.imagesPerRow) {
        // Rows has a half image.
        if (columnI == this.imagesPerRow + 0.5 && width == 1.0) {
          row.pop();

          const averageAR = this.calculateRowAspectRatio(row);

          row.forEach((rowItem) => {
            const isRowItemVertical =
              rowItem.aspectRatio != undefined && rowItem.aspectRatio < 1.0;

            let width: number;
            if (isRowItemVertical && this.imagesPerRow != 1) {
              width = 0.5;
            } else {
              width = 1.0;
            }

            rowItem.width = width * (100.0 / (this.imagesPerRow - 0.5));
            rowItem.height = averageAR;
          });

          columnI = width;
          row = [item];
        } else {
          const averageAR = this.calculateRowAspectRatio(row);

          row.forEach((rowItem) => {
            rowItem.height = averageAR;
          });

          columnI = 0.0;
          row = [];
        }

        rowI += 1.0;
      }

      if (i == this.items.length - 1) {
        const averageAR = this.calculateRowAspectRatio(row);

        row.forEach((rowItem) => {
          rowItem.height = averageAR;
        });
      }
    }
  }

  percentChangeHandler($event: any) {
    this.imagesPerRow = $event;

    this.recalcLayout();

    if (this.imagesPerRow == 1) {
      this.imageSize = ImageSize.Large;
    } else {
      this.imageSize = ImageSize.Normal;
    }
  }

  getItemId(index: number, item: any) {
    return item.id;
  }

  itemOver(item: any, $event: MouseEvent) {
    let element = $event.target as HTMLElement | null;
    if (element == null) {
      return;
    }

    element = element.closest('app-main-preview-item');
    if (element == null) {
      return;
    }

    const videoElements = element.querySelector(
      'video'
    ) as HTMLVideoElement | null;
    videoElements?.play();
  }

  itemOut(item: any, $event: MouseEvent) {
    let element = $event.target as HTMLElement | null;
    if (element == null) {
      return;
    }

    element = element.closest('app-main-preview-item');
    if (element == null) {
      return;
    }

    const videoElements = element.querySelector(
      'video'
    ) as HTMLVideoElement | null;
    videoElements?.pause();
  }
}
