import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as Tether from 'tether';
import Popper from 'popper.js';
import PopperOptions = Popper.PopperOptions;

export interface Action {
  id: string;
  title: string;
}

@Component({
  selector: 'app-import-button',
  templateUrl: './import-button.component.html',
  styleUrls: ['./import-button.component.scss'],
})
export class ImportButtonComponent implements OnInit {
  @Input() name: string = '';
  @Input() title: string = '';
  @Input() foregroundColor: string = '#FFFFFF';
  @Input() backgroundColor: string = '';
  @Input() disabledBackgroundColor: string = '';
  @Input() disabled: boolean = false;
  @Input() hasDropdown: boolean = true;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() dropdownActionClick: EventEmitter<void> = new EventEmitter<void>();

  autocompleteWidth: Number = 0.0;
  tether: Tether | null = null;

  constructor() {}

  ngOnInit(): void {}

  buttonDidClick(event: MouseEvent) {
    let target: HTMLElement = event.target as HTMLElement;
    if (target.tagName != 'BUTTON') {
      if (this.hasDropdown) {
        this.openActions();
      }

      event.preventDefault();
      return;
    }

    if (this.disabled) return;

    this.buttonClick.emit(void 0);
  }

  ///////////////////////////////
  // Actions
  ///////////////////////////////

  @ViewChild('actionsEl') actionsEl: ElementRef | null = null;
  @ViewChild('actionsToggleEl') actionsToggleEl: ElementRef | null = null;

  @Input() actions: Action[] = [];
  @Output() actionSelected: EventEmitter<{
    field: string;
    action: Action;
  }> = new EventEmitter<{
    field: string;
    action: Action;
  }>();

  public shouldShowActionDropdown: boolean = false;

  private actionsDropdownPopper?: Popper;
  public actionsDropdownWidth: Number = 300.0;

  private readonly actionsDropdownPopperOptions: PopperOptions = {
    placement: 'bottom-end',
    modifiers: {
      flip: {
        behavior: ['left'],
      },

      offset: {
        offset: '-8',
      },
    },
  };

  ///////////////////////////////
  // Listeners
  ///////////////////////////////

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (
      !this.actionsEl!.nativeElement.contains(event.target) &&
      this.shouldShowActionDropdown
    ) {
      this.hideActions();
    }
  }

  ///////////////////////////////
  // Actions
  ///////////////////////////////

  openActions() {
    if (this.disabled) return;

    const reference = this.actionsDropdownPopper
      ? this.actionsDropdownPopper
      : this.actionsToggleEl!.nativeElement;
    this.actionsDropdownPopper = new Popper(
      reference,
      this.actionsEl!.nativeElement,
      this.actionsDropdownPopperOptions
    );

    this.autocompleteWidth = 233;

    this.shouldShowActionDropdown = true;
  }

  hideActions() {
    this.tether?.destroy();
    this.tether = null;
    this.shouldShowActionDropdown = false;
  }

  onActionClick(action: Action) {
    this.actionSelected.emit({
      field: this.name,
      action: action,
    });
    this.hideActions();
  }
}
