/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NewsletterStatus {
  status?: NewsletterStatus.StatusEnum;
}
export namespace NewsletterStatus {
  export type StatusEnum =
    | 'PRESENTED'
    | 'PRESENTED_AND_CLOSED'
    | 'SENT_WITH_SUCCESS'
    | 'SENT_WITH_FAILURE';
  export const StatusEnum = {
    PRESENTED: 'PRESENTED' as StatusEnum,
    PRESENTEDANDCLOSED: 'PRESENTED_AND_CLOSED' as StatusEnum,
    SENTWITHSUCCESS: 'SENT_WITH_SUCCESS' as StatusEnum,
    SENTWITHFAILURE: 'SENT_WITH_FAILURE' as StatusEnum,
  };
}
