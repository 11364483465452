import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from 'src/app/shared/environment';
import { BrowserTracing } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
  // Sentry.init({
  //   dsn: "https://301d19a8c15b48ff84be42f7a2ad49bc@sentry.smartcode.eu/11",
  //   integrations: [
  //     new BrowserTracing({
  //       tracingOrigins: ["localhost", "api.pixsell.hr"],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //   ],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 0.01,
  // });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.debug(err));
