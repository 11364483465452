<div [ngClass]="'theme-' + theme">
  <div class="field-title">{{ title }}</div>

  <div class="field-container" [class.disabled]="disabled">
    <ckeditor
      [editor]="Editor"
      [data]="rawValue"
      (change)="onChange($event)"
      (ready)="onReady($event)"
      [disabled]="disabled"
      [config]="{ toolbar: ['styles', 'bold', 'italic', 'underline', 'link'] }"
    ></ckeditor>
  </div>

  <!-- Errors -->
  <div *ngIf="errors != undefined && errors.length > 0" class="field-errors">
    <div *ngFor="let error of errors" class="field-error">
      {{ error.error_description }}
    </div>
  </div>
</div>
