<button
  *ngIf="pagebuilderMode"
  (dblclick)="buttonDoubleClicked()"
  [class.selected]="isSelected"
  [@selectDeselect]="isSelected ? 'selected' : 'deselected'"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>

<ng-container *ngIf="!pagebuilderMode">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
