<ng-container>
  <app-search-bar-solo
    class="s-bar"
    [placeholder]="searchPlaceholder"
    [searchRoute]="endpoint"
    (searchEvent)="isSearchActive = true"
  ></app-search-bar-solo>

  <div
    class="production__main"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScroll()"
    (scroll)="onNormalScroll($event)"
  >
    <div class="reuters-banner">
      <div class="reuters-banner__logos">
        <img src="{{ logoUrl }}" alt="" />
        <img src="{{ logo2Url }}" alt="Reuters" *ngIf="logo2Url" />
        <div *ngIf="!logo2Url">
          {{ logo2Text }}
        </div>
      </div>
      <p>
        {{ bannerText }}
      </p>
    </div>

    <ng-container *ngIf="gridType === 3">
      <div class="main-grid__container" *ngIf="gridData">
        <app-fancy-grid
          [imageData]="gridData"
          [imagesPerRow]="4"
          [allowImagePerRowRecalculation]="true"
          [variant]="3"
        ></app-fancy-grid>
      </div>
    </ng-container>

    <div class="load-more-btn__container">
      <button
        class="load-more-btn"
        *ngIf="showLoadNextPageButton"
        (click)="loadNextPage()"
        [innerHTML]="'all.load_more' | translate"
      >
        Učitaj više
      </button>
    </div>
  </div>
</ng-container>
