<div class="dl-container">
  <div class="dl-cards">
    <div class="dl-card" *ngFor="let card of downloadList; let i = index">
      <div class="dl-card__left">
        <div class="dl-card__image-container">
          <img
            class="dl-card__image"
            src="{{ card.previews[0].preview_url }}"
            alt=""
          />
        </div>
        <div class="dl-card__text">
          <div class="dl-card__title">{{ card.title }}</div>
          <div class="dl-card__price">
            <app-d-price
              theme="5"
              [value]="card.line_total_with_vat"
              [alternativeValue]="card.alternative_line_total_with_vat"
              [useCurrentEuroPhase]="true"
            ></app-d-price>
          </div>
        </div>
      </div>
      <button class="dl-card__close-btn" (click)="deleteItem(i)"></button>
    </div>
  </div>
</div>
<div class="dl-button__container">
  <button class="dl-button" (click)="download()">
    <div class="dl-button__icon"></div>
    Preuzmi
  </button>
</div>
