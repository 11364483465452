<div class="preview-item-container" [class.isLocked]="isLocked == true">
  <div class="download-icon" (click)="download($event)" *ngIf="canDownload">
    <img src="/assets/download-icon.svg" alt="" />
  </div>

  <div
    class="gallery-set-head-icon"
    (click)="setAsGalleryHead($event)"
    *ngIf="canSetAsGalleryHead"
  >
    <img src="/assets/gallery-set-head.svg" alt="" />
  </div>

  <div class="card__locked" *ngIf="isLocked">
    <div class="card__locked_bg"></div>
    <div class="card__locked-by">
      <span>Edited by: </span>
      <span class="locked-name">
        {{ lockedBy }}
      </span>
    </div>
    <img src="/assets/lock.png" alt="" class="lock-icon" />
  </div>

  <div *ngIf="!isVideo" class="preview-item-container-image-container">
    <div class="loading-container"></div>
    <img
      *ngIf="shouldLoadBlur && !imageLoaded && !isImageError"
      [src]="imageLoadingPreviewUrl"
      (error)="erroredImage($event)"
      (load)="loadedPreviewImage($event)"
    />
    <img
      *ngIf="!isImageError"
      [src]="imagePreviewUrl"
      (error)="erroredImage($event)"
      (load)="loadedImage($event)"
    />
  </div>

  <div *ngIf="isVideo" class="preview-item-container-video-container">
    <div class="loading-container"></div>
    <img
      *ngIf="shouldLoadBlur && !imageLoaded && !isImageError"
      [src]="imageLoadingPreviewUrl"
      (error)="erroredImage($event)"
      (load)="loadedPreviewImage($event)"
    />
    <img
      *ngIf="!isImageError"
      [src]="imagePreviewUrl"
      (error)="erroredImage($event)"
      (load)="loadedImage($event)"
    />
    <div class="video-button-holder"></div>
  </div>

  <div class="preview-item-container-error" *ngIf="isError">
    Data missing
    <img
      class="preview-item-container-error-icon"
      src="assets/import-error.svg"
    />
  </div>

  <div class="preview-item-container-selection">
    <div></div>
  </div>
</div>
