import { AutocompleteOption } from './field-text/field-text.component';

export class ComputeFields {
  static computeMultipleAutocompleteFieldValue<T>(
    selectedIds: number[],
    itemsById: { [key: number]: T },
    selector: (arg0: T) => AutocompleteOption[] | undefined
  ): AutocompleteOption[] {
    // No selection case.
    if (selectedIds.length == 0) return []; // No value

    if (selectedIds.length == 1) {
      let item = itemsById[selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return []; // No value
    }

    // Fetch selected items.
    let selectedItems = selectedIds
      .map((id) => itemsById[id])
      .filter((i) => i != undefined);
    let values: string[] = selectedItems.map(
      (item) =>
        selector(item)
          ?.map((t) => t.title ?? '')
          .join(', ') ?? ''
    );

    let allEqualFunc = (arr: string[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let item = itemsById[selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return [];
    } else {
      return [{ value: -3, title: '<multiple values>', isSelectable: false }]; // Multiple values.
    }
  }

  static computeAutocompleteFieldValue<T>(
    selectedIds: number[],
    itemsById: { [key: number]: T },
    selector: (arg0: T) => AutocompleteOption | undefined
  ): AutocompleteOption {
    // No selection case.
    if (selectedIds.length == 0)
      return { title: '', value: -1, isSelectable: false };

    if (selectedIds.length == 1) {
      let item = itemsById[selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return { title: '', value: -1, isSelectable: false };
    }

    // Fetch selected items.
    let selectedItems = selectedIds
      .map((id) => itemsById[id])
      .filter((i) => i != undefined);
    let values: (string | undefined)[] = selectedItems.map(
      (item) => selector(item)?.title
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return selector(selectedItems[0])!;

      return { title: '', value: -1, isSelectable: false };
    } else {
      return { title: '<multiple values>', value: -3, isSelectable: false };
    }
  }

  static computeFieldValue<T>(
    selectedIds: number[],
    itemsById: { [key: number]: T },
    selector: (arg0: T) => string | undefined
  ): string {
    // No selection case.
    if (selectedIds.length == 0) return '';

    if (selectedIds.length == 1) {
      let item = itemsById[selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return '';
    }

    // Fetch selected items.
    let selectedItems = selectedIds
      .map((id) => itemsById[id])
      .filter((i) => i != undefined);
    let values: (string | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return '';
    } else {
      return '<multiple values>';
    }
  }

  static computeBooleanFieldValue<T>(
    selectedIds: number[],
    itemsById: { [key: number]: T },
    selector: (arg0: T) => boolean | undefined
  ): boolean {
    // No selection case.
    if (selectedIds.length == 0) return false;

    if (selectedIds.length == 1) {
      let item = itemsById[selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return false;
    }

    // Fetch selected items.
    let selectedItems = selectedIds
      .map((id) => itemsById[id])
      .filter((i) => i != undefined);
    let values: (boolean | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return false;
    } else {
      // TODO: Not true or false!
      return true;
    }
  }

  static computeNumberFieldValue<T>(
    selectedIds: number[],
    itemsById: { [key: number]: T },
    selector: (arg0: T) => number | undefined
  ): number {
    // No selection case.
    if (selectedIds.length == 0) return 0.0;

    if (selectedIds.length == 1) {
      let item = itemsById[selectedIds[0]];
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return 0.0;
    }

    // Fetch selected items.
    let selectedItems = selectedIds
      .map((id) => itemsById[id])
      .filter((i) => i != undefined);
    let values: (number | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return 0.0;
    } else {
      // TODO: Not true or false!
      return 0.0;
    }
  }
}
