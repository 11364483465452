import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  DynamicView,
  DynamicViewsService as ApiDynamicViewsService,
} from 'piwe-front-swagger-client';

@Injectable({
  providedIn: 'root',
})
export class DynamicViewService {
  private dynamicViews?: DynamicView[];

  private dynamicViewsSource = new BehaviorSubject<DynamicView[] | undefined>(
    undefined
  );
  public dynamicViewsObservable: Observable<DynamicView[] | undefined> =
    this.dynamicViewsSource.asObservable();

  constructor(private apiDynamicViewsService: ApiDynamicViewsService) {}

  loadDynamicViews(): void {
    this.apiDynamicViewsService
      .cmsGetDynamicViews()
      .subscribe((dynamicViews: DynamicView[]) => {
        this.dynamicViews = dynamicViews;
        this.dynamicViewsSource.next(dynamicViews);
      });
  }
}
