import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CmsComponent } from './cms/cms.component';
import { FrontComponent } from './front/front.component';
import { AuthenticationGuard } from './authentication.guard';
import { PathResolveService } from './path-resolve.service';
import { Error404Component } from './error404/error404.component';
import { CmsGuard } from './cms.guard';
import { Error500Component } from './error500/error500.component';

const routes: Routes = [
  {
    path: '500',
    component: Error500Component,
  },
  {
    path: '',
    component: FrontComponent,
    loadChildren: () =>
      import('./front/front.module').then((m) => m.FrontModule),
  },
  {
    path: 'cms',
    component: CmsComponent,
    loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule),
    canActivate: [AuthenticationGuard, CmsGuard],
  },
  {
    path: '**',
    resolve: {
      path: PathResolveService,
    },
    component: Error404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
