/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Agency } from './agency';
import { Author } from './author';
import { Category } from './category';
import { Keyword } from './keyword';
import { LicenceType } from './licenceType';
import { MediaItemLock } from './mediaItemLock';
import { MediaPreivew } from './mediaPreivew';
import { Person } from './person';
import { SpecialOfferType } from './specialOfferType';
import { Tag } from './tag';
import { Translatable } from './translatable';

export interface ProductUpdateItem {
  /**
   * UUID client side generated
   */
  id: string;
  agency?: Agency;
  api_ftp?: boolean;
  aspect_ratio?: number;
  author?: Author;
  author_id: number;
  caption: Array<Translatable>;
  caption_suffix?: string;
  category?: Category;
  categories?: Array<Category>;
  date_created?: string;
  date_imported?: string;
  age_licence?: string;
  age_collection?: string;
  editing_lock?: MediaItemLock;
  /**
   * If we don't send a gallery_id then create a gallery from headline on publish
   */
  gallery_id?: number;
  has_preview?: boolean;
  /**
   * If we don't send a gallery_id then create a gallery from headline on publish
   */
  headline?: Array<Translatable>;
  is_special_offer?: boolean;
  keywords_list?: Array<Keyword>;
  kind: ProductUpdateItem.KindEnum;
  licences?: Array<LicenceType>;
  needs_translation?: boolean;
  people_list?: Array<Person>;
  preview?: MediaPreivew;
  special_offer_types?: Array<SpecialOfferType>;
  tag_list?: Array<Tag>;
  web_price?: number;
  print_price?: number;
  currency?: string;
  product_name?: string;
  file_name?: string;
  video_title?: Array<Translatable>;
}
export namespace ProductUpdateItem {
  export type KindEnum = 'PHOTO' | 'VIDEO';
  export const KindEnum = {
    PHOTO: 'PHOTO' as KindEnum,
    VIDEO: 'VIDEO' as KindEnum,
  };
}
