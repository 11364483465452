import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IHomepageTemplate } from 'src/app/cms/web-administration/homepage-administration/homepage-administration.service';
import { SnackBarService } from 'src/app/share/snack-bar.service';

@Component({
  selector: 'app-page-card',
  templateUrl: './page-card.component.html',
  styleUrls: ['./page-card.component.scss'],
})
export class PageCardComponent implements OnInit {
  @Input() data!: IHomepageTemplate;
  public isContextMenuOpen: boolean = false;

  @Output() editTemplateEvent: EventEmitter<void> = new EventEmitter();
  @Output() publishTemplateEvent: EventEmitter<void> = new EventEmitter();
  @Output() renameTemplateEvent: EventEmitter<string> = new EventEmitter();
  @Output() duplicateTemplateEvent: EventEmitter<void> = new EventEmitter();
  @Output() openActivityLogTemplateEvent: EventEmitter<void> =
    new EventEmitter();
  @Output() deleteTemplateEvent: EventEmitter<void> = new EventEmitter();

  public isBeingRenamed: boolean = false;
  public templateName: string = '';

  @ViewChild('templateNameInput') templateNameInput!: ElementRef;

  @Input() isPublishable: boolean = true;
  @Input() contextMenuType: 'published' | 'draft' | 'aboutUs' = 'draft';

  constructor(private snackBarService: SnackBarService) {}

  ngOnInit(): void {}

  openContextMenu() {
    this.isContextMenuOpen = true;
  }
  closeContextMenu() {
    this.isContextMenuOpen = false;
  }

  editTemplate() {
    this.closeContextMenu();
    this.editTemplateEvent.emit();
  }
  publishTemplate() {
    this.closeContextMenu();
    if (this.isPublishable) {
      this.publishTemplateEvent.emit();
    } else {
      this.snackBarService.showSnackBar(
        "This template can't be published because it isn't fully populated",
        'ERROR'
      );
    }
  }

  enableRename() {
    this.templateName = '';

    this.isBeingRenamed = true;
    this.closeContextMenu();
    setTimeout(() => {
      this.templateNameInput.nativeElement.focus();
    }, 0);
  }
  disableRename() {
    this.isBeingRenamed = false;
  }
  renameTemplate() {
    this.isBeingRenamed = false;

    this.renameTemplateEvent.emit(this.templateName);
  }

  duplicateTemplate() {
    this.closeContextMenu();
    this.duplicateTemplateEvent.emit();
  }
  openActivityLog() {
    this.closeContextMenu();
    this.openActivityLogTemplateEvent.emit();
  }
  deleteTemplate() {
    this.closeContextMenu();
    this.deleteTemplateEvent.emit();
  }
}
