import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  AgenciesService,
  Agency,
  SpecialInstructions,
  SpecialInstructionsService,
  UserRights,
  UserRightsService,
} from 'piwe-front-swagger-client';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  constructor(
    private agenciesService: AgenciesService,
    private userRightsService: UserRightsService,
    private specialInstructionsService: SpecialInstructionsService
  ) {}

  // _formValidityObject keeps track of form validity :)
  // ----------validity logic explanation----------
  // on each ngOnInit => the validity is set to false
  // on ngOnDestroy => validity is checked and validityObject is updated
  // on each submit check validity of current form, if its invalid, mark as dirty and touched
  // if its valid, changed validity object and navigate to the first invalid tab
  // if all tabs are valid, POST
  private _formValidityObject = {
    isGeneralValid: false,
    isPriceAndContractsValid: false,
    isIptcValid: false,
    isSettingsValid: false,
  };
  get formValidityObject() {
    return this._formValidityObject;
  }

  set generalValidity(value: boolean) {
    this._formValidityObject.isGeneralValid = value;
  }

  set priceAndContractsValidity(value: boolean) {
    this._formValidityObject.isPriceAndContractsValid = value;
  }

  set iptcValidity(value: boolean) {
    this._formValidityObject.isIptcValid = value;
  }

  set settingsValidity(value: boolean) {
    this._formValidityObject.isSettingsValid = value;
  }

  private _agencyData: any = {};
  public agencyData$ = new Subject();

  get agencyData() {
    return this._agencyData;
  }

  set agencyData(data: any) {
    this._agencyData = data;
    this.agencyData$.next(this.agencyData);
  }

  deserializeAgency(agency: Agency): any {
    return {
      id: agency.id,
      agency_name: agency.name,
      slug: agency.slug,
      email: agency.email,
      web: agency.web,
      currency: agency.currency,
      street_and_number: agency.street_and_number,
      zip: agency.zip,
      city: agency.city,
      country: agency.country,
      contact_person: agency.contact_person,
      contact_number: agency.contact_number,
      contact_email: agency.contact_email,
      note: agency.note,
      var_large_price: agency.var_large_price,
      var_small_price: agency.var_small_price,
      contracts_bellow_limit: agency.contracts_bellow_limit,
      contracts_above_limit: agency.contracts_above_limit,
      contract_limit: agency.contract_limit,
      is_active: agency.is_active,
      is_public: agency.is_public,
      is_visible_in_rss: agency.is_visible_in_rss,
      positon_in_rss: agency.positon_in_rss,
      content_type: agency.content_type,
      has_newsletter_group: false,
      newsletter_group: undefined,
      import_type: agency.import_type,
      agency_folder_name: agency.agency_folder_name,
      contains_subfolder: agency.contains_subfolder,
      agency_folder_name2: agency.agency_folder_name2,
      iptc_identificators: agency.iptc_identificators,
      iptc_tags: agency.iptc_tags,
      description: agency.description,
      sufix: agency.sufix,
      prefix: agency.prefix,
      uses_iptc_fields: agency.uses_iptc_fields,
      specialInstructions: agency.special_instructions?.map((i) => {
        return {
          id: i.id,
          name: i.name,
          active: true,
        };
      }),
      userRights: agency.user_rights,
      blocked_countries: agency.blocked_countries,
      allowed_countries: agency.allowed_countries,
      special_instructions: agency.special_instructions?.map((i) => {
        return {
          id: i.id,
          name: i.name,
          active: true,
        };
      }),
      user_rights: agency.user_rights?.map((i) => {
        return {
          id: i.id,
          name: i.name,
          active: true,
        };
      }),
      iptc_after_download: agency.iptc_after_download,
    };
  }

  parseBoolean(value: any): any {
    if (typeof value == 'boolean') {
      return value;
    } else if (typeof value === 'string' || value instanceof String) {
      return value == 'true';
    }

    return false;
  }

  parseFloat(value: any): number {
    if (value == undefined) return 0.0;

    if (typeof value == 'number') return value;
    if (typeof value == 'string') return this.parseFloat(value);

    return 0.0;
  }

  serializeAgency(agency: any): Agency {
    //console.log("SERIALIZE", agency);

    let contentType = agency.content_type;
    if (contentType === '') {
      contentType = undefined;
    }

    let importType = agency.import_type;
    if (importType === '') {
      importType = undefined;
    }

    let positionInRss = 0;
    if ((positionInRss as unknown) === '') {
      positionInRss = agency.positon_in_rss;
    }

    return {
      id: agency.id,
      name: agency.agency_name,
      slug: agency.slug,
      currency: agency.currency,
      email: agency.email,
      web: agency.web,
      street_and_number: agency.street_and_number,
      zip: agency.zip,
      city: agency.city,
      country: agency.country,
      contact_person: agency.contact_person,
      contact_number: agency.contact_number,
      contact_email: agency.contact_email,
      note: agency.note,
      var_large_price: agency.var_large_price,
      var_small_price: agency.var_small_price,
      contracts_bellow_limit: agency.contracts_bellow_limit,
      contracts_above_limit: agency.contracts_above_limit,
      contract_limit: agency.contract_limit,
      is_active: this.parseBoolean(agency.is_active),
      is_public: this.parseBoolean(agency.is_public),
      is_visible_in_rss: this.parseBoolean(agency.is_visible_in_rss),
      positon_in_rss: positionInRss,
      content_type: contentType,
      import_type: importType,
      agency_folder_name: agency.agency_folder_name,
      contains_subfolder: this.parseBoolean(agency.contains_subfolder),
      agency_folder_name2: agency.agency_folder_name2,
      iptc_identificators: agency.iptc_identificators,
      iptc_tags: agency.iptc_tags,
      description: agency.description,
      sufix: agency.sufix,
      prefix: agency.prefix,
      uses_iptc_fields: agency.uses_iptc_fields,
      special_instructions: agency.special_instructions,
      user_rights: agency.user_rights,
      blocked_countries: agency.blocked_countries,
      allowed_countries: agency.allowed_countries,
      iptc_after_download: agency.iptc_after_download,
    };
  }

  getAgencyData(id: number | string): Observable<any> {
    let number: number = id as number;

    return this.agenciesService.cmsGetAgency(number).pipe(
      map((r) => {
        return this.deserializeAgency(r);
      })
    );
  }

  patchAgencyData(id: number | string, data: any) {
    return this.agenciesService
      .cmsPutAgency(
        id as number,
        this.serializeAgency({
          ...data,
        })
      )
      .subscribe((response) => {
        const agency = response;
        this.agencyData = this.deserializeAgency(agency);
      });
  }

  postNewAgency() {
    return this.agenciesService
      .cmsPostAgencies(this.serializeAgency(this.agencyData))
      .subscribe((response) => {
        const agency = response;
        this.agencyData = this.deserializeAgency(agency);
      });
  }

  postSpecialInstruction(
    specialInstructions: SpecialInstructions
  ): Observable<any> {
    return this.specialInstructionsService
      .cmsPostSpecialInstructions(specialInstructions)
      .pipe(
        map((r) => {
          return {
            id: r.id!,
            name: r.name!,
            text: r.name!,
          };
        })
      );
  }

  getSpecialInstructions(): Observable<any[]> {
    return this.specialInstructionsService.cmsGetSpecialInstructions().pipe(
      map((r) => {
        return r.special_instruction_list!.map((i) => {
          return {
            id: i.id!,
            name: i.name!,
            text: i.name!,
          };
        });
      })
    );
  }

  getUserRights(): Observable<any[]> {
    return this.userRightsService.cmsGetUserRights().pipe(
      map((r) => {
        return r.user_rights_list!.map((i) => {
          return {
            id: i.id!,
            name: i.name!,
            text: i.name!,
          };
        });
      })
    );
  }

  postUserRights(userRights: UserRights): Observable<any> {
    return this.userRightsService.cmsPostUserRights(userRights).pipe(
      map((r) => {
        return {
          id: r.id!,
          name: r.name!,
          text: r.name!,
        };
      })
    );
  }
}
