import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutocompleteOption } from '../../../../field-text/field-text.component';

@Component({
  selector: 'app-sidebar-tags',
  templateUrl: './sidebar-tags.component.html',
  styleUrls: ['./sidebar-tags.component.scss'],
})
export class SidebarTagsComponent implements OnInit {
  @Input() controlName!: string;
  @Input() form!: FormGroup;
  @Input() isRequired: boolean = false;
  @Input() label: string = '';

  @Input() tags: Set<string> = new Set();
  @Output() tagsChange: EventEmitter<Set<string>> = new EventEmitter();

  @ViewChild('tagInput') tagInput!: ElementRef;

  @Output() removedTag: EventEmitter<AutocompleteOption> = new EventEmitter();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.isRequired)
      this.form.addControl(
        this.controlName,
        this.formBuilder.control('', Validators.required)
      );
    else this.form.addControl(this.controlName, this.formBuilder.control(''));
  }

  handleEnter() {
    const tag = this.form.get(this.controlName)?.value;
    if (this.tags.has(tag)) {
      this.tagInput.nativeElement.setCustomValidity('This tag already exists.');
      this.tagInput.nativeElement.reportValidity();
      this.tagInput.nativeElement.setCustomValidity('');
      return;
    }

    if (this.tagInput.nativeElement.checkValidity() && tag !== '') {
      this.tags.add(tag);
      this.tagsChange.emit(this.tags);
      this.form.get(this.controlName)?.setValue('');
      return;
    }

    this.tagInput.nativeElement.reportValidity();
  }
  removeTag(tag: string) {
    this.tags.delete(tag);
    this.tagsChange.emit(this.tags);
  }
}
