import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ContextMenuService } from '../../context-menu.service';

type Action = {
  id: string;
  title: string;
};

@Component({
  selector: 'app-context-menu-overlay',
  templateUrl: './context-menu-overlay.component.html',
  styleUrls: ['./context-menu-overlay.component.scss'],
})
export class ContextMenuOverlayComponent implements OnInit {
  active: boolean = false;
  posX: number = 0;
  posY: number = 0;
  itemId?: number;

  actions: Action[] = [
    { id: 'preview', title: 'Preview' },
    { id: 'log', title: 'Log information' },
    { id: 'iptc', title: 'Show Full Exif' },
    { id: 'replace', title: 'Replace image' },
  ];

  constructor(
    private contextMenuService: ContextMenuService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  targetInsideMainElementTagName(target: HTMLElement, tagName: string) {
    var parent: HTMLElement | null = target;
    var i = 0;
    while (parent != null) {
      if (parent.tagName == tagName) {
        this.itemId = parseInt(parent.getAttribute('DATA-ID')!);
        return true;
      }

      parent = parent.parentElement;

      i += 1;

      if (i >= 20)
        // If this runs away, kill this loop.
        break;
    }

    return false;
  }

  targetInsideMainElementClassName(target: HTMLElement, className: string) {
    var parent: HTMLElement | null = target;
    var i = 0;
    while (parent != null) {
      if (parent.className.includes(className)) return true;

      parent = parent.parentElement;

      i += 1;

      if (i >= 20)
        // If this runs away, kill this loop.
        break;
    }

    return false;
  }

  actionSelected(action: Action) {
    this.active = false;
    this.contextMenuService.selectedAction(action, this.itemId!);
  }

  @HostListener('document:click', ['$event'])
  documentClicked(event: MouseEvent) {
    let target: HTMLElement = event.target as HTMLElement;

    if (this.targetInsideMainElementClassName(target, 'context-menu-overlay')) {
    } else {
      this.active = false;
    }
  }

  @HostListener('document:contextmenu', ['$event'])
  documentContextMenu(event: MouseEvent) {
    //ONLY ENABLED IF ROUTE STARTS WITH CMS
    if (this.router.url.split('/')[1] === 'cms') {
      let target: HTMLElement = event.target as HTMLElement;

      if (
        this.targetInsideMainElementTagName(target, 'APP-MAIN-PREVIEW-ITEM')
      ) {
        event.preventDefault();

        this.active = true;
        this.posX = event.pageX;
        this.posY = event.pageY;
      }
    }
  }
}
