import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Observable, of, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { NewsletterStatus, ProfileService } from 'piwe-front-swagger-client';
import {
  concatMap,
  filter,
  map,
  mergeMap,
  switchMapTo,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import logging from './logging';

@Injectable({
  providedIn: 'root',
})
export class NewsletterPopupService {
  private static URL_THAT_TRIGGER = [
    '/',
    '/production',
    '/video',
    '/agency',
    '/stock',
    '/agefotostock',
  ];

  private shouldShowNewsletterPopupSource = new Subject<boolean>();
  public shouldShowNewsletterPopupData: Observable<boolean> =
    this.shouldShowNewsletterPopupSource.asObservable();

  private newsletterStatus?: NewsletterStatus;

  private alreadyShownInSession = false;

  constructor(
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private router: Router
  ) {
    this.authenticationService.isAuthenticatedData
      .pipe(
        concatMap((v) => this.profileService.frontGetNewsletterStatus()),
        filter((value) => {
          return value !== undefined;
        })
      )
      .subscribe((value) => {
        this.newsletterStatus = value;
      });

    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter((v) => this.authenticationService.isAuthenticated()),
        map((value) => {
          const mapped = value as NavigationEnd;
          const pathName = mapped.url.split('?')[0];
          return pathName;
        }),
        filter((t) => NewsletterPopupService.URL_THAT_TRIGGER.includes(t)),
        concatMap((_) => {
          if (this.newsletterStatus !== undefined) {
            return of(this.newsletterStatus); // Return cached.
          }

          return this.profileService.frontGetNewsletterStatus();
        }),
        tap((value) => {
          if (value !== undefined) {
            this.newsletterStatus = value;
          }
        }),
        filter((v) => v.status === null),
        filter((v) => !this.alreadyShownInSession),
        tap((v) => {
          this.alreadyShownInSession = true; // Once we show it we should never show it once again in a session.
        })
      )
      .subscribe((value) => {
        this.shouldShowNewsletterPopupSource.next(true);
      });
  }

  saveStatus(
    status:
      | 'PRESENTED'
      | 'PRESENTED_AND_CLOSED'
      | 'SENT_WITH_SUCCESS'
      | 'SENT_WITH_FAILURE'
  ): Observable<NewsletterStatus> {
    return this.profileService.frontUpdateNewsletterStatus({ status }).pipe(
      tap((value) => {
        this.newsletterStatus = value;
      })
    );
  }
}
