import { Injectable } from '@angular/core';
import { ProfileService } from './profile.service';
import { map, skip, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'piwe-front-swagger-client';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private loadedPermissions?: User.UserPermissionsEnum[];

  private permissionsSource = new BehaviorSubject<
    User.UserPermissionsEnum[] | undefined
  >(undefined);
  public permissionsData: Observable<User.UserPermissionsEnum[] | undefined> =
    this.permissionsSource.asObservable();

  constructor(
    private profileService: ProfileService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.isAuthenticatedData.subscribe(
      (isAuthenticated) => {
        if (isAuthenticated) {
          this.getPermissions().subscribe((permissions) => {
            this.loadedPermissions = permissions;
            this.permissionsSource.next(permissions);
          });
        } else {
          this.loadedPermissions = undefined;
          this.permissionsSource.next(undefined);
        }
      }
    );
  }

  getPermissions(): Observable<User.UserPermissionsEnum[] | undefined> {
    return this.profileService.profileData.pipe(
      skip(1),
      take(1),
      map((user) => {
        return user?.user_permissions;
      })
    );
  }
}
