import { Injectable } from '@angular/core';
import {
  CartPaymentGatewayResponse,
  CartService as ApiCartService,
  ProductsFrontService,
} from 'piwe-front-swagger-client';
import {
  AddToCart,
  Cart,
  IdsList,
} from 'projects/piwe-front-swagger-client/src';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingService } from './shared/loading.service';
import { SnackBarService } from './share/snack-bar.service';

export interface ICartUpdateSubject {
  cartItemCount: number;
  itemName?: string;
  itemImgUrl?: string;
  cartNewItemCount?: number;
  //where it gets emitted add as object
  //on add to cart add itemname and url
}

export interface IVariationsObject {
  print: boolean;
  web: boolean;
  video: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private _cartItemCount: number = 0;
  public cartUpdateSubject: Subject<ICartUpdateSubject> = new Subject();

  private itemName: string | null = null;
  private itemImgUrl: string | null = null;
  private cartNewItemCount: number = 1;

  public cartLines: Cart['lines'] = [];

  constructor(
    private apiCartService: ApiCartService,
    private productsFrontService: ProductsFrontService,
    private loadingService: LoadingService,
    private snackBarService: SnackBarService
  ) {}

  calculateProductPrice(id: number | string, source: string | undefined) {
    return this.productsFrontService.frontCalculateProductPrice(
      id.toString(),
      source
    );
  }

  addCartLine(
    productId: number | string,
    variation: AddToCart.VariationEnum,
    signature: boolean,
    source: string | undefined
  ) {
    const body: AddToCart = {
      product_id: productId as any,
      variation: variation,
      download_with_author_signature: signature,
      source: source as AddToCart.SourceEnum | undefined,
    };

    // @ts-ignore
    return this.apiCartService.frontAddCartLine(body).pipe(
      tap((response: Cart) => {
        let line: any;
        //@ts-ignore
        if (response.lines?.length > 0) {
          //@ts-ignore
          const differencesArray = response.lines.filter(
            //@ts-ignore
            ({ id: rId }) => !this.cartLines.some(({ id: cId }) => rId === cId)
          );
          line = differencesArray[0];
        } else {
          line = response.lines![0];
        }

        this.cartLines = response.lines;
        if (line) {
          this.itemName = line.title;
          this.itemImgUrl = line.previews[0].preview_url;
        }

        const numberOfNewItems = response.lines?.length ?? 0;

        this.cartNewItemCount = numberOfNewItems - this.cartItemCount;

        this.cartItemCount = numberOfNewItems;
      })
    );
  }

  deleteCartLineItem(id: number) {
    const body: IdsList = { ids: [id] };
    return this.apiCartService.frontDeleteCartLineItems(body).pipe(
      tap((r) => {
        this.decrementCartItemCount();
        this.cartLines = r.lines;
      })
    );
  }

  getCart() {
    return this.apiCartService.frontGetCart().pipe(
      tap((r: Cart) => {
        this.cartItemCount = r.lines?.length ?? 0;
        this.cartLines = r.lines;
        this.cartUpdated();
      })
    );
  }

  updateCartLine(id: number, signature: boolean) {
    const body = {
      download_with_author_signature: signature,
    };
    return this.apiCartService.frontUpdateCartLine(id, body);
  }

  get cartItemCount() {
    return this._cartItemCount;
  }

  set cartItemCount(value: number) {
    this._cartItemCount = value;
    this.cartUpdated();
  }

  private incrementCartItemCount(value: number = 1) {
    this.cartItemCount += value;
  }

  private decrementCartItemCount() {
    if (this.cartItemCount > 0) this.cartItemCount -= 1;
    if (this.cartItemCount < 0) this.cartItemCount = 0;
  }

  private cartUpdated() {
    const cartUpdateObject: ICartUpdateSubject = {
      cartItemCount: this.cartItemCount,
      cartNewItemCount: this.cartNewItemCount,
    };
    if (this.itemName && this.itemImgUrl) {
      cartUpdateObject.itemName = this.itemName;
      cartUpdateObject.itemImgUrl = this.itemImgUrl;
    }
    this.cartUpdateSubject.next(cartUpdateObject);

    this.itemName = null;
    this.itemImgUrl = null;
    this.cartNewItemCount = 1;
  }

  public checkIfProductExistsInCart(productId: number | string) {
    const lines = this.cartLines?.filter(
      ({ product_id }) => product_id === productId
    );
    const variations: IVariationsObject = {
      print: false,
      web: false,
      video: false,
    };
    if (lines)
      for (const line of lines) {
        if (line.variation === 'PRINT') variations.print = true;
        if (line.variation === 'WEB') variations.web = true;
        if (line.is_video === true) variations.video = true;
      }

    return variations;
    // return !!this.cartLines?.filter(
    //   ({ product_id }) => product_id === productId
    // ).length;
  }

  downloadPreview(id: number | string, source: string | undefined) {
    return this.productsFrontService.frontDownloadProductPreview(
      id as any,
      source
    );
  }

  downloadFullProduct(
    id: number | string,
    kind: string,
    has_author_signature: boolean,
    source: string | undefined
  ) {
    return this.productsFrontService.frontDownloadFullProduct(
      id as any,
      kind,
      has_author_signature,
      source
    );
  }

  downloadFullProductLink(
    id: number | string,
    kind: string,
    has_author_signature: boolean,
    source: string | undefined
  ) {
    return this.productsFrontService.frontDownloadFullCreateLink(
      id as any,
      kind,
      has_author_signature,
      source
    );
  }

  downloadZip() {
    return this.apiCartService.frontDownloadCartZip();
    //cart/id/download
  }

  createCartPayment(paymentMethod: Cart.AvailablePaymentMethodsEnum) {
    const body = {
      payment_method: paymentMethod,
    };
    return this.apiCartService.frontCreateCartPayment(body);
  }

  paymentFinalize(body: CartPaymentGatewayResponse) {
    return this.apiCartService.frontFinalizeCartPayment(body);
  }

  sendToSystem(
    productId: number | string,
    variation: AddToCart.VariationEnum,
    signature: boolean,
    source: string | undefined
  ) {
    this.loadingService.setLoading(true);
    this.productsFrontService
      .frontRunProductAction(
        productId as any,
        'SEND_TO_SYSTEM',
        variation,
        signature,
        source
      )
      .subscribe(
        (result) => {
          this.loadingService.setLoading(false);
          this.snackBarService.showSnackBar(
            'Uspješno poslano u sistem.',
            'INFO'
          );
        },
        (error) => {
          this.loadingService.setLoading(false);
          this.snackBarService.showSnackBar('Nešto je pošlo po zlu.', 'ERROR');
        }
      );
  }

  sendToCms(
    productId: number | string,
    variation: AddToCart.VariationEnum,
    signature: boolean,
    source: string | undefined
  ) {
    this.loadingService.setLoading(true);
    this.productsFrontService
      .frontRunProductAction(
        productId as any,
        'SEND_TO_CMS',
        variation,
        signature,
        source
      )
      .subscribe(
        (result) => {
          this.loadingService.setLoading(false);
          this.snackBarService.showSnackBar('Uspješno poslano u CMS.', 'INFO');
        },
        (error) => {
          this.loadingService.setLoading(false);
          this.snackBarService.showSnackBar('Nešto je pošlo po zlu.', 'ERROR');
        }
      );
  }
}
