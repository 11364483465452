import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-slider-switch',
  templateUrl: './slider-switch.component.html',
  styleUrls: ['./slider-switch.component.scss'],
})
export class SliderSwitchComponent implements OnInit {
  public checkbox: boolean = false;
  @Output() switchToggledEvent: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleModelChange() {
    this.switchToggledEvent.emit(this.checkbox);
  }
}
