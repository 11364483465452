<!-- <app-image-side-right [product]="product"></app-image-side-right> -->

<div class="cart-menu-wrapper" (click)="handleClick()" @backgroundAnimation>
  <div class="cart-menu" (click)="stopPropagation($event)" @sidebarAnimation>
    <div *ngIf="!isDownloadDoneModalOpen">
      <div class="menu-header">
        <div class="title">
          {{
            variant == 1
              ? ("purchase_sidebar.add_to_cart" | translate)
              : ("purchase_sidebar.download" | translate)
          }}
        </div>
        <button class="btn" (click)="handleClick()"></button>
      </div>
      <app-image-side-right
        [productId]="productId"
        [isDownload]="variant === 2 ? true : false"
        (downloadCompletedEvent)="downloadCompleted()"
        [productCount]="productCount"
        (addedToCartEvent)="onAddedToCart()"
        [isGallery]="isGallery"
        [source]="source"
      ></app-image-side-right>
    </div>
  </div>

  <div class="download-menu__wrapper" *ngIf="isDownloadDoneModalOpen">
    <div class="download-menu" (click)="stopPropagation($event)">
      <button class="download-menu__close-btn" (click)="handleClick()"></button>
      <img
        class="download-menu__img"
        src="/assets/download-ready.svg"
        alt="Download ready"
      />
      <div
        class="download-menu__title"
        [innerHTML]="'purchase_sidebar.downloaded_soon' | translate"
      >
        Sadržaj će se uskoro preuzeti!
      </div>
      <div class="download-menu__text">
        Ako se sadržaj ne preuzme automatski
        <a href="#">kliknite ovdje -- dodaj link</a>
      </div>
    </div>
  </div>
</div>
