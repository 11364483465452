<div class="card">
  <div class="card__img-container">
    <img
      class="card__img"
      alt=""
      src="{{ 'ap2/' + cardData.product_list[0]?.preview_url }}"
    />
  </div>
  <div class="card__body">
    <div class="card__title">
      {{ cardData?.headline[0].content }}
    </div>
    <div class="card__tags">
      <div class="card__tag" *ngFor="let tag of cardData.tags_list">
        {{ tag.name[0].content }}
      </div>
    </div>
    <div class="card__details">
      <span class="card__details-title">Publish date: </span>

      <span class="card__details-number">{{
        cardData.created_date | date : "dd.MM.yyyy."
      }}</span>
    </div>
    <div class="card__details">
      <span class="card__details-title">Number of products: </span>
      <span class="card__details-number">{{
        cardData.product_list.length
      }}</span>
    </div>
  </div>
</div>
