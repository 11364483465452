/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContractLine } from './contractLine';

export interface UpdateDownload {
  currency?: UpdateDownload.CurrencyEnum;
  contract_item?: ContractLine;
  download_price?: number;
  comment?: string;
}
export namespace UpdateDownload {
  export type CurrencyEnum = 'HRK' | 'EUR';
  export const CurrencyEnum = {
    HRK: 'HRK' as CurrencyEnum,
    EUR: 'EUR' as CurrencyEnum,
  };
}
