/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AgencyPreview } from './agencyPreview';
import { AutocompleteItem } from './autocompleteItem';

export interface ContractLine {
  id?: number;
  line_type?: ContractLine.LineTypeEnum;
  line_name?: string;
  from?: number;
  to?: number;
  flat_rate?: number;
  percentage?: number;
  agencies?: Array<AgencyPreview>;
  agency_groups?: Array<AutocompleteItem>;
  state_used?: number;
  state_total?: number;
  group_name?: string;
  line_price?: number;
  invoice_product?: ContractLine.InvoiceProductEnum;
  content_type?: ContractLine.ContentTypeEnum;
}
export namespace ContractLine {
  export type LineTypeEnum = 'FLAT_RATE' | 'PERCENTAGE';
  export const LineTypeEnum = {
    FLATRATE: 'FLAT_RATE' as LineTypeEnum,
    PERCENTAGE: 'PERCENTAGE' as LineTypeEnum,
  };
  export type InvoiceProductEnum =
    | 'PD_AgF_D'
    | 'PD_AgV_D'
    | 'PD_PxF_D'
    | 'PD_PxF_K'
    | 'PD_PxF_S_A'
    | 'PD_PxF_S_D'
    | 'PD_PxV_D'
    | 'PojedinacniDownload'
    | 'Predujam'
    | 'RP_AgF_D'
    | 'RP_AgV_D'
    | 'RP_PxF_D'
    | 'RP_PxV_D'
    | 'UgMN_AgF_D'
    | 'UgMN_AgV_D'
    | 'UgMN_PxF_D'
    | 'UgMN_PxV_D'
    | 'UgRa_AgF_D'
    | 'UgRa_AgV_D'
    | 'UgRa_PxF_D'
    | 'UgRa_PxV_D';
  export const InvoiceProductEnum = {
    PDAgFD: 'PD_AgF_D' as InvoiceProductEnum,
    PDAgVD: 'PD_AgV_D' as InvoiceProductEnum,
    PDPxFD: 'PD_PxF_D' as InvoiceProductEnum,
    PDPxFK: 'PD_PxF_K' as InvoiceProductEnum,
    PDPxFSA: 'PD_PxF_S_A' as InvoiceProductEnum,
    PDPxFSD: 'PD_PxF_S_D' as InvoiceProductEnum,
    PDPxVD: 'PD_PxV_D' as InvoiceProductEnum,
    PojedinacniDownload: 'PojedinacniDownload' as InvoiceProductEnum,
    Predujam: 'Predujam' as InvoiceProductEnum,
    RPAgFD: 'RP_AgF_D' as InvoiceProductEnum,
    RPAgVD: 'RP_AgV_D' as InvoiceProductEnum,
    RPPxFD: 'RP_PxF_D' as InvoiceProductEnum,
    RPPxVD: 'RP_PxV_D' as InvoiceProductEnum,
    UgMNAgFD: 'UgMN_AgF_D' as InvoiceProductEnum,
    UgMNAgVD: 'UgMN_AgV_D' as InvoiceProductEnum,
    UgMNPxFD: 'UgMN_PxF_D' as InvoiceProductEnum,
    UgMNPxVD: 'UgMN_PxV_D' as InvoiceProductEnum,
    UgRaAgFD: 'UgRa_AgF_D' as InvoiceProductEnum,
    UgRaAgVD: 'UgRa_AgV_D' as InvoiceProductEnum,
    UgRaPxFD: 'UgRa_PxF_D' as InvoiceProductEnum,
    UgRaPxVD: 'UgRa_PxV_D' as InvoiceProductEnum,
  };
  export type ContentTypeEnum = 'PHOTO' | 'VIDEO' | 'PHOTO_VIDEO';
  export const ContentTypeEnum = {
    PHOTO: 'PHOTO' as ContentTypeEnum,
    VIDEO: 'VIDEO' as ContentTypeEnum,
    PHOTOVIDEO: 'PHOTO_VIDEO' as ContentTypeEnum,
  };
}
