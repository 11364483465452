/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FinanceAdministartionItemItem {
  sort?: string;
  contract_type?: string;
  content_type?: string;
  agency?: string;
  product_name_CMS?: string;
  mt?: string;
  domestic_account?: string;
  foreign_account?: string;
  ic_domestic?: string;
  ic_foreign?: string;
  description_HR?: string;
  description_ENG?: string;
}
