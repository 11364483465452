<div
  class="overlay"
  [class.inAnimation]="!outAnimation"
  [class.outAnimation]="outAnimation"
  (click)="handleOverlayTap()"
>
  <div class="main-view">
    <div class="main-view-row" #mainView>
      <!-- this is added so that everything would be properly centered -->
      <div class="close-btn-mirror"></div>
      <button
        class="exhibition-navigation-buttons exhibition-navigation-buttons-left"
        (click)="onPhotoSwitch(-1, $event)"
      >
        <img
          *ngIf="!isMobile || !isUiHidden"
          src="/assets/exhibition-left.svg"
          alt=""
        />
      </button>
      <div class="main-img-wrap">
        <div class="stop-right-click"></div>
        <img class="main-img" alt="" src="{{ apiUrl + selectedPhoto }}" />
      </div>
      <button
        class="exhibition-navigation-buttons exhibition-navigation-buttons-right"
        (click)="onPhotoSwitch(1, $event)"
      >
        <img
          *ngIf="!isMobile || !isUiHidden"
          src="/assets/exhibition-right.svg"
          alt=""
        />
      </button>
      <button
        *ngIf="!isMobile || !isUiHidden"
        class="close-btn"
        (click)="handleClose($event)"
      >
        <img src="/assets/exhibition-close.svg" alt="" />
      </button>
    </div>
  </div>

  <div
    *ngIf="!isMobile"
    class="grid-container"
    [ngStyle]="{ width: mainView.offsetWidth - 144 + 'px' }"
  >
    <div class="other-photos-title">Ostale fotografije</div>

    <app-fancy-grid
      [imageData]="images"
      [imagesPerRow]="3"
      [allowImagePerRowRecalculation]="true"
      [imageRecalculationCap]="3"
      [variant]="4"
      [indexOfCurrentlySelected]="currentPhotoIndex"
      (selectionChangedEvent)="handlePhotoClick($event)"
    ></app-fancy-grid>
  </div>

  <div
    *ngIf="isMobile && !isUiHidden"
    class="mobile-scroll-outer"
    (click)="stopEventPropagation($event)"
  >
    <!-- if this ever gets a bug report just remake it, it currently has some weird edge cases -->
    <!-- instead of fancy grid, create a new grid or flex, define a height of the container, imgs should have height 100% and width auto and hopefully everything will be fine -->
    <div
      class="mobile-scroll-inner"
      [ngStyle]="{ width: images.length * 62 * 1.3 + 'px' }"
    >
      <app-fancy-grid
        [imageData]="images"
        [imagesPerRow]="images.length"
        [allowImagePerRowRecalculation]="false"
        [variant]="4"
        [indexOfCurrentlySelected]="currentPhotoIndex"
        (selectionChangedEvent)="handlePhotoClick($event)"
      ></app-fancy-grid>
    </div>
  </div>
</div>
