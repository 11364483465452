import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-gallery-empty',
  templateUrl: './create-gallery-empty.component.html',
  styleUrls: ['./create-gallery-empty.component.scss'],
})
export class CreateGalleryEmptyComponent implements OnInit {
  @Input() text: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  handleClick() {
    this.router.navigate(['add'], {
      relativeTo: this.route,
    });
  }
}
