import { Pipe, PipeTransform } from '@angular/core';
import { AutocompleteItem } from 'piwe-front-swagger-client';

@Pipe({
  name: 'autocomplete',
})
export class AutocompletePipe implements PipeTransform {
  transform(value?: AutocompleteItem, ...args: unknown[]): unknown {
    if (value == undefined || value.title.length == 0) return '<empty>';

    return value.title;
  }
}
