/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CartLine } from './cartLine';
import { ProductPreviewSchema } from './productPreviewSchema';

/**
 * Check out Figma file for more information on properties: https://www.figma.com/file/bxdGwhSTzlh51nAWKilDLN/Untitled?node-id=1%3A10110
 */
export interface Cart {
  lines?: Array<CartLine>;
  line_subtotal_vat?: number;
  line_total_no_vat?: number;
  line_total_with_vat?: number;
  alternative_line_total_no_vat?: number;
  alternative_line_total_with_vat?: number;
  use_tax?: boolean;
  currency?: Cart.CurrencyEnum;
  available_payment_methods?: Array<Cart.AvailablePaymentMethodsEnum>;
  related_items?: Array<ProductPreviewSchema>;
}
export namespace Cart {
  export type CurrencyEnum = 'HRK' | 'EUR';
  export const CurrencyEnum = {
    HRK: 'HRK' as CurrencyEnum,
    EUR: 'EUR' as CurrencyEnum,
  };
  export type AvailablePaymentMethodsEnum =
    | 'CARD'
    | 'KEKS_PAY'
    | 'PAYPAL'
    | 'DOWNLOAD';
  export const AvailablePaymentMethodsEnum = {
    CARD: 'CARD' as AvailablePaymentMethodsEnum,
    KEKSPAY: 'KEKS_PAY' as AvailablePaymentMethodsEnum,
    PAYPAL: 'PAYPAL' as AvailablePaymentMethodsEnum,
    DOWNLOAD: 'DOWNLOAD' as AvailablePaymentMethodsEnum,
  };
}
