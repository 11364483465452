import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

export const selectDeselectAnimation = trigger('selectDeselect', [
  state(
    'selected',
    style({
      border: '3px solid #f8ab5d',
    })
  ),
  state(
    'deselected',
    style({
      border: '0 solid #f8ab5d',
    })
  ),
  transition('selected <=> deselected', [animate('100ms ease-in')]),
]);

@Component({
  selector: 'app-block-container',
  templateUrl: './block-container.component.html',
  styleUrls: ['./block-container.component.scss'],
  animations: [selectDeselectAnimation],
})
export class BlockContainerComponent implements OnInit {
  @Input() isSelected: boolean = false;
  @Input() isSelectedInsideThisBlock: boolean = false;

  @Output() deleteButtonClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() SelectButtonClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() hoveringBlockEvent: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  handleDelete() {
    this.deleteButtonClickedEvent.emit();
  }
  handleSelect() {
    this.SelectButtonClickedEvent.emit();
  }

  handleMouseEnter() {
    this.hoveringBlockEvent.emit(true);
  }
  handleMouseLeave() {
    this.hoveringBlockEvent.emit(false);
  }
}
