import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  BlockMedia,
  HomepageCreatorDataService,
} from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-data.service';

@Component({
  selector: 'app-homepage-section-title',
  templateUrl: './homepage-section-title.component.html',
  styleUrls: ['./homepage-section-title.component.scss'],
})
export class HomepageSectionTitleComponent implements OnInit {
  @Input() headline: string = '';
  @Input() description: string = '';
  @Input() headlineEn: string = '';
  @Input() descriptionEn: string = '';
  @Input() link: string = '';

  @Input() pagebuilderMode: boolean = true;

  public currentLanguage = 'hr';
  private currentLanguageSubscription = new Subscription();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    //translation
    this.currentLanguage = this.translateService.currentLang;
    this.currentLanguageSubscription =
      this.translateService.onLangChange.subscribe(
        (params: LangChangeEvent) => {
          this.currentLanguage = params.lang;
        }
      );
  }

  ngOnDestroy() {
    this.currentLanguageSubscription.unsubscribe();
  }
}
