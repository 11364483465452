<div class="main">
  <div class="main-div">
    <div class="content">
      <div class="error-code">404</div>

      <h1 [innerHTML]="'404.title' | translate">Stranica nije pronađena</h1>

      <div class="body" [innerHTML]="'404.body' | translate">
        Stranica koju tražite uklonjena je, promjenjen je njezin naziv ili
        trenutno nije dostupna.
      </div>

      <button class="btn" routerLink="/">
        <img class="icon" src="/assets/back-white-error.svg" alt="" />
        {{ "404.button" | translate }}
      </button>
    </div>
  </div>

  <div class="login-img"></div>
</div>

<!--  -->
<!--  -->
<!--  -->

<!-- <h1>404 - Stranica nije pronađena</h1>

  <p *ngIf="path">
    Možda ste mislili: <a [href]="path">{{ path }}</a>
  </p>
  <p *ngIf="!path">Vratite se na <a href="/">naslovnicu</a>.</p>
</div> -->
