import { Pipe, PipeTransform } from '@angular/core';
import { Translatable } from '../../../projects/piwe-front-swagger-client/src';

@Pipe({
  name: 'translatable',
})
export class TranslatablePipe implements PipeTransform {
  transform(
    value: Array<Translatable> | undefined,
    ...args: unknown[]
  ): string {
    if (value == undefined) return '';
    return value.find((v) => v.language == 'HR')?.content ?? '';
  }
}
