<app-single-cms-input
  *ngIf="isEditModeActive"
  [autocompleteType]="autocompleteType"
  [controlName]="controlName"
  [form]="form"
  [label]="label"
  [type]="type"
  [placeholder]="placeholder"
  [selectOptions]="selectOptions"
></app-single-cms-input>

<div *ngIf="!isEditModeActive">
  <div class="label">{{ label }}</div>
  <div class="text" *ngIf="type == InputType.Password">••••••</div>
  <div class="text" *ngIf="type == InputType.Autocomplete">
    {{ placeholder }}
  </div>
  <div
    class="text"
    *ngIf="
      type == InputType.Text ||
      type == InputType.Number ||
      type == InputType.Disabled
    "
  >
    {{
      form == undefined ||
      form.get(controlName) == undefined ||
      form.get(controlName)?.value === "" ||
      form.get(controlName)?.value == null ||
      form.get(controlName)?.value == undefined ||
      form.get(controlName)?.value.length == 0
        ? "/"
        : form.get(controlName)?.value
    }}
  </div>
  <div class="text" *ngIf="type == InputType.Date || type == InputType.Date2">
    <ng-container *ngIf="form.get(controlName)?.value === ''">/</ng-container>
    <ng-container *ngIf="form.get(controlName)?.value !== ''">
      <ng-container *ngIf="isDateFormated">
        {{ form.get(controlName)?.value | date : dateTimeFormat }}
      </ng-container>
      <ng-container *ngIf="!isDateFormated">
        {{ form.get(controlName)?.value }}
      </ng-container>
    </ng-container>
  </div>
  <div class="text" *ngIf="type == InputType.Boolean">
    {{
      form.get(controlName)?.value !== false &&
      (form.get(controlName)?.value === true ||
        form.get(controlName)?.value.toLowerCase() === "true")
        ? "Yes"
        : "No"
    }}
  </div>
  <div
    class="text"
    *ngIf="type == InputType.Select || type == InputType.SelectNumeric"
  >
    {{
      form == undefined || form.get(controlName)?.value === ""
        ? "/"
        : selectOptions[form.get(controlName)?.value]
    }}
  </div>
  <div
    class="text"
    *ngIf="type == InputType.Tags && form.get(controlName)!.value !== undefined"
  >
    {{ computeValue() }}
  </div>
  <div *ngIf="type == InputType.ImageUpload">
    <img class="avatar" src="/assets/default-avatar.svg" alt="" />
  </div>
  <div class="helpText" *ngIf="helpText">{{ helpText }}</div>
</div>
