import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import {
  ErrorResponse,
  LoginResponse,
  RegistrationLoginService,
} from 'piwe-front-swagger-client';
import { catchError, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from './profile.service';
import { AuthenticationStorageService } from './authentication-storage.service';
import { MessagingService } from './messaging.service';
import { ReCaptchaV3Service } from 'ngx-captcha';

export var clearSource = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  redirectUrl: string | undefined;
  siteKey = '6Ld6Mz0mAAAAAMP19-uaSF7pmMedZB0KN24QkJ9v';

  private isAuthenticatedSource = new BehaviorSubject<boolean>(false);
  public isAuthenticatedData: Observable<boolean> =
    this.isAuthenticatedSource.asObservable();

  constructor(
    private authenticationStorageService: AuthenticationStorageService,
    private registrationLoginService: RegistrationLoginService,
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private messagingService: MessagingService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {
    this.route.queryParams.subscribe((qp) => {
      this.redirectUrl = qp['redirect'];
    });

    if (this.isAuthenticated()) {
      this.isAuthenticatedSource.next(true);
      this.profileService.getProfile();
      this.messagingService.requestPermission();
    }

    clearSource.asObservable().subscribe((_) => {
      this.clearToken();
    });
  }

  clearToken() {
    this.isAuthenticatedSource.next(false);
    this.authenticationStorageService.clearToken();
    this.profileService.clearProfile();
  }

  isAuthenticated(): boolean {
    return this.authenticationStorageService.getToken() != undefined;
  }

  requestPasswordReset(username: string): Observable<boolean | ErrorResponse> {
    return this.registrationLoginService
      .frontForgotPassword({
        username: username,
      })
      .pipe(
        map((r) => {
          return true;
        }),
        catchError((error) => {
          let errorResponse: ErrorResponse = error.error;
          return of(errorResponse);
        })
      );
  }

  requestPasswordChange(
    password: string,
    confirm_password: string,
    token: string
  ): Observable<boolean | ErrorResponse> {
    return this.registrationLoginService
      .frontChangePassword({
        password: password,
        confirm_password: confirm_password,
        token: token,
      })
      .pipe(
        map((r) => {
          return true;
        }),
        catchError((error) => {
          let errorResponse: ErrorResponse = error.error;
          return of(errorResponse);
        })
      );
  }

  approveLogin(token: string): Observable<boolean | ErrorResponse> {
    return this.registrationLoginService
      .frontApproveLogin({
        token: token,
      })
      .pipe(
        map((r) => {
          return true;
        }),
        catchError((error) => {
          let errorResponse: ErrorResponse = error.error;
          return of(errorResponse);
        })
      );
  }
  confirmEmail(token: string): Observable<boolean | ErrorResponse> {
    return this.registrationLoginService
      .frontConfirmEmail({
        token: token,
      })
      .pipe(
        map((r) => {
          return true;
        }),
        catchError((error) => {
          let errorResponse: ErrorResponse = error.error;
          return of(errorResponse);
        })
      );
  }

  authenticate(username: string, password: string): Observable<boolean | ErrorResponse> {
    return from(this.reCaptchaV3Service.executeAsPromise(this.siteKey, 'login', {
      useGlobalDomain: false
    })).pipe(
      catchError(() => of(null)),  // Catch errors and emit null captchaToken
      switchMap((captchaToken: string | null) =>
        this.registrationLoginService
          .frontLoginUser({
            username,
            password,
            captcha_token: captchaToken ?? undefined,  // CaptchaToken is optional
          })
      ),
      tap(
        (value: LoginResponse) => {
          if (value.token) {
            this.authenticationStorageService.saveToken(value.token);
            this.isAuthenticatedSource.next(true);

            this.profileService.profileData
              .pipe(skip(1), take(1))
              .subscribe((profile) => {
                if (profile == undefined) return;

                if (this.redirectUrl) {
                  this.router.navigateByUrl(this.redirectUrl);
                } else {
                  this.router.navigateByUrl('/');
                }
              });

            this.profileService.getProfile();
            this.messagingService.requestPermission();
          }
        },
        (error) => {
          this.isAuthenticatedSource.next(false);
          this.authenticationStorageService.clearToken();
          this.profileService.clearProfile();
        }
      ),
      map((r) => {
        return r.token != undefined;
      }),
      catchError((error) => {
        let errorResponse: ErrorResponse = error.error;
        return of(errorResponse);
      })
    );
  }


  logOut() {
    this.isAuthenticatedSource.next(false);
    this.authenticationStorageService.clearToken();
    this.profileService.clearProfile();
    this.router.navigateByUrl('/login');
  }
}
