import { Injectable } from '@angular/core';
import {
  Settings as ApiSettings,
  SettingsService,
} from 'piwe-front-swagger-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export enum EuroPhase {
  HRK_ONLY,
  BIG_HRK_SMALL_EUR,
  BIG_EUR_SMALL_HRK,
  EUR_ONLY,
}

export interface Settings {
  euroPhase: EuroPhase;
  euroRate?: number;
}

@Injectable({
  providedIn: 'root',
})
export class RemoteSettingsService {
  private settings?: Settings;

  private settingsSource = new BehaviorSubject<Settings | undefined>(undefined);
  public settingsObservable: Observable<Settings | undefined> =
    this.settingsSource.asObservable();

  constructor(private settingsService: SettingsService) {}

  loadSettings(): void {
    this.settingsService.getSettings().subscribe((apiSettings: ApiSettings) => {
      this.settings = this.parseSettings(apiSettings);
      this.settingsSource.next(this.settings);
    });
  }

  private parseSettings(apiSetting: ApiSettings): Settings {
    let euroPhase: EuroPhase | undefined = EuroPhase.HRK_ONLY;
    if (apiSetting.EURO_PHASE !== undefined) {
      const intValue = parseInt(apiSetting.EURO_PHASE, 10);
      if (intValue === 0) {
        euroPhase = EuroPhase.HRK_ONLY;
      } else if (intValue === 1) {
        euroPhase = EuroPhase.BIG_HRK_SMALL_EUR;
      } else if (intValue === 2) {
        euroPhase = EuroPhase.BIG_EUR_SMALL_HRK;
      } else if (intValue === 3) {
        euroPhase = EuroPhase.EUR_ONLY;
      }
    }

    let euroRate: number | undefined;
    if (apiSetting.EURO_RATE !== undefined) {
      const floatValue = parseFloat(apiSetting.EURO_RATE);
      euroRate = floatValue;
    }

    return {
      euroPhase,
      euroRate,
    };
  }
}
