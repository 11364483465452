<div class="cart-container">
  <button
    class="cart"
    [class.count]="cartItemCount"
    attr.data-number-of-items="{{ cartItemCount }}"
    (click)="handleCartClick()"
  ></button>
  <ng-container *ngIf="isCartPopupOpen">
    <ng-container *ngTemplateOutlet="noItemsPopup"></ng-container>
  </ng-container>

  <ng-container *ngIf="isNewItemAddedPopupActive">
    <ng-container *ngTemplateOutlet="newPopup"></ng-container>
  </ng-container>
</div>

<ng-template #noItemsPopup>
  <div class="no-item__wrapper" (click)="closeToggleCartPopup()">
    <div class="no-item" (click)="stopPropagation($event)">
      <div class="no-item__header">
        <div
          class="no-item__title"
          [innerHTML]="'cart.no_items_title' | translate"
        >
          Košarica je prazna
        </div>
        <button class="no-item__btn" (click)="closeToggleCartPopup()"></button>
      </div>
      <img
        src="/assets/empty-cart-popup.svg"
        alt="Empty cart"
        class="no-item__img"
      />
      <div class="no-item__body" [innerHTML]="'cart.no_items' | translate">
        U Vašoj košarici nema dodanog sadržaja. Odaberite sadržaj i pokušajte
        ponovno.
      </div>
    </div>
  </div>
</ng-template>

<ng-template #newPopup>
  <div class="no-item__wrapper new-item-wrapper" (click)="closeNewItemPopup()">
    <div class="no-item new-item" (click)="stopPropagation($event)">
      <div class="no-item__header">
        <div
          class="no-item__title"
          [innerHTML]="'cart.successful_added' | translate"
        >
          Uspješno dodano!
        </div>
        <button class="no-item__btn" (click)="closeNewItemPopup()"></button>
      </div>
      <img
        src="{{ cartNewItemObject.itemImgUrl | pixsellPreviewUrl }}"
        alt="{{ cartNewItemObject.itemName }}"
        class="new-item__img"
      />
      <div
        class="no-item__body"
        *ngIf="cartNewItemObject.cartNewItemCount == 1"
      >
        <span [innerHTML]="'cart.added_item' | translate"
          >U košaricu je dodan proizvod</span
        >
        <span class="item-title">{{ cartNewItemObject.itemName }}</span>
      </div>
      <div
        class="no-item__body"
        *ngIf="cartNewItemObject.cartNewItemCount != 1"
      >
        <div [innerHTML]="'cart.added_items_1' | translate">
          U košaricu je dodano
        </div>
        &nbsp;<b>{{ cartNewItemObject.cartNewItemCount }}</b
        >&nbsp;
        <span [innerHTML]="'cart.added_items_2' | translate">proizvoda</span>
      </div>
    </div>
  </div>
</ng-template>
