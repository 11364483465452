import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  CMSGalleriesService as ApiGalleriesService,
  IdsList,
  ProductList,
  ProductsFrontService,
  ProductsService as ApiProductsService,
  Translatable,
  UploadSessionPublishRequest,
  UploadSessionsService,
} from 'piwe-front-swagger-client';
import { ImportItem } from '../../import/import.component';
import { AutocompleteOption } from '../../../shared/field-text/field-text.component';
import {
  Agency,
  Author,
  Category,
  ErrorResponse,
  Keyword,
  LicenceType,
  Person,
  ProductUpdate,
  SpecialOfferType,
  Tag,
} from '../../../../../projects/piwe-front-swagger-client/src';
import { GalleryImportItem } from '../galleries/galleries.component';
import {
  FieldChangedEvent,
  IMultipleItemEditorService,
} from '../../../shared/multiple-item-editor/multiple-item-editor.component';
import {
  FIELD_AGENCY,
  FIELD_API_FTP,
  FIELD_ARTICLE_AUTHOR,
  FIELD_ARTICLE_CONTENT,
  FIELD_ARTICLE_TITLE,
  FIELD_AUTHOR,
  FIELD_CAPTION,
  FIELD_CAPTION_EN,
  FIELD_CATEGORIES,
  FIELD_DATE_CREATED,
  FIELD_DATE_PUBLISHED,
  FIELD_FEATURED,
  FIELD_HEADLINE,
  FIELD_HEADLINE_EN,
  FIELD_ID,
  FIELD_KEYWORDS,
  FIELD_KEYWORDS_EN,
  FIELD_LICENCE,
  FIELD_NEEDS_TRANSLATION_EN,
  FIELD_PEOPLE,
  FIELD_PRINT_PRICE,
  FIELD_SPECIAL_OFFER,
  FIELD_SPECIAL_OFFER_TYPE,
  FIELD_TAG,
  FIELD_WEB_PRICE,
  FIELD_VIDEO_TITLE,
  FIELDS,
  FieldType,
  MultipleItemEditorService,
  FIELD_PRINT_PRICE_EUR,
  FIELD_WEB_PRICE_EUR,
} from '../../../shared/multiple-item-editor/multiple-item-editor.service';
import { formatDate } from '@angular/common';
import { MainPreviewComponent } from '../../import/main-preview/main-preview.component';
import { LoadingService } from '../../../shared/loading.service';
import { SnackBarService } from '../../../share/snack-bar.service';
import { GalleriesService } from '../galleries/galleries.service';
import logging from '../../../logging';
import { Input } from '@angular/core';

type SessionAttributes = {
  page: number;
  fromDate?: string;
  toDate?: string;
  originalName?: string;
  authors?: AutocompleteOption[];
  importDate?: string;
  downloaded?: AutocompleteOption;
  agencies?: AutocompleteOption[];
  galleryId?: string;
  contentType?: AutocompleteOption;
  productStatus?: AutocompleteOption;
  productId?: string;
  operators?: AutocompleteOption[];
  tags?: AutocompleteOption[];
  categories?: AutocompleteOption[];
  bigSearchPerson?: boolean;
  bigSearchDescription?: boolean;
  bigSearchKeywords?: boolean;
  bigSearch?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ProductsService
  extends MultipleItemEditorService<ImportItem>
  implements IMultipleItemEditorService
{
  private files: any[] = [];

  private unsavedItemsIds: number[] = [];

  public isUnsaved: boolean = false;
  private isUnsavedSource = new Subject<boolean>();
  public isUnsavedData: Observable<boolean> =
    this.isUnsavedSource.asObservable();

  private isEditingDisabledSource = new Subject<boolean>();
  public isEditingDisabledData: Observable<boolean> =
    this.isEditingDisabledSource.asObservable();

  private requestFileReplaceSource = new Subject<number>();
  public requestFileReplaceData: Observable<number> =
    this.requestFileReplaceSource.asObservable();

  private uploadingFilesSource = new Subject<any[]>();
  public uploadingFilesData: Observable<any[]> =
    this.uploadingFilesSource.asObservable();

  private itemsSource = new Subject<ImportItem[]>();
  public itemsData: Observable<ImportItem[]> = this.itemsSource.asObservable();

  private selectedIdsSource = new Subject<number[]>();
  public selectedIdsData: Observable<number[]> =
    this.selectedIdsSource.asObservable();

  private fieldChangeSource = new Subject<FieldChangedEvent>();
  public fieldChangeData: Observable<FieldChangedEvent> =
    this.fieldChangeSource.asObservable();

  private loadSessionSignalSource = new Subject<SessionAttributes>();
  public loadSessionSignalData: Observable<SessionAttributes> =
    this.loadSessionSignalSource.asObservable();

  private afterSaveSource = new Subject<(shouldRefresh: boolean) => void>();
  public afterSaveData: Observable<(shouldRefresh: boolean) => void> =
    this.afterSaveSource.asObservable();

  @Input() shouldLoadFromRemote: boolean = true;

  public getIsLoaded(): Observable<boolean> {
    return super.getIsLoaded();
  }

  requestFileReplace(id: number) {
    this.requestFileReplaceSource.next(id);
  }

  private resolveAutocompleteValue(
    type: string,
    value: AutocompleteOption | undefined
  ): any | undefined {
    if (value == undefined) return undefined;

    switch (type) {
      case 'downloaded':
        switch (value.value) {
          case 1:
            return true;
          default:
            return false;
        }

      case 'productStatus':
        switch (value.value) {
          case 1:
            return 'PUBLISHED';
          default:
            return 'NOT_PUBLISHED';
        }

      case 'contentType':
        switch (value.value) {
          case 1:
            return 'PHOTO';
          default:
            return 'VIDEO';
        }
    }
  }

  formatOptionalDate(value: any | undefined) {
    if (value == undefined) return undefined;

    return formatDate(value, 'yyyy-MM-dd', 'en-US');
  }

  constructor(
    public apiProductsService: ApiProductsService,
    public uploadService: UploadSessionsService,
    private apiProductsFront: ProductsFrontService,
    private apiGalleryService: ApiGalleriesService,
    private loadingService: LoadingService,
    private snackBarService: SnackBarService
  ) {
    super();

    this.clearItems();
    this.unsavedItemsIds = [];
    //console.log(`products.service.ts unsavedItemsIds = [], constructed`);

    this.fieldSources = {};
    this.fieldSources[FIELD_VIDEO_TITLE.name] = this.editingVideoTitleSource;
    this.fieldSources[FIELD_ID.name] = this.editingIdSource;
    this.fieldSources[FIELD_HEADLINE.name] = this.editingHeadlineSource;
    this.fieldSources[FIELD_HEADLINE_EN.name] = this.editingHeadlineEnSource;
    this.fieldSources[FIELD_CAPTION.name] = this.editingCaptionSource;
    this.fieldSources[FIELD_PEOPLE.name] = this.editingPeopleSource;
    this.fieldSources[FIELD_KEYWORDS.name] = this.editingKeywordsSource;
    this.fieldSources[FIELD_CATEGORIES.name] = this.editingCategoriesSource;
    this.fieldSources[FIELD_AUTHOR.name] = this.editingAuthorSource;
    this.fieldSources[FIELD_AGENCY.name] = this.editingAgencySource;
    this.fieldSources[FIELD_TAG.name] = this.editingTagSource;
    this.fieldSources[FIELD_SPECIAL_OFFER.name] =
      this.editingSpecialOfferSource;
    this.fieldSources[FIELD_SPECIAL_OFFER_TYPE.name] =
      this.editingSpecialOfferTypeSource;
    this.fieldSources[FIELD_API_FTP.name] = this.editingApiFtpSource;
    this.fieldSources[FIELD_LICENCE.name] = this.editingLicenceSource;
    this.fieldSources[FIELD_DATE_CREATED.name] = this.editingDateCreatedSource;
    this.fieldSources[FIELD_DATE_PUBLISHED.name] =
      this.editingDatePublishedSource;
    this.fieldSources[FIELD_NEEDS_TRANSLATION_EN.name] =
      this.editingNeedsTranslationEnSource;
    this.fieldSources[FIELD_CAPTION_EN.name] = this.editingCaptionEnSource;
    this.fieldSources[FIELD_KEYWORDS_EN.name] = this.editingKeywordsEnSource;
    this.fieldSources[FIELD_PRINT_PRICE.name] = this.editingPrintPriceSource;
    this.fieldSources[FIELD_WEB_PRICE.name] = this.editingWebPriceSource;
    this.fieldSources[FIELD_PRINT_PRICE_EUR.name] =
      this.editingPrintPriceEurSource;
    this.fieldSources[FIELD_WEB_PRICE_EUR.name] = this.editingWebPriceEurSource;
    this.fieldSources[FIELD_FEATURED.name] = this.editingWebPriceSource;

    this.fieldSources[FIELD_ARTICLE_TITLE.name] =
      this.editingArticleTitleSource;
    this.fieldSources[FIELD_ARTICLE_AUTHOR.name] =
      this.editingArticleAuthorSource;
    this.fieldSources[FIELD_ARTICLE_CONTENT.name] =
      this.editingArticleContentSource;

    this.setUpSaveSession();

    this.loadSessionSignalData
      .pipe(
        switchMap((options) => {
          //console.log("SEARCH DATA ", options);
          this.isLoadedSource.next(false);

          return this.apiProductsService.cmsGetProducts(
            options.page - 1,
            50,
            options.bigSearch,
            true,
            options.bigSearchPerson,
            options.bigSearchDescription,
            options.bigSearchKeywords,
            this.formatOptionalDate(options.fromDate),
            this.formatOptionalDate(options.toDate),
            undefined,
            this.getFirstFromArray(options.agencies?.map((a) => a.value))
          );
        })
      )
      .subscribe((result) => {
        this.isLoadedSource.next(true);
        let newItems = this.deserializeProducts(result, undefined);
        this.setItems(newItems);

        this.itemsSource.next(this.getItems());
      });
  }

  loadSession(page: number, options: SessionAttributes) {
    this.clearItems();

    this.unsavedItemsIds = [];
    //console.log(`products.service.ts unsavedItemsIds = [], loadSession`);

    this.isUnsaved = false;
    this.isUnsavedSource.next(false);
    //console.log('<products.service.ts>', 'isUnsaved', 'False cause loadSession.');

    this.itemsSource.next(this.getItems());

    this.selectedIds = [];
    this.selectedIdsSource.next([]);
    this.loadDataForSelectedItem();

    if (!(options.bigSearch?.length ?? 0)) {
      options.bigSearch = undefined;
    }

    this.loadSessionSignalSource.next({
      ...options,
      page,
    });
  }

  selectItems(itemIds: number[]) {
    this.selectedIds = itemIds;
    this.selectedIdsSource.next(itemIds);
    this.loadDataForSelectedItem();
  }

  fieldSources: { [key: string]: Subject<any> } = {};

  loadDataForSelectedItem() {
    let isAnySelectedLocked = this.selectedIds
      .map((id) => this.getItemById(id).isLocked)
      .reduce((sum, next) => sum || next, false);
    this.isEditingDisabledSource.next(isAnySelectedLocked);

    //console.log("loadDataForSelectedItem", this.selectedIds);

    for (const field of FIELDS) {
      let source = this.fieldSources[field.name];
      if (source == undefined) continue;

      var value: any;

      switch (field.fieldType) {
        case FieldType.String:
          value = this.computeFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as any;
          });
          break;
        case FieldType.Number:
          value = this.computeNumberFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as number;
          });
          break;
        case FieldType.Boolean:
          value = this.computeBooleanFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as boolean;
          });
          break;
        case FieldType.Autocomplete:
          value = this.computeAutocompleteFieldValue((i) => {
            //console.log("computeAutocompleteFieldValue", i, field.name);

            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as AutocompleteOption;
          });
          break;
        case FieldType.MultipleAutocomplete:
          value = this.computeMultipleAutocompleteFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            return i[field.name] as AutocompleteOption[];
          });
          break;
        case FieldType.Date:
          value = this.computeDateFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name))
              return undefined;

            // @ts-ignore
            //console.log("KARLO", i[field.name] as string);

            // @ts-ignore
            return i[field.name] as string;
          });
          break;
      }

      //if (this.editingGallery != undefined && field.name == "headline") {
      //  value = this.editingGallery.headline;
      //}

      this.fieldChangeSource.next({
        field: field.name,
        value: value,
      });

      if (source == undefined) {
        //console.error(`Source is missing for field '${field.name}'`)
        return;
      }

      source.next(value);
    }

    this.calculateSuffix();
  }

  computeAutocompleteFieldValue(
    selector: (arg0: ImportItem) => AutocompleteOption | undefined
  ): AutocompleteOption {
    // No selection case.
    if (this.selectedIds.length == 0)
      return { title: '', value: -1, isSelectable: false };

    if (this.selectedIds.length == 1) {
      let item = this.getItemById(this.selectedIds[0]);
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return { title: '', value: -1, isSelectable: false };
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.getItemById(id));
    let values: (string | undefined)[] = selectedItems.map(
      (item) => selector(item)?.title
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return selector(selectedItems[0])!;

      return { title: '', value: -1, isSelectable: false };
    } else {
      return { title: '<multiple values>', value: -3, isSelectable: false };
    }
  }

  computeFieldValue(
    selector: (arg0: ImportItem) => string | undefined
  ): string {
    // No selection case.
    if (this.selectedIds.length == 0) return '';

    if (this.selectedIds.length == 1) {
      let item = this.getItemById(this.selectedIds[0]);
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return '';
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.getItemById(id));
    let values: (string | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return '';
    } else {
      return '<multiple values>';
    }
  }

  computeBooleanFieldValue(
    selector: (arg0: ImportItem) => boolean | undefined
  ): boolean {
    // No selection case.
    if (this.selectedIds.length == 0) return false;

    if (this.selectedIds.length == 1) {
      let item = this.getItemById(this.selectedIds[0]);
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return false;
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.getItemById(id));
    let values: (boolean | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return false;
    } else {
      // TODO: Not true or false!
      return true;
    }
  }

  computeNumberFieldValue(
    selector: (arg0: ImportItem) => number | undefined
  ): number {
    // No selection case.
    if (this.selectedIds.length == 0) return 0.0;

    if (this.selectedIds.length == 1) {
      let item = this.getItemById(this.selectedIds[0]);
      let itemValue = selector(item);
      if (itemValue) return itemValue;

      return 0.0;
    }

    // Fetch selected items.
    let selectedItems = this.selectedIds.map((id) => this.getItemById(id));
    let values: (number | undefined)[] = selectedItems.map((item) =>
      selector(item)
    );

    let allEqualFunc = (arr: any[]) => arr.every((v) => v === arr[0]);
    let allEqual = allEqualFunc(values);

    if (allEqual) {
      let itemValue = values[0];
      if (itemValue) return itemValue;

      return 0.0;
    } else {
      // TODO: Not true or false!
      return 0.0;
    }
  }

  /////////////////////////////
  // Fields
  /////////////////////////////

  private editingHeadlineSource = new Subject<AutocompleteOption | undefined>();
  public editingHeadlineData: Observable<AutocompleteOption | undefined> =
    this.editingHeadlineSource.asObservable();

  private editingCaptionSource = new Subject<string | undefined>();
  public editingCaptionData: Observable<string | undefined> =
    this.editingCaptionSource.asObservable();

  private editingVideoTitleSource = new Subject<string | undefined>();
  public editingVideoTitleData: Observable<string | undefined> =
    this.editingVideoTitleSource.asObservable();

  private editingPeopleSource = new Subject<AutocompleteOption[] | undefined>();
  public editingPeopleData: Observable<AutocompleteOption[] | undefined> =
    this.editingPeopleSource.asObservable();

  private editingKeywordsSource = new Subject<
    AutocompleteOption[] | undefined
  >();
  public editingKeywordsData: Observable<AutocompleteOption[] | undefined> =
    this.editingKeywordsSource.asObservable();

  private editingCategoriesSource = new Subject<
    AutocompleteOption | undefined
  >();
  public editingCategoriesData: Observable<AutocompleteOption | undefined> =
    this.editingCategoriesSource.asObservable();

  private editingAuthorSource = new Subject<AutocompleteOption | undefined>();
  public editingAuthorData: Observable<AutocompleteOption | undefined> =
    this.editingAuthorSource.asObservable();

  private editingAgencySource = new Subject<AutocompleteOption | undefined>();
  public editingAgencyData: Observable<AutocompleteOption | undefined> =
    this.editingAgencySource.asObservable();

  private editingTagSource = new Subject<AutocompleteOption | undefined>();
  public editingTagData: Observable<AutocompleteOption | undefined> =
    this.editingTagSource.asObservable();

  private editingSpecialOfferSource = new Subject<boolean>();
  public editingSpecialOfferData: Observable<boolean> =
    this.editingSpecialOfferSource.asObservable();

  private editingSpecialOfferTypeSource = new Subject<
    AutocompleteOption | undefined
  >();
  public editingSpecialOfferTypeData: Observable<
    AutocompleteOption | undefined
  > = this.editingSpecialOfferTypeSource.asObservable();

  private editingApiFtpSource = new Subject<boolean>();
  public editingApiFtpData: Observable<boolean> =
    this.editingApiFtpSource.asObservable();

  private editingLicenceSource = new Subject<string | undefined>();
  public editingLicenceData: Observable<string | undefined> =
    this.editingLicenceSource.asObservable();

  private editingDateCreatedSource = new Subject<string | undefined>();
  public editingDateCreatedData: Observable<string | undefined> =
    this.editingDateCreatedSource.asObservable();

  private editingDatePublishedSource = new Subject<string | undefined>();
  public editingDatePublishedData: Observable<string | undefined> =
    this.editingDatePublishedSource.asObservable();

  private editingNeedsTranslationEnSource = new Subject<boolean>();
  public editingNeedsTranslationEnData: Observable<boolean> =
    this.editingNeedsTranslationEnSource.asObservable();

  private editingCaptionEnSource = new Subject<string | undefined>();
  public editingCaptionEnData: Observable<string | undefined> =
    this.editingCaptionEnSource.asObservable();

  private editingHeadlineEnSource = new Subject<
    AutocompleteOption | undefined
  >();
  public editingHeadlineEnData: Observable<AutocompleteOption | undefined> =
    this.editingHeadlineEnSource.asObservable();

  private editingIdSource = new Subject<string | undefined>();
  public editingIdData: Observable<string | undefined> =
    this.editingIdSource.asObservable();

  private editingKeywordsEnSource = new Subject<
    AutocompleteOption[] | undefined
  >();
  public editingKeywordsEnData: Observable<AutocompleteOption[] | undefined> =
    this.editingKeywordsEnSource.asObservable();

  private editingWebPriceSource = new Subject<number | undefined>();
  public editingWebPriceData: Observable<number | undefined> =
    this.editingWebPriceSource.asObservable();

  private editingPrintPriceSource = new Subject<number | undefined>();
  public editingPrintPriceData: Observable<number | undefined> =
    this.editingPrintPriceSource.asObservable();

  private editingWebPriceEurSource = new Subject<number | undefined>();
  public editingWebPriceEurData: Observable<number | undefined> =
    this.editingWebPriceEurSource.asObservable();

  private editingPrintPriceEurSource = new Subject<number | undefined>();
  public editingPrintPriceEurData: Observable<number | undefined> =
    this.editingPrintPriceEurSource.asObservable();

  private editingArticleTitleSource = new Subject<string | undefined>();
  public editingArticleTitleData: Observable<string | undefined> =
    this.editingArticleTitleSource.asObservable();

  private editingArticleAuthorSource = new Subject<
    AutocompleteOption | undefined
  >();
  public editingArticleAuthorData: Observable<AutocompleteOption | undefined> =
    this.editingArticleAuthorSource.asObservable();

  private editingArticleContentSource = new Subject<string | undefined>();
  public editingArticleContentData: Observable<string | undefined> =
    this.editingArticleContentSource.asObservable();

  private featuredSource = new Subject<boolean>();
  public featuredData: Observable<boolean> = this.featuredSource.asObservable();

  private splittedGalleryItems: { [key: number]: ImportItem } = {};

  setField(field: string, value: any) {
    let setter: (item: ImportItem) => void;
    let clearChange = false;

    if (this.selectedIds.length) {
      switch (field) {
        case 'headline':
          if (this.editingGallery != undefined) {
            if (
              prompt(
                "Type 'MOVE' to confirm you want to move item(s) to another gallery. Changes will apply on save."
              ) == 'MOVE'
            ) {
              setter = (item: ImportItem) => {
                item.headline = value;
                item.headlineEn = undefined;
                item.galleryId = -1;
                item.splitFromGallery = true;

                this.splittedGalleryItems[item.id] = item;
                this.editingGallery!.items = this.editingGallery!.items?.filter(
                  (i) => i != item
                );
                this.editingGallery!.previewUrl =
                  this.computePreviewGalleryImportItem(this.editingGallery!);

                this.editingGallerySource.next(this.editingGallery);
                this.unselectAll();
              };
            } else {
              setter = (item: ImportItem) => {};
              clearChange = true;
            }
          } else {
            setter = (item: ImportItem) => (item.headline = value);
          }

          break;

        case 'headlineEn':
          setter = (item: ImportItem) => (item.headlineEn = value);
          break;

        case 'videoTitle':
          setter = (item: ImportItem) => (item.videoTitle = value);
          break;

        case 'caption':
          setter = (item: ImportItem) => (item.caption = value);
          break;

        case 'people':
          setter = (item: ImportItem) => (item.people = value);
          break;

        case 'keywords':
          setter = (item: ImportItem) => (item.keywords = value);
          break;

        case 'categories':
          setter = (item: ImportItem) => (item.categories = value);
          break;

        case 'author':
          setter = (item: ImportItem) => (item.author = value);
          break;

        case 'agency':
          setter = (item: ImportItem) => (item.agency = value);
          break;

        case 'tag':
          setter = (item: ImportItem) => (item.tag = value);
          break;

        case 'specialOffer':
          setter = (item: ImportItem) => (item.specialOffer = value);
          break;

        case 'specialOfferTypes':
          setter = (item: ImportItem) => (item.specialOfferTypes = value);
          break;

        case 'apiFtp':
          setter = (item: ImportItem) => (item.apiFtp = value);
          break;

        case 'licence':
          setter = (item: ImportItem) => (item.licence = value);
          break;

        case 'dateCreated':
          setter = (item: ImportItem) => (item.dateCreated = value);
          break;

        case 'needsTranslationEn':
          setter = (item: ImportItem) => (item.needsTranslationEn = value);
          break;

        case 'captionEn':
          setter = (item: ImportItem) => (item.captionEn = value);
          break;

        case 'keywordsEn':
          setter = (item: ImportItem) => (item.keywordsEn = value);
          break;

        case 'webPrice':
          setter = (item: ImportItem) => (item.webPrice = value);
          break;

        case 'printPrice':
          setter = (item: ImportItem) => (item.printPrice = value);
          break;

        case 'webPriceEur':
          setter = (item: ImportItem) => (item.webPriceEur = value);
          break;

        case 'printPriceEur':
          setter = (item: ImportItem) => (item.printPriceEur = value);
          break;
      }
    }

    if (clearChange) {
      this.loadDataForSelectedItem();
      return;
    }

    let selectedItems = this.selectedIds.map((id) => this.getItemById(id));
    selectedItems.forEach((item) => setter(item));
    selectedItems.forEach((item) => {
      if (!this.unsavedItemsIds.includes(item.id)) {
        this.lockImportItems([item]);
        this.unsavedItemsIds.push(item.id);
      }
    });

    this.isUnsaved = true;
    this.isUnsavedSource.next(true);

    logging.debug(
      'products.service.ts',
      `Marking as unsaved, changed field '${field}' to value '${value}'`,
      field,
      value
    );
    logging.debug(
      'products.service.ts',
      `Dirty items are: '${this.unsavedItemsIds.join(', ')}'`,
      this.unsavedItemsIds,
      this.items
    );

    this.calculateSuffix();
  }

  markAsDirty(products: ImportItem[]) {
    if (products == undefined || products.length == 0) return;

    for (const product of products) {
      if (!this.unsavedItemsIds.includes(product.id)) {
        this.unsavedItemsIds.push(product.id);
      }
    }
    //console.log(`products.service.ts markAsDirty ${products}`);
    this.isUnsaved = true;
    this.isUnsavedSource.next(true);
    //console.log('<products.service.ts>', 'isUnsaved', 'True cause markDirty.', products);
  }

  markAllNotDirty() {
    //console.log('<products.service.ts>', 'isUnsaved', 'False cause markAllNotDirty.');
    this.unsavedItemsIds = [];
    this.isUnsaved = false;
    this.isUnsavedSource.next(false);
  }

  /////////////////////////////
  // Saving
  /////////////////////////////

  static deserializeProduct(
    uploadSessionItem: any,
    galleryId: number | undefined
  ): ImportItem {
    let width = 1;
    let height = 1;

    let agency: AutocompleteOption | undefined = undefined;
    if (uploadSessionItem?.agency != undefined)
      agency = {
        title: uploadSessionItem?.agency!.name!,
        value: uploadSessionItem?.agency!.id!,
        isSelectable: true,
      };

    let author: AutocompleteOption | undefined = undefined;
    if (uploadSessionItem?.author != undefined)
      author = {
        title: uploadSessionItem?.author.name!,
        value: uploadSessionItem?.author.id!,
        isSelectable: true,
      };

    let categories: AutocompleteOption | undefined = undefined;

    if (uploadSessionItem?.category != undefined)
      categories = {
        title:
          uploadSessionItem?.category.name.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',
        value: uploadSessionItem?.category.id!,
        isSelectable: true,
      };

    /*let licenceType: AutocompleteOption | undefined = undefined;
    if (uploadSessionItem.licence_type != undefined)
      licenceType = {
        title: uploadSessionItem.licence_type.name,
        value: uploadSessionItem.licence_type.id,
        isSelectable: true
      }*/
    if (uploadSessionItem?.agency != undefined)
      agency = {
        title: uploadSessionItem?.agency!.name!,
        value: uploadSessionItem?.agency!.id!,
        isSelectable: true,
      };

    let specialOfferTypes: AutocompleteOption[] | undefined = undefined;
    if (uploadSessionItem.special_offer_types != undefined)
      specialOfferTypes = uploadSessionItem.special_offer_types.map(
        (so: SpecialOfferType) => {
          return {
            title:
              so.name.find((t: Translatable) => t.language == 'HR')?.content ??
              '',
            value: so.id,
            isSelectable: true,
          };
        }
      );

    let tag: AutocompleteOption | undefined = undefined;

    if (
      uploadSessionItem.tag_list != undefined &&
      uploadSessionItem.tag_list.length != 0
    )
      tag = {
        title:
          uploadSessionItem.tag_list[0].name.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',
        value: uploadSessionItem.tag_list[0].id,
        isSelectable: true,
      };

    let object: ImportItem = {
      id: parseInt(uploadSessionItem.id),
      width: width,
      height: height,
      aspectRatio:
        uploadSessionItem.aspect_ratio ??
        MainPreviewComponent.DEFAULT_ASPECT_RATIO,
      previewUrl: uploadSessionItem.preview?.preview_url,
      blurPreviewUrl: uploadSessionItem.preview?.preview_url_blur,
      largePreviewUrl: uploadSessionItem.preview?.preview_url_large,
      previewVideoLoop: uploadSessionItem.preview?.preview_video_loop,
      previewVideo720d: uploadSessionItem.preview?.preview_video_720p,

      isVideo: uploadSessionItem.preview?.preview_video_720p != null,
      isLocked: this.checkedIsLocked(uploadSessionItem),
      lockedBy: this.lockedByName(uploadSessionItem),

      title:
        uploadSessionItem.headline?.find(
          (t: Translatable) => t.language == 'HR'
        )?.content ?? '',
      headlineEn: {
        title:
          uploadSessionItem.headline?.find(
            (t: Translatable) => t.language == 'EN'
          )?.content ?? '',
        value: -1,
        isSelectable: true,
      },
      headline: {
        title: this.getHeadline(uploadSessionItem),
        value: -1,
        isSelectable: true,
      },

      caption:
        uploadSessionItem.caption?.find((t: Translatable) => t.language == 'HR')
          ?.content ?? '',
      people: uploadSessionItem.people_list?.map((p: Person) => {
        return {
          title: p.name,
          value: p.id,
          isSelectable: true,
        };
      }),
      keywords: uploadSessionItem.keywords_list?.map((k: Keyword) => {
        return {
          title:
            k.name?.find((t: Translatable) => t.language == 'HR')?.content ??
            '',
          value: k.id,
          isSelectable: true,
        };
      }),

      categories: categories,
      author: author,
      agency: agency,
      tag: tag,
      specialOffer: uploadSessionItem.is_special_offer,
      specialOfferTypes: specialOfferTypes,
      apiFtp: uploadSessionItem.api_ftp,
      //icence: licenceType,

      printPrice: uploadSessionItem.print_price,
      webPrice: uploadSessionItem.web_price,

      needsTranslationEn: uploadSessionItem.needs_translation,
      captionEn:
        uploadSessionItem.caption?.find((t: Translatable) => t.language == 'EN')
          ?.content ?? '',
      keywordsEn: uploadSessionItem.keywords_list?.map((k: Keyword) => {
        return {
          title:
            k.name?.find((t: Translatable) => t.language == 'EN')?.content ??
            '',
          value: k.id,
          isSelectable: true,
        };
      }),
      galleryId: galleryId,
      videoTitle:
        uploadSessionItem.video_title?.find(
          (t: Translatable) => t.language == 'HR'
        )?.content ?? '',
      dateCreated: uploadSessionItem.created_date,
      dateImported: uploadSessionItem.imported_date,
      printPriceEur: uploadSessionItem.print_price_eur,
      webPriceEur: uploadSessionItem.web_price_eur,
    };

    return object;
  }

  static getHeadline(uploadSessionItem: any): string {
    if (uploadSessionItem.headline == undefined) return '';

    if (uploadSessionItem.headline!.length == 0) return '';

    return (
      uploadSessionItem.headline!.find((h: Translatable) => h.language == 'HR')
        ?.content ?? ''
    );
  }

  static lockedByName(uploadSessionItem: any): string | undefined {
    if (this.checkedIsLocked(uploadSessionItem)) {
      return (
        uploadSessionItem.editing_lock!.locked_by!.first_name +
        ' ' +
        uploadSessionItem.editing_lock!.locked_by!.last_name
      );
    }

    return undefined;
  }

  static checkedIsLocked(uploadSessionItem: any): boolean | undefined {
    if (uploadSessionItem.editing_lock != undefined) {
      let raw: string =
        <string>(<unknown>uploadSessionItem.editing_lock.locked_at!) + 'Z';
      let lockedAt = new Date(raw);
      let newDate = new Date();

      if (lockedAt != undefined) {
        let timeDiff = newDate.getTime() - lockedAt!.getTime();

        // More than 5 minutes.
        return timeDiff < 15 * 1000;
      }

      return;
    }

    return false;
  }

  deserializeProducts(
    products: ProductList,
    galleryId: number | undefined
  ): ImportItem[] {
    return products.product_list!.map((uploadSessionItem) => {
      return ProductsService.deserializeProduct(
        uploadSessionItem as any,
        galleryId
      );
    });
  }

  serializeUploadSession(): ProductUpdate {
    let changedItems = this.unsavedItemsIds
      .map((id) => {
        let normalWay = this.getItemById(id);
        if (normalWay != undefined) return normalWay;

        if (this.editingGallery != undefined) {
          return this.splittedGalleryItems[id];
        }

        return undefined;
      })
      .filter((f) => f != undefined)
      .map((a) => a as ImportItem);
    let mediaList: any[] = changedItems.map((item) => {
      // Here we have to fill out such information and take in account
      // where we have a new upload item or if the upload item is something
      // from an old session.
      //
      // LIST OF FIELDS:
      // - id: string;
      // - agency?: Agency;
      // - api_ftp?: boolean;
      // - aspect_ratio?: number;
      // - author?: Author;
      // - caption: Array<Translatable>;
      // - category?: Category;
      // - date_created?: string;
      // - editing_lock?: MediaItemLock;
      // - /**
      // -  * If we don't send a gallery_id then create a gallery from     headline on publish
      // -  */
      // - gallery_id?: number;
      // - has_preview?: boolean;
      // - /**
      // -  * If we don't send a gallery_id then create a gallery from     headline on publish
      // -  */
      // - headline?: Array<Translatable>;
      // - /**
      // -  * UUID client side generated
      // -  */
      // - is_special_offer?: boolean;
      // - keywords_list?: Array<Keyword>;
      // - kind: MediaItem.KindEnum;
      // - licence_type?: LicenceType;
      // - needs_translation?: boolean;
      // - people_list?: Array<Person>;
      // - preview_url?: string;
      // - preview_url_blur?: string;
      // - preview_url_large?: string;
      // - special_offer_type?: SpecialOfferType;
      // - tag_list?: Array<Tag>;

      let agency: Agency | undefined = undefined;
      if (item.agency != undefined)
        agency = {
          id: item.agency.value,
          name: item.agency.title,
        };

      let author: Author | undefined = undefined;
      if (item.author != undefined) {
        author = {
          id: item.author.value,
          name: item.author.title,
        };
      }

      let category: Category | undefined = undefined;
      if (item.categories != undefined)
        category = {
          id: item.categories.value,
          name: [],
        };

      let keywordsList: Keyword[] = [];
      if (item.keywords != undefined)
        item.keywords.forEach((k) => {
          keywordsList.push({
            id: k.value,
            name: [{ language: 'HR', content: k.title }],
          });
        });

      if (item.keywordsEn != undefined)
        item.keywordsEn.forEach((k) => {
          keywordsList.push({
            id: k.value,
            name: [{ language: 'EN', content: k.title }],
          });
        });

      let licenceType: LicenceType | undefined = undefined;
      if (item.author != undefined)
        author = {
          id: item.author.value,
          name: item.author.title,
        };

      let peopleList: Array<Person> = [];
      if (item.people != undefined)
        item.people.forEach((k) => {
          peopleList.push({
            id: k.value,
            name: k.title,
          });
        });

      let specialOfferTypes: SpecialOfferType[] | undefined = undefined;
      if (item.specialOfferTypes != undefined)
        specialOfferTypes = item.specialOfferTypes.map((so) => {
          return {
            id: so.value,
            name: [],
          };
        });

      let tagList: Array<Tag> = [];
      if (item.tag != undefined)
        tagList = [
          {
            id: item.tag!.value,
            name: [],
          },
        ];

      let headline: Translatable[] = [
        { language: 'HR', content: item.headline?.title },
      ];

      if (item.headlineEn?.title != undefined) {
        headline.push({ language: 'EN', content: item.headlineEn?.title });
      }

      let uploadItem = {
        // This is required, but not for sending to the server.
        editing_lock: undefined,
        has_preview: false,
        kind: 'PHOTO',

        id: item.id.toString(),
        agency: agency,
        api_ftp: item.apiFtp,
        aspect_ratio: undefined,
        author: author,
        caption: [
          { language: 'HR', content: item.caption },
          { language: 'EN', content: item.captionEn },
        ],
        category: category,
        date_created: undefined, // Backend team needs to calc this
        gallery_id: item.galleryId,
        headline: headline,
        is_special_offer: item.specialOffer ?? false,
        keywords_list: keywordsList,
        //licence_type: licenceType,
        video_title: [{ language: 'HR', content: item.videoTitle }],
        needs_translation: item.needsTranslationEn,
        people_list: peopleList,
        preview: {
          preview_url: item.previewUrl,
          preview_url_large: item.largePreviewUrl,
          preview_url_blur: item.blurPreviewUrl,
          preview_video_720p: item.previewVideo720d,
          preview_video_loop: item.previewVideoLoop,
        },
        special_offer_type: specialOfferTypes,
        tag_list: tagList,
        print_price: item.printPrice,
        web_price: item.webPrice,

        print_price_eur: item.printPriceEur,
        web_price_eur: item.webPriceEur,
      };

      return uploadItem;
    });

    return {
      product_list: mediaList,
      is_gallery_edit: this.editingGallery != undefined,
    };
  }

  private lockImportItems(importItems: ImportItem[]) {
    let ids = importItems.map((item) => item.id);
    this.apiProductsService.cmsLockProducts({ ids: ids }).subscribe((_) => {
      //alert("Done");
    });
  }

  private errorResponseSource = new Subject<ErrorResponse | undefined>();
  public errorResponseData: Observable<ErrorResponse | undefined> =
    this.errorResponseSource.asObservable();

  private errorIdsSource = new Subject<number[]>();
  public errorIdsData: Observable<number[]> =
    this.errorIdsSource.asObservable();

  private saveQueueSource = new Subject<(() => void) | undefined>();
  public saveQueueData: Observable<(() => void) | undefined> =
    this.saveQueueSource.asObservable();

  private savingStatusSource = new BehaviorSubject<boolean>(false);
  public saveStatusData: Observable<boolean> =
    this.savingStatusSource.asObservable();

  public publishSession(
    kind: UploadSessionPublishRequest.KindEnum,
    isNewsletterPush: boolean,
    isNotificationPush: boolean
  ) {}

  public queueSaveSession(callback: (() => void) | undefined) {
    this.saveQueueSource.next(callback);
  }

  private setUpSaveSession() {
    this.saveQueueData.subscribe((callback) => {
      this.saveSession(callback);
    });
  }

  private saveSession(callback: (() => void) | undefined) {
    this.savingStatusSource.next(false);
    let productList = this.serializeUploadSession() as any as ProductUpdate;
    this.apiProductsService.cmsSaveProducts(productList).subscribe(
      (result) => {
        this.markAllNotDirty();

        var completeFunction = (shouldRunDefaultAfterSave: boolean) => {
          if (shouldRunDefaultAfterSave && callback != undefined) {
            callback();
          }
        };

        if (this.afterSaveSource.observers.length != 0) {
          this.afterSaveSource.next(completeFunction);
        } else {
          completeFunction(true);
        }
      },
      (error) => {
        let errorResponse: ErrorResponse = error.error;
        this.errorResponseSource.next(errorResponse);
        this.errorIdsSource.next(
          errorResponse.errors.map((e) => parseInt(e.item_id ?? '-1'))
        );
        alert('Error saving. Please check all the marked items.');
      }
    );
  }

  /////////////////////////////
  // Uploading files
  /////////////////////////////

  public uploadFile(files: any, itemId: number) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      // TODO: Implement
      this.apiProductsService
        .cmsReplaceProductMedia(itemId, item, 'events', true)
        .subscribe((result: any) => {
          if (result.type == 1) {
            item.progress = (result.loaded / result.total) * 100.0;

            // It's finished.
            if (item.progress >= 100.0) {
              this.files = this.files.filter((file) => file !== item);
              this.uploadingFilesSource.next(this.files);

              window.location.reload();
            }
          }
        });
    }

    this.uploadingFilesSource.next(this.files);
  }

  /////////////////////////////
  // Reordering
  /////////////////////////////

  private move(array: any[], oldIndex: number, newIndex: number) {
    let temp = array[newIndex];
    array[newIndex] = array[oldIndex];
    array[oldIndex] = temp;
  }

  public moveItemFromIndexToIndex(oldIndex: number, newIndex: number) {
    this.move(this.getItems(), oldIndex, newIndex);
    this.itemsSource.next(this.getItems());
  }

  /////////////////////////////
  // Deleting
  /////////////////////////////

  public editingGallery?: GalleryImportItem;

  public editingGallerySource = new Subject<GalleryImportItem>();
  public editingGalleryData: Observable<GalleryImportItem> =
    this.editingGallerySource.asObservable();

  setEditingGallery(
    gallery: GalleryImportItem | undefined,
    shouldLoadFromRemote: boolean = false
  ) {
    this.editingGallery = gallery;

    if (this.editingGallery != undefined) {
      if (shouldLoadFromRemote) {
        this.apiGalleryService
          .cmsGetGallery(gallery!.id!.toString())
          .subscribe((full) => {
            const deserialized =
              GalleriesService.serverSchemaToClientSchema(full);

            this.clearItems();
            gallery!.items = deserialized.items;

            this.editingGallerySource.next(deserialized);
          });
      } else {
        this.editingGallerySource.next(gallery);
      }
    } else {
      this.editingGallerySource.next(undefined);
    }
  }

  getEditingGallery(): GalleryImportItem | undefined {
    return this.editingGallery;
  }

  computePreviewGalleryImportItem(
    gallery: GalleryImportItem
  ): string | undefined {
    if (gallery.items?.length ?? 0 > 1) {
      return gallery.items![0].previewUrl;
    }

    return undefined;
  }

  public deleteSelected() {
    if (this.editingGallery != undefined) {
      this.editingGallery!.items = this.editingGallery!.items?.filter(
        (i) => !this.selectedIds.includes(i.id)
      );
      this.editingGallery!.previewUrl = this.computePreviewGalleryImportItem(
        this.editingGallery!
      );

      this.editingGallerySource.next(this.editingGallery);

      return;
    }

    if (
      prompt(
        `Type 'CONFIRM' to confirm you want to delete product(s): ${this.selectedIds.join(
          ', '
        )}`
      ) != 'CONFIRM'
    ) {
      return;
    }

    let request: IdsList = {
      ids: this.selectedIds,
    };

    let toDeleteIds = this.selectedIds;

    this.loadingService.setLoading(true);
    this.apiProductsService.cmsDeleteProducts(request).subscribe(
      (result: any) => {
        this.items = this.items.filter((i) => !toDeleteIds.includes(i.id));
        toDeleteIds.forEach((id: number) => {
          delete this.itemsById[id];
        });

        this.selectedIds = this.selectedIds.filter(
          (id) => !toDeleteIds.includes(id)
        );
        this.selectedIdsSource.next(this.selectedIds);

        this.itemsSource.next(this.items);
        this.snackBarService.showSnackBar(
          'Successfully deleted product(s).',
          'INFO'
        );
        this.loadingService.setLoading(false);
      },
      (error) => {
        this.snackBarService.showSnackBar(
          'Failed deleting product(s).',
          'ERROR'
        );
        this.loadingService.setLoading(false);
      }
    );
  }

  /////////////////////////////
  // Actions
  /////////////////////////////
  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceInString(string: string, needle: string, withValue: string): string {
    const escapedRegexNeedle = this.escapeRegExp(needle);
    return string.replace(new RegExp(escapedRegexNeedle, 'gi'), withValue);
  }

  replace(
    field: string,
    what: AutocompleteOption,
    withWhat: AutocompleteOption
  ) {
    this.getItems().forEach((item) => {
      if (!this.selectedIds.includes(item.id)) return;

      var dirtyItem = false;

      switch (field) {
        case 'headline':
          if (item?.headline?.title.includes(what.title)) {
            dirtyItem = true;
            if (item.headline != undefined) {
              item.headline = {
                title: this.replaceInString(
                  item.headline!.title,
                  what.title,
                  withWhat.title
                ),
                isSelectable: item.headline!.isSelectable,
                value: item.headline!.value,
              };
              item.title = item.headline!.title;
            }
          }

          break;

        case 'caption':
          if (item.caption?.includes(what.title)) {
            dirtyItem = true;
            item.caption = this.replaceInString(
              item.caption!,
              what.title,
              withWhat.title
            );
          }

          break;

        case 'keywords':
          if (item.keywords?.find((p) => p.title == what.title)) {
            item.keywords = item.keywords.map((ao) => {
              if (ao.title == what.title) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          if (item.keywordsEn?.find((p) => p.value == what.value)) {
            item.keywordsEn = item.keywordsEn.map((ao) => {
              if (ao.value == what.value) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          break;

        case 'people':
          if (item.people?.find((p) => p.value == what.value)) {
            item.people = item.people.map((ao) => {
              if (ao.value == what.value) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          break;
      }

      if (dirtyItem) {
        this.markAsDirty([item]);
      }
    });

    this.loadDataForSelectedItem();
  }

  append(field: string, what: AutocompleteOption) {
    this.getItems().forEach((item) => {
      if (!this.selectedIds.includes(item.id)) return;

      switch (field) {
        case 'people':
          item.people?.push(what);

          break;

        case 'keywords':
          item.keywords?.push(what);

          break;

        case 'keywordsEn':
          item.keywordsEn?.push(what);

          break;

        case 'caption':
          this.itemsById[item.id].caption += what.title;
          break;
      }
    });
    this.markAsDirty(this.getItems());
    this.loadDataForSelectedItem();
  }

  cancel() {
    //console.log(`products.service.ts unsavedItemsIds = [], cancel`);
    this.unsavedItemsIds = [];
    this.isUnsaved = false;
    this.isUnsavedSource.next(false);
    //console.log('<products.service.ts>', 'isUnsaved', 'False cause cancel.');
  }

  private selectAllSource = new Subject<boolean>();
  public selectAllData: Observable<boolean> =
    this.selectAllSource.asObservable();

  selectAll() {
    this.selectAllSource.next(true);
  }

  unselectAll() {
    this.selectAllSource.next(false);
  }

  getItems(): ImportItem[] {
    if (this.editingGallery != undefined) {
      return this.editingGallery!.items!;
    }

    return super.getItems();
  }

  getItemById(id: number): ImportItem {
    if (this.editingGallery != undefined) {
      //console.debug(`getItemById Override with editing gallery`);
      const preloaded = super.getItemById(id);
      if (preloaded != undefined) return preloaded;

      //console.debug(`getItemById Editing gallery not found preloaded`, this.editingGallery!.items!);

      return this.editingGallery!.items!.find((i) => {
        return i.id == id;
      })!;
    }

    return super.getItemById(id);
  }

  /*
   * DOWNLOADING
   */

  /**
   * Method is use to download file.
   * @param filename - File name to be downloaded.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  downloadBlobWithFilenameInUserBrowser(
    filename: string,
    data: any,
    type: string
  ) {
    var a: HTMLElement = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('STYLE', 'display: none');

    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);

    a.setAttribute('HREF', url);
    a.setAttribute('DOWNLOAD', filename);
    a.click();
  }

  downloadFileForCms(fileName: string, id: number) {
    this.loadingService.setLoading(true);
    this.apiProductsFront.cmsDownloadCMSProduct(id, 'LARGE', false).subscribe(
      (blob) => {
        this.downloadBlobWithFilenameInUserBrowser(
          fileName,
          blob,
          'image/jpeg'
        );
        this.loadingService.setLoading(false);
        this.snackBarService.showSnackBar(
          'Download will start soon...',
          'INFO'
        );
      },
      (error) => {
        //console.error('<products.service.ts>', 'Failed downloading file', error);
        this.loadingService.setLoading(false);
        this.snackBarService.showSnackBar('Error downloading.', 'ERROR');
      }
    );
  }
}
