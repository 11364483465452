import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, share, tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, HttpResponse<any>> = new Map();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req);

    /*if (req.method !== "GET" && req.urlWithParams.endsWith("/available_filters")) {
      return next.handle(req)
    }

    const cachedResponse: HttpResponse<any> | undefined = this.cache.get(req.urlWithParams)
    if (cachedResponse) {
      console.log("CACHED", req.urlWithParams);
      return of(cachedResponse)
    } else {
      return next.handle(req).pipe(
        tap(stateEvent => {
          if (stateEvent instanceof HttpResponse) {
            this.cache.set(req.urlWithParams, stateEvent.clone())
          }
        }),
        share()
      )
    }*/
  }
}
