import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateImageTags',
})
export class TranslateImageTagsPipe implements PipeTransform {
  transform(defaultValue: string, currentLang: string): string {
    if (currentLang == 'hr') {
      return defaultValue;
    } else if (currentLang == 'en') {
      return defaultValue.split('.').join('-en.');
    } else {
      return defaultValue;
    }
  }
}
