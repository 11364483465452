<label for="switch" (click)="click()">
  <input
    type="checkbox"
    name="switch"
    id="switch"
    [(ngModel)]="model"
    (ngModelChange)="handleModelChange($event)"
    [disabled]="isDisabled"
  />
  <div class="background" [class.disabled]="isDisabled">
    <div class="circle"></div>
  </div>
</label>
