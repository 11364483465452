/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { HomepageResponse } from '../model/homepageResponse';
import { SubportalArrowNavigation } from '../model/subportalArrowNavigation';
import { SubportalFilterResponse } from '../model/subportalFilterResponse';
import { SubportalResponse } from '../model/subportalResponse';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class HomepageService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Get the Homepage stock
   * @param page Pagination page
   * @param page_size Items per page
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetDjevojkaDana(
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalResponse>;
  public frontGetDjevojkaDana(
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalResponse>>;
  public frontGetDjevojkaDana(
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalResponse>>;
  public frontGetDjevojkaDana(
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalResponse>(
      'get',
      `${this.basePath}/home/djevojka_dana`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param text Omnisearch Text
   * @param page Pagination page
   * @param page_size Items per page
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepage(
    text?: string,
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageResponse>;
  public frontGetHomepage(
    text?: string,
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageResponse>>;
  public frontGetHomepage(
    text?: string,
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageResponse>>;
  public frontGetHomepage(
    text?: string,
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageResponse>(
      'get',
      `${this.basePath}/home`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param page Pagination page
   * @param page_size Items per page
   * @param text Omnisearch Text
   * @param main_filter Agefotostock main filter ID, \\&#x27;filterid\\&#x27; field in Agefotostock
   * @param provider_filter_list Comma separated list of Agefotostock provider filter IDs, \\&#x27;providerslist\\&#x27; field in Agefotostock
   * @param refinement_filter_list Comma separated list of Agefotostock refinement filter IDs, \\&#x27;searchfilterslist\\&#x27; field in Agefotostock
   * @param interval Omnisearch Text
   * @param interval_start
   * @param interval_end
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageAgefotostock(
    page?: number,
    page_size?: number,
    text?: string,
    main_filter?: string,
    provider_filter_list?: Array<string>,
    refinement_filter_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalResponse>;
  public frontGetHomepageAgefotostock(
    page?: number,
    page_size?: number,
    text?: string,
    main_filter?: string,
    provider_filter_list?: Array<string>,
    refinement_filter_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalResponse>>;
  public frontGetHomepageAgefotostock(
    page?: number,
    page_size?: number,
    text?: string,
    main_filter?: string,
    provider_filter_list?: Array<string>,
    refinement_filter_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalResponse>>;
  public frontGetHomepageAgefotostock(
    page?: number,
    page_size?: number,
    text?: string,
    main_filter?: string,
    provider_filter_list?: Array<string>,
    refinement_filter_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (main_filter !== undefined && main_filter !== null) {
      queryParameters = queryParameters.set('main_filter', <any>main_filter);
    }
    if (provider_filter_list) {
      queryParameters = queryParameters.set(
        'provider_filter_list',
        provider_filter_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (refinement_filter_list) {
      queryParameters = queryParameters.set(
        'refinement_filter_list',
        refinement_filter_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (interval !== undefined && interval !== null) {
      queryParameters = queryParameters.set('interval', <any>interval);
    }
    if (interval_start !== undefined && interval_start !== null) {
      queryParameters = queryParameters.set(
        'interval_start',
        <any>interval_start
      );
    }
    if (interval_end !== undefined && interval_end !== null) {
      queryParameters = queryParameters.set('interval_end', <any>interval_end);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalResponse>(
      'get',
      `${this.basePath}/home/agefotostock`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageAgefotostockFilter(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalFilterResponse>;
  public frontGetHomepageAgefotostockFilter(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalFilterResponse>>;
  public frontGetHomepageAgefotostockFilter(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalFilterResponse>>;
  public frontGetHomepageAgefotostockFilter(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalFilterResponse>(
      'get',
      `${this.basePath}/home/agefotostock/available_filters`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param page Pagination page
   * @param page_size Items per page
   * @param text Omnisearch Text
   * @param category_list Comma separated list of IDs for categories
   * @param interval Omnisearch Text
   * @param interval_start
   * @param interval_end
   * @param tag_list Comma separated list of IDs for tags
   * @param author_id
   * @param orientation
   * @param colour
   * @param agency_list Comma separated list of IDs for Agencies
   * @param kind
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageAgencies(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    agency_list?: Array<number>,
    kind?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalResponse>;
  public frontGetHomepageAgencies(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    agency_list?: Array<number>,
    kind?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalResponse>>;
  public frontGetHomepageAgencies(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    agency_list?: Array<number>,
    kind?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalResponse>>;
  public frontGetHomepageAgencies(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    agency_list?: Array<number>,
    kind?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (category_list) {
      queryParameters = queryParameters.set(
        'category_list',
        category_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (interval !== undefined && interval !== null) {
      queryParameters = queryParameters.set('interval', <any>interval);
    }
    if (interval_start !== undefined && interval_start !== null) {
      queryParameters = queryParameters.set(
        'interval_start',
        <any>interval_start
      );
    }
    if (interval_end !== undefined && interval_end !== null) {
      queryParameters = queryParameters.set('interval_end', <any>interval_end);
    }
    if (tag_list) {
      queryParameters = queryParameters.set(
        'tag_list',
        tag_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (orientation !== undefined && orientation !== null) {
      queryParameters = queryParameters.set('orientation', <any>orientation);
    }
    if (colour !== undefined && colour !== null) {
      queryParameters = queryParameters.set('colour', <any>colour);
    }
    if (agency_list) {
      queryParameters = queryParameters.set(
        'agency_list',
        agency_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalResponse>(
      'get',
      `${this.basePath}/home/agencies`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageAgenciesFilter(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalFilterResponse>;
  public frontGetHomepageAgenciesFilter(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalFilterResponse>>;
  public frontGetHomepageAgenciesFilter(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalFilterResponse>>;
  public frontGetHomepageAgenciesFilter(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalFilterResponse>(
      'get',
      `${this.basePath}/home/agencies/available_filters`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param page Pagination page
   * @param page_size Items per page
   * @param text Omnisearch Text
   * @param category_list Comma separated list of IDs for categories
   * @param interval Omnisearch Text
   * @param interval_start
   * @param interval_end
   * @param tag_list Comma separated list of IDs for tags
   * @param author_id
   * @param orientation
   * @param colour
   * @param kind
   * @param agency_list Comma separated list of IDs for Agencies
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageAll(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalResponse>;
  public frontGetHomepageAll(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalResponse>>;
  public frontGetHomepageAll(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalResponse>>;
  public frontGetHomepageAll(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (category_list) {
      queryParameters = queryParameters.set(
        'category_list',
        category_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (interval !== undefined && interval !== null) {
      queryParameters = queryParameters.set('interval', <any>interval);
    }
    if (interval_start !== undefined && interval_start !== null) {
      queryParameters = queryParameters.set(
        'interval_start',
        <any>interval_start
      );
    }
    if (interval_end !== undefined && interval_end !== null) {
      queryParameters = queryParameters.set('interval_end', <any>interval_end);
    }
    if (tag_list) {
      queryParameters = queryParameters.set(
        'tag_list',
        tag_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (orientation !== undefined && orientation !== null) {
      queryParameters = queryParameters.set('orientation', <any>orientation);
    }
    if (colour !== undefined && colour !== null) {
      queryParameters = queryParameters.set('colour', <any>colour);
    }
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }
    if (agency_list) {
      queryParameters = queryParameters.set(
        'agency_list',
        agency_list.join(COLLECTION_FORMATS['pipe'])
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalResponse>(
      'get',
      `${this.basePath}/home/all`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageAllFilter(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalFilterResponse>;
  public frontGetHomepageAllFilter(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalFilterResponse>>;
  public frontGetHomepageAllFilter(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalFilterResponse>>;
  public frontGetHomepageAllFilter(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalFilterResponse>(
      'get',
      `${this.basePath}/home/all/available_filters`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param page Pagination page
   * @param page_size Items per page
   * @param text Omnisearch Text
   * @param category_list Comma separated list of IDs for categories
   * @param interval Omnisearch Text
   * @param interval_start
   * @param interval_end
   * @param tag_list Comma separated list of IDs for tags
   * @param author_id
   * @param orientation
   * @param colour
   * @param kind
   * @param agency_list Comma separated list of IDs for Agencies
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageProduction(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalResponse>;
  public frontGetHomepageProduction(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalResponse>>;
  public frontGetHomepageProduction(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalResponse>>;
  public frontGetHomepageProduction(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (category_list) {
      queryParameters = queryParameters.set(
        'category_list',
        category_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (interval !== undefined && interval !== null) {
      queryParameters = queryParameters.set('interval', <any>interval);
    }
    if (interval_start !== undefined && interval_start !== null) {
      queryParameters = queryParameters.set(
        'interval_start',
        <any>interval_start
      );
    }
    if (interval_end !== undefined && interval_end !== null) {
      queryParameters = queryParameters.set('interval_end', <any>interval_end);
    }
    if (tag_list) {
      queryParameters = queryParameters.set(
        'tag_list',
        tag_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (orientation !== undefined && orientation !== null) {
      queryParameters = queryParameters.set('orientation', <any>orientation);
    }
    if (colour !== undefined && colour !== null) {
      queryParameters = queryParameters.set('colour', <any>colour);
    }
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }
    if (agency_list) {
      queryParameters = queryParameters.set(
        'agency_list',
        agency_list.join(COLLECTION_FORMATS['pipe'])
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalResponse>(
      'get',
      `${this.basePath}/home/production`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageProductionFilter(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalFilterResponse>;
  public frontGetHomepageProductionFilter(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalFilterResponse>>;
  public frontGetHomepageProductionFilter(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalFilterResponse>>;
  public frontGetHomepageProductionFilter(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalFilterResponse>(
      'get',
      `${this.basePath}/home/production/available_filters`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param page Pagination page
   * @param page_size Items per page
   * @param text Omnisearch Text
   * @param category_list Comma separated list of IDs for categories
   * @param interval Omnisearch Text
   * @param interval_start
   * @param interval_end
   * @param tag_list Comma separated list of IDs for tags
   * @param author_id
   * @param orientation
   * @param colour
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageReuters(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalResponse>;
  public frontGetHomepageReuters(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalResponse>>;
  public frontGetHomepageReuters(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalResponse>>;
  public frontGetHomepageReuters(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (category_list) {
      queryParameters = queryParameters.set(
        'category_list',
        category_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (interval !== undefined && interval !== null) {
      queryParameters = queryParameters.set('interval', <any>interval);
    }
    if (interval_start !== undefined && interval_start !== null) {
      queryParameters = queryParameters.set(
        'interval_start',
        <any>interval_start
      );
    }
    if (interval_end !== undefined && interval_end !== null) {
      queryParameters = queryParameters.set('interval_end', <any>interval_end);
    }
    if (tag_list) {
      queryParameters = queryParameters.set(
        'tag_list',
        tag_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (orientation !== undefined && orientation !== null) {
      queryParameters = queryParameters.set('orientation', <any>orientation);
    }
    if (colour !== undefined && colour !== null) {
      queryParameters = queryParameters.set('colour', <any>colour);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalResponse>(
      'get',
      `${this.basePath}/home/reuters`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageReutersFilter(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalFilterResponse>;
  public frontGetHomepageReutersFilter(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalFilterResponse>>;
  public frontGetHomepageReutersFilter(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalFilterResponse>>;
  public frontGetHomepageReutersFilter(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalFilterResponse>(
      'get',
      `${this.basePath}/home/reuters/available_filters`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage stock
   * @param page Pagination page
   * @param page_size Items per page
   * @param text Omnisearch Text
   * @param category_list Comma separated list of IDs for categories
   * @param interval Omnisearch Text
   * @param interval_start
   * @param interval_end
   * @param tag_list Comma separated list of IDs for tags
   * @param author_id
   * @param orientation
   * @param colour
   * @param kind
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageStock(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalResponse>;
  public frontGetHomepageStock(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalResponse>>;
  public frontGetHomepageStock(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalResponse>>;
  public frontGetHomepageStock(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (category_list) {
      queryParameters = queryParameters.set(
        'category_list',
        category_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (interval !== undefined && interval !== null) {
      queryParameters = queryParameters.set('interval', <any>interval);
    }
    if (interval_start !== undefined && interval_start !== null) {
      queryParameters = queryParameters.set(
        'interval_start',
        <any>interval_start
      );
    }
    if (interval_end !== undefined && interval_end !== null) {
      queryParameters = queryParameters.set('interval_end', <any>interval_end);
    }
    if (tag_list) {
      queryParameters = queryParameters.set(
        'tag_list',
        tag_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (orientation !== undefined && orientation !== null) {
      queryParameters = queryParameters.set('orientation', <any>orientation);
    }
    if (colour !== undefined && colour !== null) {
      queryParameters = queryParameters.set('colour', <any>colour);
    }
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalResponse>(
      'get',
      `${this.basePath}/home/stock`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageStockFilter(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalFilterResponse>;
  public frontGetHomepageStockFilter(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalFilterResponse>>;
  public frontGetHomepageStockFilter(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalFilterResponse>>;
  public frontGetHomepageStockFilter(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalFilterResponse>(
      'get',
      `${this.basePath}/home/stock/available_filters`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param page Pagination page
   * @param page_size Items per page
   * @param text Omnisearch Text
   * @param category_list Comma separated list of IDs for categories
   * @param interval Omnisearch Text
   * @param interval_start
   * @param interval_end
   * @param tag_list Comma separated list of IDs for tags
   * @param author_id
   * @param agency_list Comma separated list of IDs for Agencies
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageVideo(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    agency_list?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalResponse>;
  public frontGetHomepageVideo(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    agency_list?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalResponse>>;
  public frontGetHomepageVideo(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    agency_list?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalResponse>>;
  public frontGetHomepageVideo(
    page?: number,
    page_size?: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    agency_list?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (category_list) {
      queryParameters = queryParameters.set(
        'category_list',
        category_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (interval !== undefined && interval !== null) {
      queryParameters = queryParameters.set('interval', <any>interval);
    }
    if (interval_start !== undefined && interval_start !== null) {
      queryParameters = queryParameters.set(
        'interval_start',
        <any>interval_start
      );
    }
    if (interval_end !== undefined && interval_end !== null) {
      queryParameters = queryParameters.set('interval_end', <any>interval_end);
    }
    if (tag_list) {
      queryParameters = queryParameters.set(
        'tag_list',
        tag_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (agency_list) {
      queryParameters = queryParameters.set(
        'agency_list',
        agency_list.join(COLLECTION_FORMATS['pipe'])
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalResponse>(
      'get',
      `${this.basePath}/home/video`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the Homepage content
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageVideoFilter(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalFilterResponse>;
  public frontGetHomepageVideoFilter(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalFilterResponse>>;
  public frontGetHomepageVideoFilter(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalFilterResponse>>;
  public frontGetHomepageVideoFilter(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalFilterResponse>(
      'get',
      `${this.basePath}/home/video/available_filters`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Navigate in search results using keyboard arrows
   * @param subportal Subportal used for search results
   * @param position_in_search Current position in search results
   * @param item_id ID of the current item
   * @param text Omnisearch Text
   * @param category_list Comma separated list of IDs for categories
   * @param interval Omnisearch Text
   * @param interval_start
   * @param interval_end
   * @param tag_list Comma separated list of IDs for tags
   * @param author_id
   * @param orientation
   * @param colour
   * @param kind
   * @param agency_list Comma separated list of IDs for Agencies
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetSearchNavigation(
    subportal: string,
    position_in_search: number,
    item_id: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SubportalArrowNavigation>;
  public frontGetSearchNavigation(
    subportal: string,
    position_in_search: number,
    item_id: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SubportalArrowNavigation>>;
  public frontGetSearchNavigation(
    subportal: string,
    position_in_search: number,
    item_id: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SubportalArrowNavigation>>;
  public frontGetSearchNavigation(
    subportal: string,
    position_in_search: number,
    item_id: number,
    text?: string,
    category_list?: Array<number>,
    interval?: string,
    interval_start?: string,
    interval_end?: string,
    tag_list?: Array<number>,
    author_id?: number,
    orientation?: string,
    colour?: string,
    kind?: string,
    agency_list?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subportal === null || subportal === undefined) {
      throw new Error(
        'Required parameter subportal was null or undefined when calling frontGetSearchNavigation.'
      );
    }

    if (position_in_search === null || position_in_search === undefined) {
      throw new Error(
        'Required parameter position_in_search was null or undefined when calling frontGetSearchNavigation.'
      );
    }

    if (item_id === null || item_id === undefined) {
      throw new Error(
        'Required parameter item_id was null or undefined when calling frontGetSearchNavigation.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (subportal !== undefined && subportal !== null) {
      queryParameters = queryParameters.set('subportal', <any>subportal);
    }
    if (position_in_search !== undefined && position_in_search !== null) {
      queryParameters = queryParameters.set(
        'position_in_search',
        <any>position_in_search
      );
    }
    if (item_id !== undefined && item_id !== null) {
      queryParameters = queryParameters.set('item_id', <any>item_id);
    }
    if (text !== undefined && text !== null) {
      queryParameters = queryParameters.set('text', <any>text);
    }
    if (category_list) {
      queryParameters = queryParameters.set(
        'category_list',
        category_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (interval !== undefined && interval !== null) {
      queryParameters = queryParameters.set('interval', <any>interval);
    }
    if (interval_start !== undefined && interval_start !== null) {
      queryParameters = queryParameters.set(
        'interval_start',
        <any>interval_start
      );
    }
    if (interval_end !== undefined && interval_end !== null) {
      queryParameters = queryParameters.set('interval_end', <any>interval_end);
    }
    if (tag_list) {
      queryParameters = queryParameters.set(
        'tag_list',
        tag_list.join(COLLECTION_FORMATS['pipe'])
      );
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (orientation !== undefined && orientation !== null) {
      queryParameters = queryParameters.set('orientation', <any>orientation);
    }
    if (colour !== undefined && colour !== null) {
      queryParameters = queryParameters.set('colour', <any>colour);
    }
    if (kind !== undefined && kind !== null) {
      queryParameters = queryParameters.set('kind', <any>kind);
    }
    if (agency_list) {
      queryParameters = queryParameters.set(
        'agency_list',
        agency_list.join(COLLECTION_FORMATS['pipe'])
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SubportalArrowNavigation>(
      'get',
      `${this.basePath}/home/arrow-navigation`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
