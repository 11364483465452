import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { CookiesService } from './cookies/cookies.service';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.scss'],
})
export class FrontComponent implements OnInit {
  isLoggedIn: boolean = false;

  cookieSettingsSubscription: Subscription = new Subscription();
  isCookieOpen: boolean = true;
  isCookieSettingsOpen: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private cookiesService: CookiesService
  ) {}

  ngOnInit(): void {
    this.authenticationService.isAuthenticatedData.subscribe(
      (isAuthenticated) => {
        this.isLoggedIn = isAuthenticated;
      }
    );

    this.cookieSettingsSubscription =
      this.cookiesService.openCookieSettingsSubject.subscribe((response) => {
        this.isCookieOpen = true;
        this.isCookieSettingsOpen = true;
      });
  }

  ngOnDestroy() {
    this.cookieSettingsSubscription.unsubscribe();
  }

  closeCookies() {
    this.isCookieOpen = false;
    this.isCookieSettingsOpen = false;
  }
}
