<div (click)="radioInput.checked = true" class="radio-container">
  <input
    #radioInput
    type="radio"
    id="{{ id }}"
    name="{{ name }}"
    value="{{ value }}"
    [attr.checked]="checked ? 'checked' : null"
  />
  <div class="custom-radio"></div>
</div>
