import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStorageService {
  constructor(private cookieService: CookieService) {}

  getToken() {
    return this.cookieService.get('token');
  }

  saveToken(token: string) {
    this.cookieService.remove('token');
    this.cookieService.set('token', token);
  }

  clearToken() {
    this.cookieService.remove('token');
  }
}
