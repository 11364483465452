/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Gallery } from '../model/gallery';
import { GalleryCrudRequestResponse } from '../model/galleryCrudRequestResponse';
import { GalleryList } from '../model/galleryList';
import { IdsList } from '../model/idsList';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class CMSGalleriesService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Combine galleries
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsCombineGalleries(
    body?: IdsList,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsCombineGalleries(
    body?: IdsList,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsCombineGalleries(
    body?: IdsList,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsCombineGalleries(
    body?: IdsList,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/galleries/combine`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Delete galleries
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteGalleries(
    body?: IdsList,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsDeleteGalleries(
    body?: IdsList,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsDeleteGalleries(
    body?: IdsList,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsDeleteGalleries(
    body?: IdsList,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/galleries/delete`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Search Galleries
   * @param page Page number
   * @param page_size Size of the page, result count. Default 50. Min 1, Max 100.
   * @param query Omnisearch text
   * @param query_include_headline General purpose search.
   * @param query_include_people General purpose search.
   * @param query_include_caption General purpose search.
   * @param query_include_keywords General purpose search.
   * @param from_date Date: From
   * @param to_date Date: To
   * @param product_status Upload channel
   * @param has_article Does the gallery have an article.
   * @param agency_id Search query parameters
   * @param category_id Category ID
   * @param gallery_id Direct gallery id to query
   * @param by_operator_id User ID for filtering created by
   * @param did_send_newsletter_push
   * @param marked_for_translation
   * @param is_translated
   * @param tag_id Tag ID
   * @param is_special_offer
   * @param upload_channel Upload channel
   * @param article_title
   * @param article_author_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetGalleries(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    from_date?: string,
    to_date?: string,
    product_status?: string,
    has_article?: boolean,
    agency_id?: number,
    category_id?: number,
    gallery_id?: number,
    by_operator_id?: number,
    did_send_newsletter_push?: boolean,
    marked_for_translation?: boolean,
    is_translated?: boolean,
    tag_id?: number,
    is_special_offer?: boolean,
    upload_channel?: string,
    article_title?: string,
    article_author_id?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GalleryList>;
  public cmsGetGalleries(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    from_date?: string,
    to_date?: string,
    product_status?: string,
    has_article?: boolean,
    agency_id?: number,
    category_id?: number,
    gallery_id?: number,
    by_operator_id?: number,
    did_send_newsletter_push?: boolean,
    marked_for_translation?: boolean,
    is_translated?: boolean,
    tag_id?: number,
    is_special_offer?: boolean,
    upload_channel?: string,
    article_title?: string,
    article_author_id?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GalleryList>>;
  public cmsGetGalleries(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    from_date?: string,
    to_date?: string,
    product_status?: string,
    has_article?: boolean,
    agency_id?: number,
    category_id?: number,
    gallery_id?: number,
    by_operator_id?: number,
    did_send_newsletter_push?: boolean,
    marked_for_translation?: boolean,
    is_translated?: boolean,
    tag_id?: number,
    is_special_offer?: boolean,
    upload_channel?: string,
    article_title?: string,
    article_author_id?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GalleryList>>;
  public cmsGetGalleries(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    from_date?: string,
    to_date?: string,
    product_status?: string,
    has_article?: boolean,
    agency_id?: number,
    category_id?: number,
    gallery_id?: number,
    by_operator_id?: number,
    did_send_newsletter_push?: boolean,
    marked_for_translation?: boolean,
    is_translated?: boolean,
    tag_id?: number,
    is_special_offer?: boolean,
    upload_channel?: string,
    article_title?: string,
    article_author_id?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }
    if (
      query_include_headline !== undefined &&
      query_include_headline !== null
    ) {
      queryParameters = queryParameters.set(
        'query_include_headline',
        <any>query_include_headline
      );
    }
    if (query_include_people !== undefined && query_include_people !== null) {
      queryParameters = queryParameters.set(
        'query_include_people',
        <any>query_include_people
      );
    }
    if (query_include_caption !== undefined && query_include_caption !== null) {
      queryParameters = queryParameters.set(
        'query_include_caption',
        <any>query_include_caption
      );
    }
    if (
      query_include_keywords !== undefined &&
      query_include_keywords !== null
    ) {
      queryParameters = queryParameters.set(
        'query_include_keywords',
        <any>query_include_keywords
      );
    }
    if (from_date !== undefined && from_date !== null) {
      queryParameters = queryParameters.set('from_date', <any>from_date);
    }
    if (to_date !== undefined && to_date !== null) {
      queryParameters = queryParameters.set('to_date', <any>to_date);
    }
    if (product_status !== undefined && product_status !== null) {
      queryParameters = queryParameters.set(
        'product_status',
        <any>product_status
      );
    }
    if (has_article !== undefined && has_article !== null) {
      queryParameters = queryParameters.set('has_article', <any>has_article);
    }
    if (agency_id !== undefined && agency_id !== null) {
      queryParameters = queryParameters.set('agency_id', <any>agency_id);
    }
    if (category_id !== undefined && category_id !== null) {
      queryParameters = queryParameters.set('category_id', <any>category_id);
    }
    if (gallery_id !== undefined && gallery_id !== null) {
      queryParameters = queryParameters.set('gallery_id', <any>gallery_id);
    }
    if (by_operator_id !== undefined && by_operator_id !== null) {
      queryParameters = queryParameters.set(
        'by_operator_id',
        <any>by_operator_id
      );
    }
    if (
      did_send_newsletter_push !== undefined &&
      did_send_newsletter_push !== null
    ) {
      queryParameters = queryParameters.set(
        'did_send_newsletter_push',
        <any>did_send_newsletter_push
      );
    }
    if (
      marked_for_translation !== undefined &&
      marked_for_translation !== null
    ) {
      queryParameters = queryParameters.set(
        'marked_for_translation',
        <any>marked_for_translation
      );
    }
    if (is_translated !== undefined && is_translated !== null) {
      queryParameters = queryParameters.set(
        'is_translated',
        <any>is_translated
      );
    }
    if (tag_id !== undefined && tag_id !== null) {
      queryParameters = queryParameters.set('tag_id', <any>tag_id);
    }
    if (is_special_offer !== undefined && is_special_offer !== null) {
      queryParameters = queryParameters.set(
        'is_special_offer',
        <any>is_special_offer
      );
    }
    if (upload_channel !== undefined && upload_channel !== null) {
      queryParameters = queryParameters.set(
        'upload_channel',
        <any>upload_channel
      );
    }
    if (article_title !== undefined && article_title !== null) {
      queryParameters = queryParameters.set(
        'article_title',
        <any>article_title
      );
    }
    if (article_author_id !== undefined && article_author_id !== null) {
      queryParameters = queryParameters.set(
        'article_author_id',
        <any>article_author_id
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<GalleryList>(
      'get',
      `${this.basePath}/cms/galleries`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get gallery by ID
   * @param id_or_seo Gallery ID or SEO URL
   * @param is_legacy_url Is Pixsell legacy URL
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetGallery(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Gallery>;
  public cmsGetGallery(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Gallery>>;
  public cmsGetGallery(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Gallery>>;
  public cmsGetGallery(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_or_seo === null || id_or_seo === undefined) {
      throw new Error(
        'Required parameter id_or_seo was null or undefined when calling cmsGetGallery.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (is_legacy_url !== undefined && is_legacy_url !== null) {
      queryParameters = queryParameters.set(
        'is_legacy_url',
        <any>is_legacy_url
      );
    }
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Gallery>(
      'get',
      `${this.basePath}/cms/galleries/${encodeURIComponent(String(id_or_seo))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Create a new record of type &#x27;Gallery&#x27;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPostGallery(
    body?: GalleryCrudRequestResponse,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GalleryCrudRequestResponse>;
  public cmsPostGallery(
    body?: GalleryCrudRequestResponse,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GalleryCrudRequestResponse>>;
  public cmsPostGallery(
    body?: GalleryCrudRequestResponse,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GalleryCrudRequestResponse>>;
  public cmsPostGallery(
    body?: GalleryCrudRequestResponse,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GalleryCrudRequestResponse>(
      'post',
      `${this.basePath}/cms/galleries`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update a record of type &#x27;Gallery&#x27;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsUpdateGallery(
    body?: GalleryCrudRequestResponse,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GalleryCrudRequestResponse>;
  public cmsUpdateGallery(
    body?: GalleryCrudRequestResponse,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GalleryCrudRequestResponse>>;
  public cmsUpdateGallery(
    body?: GalleryCrudRequestResponse,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GalleryCrudRequestResponse>>;
  public cmsUpdateGallery(
    body?: GalleryCrudRequestResponse,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GalleryCrudRequestResponse>(
      'put',
      `${this.basePath}/cms/galleries`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
