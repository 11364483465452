/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FinanceInvoiceStatusRequest {
  status?: FinanceInvoiceStatusRequest.StatusEnum;
}
export namespace FinanceInvoiceStatusRequest {
  export type StatusEnum =
    | 'new'
    | 'checked'
    | 'ready_for_sap'
    | 'transfered_to_sap'
    | 'not_for_sap';
  export const StatusEnum = {
    New: 'new' as StatusEnum,
    Checked: 'checked' as StatusEnum,
    ReadyForSap: 'ready_for_sap' as StatusEnum,
    TransferedToSap: 'transfered_to_sap' as StatusEnum,
    NotForSap: 'not_for_sap' as StatusEnum,
  };
}
