import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SubportalResponse } from '../../../../projects/piwe-front-swagger-client/src';
import { SubPortal } from '../search/search.service';
import { HomepageService as ApiHomepageService } from 'piwe-front-swagger-client';
import { InfiniteScrollService } from '../infinite-scroll.service';

@Injectable({
  providedIn: 'root',
})
export class SingleAgencySearchService {
  public searchResults$ = new Subject();

  constructor(
    private http: HttpClient,
    private homepageService: ApiHomepageService
  ) {}

  search(page: number, agency: string, query: string | undefined) {
    let method:
      | ((
          page?: number,
          page_size?: number,
          text?: string,
          observe?: 'body',
          reportProgress?: boolean
        ) => Observable<SubportalResponse>)
      | undefined;

    switch (agency) {
      case 'girl-of-the-day':
        method = (this.homepageService as any).getDjevojkaDana;
        break;
      case 'reuters':
        method = (this.homepageService as any).getHomepageReuters;
        break;
    }

    let searchQuery: string | undefined = undefined;
    if (query != undefined && query?.length != 0) {
      searchQuery = query;
    }

    return method!.apply(this.homepageService, [page, 50, searchQuery]);
  }
}
