<div class="search-container">
  <div class="search">
    <input
      type="text"
      placeholder="{{ placeholder }}"
      class="search-input"
      [(ngModel)]="searchQuery"
      (keydown.enter)="onEnter()"
    />
    <button class="search-btn" (click)="onEnter()"></button>
  </div>
</div>
