import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/app/shared/environment';

@Component({
  selector: 'app-digital-exhibition',
  templateUrl: './digital-exhibition.component.html',
  styleUrls: ['./digital-exhibition.component.scss'],
})
export class DigitalExhibitionComponent implements OnInit {
  public apiUrl = environment.productServingUrl;

  public _selectedPhoto: string = '';
  public currentPhotoIndex: number = 0;
  @Input() images: any = [];
  @Output() closeEvent: EventEmitter<void> = new EventEmitter();

  @ViewChild('overlay') overlay!: ElementRef;

  public outAnimation: boolean = false;
  public isMobile: boolean = false;

  //mobile only
  public isUiHidden = false;

  constructor() {}

  ngOnInit(): void {
    this.removeVideosFromExhibition();
    this.selectedPhoto = this.images[0].preview[0].preview_url;
    this.checkWidthForMobile();
  }

  removeVideosFromExhibition() {
    this.images = this.images.filter((img: any) => {
      return img.preview[0].preview_url.includes('/photo/');
    });
  }

  set selectedPhoto(url: string) {
    this._selectedPhoto = this.convertToLargeImgUrl(url);
  }
  get selectedPhoto() {
    return this._selectedPhoto;
  }

  convertToLargeImgUrl(url: string): string {
    return url.replace('photo/thumb/', 'photo/pnw/');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWidthForMobile();
  }

  onPhotoSwitch(n: 1 | -1, event?: MouseEvent) {
    if (event != null) {
      event.stopPropagation();
    }

    const imgCount = this.images.length;

    this.currentPhotoIndex += n;

    if (this.currentPhotoIndex < 0) {
      this.currentPhotoIndex = imgCount - 1;
    } else if (this.currentPhotoIndex > imgCount - 1) {
      this.currentPhotoIndex = 0;
    }

    this.selectedPhoto =
      this.images[this.currentPhotoIndex].preview[0].preview_url;
  }
  handlePhotoClick(index: number) {
    this.currentPhotoIndex = index;
    this.selectedPhoto = this.images[index].preview[0].preview_url;

    document.querySelector('.overlay')!.scrollTo(0, 0);
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (e.code == 'ArrowRight') this.onPhotoSwitch(1);
    else if (e.code == 'ArrowLeft') this.onPhotoSwitch(-1);
    else if (e.code == 'Escape') this.handleClose();
  }

  handleClose(event?: MouseEvent) {
    if (event != null) {
      event.stopPropagation();
    }
    this.outAnimation = true;

    setTimeout(() => {
      this.closeEvent.emit();
    }, 250);
  }

  checkWidthForMobile() {
    const width: number =
      document.querySelector('.main-app-container')?.clientWidth ?? 0;

    this.isMobile = false;
    if (width <= 1024) {
      this.isMobile = true;
    }
  }

  handleOverlayTap() {
    this.isUiHidden = !this.isUiHidden;
  }

  stopEventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
