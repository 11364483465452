import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportItem } from '../../cms/import/import.component';
import {
  ImageSize,
  MainPreviewComponent,
} from '../../cms/import/main-preview/main-preview.component';
import {
  Gallery,
  GalleryProductItem,
  MediaPreivew,
  ProductPreview,
} from '../../../../projects/piwe-front-swagger-client/src';
import { CartService } from 'src/app/cart.service';
import { Subscription } from 'rxjs';
import { FilterRememberingService } from '../../front/filter-remembering.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

export enum Variant {
  Empty = 1,
  Gallery,
  BasedOnKind,
  Portfolio,
}

interface SkeletonItem {
  id: number;
  height: number;
  width: number;
}

@Component({
  selector: 'app-fancy-grid',
  templateUrl: './fancy-grid.component.html',
  styleUrls: ['./fancy-grid.component.scss'],
})
export class FancyGridComponent implements OnInit, AfterViewInit {
  constructor(
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private cartService: CartService,
    private filterRemembering: FilterRememberingService,
    private translateService: TranslateService
  ) {}

  static DEFAULT_ASPECT_RATIO = 1.5015015015015014;
  @Input() inInSearch = true;
  @Input() clearRememberedSearchIndex = false;
  @Input() imageData: any[] = [];
  @Input() itemHeight = 500;
  @Input() imagesPerRow = 3;
  @Input() allowImagePerRowRecalculation = false;
  @Input() imageRecalculationCap: number | undefined = undefined;
  @Output() imagePerRowEmitter: EventEmitter<number> = new EventEmitter();
  @Input() showDataOnlyOnHover = false;
  @Input() variant: Variant = Variant.Empty;
  @Output() cartButtonPressedEmitter: EventEmitter<number> = new EventEmitter();

  @Input() seoUrlPrefix: string | undefined = undefined;

  /** Should search more block be inserted or not */
  @Input() hasSearchMoreBlock: boolean = false;
  /** Number of row after witch the search more block is inserted */
  @Input() searchMoreBlockIndex: number = 7;
  public searchMoreBlockInsertLocation: number = 0;
  private searchMoreBlockTimeout!: NodeJS.Timeout;
  /** Is the grid inside of search more banner?
   * @description Used to limit the grid to 2 rows
   */
  @Input() isSearchMoreGrid: boolean = false;
  public searchMoreItems: any[] = [];
  public searchMoreBlockInsertAtTheEnd: boolean = false;

  alreadyInCartTimeout!: NodeJS.Timeout;
  isAlreadyInCartPopupVisible = false;
  private cartUpdateSubscription: Subscription = new Subscription();

  atLeastOnePreviewLoaded = false;

  imageSize: ImageSize = ImageSize.Normal;

  items: any[] = [];
  skeletonItems: SkeletonItem[] = [];

  bigTagMapping: { [key: string]: string } = {
    Ekskluzivno: 'big-tag-exclusive.svg',
    'Posebna ponuda': 'big-tag-special-offer.svg',
    Vremeplov: 'big-tag-timemachine.svg',
    'Novi sadržaj': 'big-tag-new-in-gallery.svg',
  };

  public currentLanguage = 'hr';
  private currentLanguageSubscription = new Subscription();

  @Output() onImageClick: EventEmitter<number> = new EventEmitter();

  @Output() atLeastOneLoaded: EventEmitter<void> = new EventEmitter();

  @ViewChild('container') container?: ElementRef;

  @Input() indexOfCurrentlySelected = 0;
  @Output() selectionChangedEvent: EventEmitter<number> = new EventEmitter();
  itemCache: Map<number, any> = new Map();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.recalcLayout();
  }

  ngOnInit(): void {
    // console.log('INIT');
    this.itemsFactory();

    // check if exists in cart and keep checking after cart updates
    // needed for already in cart in the overlay
    this.checkIfProductsExistInCart();
    this.cartUpdateSubscription = this.cartService.cartUpdateSubject.subscribe(
      (response) => {
        this.checkIfProductsExistInCart();
      }
    );
    this.getNumberOfImagesPerRow();

    //translation
    this.currentLanguage = this.translateService.currentLang;
    this.currentLanguageSubscription =
      this.translateService.onLangChange.subscribe(
        (params: LangChangeEvent) => {
          this.currentLanguage = params.lang;
        }
      );
  }

  getNumberOfImagesPerRow() {
    if (this.allowImagePerRowRecalculation) {
      const width: number =
        document.querySelector('.main-app-container')?.clientWidth ?? 0;

      if (width <= 1024) {
        this.imagesPerRow = 1;
      } else if (width > 1024 && width <= 1200) {
        this.imagesPerRow = 2;
      } else if (width > 1200 && width <= 1500) {
        this.imagesPerRow = 3;
      } else {
        this.imagesPerRow = 4;
      }
      if (this.imageRecalculationCap !== undefined) {
        if (this.imagesPerRow > this.imageRecalculationCap) {
          this.imagesPerRow = this.imageRecalculationCap;
        }
      }
      this.imagePerRowEmitter.emit(this.imagesPerRow);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageData && changes.imageData.currentValue) {
      // console.log('CHANGE');
      this.itemsFactory();
      this.recalcLayout();
    }
  }

  ngAfterViewInit() {
    this.recalcLayout();
  }

  ngOnDestroy() {
    this.cartUpdateSubscription.unsubscribe();
    this.currentLanguageSubscription.unsubscribe();
  }

  itemsFactory() {
    this.items = this.imageData?.map((element, index) => {
      if (this.itemCache.has(element.id)) {
        return this.itemCache.get(element.id);
      }

      const mediaId = element.media_id;

      let timestamp = '';
      if (element.timestamp) {
        timestamp = element.timestamp.split('T').join(' ');
      } else if (element.created_at) {
        timestamp = element.created_at.split('T').join(' ');
      } else if (element.create_date) {
        timestamp = element.create_date.split('T').join(' ');
      }

      let aspectRatio: number = FancyGridComponent.DEFAULT_ASPECT_RATIO;
      let isNewContent = false;
      let isSpecialOffer = false;
      let itemCount: number | undefined = undefined;
      let kind: ProductPreview.KindEnum | undefined = undefined;
      let preview: MediaPreivew[] | undefined = undefined;
      let seoUrl: string | undefined = undefined;
      let tag: string | undefined = undefined;
      let title: string | undefined = undefined;
      let titleEn: string | undefined = undefined;

      if ((element as Gallery).product_list) {
        const elementCasted = element as Gallery;

        if (elementCasted.product_list?.length == 0) {
          preview = [];
        } else {
          preview = [
            {
              preview_url_blur:
                elementCasted.product_list![0].preview?.preview_url_blur,
              preview_url: elementCasted.product_list![0].preview?.preview_url,
              preview_url_large:
                elementCasted.product_list![0].preview?.preview_url_large,
              preview_video_loop:
                elementCasted.product_list![0].preview?.preview_video_loop,
              preview_video_720p:
                elementCasted.product_list![0].preview?.preview_video_720p,
            },
          ];
          aspectRatio =
            elementCasted?.product_list![0]?.preview?.aspect_ratio ??
            FancyGridComponent.DEFAULT_ASPECT_RATIO;
        }

        seoUrl = elementCasted.seo_url;
        kind = ProductPreview.KindEnum.GALLERY;
        title = elementCasted.headline?.find(
          (h) => h.language === 'HR'
        )?.content;
        titleEn = elementCasted.headline?.find(
          (h) => h.language === 'EN'
        )?.content;

        itemCount = elementCasted.product_list?.length ?? 0;

        if (elementCasted.tag_list?.length !== 0) {
          // Find is it has a tag for new content.
          for (const tagListTag of elementCasted.tag_list!) {
            const tagName = tagListTag.name.find((t) => t.language === 'HR')!
              .content!;
            if (tagName.toLowerCase() === 'novi sadržaj') {
              isNewContent = true;
            }
          }

          // Search for the first tag that's not "New".
          for (const tagListTag of elementCasted.tag_list!) {
            const tagName = tagListTag.name.find((t) => t.language === 'HR')!
              .content!;

            if (tagName.toLowerCase() === 'novi sadržaj') {
              continue;
            }

            tag = this.bigTagMapping[tagName];
            isSpecialOffer = tagName.toLowerCase() == 'posebna ponuda';
            break;
          }
        }
      } else if ((element as GalleryProductItem).preview?.preview_url) {
        const elementCasted = element as GalleryProductItem;

        preview = [
          {
            preview_url_blur: elementCasted.preview?.preview_url_blur,
            preview_url: elementCasted.preview?.preview_url,
            preview_url_large: elementCasted.preview?.preview_url_large,
            preview_video_loop: elementCasted.preview?.preview_video_loop,
            preview_video_720p: elementCasted.preview?.preview_video_720p,
          },
        ];
        aspectRatio =
          elementCasted?.preview?.aspect_ratio ??
          FancyGridComponent.DEFAULT_ASPECT_RATIO;
        kind = ProductPreview.KindEnum.SINGLEPRODUCT;

        let videoTitle = elementCasted.video_title?.find(
          (t) => t.language === 'HR'
        )?.content;
        if (
          videoTitle !== undefined &&
          videoTitle !== null &&
          videoTitle.length !== 0
        ) {
          title = videoTitle;
          titleEn =
            elementCasted.video_title?.find((t) => t.language === 'EN')
              ?.content ??
            videoTitle ??
            '';
        } else {
          title = elementCasted.title?.find(
            (t) => t.language === 'HR'
          )?.content;
          titleEn =
            elementCasted.title?.find((t) => t.language === 'EN')?.content ??
            elementCasted.title?.find((t) => t.language === 'HR')?.content ??
            '';
        }

        itemCount = 0;
        seoUrl = elementCasted.seo_url;
      } else if ((element as ProductPreview).preview) {
        const elementCasted = element as ProductPreview;

        if (elementCasted.kind != 'GALLERY_GROUP') {
          if (elementCasted.preview?.length !== 0) {
            preview = [elementCasted.preview![0]];
            aspectRatio =
              elementCasted.preview![0].aspect_ratio ??
              FancyGridComponent.DEFAULT_ASPECT_RATIO;
          } else {
            preview = [
              {
                aspect_ratio: 1.5015015015015014,
                preview_url: '/assets/soon.svg',
                preview_url_large: '/assets/soon.svg',
                // @ts-ignore
                preview_video_loop: null,
              },
            ];
            aspectRatio = 1.5015015015015014;
          }
        } else {
          preview = elementCasted.preview?.slice(0, 3);
        }

        kind = elementCasted.kind;

        let videoTitle = elementCasted.video_title?.find(
          (t) => t.language === 'HR'
        )?.content;
        if (
          videoTitle !== undefined &&
          videoTitle !== null &&
          videoTitle.length !== 0
        ) {
          title = videoTitle;
          titleEn =
            elementCasted.video_title?.find((t) => t.language === 'EN')
              ?.content ??
            videoTitle ??
            '';
        } else {
          title = elementCasted.title?.find(
            (t) => t.language === 'HR'
          )?.content;
          titleEn =
            elementCasted.title?.find((t) => t.language === 'EN')?.content ??
            elementCasted.title?.find((t) => t.language === 'HR')?.content ??
            '';
        }

        itemCount = elementCasted.count;
        seoUrl = elementCasted.seo_url;

        if (elementCasted.tags?.length !== 0) {
          // Find is it has a tag for new content.
          for (const tagListTag of elementCasted.tags!) {
            const tagName = tagListTag.name.find((t) => t.language === 'HR')!
              .content!;
            if (tagName.toLowerCase() === 'novi sadržaj') {
              isNewContent = true;
            }
          }

          // Search for the first tag that's not "New".
          for (const tagListTag of elementCasted.tags!) {
            const tagName = tagListTag.name.find((t) => t.language === 'HR')!
              .content!;

            if (tagName.toLowerCase() === 'novi sadržaj') {
              continue;
            }

            tag = this.bigTagMapping[tagName];
            isSpecialOffer = tagName.toLowerCase() == 'posebna ponuda';
            break;
          }
        }
      } else if ((element as ImportItem).previewUrl) {
        const elementCasted = element as ImportItem;
        preview = [
          {
            preview_url_blur: elementCasted.blurPreviewUrl,
            preview_url: elementCasted.previewUrl,
            preview_url_large: elementCasted.largePreviewUrl,
            preview_video_loop: elementCasted.previewVideoLoop,
            preview_video_720p: elementCasted.previewVideo720d,
          },
        ];
        aspectRatio =
          elementCasted?.aspectRatio ?? FancyGridComponent.DEFAULT_ASPECT_RATIO;
        kind = ProductPreview.KindEnum.SINGLEPRODUCT;
        title = elementCasted.title;
        itemCount = elementCasted.imageCount ?? 0;

        if (elementCasted.tag != undefined) {
          const tagName = elementCasted!.tag!.title;
          tag = this.bigTagMapping[tagName];
          isSpecialOffer = tagName.toLowerCase() == 'posebna ponuda';
        }
      }

      if (element.total_count) {
        itemCount = element.total_count;
      }

      const result = {
        index,
        id: element.id,
        mediaId,
        preview,
        height: 0,
        width: 0,
        aspectRatio,
        isVideo: element.preview_video_720p != null,
        isLocked: false,
        title: title,
        titleEn: titleEn,
        imageCount: itemCount! - element.video_count,
        videoCount: element.video_count,
        galleryId: element.gallery_id,
        isLoaded: false,
        isPreviewLoaded: false,
        kind,
        timestamp,
        video_duration: element.video_duration,
        tag,
        has_article: element.has_article,
        seo_url: seoUrl,
        isSpecialOffer,
        isNewContent,
      };

      this.itemCache.set(element.id, result);

      return result;
    });
  }

  updateOriginalAspectRatio(
    itemIndex: number,
    aspectRatio: number | undefined
  ) {
    if (this.imageData == undefined || this.imageData.length <= itemIndex) {
      //console.log('MISSED2');
      return;
    }

    const element: any = this.imageData[itemIndex];

    if ((element as Gallery).product_list) {
      const elementCasted = element as Gallery;

      elementCasted.product_list![0].preview!.aspect_ratio = aspectRatio;
    } else if ((element as GalleryProductItem).preview?.preview_url) {
      const elementCasted = element as GalleryProductItem;

      elementCasted.preview!.aspect_ratio = aspectRatio;
    } else if ((element as ProductPreview).preview) {
      const elementCasted = element as ProductPreview;

      if (elementCasted.preview?.length != 0)
        elementCasted.preview![0].aspect_ratio = aspectRatio;
    } else if ((element as ImportItem).previewUrl) {
      const elementCasted = element as ImportItem;

      elementCasted.aspectRatio = aspectRatio;
    } else {
      //console.log('MISSED');
    }
  }

  generateLink(item: any) {
    switch (this.variant) {
      case Variant.Empty:
        if (item.seo_url != undefined) {
          return item.seo_url;
        } else if (this.seoUrlPrefix != undefined) {
          return `${this.seoUrlPrefix}/${item.id}`;
        } else {
          return `/p/${item.id}`;
        }
        break;
      case Variant.Gallery:
        if (item.seo_url != undefined) {
          return item.seo_url;
        } else {
          return `/ga/${item.galleryId}`;
        }
        break;

      case Variant.BasedOnKind:
        const kind: ProductPreview.KindEnum = item.kind;
        switch (kind) {
          case 'GALLERY':
            if (item.seo_url != undefined) {
              return item.seo_url;
            } else {
              return `/ga/${item.id}`;
            }
            break;
          case 'GALLERY_GROUP':
            if (item.seo_url != undefined) {
              return item.seo_url;
            } else {
              return `/g/${item.id}`;
            }
            break;
          case 'SINGLE_PRODUCT':
            if (item.seo_url != undefined) {
              return item.seo_url;
            } else if (this.seoUrlPrefix != undefined) {
              return `${this.seoUrlPrefix}/${item.id}`;
            } else {
              return `/p/${item.id}`;
            }
            break;
        }

        break;

      default:
        return '#';
    }
  }

  itemClick(item: any, $event: MouseEvent) {
    if ($event.ctrlKey || $event.shiftKey || $event.metaKey || $event.metaKey) {
      return;
    }

    $event.preventDefault();

    if (this.inInSearch != false && this.filterRemembering.isRemembering) {
      this.filterRemembering.pushCurrentUrlToStack(item.index);
    }

    if (this.clearRememberedSearchIndex) {
      this.filterRemembering.searchCurrentIndex = undefined;
    }

    switch (this.variant) {
      case Variant.Portfolio:
        this.onImageClick.emit(item.id);
        break;

      default:
        const link = this.generateLink(item);
        this.router.navigateByUrl(link);
    }
  }

  itemOver(item: any, $event: MouseEvent) {
    let element = $event.target as HTMLElement;
    element = element.parentElement!;
    const videoElements = element.querySelector(
      'video'
    ) as HTMLVideoElement | null;
    videoElements?.play();
  }

  itemOut(item: any, $event: MouseEvent) {
    let element = $event.target as HTMLElement;
    element = element.parentElement!;
    const videoElements = element.querySelector(
      'video'
    ) as HTMLVideoElement | null;
    videoElements?.pause();
  }

  getItemId(index: number, item: any) {
    return item.id;
  }

  onImageLoaded(event: { item: ImportItem; aspectRatio: number }) {
    // This will be trigger on image loaded event. We should only activate
    // recalc layout if the aspect ratio is different then the stored one.
    const newAspectRatio = event.aspectRatio;

    const index = this.items.map((i) => i.id).indexOf(event.item.id);

    if (event.item.aspectRatio != newAspectRatio) {
      this.items[index].aspectRatio = event.aspectRatio;
      this.updateOriginalAspectRatio(index, event.aspectRatio);
      this.recalcLayout();
    }

    this.items[index].isLoaded = true;

    if (!this.atLeastOnePreviewLoaded) {
      this.atLeastOneLoaded.emit();
    }
    this.atLeastOnePreviewLoaded = true;
  }

  onPreviewImageLoaded(event: { item: ImportItem; aspectRatio: number }) {
    // This will be trigger on image loaded event. We should only activate
    // recalc layout if the aspect ratio is different then the stored one.
    const newAspectRatio = event.aspectRatio;

    const index = this.items.map((i) => i.id).indexOf(event.item.id);

    if (event.item.aspectRatio != newAspectRatio) {
      this.items[index].aspectRatio = event.aspectRatio;
      this.updateOriginalAspectRatio(index, event.aspectRatio);
      this.recalcLayout();
    }

    this.items[index].isPreviewLoaded = true;

    if (!this.atLeastOnePreviewLoaded) {
      this.atLeastOneLoaded.emit();
    }
    this.atLeastOnePreviewLoaded = true;
  }

  calculateRowAspectRatio(row: ImportItem[]): number {
    if (row.length == 0) {
      return MainPreviewComponent.DEFAULT_ASPECT_RATIO;
    }

    if (row.length == 1) {
      return (
        1.0 / row[0].aspectRatio! ?? MainPreviewComponent.DEFAULT_ASPECT_RATIO
      );
    }

    return (
      row
        .map((rowItem) => {
          const isRowItemVertical =
            rowItem.aspectRatio != undefined && rowItem.aspectRatio < 1.0;

          let width: number;
          if (isRowItemVertical && this.imagesPerRow != 1) {
            width = 0.5;
          } else {
            width = 1.0;
          }

          if (rowItem.aspectRatio) {
            return (1.0 / rowItem.aspectRatio) * width;
          } else {
            return MainPreviewComponent.DEFAULT_ASPECT_RATIO;
          }
        })
        .reduce((a, b) => a + b) / row.length
    );
  }

  recalcLayout() {
    if (this.container == undefined) {
      return;
    }

    //console.log('recalcLayout');

    this.itemHeight =
      (this.container!.nativeElement.offsetWidth - 10.0) / this.imagesPerRow;

    let rowI = 0;
    let columnI = 0;
    let row: ImportItem[] = [];

    forLoop: for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      let nextItem: ImportItem | undefined;

      if (i < this.items.length - 1) {
        nextItem = this.items[i + 1];
      }

      const isVertical =
        item.aspectRatio != undefined && item.aspectRatio < 1.0;

      let width: number;
      if (isVertical && this.imagesPerRow != 1) {
        width = 0.5;
      } else {
        width = 1.0;
      }

      columnI += width;

      item.width = width * (100.0 / this.imagesPerRow);

      row.push(item);

      // Start a new row
      if (columnI >= this.imagesPerRow) {
        /** This is used for "search more block" insertion.
         * @description if the code goes into the first if branch, there is an extra item that gets inserted into the next row
         */
        let hasExtraItem: boolean = false;

        // Rows has a half image.
        if (columnI == this.imagesPerRow + 0.5 && width == 1.0) {
          row.pop();

          const averageAR = this.calculateRowAspectRatio(row);

          row.forEach((rowItem) => {
            const isRowItemVertical =
              rowItem.aspectRatio != undefined && rowItem.aspectRatio < 1.0;

            let width: number;
            if (isRowItemVertical && this.imagesPerRow != 1) {
              width = 0.5;
            } else {
              width = 1.0;
            }

            rowItem.width = width * (100.0 / (this.imagesPerRow - 0.5));
            rowItem.height = averageAR;
          });

          columnI = width;
          row = [item];

          //has extra
          hasExtraItem = true;
        } else {
          const averageAR = this.calculateRowAspectRatio(row);

          row.forEach((rowItem) => {
            rowItem.height = averageAR;
          });

          columnI = 0.0;
          row = [];
        }

        rowI += 1.0;

        /** Search more ------------------------↓ */
        //if the grid is inside search more grid, cancel recalc after second row or if its the last item
        if (this.isSearchMoreGrid && rowI == 2) {
          if (hasExtraItem && rowI == 2) {
            row.pop();
          }
          //when recalc is canceled it doesn't remove items, it just leaves them at 0 height, so this slice addresses that
          this.searchMoreItems = this.items.slice(
            0,
            i + (hasExtraItem ? 0 : 1)
          );
          break forLoop;
        }

        //used for SearchMoreBlock
        if (this.hasSearchMoreBlock && rowI == this.searchMoreBlockIndex) {
          //if correct index or last item
          this.searchMoreBlockInsertAtTheEnd = false;
          clearTimeout(this.searchMoreBlockTimeout);
          this.searchMoreBlockTimeout = setTimeout(() => {
            this.searchMoreBlockInsertLocation = i + (hasExtraItem ? 0 : 1);
          }, 40);
        }
        /** Search more ------------------------↑ */
      }

      if (i == this.items.length - 1) {
        const averageAR = this.calculateRowAspectRatio(row);

        row.forEach((rowItem) => {
          rowItem.height = averageAR;
        });

        if (
          this.hasSearchMoreBlock &&
          this.searchMoreBlockInsertLocation == 0
        ) {
          this.searchMoreBlockTimeout = setTimeout(() => {
            this.searchMoreBlockInsertAtTheEnd = true;
            this.searchMoreBlockInsertLocation = -1;
          }, 40);
        } else if (this.isSearchMoreGrid) {
          if (this.isRowFull(row)) {
            this.searchMoreItems = this.items;
          } else {
            //if there isn't enough images for a second row, delete the row
            this.searchMoreItems = this.items.slice(
              0,
              this.items.length - row.length
            );
          }
        }
      }
    }
    this.getNumberOfImagesPerRow();
  }

  isRowFull(row: ImportItem[]): boolean {
    const size = row
      .map((rowItem) => {
        const isRowItemVertical =
          rowItem.aspectRatio != undefined && rowItem.aspectRatio < 1.0;

        let width: number;
        if (isRowItemVertical && this.imagesPerRow != 1) {
          width = 0.5;
        } else {
          width = 1.0;
        }

        return width;
      })
      .reduce((size, sum) => size + sum);

    return size >= this.imagesPerRow;
  }

  addProductToCart(event: MouseEvent, id: number, isInCart: boolean) {
    event.stopPropagation();
    if (!isInCart) {
      this.cartButtonPressedEmitter.emit(id);
    } else {
      this.isAlreadyInCartPopupVisible = true;
      // console.log('timeout', this.isAlreadyInCartPopupVisible);

      this.alreadyInCartTimeout = setTimeout(() => {
        this.isAlreadyInCartPopupVisible = false;
      }, 3000);
    }
  }

  checkIfProductsExistInCart() {
    // gonna break for videos most likely
    this.items?.map((item, index) => {
      const productVariation = this.cartService.checkIfProductExistsInCart(
        item.id
      );
      if (
        productVariation.print === true ||
        productVariation.web === true ||
        productVariation.video === true
      ) {
        this.items[index].isInCart = true;
      } else {
        this.items[index].isInCart = false;
      }
    });
  }

  resetAlreadyInCartTimeout() {
    clearTimeout(this.alreadyInCartTimeout);
    this.isAlreadyInCartPopupVisible = false;
  }

  ngAfterViewChecked(): void {
    // this.recalcLayout();
  }
}
