import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NewsletterPopupService } from '../newsletter-popup.service';
import { Subject } from 'rxjs';
import { ProfileService } from '../profile.service';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-newsletter-popup',
  templateUrl: './newsletter-popup.component.html',
  styleUrls: ['./newsletter-popup.component.scss'],
})
export class NewsletterPopupComponent implements OnInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();
  popUpShown = false;
  emailValue = '';

  @ViewChild('form') form: any;

  constructor(
    private newsletterPopupService: NewsletterPopupService,
    private profileService: ProfileService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.profileService.getProfile();
      this.profileService.profileData.subscribe((profile) => {
        this.emailValue = profile?.email ?? '';
      });
    }

    this.newsletterPopupService.shouldShowNewsletterPopupData.subscribe(
      (value) => {
        if (this.authService.isAuthenticated()) {
          this.profileService.getProfile();
          this.profileService.profileData.subscribe((profile) => {
            this.emailValue = profile?.email ?? '';
          });
        }

        this.popUpShown = true;

        this.newsletterPopupService
          .saveStatus('PRESENTED')
          .subscribe((_) => {});
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close($event: Event) {
    this.popUpShown = false;

    this.newsletterPopupService
      .saveStatus('PRESENTED_AND_CLOSED')
      .subscribe((_) => {});
  }

  submit($event: Event) {
    $event.preventDefault();

    this.newsletterPopupService
      .saveStatus('SENT_WITH_SUCCESS')
      .subscribe((value) => {
        this.popUpShown = false;
        this.form.nativeElement.submit();
      });
  }
}
