/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RelatedProductSchema {
  photo_count?: number;
  preview?: string;
  id?: number;
  product_kind?: string;
  title?: string;
  video_count?: number;
  video_duration?: number;
}
