/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FinanceInvoiceProductItem } from './financeInvoiceProductItem';

export interface FinanceInvoice {
  id?: number;
  type?: FinanceInvoice.TypeEnum;
  number?: number;
  language?: string;
  company?: string;
  client?: string;
  create_date?: string;
  created_by?: string;
  payment_method?: FinanceInvoice.PaymentMethodEnum;
  approved_by?: string;
  currency?: FinanceInvoice.CurrencyEnum;
  net_price_without_discount?: number;
  discount?: number;
  net_price?: number;
  tax?: number;
  gross?: number;
  sap_allowed?: boolean;
  fiscalization?: FinanceInvoice.FiscalizationEnum;
  transferred_to_sap?: string;
  status?: FinanceInvoice.StatusEnum;
  due_date?: string;
  invoice_date?: string;
  comment?: string;
  additional_text?: string;
  canceled_by_invoice_number?: string;
  product_items?: Array<FinanceInvoiceProductItem>;
}
export namespace FinanceInvoice {
  export type TypeEnum =
    | 'invoice'
    | 'pro_forma_invoice'
    | 'storno'
    | 'storno_predujma'
    | 'odobrenje'
    | 'terećenje';
  export const TypeEnum = {
    Invoice: 'invoice' as TypeEnum,
    ProFormaInvoice: 'pro_forma_invoice' as TypeEnum,
    Storno: 'storno' as TypeEnum,
    StornoPredujma: 'storno_predujma' as TypeEnum,
    Odobrenje: 'odobrenje' as TypeEnum,
    Tereenje: 'terećenje' as TypeEnum,
  };
  export type PaymentMethodEnum = 'CARD' | 'KEKS_PAY' | 'PAYPAL' | 'DOWNLOAD';
  export const PaymentMethodEnum = {
    CARD: 'CARD' as PaymentMethodEnum,
    KEKSPAY: 'KEKS_PAY' as PaymentMethodEnum,
    PAYPAL: 'PAYPAL' as PaymentMethodEnum,
    DOWNLOAD: 'DOWNLOAD' as PaymentMethodEnum,
  };
  export type CurrencyEnum = 'HRK' | 'EUR';
  export const CurrencyEnum = {
    HRK: 'HRK' as CurrencyEnum,
    EUR: 'EUR' as CurrencyEnum,
  };
  export type FiscalizationEnum =
    | 'is_fiscalised'
    | 'not_fiscalised'
    | 'doesnt_need_fiscalization';
  export const FiscalizationEnum = {
    IsFiscalised: 'is_fiscalised' as FiscalizationEnum,
    NotFiscalised: 'not_fiscalised' as FiscalizationEnum,
    DoesntNeedFiscalization: 'doesnt_need_fiscalization' as FiscalizationEnum,
  };
  export type StatusEnum =
    | 'new'
    | 'checked'
    | 'ready_for_sap'
    | 'transfered_to_sap'
    | 'not_for_sap';
  export const StatusEnum = {
    New: 'new' as StatusEnum,
    Checked: 'checked' as StatusEnum,
    ReadyForSap: 'ready_for_sap' as StatusEnum,
    TransferedToSap: 'transfered_to_sap' as StatusEnum,
    NotForSap: 'not_for_sap' as StatusEnum,
  };
}
