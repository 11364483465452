import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Action,
  AutocompleteOption,
  FieldTextComponent,
} from '../field-text/field-text.component';
import { ModelError } from '../../../../projects/piwe-front-swagger-client/src';

@Component({
  selector: 'app-field-tags',
  templateUrl: './field-tags.component.html',
  styleUrls: ['./field-tags.component.scss'],
})
export class FieldTagsComponent
  extends FieldTextComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() title: string = '';
  @Input() theme: string = 'default';

  /**
   * Placeholder value.
   */
  @Input() placeholder?: string = '';
  originalPlaceholder?: string;

  rawValue: string | null = null;

  @Input() tags?: AutocompleteOption[] = [];

  hasTags: boolean = false;

  @Output() tagsChange: EventEmitter<AutocompleteOption[] | undefined> =
    new EventEmitter<AutocompleteOption[] | undefined>();

  @Output() actionSelected: EventEmitter<{
    field: string;
    action: Action;
  }> = new EventEmitter<{
    field: string;
    action: Action;
  }>();

  @Output() removedTag: EventEmitter<AutocompleteOption> = new EventEmitter();

  ngOnInit(): void {
    super.ngOnInit();

    if (this.theme == 'filter') {
      this.tagsChange.subscribe(() => {
        this.updatePlaceholder();
      });
    }

    this.updatePlaceholder();
  }

  updatePlaceholder() {
    if (this.originalPlaceholder === undefined) {
      this.originalPlaceholder = this.placeholder;
    }
    this.hasTags = (this.tags?.length ?? 0) != 0;

    if (!this.hasTags) {
      this.placeholder = this.originalPlaceholder;
    } else {
      this.placeholder = this.tags?.map((t) => t.title).join(', ') ?? '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.autocompleteType && this.autocompleteValue) {
      this.value = '';
    } else if (this.autocompleteType) {
      this.value = '';
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onAutocompleteClick(autocompleteOption: AutocompleteOption) {
    if (!autocompleteOption.isSelectable) return;

    if (this.tags) {
      this.tags.push({
        title: autocompleteOption.title,
        value: autocompleteOption.value,
        isSelectable: true,
      });
      this.tagsChange.emit(this.tags);
    }
    this.inputEl?.nativeElement.blur();

    if (this.theme != 'filter') {
      this.value = '';
    }
  }

  removeTag(tag: AutocompleteOption) {
    if (this.tags) {
      this.tags = this.tags.filter((item) => item !== tag);
      this.tagsChange.emit(this.tags);
      this.removedTag.emit(tag);
    }
  }
}
