import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  public openCookieSettingsSubject = new Subject<void>();

  constructor() {}

  openCookieSettings() {
    this.openCookieSettingsSubject.next();
  }
}
