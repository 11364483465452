import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InputType } from 'src/app/shared/cms/transforming-input/transforming-input.component';
import { AutocompleteOption } from '../../../../shared/field-text/field-text.component';

@Component({
  selector: 'app-agency-tag-input',
  templateUrl: './agency-tag-input.component.html',
  styleUrls: ['./agency-tag-input.component.scss'],
})
export class AgencyTagInputComponent implements OnInit {
  @Input() controlName!: string;
  @Input() form!: FormGroup;
  @Input() label: string = '';

  @Input() tags: Set<string> = new Set();
  @Output() tagsChange: EventEmitter<Set<string>> = new EventEmitter();
  @Output() tagsChanged: EventEmitter<void> = new EventEmitter();

  @ViewChild('tagInput') tagInput!: ElementRef;

  @Output() removedTag: EventEmitter<AutocompleteOption> = new EventEmitter();

  @Input() isAutocomplete: boolean = false;
  @Input() autocompleteType: string = '';

  public InputType = InputType;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form.addControl(this.controlName, this.formBuilder.control(''));
  }

  handleEnter() {
    const tag = this.form.get(this.controlName)?.value;
    if (this.tags.has(tag)) {
      this.tagInput.nativeElement.setCustomValidity('This tag already exists.');
      this.tagInput.nativeElement.reportValidity();
      this.tagInput.nativeElement.setCustomValidity('');
      return;
    }

    if (this.tagInput.nativeElement.checkValidity() && tag !== '') {
      this.tags.add(tag);
      this.tagsChanged.emit();
      this.form.get(this.controlName)?.setValue('');
      return;
    }

    this.tagInput.nativeElement.reportValidity();
  }
  removeTag(tag: string) {
    this.tags.delete(tag);
    this.tagsChanged.emit();
  }

  autocompleteInputValueChange(autocompleteObj: any) {
    if (autocompleteObj.value != -1) {
      this.form.get(this.controlName)?.setValue(autocompleteObj.title);
      this.handleEnter();
    }
  }
}
