<div class="table-container">
  <table>
    <tr>
      <th *ngIf="isSelectable"></th>
      <th *ngFor="let header of headerNames">{{ header }}</th>
    </tr>

    <tr
      *ngFor="let row of rowData; let rowIndex = index"
      [routerLink]="hasLink ? [row.id] : []"
    >
      <td *ngIf="isSelectable">
        <!-- double buttons to make it easier to hit -->
        <button
          class="selection-btn-wrap"
          (click)="handleSelection($event, rowIndex)"
        >
          <button
            class="selection-btn"
            [class.selection-btn--selected]="row.isSelected"
          ></button>
        </button>
      </td>
      <td *ngFor="let item of row.data">
        <ng-container [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="'string'">
            <ng-container
              *ngTemplateOutlet="string; context: { value: item.value }"
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'money'">
            <ng-container
              *ngTemplateOutlet="money; context: { value: item.value }"
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'boolean'">
            <ng-container
              *ngTemplateOutlet="boolean; context: { value: item.value }"
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            <ng-container
              *ngTemplateOutlet="date; context: { value: item.value }"
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'pdfButton'">
            <ng-container
              *ngTemplateOutlet="
                pdfButton;
                context: { id: row.id, value: item.value, item: item.context }
              "
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'cancelInvoiceButton'">
            <ng-container
              *ngTemplateOutlet="
                cancelInvoiceButton;
                context: {
                  id: row.id,
                  value: item.value,
                  context: item.context
                }
              "
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'fiscalizeInvoiceButton'">
            <ng-container
              *ngTemplateOutlet="
                fiscalizeInvoiceButton;
                context: {
                  id: row.id,
                  value: item.value,
                  context: item.context
                }
              "
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'button'">
            <ng-container
              *ngTemplateOutlet="
                button;
                context: {
                  id: row.id,
                  value: item.value,
                  context: item.context
                }
              "
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'deleteInvoiceButton'">
            <ng-container
              *ngTemplateOutlet="
                deleteInvoiceButton;
                context: {
                  id: row.id,
                  value: item.value,
                  context: item.context
                }
              "
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'button2'">
            <ng-container
              *ngTemplateOutlet="
                button2;
                context: {
                  id: row.id,
                  value: item.value,
                  context: item.context
                }
              "
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'image'">
            <ng-container
              *ngTemplateOutlet="image; context: { value: item.value }"
            >
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'sapDropdown'">
            <ng-container
              *ngTemplateOutlet="
                sapDropdown;
                context: {
                  id: row.id,
                  value: item.value,
                  selectOptions: item.selectOptions,
                  context: item.context
                }
              "
            >
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </table>
  <div class="table-footer" [class.footer-empty]="!rowData.length">
    <div class="footer-group">
      <button (click)="handleSelectItem()" *ngIf="isSelectable">
        {{ isAllSelected ? "Deselect all" : "Select all" }}
      </button>
      <button *ngIf="hasDelete" (click)="handleDelete()">Delete</button>
      <button *ngIf="hasExportToXLS" (click)="handleExportToXLS()">
        Export to XLS
      </button>
      <button *ngIf="hasExportToCSV" (click)="handleExportToCSV()">
        Export to CSV
      </button>
      <button *ngIf="hasPrintPDF" (click)="handlePrintPDF()">Print PDF</button>
      <button *ngIf="hasCancelInvoice" (click)="handleCancelInvoice()">
        Cancel invoice
      </button>
      <button
        *ngIf="hasGenerateSAPJournalEntry"
        (click)="handleGenerateSAPJournalEntry()"
      >
        Generate SAP Journal Entry
      </button>
      <button *ngIf="hasReadyForSap" (click)="readyForSapButton()">
        Move all to ready for SAP
      </button>
      <button
        *ngIf="hasCustomFooterButton"
        (click)="handleCustomFooterButton()"
      >
        {{ customFooterButtonText }}
      </button>
    </div>
    <div class="footer-group">
      <div
        class="footer-selection-number"
        *ngIf="numberOfSelectedElements !== 0"
      >
        {{ numberOfSelectedElements }} selected
      </div>
      <div class="footer-items">{{ rowData.length || 0 }} items</div>
    </div>
  </div>
</div>

<!-- templates -->
<ng-template #string let-value="value">
  {{ value }}
</ng-template>

<ng-template #money let-value="value">
  {{ value | number : "1.2-2" : "hr-HR" }}
</ng-template>

<ng-template #boolean let-value="value">
  <img
    style="height: 20px"
    src="{{ value === true ? '/assets/yes.svg' : '/assets/no.svg' }}"
    alt=""
  />
</ng-template>

<ng-template #date let-value="value">
  {{ value | date : "dd.MM.yyyy. HH:mm" }}
</ng-template>

<ng-template #pdfButton let-id="id" let-context="context">
  <button class="list-button" (click)="printPDFSingleButtonPressed($event, id)">
    PDF
  </button>
</ng-template>

<ng-template #cancelInvoiceButton let-id="id" let-context="context">
  <button
    class="list-button"
    [disabled]="
      !(
        context.canceled_by_invoice_number == null && context.type !== 'storno'
      ) ||
      (!(
        context.status == 'ready_for_sap' ||
        context.status == 'transfered_to_sap' ||
        context.status == 'not_for_sap'
      ) &&
        (context.fiscalization == 'fiscalized' ||
          context.fiscalization == 'doesnt_need_fiscalization'))
    "
    (click)="cancelInvoiceSingleButtonPressed($event, id)"
  >
    Cancel
  </button>
</ng-template>

<ng-template #deleteInvoiceButton let-id="id" let-context="context">
  <button
    class="list-button"
    [disabled]="context.status != 'new'"
    (click)="button2Pressed($event, id)"
  >
    Delete
  </button>
</ng-template>

<ng-template #fiscalizeInvoiceButton let-id="id" let-context="context">
  <button
    class="list-button"
    [disabled]="context.fiscalization != 'not_fiscalised'"
    (click)="ficalizeInvoiceSingleButtonPressed($event, id)"
  >
    Fiscalize
  </button>
</ng-template>

<ng-template #button let-value="value" let-id="id">
  <button class="list-button" (click)="buttonPressed($event, id)">
    {{ value }}
  </button>
</ng-template>

<ng-template #button2 let-value="value" let-id="id">
  <button class="list-button" (click)="button2Pressed($event, id)">
    {{ value }}
  </button>
</ng-template>

<ng-template #image let-value="value">
  <div class="preview-img-container">
    <img class="preview-img" src="{{ value }}" alt="" />
  </div>
</ng-template>

<ng-template
  #sapDropdown
  let-id="id"
  let-value="value"
  let-context="context"
  let-selectOptions="selectOptions"
>
  <select
    name="sap-dropdown"
    id="sap-dropdown"
    class="sap-dropdown"
    [disabled]="
      context.status == 'ready_for_sap' ||
      context.status == 'transfered_to_sap' ||
      context.status == 'not_for_sap'
    "
    (click)="stopPropagation($event)"
    (change)="sapDropdownValueChanged($event, id)"
  >
    <option
      *ngFor="let option of selectOptions"
      value="{{ option.value }}"
      [selected]="context.status === option.value"
    >
      {{ option.name }}
    </option>
  </select>
</ng-template>
