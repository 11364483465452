/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ContactRequest } from '../model/contactRequest';
import { ContractList } from '../model/contractList';
import { DownloadList } from '../model/downloadList';
import { IdsList } from '../model/idsList';
import { InvoiceList } from '../model/invoiceList';
import { NewsletterStatus } from '../model/newsletterStatus';
import { User } from '../model/user';
import { UserList } from '../model/userList';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ProfileService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Create a new subusers
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsCreateProfileSubuser(
    body?: User,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<User>;
  public cmsCreateProfileSubuser(
    body?: User,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public cmsCreateProfileSubuser(
    body?: User,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public cmsCreateProfileSubuser(
    body?: User,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<User>(
      'post',
      `${this.basePath}/profile/subusers`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Delete a subusers
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteProfileSubuser(
    body?: IdsList,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsDeleteProfileSubuser(
    body?: IdsList,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsDeleteProfileSubuser(
    body?: IdsList,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsDeleteProfileSubuser(
    body?: IdsList,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/profile/subusers/delete`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Delete a subuser profile image
   * @param userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteProfileSubuserProfileImage(
    userId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<User>;
  public cmsDeleteProfileSubuserProfileImage(
    userId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public cmsDeleteProfileSubuserProfileImage(
    userId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public cmsDeleteProfileSubuserProfileImage(
    userId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling cmsDeleteProfileSubuserProfileImage.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<User>(
      'delete',
      `${this.basePath}/profile/users/${encodeURIComponent(
        String(userId)
      )}/profile_image`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update a subuser
   * @param userId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsUpdateProfileSubuser(
    userId: number,
    body?: User,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<User>;
  public cmsUpdateProfileSubuser(
    userId: number,
    body?: User,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public cmsUpdateProfileSubuser(
    userId: number,
    body?: User,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public cmsUpdateProfileSubuser(
    userId: number,
    body?: User,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling cmsUpdateProfileSubuser.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<User>(
      'put',
      `${this.basePath}/profile/users/${encodeURIComponent(String(userId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update a subuser
   * @param userId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsUpdateProfileSubuserProfileImage(
    userId: number,
    body?: Object,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<User>;
  public cmsUpdateProfileSubuserProfileImage(
    userId: number,
    body?: Object,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public cmsUpdateProfileSubuserProfileImage(
    userId: number,
    body?: Object,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public cmsUpdateProfileSubuserProfileImage(
    userId: number,
    body?: Object,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling cmsUpdateProfileSubuserProfileImage.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['image/jpg'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<User>(
      'put',
      `${this.basePath}/profile/users/${encodeURIComponent(
        String(userId)
      )}/profile_image`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Delete a subuser
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontDeleteProfileImage(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<User>;
  public frontDeleteProfileImage(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public frontDeleteProfileImage(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public frontDeleteProfileImage(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<User>(
      'delete',
      `${this.basePath}/current_user/profile_image`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get current user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetCurrentUser(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<User>;
  public frontGetCurrentUser(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public frontGetCurrentUser(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public frontGetCurrentUser(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<User>(
      'get',
      `${this.basePath}/current_user`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get newsletter status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetNewsletterStatus(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NewsletterStatus>;
  public frontGetNewsletterStatus(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NewsletterStatus>>;
  public frontGetNewsletterStatus(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NewsletterStatus>>;
  public frontGetNewsletterStatus(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<NewsletterStatus>(
      'get',
      `${this.basePath}/newsletter_status`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get contract PDF
   * @param contractId Contract ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetProfileContractPDF(
    contractId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public frontGetProfileContractPDF(
    contractId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public frontGetProfileContractPDF(
    contractId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public frontGetProfileContractPDF(
    contractId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (contractId === null || contractId === undefined) {
      throw new Error(
        'Required parameter contractId was null or undefined when calling frontGetProfileContractPDF.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/pdf'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'get',
      `${this.basePath}/profile/contracts/${encodeURIComponent(
        String(contractId)
      )}/pdf`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get active contracts
   * @param page Pagination page
   * @param page_size Items per page
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetProfileContracts(
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ContractList>;
  public frontGetProfileContracts(
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ContractList>>;
  public frontGetProfileContracts(
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ContractList>>;
  public frontGetProfileContracts(
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ContractList>(
      'get',
      `${this.basePath}/profile/contracts`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get all downloads
   * @param page Pagination page
   * @param page_size Items per page
   * @param date_from
   * @param date_to
   * @param content_type
   * @param user_type
   * @param user_id
   * @param author_id
   * @param agency_id
   * @param variation
   * @param above_price
   * @param below_price
   * @param product_name
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetProfileDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    author_id?: number,
    agency_id?: number,
    variation?: string,
    above_price?: number,
    below_price?: number,
    product_name?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DownloadList>;
  public frontGetProfileDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    author_id?: number,
    agency_id?: number,
    variation?: string,
    above_price?: number,
    below_price?: number,
    product_name?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DownloadList>>;
  public frontGetProfileDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    author_id?: number,
    agency_id?: number,
    variation?: string,
    above_price?: number,
    below_price?: number,
    product_name?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DownloadList>>;
  public frontGetProfileDownloads(
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
    content_type?: string,
    user_type?: string,
    user_id?: number,
    author_id?: number,
    agency_id?: number,
    variation?: string,
    above_price?: number,
    below_price?: number,
    product_name?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (date_from !== undefined && date_from !== null) {
      queryParameters = queryParameters.set('date_from', <any>date_from);
    }
    if (date_to !== undefined && date_to !== null) {
      queryParameters = queryParameters.set('date_to', <any>date_to);
    }
    if (content_type !== undefined && content_type !== null) {
      queryParameters = queryParameters.set('content_type', <any>content_type);
    }
    if (user_type !== undefined && user_type !== null) {
      queryParameters = queryParameters.set('user_type', <any>user_type);
    }
    if (user_id !== undefined && user_id !== null) {
      queryParameters = queryParameters.set('user_id', <any>user_id);
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (agency_id !== undefined && agency_id !== null) {
      queryParameters = queryParameters.set('agency_id', <any>agency_id);
    }
    if (variation !== undefined && variation !== null) {
      queryParameters = queryParameters.set('variation', <any>variation);
    }
    if (above_price !== undefined && above_price !== null) {
      queryParameters = queryParameters.set('above_price', <any>above_price);
    }
    if (below_price !== undefined && below_price !== null) {
      queryParameters = queryParameters.set('below_price', <any>below_price);
    }
    if (product_name !== undefined && product_name !== null) {
      queryParameters = queryParameters.set('product_name', <any>product_name);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DownloadList>(
      'get',
      `${this.basePath}/profile/downloads`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get invoice PDF
   * @param invoiceId Invoice ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetProfileInvoicePDF(
    invoiceId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public frontGetProfileInvoicePDF(
    invoiceId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public frontGetProfileInvoicePDF(
    invoiceId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public frontGetProfileInvoicePDF(
    invoiceId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (invoiceId === null || invoiceId === undefined) {
      throw new Error(
        'Required parameter invoiceId was null or undefined when calling frontGetProfileInvoicePDF.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/pdf'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'get',
      `${this.basePath}/profile/invoices/${encodeURIComponent(
        String(invoiceId)
      )}/pdf`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json', // manual changes
      }
    );
  }

  /**
   *
   * Get all invoices
   * @param page Pagination page
   * @param page_size Items per page
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetProfileInvoices(
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoiceList>;
  public frontGetProfileInvoices(
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoiceList>>;
  public frontGetProfileInvoices(
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoiceList>>;
  public frontGetProfileInvoices(
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InvoiceList>(
      'get',
      `${this.basePath}/profile/invoices`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get all subusers
   * @param page Pagination page
   * @param page_size Items per page
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetProfileSubusers(
    page?: number,
    page_size?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserList>;
  public frontGetProfileSubusers(
    page?: number,
    page_size?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserList>>;
  public frontGetProfileSubusers(
    page?: number,
    page_size?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserList>>;
  public frontGetProfileSubusers(
    page?: number,
    page_size?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<UserList>(
      'get',
      `${this.basePath}/profile/subusers`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Send contact request
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontSendContactUsRequest(
    body?: ContactRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public frontSendContactUsRequest(
    body?: ContactRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public frontSendContactUsRequest(
    body?: ContactRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public frontSendContactUsRequest(
    body?: ContactRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/contact_us`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Send contact request
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontSendProfileContactRequest(
    body?: ContactRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public frontSendProfileContactRequest(
    body?: ContactRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public frontSendProfileContactRequest(
    body?: ContactRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public frontSendProfileContactRequest(
    body?: ContactRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/profile/contact`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update current user
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontUpdateCurrentUser(
    body?: User,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<User>;
  public frontUpdateCurrentUser(
    body?: User,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public frontUpdateCurrentUser(
    body?: User,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public frontUpdateCurrentUser(
    body?: User,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<User>(
      'put',
      `${this.basePath}/current_user`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update newsletter status
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontUpdateNewsletterStatus(
    body?: NewsletterStatus,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NewsletterStatus>;
  public frontUpdateNewsletterStatus(
    body?: NewsletterStatus,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NewsletterStatus>>;
  public frontUpdateNewsletterStatus(
    body?: NewsletterStatus,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NewsletterStatus>>;
  public frontUpdateNewsletterStatus(
    body?: NewsletterStatus,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<NewsletterStatus>(
      'put',
      `${this.basePath}/newsletter_status`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update a subuser
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontUpdateProfileImage(
    body?: Object,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<User>;
  public frontUpdateProfileImage(
    body?: Object,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<User>>;
  public frontUpdateProfileImage(
    body?: Object,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<User>>;
  public frontUpdateProfileImage(
    body?: Object,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['image/jpg'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<User>(
      'put',
      `${this.basePath}/current_user/profile_image`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
