import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from '../field-text/field-text.component';
import { ModelError } from '../../../../projects/piwe-front-swagger-client/src';

export interface FieldRadioOption {
  title: string;
  value: string;
}

@Component({
  selector: 'app-field-radio',
  templateUrl: './field-radio.component.html',
  styleUrls: ['./field-radio.component.scss'],
})
export class FieldRadioComponent implements OnInit {
  @Input() title?: string;
  @Input() options: FieldRadioOption[] = [];
  @Input() value?: string;
  @Output() valueChange: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >();

  @Input() errors: ModelError[] = [];

  @Output() actionSelected: EventEmitter<{
    field: string;
    action: Action;
  }> = new EventEmitter<{
    field: string;
    action: Action;
  }>();

  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onOptionClick(option: FieldRadioOption) {
    if (this.disabled) return;

    this.value = option.value;
    this.valueChange.emit(this.value);
  }
}
