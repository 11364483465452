<div
  class="file-container"
  appDropzone
  (fileDropped)="onFileDropped($event)"
  [style.display]="isImport ? 'block' : 'none'"
>
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    [multiple]="isImport"
    (change)="fileBrowseHandler($event)"
  />
  <img src="assets/upload-file.svg" class="file-container-image" />
  <img src="assets/upload-file-hover.svg" class="file-container-image-hover" />
  <h3>Drag and drop your files here or</h3>
  <label for="fileDropRef">Choose files</label>
</div>
<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <app-dropzone-progress
        [progress]="file?.progress"
      ></app-dropzone-progress>
    </div>
    <!--<img src="assets/img/dnd/ic-delete-file.svg" class="delete" width="20px"
         alt="file" (click)="deleteFile(i)">-->
  </div>
</div>
