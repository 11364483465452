/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Body } from '../model/body';
import { ErrorResponse } from '../model/errorResponse';
import { HomepageTemplateItem } from '../model/homepageTemplateItem';
import { HomepageTemplateItemResponse } from '../model/homepageTemplateItemResponse';
import { HomepageTemplateListResponse } from '../model/homepageTemplateListResponse';
import { TemplateBlockProductPreviewSchema } from '../model/templateBlockProductPreviewSchema';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class WebAdministrationService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Delete teplate
   * @param id ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteTemplate(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateListResponse>;
  public cmsDeleteTemplate(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateListResponse>>;
  public cmsDeleteTemplate(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateListResponse>>;
  public cmsDeleteTemplate(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsDeleteTemplate.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageTemplateListResponse>(
      'post',
      `${this.basePath}/cms/web_administration/homepage/${encodeURIComponent(
        String(id)
      )}/delete`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Duplicate teplate
   * @param id ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDuplicateTemplate(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateListResponse>;
  public cmsDuplicateTemplate(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateListResponse>>;
  public cmsDuplicateTemplate(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateListResponse>>;
  public cmsDuplicateTemplate(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsDuplicateTemplate.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageTemplateListResponse>(
      'post',
      `${this.basePath}/cms/web_administration/homepage/${encodeURIComponent(
        String(id)
      )}/duplicate`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get a single template based on id
   * @param id ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetHomepageTemplate(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateItemResponse>;
  public cmsGetHomepageTemplate(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateItemResponse>>;
  public cmsGetHomepageTemplate(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateItemResponse>>;
  public cmsGetHomepageTemplate(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsGetHomepageTemplate.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageTemplateItemResponse>(
      'get',
      `${this.basePath}/cms/web_administration/homepage/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns all homepage templates
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetHomepageTemplateList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateListResponse>;
  public cmsGetHomepageTemplateList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateListResponse>>;
  public cmsGetHomepageTemplateList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateListResponse>>;
  public cmsGetHomepageTemplateList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageTemplateListResponse>(
      'get',
      `${this.basePath}/cms/web_administration/homepage`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns the published template
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetPublishedTemplate(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateItemResponse>;
  public cmsGetPublishedTemplate(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateItemResponse>>;
  public cmsGetPublishedTemplate(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateItemResponse>>;
  public cmsGetPublishedTemplate(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageTemplateItemResponse>(
      'get',
      `${this.basePath}/cms/web_administration/homepage/published`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Create a new homepage template
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPostHomepageTemplate(
    body?: HomepageTemplateItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateItemResponse>;
  public cmsPostHomepageTemplate(
    body?: HomepageTemplateItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateItemResponse>>;
  public cmsPostHomepageTemplate(
    body?: HomepageTemplateItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateItemResponse>>;
  public cmsPostHomepageTemplate(
    body?: HomepageTemplateItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<HomepageTemplateItemResponse>(
      'post',
      `${this.basePath}/cms/web_administration/homepage`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Publish a template and unpublish the published one
   * @param id ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPublishTemplate(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateListResponse>;
  public cmsPublishTemplate(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateListResponse>>;
  public cmsPublishTemplate(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateListResponse>>;
  public cmsPublishTemplate(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsPublishTemplate.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageTemplateListResponse>(
      'post',
      `${this.basePath}/cms/web_administration/homepage/${encodeURIComponent(
        String(id)
      )}/publish`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Edit a homepage template
   * @param id ID
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPutHomepageTemplate(
    id: number,
    body?: HomepageTemplateItem,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateItemResponse>;
  public cmsPutHomepageTemplate(
    id: number,
    body?: HomepageTemplateItem,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateItemResponse>>;
  public cmsPutHomepageTemplate(
    id: number,
    body?: HomepageTemplateItem,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateItemResponse>>;
  public cmsPutHomepageTemplate(
    id: number,
    body?: HomepageTemplateItem,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsPutHomepageTemplate.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<HomepageTemplateItemResponse>(
      'put',
      `${this.basePath}/cms/web_administration/homepage/${encodeURIComponent(
        String(id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Rename teplate
   * @param id ID
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsRenameTemplate(
    id: number,
    body?: Body,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateListResponse>;
  public cmsRenameTemplate(
    id: number,
    body?: Body,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateListResponse>>;
  public cmsRenameTemplate(
    id: number,
    body?: Body,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateListResponse>>;
  public cmsRenameTemplate(
    id: number,
    body?: Body,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling cmsRenameTemplate.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<HomepageTemplateListResponse>(
      'post',
      `${this.basePath}/cms/web_administration/homepage/${encodeURIComponent(
        String(id)
      )}/rename`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Upload homepage media
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsUploadHomePageMedia(
    body?: Object,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<TemplateBlockProductPreviewSchema>;
  public cmsUploadHomePageMedia(
    body?: Object,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TemplateBlockProductPreviewSchema>>;
  public cmsUploadHomePageMedia(
    body?: Object,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TemplateBlockProductPreviewSchema>>;
  public cmsUploadHomePageMedia(
    body?: Object,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/octet-stream'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<TemplateBlockProductPreviewSchema>(
      'post',
      `${this.basePath}/cms/web_administration/homepage/upload`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns all homepage templates
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetHomepageTemplateList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateListResponse>;
  public frontGetHomepageTemplateList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateListResponse>>;
  public frontGetHomepageTemplateList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateListResponse>>;
  public frontGetHomepageTemplateList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageTemplateListResponse>(
      'get',
      `${this.basePath}/web_administration/homepage`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns the published template
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetPublishedTemplate(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<HomepageTemplateItemResponse>;
  public frontGetPublishedTemplate(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<HomepageTemplateItemResponse>>;
  public frontGetPublishedTemplate(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<HomepageTemplateItemResponse>>;
  public frontGetPublishedTemplate(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<HomepageTemplateItemResponse>(
      'get',
      `${this.basePath}/web_administration/homepage/published`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
