<a
  class="right-secondary-item"
  [routerLink]="
    pagebuilderMode
      ? []
      : mediaData?.kind === 'GALLERY'
      ? ['/ga/', mediaData?.id]
      : ['/g/', mediaData?.id]
  "
>
  <app-single-image
    *ngIf="mediaData !== null"
    class="image"
    [imageLoadingPreviewUrl]="mediaData!.preview![0].preview_url_blur!"
    [imagePreviewUrl]="mediaData!.preview![0].preview_url!"
    [class.aspect-ratio-small]="imgSize === 'small'"
    [class.aspect-ratio-medium]="imgSize === 'medium'"
    [class.aspect-ratio-large]="imgSize === 'large'"
  >
  </app-single-image>
  <app-single-image
    *ngIf="mediaData === null"
    class="image"
    [imageLoadingPreviewUrl]="noImgSectionUrl"
    [imagePreviewUrl]="noImgSectionUrl"
    [class.aspect-ratio-small]="imgSize === 'small'"
    [class.aspect-ratio-medium]="imgSize === 'medium'"
    [class.aspect-ratio-large]="imgSize === 'large'"
  >
    >
  </app-single-image>
  <div
    class="right-secondary-text"
    [class.text-container-small]="imgSize === 'small'"
    [class.text-container-medium]="imgSize === 'medium'"
    [class.text-container-large]="imgSize === 'large'"
  >
    <div class="right-secondary-header">
      <div class="right-secondary-details">
        <div class="image-count"></div>
        <div class="image-count-text">
          {{ mediaData?.count }}
        </div>
        <div class="right-secondary-vr"></div>
        <div class="right-secondary-date" *ngIf="mediaData?.timestamp !== null">
          {{ mediaData?.timestamp | date : "dd.MM.yyyy. HH:mm" }}
        </div>
        <div class="right-secondary-date" *ngIf="mediaData?.timestamp === null">
          {{ "01.01.1900. 00:00" }}
        </div>
      </div>
      <div *ngIf="mediaData?.has_article" class="article-icon"></div>
    </div>
    <p [class.large-title]="imgSize === 'large'">
      {{
        (currentLanguage ? productTitle : productTitleEn) ||
          "Placeholder headline"
      }}
    </p>
  </div>
</a>
