<div class="table-wrapper" #tableWrapper>
  <table *ngIf="columns && columnTitles && columnTypes && items">
    <thead>
      <tr>
        <th
          *ngFor="let column of columns; index as i"
          [style.width]="columnSize(columnTypes[i])"
        >
          {{ columnTitles[i] }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of items; index as i; trackBy: getItemId"
        [ngClass]="{
          selected: selectedItemIds.includes(item.id),
          'previous-selected': isPreviousSelected(item)
        }"
        (click)="didSelectTableRow(item, $event)"
      >
        <td *ngFor="let column of columns; index as n">
          <ng-container [ngSwitch]="columnTypes[n]">
            <div
              class="appMainPreviewItem"
              *ngSwitchCase="'import-item-preview'"
            >
              <app-field-checkbox
                [checked]="selectedItemIds.includes(item.id)"
              ></app-field-checkbox>

              <app-main-preview-item
                [attr.data-id]="item.id"
                [isVideo]="item.isVideo"
                [previewUrl]="item.previewUrl"
                [loadingPreviewUrl]="item.blurPreviewUrl"
                [largePreviewUrl]="item.largePreviewUrl"
                [isLocked]="item.isLocked"
                [previewUrl]="item.previewUrl"
                [style.height.px]="(1.0 / item.aspectRatio!) * 96.0"
              >
              </app-main-preview-item>
            </div>

            <span *ngSwitchCase="'string'">{{ item[column] }}</span>
            <span *ngSwitchCase="'autocomplete'">
              {{ item[column] | autocomplete }}</span
            >
            <span *ngSwitchCase="'tags'">{{
              item[column] | autocompleteTags
            }}</span>
            <span *ngSwitchCase="'boolean'">{{ item[column] | yesNo }}</span>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
