import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private snackBarSource = new Subject<{
    title: string;
    state: 'INFO' | 'ERROR';
  }>();
  public snackBarData: Observable<{ title: string; state: 'INFO' | 'ERROR' }> =
    this.snackBarSource.asObservable();

  constructor() {}

  showSnackBar(title: string, state: 'INFO' | 'ERROR') {
    this.snackBarSource.next({
      title: title,
      state: state,
    });

    setTimeout(() => {
      this.snackBarSource.next(undefined);
    }, 5000);
  }
}
