import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '../permissions.service';
import { User } from 'piwe-front-swagger-client';

@Component({
  selector: 'app-app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
})
export class AppSidebarComponent implements OnInit {
  subroutes: { [key: string]: string[] } = {
    content: [
      '/cms/content/products',
      '/cms/content/galleries',
      '/cms/content/gallery-group',
      '/cms/content/articles',
      '/cms/content/categories',
    ],

    contractAndUsers: [
      '/cms/contracts-and-users/companies',
      '/cms/contracts-and-users/users',
      '/cms/contracts-and-users/downloads',
      '/cms/contracts-and-users/contracts',
      '/cms/contracts-and-users/invoices',
      '/cms/contracts-and-users/newsletter',
    ],

    deskAdministration: [
      '/cms/desk-administration/agencies',
      '/cms/desk-administration/iptc',
      '/cms/desk-administration/keywords',
      '/cms/desk-administration/proper-names',
      '/cms/desk-administration/tags',
    ],
    webAdministration: [
      '/cms/web-administration/homepage-administration',
      '/cms/web-administration/about-us-administration',
      '/cms/web-administration/static-pages-administration',
    ],
    finances: [
      '/cms/finances/downloads',
      '/cms/finances/lump-sum',
      '/cms/finances/administration',
      '/cms/finances/invoices',
    ],
    internalUsersAdministration: [
      '/cms/internal-users-administration/internal-users',
      '/cms/internal-users-administration/user-groups',
      '/cms/internal-users-administration/user-loging-log',
    ],
  };

  isContentExpanded: boolean = false;
  isNewsletterExpanded: boolean = false;
  isContractsAndUsersExpanded: boolean = false;
  isDeskAdministrationExpanded: boolean = false;
  isWebAdministrationExpanded: boolean = false;
  isReportsExpanded: boolean = false;
  isSaleExpanded: boolean = false;
  isCalculationExpanded: boolean = false;
  isFinancesExpanded: boolean = false;
  isInternalUsersAdministrationExpanded: boolean = false;

  public userPermissions: User.UserPermissionsEnum[] | undefined = undefined;

  UserPermissionsEnum = User.UserPermissionsEnum;

  constructor(
    private router: Router,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.permissionsService.permissionsData.subscribe((userPermissions) => {
      this.userPermissions = userPermissions;
    });

    for (const subrouteKey in this.subroutes) {
      const subroutes = this.subroutes[subrouteKey];

      let isActive = false;
      for (const subroute of subroutes) {
        //console.log(subrouteKey, subroute);
        if (this.router.isActive(subroute, false)) {
          isActive = true;
        }
      }

      switch (subrouteKey) {
        case 'content':
          this.isContentExpanded = isActive;
          break;
        case 'contractAndUsers':
          this.isContractsAndUsersExpanded = isActive;
          break;
        case 'deskAdministration':
          this.isDeskAdministrationExpanded = isActive;
          break;
        case 'webAdministration':
          this.isWebAdministrationExpanded = isActive;
          break;
        case 'finances':
          this.isFinancesExpanded = isActive;
          break;
        case 'internalUsersAdministration':
          this.isInternalUsersAdministrationExpanded = isActive;
          break;
      }
    }
  }

  toggle(item: string): void {
    switch (item) {
      case 'content':
        this.isContentExpanded = !this.isContentExpanded;
        this.isNewsletterExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isSaleExpanded = false;
        this.isCalculationExpanded = false;
        this.isFinancesExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'newsletter':
        this.isNewsletterExpanded = !this.isNewsletterExpanded;
        this.isContentExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isSaleExpanded = false;
        this.isCalculationExpanded = false;
        this.isFinancesExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'contractsAndUsers':
        this.isContractsAndUsersExpanded = !this.isContractsAndUsersExpanded;
        this.isContentExpanded = false;
        this.isNewsletterExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isSaleExpanded = false;
        this.isCalculationExpanded = false;
        this.isFinancesExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'deskAdministration':
        this.isDeskAdministrationExpanded = !this.isDeskAdministrationExpanded;
        this.isContentExpanded = false;
        this.isNewsletterExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isSaleExpanded = false;
        this.isCalculationExpanded = false;
        this.isFinancesExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'webAdministration':
        this.isWebAdministrationExpanded = !this.isWebAdministrationExpanded;
        this.isContentExpanded = false;
        this.isNewsletterExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isSaleExpanded = false;
        this.isCalculationExpanded = false;
        this.isFinancesExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'reports':
        this.isReportsExpanded = !this.isReportsExpanded;
        this.isContentExpanded = false;
        this.isNewsletterExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isSaleExpanded = false;
        this.isCalculationExpanded = false;
        this.isFinancesExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'sale':
        this.isSaleExpanded = !this.isSaleExpanded;
        this.isContentExpanded = false;
        this.isNewsletterExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isCalculationExpanded = false;
        this.isFinancesExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'calculation':
        this.isCalculationExpanded = !this.isCalculationExpanded;
        this.isContentExpanded = false;
        this.isNewsletterExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isSaleExpanded = false;
        this.isFinancesExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'finances':
        this.isFinancesExpanded = !this.isFinancesExpanded;
        this.isContentExpanded = false;
        this.isNewsletterExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isSaleExpanded = false;
        this.isCalculationExpanded = false;
        this.isInternalUsersAdministrationExpanded = false;
        break;
      case 'internalUsersAdministration':
        this.isInternalUsersAdministrationExpanded =
          !this.isInternalUsersAdministrationExpanded;
        this.isContentExpanded = false;
        this.isNewsletterExpanded = false;
        this.isContractsAndUsersExpanded = false;
        this.isDeskAdministrationExpanded = false;
        this.isWebAdministrationExpanded = false;
        this.isReportsExpanded = false;
        this.isSaleExpanded = false;
        this.isCalculationExpanded = false;
        this.isFinancesExpanded = false;
        break;
    }
  }

  alert(text: string) {
    alert(text);
  }
}
