<div class="newsletter-container" *ngIf="popUpShown">
  <div>
    <div class="newsletter-close-button" (click)="close($event)"></div>
    <div class="newsletter-logo"></div>
    <div class="newsletter-body">
      <h1>Newsletter</h1>
      <h2 [innerHTML]="'newsletter.title' | translate">
        Prijavite se na naš Newsletter i primajte najnovije vijesti i odabrane
        galerije u vaš inbox.
      </h2>

      <form
        ngNoForm
        #form
        [action]="'newsletter.action' | translate"
        method="POST"
      >
        <div class="newsletter-form">
          <input type="hidden" name="DMDtask" value="subscribe" />
          <input
            type="text"
            name="email"
            [value]="emailValue"
            maxlength="255"
            placeholder="E-mail"
          />
          <input
            (click)="submit($event)"
            type="submit"
            [value]="'newsletter.button' | translate"
          />
          <input
            type="hidden"
            name="subscribe_source"
            [value]="'newsletter.subscribe_source' | translate"
          />
          <input
            style="display: none"
            type="text"
            name="lang"
            [value]="'newsletter.lang' | translate"
          />
        </div>

        <div style="visibility: hidden; font-size: 0">
          Please <em>don't</em> insert text in the box below!
          <input
            type="text"
            name="submitCheck"
            value=""
            style="width: 1px; height: 1px; font-size: 0"
          />
        </div>
      </form>
    </div>
  </div>
</div>
