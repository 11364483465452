<button
  class="select-btn"
  (click)="handleSelect()"
  [@selectDeselect]="isSelected ? 'selected' : 'deselected'"
  [class.hovered]="!isSelected && !isSelectedInsideThisBlock"
  (mouseenter)="handleMouseEnter()"
  (mouseleave)="handleMouseLeave()"
>
  <ng-content></ng-content>
  <button class="delete-btn" (click)="handleDelete()" *ngIf="isSelected">
    Delete block
  </button>
</button>
