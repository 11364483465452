import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private tableColumnsSource = new Subject<string[]>();
  public tableColumnsData: Observable<string[]> =
    this.tableColumnsSource.asObservable();

  private tableColumnTypesSource = new Subject<string[]>();
  public tableColumnTypesData: Observable<string[]> =
    this.tableColumnTypesSource.asObservable();

  private tableColumnTitlesSource = new Subject<string[]>();
  public tableColumnTitlesData: Observable<string[]> =
    this.tableColumnTitlesSource.asObservable();

  private selectedIdsSource = new Subject<number[]>();
  public selectedIdsData: Observable<number[]> =
    this.selectedIdsSource.asObservable();

  private itemsSource = new Subject<any[]>();
  public itemsData: Observable<any[]> = this.itemsSource.asObservable();

  constructor() {}

  setTableData(
    columns: string[],
    columnTypes: string[],
    columnTitles: string[]
  ) {
    this.tableColumnTypesSource.next(columnTypes);
    this.tableColumnTitlesSource.next(columnTitles);
    this.tableColumnsSource.next(columns);
  }

  setSelected(ids: number[]) {
    this.selectedIdsSource.next(ids);
  }

  setData(itemsData: any[]) {
    this.itemsSource.next(itemsData);
  }
}
