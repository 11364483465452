/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MediaPreivew {
  aspect_ratio?: number;
  /**
   * Main preview image used in grids, etc. Medium resolution. If video this image will be the still screenshot from the video.
   */
  preview_url?: string;
  /**
   * Main preview image used in zooming, larger grids. Large resultion. If video this image will be the still screenshot from the video.
   */
  preview_url_large?: string;
  /**
   * Blured placeholder while loading, will also help the grid with the aspect ratios. If video this image will be the still screenshot from the video.
   */
  preview_url_blur?: string;
  /**
   * This is the video that is displayed in the grid as a preview on hover.
   */
  preview_video_loop?: string;
  /**
   * Full resolution video with a watermark.
   */
  preview_video_720p?: string;
}
