/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RegistrationRequest {
  kind: RegistrationRequest.KindEnum;
  username: string;
  email: string;
  company_name?: string;
  password: string;
  confirm_password: string;
  oib_company?: string;
  tax_number?: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
  address_line_1?: string;
  city?: string;
  postal_code?: string;
  press_magazine_name?: string;
  country_id?: number;
  currency_id?: number;
  accepted_consents_ids?: Array<number>;
  did_sign_up_for_newsletter?: boolean;
}
export namespace RegistrationRequest {
  export type KindEnum = 'SMALL' | 'CORPORATE';
  export const KindEnum = {
    SMALL: 'SMALL' as KindEnum,
    CORPORATE: 'CORPORATE' as KindEnum,
  };
}
