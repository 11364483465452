/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Agency } from './agency';
import { Author } from './author';
import { Company } from './company';
import { ContractLine } from './contractLine';
import { ProductPreview } from './productPreview';
import { UserPreview } from './userPreview';

export interface Download {
  id?: number;
  product_preview?: ProductPreview;
  product_id?: number;
  original_filename?: string;
  content_type?: Download.ContentTypeEnum;
  size?: string;
  agency?: Agency;
  company?: Company;
  i_c?: boolean;
  author?: Author;
  user?: UserPreview;
  downloaded_at?: string;
  download_price?: number;
  original_price?: number;

  use_tax?: boolean;
  alternative_download_price?: number;
  alternative_original_price?: number;

  discount?: number;
  currency?: Download.CurrencyEnum;
  variation?: Download.VariationEnum;
  comment?: string;
  contract_item?: ContractLine;
  place_of_expense?: string;
  exchange_rate?: number;
  invoice?: string;
  product?: string;
  euro_mode?: Download.EuroModeEnum;

  ip?: string;
  user_agent?: string;
}
export namespace Download {
  export type ContentTypeEnum = 'PHOTO' | 'VIDEO';
  export const ContentTypeEnum = {
    PHOTO: 'PHOTO' as ContentTypeEnum,
    VIDEO: 'VIDEO' as ContentTypeEnum,
  };
  export type CurrencyEnum = 'HRK' | 'EUR';
  export const CurrencyEnum = {
    HRK: 'HRK' as CurrencyEnum,
    EUR: 'EUR' as CurrencyEnum,
  };
  export type VariationEnum = 'PRINT' | 'WEB';
  export const VariationEnum = {
    PRINT: 'PRINT' as VariationEnum,
    WEB: 'WEB' as VariationEnum,
  };
  export type EuroModeEnum =
    | 'HRK_ONLY'
    | 'BIG_HRK_SMALL_EUR'
    | 'BIG_EUR_SMALL_HRK'
    | 'EUR_ONLY';
  export const EuroModeEnum = {
    HRKONLY: 'HRK_ONLY' as EuroModeEnum,
    BIGHRKSMALLEUR: 'BIG_HRK_SMALL_EUR' as EuroModeEnum,
    BIGEURSMALLHRK: 'BIG_EUR_SMALL_HRK' as EuroModeEnum,
    EURONLY: 'EUR_ONLY' as EuroModeEnum,
  };
}
