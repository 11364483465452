/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Agency } from './agency';
import { Author } from './author';
import { Category } from './category';
import { MainFilter } from './mainFilter';
import { Provider } from './provider';
import { RefinementFilter } from './refinementFilter';
import { Tag } from './tag';

export interface SubportalFilterResponse {
  filter_category?: boolean;
  available_filter_category?: Array<Category>;
  filter_interval?: boolean;
  available_filter_interval?: SubportalFilterResponse.AvailableFilterIntervalEnum;
  available_filter_interval_start?: string;
  available_filter_interval_end?: string;
  filter_tags?: boolean;
  available_filter_tags?: Array<Tag>;
  filter_main_filter?: boolean;
  available_main_filters?: Array<MainFilter>;
  available_refinement_filters?: Array<RefinementFilter>;
  filter_author?: boolean;
  available_filter_author?: Author;
  filter_agency?: boolean;
  available_filter_agency?: Array<Agency>;
  filter_provider?: boolean;
  available_filter_provider?: Array<Provider>;
  filter_kind?: boolean;
  available_filter_kind?: SubportalFilterResponse.AvailableFilterKindEnum;
  filter_orientation?: boolean;
  available_filter_orientation?: SubportalFilterResponse.AvailableFilterOrientationEnum;
  filter_colour?: boolean;
  available_filter_colour?: SubportalFilterResponse.AvailableFilterColourEnum;
}
export namespace SubportalFilterResponse {
  export type AvailableFilterIntervalEnum =
    | 'ALL'
    | 'PAST_24H'
    | 'PAST_48H'
    | 'RANGE';
  export const AvailableFilterIntervalEnum = {
    ALL: 'ALL' as AvailableFilterIntervalEnum,
    PAST24H: 'PAST_24H' as AvailableFilterIntervalEnum,
    PAST48H: 'PAST_48H' as AvailableFilterIntervalEnum,
    RANGE: 'RANGE' as AvailableFilterIntervalEnum,
  };
  export type AvailableFilterKindEnum = 'VIDEO' | 'PHOTO';
  export const AvailableFilterKindEnum = {
    VIDEO: 'VIDEO' as AvailableFilterKindEnum,
    PHOTO: 'PHOTO' as AvailableFilterKindEnum,
  };
  export type AvailableFilterOrientationEnum =
    | 'HORIZONTAL'
    | 'VERTICAL'
    | 'SQUARE';
  export const AvailableFilterOrientationEnum = {
    HORIZONTAL: 'HORIZONTAL' as AvailableFilterOrientationEnum,
    VERTICAL: 'VERTICAL' as AvailableFilterOrientationEnum,
    SQUARE: 'SQUARE' as AvailableFilterOrientationEnum,
  };
  export type AvailableFilterColourEnum = 'COLOUR' | 'GRAYSCALE';
  export const AvailableFilterColourEnum = {
    COLOUR: 'COLOUR' as AvailableFilterColourEnum,
    GRAYSCALE: 'GRAYSCALE' as AvailableFilterColourEnum,
  };
}
