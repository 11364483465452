import { Component, Input, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SrvService } from '../front/profile/srv.service';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss'],
  animations: [
    trigger('fadeModal', [
      state(
        'fadeIn',
        style({
          opacity: 1,
        })
      ),
      transition(':enter', [
        style({
          display: 'block',
          opacity: 0,
        }),
        animate('0.2s ease-in'),
      ]),
      transition(':leave', [
        animate('0.2s ease-in'),
        style({
          display: 'none',
          opacity: 0,
        }),
      ]),
    ]),
  ],
})
export class AppNavbarComponent implements OnInit {
  user: any;
  chooseAction: boolean = true;
  @Input() isLoggedIn: boolean = true;

  constructor(
    private srv: SrvService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.srv.getCurrentUser().subscribe((user) => {
      //console.log('Navbar loaded', user);
      this.user = user;
    });
  }

  openModal() {
    this.chooseAction = false;
  }

  closeModalWindow() {
    this.chooseAction = true;
  }

  logOut() {
    this.authenticationService.logOut();
    this.closeModalWindow();
  }

  stopModalPropagation(el: Event) {
    el.stopPropagation();
  }
}
