import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-add',
  templateUrl: './sidebar-add.component.html',
  styleUrls: ['./sidebar-add.component.scss'],
})
export class SidebarAddComponent implements OnInit {
  @Input() selectedItems: any[] = [];
  @Output() removeSelection: EventEmitter<void> = new EventEmitter();

  @Input() itemList: Set<any> = new Set();
  @Output() itemListChange: EventEmitter<Set<any>> = new EventEmitter();

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  addItems() {
    this.selectedItems.map((data) => {
      this.itemList.add(data);
      this.itemListChange.emit(this.itemList);
    });
    this.removeSelection.emit();
  }
  removeItem(value: any) {
    this.itemList.delete(value);
    this.itemListChange.emit(this.itemList);
  }

  finish() {
    this.router.navigate(['../'], {
      relativeTo: this.route,
      state: { addedItems: this.itemList },
    });
  }

  addAndFinish() {
    this.addItems();
    this.finish();
  }
}
