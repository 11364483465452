import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/app/shared/environment';

@Pipe({
  name: 'pixsellPreviewUrl',
})
export class PixsellPreviewUrlPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == undefined || value == '') return '/assets/no-image.svg';

    if (typeof value === 'string') {
      if (value.includes('https://') || value.includes('http://')) {
        return value;
      }
    }
    return environment.productServingUrl + value ?? '';
  }
}
