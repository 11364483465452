import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/profile.service';

@Component({
  selector: 'app-currency-container',
  templateUrl: './currency-container.component.html',
  styleUrls: ['./currency-container.component.scss'],
})
export class CurrencyContainerComponent implements OnInit {
  public currency: string = 'kn';

  constructor(private profileService: ProfileService) {}

  ngOnInit(): void {
    this.profileService.getProfile();
    this.profileService.profileData.subscribe((response) => {
      if (response?.currency_name !== undefined) {
        switch (response?.currency_name) {
          case 'HRK':
            this.currency = 'kn';
            break;
          case 'EUR':
            this.currency = '€';
            break;
          case 'USD':
            this.currency = '$';
            break;
          case 'GBP':
            this.currency = '£';
            break;
          default:
            this.currency = 'kn';
            break;
        }
      }
    });
  }
}
