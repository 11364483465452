import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { environment } from 'src/app/shared/environment';
import { HttpClient } from '@angular/common/http';
import { GalleryImportItem } from '../../cms/content/galleries/galleries.component';
import { GalleriesService } from '../../cms/content/galleries/galleries.service';
import { GalleryGroupService } from '../../cms/content/gallery-group/gallery-group.service';
import { Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-preview-gallery-item',
  templateUrl: './main-preview-gallery-item.component.html',
  styleUrls: ['./main-preview-gallery-item.component.scss'],
})
export class MainPreviewGalleryItemComponent implements OnInit, OnChanges {
  @Input() importItem?: GalleryImportItem;
  @Input() isVideo: boolean = false;
  @Input() isLocked?: boolean;
  @Input() lockedBy?: string;
  @Input() isError?: boolean;
  @Input() canSetAsGalleryHead: boolean = false;

  public currentLanguage = 'hr';
  private currentLanguageSubscription = new Subscription();

  videoPreviewUrl?: string;
  imagePreviewUrl?: string;
  imageLoadingPreviewUrl?: string;

  imageLoaded: boolean = false;
  isImageError: boolean = false;

  @Input() title?: string;
  @Input() publishDate?: string;
  @Input() numberOfProducts?: number;
  @Input() tags?: (string | undefined)[];
  @Input() bigTag?: string | undefined;

  previewUrl?: string;

  bigTagMapping: { [key: string]: string } = {
    Ekskluzivno: 'big-tag-exclusive.svg',
    'Posebna ponuda': 'big-tag-special-offer.svg',
    Vremeplov: 'big-tag-timemachine.svg',
    'Novi sadržaj': 'big-tag-new-in-gallery.svg',
  };
  bigTagElement?: string;

  constructor(
    private http: HttpClient,
    private galleryGroupService: GalleryGroupService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLanguage = this.translateService.currentLang;

    this.currentLanguageSubscription =
      this.translateService.onLangChange.subscribe(
        (params: LangChangeEvent) => {
          this.currentLanguage = params.lang;
        }
      );
  }

  ngOnDestroy() {
    this.currentLanguageSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.previewUrl = this.getPreviewUrl();

    if (this.importItem != undefined && this.importItem.tag != undefined) {
      let tagName = this.importItem.tag.title;
      this.bigTagElement = this.bigTagMapping[tagName];
    }
  }

  getPreviewUrl(): string {
    if (this.importItem?.previewUrl == undefined) return '/assets/no-image.svg';

    return environment.productServingUrl + this.importItem?.previewUrl ?? '';
  }

  setAsGalleryHead($event: MouseEvent) {
    $event.preventDefault();

    this.galleryGroupService.setAsGalleryHead(this.importItem!);
  }
}
