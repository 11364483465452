<button
  class="import-button"
  (click)="buttonDidClick($event)"
  [style.color]="foregroundColor"
  [style.backgroundColor]="disabled ? disabledBackgroundColor : backgroundColor"
>
  {{ title }}
  <div
    class="import-button-dropdown"
    #actionsToggleEl
    *ngIf="actions.length > 0"
    (click)="openActions()"
  >
    <img src="/assets/action-button-dropdown.svg" alt="" />
  </div>
</button>

<ul
  #actionsEl
  class="field-autocomplete"
  style="position: absolute; top: 0px; left: 0px"
  [style.visibility]="shouldShowActionDropdown ? 'visible' : 'hidden'"
  [style.width.px]="actionsDropdownWidth"
>
  <li *ngFor="let action of actions" (mousedown)="onActionClick(action)">
    {{ action.title }}
  </li>
</ul>
