import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  CMSGalleriesService as ApiGalleriesService,
  GalleryGroupsService as ApiGalleryGroupsService,
  IdsList,
  MediaItem,
  ProductsService as ApiProductsService,
  Translatable,
  UploadSessionPublishRequest,
  UploadSessionsService,
} from 'piwe-front-swagger-client';
import { AutocompleteOption } from '../../../shared/field-text/field-text.component';
import {
  Agency,
  Author,
  Category,
  ErrorResponse,
  GalleryGroupItem,
  GalleryGroupList,
  Keyword,
  LicenceType,
  Person,
  SpecialOfferType,
  Tag,
} from '../../../../../projects/piwe-front-swagger-client/src';
import {
  FIELD_AGENCY,
  FIELD_API_FTP,
  FIELD_ARTICLE_AUTHOR,
  FIELD_ARTICLE_CONTENT,
  FIELD_ARTICLE_TITLE,
  FIELD_AUTHOR,
  FIELD_CAPTION,
  FIELD_CAPTION_EN,
  FIELD_CATEGORIES,
  FIELD_DATE_CREATED,
  FIELD_DATE_PUBLISHED,
  FIELD_FEATURED,
  FIELD_HEADLINE,
  FIELD_HEADLINE_EN,
  FIELD_ID,
  FIELD_KEYWORDS,
  FIELD_KEYWORDS_EN,
  FIELD_LICENCE,
  FIELD_NEEDS_TRANSLATION_EN,
  FIELD_PEOPLE,
  FIELD_PRINT_PRICE,
  FIELD_SPECIAL_OFFER,
  FIELD_SPECIAL_OFFER_TYPE,
  FIELD_TAG,
  FIELD_WEB_PRICE,
  FIELDS,
  FieldType,
  MultipleItemEditorService,
} from '../../../shared/multiple-item-editor/multiple-item-editor.service';
import {
  FieldChangedEvent,
  IMultipleItemEditorService,
} from '../../../shared/multiple-item-editor/multiple-item-editor.component';
import { ProductsService } from '../products/products.service';
import { GalleryGroupImportItem } from './gallery-group.component';
import { GalleriesService } from '../galleries/galleries.service';
import { GalleryImportItem } from '../galleries/galleries.component';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { LoadingService } from '../../../shared/loading.service';
import { SnackBarService } from '../../../share/snack-bar.service';

type SessionAttributes = {
  page?: number;
  fromDate?: string;
  toDate?: string;
  originalName?: string;
  authors?: AutocompleteOption[];
  importDate?: string;
  downloaded?: AutocompleteOption;
  agencies?: AutocompleteOption[];
  galleryId?: string;
  contentType?: AutocompleteOption;
  productStatus?: AutocompleteOption;
  productId?: string;
  operators?: AutocompleteOption[];
  tags?: AutocompleteOption[];
  categories?: AutocompleteOption[];
  bigSearchPerson?: boolean;
  bigSearchDescription?: boolean;
  bigSearchKeywords?: boolean;
  bigSearch?: string;
};

@Injectable({
  providedIn: 'root',
})
export class GalleryGroupService
  extends MultipleItemEditorService<GalleryGroupImportItem>
  implements IMultipleItemEditorService
{
  constructor(
    public apiGalleriesService: ApiGalleriesService,
    public apiGalleryGroupService: ApiGalleryGroupsService,
    public apiProductsService: ApiProductsService,
    public uploadService: UploadSessionsService,
    public productService: ProductsService,
    public galleryService: GalleriesService,
    private loadingService: LoadingService,
    private snackBarService: SnackBarService
  ) {
    super();

    this.items = [];
    this.itemsById = {};
    this.unsavedItemsIds = [];

    this.fieldSources = {};
    this.fieldSources[FIELD_ID.name] = this.editingIdSource;
    this.fieldSources[FIELD_HEADLINE.name] = this.editingHeadlineSource;
    this.fieldSources[FIELD_HEADLINE_EN.name] = this.editingHeadlineEnSource;
    this.fieldSources[FIELD_CAPTION.name] = this.editingCaptionSource;
    this.fieldSources[FIELD_PEOPLE.name] = this.editingPeopleSource;
    this.fieldSources[FIELD_KEYWORDS.name] = this.editingKeywordsSource;
    this.fieldSources[FIELD_CATEGORIES.name] = this.editingCategoriesSource;
    this.fieldSources[FIELD_AUTHOR.name] = this.editingAuthorSource;
    this.fieldSources[FIELD_AGENCY.name] = this.editingAgencySource;
    this.fieldSources[FIELD_TAG.name] = this.editingTagSource;
    this.fieldSources[FIELD_SPECIAL_OFFER.name] =
      this.editingSpecialOfferSource;
    this.fieldSources[FIELD_SPECIAL_OFFER_TYPE.name] =
      this.editingSpecialOfferTypeSource;
    this.fieldSources[FIELD_API_FTP.name] = this.editingApiFtpSource;
    this.fieldSources[FIELD_LICENCE.name] = this.editingLicenceSource;
    this.fieldSources[FIELD_DATE_CREATED.name] = this.editingDateCreatedSource;
    this.fieldSources[FIELD_NEEDS_TRANSLATION_EN.name] =
      this.editingNeedsTranslationEnSource;
    this.fieldSources[FIELD_CAPTION_EN.name] = this.editingCaptionEnSource;
    this.fieldSources[FIELD_KEYWORDS_EN.name] = this.editingKeywordsEnSource;
    this.fieldSources[FIELD_PRINT_PRICE.name] = this.editingPrintPriceSource;
    this.fieldSources[FIELD_WEB_PRICE.name] = this.editingWebPriceSource;
    this.fieldSources[FIELD_DATE_PUBLISHED.name] =
      this.editingDatePublishedSource;

    this.fieldSources[FIELD_ARTICLE_TITLE.name] =
      this.editingArticleTitleSource;
    this.fieldSources[FIELD_ARTICLE_AUTHOR.name] =
      this.editingArticleAuthorSource;
    this.fieldSources[FIELD_ARTICLE_CONTENT.name] =
      this.editingArticleContentSource;

    this.fieldSources[FIELD_FEATURED.name] = this.featuredSource;

    this.setUpSaveSession();

    this.loadSessionSignalData
      .pipe(
        switchMap((options: any) => {
          this.isLoadedSource.next(false);

          /*
        // agency?: number
            undefined,
            // author?: number
            undefined,
            // category?: number
            undefined,
            // downloaded?: boolean
            undefined,
            // from_date?: string
            this.formatOptionalDate(options.fromDate),
            // gallery_group_id?: number
            undefined,
            // operater?: number
            undefined,
            // page?: number
            options.page!,
            // page_size?: number
            undefined,
            // tags?: Array<number>
            undefined,
            // text?: number
            options["largeSearchValue"],
            // to_date?: string
            this.formatOptionalDate(options.toDate),
            //  search_text_person?: boolean
            options["bigSearchPersonValue"] ?? true,
            //  search_text_description?: boolean
            options["bigSearchDescriptionValue"] ?? true,
            //  search_text_keywords?: boolean
            options["bigSearchKeywordsValue"] ?? true,
         */

          // console.log("SEARCH DATA ", options);
          return this.apiGalleryGroupService.cmsGetGalleryGroups(
            options.page,
            50,
            options.largeSearchValue,
            true,
            options.bigSearchPerson ?? true,
            options.bigSearchDescription ?? true,
            options.bigSearchKeywords ?? true,
            this.formatOptionalDate(options.fromDateValue),
            this.formatOptionalDate(options.toDateValue)
          );
        })
      )
      .subscribe((result) => {
        this.isLoadedSource.next(true);

        this.unserializedGalleryGroupList = result.gallery_group_list;
        const newItems = GalleryGroupService.deserializeProducts(result);
        // let difference = newItems.filter(x => !this.items.map(i => i.id).includes(x.id));

        this.itemsById = [];
        newItems.forEach((item) => {
          this.itemsById[item.id] = item;
        });

        this.items = newItems;

        this.itemsSource.next(this.items);
      });

    GalleryGroupService.markAsDirtySource.asObservable().subscribe((ids) => {
      this.markAsDirty(ids);
    });
  }
  static markAsDirtySource = new Subject<GalleryGroupImportItem[]>();

  private files: any[] = [];

  private unsavedItemsIds: number[] = [];

  private isUnsavedSource = new Subject<boolean>();
  public isUnsavedData: Observable<boolean> =
    this.isUnsavedSource.asObservable();

  private isEditingDisabledSource = new Subject<boolean>();
  public isEditingDisabledData: Observable<boolean> =
    this.isEditingDisabledSource.asObservable();

  private requestFileReplaceSource = new Subject<number>();
  public requestFileReplaceData: Observable<number> =
    this.requestFileReplaceSource.asObservable();

  private uploadingFilesSource = new Subject<any[]>();
  public uploadingFilesData: Observable<any[]> =
    this.uploadingFilesSource.asObservable();

  private itemsSource = new Subject<GalleryGroupImportItem[]>();
  public itemsData: Observable<GalleryGroupImportItem[]> =
    this.itemsSource.asObservable();

  private selectedIdsSource = new Subject<number[]>();
  public selectedIdsData: Observable<number[]> =
    this.selectedIdsSource.asObservable();

  private loadSessionSignalSource = new Subject<SessionAttributes>();
  public loadSessionSignalData: Observable<SessionAttributes> =
    this.loadSessionSignalSource.asObservable();

  private afterSaveSource = new Subject<(shouldRefresh: boolean) => void>();
  public afterSaveData: Observable<(shouldRefresh: boolean) => void> =
    this.afterSaveSource.asObservable();

  private setAsGalleryHeadEventSource = new Subject<GalleryImportItem>();
  public setAsGalleryHeadEventData: Observable<GalleryImportItem> =
    this.setAsGalleryHeadEventSource.asObservable();

  public unserializedGalleryGroupList!: GalleryGroupItem[];

  fieldSources: { [key: string]: Subject<any> } = {};

  /////////////////////////////
  // Fields
  /////////////////////////////

  private editingHeadlineSource = new Subject<AutocompleteOption | undefined>();
  public editingHeadlineData: Observable<AutocompleteOption | undefined> =
    this.editingHeadlineSource.asObservable();

  private editingHeadlineEnSource = new Subject<
    AutocompleteOption | undefined
  >();
  public editingHeadlineEnData: Observable<AutocompleteOption | undefined> =
    this.editingHeadlineEnSource.asObservable();

  private editingIdSource = new Subject<string | undefined>();
  public editingIdData: Observable<string | undefined> =
    this.editingIdSource.asObservable();

  private editingCaptionSource = new Subject<string | undefined>();
  public editingCaptionData: Observable<string | undefined> =
    this.editingCaptionSource.asObservable();

  private editingPeopleSource = new Subject<AutocompleteOption[] | undefined>();
  public editingPeopleData: Observable<AutocompleteOption[] | undefined> =
    this.editingPeopleSource.asObservable();

  private editingKeywordsSource = new Subject<
    AutocompleteOption[] | undefined
  >();
  public editingKeywordsData: Observable<AutocompleteOption[] | undefined> =
    this.editingKeywordsSource.asObservable();

  private editingCategoriesSource = new Subject<
    AutocompleteOption | undefined
  >();
  public editingCategoriesData: Observable<AutocompleteOption | undefined> =
    this.editingCategoriesSource.asObservable();

  private editingAuthorSource = new Subject<AutocompleteOption | undefined>();
  public editingAuthorData: Observable<AutocompleteOption | undefined> =
    this.editingAuthorSource.asObservable();

  private editingAgencySource = new Subject<AutocompleteOption | undefined>();
  public editingAgencyData: Observable<AutocompleteOption | undefined> =
    this.editingAgencySource.asObservable();

  private editingTagSource = new Subject<AutocompleteOption | undefined>();
  public editingTagData: Observable<AutocompleteOption | undefined> =
    this.editingTagSource.asObservable();

  private editingSpecialOfferSource = new Subject<boolean>();
  public editingSpecialOfferData: Observable<boolean> =
    this.editingSpecialOfferSource.asObservable();

  private editingSpecialOfferTypeSource = new Subject<
    AutocompleteOption[] | undefined
  >();
  public editingSpecialOfferTypeData: Observable<
    AutocompleteOption[] | undefined
  > = this.editingSpecialOfferTypeSource.asObservable();

  private editingApiFtpSource = new Subject<boolean>();
  public editingApiFtpData: Observable<boolean> =
    this.editingApiFtpSource.asObservable();

  private editingLicenceSource = new Subject<string | undefined>();
  public editingLicenceData: Observable<string | undefined> =
    this.editingLicenceSource.asObservable();

  private editingDateCreatedSource = new Subject<string | undefined>();
  public editingDateCreatedData: Observable<string | undefined> =
    this.editingDateCreatedSource.asObservable();

  private editingNeedsTranslationEnSource = new Subject<boolean>();
  public editingNeedsTranslationEnData: Observable<boolean> =
    this.editingNeedsTranslationEnSource.asObservable();

  private editingCaptionEnSource = new Subject<string | undefined>();
  public editingCaptionEnData: Observable<string | undefined> =
    this.editingCaptionEnSource.asObservable();

  private editingKeywordsEnSource = new Subject<
    AutocompleteOption[] | undefined
  >();
  public editingKeywordsEnData: Observable<AutocompleteOption[] | undefined> =
    this.editingKeywordsEnSource.asObservable();

  private editingWebPriceSource = new Subject<number | undefined>();
  public editingWebPriceData: Observable<number | undefined> =
    this.editingWebPriceSource.asObservable();

  private editingPrintPriceSource = new Subject<number | undefined>();
  public editingPrintPriceData: Observable<number | undefined> =
    this.editingPrintPriceSource.asObservable();

  private editingArticleTitleSource = new Subject<string | undefined>();
  public editingArticleTitleData: Observable<string | undefined> =
    this.editingArticleTitleSource.asObservable();

  private editingArticleAuthorSource = new Subject<
    AutocompleteOption | undefined
  >();
  public editingArticleAuthorData: Observable<AutocompleteOption | undefined> =
    this.editingArticleAuthorSource.asObservable();

  private editingArticleContentSource = new Subject<string | undefined>();
  public editingArticleContentData: Observable<string | undefined> =
    this.editingArticleContentSource.asObservable();

  private schedulePublishDialogSource = new Subject<void>();
  public schedulePublishDialogData: Observable<void> =
    this.schedulePublishDialogSource.asObservable();

  private schedulePublishDialogDoneSource = new Subject<string>();
  public schedulePublishDialogDoneData: Observable<string> =
    this.schedulePublishDialogDoneSource.asObservable();

  private editingDatePublishedSource = new Subject<string | undefined>();
  public editingDatePublishedData: Observable<string | undefined> =
    this.editingDatePublishedSource.asObservable();

  private featuredSource = new Subject<boolean>();
  public featuredData: Observable<boolean> = this.featuredSource.asObservable();

  private errorResponseSource = new Subject<ErrorResponse | undefined>();
  public errorResponseData: Observable<ErrorResponse | undefined> =
    this.errorResponseSource.asObservable();

  private errorIdsSource = new Subject<number[]>();
  public errorIdsData: Observable<number[]> =
    this.errorIdsSource.asObservable();

  private saveQueueSource = new Subject<(() => void) | undefined>();
  public saveQueueData: Observable<(() => void) | undefined> =
    this.saveQueueSource.asObservable();

  private savingStatusSource = new BehaviorSubject<boolean>(false);
  public saveStatusData: Observable<boolean> =
    this.savingStatusSource.asObservable();

  private selectAllSource = new Subject<void>();
  public selectAllData: Observable<void> = this.selectAllSource.asObservable();

  private fieldChangeSource = new Subject<FieldChangedEvent>();
  public fieldChangeData: Observable<FieldChangedEvent> =
    this.fieldChangeSource.asObservable();

  public static parseDate(date: string | undefined): string | undefined {
    if (date == undefined || date == null) {
      return undefined;
    }

    return date.split('T')[0];
  }

  public static deserializeProduct(
    uploadSessionItem: GalleryGroupItem
  ): GalleryGroupImportItem {
    const width = 1;
    const height = 1;

    let agency: AutocompleteOption | undefined;
    if (uploadSessionItem.agency != undefined) {
      agency = {
        title: uploadSessionItem.agency!.name!,
        value: uploadSessionItem.agency!.id!,
        isSelectable: true,
      };
    }

    let author: AutocompleteOption | undefined;
    if (uploadSessionItem.author != undefined) {
      author = {
        title: uploadSessionItem.author.name,
        value: uploadSessionItem.author.id,
        isSelectable: true,
      };
    }

    let categories: AutocompleteOption | undefined;

    if (uploadSessionItem.category != undefined) {
      categories = {
        title:
          uploadSessionItem.category.name.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',
        value: uploadSessionItem.category.id!,
        isSelectable: true,
      };
    }

    let articleAuthor: AutocompleteOption | undefined;
    if (uploadSessionItem.article_author != undefined) {
      author = {
        title: uploadSessionItem.article_author.name,
        value: uploadSessionItem.article_author.id,
        isSelectable: true,
      };
    }

    /*let licenceType: AutocompleteOption | undefined = undefined;
    if (uploadSessionItem.licence_type != undefined)
      licenceType = {
        title: uploadSessionItem.licence_type.name,
        value: uploadSessionItem.licence_type.id,
        isSelectable: true
      }*/
    if (uploadSessionItem.agency != undefined) {
      agency = {
        title: uploadSessionItem.agency!.name!,
        value: uploadSessionItem.agency!.id!,
        isSelectable: true,
      };
    }

    let specialOfferTypes: AutocompleteOption[] | undefined;
    if (uploadSessionItem.special_offer_types != undefined) {
      specialOfferTypes = uploadSessionItem.special_offer_types.map((sp) => {
        return {
          title:
            sp.name.find((t: Translatable) => t.language == 'HR')?.content ??
            '',
          value: sp.id,
          isSelectable: true,
        };
      });
    }

    let tag: AutocompleteOption | undefined;

    if (
      uploadSessionItem.tag_list != undefined &&
      uploadSessionItem.tag_list.length != 0
    ) {
      tag = {
        title:
          uploadSessionItem.tag_list[0].name.find(
            (t: Translatable) => t.language == 'HR'
          )?.content ?? '',
        value: uploadSessionItem.tag_list[0].id,
        isSelectable: true,
      };
    }

    let items: GalleryImportItem[] | undefined;
    if (uploadSessionItem.full_gallery_list != undefined) {
      items = uploadSessionItem.full_gallery_list?.map((p) =>
        GalleriesService.serverSchemaToClientSchema(p as any)
      );
    } else {
      items = uploadSessionItem.gallery_list?.map((p) =>
        GalleriesService.serverSchemaToClientSchema(p as any)
      );
    }

    const object: GalleryGroupImportItem = {
      id: uploadSessionItem.id!,
      width,
      height,

      // aspectRatio: uploadSessionItem.aspect_ratio,
      // previewUrl: uploadSessionItem.preview_url,
      // blurPreviewUrl: uploadSessionItem.preview_url_blur,
      // largePreviewUrl: uploadSessionItem.preview_url_large,

      // isVideo: uploadSessionItem.kind == "VIDEO",
      // isLocked: this.checkedIsLocked(uploadSessionItem),
      // lockedBy: this.lockedByName(uploadSessionItem),

      previewUrl: this.computePreview(uploadSessionItem),

      title:
        uploadSessionItem.headline?.find(
          (t: Translatable) => t.language == 'HR'
        )?.content ?? '',
      headline: {
        title: this.getHeadline(uploadSessionItem),
        value: -1,
        isSelectable: true,
      },

      caption:
        uploadSessionItem.caption?.find((t: Translatable) => t.language == 'HR')
          ?.content ?? '',
      people: uploadSessionItem.people_list?.map((p) => {
        return {
          title: p.name,
          value: p.id,
          isSelectable: true,
        };
      }),
      keywords: uploadSessionItem.keywords_list?.map((k) => {
        return {
          title:
            k.name?.find((t: Translatable) => t.language == 'HR')?.content ??
            '',
          value: k.id,
          isSelectable: true,
        };
      }),

      categories,
      author,
      agency,
      tag,
      specialOffer: uploadSessionItem.is_special_offer,
      specialOfferTypes,
      // apiFtp: uploadSessionItem.api_ftp,
      // icence: licenceType,

      printPrice: uploadSessionItem.print_price,
      webPrice: uploadSessionItem.web_price,

      items,

      articleAuthor,
      articleContent: uploadSessionItem.article_content,
      articleTitle: uploadSessionItem.article_title,

      // dateCreated: uploadSessionItem.date_created,
      // needsTranslationEn: uploadSessionItem.needs_translation,
      captionEn:
        uploadSessionItem.caption?.find((t: Translatable) => t.language == 'EN')
          ?.content ?? '',
      keywordsEn: uploadSessionItem.keywords_list?.map((k) => {
        return {
          title:
            k.name?.find((t: Translatable) => t.language == 'EN')?.content ??
            '',
          value: k.id,
          isSelectable: true,
        };
      }),

      publishState: uploadSessionItem.publish_state,
      publishDate: uploadSessionItem.publish_date,
      dateCreated: uploadSessionItem.created_date,

      totalCount: uploadSessionItem.total_count,
    };

    return object;
  }

  public static deserializeProducts(
    products: GalleryGroupList
  ): GalleryGroupImportItem[] {
    return products.gallery_group_list!.map((gg) => {
      return GalleryGroupService.deserializeProduct(gg);
    });
  }

  static getHeadline(mediaItem: GalleryGroupItem): string {
    if (mediaItem.headline == undefined) {
      return '';
    }

    if (mediaItem.headline!.length == 0) {
      return '';
    }

    return mediaItem.headline![0].content ?? '';
  }

  static computePreview(
    uploadSessionItem: GalleryGroupItem
  ): string[] | undefined {
    if (uploadSessionItem.previews != undefined) {
      return uploadSessionItem.previews.map((p) => p.preview_url!);
    }

    return undefined;
  }

  public static computePreviewGalleryImportItem(
    galleryGroupImportItem: GalleryGroupImportItem
  ) {
    return galleryGroupImportItem.items?.map(
      (i) => GalleriesService.computePreviewGalleryImportItem(i) ?? ''
    );
  }

  public getIsLoaded(): Observable<boolean> {
    return super.getIsLoaded();
  }

  setAsGalleryHead(importItem: GalleryImportItem) {
    this.setAsGalleryHeadEventSource.next(importItem);
  }

  requestFileReplace(id: number) {
    this.requestFileReplaceSource.next(id);
  }

  private resolveAutocompleteValue(
    type: string,
    value: AutocompleteOption | undefined
  ): any | undefined {
    if (value == undefined) {
      return undefined;
    }

    switch (type) {
      case 'downloaded':
        switch (value.value) {
          case 1:
            return true;
          default:
            return false;
        }

      case 'productStatus':
        switch (value.value) {
          case 1:
            return 'PUBLISHED';
          default:
            return 'NOT_PUBLISHED';
        }

      case 'contentType':
        switch (value.value) {
          case 1:
            return 'PHOTO';
          default:
            return 'VIDEO';
        }
    }
  }

  formatOptionalDate(value: any | undefined) {
    if (value == undefined) {
      return undefined;
    }

    return formatDate(value, 'yyyy-MM-dd', 'en-US');
  }

  loadSession(page: number, options: any) {
    this.items = [];
    this.itemsById = {};
    this.unsavedItemsIds = [];

    this.isUnsavedSource.next(false);

    this.itemsSource.next(this.items);

    this.selectedIds = [];
    this.selectedIdsSource.next([]);
    this.loadDataForSelectedItem();

    if (!(options.bigSearch?.length ?? 0)) {
      options.bigSearch = undefined;
    }

    this.loadSessionSignalSource.next({
      ...options,
      page,
    });
  }

  selectItems(itemIds: number[]) {
    this.selectedIds = itemIds;
    this.selectedIdsSource.next(itemIds);
    this.loadDataForSelectedItem();
  }

  loadDataForSelectedItem() {
    const isAnySelectedLocked = this.selectedIds
      .map((id) => this.itemsById[id].isLocked)
      .reduce((sum, next) => sum || next, false);
    this.isEditingDisabledSource.next(isAnySelectedLocked);

    for (const field of FIELDS) {
      const source = this.fieldSources[field.name];
      let value: any;

      if (source == undefined) {
        console.warn(
          `Source is missing for field '${field.name}', please add the source to this.fieldSources if you want to use this field.`
        );
        continue;
      }

      switch (field.fieldType) {
        case FieldType.String:
          value = this.computeFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name)) {
              return undefined;
            }

            let key = field.name;

            // @ts-ignore
            let value = i[key] as any;

            if (key == 'datePublished') {
              key = 'publishDate';

              // @ts-ignore
              value = i[key] as any;

              if (value !== null && value !== undefined) {
                value = moment(value).format('YYYY-MM-DD');
              } else {
                value = '';
              }
            }

            return value;
          });
          break;
        case FieldType.Number:
          value = this.computeNumberFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name)) {
              return undefined;
            }

            // @ts-ignore
            return i[field.name] as number;
          });
          break;
        case FieldType.Boolean:
          value = this.computeBooleanFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name)) {
              return undefined;
            }

            // @ts-ignore
            return i[field.name] as boolean;
          });
          break;
        case FieldType.Autocomplete:
          value = this.computeAutocompleteFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name)) {
              return undefined;
            }

            // @ts-ignore
            return i[field.name] as AutocompleteOption;
          });
          break;
        case FieldType.MultipleAutocomplete:
          value = this.computeMultipleAutocompleteFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name)) {
              return undefined;
            }

            // @ts-ignore
            return i[field.name] as AutocompleteOption[];
          });
          break;
        case FieldType.Date:
          value = this.computeDateFieldValue((i) => {
            if (i == undefined || !i.hasOwnProperty(field.name)) {
              return undefined;
            }

            // @ts-ignore
            return i[field.name] as string;
          });
          break;
      }

      this.fieldChangeSource.next({
        field: field.name,
        value,
      });

      source.next(value);
    }

    this.calculateSuffix();
  }

  finishSchedulePublishDialog(value: string) {
    this.schedulePublishDialogDoneSource.next(value);
  }

  openSchedulePublishDialog() {
    this.schedulePublishDialogSource.next();
  }

  markAsDirty(products: GalleryGroupImportItem[]) {
    for (const product of products) {
      if (!this.unsavedItemsIds.includes(product.id)) {
        this.unsavedItemsIds.push(product.id);
      }
    }
    this.isUnsavedSource.next(true);
  }

  markAllNotDirty() {
    this.unsavedItemsIds = [];
    this.isUnsavedSource.next(false);
  }

  setField(field: string, value: any) {
    let setter: (item: GalleryGroupImportItem) => void;

    if (this.selectedIds.length) {
      switch (field) {
        case 'headline':
          setter = (item: GalleryGroupImportItem) => (item.headline = value);
          break;

        case 'caption':
          setter = (item: GalleryGroupImportItem) => (item.caption = value);
          break;

        case 'people':
          setter = (item: GalleryGroupImportItem) => (item.people = value);
          break;

        case 'keywords':
          setter = (item: GalleryGroupImportItem) => (item.keywords = value);
          break;

        case 'categories':
          setter = (item: GalleryGroupImportItem) => (item.categories = value);
          break;

        case 'author':
          setter = (item: GalleryGroupImportItem) => (item.author = value);
          break;

        case 'agency':
          setter = (item: GalleryGroupImportItem) => (item.agency = value);
          break;

        case 'tag':
          setter = (item: GalleryGroupImportItem) => (item.tag = value);
          break;

        case 'specialOffer':
          setter = (item: GalleryGroupImportItem) =>
            (item.specialOffer = value);
          break;

        case 'specialOfferTypes':
          setter = (item: GalleryGroupImportItem) =>
            (item.specialOfferTypes = value);
          break;

        case 'apiFtp':
          setter = (item: GalleryGroupImportItem) => (item.apiFtp = value);
          break;

        case 'licence':
          setter = (item: GalleryGroupImportItem) => (item.licence = value);
          break;

        case 'dateCreated':
          setter = (item: GalleryGroupImportItem) => (item.dateCreated = value);
          break;

        case 'needsTranslationEn':
          setter = (item: GalleryGroupImportItem) =>
            (item.needsTranslationEn = value);
          break;

        case 'captionEn':
          setter = (item: GalleryGroupImportItem) => (item.captionEn = value);
          break;

        case 'keywordsEn':
          setter = (item: GalleryGroupImportItem) => (item.keywordsEn = value);
          break;

        case 'webPrice':
          setter = (item: GalleryGroupImportItem) => (item.webPrice = value);
          break;

        case 'printPrice':
          setter = (item: GalleryGroupImportItem) => (item.printPrice = value);
          break;

        case 'articleTitle':
          setter = (item: GalleryGroupImportItem) =>
            (item.articleTitle = value);
          break;

        case 'articleAuthor':
          setter = (item: GalleryGroupImportItem) =>
            (item.articleAuthor = value);
          break;

        case 'articleContent':
          setter = (item: GalleryGroupImportItem) =>
            (item.articleContent = value);
          break;

        case 'publishDate':
          setter = (item: GalleryGroupImportItem) => (item.publishDate = value);
          break;
      }
    }

    const selectedItems = this.selectedIds.map((id) => this.itemsById[id]);
    selectedItems.forEach((item) => {
      // console.log("Setting value", item, value, this.itemsById);

      setter(item);
    });
    selectedItems.forEach((item) => {
      if (!this.unsavedItemsIds.includes(item.id)) {
        this.lockGalleryGroupImportItems([item]);
        this.unsavedItemsIds.push(item.id);
      }
    });

    this.isUnsavedSource.next(true);

    this.calculateSuffix();
  }

  /////////////////////////////
  // Saving
  /////////////////////////////

  checkedIsLocked(mediaItem: MediaItem) {
    if (mediaItem.editing_lock != undefined) {
      const raw: string =
        (mediaItem.editing_lock.locked_at! as unknown as string) + 'Z';
      const lockedAt = new Date(raw);
      const newDate = new Date();

      if (lockedAt != undefined) {
        const timeDiff = newDate.getTime() - lockedAt!.getTime();

        // More than 5 minutes.
        return timeDiff < 15 * 1000;
      }

      return;
    }

    return false;
  }

  lockedByName(mediaItem: MediaItem): string | undefined {
    if (this.checkedIsLocked(mediaItem)) {
      return (
        mediaItem.editing_lock!.locked_by!.first_name +
        ' ' +
        mediaItem.editing_lock!.locked_by!.last_name
      );
    }

    return undefined;
  }

  computePreviewGalleryGroupImportItem(
    gallery: GalleryGroupImportItem
  ): string | undefined {
    if (gallery.items?.length ?? 0 > 1) {
      return gallery.items![0].previewUrl;
    }

    return undefined;
  }

  createEmptyGallery() {
    const now = new Date();
    const utcMilllisecondsSinceEpoch =
      now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    const id = -utcMilllisecondsSinceEpoch;

    const gallery: GalleryGroupImportItem = {
      agency: undefined,
      apiFtp: false,
      author: undefined,
      caption: '',
      captionEn: '',
      categories: undefined,
      dateCreated: '',
      dateImported: '',
      headline: undefined,
      height: 0,
      id,
      imageCount: 0,
      videoDuration: '',
      isLoaded: false,
      items: [],
      keywords: [],
      keywordsEn: [],
      licence: '',
      needsTranslationEn: false,
      people: [],
      previewUrl: [],
      printPrice: 0,
      specialOffer: false,
      specialOfferTypes: undefined,
      tag: undefined,
      title: '',
      webPrice: 0,
      width: 0,
      publishState: 'AUTO_PUBLISH_AFTER_DATE',
      publishDate: undefined,
    };

    this.unsavedItemsIds.push(id);
    this.itemsById[id] = gallery;

    return gallery;
  }

  clientListSchemaToServerListSchema(
    sendProducts: boolean = true
  ): GalleryGroupList {
    const unsavedItems = this.unsavedItemsIds.map((id) => this.itemsById[id]);
    const galleryGroupItems: GalleryGroupItem[] = unsavedItems
      .map((item) => {
        if (item == undefined) {
          return undefined;
        }

        // Here we have to fill out such information and take in account
        // where we have a new upload item or if the upload item is something
        // from an old session.

        let agency: Agency | undefined;
        if (item.agency != undefined) {
          agency = {
            id: item.agency.value,
            name: item.agency.title,
          };
        }

        let author: Author | undefined;
        if (item.author != undefined) {
          author = {
            id: item.author.value,
            name: item.author.title,
          };
        }

        let category: Category | undefined;
        if (item.categories != undefined) {
          category = {
            id: item.categories.value,
            name: [],
          };
        }

        const keywordsList: Keyword[] = [];
        if (item.keywords != undefined) {
          item.keywords.forEach((k) => {
            keywordsList.push({
              id: k.value,
              name: [],
            });
          });
        }

        if (item.keywordsEn != undefined) {
          item.keywordsEn.forEach((k) => {
            keywordsList.push({
              id: k.value,
              name: [],
            });
          });
        }

        let licenceType: LicenceType | undefined;
        if (item.author != undefined) {
          author = {
            id: item.author.value,
            name: item.author.title,
          };
        }

        const peopleList: Array<Person> = [];
        if (item.people != undefined) {
          item.people.forEach((k) => {
            peopleList.push({
              id: k.value,
              name: k.title,
            });
          });
        }

        let specialOfferTypes: SpecialOfferType[] | undefined;
        if (item.specialOfferTypes != undefined) {
          specialOfferTypes = item.specialOfferTypes.map((so) => {
            return {
              id: so.value,
              name: [],
            };
          });
        }

        let tagList: Array<Tag> = [];
        if (item.tag != undefined) {
          tagList = [
            {
              id: item.tag!.value,
              name: [],
            },
          ];
        }

        let id: number | undefined = item.id;
        if (id < 0) {
          id = undefined;
        }

        let articleContent: string | undefined;
        if (item.articleContent?.length != 0) {
          articleContent = item.articleContent;
        }

        let articleAuthor: Author | undefined;
        if (item.articleAuthor != undefined) {
          articleAuthor = {
            id: item.articleAuthor.value,
            name: item.articleAuthor.title,
          };
        }

        let publishDate: string | undefined;
        if (item.publishDate != undefined) {
          publishDate = moment(item.publishDate).format('YYYY-MM-DDTHH:mm:ss');
        }

        const uploadItem: GalleryGroupItem = {
          // This is required, but not for sending to the server.

          id: id!,
          agency,
          author,
          caption: [
            { language: 'HR', content: item.caption },
            { language: 'EN', content: item.captionEn },
          ],
          category,
          headline: [{ language: 'HR', content: item.headline?.title }],
          is_special_offer: item.specialOffer ?? false,
          keywords_list: keywordsList,
          article_title: item.articleTitle,
          article_content: articleContent,
          article_author: articleAuthor,
          people_list: peopleList,
          special_offer_types: specialOfferTypes,
          tag_list: tagList,
          print_price: item.printPrice,
          web_price: item.webPrice,

          publish_state: item.publishState,
          publish_date: publishDate,
        };

        if (sendProducts) {
          uploadItem.gallery_list = item.items?.map((item) => {
            const product: any = {
              id: item.id,
            };

            return product;
          });
        }

        return uploadItem;
      })
      .filter((i) => i != undefined)
      .map((i) => i! as GalleryGroupItem);

    return {
      gallery_group_list: galleryGroupItems,
    };
  }

  private lockGalleryGroupImportItems(importItems: GalleryGroupImportItem[]) {
    const ids = importItems.map((item) => item.id);
    this.apiProductsService.cmsLockProducts({ ids }).subscribe((_) => {
      // alert("Done");
    });
  }

  public publishSession(
    kind: UploadSessionPublishRequest.KindEnum,
    isNewsletterPush: boolean,
    isNotificationPush: boolean
  ) {}

  public queueSaveSession(callback: (() => void) | undefined) {
    this.saveQueueSource.next(callback);
  }

  private setUpSaveSession() {
    this.saveQueueData.subscribe((callback) => {
      this.saveSession(callback);
    });
  }

  private saveSession(callback: (() => void) | undefined) {
    const isEditingGalleryGroup =
      this.galleryService.editingGalleryGroup != undefined;

    this.savingStatusSource.next(false);
    const productList = this.clientListSchemaToServerListSchema(
      isEditingGalleryGroup
    );
    this.apiGalleryGroupService.cmsPostGalleryGroup(productList).subscribe(
      (result) => {
        if (callback != undefined) {
          callback();
        }
      },
      (error) => {
        const errorResponse: ErrorResponse = error.error;
        this.errorResponseSource.next(errorResponse);
        this.errorIdsSource.next(
          errorResponse.errors.map((e) => parseInt(e.item_id ?? '-1'))
        );
        alert('Error saving. Please check all the marked items.');
      }
    );
  }

  /////////////////////////////
  // Uploading files
  /////////////////////////////

  public uploadFile(files: any, itemId: number) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      // TODO: Implement
      this.apiProductsService
        .cmsReplaceProductMedia(itemId, item, 'events', true)
        .subscribe((result: any) => {
          if (result.type == 1) {
            item.progress = (result.loaded / result.total) * 100.0;

            // It's finished.
            if (item.progress >= 100.0) {
              this.files = this.files.filter((file) => file !== item);
              this.uploadingFilesSource.next(this.files);

              window.location.reload();
            }
          }
        });
    }

    this.uploadingFilesSource.next(this.files);
  }

  /////////////////////////////
  // Reordering
  /////////////////////////////

  private move(array: any[], oldIndex: number, newIndex: number) {
    const temp = array[newIndex];
    array[newIndex] = array[oldIndex];
    array[oldIndex] = temp;
  }

  public moveItemFromIndexToIndex(oldIndex: number, newIndex: number) {
    this.move(this.items, oldIndex, newIndex);
    this.itemsSource.next(this.items);
  }

  /////////////////////////////
  // Deleting
  /////////////////////////////

  public deleteSelected() {
    if (
      prompt(
        `Type 'CONFIRM' to confirm you want to delete gallery group(s): ${this.selectedIds.join(
          ', '
        )}`
      ) != 'CONFIRM'
    ) {
      return;
    }

    const request: IdsList = {
      ids: this.selectedIds,
    };

    const toDeleteIds = this.selectedIds;

    this.loadingService.setLoading(true);
    this.apiGalleryGroupService.cmsDeleteGalleryGroups(request).subscribe(
      (result: any) => {
        this.items = this.items.filter((i) => !toDeleteIds.includes(i.id));
        toDeleteIds.forEach((id: number) => {
          delete this.itemsById[id];
        });

        this.selectedIds = this.selectedIds.filter(
          (id) => !toDeleteIds.includes(id)
        );
        this.selectedIdsSource.next(this.selectedIds);

        this.itemsSource.next(this.items);
        this.snackBarService.showSnackBar(
          'Successfully deleted gallery group(s).',
          'INFO'
        );
        this.loadingService.setLoading(false);
      },
      (error) => {
        this.snackBarService.showSnackBar(
          'Failed deleting gallery group(s).',
          'ERROR'
        );
        this.loadingService.setLoading(false);
      }
    );
  }

  /////////////////////////////
  // Actions
  /////////////////////////////
  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceInString(string: string, needle: string, withValue: string): string {
    const escapedRegexNeedle = this.escapeRegExp(needle);
    return string.replace(new RegExp(escapedRegexNeedle, 'gi'), withValue);
  }

  replace(
    field: string,
    what: AutocompleteOption,
    withWhat: AutocompleteOption
  ) {
    this.items.forEach((item) => {
      if (!this.selectedIds.includes(item.id)) {
        return;
      }

      let dirtyItem = false;

      switch (field) {
        case 'people':
          if (item.people?.find((p) => p.value == what.value)) {
            item.people = item.people.map((ao) => {
              if (ao.value == what.value) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          break;

        case 'keywords':
          if (item.keywords?.find((p) => p.title == what.title)) {
            item.keywords = item.keywords.map((ao) => {
              if (ao.title == what.title) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          if (item.keywordsEn?.find((p) => p.value == what.value)) {
            item.keywordsEn = item.keywordsEn.map((ao) => {
              if (ao.value == what.value) {
                dirtyItem = true;
                return withWhat;
              }

              return ao;
            });
          }

          break;

        case 'headline':
          if (item?.headline?.title.includes(what.title)) {
            dirtyItem = true;
            if (item.headline != undefined) {
              item.headline = {
                title: item.headline!.title.replace(what.title, withWhat.title),
                isSelectable: item.headline!.isSelectable,
                value: item.headline!.value,
              };
              item.title = item.headline!.title;
            }
          }

          break;

        case 'categories':
          if (item.categories?.value == what.value) {
            dirtyItem = true;
            item.categories = withWhat;
          }

          break;

        case 'author':
          if (item.author?.value == what.value) {
            dirtyItem = true;
            item.author = withWhat;
          }

          break;

        case 'agency':
          if (item.agency?.value == what.value) {
            dirtyItem = true;
            item.agency = withWhat;
          }

          break;

        case 'tag':
          if (item.tag?.value == what.value) {
            dirtyItem = true;
            item.tag = withWhat;
          }

          break;

        case 'caption':
          if (item.caption?.includes(what.title)) {
            dirtyItem = true;
            item.caption = item.caption?.replace(what.title, withWhat.title);
          }

          break;
      }

      if (dirtyItem) {
        this.markAsDirty([item]);
      }
    });
    this.loadDataForSelectedItem();
  }

  append(field: string, what: AutocompleteOption) {
    this.items.forEach((item) => {
      if (!this.selectedIds.includes(item.id)) {
        return;
      }

      switch (field) {
        case 'people':
          item.people?.push(what);

          break;

        case 'keywords':
          item.keywords?.push(what);

          break;

        case 'keywordsEn':
          item.keywordsEn?.push(what);

          break;

        case 'caption':
          this.itemsById[item.id].caption += what.title;
          break;
      }
    });
    this.markAsDirty(this.items);
    this.loadDataForSelectedItem();
  }

  cancel() {
    this.unsavedItemsIds = [];
    this.isUnsavedSource.next(false);
  }

  selectAll() {
    this.selectAllSource.next();
  }

  unselectAll() {}
}
