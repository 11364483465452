import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-detailed',
  templateUrl: './pagination-detailed.component.html',
  styleUrls: ['./pagination-detailed.component.scss'],
})
export class PaginationDetailedComponent implements OnInit {
  @Input() page: number = 1;
  @Input() totalPages: number = 1;
  @Input() totalItems: number = 0;

  @Output() nextPageEvent: EventEmitter<void> = new EventEmitter();
  @Output() previousPageEvent: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  nextPage() {
    this.nextPageEvent.emit();
  }
  previousPage() {
    this.previousPageEvent.emit();
  }
}
