<label for="{{ controlName }}">{{ (isRequired ? "* " : "") + label }}</label>
<div [formGroup]="form">
  <textarea
    name="{{ controlName }}"
    id="{{ controlName }}"
    formControlName="{{ controlName }}"
  >
  </textarea>

  <input
    type="text"
    name="photoBy"
    id="photoBy"
    formControlName="photoBy"
    placeholder="It will be filled automatically"
    [attr.disabled]="true"
  />
</div>
