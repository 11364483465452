/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Contract } from '../model/contract';
import { ContractList } from '../model/contractList';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class CMSContractsService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Create a company contract
   * @param company_id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsCreateCompanyContract(
    company_id: number,
    body?: Contract,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Contract>;
  public cmsCreateCompanyContract(
    company_id: number,
    body?: Contract,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Contract>>;
  public cmsCreateCompanyContract(
    company_id: number,
    body?: Contract,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Contract>>;
  public cmsCreateCompanyContract(
    company_id: number,
    body?: Contract,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (company_id === null || company_id === undefined) {
      throw new Error(
        'Required parameter company_id was null or undefined when calling cmsCreateCompanyContract.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Contract>(
      'post',
      `${this.basePath}/cms/companies/${encodeURIComponent(
        String(company_id)
      )}/contracts`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get company contracts
   * @param company_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetCompanyContracts(
    company_id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ContractList>;
  public cmsGetCompanyContracts(
    company_id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ContractList>>;
  public cmsGetCompanyContracts(
    company_id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ContractList>>;
  public cmsGetCompanyContracts(
    company_id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (company_id === null || company_id === undefined) {
      throw new Error(
        'Required parameter company_id was null or undefined when calling cmsGetCompanyContracts.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ContractList>(
      'get',
      `${this.basePath}/cms/companies/${encodeURIComponent(
        String(company_id)
      )}/contracts`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get contracts
   * @param page Pagination page
   * @param page_size Items per page
   * @param id
   * @param beginning_date
   * @param end_date
   * @param contract_name
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetContracts(
    page?: number,
    page_size?: number,
    id?: number,
    beginning_date?: string,
    end_date?: string,
    contract_name?: string,
    status?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ContractList>;
  public cmsGetContracts(
    page?: number,
    page_size?: number,
    id?: number,
    beginning_date?: string,
    end_date?: string,
    contract_name?: string,
    status?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ContractList>>;
  public cmsGetContracts(
    page?: number,
    page_size?: number,
    id?: number,
    beginning_date?: string,
    end_date?: string,
    contract_name?: string,
    status?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ContractList>>;
  public cmsGetContracts(
    page?: number,
    page_size?: number,
    id?: number,
    beginning_date?: string,
    end_date?: string,
    contract_name?: string,
    status?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (beginning_date !== undefined && beginning_date !== null) {
      queryParameters = queryParameters.set(
        'beginning_date',
        <any>beginning_date
      );
    }
    if (end_date !== undefined && end_date !== null) {
      queryParameters = queryParameters.set('end_date', <any>end_date);
    }
    if (contract_name !== undefined && contract_name !== null) {
      queryParameters = queryParameters.set(
        'contract_name',
        <any>contract_name
      );
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ContractList>(
      'get',
      `${this.basePath}/cms/contracts`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update a company contract
   * @param contract_id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsUpdateCompanyContract(
    contract_id: number,
    body?: Contract,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Contract>;
  public cmsUpdateCompanyContract(
    contract_id: number,
    body?: Contract,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Contract>>;
  public cmsUpdateCompanyContract(
    contract_id: number,
    body?: Contract,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Contract>>;
  public cmsUpdateCompanyContract(
    contract_id: number,
    body?: Contract,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (contract_id === null || contract_id === undefined) {
      throw new Error(
        'Required parameter contract_id was null or undefined when calling cmsUpdateCompanyContract.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Contract>(
      'put',
      `${this.basePath}/cms/companies/contracts/${encodeURIComponent(
        String(contract_id)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
