import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-gallery-group-card',
  templateUrl: './gallery-group-card.component.html',
  styleUrls: ['./gallery-group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryGroupCardComponent implements OnInit {
  @Input() cardData: any = {};

  constructor() {}

  ngOnInit(): void {}
}
