/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Invoice {
  id?: number;
  date?: string;
  total_no_tax?: number;
  total_with_tax?: number;
  alternative_total_no_tax?: number;
  alternative_total_with_tax?: number;
  currency?: string;
  status?: Invoice.StatusEnum;
  invoice_number?: string;
  payment_method?: Invoice.PaymentMethodEnum;
  euro_mode?: Invoice.EuroModeEnum;
  exchange_rate?: number;
}
export namespace Invoice {
  export type StatusEnum = 'PAID' | 'UNPAID';
  export const StatusEnum = {
    PAID: 'PAID' as StatusEnum,
    UNPAID: 'UNPAID' as StatusEnum,
  };
  export type PaymentMethodEnum = 'CARD' | 'KEKS_PAY' | 'PAYPAL' | 'DOWNLOAD';
  export const PaymentMethodEnum = {
    CARD: 'CARD' as PaymentMethodEnum,
    KEKSPAY: 'KEKS_PAY' as PaymentMethodEnum,
    PAYPAL: 'PAYPAL' as PaymentMethodEnum,
    DOWNLOAD: 'DOWNLOAD' as PaymentMethodEnum,
  };
  export type EuroModeEnum =
    | 'HRK_ONLY'
    | 'BIG_HRK_SMALL_EUR'
    | 'BIG_EUR_SMALL_HRK'
    | 'EUR_ONLY';
  export const EuroModeEnum = {
    HRKONLY: 'HRK_ONLY' as EuroModeEnum,
    BIGHRKSMALLEUR: 'BIG_HRK_SMALL_EUR' as EuroModeEnum,
    BIGEURSMALLHRK: 'BIG_EUR_SMALL_HRK' as EuroModeEnum,
    EURONLY: 'EUR_ONLY' as EuroModeEnum,
  };
}
