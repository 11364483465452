import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CMSService, ProductsService } from 'piwe-front-swagger-client';
import { LoadingService } from '../shared/loading.service';
import { SnackBarService } from '../share/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class IptcServiceService {
  private htmlResultsSource = new Subject<string>();
  public htmlResultsData: Observable<string> =
    this.htmlResultsSource.asObservable();

  constructor(
    private cmsService: CMSService,
    private productService: ProductsService,
    private loadingService: LoadingService,
    private snackBarService: SnackBarService
  ) {}

  showExifForProductId(productId: number) {
    this.loadingService.setLoading(true);

    this.productService.cmsGetProductIptc(productId).subscribe(
      (value) => {
        this.loadingService.setLoading(false);
        this.htmlResultsSource.next(value.html_response);
      },
      () => {
        this.loadingService.setLoading(false);
        this.snackBarService.showSnackBar('Something went wrong.', 'ERROR');
      }
    );
  }

  showExifForDownloadId(downloadId: number) {
    this.loadingService.setLoading(true);

    this.cmsService.cmsGetDownloadIptc(downloadId).subscribe(
      (value) => {
        this.loadingService.setLoading(false);
        this.htmlResultsSource.next(value.html_response);
      },
      () => {
        this.loadingService.setLoading(false);
        this.snackBarService.showSnackBar('Something went wrong.', 'ERROR');
      }
    );
  }
}
