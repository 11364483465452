import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum PreviewType {
  Grid = 1,
  Table,
  InlineEdit,
}

export enum ContentType {
  gallery = 'gallery',
  galleryGroup = 'galleryGroup',
  galleryDetailed = 'galleryDetailed',
}

@Component({
  selector: 'app-gallery-preview',
  templateUrl: './gallery-preview.component.html',
  styleUrls: ['./gallery-preview.component.scss'],
})
export class GalleryPreviewComponent implements OnInit {
  @Input() selectionMode: 'default' | 'single' = 'default';

  @Input() items: any = [];
  @Input() contentType: ContentType = ContentType.gallery;

  @Input() page: number = 1;
  @Input() totalPages: number = 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() previewType: PreviewType = PreviewType.Grid;
  @Output() previewTypeChange: EventEmitter<PreviewType> =
    new EventEmitter<PreviewType>();

  public selectedCards: number[] = [];
  @Output() selectedCardsChangedEvent: EventEmitter<number[]> =
    new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  changePreviewType(newPreviewType: string) {
    this.previewType = PreviewType[newPreviewType as keyof typeof PreviewType];
    this.previewTypeChange.emit(this.previewType);
  }

  previousPage() {
    this.page -= 1;
    this.pageChange.emit(this.page);
  }

  nextPage() {
    this.page += 1;
    this.pageChange.emit(this.page);
  }

  handleCardClick(index: number, $event: MouseEvent) {
    if (this.selectionMode === 'default') {
      if ($event.shiftKey && this.selectedCards.length) {
        this.selectedCards = handleShiftKey(
          index,
          new Set([...this.selectedCards])
        );
        this.selectedCardsChangedEvent.emit(this.selectedCards);
        return;
      }

      if ($event.ctrlKey || $event.metaKey) {
        const selectionExistsAtIndex = this.selectedCards.indexOf(index);

        if (selectionExistsAtIndex !== -1) {
          this.selectedCards.splice(selectionExistsAtIndex, 1);
        } else {
          this.selectedCards.push(index);
        }
        this.selectedCardsChangedEvent.emit(this.selectedCards);
        return;
      }

      this.selectedCards.length = 0;
      this.selectedCards = [index];
      this.selectedCardsChangedEvent.emit(this.selectedCards);
      return;
    }

    if (this.selectionMode === 'single') {
      const selectionExistsAtIndex = this.selectedCards.indexOf(index);

      if (selectionExistsAtIndex !== -1) {
        this.selectedCards.splice(selectionExistsAtIndex, 1);
      } else {
        this.selectedCards.length = 0;
        this.selectedCards = [index];
      }
      this.selectedCardsChangedEvent.emit(this.selectedCards);
      return;
    }

    ////////////////////////
    function handleShiftKey(
      clickedIndex: number,
      cardSet: Set<number>
    ): number[] {
      let firstIndex = Math.min(...cardSet) + 1;
      let lastIndex = Math.max(...cardSet) - 1;

      while (clickedIndex <= lastIndex) {
        cardSet.add(lastIndex);
        lastIndex--;
      }
      while (clickedIndex >= firstIndex) {
        cardSet.add(firstIndex);
        firstIndex++;
      }
      return [...cardSet];
    }
  }

  removeSelection() {
    this.selectedCards.length = 0;
    this.selectedCardsChangedEvent.emit(this.selectedCards);
  }
}
