import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
})
export class RadioInputComponent implements OnInit {
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() value: any = '';
  @Input() checked: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
