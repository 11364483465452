import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import logging from './logging';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAuthenticated = this.authenticationService.isAuthenticated();

    logging.debug('authentication.guard.ts', 'canActivate', route, state);

    if (isAuthenticated) {
      return true;
    } else {
      this.authenticationService.clearToken();

      this.router.navigate(['/login'], {
        queryParams: {
          redirect: state.url,
        },
      });
      return false;
    }
  }
}
