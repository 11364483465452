/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { IdsList } from '../model/idsList';
import { IptcHtmlResponse } from '../model/iptcHtmlResponse';
import { ProductList } from '../model/productList';
import { ProductUpdate } from '../model/productUpdate';
import { ProductUpdateItem } from '../model/productUpdateItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ProductsService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Delete products
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteProducts(
    body?: IdsList,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsDeleteProducts(
    body?: IdsList,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsDeleteProducts(
    body?: IdsList,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsDeleteProducts(
    body?: IdsList,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/products/delete`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get the product IPTC
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetProductIptc(
    productId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<IptcHtmlResponse>;
  public cmsGetProductIptc(
    productId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<IptcHtmlResponse>>;
  public cmsGetProductIptc(
    productId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<IptcHtmlResponse>>;
  public cmsGetProductIptc(
    productId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling cmsGetProductIptc.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<IptcHtmlResponse>(
      'get',
      `${this.basePath}/cms/products/${encodeURIComponent(
        String(productId)
      )}/iptc`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get products
   * @param page Page number
   * @param page_size Size of the page, result count. Default 50. Min 1, Max 100.
   * @param query Omnisearch text
   * @param query_include_headline General purpose search.
   * @param query_include_people General purpose search.
   * @param query_include_caption General purpose search.
   * @param query_include_keywords General purpose search.
   * @param date_from Search query parameters
   * @param date_to Search query parameters
   * @param product_status Integer of a specific product
   * @param agency_id Search query parameters
   * @param author_id Search query parameters
   * @param original_name Fuzzy search by original filename. Replaces previous parameter original_name_contains
   * @param content_type Search query parameters
   * @param by_operator_id Search query parameters
   * @param was_ever_downloaded Search query parameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetProducts(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    date_from?: string,
    date_to?: string,
    product_status?: string,
    agency_id?: number,
    author_id?: number,
    original_name?: string,
    content_type?: string,
    by_operator_id?: number,
    was_ever_downloaded?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductList>;
  public cmsGetProducts(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    date_from?: string,
    date_to?: string,
    product_status?: string,
    agency_id?: number,
    author_id?: number,
    original_name?: string,
    content_type?: string,
    by_operator_id?: number,
    was_ever_downloaded?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductList>>;
  public cmsGetProducts(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    date_from?: string,
    date_to?: string,
    product_status?: string,
    agency_id?: number,
    author_id?: number,
    original_name?: string,
    content_type?: string,
    by_operator_id?: number,
    was_ever_downloaded?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductList>>;
  public cmsGetProducts(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    date_from?: string,
    date_to?: string,
    product_status?: string,
    agency_id?: number,
    author_id?: number,
    original_name?: string,
    content_type?: string,
    by_operator_id?: number,
    was_ever_downloaded?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }
    if (
      query_include_headline !== undefined &&
      query_include_headline !== null
    ) {
      queryParameters = queryParameters.set(
        'query_include_headline',
        <any>query_include_headline
      );
    }
    if (query_include_people !== undefined && query_include_people !== null) {
      queryParameters = queryParameters.set(
        'query_include_people',
        <any>query_include_people
      );
    }
    if (query_include_caption !== undefined && query_include_caption !== null) {
      queryParameters = queryParameters.set(
        'query_include_caption',
        <any>query_include_caption
      );
    }
    if (
      query_include_keywords !== undefined &&
      query_include_keywords !== null
    ) {
      queryParameters = queryParameters.set(
        'query_include_keywords',
        <any>query_include_keywords
      );
    }
    if (date_from !== undefined && date_from !== null) {
      queryParameters = queryParameters.set('date_from', <any>date_from);
    }
    if (date_to !== undefined && date_to !== null) {
      queryParameters = queryParameters.set('date_to', <any>date_to);
    }
    if (product_status !== undefined && product_status !== null) {
      queryParameters = queryParameters.set(
        'product_status',
        <any>product_status
      );
    }
    if (agency_id !== undefined && agency_id !== null) {
      queryParameters = queryParameters.set('agency_id', <any>agency_id);
    }
    if (author_id !== undefined && author_id !== null) {
      queryParameters = queryParameters.set('author_id', <any>author_id);
    }
    if (original_name !== undefined && original_name !== null) {
      queryParameters = queryParameters.set(
        'original_name',
        <any>original_name
      );
    }
    if (content_type !== undefined && content_type !== null) {
      queryParameters = queryParameters.set('content_type', <any>content_type);
    }
    if (by_operator_id !== undefined && by_operator_id !== null) {
      queryParameters = queryParameters.set(
        'by_operator_id',
        <any>by_operator_id
      );
    }
    if (was_ever_downloaded !== undefined && was_ever_downloaded !== null) {
      queryParameters = queryParameters.set(
        'was_ever_downloaded',
        <any>was_ever_downloaded
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProductList>(
      'get',
      `${this.basePath}/cms/products`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Lock products
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsLockProducts(
    body?: IdsList,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsLockProducts(
    body?: IdsList,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsLockProducts(
    body?: IdsList,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsLockProducts(
    body?: IdsList,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/products/lock`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Replace Media
   * @param productId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsReplaceProductMedia(
    productId: number,
    body?: Object,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductUpdate>;
  public cmsReplaceProductMedia(
    productId: number,
    body?: Object,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductUpdate>>;
  public cmsReplaceProductMedia(
    productId: number,
    body?: Object,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductUpdate>>;
  public cmsReplaceProductMedia(
    productId: number,
    body?: Object,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling cmsReplaceProductMedia.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/octet-stream'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ProductUpdate>(
      'put',
      `${this.basePath}/cms/products/${encodeURIComponent(
        String(productId)
      )}/replace`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Save products
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsSaveProducts(
    body?: ProductUpdate,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsSaveProducts(
    body?: ProductUpdate,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsSaveProducts(
    body?: ProductUpdate,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsSaveProducts(
    body?: ProductUpdate,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/products`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Unlock products
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsUnlockProducts(
    body?: IdsList,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsUnlockProducts(
    body?: IdsList,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsUnlockProducts(
    body?: IdsList,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsUnlockProducts(
    body?: IdsList,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/products/unlock`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Update product
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsUpdateProducts(
    body?: ProductUpdate,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsUpdateProducts(
    body?: ProductUpdate,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsUpdateProducts(
    body?: ProductUpdate,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsUpdateProducts(
    body?: ProductUpdate,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/cms/products`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Get product
   * @param id_or_seo ID or SEO URL
   * @param is_legacy_url Is Pixsell legacy URL
   * @param is_legacy_url_video Is Pixsell legacy URL for video
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetProduct(
    id_or_seo: string,
    is_legacy_url?: boolean,
    is_legacy_url_video?: boolean,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProductUpdateItem>;
  public frontGetProduct(
    id_or_seo: string,
    is_legacy_url?: boolean,
    is_legacy_url_video?: boolean,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProductUpdateItem>>;
  public frontGetProduct(
    id_or_seo: string,
    is_legacy_url?: boolean,
    is_legacy_url_video?: boolean,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProductUpdateItem>>;
  public frontGetProduct(
    id_or_seo: string,
    is_legacy_url?: boolean,
    is_legacy_url_video?: boolean,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_or_seo === null || id_or_seo === undefined) {
      throw new Error(
        'Required parameter id_or_seo was null or undefined when calling frontGetProduct.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (is_legacy_url !== undefined && is_legacy_url !== null) {
      queryParameters = queryParameters.set(
        'is_legacy_url',
        <any>is_legacy_url
      );
    }
    if (is_legacy_url_video !== undefined && is_legacy_url_video !== null) {
      queryParameters = queryParameters.set(
        'is_legacy_url_video',
        <any>is_legacy_url_video
      );
    }
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ProductUpdateItem>(
      'get',
      `${this.basePath}/products/${encodeURIComponent(String(id_or_seo))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
