import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface Row {
  id: number;
  data: RowData[];
  isSelected?: boolean;
}
type RowData = {
  type:
    | 'string'
    | 'boolean'
    | 'money'
    | 'date'
    | 'pdfButton'
    | 'cancelInvoiceButton'
    | 'deleteInvoiceButton'
    | 'fiscalizeInvoiceButton'
    | 'status'
    | 'input'
    | 'image'
    | 'button'
    | 'button2'
    | 'sapDropdown';
  value?: any;
  selectOptions?: { name: string; value: string }[];
  context?: any;
};

@Component({
  selector: 'app-table-selectable',
  templateUrl: './table-selectable.component.html',
  styleUrls: ['./table-selectable.component.scss'],
})
export class TableSelectableComponent implements OnInit {
  @Input() headerNames: string[] = [];
  @Input() rowData!: Row[];
  @Input() isSelectable: boolean = false;
  @Input() hasLink: boolean = true;

  public isAllSelected: boolean = false;
  public numberOfSelectedElements: number = 0;

  @Input() hasDelete: boolean = false;
  @Input() hasExportToXLS: boolean = false;
  @Input() hasExportToCSV: boolean = false;

  @Input() hasPrintPDF: boolean = false;
  @Input() hasCancelInvoice: boolean = false;
  @Input() hasGenerateSAPJournalEntry: boolean = false;
  @Input() hasReadyForSap: boolean = false;

  @Input() hasCustomFooterButton: boolean = false;
  @Input() customFooterButtonText: string = '';
  @Output() customFooterButtonPressedEvent: EventEmitter<void> =
    new EventEmitter();

  @Output() deleteEmitter: EventEmitter<number[]> = new EventEmitter();
  @Output() exportToXLSEvent: EventEmitter<number[]> = new EventEmitter();
  @Output() exportToCSVEvent: EventEmitter<number[]> = new EventEmitter();
  @Output() sapExportEvent: EventEmitter<number[]> = new EventEmitter();

  @Output() buttonPressedEvent: EventEmitter<number> = new EventEmitter();
  @Output() button2PressedEvent: EventEmitter<number> = new EventEmitter();
  @Output() printPDFSingleButtonPressedEvent: EventEmitter<number> =
    new EventEmitter();
  @Output() cancelInvoiceSingleButtonPressedEvent: EventEmitter<number> =
    new EventEmitter();
  @Output() fiscalizeInvoiceSingleButtonPressedEvent: EventEmitter<number> =
    new EventEmitter();
  @Output() readyForSapButtonPressedEvent: EventEmitter<number> =
    new EventEmitter();

  @Output() sapDropdownChangedEvent: EventEmitter<{
    id: number;
    value: string;
  }> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleSelection(event: MouseEvent, index: number) {
    event.stopPropagation();
    const previousValue = this.rowData[index].isSelected;
    this.rowData[index].isSelected = !previousValue;

    this.calculateNumberOfSelectedElements();
  }

  handleSelectItem() {
    if (!this.isAllSelected) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }
  selectAll() {
    this.rowData = this.rowData.map((element) => {
      element.isSelected = true;
      return element;
    });
    this.calculateNumberOfSelectedElements();
  }
  deselectAll() {
    this.rowData = this.rowData.map((element) => {
      element.isSelected = false;
      return element;
    });
    this.calculateNumberOfSelectedElements();
  }
  calculateNumberOfSelectedElements() {
    this.numberOfSelectedElements = this.rowData.reduce(
      (accumulator, currentValue) => {
        return currentValue.isSelected ? accumulator + 1 : accumulator;
      },
      0
    );
    if (this.numberOfSelectedElements === this.rowData.length) {
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
  }
  getSelectedIds(): number[] {
    const selectedIdArray: number[] = [];
    this.rowData.map((element) => {
      if (element.isSelected) {
        selectedIdArray.push(element.id);
      }
    });
    return selectedIdArray;
  }

  handleDelete() {
    const selectedIdArray = this.getSelectedIds();
    this.deleteEmitter.emit(selectedIdArray);
  }
  handleExportToXLS() {
    const selectedIdArray = this.getSelectedIds();
    this.exportToXLSEvent.emit(selectedIdArray);
  }
  handleExportToCSV() {
    const selectedIdArray = this.getSelectedIds();
    this.exportToCSVEvent.emit(selectedIdArray);
  }

  buttonPressed(event: Event, num: number) {
    event.stopPropagation();
    this.buttonPressedEvent.emit(num);
  }
  button2Pressed(event: Event, num: number) {
    event.stopPropagation();
    this.button2PressedEvent.emit(num);
  }
  printPDFSingleButtonPressed(event: Event, num: number) {
    event.stopPropagation();
    this.printPDFSingleButtonPressedEvent.emit(num);
  }
  cancelInvoiceSingleButtonPressed(event: Event, num: number) {
    event.stopPropagation();
    this.cancelInvoiceSingleButtonPressedEvent.emit(num);
  }
  ficalizeInvoiceSingleButtonPressed(event: Event, num: number) {
    event.stopPropagation();
    this.fiscalizeInvoiceSingleButtonPressedEvent.emit(num);
  }

  readyForSapButton() {
    this.readyForSapButtonPressedEvent.emit();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }
  sapDropdownValueChanged(event: any, id: number) {
    const outputObj = {
      id: id,
      value: event.target.value,
    };
    this.sapDropdownChangedEvent.emit(outputObj);
  }

  //TODO NEXT
  handlePrintPDF() {}
  handleCancelInvoice() {}
  handleGenerateSAPJournalEntry() {
    this.sapExportEvent.emit([]);
  }

  delete() {}

  handleCustomFooterButton() {
    this.customFooterButtonPressedEvent.emit();
  }
}
