/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Agency } from './agency';
import { Author } from './author';
import { Category } from './category';
import { Gallery } from './gallery';
import { GalleryPreviewItem } from './galleryPreviewItem';
import { Keyword } from './keyword';
import { MediaPreivew } from './mediaPreivew';
import { Person } from './person';
import { SpecialOfferType } from './specialOfferType';
import { Tag } from './tag';
import { Translatable } from './translatable';

export interface GalleryGroupItem {
  id: number;
  publish_date?: string;
  publish_state?: GalleryGroupItem.PublishStateEnum;
  gg_product_id?: number;
  caption?: Array<Translatable>;
  headline?: Array<Translatable>;
  keywords_list?: Array<Keyword>;
  people_list?: Array<Person>;
  category?: Category;
  categories?: Array<Category>;
  author?: Author;
  agency?: Agency;
  tag_list?: Array<Tag>;
  is_special_offer?: boolean;
  special_offer_types?: Array<SpecialOfferType>;
  web_price?: number;
  print_price?: number;
  created_date?: string;
  imported_date?: string;
  previews?: Array<MediaPreivew>;
  gallery_list?: Array<GalleryPreviewItem>;
  /**
   * Will return a full materialized gallery list with all of the information for the gallery objects. NOTE: This will only be the case when you look up gallery group directly using /gallery_groups/{groupId}. This will not be present when you query a lot of galleries.
   */
  full_gallery_list?: Array<Gallery>;
  article_id?: number;
  article_title?: string;
  article_content?: string;
  article_author?: Author;
  /**
   * URL to view more information. Will not contain the base URL of the website.
   */
  seo_url?: string;
  total_count?: number;
}
export namespace GalleryGroupItem {
  export type PublishStateEnum =
    | 'NOT_PUBLISHED'
    | 'PUBLISHED'
    | 'AUTO_PUBLISH_AFTER_DATE';
  export const PublishStateEnum = {
    NOTPUBLISHED: 'NOT_PUBLISHED' as PublishStateEnum,
    PUBLISHED: 'PUBLISHED' as PublishStateEnum,
    AUTOPUBLISHAFTERDATE: 'AUTO_PUBLISH_AFTER_DATE' as PublishStateEnum,
  };
}
