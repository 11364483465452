import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-gallery-cart-downloads',
  templateUrl: './gallery-cart-downloads.component.html',
  styleUrls: ['./gallery-cart-downloads.component.scss'],
})
export class GalleryCartDownloadsComponent implements OnInit {
  @Input() downloadList!: any;
  @Output() downloadCompletedEvent: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    //this.downloadList = this.downloadList.lines;

    // 🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡
    ///delete or comment this when connecting to api
    this.downloadList = [
      {
        id: 0,
        title: 'PXL_076120_30793454',
        author: 'string',
        resolution_width: 0,
        resolution_height: 0,
        published_at: '2021-08-09',
        line_subtotal_vat: 0,
        line_total_no_vat: 0,
        line_total_with_vat: 33,
        currency: 'HRK',
        variation: 'PRINT',
        previews: [
          {
            aspect_ratio: 0,
            preview_url: '/assets/img/image2.png',
            preview_url_large: 'string',
            preview_url_blur: 'string',
            preview_video_loop: 'string',
            preview_video_720p: 'string',
          },
        ],
        download_with_author_signature: true,
        kind: 'SINGLE_PRODUCT',
        product_id: 123123,
      },
      {
        id: 1,
        title: 'PXL_076120_30793454dsdsdas',
        author: 'string',
        resolution_width: 0,
        resolution_height: 0,
        published_at: '2021-08-09',
        line_subtotal_vat: 0,
        line_total_no_vat: 0,
        line_total_with_vat: 64455,
        currency: 'HRK',
        variation: 'PRINT',
        previews: [
          {
            aspect_ratio: 0,
            preview_url: '/assets/img/image1.png',
            preview_url_large: 'string',
            preview_url_blur: 'string',
            preview_video_loop: 'string',
            preview_video_720p: 'string',
          },
        ],
        download_with_author_signature: true,
        kind: 'SINGLE_PRODUCT',
        product_id: 32330,
      },
      {
        id: 2,
        title: 'PXL_076120_30793454',
        author: 'string',
        resolution_width: 0,
        resolution_height: 0,
        published_at: '2021-08-09',
        line_subtotal_vat: 0,
        line_total_no_vat: 0,
        line_total_with_vat: 600,
        currency: 'HRK',
        variation: 'PRINT',
        previews: [
          {
            aspect_ratio: 0,
            preview_url: '/assets/img/image6.png',
            preview_url_large: 'string',
            preview_url_blur: 'string',
            preview_video_loop: 'string',
            preview_video_720p: 'string',
          },
        ],
        download_with_author_signature: true,
        kind: 'SINGLE_PRODUCT',
        product_id: 34506,
      },
    ];

    //console.log('dl list', this.downloadList);
  }

  deleteItem(index: number) {
    this.downloadList.splice(index, 1);
  }
  download() {
    // send to server
    //body = this.downloadList.map > el.id
    //this.serviceservice.download(body).sub(response=>{

    // might need dl link from response
    this.downloadCompletedEvent.emit();
    //}
  }
}
