/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Translatable } from './translatable';

export interface Tag {
  id: number;
  name: Array<Translatable>;
  status?: Tag.StatusEnum;
}
export namespace Tag {
  export type StatusEnum = 'ACTIVE' | 'INACTIVE';
  export const StatusEnum = {
    ACTIVE: 'ACTIVE' as StatusEnum,
    INACTIVE: 'INACTIVE' as StatusEnum,
  };
}
