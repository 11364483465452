<label for="{{ controlName }}">{{ (isRequired ? "* " : "") + label }}</label>
<div [formGroup]="form">
  <input
    type="text"
    name="{{ controlName }}"
    id="{{ controlName }}"
    formControlName="{{ controlName }}"
  />
  <button></button>
</div>
