<div class="sidebar">
  <ng-container *ngIf="buttonVariant === 'default'">
    <ng-container *ngTemplateOutlet="buttonsCancelSave"></ng-container>
  </ng-container>

  <ng-container *ngIf="buttonVariant === 'publish'">
    <ng-container *ngTemplateOutlet="buttonsSavePublish"></ng-container>
  </ng-container>

  <div>
    <div class="header-menu">
      <button class="header-btn header-btn--active">Croatian</button>
      <button class="header-btn">English</button>
    </div>
    <!-- fields -->
    <div class="sidebar__content" [formGroup]="sidebarForm" *ngIf="sidebarForm">
      <ng-container *ngTemplateOutlet="croatian"></ng-container>
    </div>
  </div>
</div>

<!-- templates -->
<ng-template #croatian>
  <app-sidebar-autocomplete
    [form]="sidebarForm"
    [controlName]="'headline'"
    [isRequired]="true"
    [label]="'Headline / Gallery'"
  ></app-sidebar-autocomplete>

  <app-sidebar-caption
    [form]="sidebarForm"
    [controlName]="'caption'"
    [isRequired]="true"
    [label]="'Caption'"
  >
  </app-sidebar-caption>
  <app-sidebar-tags
    [form]="sidebarForm"
    [controlName]="'keywords'"
    [isRequired]="false"
    [label]="'Keywords'"
    [(tags)]="keywords"
  ></app-sidebar-tags>
  <app-sidebar-tags
    [form]="sidebarForm"
    [controlName]="'people'"
    [isRequired]="false"
    [label]="'People'"
    [(tags)]="people"
  ></app-sidebar-tags>
  <app-sidebar-autocomplete
    [form]="sidebarForm"
    [controlName]="'category'"
    [isRequired]="false"
    [label]="'* Category'"
  ></app-sidebar-autocomplete>
  <app-sidebar-text
    [form]="sidebarForm"
    [controlName]="'author'"
    [isRequired]="false"
    [label]="'Author'"
  ></app-sidebar-text>
  <app-sidebar-autocomplete
    [form]="sidebarForm"
    [controlName]="'agency'"
    [isRequired]="false"
    [label]="'* Agency'"
  ></app-sidebar-autocomplete>
  <app-sidebar-autocomplete
    [form]="sidebarForm"
    [controlName]="'tag'"
    [isRequired]="false"
    [label]="'Tag'"
  ></app-sidebar-autocomplete>
  <app-sidebar-autocomplete-checkbox
    [form]="sidebarForm"
    [controlName]="'specialOffer'"
    [isRequired]="false"
    [label]="'Special Offer'"
  >
  </app-sidebar-autocomplete-checkbox>

  <app-sidebar-text
    [form]="sidebarForm"
    [controlName]="'printPrice'"
    [isRequired]="false"
    [label]="'Large (Print) price'"
  ></app-sidebar-text>
  <app-sidebar-text
    [form]="sidebarForm"
    [controlName]="'webPrice'"
    [isRequired]="false"
    [label]="'Small (Web) price'"
  ></app-sidebar-text>

  <app-sidebar-checkbox-list
    [title]="'Special instruction'"
    [form]="sidebarForm"
    [controlNameArray]="[
      'noLicence',
      'standard',
      'childRights',
      'copyright2',
      'copyright3',
      'copyright4'
    ]"
    [labelArray]="[
      'Licenca nije potrebna',
      'Standard',
      'Child rights',
      'Copyright 2',
      'Copyright 3',
      'Copyright 4'
    ]"
  ></app-sidebar-checkbox-list>
</ng-template>

<ng-template #english></ng-template>

<!--  -->
<!-- buttons -->
<ng-template #buttonsCancelSave>
  <div class="header-buttons">
    <button
      class="btn btn--secondary"
      title="Cancel"
      (click)="cancel()"
      [disabled]="!selectedItem"
      [class.disabled]="!selectedItem"
    >
      Cancel
    </button>
    <button
      class="btn btn--primary"
      title="Save"
      [disabled]="!selectedItem"
      [class.disabled]="!selectedItem"
      (click)="save()"
    >
      Save
    </button>
  </div>
</ng-template>

<ng-template #buttonsSavePublish>
  <div class="header-buttons">
    <button
      class="btn btn--secondary"
      title="Cancel"
      (click)="savePublishVariant()"
    >
      Save
    </button>
    <button class="btn btn--primary" title="Save" (click)="publish()">
      Publish
    </button>
  </div>
</ng-template>
