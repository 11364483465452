import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-location-header',
  templateUrl: './location-header.component.html',
  styleUrls: ['./location-header.component.scss'],
})
export class LocationHeaderComponent implements OnInit {
  @Input() icon: 'close' | 'back' = 'back';
  @Input() title: string = '';
  @Input() useHistoryBack: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {}

  handleClick() {
    if (this.useHistoryBack) {
      //@ts-ignore
      this.location.back();
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
}
