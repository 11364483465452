import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-scroll-slide',
  templateUrl: './horizontal-scroll-slide.component.html',
  styleUrls: ['./horizontal-scroll-slide.component.scss'],
})
export class HorizontalScrollSlideComponent implements OnInit {
  @Input() link: string = '';
  @Input() previewUrl: string = '';

  constructor() {}

  ngOnInit(): void {}
}
