import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expansion-panel-header',
  templateUrl: './expansion-panel-header.component.html',
  styleUrls: ['./expansion-panel-header.component.scss'],
})
export class ExpansionPanelHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onExpandToggle() {}
}
