/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HomepageBlockProductPreview } from './homepageBlockProductPreview';

export interface HomepageBlock {
  block_type?: HomepageBlock.BlockTypeEnum;
  products: Array<HomepageBlockProductPreview | null>; // manual changes
  order?: number;
  content?: any;
}
export namespace HomepageBlock {
  export type BlockTypeEnum =
    | 'LAYOUT_A'
    | 'LAYOUT_B'
    | 'LAYOUT_C'
    | 'LAYOUT_D'
    | 'LAYOUT_E'
    | 'LAYOUT_F'
    | 'LAYOUT_G'
    | 'CONTENT_PRODUCTION'
    | 'CONTENT_VIDEO'
    | 'CONTENT_AGENCIES'
    | 'CONTENT_CUSTOM_ORDERS'
    | 'CONTENT_EDITORIAL'
    | 'CONTENT_AGEPHOTOSTOCK';
  export const BlockTypeEnum = {
    LAYOUTA: 'LAYOUT_A' as BlockTypeEnum,
    LAYOUTB: 'LAYOUT_B' as BlockTypeEnum,
    LAYOUTC: 'LAYOUT_C' as BlockTypeEnum,
    LAYOUTD: 'LAYOUT_D' as BlockTypeEnum,
    LAYOUTE: 'LAYOUT_E' as BlockTypeEnum,
    LAYOUTF: 'LAYOUT_F' as BlockTypeEnum,
    LAYOUTG: 'LAYOUT_G' as BlockTypeEnum,
    CONTENTPRODUCTION: 'CONTENT_PRODUCTION' as BlockTypeEnum,
    CONTENTVIDEO: 'CONTENT_VIDEO' as BlockTypeEnum,
    CONTENTAGENCIES: 'CONTENT_AGENCIES' as BlockTypeEnum,
    CONTENTCUSTOMORDERS: 'CONTENT_CUSTOM_ORDERS' as BlockTypeEnum,
    CONTENTEDITORIAL: 'CONTENT_EDITORIAL' as BlockTypeEnum,
    CONTENTAGEPHOTOSTOCK: 'CONTENT_AGEPHOTOSTOCK' as BlockTypeEnum,
  };
}
