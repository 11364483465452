/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserPreview } from './userPreview';

export interface Company {
  status?: Company.StatusEnum;
  company_id?: number;
  company_name?: string;
  address_line_1?: string;
  country_id?: number;
  date_created?: Date;
  oib_company?: string;
  tax_number?: string;
  mbs?: string;
  press_magazine_name?: string;
  contact_user?: UserPreview;
  phone?: string;
  city?: string;
  postal_number?: string;
  email?: string;
  vat_number?: string;
  sap_number?: string;
  sap_indicator?: string;
  note?: string;
  representative_user?: UserPreview;
  payment_method?: string;
  payment_model?: string;
  payment_due_in_days?: number;
  currency_id?: number;
  currency_name?: string;
  i_c?: boolean;
  hide_discounts?: boolean;
  invoice_text?: string;
  additional_invoice_text?: string;
  language?: string;
  invoice_sent_at_email?: string;
  invoice_n_r?: boolean;
  dont_create_invoice?: boolean;
  telephone?: string;
  fax?: string;
  contact_person?: string;
  contact_email?: string;
  contact_phone?: string;
  /**
   * What kind of stuff the can download
   */
  download_type_premissions?: Array<Company.DownloadTypePremissionsEnum>;
  /**
   * Send to system tab on user editing
   */
  send_to_system?: boolean;
  send_to_system_username?: string;
  send_to_system_password?: string;
  send_to_system_url?: string;
  send_to_system_api_key?: string;
  send_to_system_api_token?: string;
  /**
   * Send to CMS tab on user editing
   */
  send_to_cms?: boolean;
  send_to_cms_username?: string;
  send_to_cms_password?: string;
  send_to_cms_api_key?: string;
  send_to_cms_api_token?: string;
  hidden_agency_ids?: Array<number>;
}
export namespace Company {
  export type StatusEnum = 'PENDING' | 'ACTIVATED';
  export const StatusEnum = {
    PENDING: 'PENDING' as StatusEnum,
    ACTIVATED: 'ACTIVATED' as StatusEnum,
  };
  export type DownloadTypePremissionsEnum =
    | 'PHOTO'
    | 'PHOTO_LARGE'
    | 'PHOTO_LARGE_SIGNATURE'
    | 'PHOTO_SMALL'
    | 'PHOTO_SMALL_SIGNATURE'
    | 'VIDEO';
  export const DownloadTypePremissionsEnum = {
    PHOTO: 'PHOTO' as DownloadTypePremissionsEnum,
    PHOTOLARGE: 'PHOTO_LARGE' as DownloadTypePremissionsEnum,
    PHOTOLARGESIGNATURE: 'PHOTO_LARGE_SIGNATURE' as DownloadTypePremissionsEnum,
    PHOTOSMALL: 'PHOTO_SMALL' as DownloadTypePremissionsEnum,
    PHOTOSMALLSIGNATURE: 'PHOTO_SMALL_SIGNATURE' as DownloadTypePremissionsEnum,
    VIDEO: 'VIDEO' as DownloadTypePremissionsEnum,
  };
}
