import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TemplateBlock } from 'piwe-front-swagger-client';
import { BlockData } from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-data.service';
import { EditModeTypes } from 'src/app/cms/web-administration/homepage-administration/homepage-creator/homepage-creator-sidebar/sidebar-page-builder-edit/sidebar-page-builder-edit.component';

export interface DoubleClickSectionObject {
  innerIndex: number;
  editModeType: EditModeTypes;
}

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  @Input() sectionVariant!: 1 | 2 | 3 | 4 | 5;
  @Input() isSelectedInsideThisBlock: boolean = false;

  //aspect ratio of the grid, used for determining the height
  private aspectRatios = [0.805, 0.87, 0.84, 0.7];

  @Input() images: any[] = [];
  @Input() pagebuilderMode: boolean = true;
  @ViewChild('grid') gridReference!: ElementRef;
  @Output() blockDoubleClicked: EventEmitter<DoubleClickSectionObject> =
    new EventEmitter();

  @Input() blockData!: TemplateBlock;

  public doubleClickIndex: number | null = null;
  public isMobile: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.calculateGridRows();
    this.checkWidthForMobile();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWidthForMobile();
    if (!this.isMobile) {
      this.calculateGridRows();
    }
  }

  calculateGridRows() {
    //block height changes depending on the width of the grid and the aspect ratio of the blockVariant
    const width = this.gridReference.nativeElement.offsetWidth;
    const height = width * this.aspectRatios[this.sectionVariant - 1];

    let gridTemplateRowsValue: string = '';
    switch (this.sectionVariant) {
      case 1:
        gridTemplateRowsValue = `${height * 0.54}px ${height * 0.46}px`;
        break;
      case 2:
        gridTemplateRowsValue = `${height / 2}px ${height / 2}px`;
        break;
      case 3:
        gridTemplateRowsValue = `${height * 0.7}px ${height * 0.3}px`;
        break;
      case 4:
        gridTemplateRowsValue = `${height * 0.63}px ${height * 0.37}px`;
        break;
      case 5:
        gridTemplateRowsValue = `${height * 0.36}px ${height * 0.29}px ${
          height * 0.35
        }px`;
        break;
    }

    this.gridReference.nativeElement.style.gridTemplateRows =
      gridTemplateRowsValue;
  }

  handleTitleDoubleClick() {
    const emitObj: DoubleClickSectionObject = {
      innerIndex: -1,
      editModeType: EditModeTypes.TextEditor,
    };
    this.blockDoubleClicked.emit(emitObj);
    this.doubleClickIndex = -1;
  }
  handleImageDoubleClick(i: number) {
    const emitObj: DoubleClickSectionObject = {
      innerIndex: i,
      editModeType: EditModeTypes.ImageEditor,
    };
    this.blockDoubleClicked.emit(emitObj);
    this.doubleClickIndex = i;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSelectedInsideThisBlock) {
      if (changes.isSelectedInsideThisBlock.previousValue) {
        this.doubleClickIndex = null;
      }
    }
  }

  checkWidthForMobile() {
    const width: number =
      document.querySelector('.main-app-container')?.clientWidth ?? 0;

    this.isMobile = false;
    if (width <= 1024) {
      this.isMobile = true;
    }
  }
}
