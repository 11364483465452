import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent implements OnInit {
  @Input() state: 'INFO' | 'ERROR' = 'INFO';
  @Input() text: string = '';
  @Output() snackBarClicked: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleClick() {
    this.snackBarClicked.emit();
  }
}
