<div [ngClass]="'theme-' + theme">
  <div
    class="field-checkbox"
    [class.checked]="checked"
    [class.disabled]="disabled"
    (click)="toggle()"
  >
    <div class="field-checkbox-image"></div>
    <div class="field-checkbox-title">{{ title }}</div>
  </div>

  <!-- Errors -->
  <div *ngIf="errors != undefined && errors.length > 0" class="field-errors">
    <div *ngFor="let error of errors" class="field-error">
      {{ error.error_description }}
    </div>
  </div>
</div>
