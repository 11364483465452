import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModelError } from '../../../../projects/piwe-front-swagger-client/src';
import { Action } from '../field-text/field-text.component';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-field-wysiwyg',
  templateUrl: './field-wysiwyg.component.html',
  styleUrls: ['./field-wysiwyg.component.scss'],
})
export class FieldWysiwygComponent implements OnInit, OnChanges {
  public Editor = DecoupledEditor;

  @Input() title: string = '';

  @Input() value?: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() suffix?: string;

  @Input() disabled: boolean = false;

  @Input() rows: number = 20;

  @Input() errors: ModelError[] = [];

  @Input() rawValue: string = '';

  @Output() actionSelected: EventEmitter<{
    field: string;
    action: Action;
  }> = new EventEmitter<{
    field: string;
    action: Action;
  }>();

  /**
   * Theming.
   */
  @Input() theme: string = '';

  editor?: any;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'value': {
            const currentValue =
              (changes['value'].currentValue as string | undefined) ?? '';
            //console.log("WYSIWYG Editor", this.editor, currentValue);

            if (this.editor == undefined) {
              this.rawValue = currentValue;
            } else {
              if (this.editor.getData() != currentValue) {
                this.editor?.setData(currentValue);
              }
            }
          }
        }
      }
    }
  }

  @ViewChild('editor') editorComponent?: CKEditorComponent;

  public getEditor() {
    // Warning: This may return "undefined" if the editor is hidden behind the `*ngIf` directive or
    // if the editor is not fully initialised yet.
    return this.editorComponent?.editorInstance;
  }

  public onReady(editor: any) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );

    this.editor = editor;
  }

  onChange($event: ChangeEvent) {
    if (this.value == $event.editor.getData()) return;

    //console.log("WYSIWYG CHANGE", $event);
    this.valueChange.emit($event.editor.getData());
  }
}
