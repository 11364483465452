/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ModelError {
  error_code: string;
  error_description?: string;
  /**
   * If error is related to an item, ref it here.
   */
  item_id?: string;
  /**
   * If it's a field validation error, this will contain the relevant field name
   */
  field_name?: string;
}
