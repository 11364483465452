// @ts-ignore

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ProductPriceCalculation, User } from 'piwe-front-swagger-client';
import { CartService, IVariationsObject } from 'src/app/cart.service';
import {
  AddToCart,
  Translatable,
} from '../../../../projects/piwe-front-swagger-client/src';
import * as fileSaver from 'file-saver';
import DownloadOptionsEnum = ProductPriceCalculation.DownloadOptionsEnum;
import { SnackBarService } from 'src/app/share/snack-bar.service';
import { skip, skipWhile, take, takeUntil } from 'rxjs/operators';
import { ProfileService } from '../../profile.service';
import { Subject, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-image-side-right',
  templateUrl: './image-side-right.component.html',
  styleUrls: ['./image-side-right.component.scss'],
})
export class ImageSideRightComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  @Input() isLoaded = false;
  @Input() source?: string;
  @Input() fileName?: string;
  @Input() productId!: number | string;
  @Input() isDownload = false;
  @Input() isGallery = false;
  @Output() downloadCompletedEvent: EventEmitter<void> = new EventEmitter();
  @Output() addedToCartEvent: EventEmitter<void> = new EventEmitter();

  @Input() productCount = 1;
  isDownloading = false;
  isDownloadingSample = false;

  public isCurrentProductInCartObject: IVariationsObject = {
    print: false,
    web: false,
    video: false,
  };

  public isHowToUseActive = false;

  public largePrice?: number;
  public smallPrice?: number;

  public variation: AddToCart['variation'] = 'PRINT';
  public signature = false;

  public notes: string[] = [];
  public calculateProductPriceObject: ProductPriceCalculation = {};

  public isPrintAvailible = false;
  public isWebAvailible = false;
  public specialInstructionsName: Array<any> = [];
  public specialInstructionsContent: Array<any> = [];
  public specialInstructionsNameEn: Array<any> = [];
  public specialInstructionsContentEn: Array<any> = [];
  public usageRightsName: Array<any> = [];
  public usageRightsContent: Array<any> = [];
  public usageRightsNameEn: Array<any> = [];
  public usageRightsContentEn: Array<any> = [];

  public isDownloadAvailable = false;
  public isSendToSystemAvailable = false;
  public isSendToCMSAvailable = false;
  public isAddToCartAvailable = false;
  public isDownloadSampleAvailable = false;
  public isVideo = false;
  public videoResolution = '';
  public userKind: User.KindEnum | undefined = 'SMALL';
  private userCurrency = '';

  public currentLanguage = 'hr';
  private currentLanguageSubscription = new Subscription();

  constructor(
    private cartService: CartService,
    private snackBarService: SnackBarService,
    private profileService: ProfileService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.profileService.getProfile();
    this.profileService.profileData
      .pipe(
        takeUntil(this.unsubscribe$),
        skipWhile((x) => x == undefined),
        take(1)
      )
      .subscribe((response: User | undefined) => {
        this.userKind = response?.kind;
      });

    this.cartService
      .calculateProductPrice(this.productId, this.source)
      .subscribe(
        (response: ProductPriceCalculation) => {
          switch (response?.currency) {
            case 'HRK':
              this.userCurrency = 'kn';
              break;
            case 'EUR':
              this.userCurrency = '€';
              break;
            case 'USD':
              this.userCurrency = '$';
              break;
            case 'GBP':
              this.userCurrency = '£';
              break;
            default:
              this.userCurrency = '';
              break;
          }

          this.fileName = response.file_name;
          this.calculateProductPriceObject = response;
          this.isPrintAvailible = response.available_variations?.find(
            (element) => element === 'PRINT'
          )
            ? true
            : false;
          this.isWebAvailible = response.available_variations?.find(
            (element) => element === 'WEB'
          )
            ? true
            : false;

          if (this.isPrintAvailible && !this.isWebAvailible) {
            this.variation = 'PRINT';
          }
          if (this.isWebAvailible && !this.isPrintAvailible) {
            this.variation = 'WEB';
          }

          this.isVideo = response.available_variations?.find(
            (element) => element === 'VIDEO'
          )
            ? true
            : false;
          this.videoResolution =
            this.calculateProductPriceObject?.video_resolution
              ?.toLowerCase()
              .split('x')
              .join(' x ') ?? 'unknown';

          if (!this.isGallery) {
            this.specialInstructionsName = this.getSpecialInstructionsContent(
              this.calculateProductPriceObject.special_instructions,
              'HR'
            );
            this.specialInstructionsContent =
              this.getSpecialInstructionsContent(
                this.calculateProductPriceObject.special_instructions,
                'HR',
                'content'
              );
            this.specialInstructionsNameEn = this.getSpecialInstructionsContent(
              this.calculateProductPriceObject.special_instructions,
              'EN'
            );
            this.specialInstructionsContentEn =
              this.getSpecialInstructionsContent(
                this.calculateProductPriceObject.special_instructions,
                'EN',
                'content'
              );

            this.usageRightsName = this.getSpecialInstructionsContent(
              this.calculateProductPriceObject.usage_rights,
              'HR'
            );
            this.usageRightsContent = this.getSpecialInstructionsContent(
              this.calculateProductPriceObject.usage_rights,
              'HR',
              'content'
            );
            this.usageRightsNameEn = this.getSpecialInstructionsContent(
              this.calculateProductPriceObject.usage_rights,
              'EN'
            );
            this.usageRightsContentEn = this.getSpecialInstructionsContent(
              this.calculateProductPriceObject.usage_rights,
              'EN',
              'content'
            );
          }

          this.isDownloadAvailable =
            response.download_options?.includes(DownloadOptionsEnum.DOWNLOAD) ??
            false;
          this.isSendToSystemAvailable =
            response.download_options?.includes(
              DownloadOptionsEnum.SENDTOSYSTEM
            ) ?? false;
          this.isSendToCMSAvailable =
            response.download_options?.includes(
              DownloadOptionsEnum.SENDTOCMS
            ) ?? false;
          this.isAddToCartAvailable =
            response.download_options?.includes(
              DownloadOptionsEnum.ADDTOCART
            ) ?? false;
          this.isDownloadSampleAvailable =
            response.download_options?.includes(
              DownloadOptionsEnum.DOWNLOADEXAMPLE
            ) ?? false;

          this.isLoaded = true;
        },
        (err) => {
          // console.log(err);
        }
      );

    this.isCurrentProductInCartObject =
      this.cartService.checkIfProductExistsInCart(this.productId);

    // translations
    this.currentLanguage = this.translateService.currentLang;
    this.currentLanguageSubscription =
      this.translateService.onLangChange.subscribe(
        (params: LangChangeEvent) => {
          this.currentLanguage = params.lang;
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.currentLanguageSubscription.unsubscribe();
  }

  getSpecialInstructionsContent(
    arr: Array<any> | undefined,
    language: string,
    property: string = 'name'
  ) {
    if (arr == undefined) { return []; }

    const filteredArray = arr.map(
      (el) =>
        el[property]!.filter((t: Translatable) => {
          if (t.language == language) { return t; }
          else { return false; }
        })[0]
    );
    // removes undefined values from array
    return filteredArray.filter((el) => el);
  }

  toggleHowToUse() {
    this.isHowToUseActive = !this.isHowToUseActive;
  }

  addToCart() {
    this.cartService
      .addCartLine(this.productId, this.variation, this.signature, this.source!)
      .subscribe(
        (response) => {
          this.isCurrentProductInCartObject =
            this.cartService.checkIfProductExistsInCart(this.productId);
          this.addedToCartEvent.emit();
        },
        (err) => {
          this.snackBarService.showSnackBar('Nešto je pošlo po zlu.', 'ERROR');
        }
      );
  }

  confirmPaymentCorpo() {
    let amount: number;
    if (this.variation == 'PRINT') {
      amount = this.calculateProductPriceObject!.print_price!;
    } else {
      amount = this.calculateProductPriceObject!.web_price!;
    }

    if (this.isVideo) {
      amount = this.calculateProductPriceObject!.video_price!;
    }

    if (amount == 0.0) { return true; }

    const answer = window.confirm(
      `Da li ste sigurni da želite downloadirati proizvod(e) za ${amount.toFixed(
        2
      )} ${this.userCurrency}?`
    );

    return answer;
  }

  downloadFull(event: MouseEvent) {
    event.preventDefault();


    if (
      this.userKind != 'CORPORATE' ||
      (this.userKind == 'CORPORATE' && this.confirmPaymentCorpo())
    ) {
      this.isDownloading = true;
      this.cartService
        .downloadFullProductLink(
          this.productId,
          this.variation,
          this.signature,
          this.source!
        )
        .subscribe(
          (response) => {
            if (response != undefined && response.link != undefined) {
              window.location.assign(response.link);
              this.snackBarService.showSnackBar(
                'Uspješno pokrenuto preuzimanje.',
                'INFO'
              );

              const that = this;
              setTimeout(_ => {
                that.isDownloading = false;
              }, 1500);
            } else {
              this.snackBarService.showSnackBar(
                'Greška prilikom preuzimanja.',
                'ERROR'
              );
              this.isDownloading = false;
            }
          },
          () => {
            this.snackBarService.showSnackBar(
              'Greška prilikom preuzimanja.',
              'ERROR'
            );
            this.isDownloading = false;
          }
        );
    }
  }

  downloadSample(event: MouseEvent) {
    this.isDownloadingSample = true;
    event.preventDefault();
    this.cartService
      .downloadPreview(this.productId, this.source!)
      .subscribe((response) => {
        const blob: any = new Blob([response], {
          type: 'image/jpeg; charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, this.fileName);
        let that = this;
        setTimeout(_ => {
          that.isDownloadingSample = false;
        }, 1500);
      }, error => {
        this.isDownloadingSample = false;
      });
  }

  downloadCompleted() {
    this.downloadCompletedEvent.emit();
  }

  sendToSystem() {
    if (
      this.userKind != 'CORPORATE' ||
      (this.userKind == 'CORPORATE' && this.confirmPaymentCorpo())
    ) {
      this.cartService.sendToSystem(
        this.productId,
        this.variation,
        this.signature,
        this.source!
      );
    }
  }

  sendToCms() {
    if (
      this.userKind != 'CORPORATE' ||
      (this.userKind == 'CORPORATE' && this.confirmPaymentCorpo())
    ) {
      this.cartService.sendToCms(
        this.productId,
        this.variation,
        this.signature,
        this.source!
      );
    }
  }
}
