import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CMSService,
  CMSContractsService,
  Contract,
  ContractLine,
  ContractList,
} from 'piwe-front-swagger-client';
import { map } from 'rxjs/operators';
import { IContract, Serialization } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  constructor(private cmsContractsService: CMSContractsService) {}

  /**
   * Returns a list of contracts with the passed query params for filtering.
   * @param queryParams
   */
  getContracts(page: number, queryParams: any): Observable<ContractList> {
    let id: number | undefined;
    let beginningDate: string | undefined;
    let endDate: string | undefined;
    let contractName: string | undefined;
    let status: string | undefined;

    if (queryParams['id'] != undefined && queryParams['id'].length != 0) {
      id = queryParams['id'];
    }
    if (
      queryParams['beginningDate'] != undefined &&
      queryParams['beginningDate'].length != 0
    ) {
      beginningDate = queryParams['beginningDate'];
    }
    if (
      queryParams['endDate'] != undefined &&
      queryParams['endDate'].length != 0
    ) {
      endDate = queryParams['endDate'];
    }
    if (
      queryParams['contractName'] != undefined &&
      queryParams['contractName'].length != 0
    ) {
      contractName = queryParams['contractName'];
    }
    if (
      queryParams['status'] != undefined &&
      queryParams['status'].length != 0
    ) {
      status = queryParams['status'];
    }

    return this.cmsContractsService.cmsGetContracts(
      page - 1,
      50,
      id,
      beginningDate,
      endDate,
      contractName,
      status
    );
  }

  /**
   * Gets specific contracts for a company.
   * @param companyId
   */
  getCompanyContracts(companyId: number): Observable<ContractList> {
    return this.cmsContractsService.cmsGetCompanyContracts(companyId);
  }

  /**
   * Updates the contract.
   * @param id ID of the contract
   * @param body Contract
   */
  patchContracts(id: number, body: Contract) {
    return this.cmsContractsService.cmsUpdateCompanyContract(id, body);
  }

  /**
   * Create the contract.
   * @param body Contract
   */
  postContract(companyId: number, body: Contract) {
    return this.cmsContractsService.cmsCreateCompanyContract(companyId, body);
  }

  uploadFile(file: any) {}

  /**
   * Download contract pdf.
   * @param id ID of the contract
   */
  downloadContractPdf(id: number) {
    // return this.cmsContractsService.dlContractPdf(id);
    return new Observable<string>((observer) => {});
  }
}
