class Logger {
  debug(location: string, ...args: any[]) {
    //console.debug('LOG', location, ...args);
  }

  info(location: string, ...args: any[]) {
    //console.debug('LOG', location, ...args);
  }

  warning(location: string, ...args: any[]) {
    //console.debug('LOG', location, ...args);
  }

  error(location: string, ...args: any[]) {
    //console.debug('LOG', location, ...args);
  }

  fatal(location: string, ...args: any[]) {
    //console.debug('LOG', location, ...args);
  }
}

const llog = new Logger();
export default llog;
