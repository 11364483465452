import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() isPrimaryButtonHidden: boolean = false;
  @Input() isSecondaryButtonHidden: boolean = false;
  @Input() primaryButtonText: string = 'Save';
  @Input() secondaryButtonText: string = 'Cancel';
  @Input() title: string = '';
  @Input() isSaveButtonDisabled: boolean = false;
  @Input() isFooterHidden: boolean = false;

  @Output() modalClosed: EventEmitter<void> = new EventEmitter();
  @Output() saveModalData: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleCancelClick() {
    this.modalClosed.emit();
  }

  handleSaveButtonClick() {
    this.saveModalData.emit();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.handleCancelClick();
      return;
    }

    if (!this.isFooterHidden && !this.isSaveButtonDisabled) {
      if (event.key === 'Enter') {
        this.handleSaveButtonClick();
      }
    }
  }
}
