<div class="outer">
  <div class="container-c">
    <div class="text">
      <h1>Agephotostock</h1>
      <p>
        Lorem Ipsum je jednostavno probni tekst koji se koristi u tiskarskoj i
        slovoslagarskoj industriji. Lorem Ipsum postoji kao industrijski
        standard
      </p>
    </div>
    <div>
      <ng-container *ngFor="let row of galleryMatrix">
        <div class="image-row">
          <ng-container *ngFor="let element of row">
            <div class="image-container">
              <app-single-image
                class="image"
                [imageLoadingPreviewUrl]="element.blurPreviewUrl"
                [imagePreviewUrl]="element.previewUrl"
                (onImageLoaded)="onImageLoad($event)"
              >
              </app-single-image>
              <div class="overlay">
                <div class="overlay-text">
                  <div class="overlay-text-top">{{ element.timestamp }}</div>
                  <div class="overlay-text-bottom">{{ element.title }}</div>
                </div>
                <button></button>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
