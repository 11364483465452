import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { SingleAgencySearchService } from './single-agency-search.service';
import { InfiniteScrollService } from '../infinite-scroll.service';
import { ProductPreview } from '../../../../projects/piwe-front-swagger-client/src';
import { SubportalResponse } from 'piwe-front-swagger-client';
import { takeUntil } from 'rxjs/operators';
import { SubPortal } from '../search/search.service';

export enum GridType {
  normal = 1,
  fancy,
  gallery = 3,
}

@Component({
  selector: 'app-single-agency-search',
  templateUrl: './single-agency-search.component.html',
  styleUrls: ['./single-agency-search.component.scss'],
})
export class SingleAgencySearchComponent implements OnInit, OnDestroy {
  // https://stackoverflow.com/questions/35923744/pass-enums-in-angular2-view-templates
  public subPortal: typeof SubPortal = SubPortal;

  private readonly unsubscribe$ = new Subject<void>();

  // endpoint is used both for the api endpoint and the search url
  @Input() endpoint: string = '';
  @Input() bannerTitle: string = '';
  @Input() searchPlaceholder: string = '';
  @Input() logoUrl: string = '';
  // if url exists text is ignored, if url is empty text is used
  @Input() logo2Url: string = '';
  @Input() logo2Text: string = '';
  @Input() bannerText: string = '';

  @Input() gridType: GridType = 1;

  public gridData: ProductPreview[] = [];
  public isSearchActive: boolean = false;
  public showLoadNextPageButton: boolean = false;

  public routeSubscription: Subscription = Subscription.EMPTY;
  public currentPage: number = 1;
  private query: any | undefined;

  constructor(
    private service: SingleAgencySearchService,
    private route: ActivatedRoute,
    private infiniteScrollService: InfiniteScrollService
  ) {}

  splice<T>(array: any[], from: number, to: number): T[] {
    //console.log("SPLICE", array);

    if (array == undefined) return [];

    if (array.length < from && array.length < to) {
      return [];
    }

    return array.splice(from, to);
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        const q = params['q'];
        this.query = q;
        this.isSearchActive = true;
        if (!q) {
          this.isSearchActive = false;
        }
        this.currentPage = 0;
        this.gridData = [];
        this.loadData(this.currentPage, q);
      });

    this.infiniteScrollService.scrollSignalData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        const currentPage = this.currentPage ?? 1;
        if (currentPage < 1) {
          this.loadNextPage();
        } else {
          this.showLoadNextPageButton = true;
        }
      });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadData(page: number, text: string) {
    this.service
      .search(page, this.endpoint, text)
      .subscribe((data: SubportalResponse) => {
        this.gridData = this.gridData.concat(data.content!);
      });
  }

  onScroll() {
    this.infiniteScrollService.signalScroll();
  }

  onNormalScroll(event: any) {}

  loadNextPage() {
    this.currentPage += 1;
    this.loadData(this.currentPage, this.query);
  }
}
