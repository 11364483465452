<div
  [ngClass]="'theme-' + theme"
  [class.has-tags]="hasTags"
  [class.focus]="focus"
>
  <div class="field-title">{{ title }}</div>

  <div class="field-group-container">
    <div class="field-container" [class.disabled]="disabled">
      <input
        type="text"
        [placeholder]="placeholder"
        [disabled]="disabled"
        #inputEl
        [(ngModel)]="value"
        (ngModelChange)="onValueChanged()"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />

      <div
        class="expand"
        *ngIf="autocompleteType != undefined"
        [class.active]="focus && !hideAutocomplete"
        (mousedown)="onAutocompleteToggleMouseDown()"
        (click)="onAutocompleteToggleClick()"
      ></div>

      <div
        #autocomplete
        class="field-autocomplete"
        [ngClass]="'theme-' + theme"
        [style.display]="focus && !hideAutocomplete ? 'block' : 'none'"
        [style.width.px]="autocompleteWidth"
      >
        <div
          class="autocomplete-tag-container"
          *ngIf="value != undefined && value.length == 0 && theme == 'filter'"
        >
          <div *ngFor="let tag of tags" class="tag">
            <span style="vertical-align: center"
              >{{ tag.title }}
              <span class="autocomplete-id">(ID: {{ tag.value }})</span></span
            >
            <div class="tag-clear" (click)="removeTag(tag)"></div>
          </div>
        </div>

        <ul *ngIf="value != undefined && value.length != 0">
          <li
            *ngFor="let autocompleteOption of autocompleteOptions"
            (mousedown)="onAutocompleteClick(autocompleteOption)"
            [innerHTML]="autocompleteOption.displayTitle"
          ></li>
        </ul>
      </div>

      <div class="tag-container">
        <div *ngFor="let tag of tags" class="tag">
          {{ tag.title }}
          <span class="autocomplete-id"
            >({{ tag.helperText == undefined ? "ID: " : ""
            }}{{
              tag.helperText == undefined ? tag.value : tag.helperText
            }})</span
          >
          <div class="tag-clear" (mousedown)="removeTag(tag)"></div>
        </div>
      </div>
    </div>

    <div
      #actionsToggleEl
      class="field-action-toggle"
      (click)="openActions()"
      *ngIf="actions.length > 0"
    ></div>
  </div>

  <ul
    #actionsEl
    class="field-autocomplete"
    style="position: absolute; top: 0px; left: 0px"
    [style.visibility]="shouldShowActionDropdown ? 'visible' : 'hidden'"
    [style.width.px]="actionsDropdownWidth"
  >
    <li *ngFor="let action of actions" (mousedown)="onActionClick(action)">
      {{ action.title }}
    </li>
  </ul>

  <!-- Errors -->
  <div *ngIf="errors != undefined && errors.length > 0" class="field-errors">
    <div *ngFor="let error of errors" class="field-error">
      {{ error.error_description }}
    </div>
  </div>
</div>
