<div class="dialog-container" [class.active]="active" *ngIf="active">
  <div class="dialog">
    <div class="dialog-title">Scheduled publish</div>
    <div class="dialog-body">
      <dp-day-time-calendar
        theme="dp-material"
        [(ngModel)]="selectedDate"
        [config]="config"
      ></dp-day-time-calendar>
    </div>
    <div class="dialog-footer">
      <div class="button-group">
        <div class="cancel-button">
          <app-import-button
            title="Cancel"
            foregroundColor="#7D7D7D"
            backgroundColor="#F8F8F8"
            (buttonClick)="cancel()"
          ></app-import-button>
        </div>

        <div class="replace-all-button">
          <app-import-button
            title="Publish"
            [disabled]="selectedDate == undefined"
            disabledBackgroundColor="#F7C28B"
            backgroundColor="#DE8F41"
            (buttonClick)="publish()"
          ></app-import-button>
        </div>
      </div>
    </div>
  </div>
</div>
