/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DynamicViewValidation {
  field_name?: string;
  kind?: DynamicViewValidation.KindEnum;
  value?: string | number;
}
export namespace DynamicViewValidation {
  export type KindEnum = 'EMAIL' | 'MIN_LENGTH' | 'MAX_LENGTH';
  export const KindEnum = {
    EMAIL: 'EMAIL' as KindEnum,
    MINLENGTH: 'MIN_LENGTH' as KindEnum,
    MAXLENGTH: 'MAX_LENGTH' as KindEnum,
  };
}
