<div class="row-c">
  <div class="left">
    <img class="img-round" src="{{ imgUrl }}" alt="" />
    <div class="name">{{ name }}</div>
  </div>

  <div class="img-row" #row>
    <img src="{{ sampleUrl1 }}" alt="" />
    <img src="{{ sampleUrl2 }}" alt="" />
    <img src="{{ sampleUrl3 }}" alt="" />
    <img src="{{ sampleUrl4 }}" alt="" />
  </div>
</div>
