import { Injectable } from '@angular/core';
import { ProductPreview, TemplateBlock } from 'piwe-front-swagger-client';
import { Observable, Subject } from 'rxjs';
import { HomepageAdministrationService } from '../homepage-administration.service';
import {
  HomepageBlockProductPreview,
  WebAdministrationService,
} from 'piwe-front-swagger-client';
import { MediaPreivew } from '../../../../../../projects/piwe-front-swagger-client/src';

export type BlockTypeBlock = {
  id: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  variation: 'block';
};
export type BlockTypeSection = {
  id: 1 | 2 | 3 | 4 | 5 | 6;
  variation: 'section';
};

export type BlockType = BlockTypeBlock | BlockTypeSection;

export type BlockMedia = {
  id: number;
  preview_url: string;
  preview_url_blur: string;
  preview_url_large?: string;
  title: string;
  timestamp: string;
  childCount: number;
  isArticle?: boolean;
  type: 'gallery' | 'galleryGroup';
} | null;

export interface BlockData {
  blockType: BlockType;
  media: BlockMedia[];
  headline?: string;
  description?: string;
  headlineEn?: string;
  descriptionEn?: string;
  bg_color?: string;
  text_color?: string;
}
export type FieldName =
  | 'headline'
  | 'description'
  | 'headlineEn'
  | 'descriptionEn'
  | 'background_color'
  | 'text_color'
  | 'link';
export type ProductFieldName = 'id' | 'link';

export interface Coordinates {
  innerIndex: number;
  outerIndex: number;
}

@Injectable({
  providedIn: 'root',
})
export class HomepageCreatorDataService {
  private _dataMatrix: TemplateBlock[] = [];

  public homepageDataUpdatedSubject = new Subject();
  public noImgUrl = '/assets/empty-state-page-builder.svg';
  public noImgSectionUrl = '/assets/section-placeholder.svg';

  private _coordinates: Coordinates = {
    innerIndex: -1,
    outerIndex: -1,
  };

  public scrollPosition: number | null = null;
  public memoScrollPositionSource = new Subject<void>();
  public memoScrollPositionData: Observable<void> =
    this.memoScrollPositionSource.asObservable();

  constructor(
    private homepageAdministrationService: HomepageAdministrationService,
    private webAdministrationService: WebAdministrationService
  ) {}

  get coordinates() {
    return this._coordinates;
  }
  set coordinates(value) {
    this._coordinates = value;
  }
  set innerIndex(value: number) {
    this.coordinates = {
      ...this.coordinates,
      innerIndex: value,
    };
  }
  set outerIndex(value: number) {
    this.coordinates = {
      ...this.coordinates,
      outerIndex: value,
    };
  }

  get dataMatrix() {
    return this._dataMatrix;
  }
  set dataMatrix(value: TemplateBlock[]) {
    this._dataMatrix = value;
    this.emitHomepageDataUpdated();
  }

  init() {
    // get from DB
  }
  swapMedia(data: ProductPreview | null) {
    const outerIndex = this.coordinates.outerIndex;
    const innerIndex = this.coordinates.innerIndex;

    this.dataMatrix[outerIndex].products![innerIndex] = data as any;
  }
  destroy() {
    // needs to be called on every new init unless last route was gallery
    this.dataMatrix.length = 0;
  }
  addNewEmptyBlock(blockType: BlockType) {
    const data: TemplateBlock = {
      block_type: blockType,
      products: [],
    };

    const numberOfPictures = this.getNumberOfPictures(blockType);
    for (let i = 0; i < numberOfPictures; i++) {
      data.products?.push(null as any);
    }
    this.dataMatrix.push(data);
    this.emitHomepageDataUpdated();
  }
  addNewEmptyBlockAtIndex(blockType: BlockType, index: number) {
    const data: TemplateBlock = {
      block_type: blockType,
      products: [],
    };
    const numberOfPictures = this.getNumberOfPictures(blockType);
    for (let i = 0; i < numberOfPictures; i++) {
      data.products!.push(null);
    }
    this.dataMatrix.splice(index, 0, data);
    this.emitHomepageDataUpdated();
  }
  deleteBlock(index: number) {
    this.dataMatrix.splice(index, 1);
    this.emitHomepageDataUpdated();
  }

  private emitHomepageDataUpdated() {
    this.homepageDataUpdatedSubject.next();
  }
  getHomepageData() {
    return this.dataMatrix;
  }

  getNumberOfPictures(blockType: BlockType) {
    let numberOfPictures = 0;
    if (blockType.variation === 'block') {
      switch (blockType.id) {
        case 1:
        case 2:
          numberOfPictures = 3;
          break;
        case 3:
          numberOfPictures = 2;
          break;
        case 4:
          numberOfPictures = 6;
          break;
        case 5:
          numberOfPictures = 1;
          break;
        case 6:
        case 7:
          numberOfPictures = 5;
          break;
      }
    } else if (blockType.variation === 'section') {
      switch (blockType.id) {
        case 1:
          numberOfPictures = 8;
          break;
        case 2:
          numberOfPictures = 9;
          break;
        case 3:
          numberOfPictures = 5;
          break;
        case 4:
          numberOfPictures = 6;
          break;
        case 5:
          numberOfPictures = 10;
          break;
        case 6:
          numberOfPictures = 1;
          break;
      }
    }

    return numberOfPictures;
  }

  editText(blockIndex: number, fieldName: FieldName, value: string) {
    this.dataMatrix[blockIndex][fieldName] = value;
  }

  editProduct(fieldName: ProductFieldName, value: string) {
    const outerIndex = this.coordinates.outerIndex!;
    const innerIndex = this.coordinates.innerIndex!;

    const product: any = this.dataMatrix[outerIndex].products![innerIndex];
    if (product !== null) {
      product[fieldName] = value;
    }
  }

  upload(body: Object): Observable<HomepageBlockProductPreview> {
    //@ts-ignore
    return this.webAdministrationService.cmsUploadHomePageMedia(body);
  }

  hasImageAtCoordinates(): boolean {
    const outerIndex = this.coordinates.outerIndex;
    const innerIndex = this.coordinates.innerIndex;

    let result: boolean = false;
    if (this.dataMatrix[outerIndex].products![innerIndex] !== null) {
      result = true;
    }
    return result;
  }

  getSelectedData(): HomepageBlockProductPreview | null {
    const outerIndex = this.coordinates.outerIndex;
    const innerIndex = this.coordinates.innerIndex;

    return this.dataMatrix[outerIndex].products![innerIndex];
  }

  canBeSavedAsDraft(): boolean {
    return this.dataMatrix.length > 0;
  }

  canBePublished(): boolean {
    let isPublishable: boolean = this.canBeSavedAsDraft();

    if (isPublishable) {
      this.dataMatrix.map((element) => {
        element.products?.map((el) => {
          if (el === null) {
            isPublishable = false;
          }
        });
      });
    }
    return isPublishable;
  }

  saveDraft() {
    //console.log('test', 'save draft', this.dataMatrix);

    return this.homepageAdministrationService.postTemplate(
      this.dataMatrix as any,
      false,
      this.canBePublished()
    );
  }
  publish() {
    //console.log('test', 'publish', this.dataMatrix);

    return this.homepageAdministrationService.postTemplate(
      this.dataMatrix as any,
      true,
      this.canBePublished()
    );
  }
}
