/**
 * Pixsell API Spec
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.13.0-rev2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { GalleryGroupCrudRequestResponse } from '../model/galleryGroupCrudRequestResponse';
import { GalleryGroupItem } from '../model/galleryGroupItem';
import { GalleryGroupList } from '../model/galleryGroupList';
import { IdsList } from '../model/idsList';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class GalleryGroupsService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Delete gallery groups
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsDeleteGalleryGroups(
    body?: IdsList,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public cmsDeleteGalleryGroups(
    body?: IdsList,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public cmsDeleteGalleryGroups(
    body?: IdsList,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public cmsDeleteGalleryGroups(
    body?: IdsList,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/cms/gallery_groups/delete`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a gallery group with that ID/SEO URL
   * @param id_or_seo
   * @param is_legacy_url Is Pixsell legacy URL
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetGalleryGroup(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GalleryGroupItem>;
  public cmsGetGalleryGroup(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GalleryGroupItem>>;
  public cmsGetGalleryGroup(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GalleryGroupItem>>;
  public cmsGetGalleryGroup(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_or_seo === null || id_or_seo === undefined) {
      throw new Error(
        'Required parameter id_or_seo was null or undefined when calling cmsGetGalleryGroup.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (is_legacy_url !== undefined && is_legacy_url !== null) {
      queryParameters = queryParameters.set(
        'is_legacy_url',
        <any>is_legacy_url
      );
    }
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<GalleryGroupItem>(
      'get',
      `${this.basePath}/cms/gallery_groups/${encodeURIComponent(
        String(id_or_seo)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Search GalleryGroups
   * @param page Page number
   * @param page_size Size of the page, result count. Default 50. Min 1, Max 100.
   * @param query Omnisearch text
   * @param query_include_headline General purpose search.
   * @param query_include_people General purpose search.
   * @param query_include_caption General purpose search.
   * @param query_include_keywords General purpose search.
   * @param from_date Date created: From
   * @param to_date A description
   * @param contains_gallery_id Contains Gallery ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsGetGalleryGroups(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    from_date?: string,
    to_date?: string,
    contains_gallery_id?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GalleryGroupList>;
  public cmsGetGalleryGroups(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    from_date?: string,
    to_date?: string,
    contains_gallery_id?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GalleryGroupList>>;
  public cmsGetGalleryGroups(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    from_date?: string,
    to_date?: string,
    contains_gallery_id?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GalleryGroupList>>;
  public cmsGetGalleryGroups(
    page?: number,
    page_size?: number,
    query?: string,
    query_include_headline?: boolean,
    query_include_people?: boolean,
    query_include_caption?: boolean,
    query_include_keywords?: boolean,
    from_date?: string,
    to_date?: string,
    contains_gallery_id?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set('page_size', <any>page_size);
    }
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }
    if (
      query_include_headline !== undefined &&
      query_include_headline !== null
    ) {
      queryParameters = queryParameters.set(
        'query_include_headline',
        <any>query_include_headline
      );
    }
    if (query_include_people !== undefined && query_include_people !== null) {
      queryParameters = queryParameters.set(
        'query_include_people',
        <any>query_include_people
      );
    }
    if (query_include_caption !== undefined && query_include_caption !== null) {
      queryParameters = queryParameters.set(
        'query_include_caption',
        <any>query_include_caption
      );
    }
    if (
      query_include_keywords !== undefined &&
      query_include_keywords !== null
    ) {
      queryParameters = queryParameters.set(
        'query_include_keywords',
        <any>query_include_keywords
      );
    }
    if (from_date !== undefined && from_date !== null) {
      queryParameters = queryParameters.set('from_date', <any>from_date);
    }
    if (to_date !== undefined && to_date !== null) {
      queryParameters = queryParameters.set('to_date', <any>to_date);
    }
    if (contains_gallery_id !== undefined && contains_gallery_id !== null) {
      queryParameters = queryParameters.set(
        'contains_gallery_id',
        <any>contains_gallery_id
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<GalleryGroupList>(
      'get',
      `${this.basePath}/cms/gallery_groups`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Create or update a new record of type &#x27;Gallery&#x27;
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cmsPostGalleryGroup(
    body?: GalleryGroupCrudRequestResponse,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GalleryGroupCrudRequestResponse>;
  public cmsPostGalleryGroup(
    body?: GalleryGroupCrudRequestResponse,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GalleryGroupCrudRequestResponse>>;
  public cmsPostGalleryGroup(
    body?: GalleryGroupCrudRequestResponse,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GalleryGroupCrudRequestResponse>>;
  public cmsPostGalleryGroup(
    body?: GalleryGroupCrudRequestResponse,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GalleryGroupCrudRequestResponse>(
      'post',
      `${this.basePath}/cms/gallery_groups`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a gallery group with that ID/SEO URL
   * @param id_or_seo
   * @param is_legacy_url Is Pixsell legacy URL
   * @param source Source of this product, Pixsell itself or external source like THP.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public frontGetGalleryGroup(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GalleryGroupItem>;
  public frontGetGalleryGroup(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GalleryGroupItem>>;
  public frontGetGalleryGroup(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GalleryGroupItem>>;
  public frontGetGalleryGroup(
    id_or_seo: string,
    is_legacy_url?: boolean,
    source?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_or_seo === null || id_or_seo === undefined) {
      throw new Error(
        'Required parameter id_or_seo was null or undefined when calling frontGetGalleryGroup.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (is_legacy_url !== undefined && is_legacy_url !== null) {
      queryParameters = queryParameters.set(
        'is_legacy_url',
        <any>is_legacy_url
      );
    }
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<GalleryGroupItem>(
      'get',
      `${this.basePath}/gallery_groups/${encodeURIComponent(
        String(id_or_seo)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
