<div
  *ngIf="
    !pagebuilderMode && link != undefined && link.length != 0;
    else elseTpl
  "
>
  <a [href]="link">
    <ng-container *ngTemplateOutlet="elseTpl"></ng-container>
  </a>
</div>

<ng-template #elseTpl>
  <div class="text can-change-color">
    <h1 *ngIf="pagebuilderMode">
      {{ headline || "Placeholder headline" }}
    </h1>
    <h1 *ngIf="!pagebuilderMode">
      {{
        currentLanguage == "hr" ? headline : headlineEn ? headlineEn : headline
      }}
    </h1>
    <p *ngIf="pagebuilderMode">
      {{ description || "Placeholder description" }}
    </p>
    <p *ngIf="!pagebuilderMode">
      {{
        currentLanguage == "hr"
          ? description
          : descriptionEn
          ? descriptionEn
          : description
      }}
    </p>
  </div>
</ng-template>
