<article
  [class.disabled]="!this.form.get('specialOfferCheckbox')?.value"
  [formGroup]="form"
>
  <div class="checkbox">
    <label for="specialOfferCheckbox">
      <input
        type="checkbox"
        name="specialOfferCheckbox"
        id="specialOfferCheckbox"
        formControlName="specialOfferCheckbox"
      />
      <div class="checkbox-custom"></div>
    </label>
    <label for="{{ controlName }}">{{
      (isRequired ? "* " : "") + label
    }}</label>
  </div>
  <div class="input-div">
    <input
      type="text"
      name="{{ controlName }}"
      id="{{ controlName }}"
      formControlName="{{ controlName }}"
    />
    <button></button>
  </div>
</article>
