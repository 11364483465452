<label for="{{ controlName }}">{{ label }}</label>
<div [formGroup]="form">
  <div class="input-group">
    <input
      [class.input-hidden]="isAutocomplete"
      #tagInput
      type="text"
      name="{{ controlName }}"
      id="{{ controlName }}"
      formControlName="{{ controlName }}"
      minlength="3"
      (keydown.enter)="handleEnter()"
      [class.input--expanded]="tags.size"
    />

    <app-field-text
      *ngIf="isAutocomplete"
      #tagInput
      style="width: 100%"
      class="import-sidebar-input"
      [label]="'test'"
      (autocompleteValueChange)="autocompleteInputValueChange($event)"
      [acceptUserInput]="true"
      [hasCreateNewAutocomplete]="false"
      theme="cms-iptc"
      [autocompleteType]="autocompleteType"
      [shouldEmptyInputOnAutocompleteSelected]="true"
    ></app-field-text>

    <button *ngIf="!isAutocomplete" class="dropdown-button"></button>
  </div>

  <div class="tag-container" *ngIf="tags.size">
    <div *ngFor="let tag of tags">
      <div class="tag">
        {{ tag }} <button class="tag-btn" (click)="removeTag(tag)"></button>
      </div>
    </div>
  </div>
</div>
